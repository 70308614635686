import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import authService from '../../services/authService';
import _ from 'lodash';
import MynyfyText from '../../components/MynyfyText';
import './Sidebar.scss';
import ExitToAppRoundedIcon from '@mui/icons-material/ExitToAppRounded';
import MynyfyModal from '../../components/MynyfyModal';
import MynyfyButton from '../../components/MynyfyButton';
import AdsClickOutlinedIcon from '@mui/icons-material/AdsClickOutlined';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import Diversity2RoundedIcon from '@mui/icons-material/Diversity2Rounded';
import NotificationsNoneRoundedIcon from '@mui/icons-material/NotificationsNoneRounded';
import ExploreOutlinedIcon from '@mui/icons-material/ExploreOutlined';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { AccountCircleOutlined, CardGiftcardOutlined, HomeOutlined } from '@mui/icons-material';
import theme from '../../utils/theme';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import { ReactComponent as CouponsIcon } from '../../assets/svg/coupons.svg';
import { ReactComponent as ShopWinIcon } from '../../assets/svg/shop_and_win.svg';
import { userLogout } from '../../redux/actions/user.action';
import { updateLocation } from '../../redux/actions/location.action';

const Sidebar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const location = useSelector((state) => state.location?.location);
  const deviceId = useSelector((state) => state.user?.deviceId);
  const mynts = useSelector((state) => state.mynts);
  const user = useSelector((state) => state.user);
  const isLoggedIn = user.isLoggedIn;

  const [isLoading, setIsLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState('/');
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);

  useEffect(() => {
    setCurrentTab(window.location.pathname);
  }, [window.location.pathname]);

  const tabAndNavigationHandler = (path, pathData) => {
    navigate(path, { state: pathData || null });
  };

  const handleLogoutModalClose = () => {
    setIsLogoutModalOpen(false);
  };

  const logoutHandler = () => {
    setIsLoading(true);
    setTimeout(async () => {
      await authService.logout({ userId: user?.id, deviceId }).catch(() => {});
      authService._removeToken();
      let userLoc = _.cloneDeep(location);
      dispatch(userLogout());
      dispatch(updateLocation(userLoc));
      navigate('/');
      setIsLoading(false);
    }, 1000);
  };

  return (
    <div className='sidebar'>
      <div>
        <div style={{ padding: 5 }}>
          <div style={{ marginBottom: 10 }} onClick={() => navigate('/')}>
            <img
              src={require('../../assets/imgs/LandingPage/mynyfy.png')}
              className='logo'
              style={{
                width: '120px',
                objectfit: 'contain',
                cursor: 'pointer',
              }}
              alt='Mynyfy'
            />
          </div>
          {/* <div className='divider'></div> */}
          {isLoggedIn && (
            <div className='row cursor' onClick={() => navigate('/WebTransactions', { state: 1 })}>
              <img
                src={require('../../assets/imgs/icons/coin.png')}
                style={{ width: 40, height: 40 }}
              />
              <div style={{ marginLeft: 5 }}>
                <MynyfyText title={mynts} className='myntVal' pBig />
                <div className='row'>
                  <MynyfyText title={'My Mynts'} color={theme.MUTED_BLUE} pBig />
                </div>
              </div>
            </div>
          )}
        </div>
        <ul>
          <MainNavigationItems
            currentTab={currentTab}
            tabAndNavigationHandler={tabAndNavigationHandler}
          />
        </ul>
      </div>
      <div
        style={{
          padding: '15px 0 15px 0',
          position: 'sticky',
          bottom: 20,
          backgroundColor: 'rgba(255, 255, 255, 0.3)',
          backdropFilter: 'blur(10px)',
          zIndex: 10,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
        <div
          className='bounce-animate'
          onClick={() => setIsLogoutModalOpen(true)}
          style={{
            border: '2px solid #eeeeee',
            marginLeft: '170px',
            padding: '5px',
            borderRadius: '7px',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <LogoutRoundedIcon style={{ fontSize: '17px' }} fontSize='small' />
        </div>
      </div>
      <MynyfyModal
        open={isLogoutModalOpen}
        close={handleLogoutModalClose}
        position={'center'}
        style={{ with: 'auto', borderRadius: 20 }}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <ExitToAppRoundedIcon
            fontSize='medium'
            style={{
              fontSize: '30px',
              padding: '15px',
              borderRadius: '100px',
              color: '#ce3838',
              backgroundColor: '#fceeee',
              marginBottom: '15px',
            }}
          />
          <MynyfyText title={'Logout'} h4 semiBold />
          <MynyfyText
            title={'Are you sure you want to sign out?'}
            style={{ marginTop: '5px' }}
            medium
            color={'#5f5f5f'}
            h6
          />
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginTop: '25px',
            }}>
            <MynyfyButton
              onClick={handleLogoutModalClose}
              style={{
                border: '2px solid #eeeeee',
                backgroundColor: 'white',
                borderRadius: '7px',
                color: '#5f5f5f',
                width: '150px',
                fontFamily: 'MynyfySemiBold',
                height: '40px',
              }}
              fullWidth={true}
              label={'Cancel'}
            />
            <MynyfyButton
              onClick={logoutHandler}
              loading={isLoading}
              style={{
                backgroundColor: '#ce3838',
                color: 'white',
                borderRadius: '7px',
                width: '150px',
                fontFamily: 'MynyfySemiBold',
                height: '40px',
              }}
              fullWidth={true}
              label={'Yes, Exit'}
            />
          </div>
        </div>
      </MynyfyModal>
    </div>
  );
};

export default Sidebar;

const MainNavigationItems = ({ currentTab, tabAndNavigationHandler }) => (
  <>
    <SidebarItem
      path='/'
      label='Brands'
      Icon={<HomeOutlined className='icon' fontSize='medium' />}
      currentTab={currentTab}
      onClick={tabAndNavigationHandler}
    />
    <SidebarItem
      path='/Coupons'
      pathData={{ sidebar: true }}
      label='Coupons'
      Icon={<CouponsIcon width={22} height={22} className='icon' />}
      currentTab={currentTab}
      onClick={tabAndNavigationHandler}
    />
    <SidebarItem
      path='/ShopToJoin'
      pathData={{ sidebar: true }}
      label='Contest'
      Icon={<ShopWinIcon width={22} height={22} className='icon' />}
      currentTab={currentTab}
      onClick={tabAndNavigationHandler}
    />
    {/* <SidebarItem
      path='/Deals/'
      label='Deals'
      Icon={<LocalOfferOutlinedIcon className='icon' fontSize='medium' />}
      currentTab={currentTab}
      onClick={tabAndNavigationHandler}
    />
    <SidebarItem
      path='/WebRewards/'
      label='Vouchers'
      Icon={<CardGiftcardOutlined className='icon' fontSize='medium' />}
      currentTab={currentTab}
      onClick={tabAndNavigationHandler}
    />
    <SidebarItem
      path='/Jackpots/'
      label='Jackpots'
      Icon={<AdsClickOutlinedIcon className='icon' fontSize='medium' />}
      currentTab={currentTab}
      onClick={tabAndNavigationHandler}
    />
    <SidebarItem
      path='/WebShoppingLayout'
      label='Explore Sellers'
      Icon={<StorefrontOutlinedIcon className='icon' fontSize='medium' />}
      currentTab={currentTab}
      onClick={tabAndNavigationHandler}
    />
    <SidebarItem
      path='/WebFoodLayout'
      label='Explore Foods'
      Icon={<ExploreOutlinedIcon className='icon' fontSize='medium' />}
      currentTab={currentTab}
      onClick={tabAndNavigationHandler}
    /> */}
    <SidebarItem
      path='/WebNotifications/'
      label='Notifications'
      Icon={<NotificationsNoneOutlinedIcon className='icon' fontSize='medium' />}
      currentTab={currentTab}
      onClick={tabAndNavigationHandler}
    />
    <SidebarItem
      path='/orders/'
      label='Orders'
      Icon={<ShoppingCartOutlinedIcon className='icon' fontSize='medium' />}
      currentTab={currentTab}
      onClick={tabAndNavigationHandler}
    />
    <SidebarItem
      path='/Profile/'
      label='Profile'
      Icon={<AccountCircleOutlined className='icon' fontSize='medium' />}
      currentTab={currentTab}
      onClick={tabAndNavigationHandler}
    />
  </>
);

const SidebarItem = ({ path, pathData, label, Icon, currentTab, onClick, badge }) => {
  const baseRoute = path.split('/')[1];
  const currentBaseRoute = currentTab.split('/')[1];
  const isActive = baseRoute === currentBaseRoute;
  const [isBouncy, setIsBouncy] = useState(false);

  const handleClick = (path) => {
    setIsBouncy(true);
    onClick(path, pathData);
    setTimeout(() => {
      setIsBouncy(false);
    }, 300);
  };

  return (
    <li
      onClick={() => handleClick(path, pathData)}
      className={`${isActive ? 'activeTab' : ''} ${isBouncy ? 'bouncy' : ''}`}>
      {Icon ? Icon : null}
      <a>
        {label}
        {badge && <span className='new-orders-count'>{badge}</span>}
      </a>
    </li>
  );
};
