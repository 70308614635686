import React, { Fragment, useState } from 'react';
import MynyfyText from '../../../components/MynyfyText';
import './SignUp.scss';
import MynyfyInput from '../../../components/MynyfyInput';
import { Card, Checkbox } from '@mui/material';
import MynyfyButton from '../../../components/MynyfyButton';
import { useNavigate } from 'react-router-dom';
import MynyfySpan from '../../../components/MynyfySpan';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import validate from '../../../utils/validate';
import _ from 'lodash';
import { MsgEnums } from '../../../utils/MsgEnums';
import { KeyboardBackspaceRounded } from '@mui/icons-material';
import authService from '../../../services/authService';
import { SyncProfile } from '../../../utils/enums';
import jwtDecode from 'jwt-decode';
import IconButton from '@mui/material/IconButton';
import { VisibilityRounded, VisibilityOffRounded } from '@mui/icons-material';
import theme from '../../../utils/theme';
import { snackbarInfo } from '../../../redux/actions/snackbar.action';
import commonService from '../../../services/commonService';
import { MynyfyVideos } from '../../../utils/MynyfyEnums';
import { registrationValidator } from '../../../utils/validators';

const SignUp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const branddata = location.state.data;

  const [mobile, setMobile] = useState('');
  const [otp, setOTP] = useState('');
  const [showOTP, setShowOTP] = useState(false);
  const [isOtpSent, setOtpSent] = useState(false);
  const [password, setPassword] = useState('');
  const [isPassword, setIsPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isTerms, setIsTerms] = useState(false);
  const [existingProfile, setExistingProfile] = useState(null);
  const [error, setError] = useState({});
  const [otpError, setOtpError] = useState(null);
  const [isClicked, setClicked] = useState(false);

  const checkProfile = async () => {
    setClicked(true);
    let body = { mobile };
    let errors = validate(body, registrationValidator);

    setError(errors);

    if (_.isEmpty(errors)) {
      await authService
        .profileExist(mobile)
        .then((res) => {
          if (res.profile === null) {
            sendOtp();
            setExistingProfile(false);
          } else if (
            res.profile?.syncedWith === SyncProfile.SELLER ||
            res.profile?.syncedWith === SyncProfile.BUYER_SELLER ||
            res.profile.syncedWith === SyncProfile.BUYER
          ) {
            setExistingProfile(res.profile);
            setIsPassword(true);
          } else if (
            res.profile.syncedWith === SyncProfile.PARTNER ||
            res.profile.syncedWith === SyncProfile.BUYER_PARTNER
          ) {
            snackbarHandler({
              type: 'warning',
              message: [
                `This mobile number is registered with ${res.profile.syncedWith}`,
                'Account Sync feature will be comming soon',
                'Please login with new number',
              ],
            });
          }
        })
        .finally(() => setClicked(false));
    } else {
      setClicked(false);
      snackbarHandler({ type: 'warning', message: 'Please enter valid Mobile Number' });
    }
  };

  const sendOtp = () => {
    let body = { mobile };
    let errors = validate(body, registrationValidator);
    setError(errors);
    if (_.isEmpty(errors)) {
      authService
        .sendOtp(mobile)
        .then((res) => {
          if (res) {
            setOtpSent(true);
            snackbarHandler({ type: 'success', message: 'OTP has been sent successfully' });
          } else {
            snackbarHandler({ type: 'error', message: 'Failed to send OTP' });
          }
        })
        .finally(() => setClicked(false));
    } else {
      snackbarHandler({ type: 'warning', message: 'Please enter valid Mobile Number' });
      setClicked(false);
    }
  };

  const handleOTPSetup = () => {
    if (otp?.length != 6) {
      snackbarHandler({ type: 'warning', message: 'Invalid OTP' });
      setOtpError('OTP must be of length 6');
    } else {
      setIsPassword(true);
    }
  };

  const registerHandler = async () => {
    let body = { mobile, password, otp, isTerms };
    let errors = validate(body, registrationValidator);
    setError(errors);

    if (_.isEmpty(errors)) {
      let body = {
        profile: { mobile: mobile, password: password },
        otp,
        forProfile: 'Seller',
      };
      try {
        const res = await authService.register(body);
        if (res?.data?.message === 'Profile Created Successfully') {
          await loginHandler();
          snackbarHandler({
            type: 'success',
            message: 'Your account has been created successfully',
          });
        } else if (res?.data?.message === 'OTP Verification Failure.') {
          snackbarHandler({
            type: 'error',
            message: ['OTP Verification Failed', 'please enter correct OTP'],
          });
          setIsPassword(false);
        } else {
          snackbarHandler({ type: 'error', message: res?.message });
        }
      } catch (err) {
        snackbarHandler({
          type: 'error',
          message: [MsgEnums.SOMETHING_WENT_WRONG, MsgEnums.TRY_AGAIN_LATER],
        });
      }
    } else {
      snackbarHandler({ type: 'warning', message: MsgEnums.FILL_DETAILS });
    }
  };

  const syncProfileHandler = () => {
    let registerData = { profile: { ...existingProfile, password }, forProfile: 'Seller' };
    authService.register(registerData).then((registerRes) => {
      if (registerRes?.data?.message === 'Profile Created Successfully') {
        loginHandler();
      } else {
        snackbarHandler({
          type: 'error',
          message: registerRes.message || [MsgEnums.SOMETHING_WENT_WRONG, MsgEnums.TRY_AGAIN_LATER],
        });
      }
    });
  };

  const loginHandler = async (isDirectLogin) => {
    let body = { mobile, password, forProfile: 'Seller' };
    try {
      const res = await authService.login(body);
      if (res?.data?.message === 'Authenticated!') {
        const token = res.data.token;
        const user = jwtDecode(token);
        if (isDirectLogin) {
          getSellerById({ user, token });
        } else {
          navigate('/Reseller/ResellerRegistration', { state: { user, token, branddata } });
        }
      } else {
        snackbarHandler({ type: 'error', message: res?.data?.message });
      }
    } catch (err) {
      console.log(err);
      clearState();
      snackbarHandler({
        type: 'error',
        message: ['Unable to login automatically', 'Please login on your own'],
      });
    }
  };

  const getSellerById = ({ user, token }) => {
    if (user) {
      authService.getSellerByProfileId(user.profile, token).then((res) => {
        if (res.success) {
          if (res?.data?.organizationId) {
            associateBrand(res.data.organizationId, token);
          } else if (res.data.profile) {
            navigate('/Reseller/ResellerRegistration', { state: { user, token, branddata } });
          }
        }
      });
    } else {
      clearState();
      snackbarHandler({
        type: 'error',
        message: [MsgEnums.SOMETHING_WENT_WRONG, MsgEnums.TRY_AGAIN_LATER],
      });
    }
  };

  const associateBrand = (orgId, token) => {
    if (orgId) {
      let body = { addBrandId: branddata?._id };
      commonService.updateOrgBrand(orgId, body, token).then((res) => {
        if (res.success) {
          snackbarHandler({
            type: 'success',
            message: 'Dropshipping | Influencer account added successfully',
          });
          navigate(-1);
        } else {
          snackbarHandler({ open: true, type: 'error', message: res.message });
        }
      });
    } else {
      snackbarHandler({ type: 'warning', message: 'Store Id & Brand Id are Mandatory' });
    }
  };

  const clearState = () => {
    setMobile('');
    setOTP('');
    setOtpSent(false);
    setPassword('');
    setIsPassword(false);
    setShowPassword(false);
    setIsTerms(false);
    setExistingProfile(null);
    setError({});
    setOtpError(null);
    setClicked(false);
  };

  const snackbarHandler = ({ type, message }) => {
    dispatch(snackbarInfo({ open: true, type, message }));
  };

  return (
    <div id='SignUp'>
      <KeyboardBackspaceRounded className='backBtn' onClick={() => navigate(-1)} />
      <MynyfyText style={{ paddingBottom: 100 }} title={branddata?.brand} color={'#fff'} h3 />
      {/*<img src={branddata?.logo?.url} alt='Mynyfy' className='logo' />*/}
      <MynyfyText title={'Dropshipping | Influencer'} color={'#fff'} h5 />
      <MynyfyText title={'SignUp'} color={'#fff'} h6 style={{ marginTop: '5px' }} />

      <Card className='container' style={{ marginTop: 75 }}>
        {(isPassword && existingProfile) || (existingProfile == null && !isOtpSent) ? (
          <Fragment>
            <MynyfyText
              title={'To avoid spam data, we allow only OTP based Signup'}
              pSmall
              style={{ margin: '0px 5px 5px' }}
            />
            <MynyfyInput
              type='number'
              fullWidth={true}
              placeHolder={'Phone Number'}
              value={mobile}
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d*$/.test(value) && value.length <= 10) {
                  setMobile(value);
                }
              }}
              helperText={error?.mobile}
              error={error?.mobile ? true : false}
            />
            <MynyfyText
              title={'Enter 10 digit number without 0 & +91'}
              pSmall
              style={{ margin: '0px 5px 5px' }}
            />
          </Fragment>
        ) : null}

        {isPassword ? (
          <div>
            <div style={{ display: 'flex' }}>
              <MynyfyInput
                fullWidth={true}
                placeHolder={'Set Password'}
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                helperText={error?.password}
                error={error?.password ? true : false}
              />
              <IconButton
                style={{ position: 'absolute', marginLeft: '68%', marginTop: '5px' }}
                onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <VisibilityRounded /> : <VisibilityOffRounded />}
              </IconButton>
            </div>
          </div>
        ) : null}

        {isOtpSent && !isPassword ? (
          <div style={{ display: 'flex' }}>
            <MynyfyInput
              fullWidth={true}
              placeHolder={'OTP'}
              type={showOTP ? 'text' : 'password'}
              value={otp}
              onChange={(e) => setOTP(e.target.value)}
              helperText={error?.otp}
              error={error?.otp ? true : false}
            />
            <IconButton
              style={{ position: 'absolute', marginLeft: '68%', marginTop: '5px' }}
              onClick={() => setShowOTP(!showOTP)}>
              {showOTP ? <VisibilityRounded /> : <VisibilityOffRounded />}
            </IconButton>
          </div>
        ) : null}

        {isOtpSent && isPassword ? (
          <div className='row' style={{ padding: '10px 0px' }}>
            <Checkbox
              checked={isTerms}
              style={{ color: error?.isTerms ? 'red' : theme.PRIMARY_BLUE }}
              onClick={() => setIsTerms(!isTerms)}
            />
            <div>
              <MynyfySpan title={'I agree to '} pSmall />
              <MynyfySpan
                title={'Terms & Conditions and Data Privacy Policies'}
                link
                onClick={() => {
                  navigate('/Policies/Terms&Conditions', { state: 'Terms & Conditions' });
                }}
                pSmall
              />
            </div>
          </div>
        ) : null}

        <MynyfyButton
          label={
            existingProfile == null ||
            (isOtpSent && !isPassword) ||
            existingProfile?.syncedWith == SyncProfile.BUYER
              ? 'Continue'
              : existingProfile == false && isOtpSent && isPassword
              ? 'Sign Up'
              : 'Login'
          }
          fullWidth={true}
          disabled={isClicked}
          onClick={() => {
            existingProfile == null
              ? checkProfile()
              : isOtpSent && !isPassword
              ? handleOTPSetup()
              : existingProfile == false && isOtpSent && isPassword
              ? registerHandler()
              : existingProfile?.syncedWith == SyncProfile.BUYER
              ? syncProfileHandler()
              : loginHandler(true);
          }}
        />

        <div className='rowC' style={{ marginTop: 10 }}>
          <MynyfySpan title={'How it works?'} />
          <a
            href='https://youtube.com/shorts/LMgCxt5Ilj0'
            target='_blank'
            rel='noopener noreferrer'
            style={{ textDecoration: 'none' }}>
            <MynyfySpan title={'Watch video'} link style={{ marginLeft: 5 }} />
          </a>
        </div>
      </Card>

      <MynyfyText
        title={'View Demo'}
        color={theme.LIGHT_COLOR}
        onClick={() => window.open(MynyfyVideos.resellerSignUpMobile)}
        style={{
          padding: 10,
          borderRadius: 8,
          border: '1px solid #fff',
          margin: '15px 0px 0px auto',
        }}
        className='cursor'
        h5
      />
    </div>
  );
};

export default SignUp;
