import React, { Fragment, useEffect, useState, useRef } from 'react';
import WebMynyfyHeader from '../../components/WebMynyfyHeader';
import { useLocation, useNavigate } from 'react-router';
import MynyfyText from '../../components/MynyfyText';
import MynyfyButton from '../../components/MynyfyButton';
import { Carousel } from 'react-responsive-carousel';
import { Button, CardContent } from '@mui/material';
import IosShareOutlinedIcon from '@mui/icons-material/IosShareOutlined';
import MynyfyLoader from '../../components/MynfyfLoader';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { snackbarInfo } from '../../redux/actions/snackbar.action';
import ProductCartHelper from './ProductCartHelper';
import MynyfyPre from '../../components/MynyfyPre';
import {
  ControlPointRounded,
  DiscountRounded,
  RemoveCircleOutlineRounded,
} from '@mui/icons-material';
import { ReactComponent as VegIcon } from '../../assets/svg/veg.svg';
import { ReactComponent as NonVegIcon } from '../../assets/svg/nonVeg.svg';
import { ProductMaterialType } from '../../utils/enums';
import { addToBag, decrementHandler, incrementHandler, qtyHandler } from './AddProductHelper';
import { formatToMoney, openUrl, s3ToCloudFront, videoUrl } from '../../utils/CommonMethods';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import BrandProductCartHelper from './ProductCartHelperForBrand';
import LocationModal from '../Location/LocationModal';
import MynyfyWebModal from '../../components/MynyfyWebModal';
import MynyfyWebViewVideo from '../../components/MynyfyWebViewVideo';

const NO_OF_OFFERS = 3;

const WebBrandProductDetailsModal = ({
  id,
  details,
  navFrom,
  brandDetails,
  isEcommerceActive,
  table,
  openModal,
  closeModal,
  modalProps,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //   const route = useLocation();
  let productDetails = details;
  let policies = brandDetails?.policies;

  const inputRef = useRef(null);
  const containerRef = useRef(null);

  const bag = useSelector((state) => state.bag);
  const location = useSelector((state) => state.location?.location);

  const [isMoreOffers, setMoreOffers] = useState(false);
  const [offers, setOffers] = useState(false);
  const [routeDetails, setRouteDetails] = useState(null);
  const [isAddBtnClicked, setAddBtnClicked] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);
  const [isLocationModal, setIsLocationModal] = useState(false);
  const [selectedSize, setSelectedSize] = useState(null);
  const [selectedColor, setSelectedColor] = useState(null);
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [isSizes, setSizes] = useState(false);
  const [isColors, setColors] = useState(false);
  const [isViewVideo, setIsViewVideo] = useState({ open: false, url: null });

  useEffect(() => {
    setSelectedColor(productDetails.variants[0]?.color);
    setSelectedSize(productDetails.variants[0]?.size);

    setColors(
      _.isEmpty(productDetails.variants?.map((e) => e.color)?.filter((e) => e)) ? false : true
    );
    setSizes(
      _.isEmpty(productDetails.variants?.map((e) => e.size)?.filter((e) => e)) ? false : true
    );

    if (productDetails?.category) {
      if (!_.isEmpty(bag?.offers)) {
        let reqOffers = bag?.offers?.filter(
          (e) => e?.category?._id == productDetails?.category?._id || !e?.category
        );
        setOffers(reqOffers);
      }
    } else setOffers(bag?.offers);
  }, []);

  useEffect(() => {
    let reqProduct = details;
    const reqVariant = reqProduct?.variants?.find(
      (f) => f.size == selectedSize && f.color == selectedColor
    );
    setSelectedVariant(reqVariant);
  }, [selectedSize, selectedColor]);

  useEffect(() => {
    if (bag?.calculation?.qty && isAddBtnClicked) {
      containerRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
      setAddBtnClicked(false);
    }
  }, [bag?.calculation?.qty]);

  const shareUrl = async () => {
    if (bag?.about) {
      try {
        if (navigator.share) {
          await navigator.share({
            title: bag?.about?.name || bag?.about?.brand,
            text:
              `Now you can find all the products, offer, contest of ${
                bag?.about?.name || bag?.about?.brand
              } ${bag?.about?.type}` +
              '\n' +
              '\n' +
              `${bag?.about?.type} link : ${bag?.about?.url}`,
          });
        }
      } catch (error) {
        alert(error.message);
      }
    }
  };

  const getUniqueAttributes = ({ key }) => {
    return [...new Set(productDetails.variants.map((item) => item[key]))].filter((e) => e);
  };

  const getRelatedValues = ({ key, selectedKey, selectedValue }) => {
    return productDetails.variants
      .filter((item) => item[selectedKey] === selectedValue)
      .map((item) => item[key]);
  };

  const isDisabled = (key, value) => {
    let obj = {};
    if (key === 'size') {
      obj = { key: 'size', selectedKey: 'color', selectedValue: selectedColor };
    } else if (key === 'color') {
      obj = { key: 'color', selectedKey: 'size', selectedValue: selectedSize };
    }
    return getRelatedValues(obj)?.includes(value) ? 'true' : 'false';
  };

  useEffect(() => {
    setCurrentImage(selectedVariant?.image?.[0]);
  }, [selectedVariant]);

  const calculateDiscount = (originalPrice, discountedPrice) => {
    if (originalPrice <= 0 || discountedPrice < 0 || discountedPrice > originalPrice) {
      return 'Invalid prices';
    }
    const discountPercentage = ((originalPrice - discountedPrice) / originalPrice) * 100;
    return discountPercentage.toFixed(2) + '%';
  };

  const addToBagHandler = () => {
    if (!_.isEmpty(brandDetails?.razorpayAccountId)) {
      if (location?.state?._id && location?.city?._id && location?.area?._id) {
        addToBag({ productDetails, variant: selectedVariant, bag, dispatch, from: 'brand' });
        setAddBtnClicked(true);
      } else {
        setIsLocationModal(true);
      }
    } else {
      dispatch(
        snackbarInfo({
          open: true,
          type: 'error',
          message: 'Currently not in service please contact brand',
        })
      );
    }
  };

  return (
    <MynyfyWebModal open={openModal} onClose={() => closeModal()} {...modalProps}>
      <div style={{ overflowY: 'auto', height: '90vh', borderRadius: 10, minWidth: 500 }}>
        <div
          style={{
            position: 'sticky',
            top: 0,
            right: 0,
            display: 'flex',
            alignItems: 'start',
            justifyContent: 'end',
            zIndex: 10,
          }}>
          <MynyfyText
            className={'cursor'}
            title={'Close'}
            style={{ padding: 10, marginRight: 10, marginTop: 5 }}
            onClick={() => closeModal()}
          />
        </div>
        {productDetails && selectedVariant ? (
          <Fragment>
            <div
              id='Products'
              style={{ paddingBottom: bag?.calculation?.qty ? 75 : 0 }}
              ref={containerRef}>
              <div style={{ marginTop: 30, marginLeft: 50, marginRight: 50 }}>
                <div style={{ display: 'flex' }}>
                  <div sx={{ maxWidth: 200 }}>
                    <div
                      style={{
                        margin: 'auto',
                        display: 'flex',
                        maxHeight: '500px',
                      }}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          padding: '10px 20px',
                          overflowY: 'auto',
                        }}>
                        {selectedVariant?.image?.map((image, i) => (
                          <img
                            key={i}
                            src={s3ToCloudFront(image?.url)}
                            alt={productDetails?.name}
                            className='cursor'
                            style={{
                              objectFit: 'contain',
                              width: '100px',
                              height: '100px',
                              border: `2px solid ${
                                currentImage?._id === image?._id ? 'black' : '#e8e8e8'
                              }`,
                              padding: '5px 0',
                              borderRadius: 10,
                              margin: '10px 10px',
                            }}
                            onClick={() => setCurrentImage(image)}
                          />
                        ))}
                      </div>
                      <img
                        src={s3ToCloudFront(currentImage?.url)}
                        alt={productDetails?.name}
                        style={{
                          width: '550px',
                          height: '500px',
                          objectFit: 'contain',
                          marginRight: '20px',
                        }}
                      />
                    </div>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                    <div>
                      <CardContent>
                        <div className='rowSB'>
                          <MynyfyText
                            title={productDetails?.name}
                            h2
                            bold
                            style={{ marginBottom: 10 }}
                          />
                        </div>
                        <MynyfyText
                          title={productDetails?.description}
                          style={{ marginTop: 1 }}
                          pBig
                          color={'#5f5f5f'}
                        />
                        {selectedVariant?.videoUrl ? (
                          <MynyfyText
                            title={'View Video'}
                            bold
                            link
                            style={{ marginTop: 10 }}
                            onClick={() => {
                              let reqUrl = videoUrl(selectedVariant?.videoUrl);
                              setIsViewVideo({ open: true, url: reqUrl });
                            }}
                          />
                        ) : null}
                        <div className='rowSB' style={{ marginTop: 20 }}>
                          <div className='row'>
                            {selectedVariant?.actualPrice !== undefined && (
                              <MynyfyText
                                title={'Rs. ' + formatToMoney(selectedVariant?.actualPrice)}
                                style={{
                                  textDecoration:
                                    selectedVariant?.price &&
                                    selectedVariant.actualPrice !== selectedVariant.price
                                      ? 'line-through'
                                      : 'unset',
                                }}
                                h4
                                bold={
                                  selectedVariant?.price &&
                                  selectedVariant.actualPrice !== selectedVariant.price
                                    ? false
                                    : true
                                }
                              />
                            )}
                            {selectedVariant?.actualPrice === selectedVariant?.price ||
                            !selectedVariant?.price ? null : (
                              <MynyfyText
                                title={'Rs. ' + formatToMoney(selectedVariant?.price)}
                                bold
                                h4
                                color={'green'}
                                style={{ marginLeft: 10 }}
                              />
                            )}
                          </div>
                          {!productDetails.materialType ? (
                            productDetails.materialType == ProductMaterialType.NON_VEGETARIAN ? (
                              <NonVegIcon width={20} height={20} />
                            ) : (
                              <VegIcon width={20} height={20} />
                            )
                          ) : null}
                        </div>
                      </CardContent>
                    </div>

                    <div style={{ width: '100%' }}>
                      {isSizes || isColors ? (
                        <div>
                          <div style={{ background: '#F1F1F1', height: '2px' }}></div>
                          <div style={{ paddingBlock: '15px' }}>
                            <div style={{ margin: '0px 16px', marginTop: '5px' }}>
                              {isSizes ? (
                                <Fragment>
                                  <MynyfyText
                                    title='Variants'
                                    color={'grey'}
                                    style={{ marginBottom: 10 }}
                                  />
                                  <div className='row' style={{ gap: 10, flexWrap: 'wrap' }}>
                                    {getUniqueAttributes({ key: 'size' }).map((size) => (
                                      <MynyfyText
                                        key={size}
                                        title={size}
                                        className='cursor'
                                        style={{
                                          padding: '10px 20px',
                                          borderRadius: 5,
                                          backgroundColor: selectedSize === size && '#f0f8fd',
                                          border:
                                            selectedSize === size
                                              ? '2px solid #3076b5'
                                              : '2px solid #e8e8e8',
                                        }}
                                        color={selectedSize === size ? '#3076b5' : 'black'}
                                        isselected={selectedSize == size ? 'true' : 'false'}
                                        isactive={isDisabled('size', size)}
                                        onClick={() => {
                                          setSelectedSize(size);
                                          let obj = {
                                            key: 'color',
                                            selectedKey: 'size',
                                            selectedValue: size,
                                          };
                                          const reqColors = getRelatedValues(obj);
                                          if (!reqColors.includes(selectedColor)) {
                                            setSelectedColor(reqColors?.[0] || null);
                                          }
                                        }}
                                      />
                                    ))}
                                  </div>
                                </Fragment>
                              ) : null}
                              {isColors ? (
                                <Fragment>
                                  <MynyfyText
                                    title='Colors'
                                    color={'grey'}
                                    style={{ marginTop: 20 }}
                                  />
                                  <div className='row' style={{ gap: 10 }}>
                                    {getUniqueAttributes({ key: 'color' }).map((color) => (
                                      <div
                                        key={color}
                                        className='chipBtn colorChip'
                                        style={{ backgroundColor: color }}
                                        isselected={selectedColor == color ? 'true' : 'false'}
                                        isactive={isDisabled('color', color)}
                                        onClick={() => {
                                          setSelectedColor(color);
                                          let obj = {
                                            key: 'size',
                                            selectedKey: 'color',
                                            selectedValue: color,
                                          };
                                          const reqSizes = getRelatedValues(obj);
                                          if (!reqSizes.includes(selectedSize))
                                            setSelectedSize(reqSizes?.[0] || null);
                                        }}></div>
                                    ))}
                                  </div>
                                </Fragment>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>

                    <div className='row' style={{ padding: '10px 20px 15px' }}>
                      {bag?.products?.find(
                        (e) =>
                          e.brandProductId == productDetails._id &&
                          e.brandVariantId == selectedVariant._id
                      ) ? (
                        <div
                          className='rowC'
                          style={{
                            margin: '0px 10px',
                            height: 50,
                            backgroundColor: 'black',
                            width: '300px',
                            marginLeft: -10,
                          }}>
                          <RemoveRoundedIcon
                            fontSize='medium'
                            className='cursor'
                            style={{ color: 'white' }}
                            onClick={() => {
                              decrementHandler({
                                productDetails,
                                variant: selectedVariant,
                                bag,
                                dispatch,
                                from: 'brand',
                              });
                              inputRef.current.focus();
                            }}
                          />
                          <input
                            value={
                              bag?.products?.find(
                                (e) =>
                                  e.brandProductId == productDetails._id &&
                                  e.brandVariantId == selectedVariant._id
                              )?.count || 0
                            }
                            onChange={(e) => {
                              qtyHandler({
                                productDetails,
                                variant: selectedVariant,
                                value: e.target.value,
                                bag,
                                dispatch,
                                from: 'brand',
                              });
                            }}
                            autoFocus
                            ref={(el) => (inputRef.current = el)}
                            className='qtyInput detailsInput'
                            style={{
                              width: '200px',
                              backgroundColor: 'transparent',
                              color: 'white',
                              border: 'none',
                              fontSize: '20px',
                            }}
                          />
                          <AddRoundedIcon
                            fontSize='medium'
                            className='cursor'
                            style={{ color: 'white' }}
                            onClick={() => {
                              incrementHandler({
                                productDetails,
                                variant: selectedVariant,
                                bag,
                                dispatch,
                                from: 'brand',
                              });
                              inputRef.current.focus();
                            }}
                          />
                        </div>
                      ) : (
                        <div
                          style={{
                            flex: 1,
                            display: 'flex',
                            alignItems: 'center',
                            marginLeft: 10,
                          }}>
                          <MynyfyButton
                            label={
                              (selectedVariant.count
                                ? selectedVariant.count - selectedVariant.ordersCount
                                : selectedVariant.count == 0
                                ? 0
                                : 'unlimited') == 0
                                ? 'OUT OF STOCK'
                                : 'ADD TO CART'
                            }
                            className='addBagBtn'
                            onClick={() => addToBagHandler()}
                            disabled={
                              (selectedVariant.count
                                ? selectedVariant.count - selectedVariant.ordersCount
                                : selectedVariant.count == 0
                                ? 0
                                : 'unlimited') == 0
                                ? true
                                : false
                            }
                            style={{
                              backgroundColor:
                                (selectedVariant.count
                                  ? selectedVariant.count - selectedVariant.ordersCount
                                  : selectedVariant.count == 0
                                  ? 0
                                  : 'unlimited') == 0
                                  ? 'gray'
                                  : 'black',
                              borderRadius: 3,
                              width: '350px',
                              height: 50,
                              marginLeft: -20,
                              color: 'white',
                              fontFamily: 'MynyfyRegular',
                            }}
                          />
                          <Button
                            style={{
                              background: 'white',
                              borderRadius: 3,
                              border: '1px solid black',
                              height: 50,
                            }}
                            onClick={() => shareUrl()}>
                            <IosShareOutlinedIcon style={{ color: 'black', fontSize: '20px' }} />
                          </Button>
                        </div>
                      )}
                    </div>
                    <BrandProductCartHelper
                      brandDetails={brandDetails}
                      navFrom={'brand'}
                      table={table}
                      from={'modal'}
                      modalProps={{
                        style: {
                          height: '50px',
                          borderRadius: '5px',
                          width: '300px',
                          marginLeft: 5,
                          border: '1px solid black',
                          backgroundColor: 'white',
                          color: 'black',
                        },
                      }}
                    />
                    <div>
                      <br />
                      {selectedVariant?.specification ? (
                        <div>
                          <div style={{ background: '#F1F1F1', height: '2px' }}></div>
                          <div style={{ paddingTop: '15px' }}>
                            <div style={{ margin: '0px 16px', marginTop: '5px' }}>
                              <div className='rowSB'>
                                <MynyfyText title='Product Details / Specifications' pBig bold />
                                <MynyfyText title='+ More ' link />
                              </div>
                              <div style={{ paddingTop: 10 }}>
                                <MynyfyPre title={selectedVariant?.specification} color={'#000'} />
                              </div>
                            </div>
                          </div>
                          <br />
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div></div>
              </div>
            </div>
            <LocationModal
              isOpen={isLocationModal}
              close={(e) => {
                if (!_.isEmpty(location) || e) setIsLocationModal(false);
              }}
              closeBtn={() => setIsLocationModal(false)}
              content={
                'Some products may not be deliverable to every location. Set your delivery location to view serviceable products.'
              }
              modalProps={{
                position: 'center',
                style: {
                  borderRadius: 10,
                },
              }}
            />
          </Fragment>
        ) : (
          <MynyfyLoader />
        )}
      </div>

      <MynyfyWebViewVideo
        openModal={isViewVideo?.open}
        closeModal={() => setIsViewVideo({ open: false, url: null })}
        url={isViewVideo?.url}
      />
    </MynyfyWebModal>
  );
};

export default WebBrandProductDetailsModal;
