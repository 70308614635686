import React, { Fragment, useState } from 'react';
import MynyfyText from '../../components/MynyfyText';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Card } from '@mui/material';
import _ from 'lodash';
import './WebHome.scss';
import moment from 'moment';
import theme from '../../utils/theme';
import MynyfyPre from '../../components/MynyfyPre';
import WebMynyfyHeader from '../../components/WebMynyfyHeader';
import { AddCircle, KeyboardDoubleArrowRight } from '@mui/icons-material';
import LocationModal from '../Location/LocationModal';
import { s3ToCloudFront } from '../../utils/CommonMethods';

const WebShoppingLayout = () => {
  const navigate = useNavigate();

  const coupons = useSelector((state) => state.promos?.coupons?.sorted);
  const shopAndWin = useSelector((state) => state.contest?.shopAndWin);
  const location = useSelector((state) => state.location?.location);
  const user = useSelector((state) => state.user);
  const isLoggedIn = user?.isLoggedIn;

  const [isLocationModal, setIsLocationModal] = useState(false);

  const imgSize = 27;

  return (
    <Fragment>
      <div>
        <WebMynyfyHeader
          title={'EXPLORE SELLERS'}
          customElememt={() => (
            <div
              className='row cursor'
              onClick={() => window.open(process.env.REACT_APP_SELLER_WEB_HOST)}>
              <MynyfyText title={'Add Yours'} style={{ paddingRight: 5 }} />
              <AddCircle color='primary' fontSize='small' />
            </div>
          )}
        />
      </div>
      <div id='webHome'>
        {_.isEmpty(coupons) ? null : (
          <div style={{ margin: 20 }}>
            <div style={{ marginBottom: 50 }}>
              <div onClick={() => navigate('/Coupons')}>
                <MynyfyText
                  title={'Offers'}
                  h4
                  className={'cursor'}
                  style={{ margin: 10, color: '#000', letterSpacing: 1 }}
                />
              </div>
              <div className='row' style={{ gap: 20, margin: '20px 0px', overflow: 'auto' }}>
                {coupons?.slice(0, 4)?.map((couponsDetails, i) => (
                  <div
                    onClick={() => {
                      if (isLoggedIn) {
                        navigate(`/Coupons/${couponsDetails._id}`, {
                          state: { details: couponsDetails },
                        });
                      } else navigate('/SignIn');
                    }}
                    style={{ padding: '5px' }}
                    key={i}>
                    <Card style={{ width: 200, minHeight: 300 }} className='cursor'>
                      {couponsDetails.image ? (
                        <img
                          src={s3ToCloudFront(couponsDetails.image?.url)}
                          alt={couponsDetails.name}
                          style={{
                            objectFit: 'fill',
                            display: 'block',
                            height: 220,
                            width: 200,
                          }}
                        />
                      ) : (
                        <div style={{ width: 200, height: 220 }}>
                          <div
                            style={{
                              padding: 10,
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              // height: '-webkit-fill-available',
                              height: 220,
                            }}>
                            <MynyfyPre
                              title={'Get \n' + theme.RUPEE + couponsDetails.actualPrice + ' off'}
                              bold
                              h3
                            />
                            {couponsDetails.minimumOrderValue ? (
                              <MynyfyText
                                title={
                                  'on a min order of ' +
                                  theme.RUPEE +
                                  couponsDetails.minimumOrderValue
                                }
                                style={{ paddingTop: 8 }}
                              />
                            ) : null}
                          </div>
                        </div>
                      )}

                      <MynyfyText
                        title={couponsDetails.name}
                        style={{ padding: 10, textTransform: 'capitalize' }}
                        className='singleLine'
                        center
                      />

                      <div className='rowSB' style={{ padding: '0px 10px 10px' }}>
                        <MynyfyPre title={`Save\nRs ${couponsDetails.actualPrice}`} small />
                        <div style={{ textAlign: 'end' }}>
                          <MynyfyPre title={`${couponsDetails.days} days`} small />
                          <MynyfyPre title={'Left'} small />
                        </div>
                      </div>
                    </Card>
                  </div>
                ))}
                <KeyboardDoubleArrowRight
                  onClick={() => navigate('/Coupons')}
                  className='all cursor'
                />
              </div>
            </div>
          </div>
        )}

        {_.isEmpty(shopAndWin) ? null : (
          <div style={{ margin: 20 }}>
            {/* <Card className='feature'> */}
            <div>
              <div className='rowSB' onClick={() => navigate('/ShopToJoin')}>
                <MynyfyText
                  title={'Give away Contests'}
                  h4
                  style={{ margin: 10, color: '#000', letterSpacing: 1 }}
                />
                {/* <MynyfyText title={'View All >>'} bold link /> */}
              </div>
              <div className='offer'>
                {shopAndWin?.slice(0, 4)?.map((shopAndWinDetails, i) => (
                  <div
                    onClick={() => {
                      if (isLoggedIn) {
                        navigate(`/Contest/${shopAndWinDetails._id}`, {
                          state: { details: shopAndWinDetails },
                        });
                      } else navigate('/SignIn');
                    }}
                    key={i}>
                    <Card className='cursor homeContestCardNew'>
                      <img
                        src={s3ToCloudFront(shopAndWinDetails.image)}
                        alt={shopAndWinDetails.name}
                        className='contestImageNew'
                      />

                      <MynyfyText
                        title={shopAndWinDetails.name}
                        style={{ padding: 10, textTransform: 'capitalize' }}
                        className='singleLine'
                        center
                      />

                      <div className='rowSB' style={{ padding: '0px 10px 10px' }}>
                        <div>
                          <MynyfyText
                            title={
                              'Ends on ' + moment(shopAndWinDetails.endDate).format('DD MMM YYYY')
                            }
                            small
                          />
                        </div>
                        <div>
                          <MynyfyPre title={'Shop to Join '} bold center small />
                        </div>
                      </div>
                    </Card>
                  </div>
                ))}
                <KeyboardDoubleArrowRight
                  onClick={() => navigate('/ShopToJoin')}
                  className='all cursor'
                />
              </div>
            </div>
            {/* </Card> */}
          </div>
        )}

        <div className='row' style={{ margin: '50px 15px', paddingBottom: 30 }}>
          <Card
            style={{ padding: 30, flex: 1, margin: 5, borderRadius: 20 }}
            className='cursor'
            onClick={() => navigate('/OnlineStores')}>
            <div className='rowC'>
              <MynyfyText title={'SHOP ONLINE '} pBig bold style={{ textWrap: 'noWrap' }} />
              <div style={{ margin: 15 }}>
                <img
                  src={require('../../assets/imgs/LandingPage/doorDelivery.png')}
                  width={imgSize}
                  height={imgSize}
                />
              </div>
            </div>
          </Card>
          <Card
            style={{ padding: 30, flex: 1, margin: 5, borderRadius: 20 }}
            className='cursor'
            onClick={() => {
              if (location) navigate('/OfflineStores');
              else setIsLocationModal(true);
            }}>
            <div className='rowC'>
              <MynyfyText title={'SHOP OFFLINE'} pBig bold style={{ textWrap: 'noWrap' }} />
              <div style={{ margin: 15 }}>
                <img
                  src={require('../../assets/imgs/LandingPage/Localsellers.png')}
                  width={imgSize}
                  height={imgSize}
                  fill={theme.PRIMARY_BLUE}
                />
              </div>
            </div>
          </Card>
        </div>
      </div>

      <LocationModal
        isOpen={isLocationModal}
        close={(e) => {
          if (!_.isEmpty(location) || e) setIsLocationModal(false);
        }}
        closeBtn={() => setIsLocationModal(false)}
      />
    </Fragment>
  );
};

export default WebShoppingLayout;
