import _ from 'lodash';
import { objectReplace } from '../../utils/CommonMethods';
import storeService from '../../services/storeService';

export const likeStore = ({
  storeId,
  setLiked,
  profile,
  data,
  dispatch,
  action,
  userProfile,
  snackbarInfo,
}) => {
  let reqData = _.cloneDeep(data);
  setLiked(true);
  storeService
    .likeStore(storeId)
    .then((res) => {
      if (res.success) {
        let fullProfile = _.cloneDeep(profile);
        let likedStores = fullProfile?.likedSellers;
        if (likedStores) {
          if (!likedStores.includes(storeId)) {
            likedStores.push(storeId);
            fullProfile.likedSellers = likedStores;
          }
        } else {
          let likedStores = [storeId];
          fullProfile.likedSellers = likedStores;
        }
        if (!_.isEmpty(reqData)) {
          let storesData = reqData.filter((e) => e._id == storeId);
          storesData[0].likesCount = storesData[0].likesCount + 1;
          let newArr = objectReplace(reqData, storesData[0], '_id');
          dispatch(action(newArr));
        }
        dispatch(userProfile(fullProfile));
        dispatch(
          snackbarInfo({
            open: true,
            type: 'success',
            message: 'Now you are following us on Mynyfy App',
          })
        );
      } else {
        dispatch(snackbarInfo({ open: true, type: 'error', message: res.message }));
      }
    })
    .finally(() => setLiked(false));
};

export const unlikeStore = ({
  storeId,
  setLiked,
  profile,
  data,
  dispatch,
  action,
  userProfile,
  snackbarInfo,
}) => {
  let reqData = _.cloneDeep(data);
  let clonedProfile = _.cloneDeep(profile);
  setLiked(true);
  storeService
    .unlikeStore(storeId)
    .then((res) => {
      if (res.success) {
        let likedStores = _.cloneDeep(clonedProfile?.likedSellers);
        if (likedStores.includes(storeId)) {
          clonedProfile.likedSellers = likedStores.filter((e) => e !== storeId);
        }
        if (!_.isEmpty(reqData)) {
          let storesData = reqData.filter((e) => e._id == storeId);
          storesData[0].likesCount = storesData[0].likesCount - 1;
          let newArr = objectReplace(reqData, storesData[0], '_id');
          dispatch(action(newArr));
        }
        dispatch(userProfile(clonedProfile));
        dispatch(snackbarInfo({ open: true, type: 'success', message: res.data }));
      } else {
        dispatch(snackbarInfo({ open: true, type: 'error', message: res.message }));
      }
    })
    .finally(() => setLiked(false));
};
