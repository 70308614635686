export const SyncProfile = {
  BUYER: 'Buyer',
  SELLER: 'Seller',
  PARTNER: 'Partner',
  BUYER_SELLER: 'Buyer Seller', //Buyer & Seller
  BUYER_PARTNER: 'Buyer Partner', // Buyer & Partner
};

export const PromoType = {
  COUPON: 'COUPON',
  POINT_COUPON: 'POINT_COUPON',
  DEAL: 'DEAL',
  REWARD: 'REWARD',
  GROUP_DEAL: 'GROUP_DEAL',
  DAY_DEAL: 'DAY_DEAL',
  ADMIN_OFFERS: 'ADMIN_OFFERS',
};

export const EnquiryStatus = {
  INITIATED: 'Initiated',
  CREATED: 'Created',
  ACTIVE: 'Active',
  WAITING: 'Waiting',
  ACCEPTED: 'Accepted',
  CONFIRMED: 'Confirmed',
  COMPLETED: 'Completed',
  CANCELLED: 'Cancelled',
  ENQUIRY_EXPIRED: 'Order Expired',
  GROUP_EXPIRED: 'Group Expired',
  GROUP_INITIATED: 'Group Initiated', // Initiated
  SUBMITTED: 'Submitted', // Created
  WAITING_FOR_ACCEPTANCE: 'Waiting for Acceptance', // Waiting
  TIME_ELAPSED: 'Time Elapsed', // Time Expired
  GROUP_TIME_ELAPSED: 'Group Time Elapsed', //  Grp Time expired
  ENQUIRY_TIME_ELAPSED: 'Order Time Elapsed', // Enquiry Time expired
  SELLER_RESPONDED: 'Seller Responded',
};

export const OrderStatus = {
  PENDING: 'PENDING',
  IN_PROGRESS: 'IN PROGRESS',
  ACTIVE: 'ACTIVE',
  COMPLETED: 'COMPLETED',
  CANCELLED: 'CANCELLED',
  REFUND_REQUESTED: 'REFUND REQUESTED',
  REFUND_COMPLETED: 'REFUND COMPLETED',
};

export const OrderDeliveryStatus = {
  IN_PROGRESS: 'IN PROGRESS',
  ACCEPTED: 'ACCEPTED',
  SHIPPED: 'SHIPPED',
  PICK_UP_READY: 'PICK UP READY',
  DELIVERED: 'DELIVERED',
  RETURNED: 'RETURNED',
  CANCELLED: 'CANCELLED',
};

export const FEATURE_TYPES = {
  CONTESTS: 'CONTESTS',
  BRANDS: 'BRANDS',
  STORES: 'STORES',
  PROMOS: 'PROMOS',
  POINT_COUPONS: 'POINT_COUPONS',
};

export const ContestType = {
  SHOP_AND_WIN: 'SHOP_AND_WIN',
  SHOP_AND_SCAN: 'SHOP_AND_SCAN',
  SEE_AND_WIN: 'SEE_AND_WIN',
  SCAN_AND_WIN: 'SCAN_AND_WIN',
  SCROLL_AND_WIN: 'SCROLL_AND_WIN',
  JACKPOT: 'JACKPOT',
};

export const ContestStatus = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  COMPLETED: 'COMPLETED',
  PENDING: 'PENDING',
  NOT_APPROVED: 'NOTAPPROVED',
  REJECTED: 'REJECTED',
};

export const TransactionStatus = {
  SUCCESS: 'Success',
  FAILED: 'Failed',
  INITIATED: 'Initiated',
  PENDING: 'Pending',
  REFUNDED: 'Refunded',
  REFUND_FAILED: 'Refund Failed',
};

// Do not Change or add types in the Transaction Type enums without consulting backend team strictly
export const TransactionType = {
  LUCKY_DRAW: 'LuckyDraw',
  PROMO: 'Promo',
  PRODUCT_ORDER: 'ProductOrder',
  FLASH_AD: 'Opportunity',
};

export const PaymentMode = {
  CASH: 'CASH',
  ONLINE: 'ONLINE',
  RAZOR_PAY: 'RAZOR_PAY',
  UPI: 'UPI',
  UPI_QR: 'UPI_QR',
  SAB_PAISA: 'SAB_PAISA',
};

export const PaymentGateways = {
  RAZOR_PAY: 'RAZOR_PAY',
  PHONE_PE: 'PHONE_PE',
  SAB_PAISA: 'SAB_PAISA',
};

export const SortTypes = {
  LOW_TO_HIGH_MYNTS: 'Low to high Mynts',
  HIGH_TO_LOW_MYNTS: 'High to low Mynts',
  LOW_TO_HIGH_PRICE: 'Low to high Price',
  HIGH_TO_LOW_PRICE: 'High to low Price',
  ONLY_VEG: 'Only Vegetarian',
  ONLY_NON_VEG: 'Only Non-Vegetarian',
  IN_STOCK: 'In Stock',
  OUT_OF_STOCK: 'Out of Stock',
  ALL: 'All',
};

export const ProductMaterialType = {
  VEGETARIAN: 'VEGETARIAN',
  NON_VEGETARIAN: 'NON-VEGETARIAN',
};

export const OrderType = {
  TAKE_AWAY: 'Take Away',
  DINE_IN: 'Dine In',
  HOME_DELIVERY: 'Home Delivery',
};

export const PaymentData = {
  NAME: 'MYNYFY INDIA PVT LTD',
  LOGO: 'https://mynyfy-prod-user.s3.ap-south-1.amazonaws.com/CommonImages/acHGXaxf7kw2Ku91ban194.jpeg',
  CURRENCY: 'INR',
};

export const OpportunityType = {
  BUSINESS_OPPORTUNITY: 'BUSINESS_OPPORTUNITY',
  JOB_OPPORTUNITY: 'JOB_OPPORTUNITY',
  ANNOUNCEMENT: 'ANNOUNCEMENT',
  ONLINE_DEALS: 'ONLINE_DEALS',
  ADMIN_OFFERS: 'ADMIN_OFFERS',
};

export const LuckyDrawStatus = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  COMPLETED: 'COMPLETED',
  PENDING: 'PENDING',
  NOT_APPROVED: 'NOTAPPROVED',
  REJECTED: 'REJECTED',
};

export const CustomerSubscriptionType = {
  PASS: 'Pass',
  PACK: 'Pack',
};

export const ProductType = {
  PRODUCT: 'product',
  SERVICE: 'service',
};

export const SocketEvent = {
  CONNECT: 'connect',
  DISCONNECT: 'disconnect',
  NEW_NOTIFICATION: 'newNotification',
  NEW_ORDER: 'newProductOrder',
  ORDER_UPDATED: 'orderUpdated',
  RECONNECT: 'reconnect',
  RECONNECT_ATTEMPT: 'reconnect_attempt',
  RECONNECT_ERROR: 'reconnect_error',
  RECONNECT_FAILED: 'reconnect_failed',
  ERROR: 'error',
};
