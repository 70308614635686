import React from 'react';
import '../promos.scss';
import { PromoType } from '../../../utils/enums';
import WebPromos from '../../Promos/WebPromos.jsx';
import { useSelector } from 'react-redux';
import { fetchDayDeals, fetchSortedDayDeals } from '../../../redux/actions/promo.action';

const WebDayDeal = ({ goHome }) => {
  const promos = useSelector((state) => state.promos?.dayDeals?.actual);
  const sortedPromos = useSelector((state) => state.promos?.dayDeals?.sorted);

  return (
    <WebPromos
      type={PromoType.DAY_DEAL}
      isFilter={true}
      screen={'Deals'}
      emptyListTitle={'Deals'}
      promos={promos}
      sortedPromos={sortedPromos}
      action={fetchDayDeals}
      sortedAction={fetchSortedDayDeals}
      goHome={goHome}
    />
  );
};

export default WebDayDeal;
