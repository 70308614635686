import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import opportunityService from '../../services/opportunityService';
import MynyfyModal from '../../components/MynyfyModal';
import { useNavigate } from 'react-router';
import {
  CallRounded,
  HighlightOffRounded,
  LanguageRounded,
  ShopRounded,
} from '@mui/icons-material';
import { seenFlashAds } from '../../redux/actions/flashAd.action';
import _ from 'lodash';
import './flashAds.scss';
import { flashAdSeen } from '../../redux/actions/flashAdSeen.action';
import { getOS, s3ToCloudFront } from '../../utils/CommonMethods';

const FlashAds = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const os = getOS();
  const platform = os === 'android' || os === 'ios' ? 'mobile' : 'desktop';

  const location = useSelector((state) => state.location?.location);
  const flashAdsData = useSelector((state) => state.flashAds);
  const isFlashAdSeen = useSelector((state) => state.isFlashAdSeen);

  const [flashAd, setFlashAd] = useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (isFlashAdSeen) {
      //  Already Seen the flash ad for this session
    } else {
      if (!_.isEmpty(flashAdsData.flashAds)) {
        if (_.isEmpty(flashAdsData.seenAds)) {
          getFlashAd(flashAdsData.flashAds);
        } else {
          let reqFlashAds = [];
          flashAdsData.flashAds.map((e) => {
            if (!flashAdsData.seenAds.includes(e._id)) {
              reqFlashAds.push(e);
            }
          });
          if (!_.isEmpty(reqFlashAds)) {
            getFlashAd(reqFlashAds);
          } else {
            getFlashAd(flashAdsData.flashAds, true);
          }
        }
      }
    }
  }, []);

  const getFlashAd = (ads, isRepeat) => {
    setFlashAd(ads[0]);
    viewFlashAd(ads[0]._id);
    setOpen(true);
    dispatch(flashAdSeen(true));
    if (isRepeat) {
      dispatch(seenFlashAds([ads[0]._id]));
    } else {
      let seeAds = _.cloneDeep(flashAdsData.seenAds);
      seeAds.push(ads[0]._id);
      dispatch(seenFlashAds(seeAds));
    }
  };

  const viewFlashAd = (id) => {
    let data = {};
    if (location) {
      if (location?.state) {
        data.state = location?.state?._id;
      }
      if (location?.city) {
        data.city = location?.city?._id;
      }
      if (location?.area) {
        data.area = location?.area?._id;
      }
    }
    opportunityService.viewOpportunity(id, data);
  };

  const websiteClicks = (id) => {
    opportunityService.websiteClicks(id).then((e) => console.log(e));
  };

  const closeFlashAd = () => {
    if (flashAd.screenName) {
      if (flashAd.serviceId) {
        if (['Jackpot', 'ShopAndWin'].includes(flashAd.screenName)) {
          navigate('/Contest/' + flashAd.serviceId);
        } else if (flashAd.screenName == 'Brands') {
          navigate('/Brands/' + flashAd.serviceId);
        } else if (
          [
            'OnlineStores',
            'OfflineStores',
            'HomeDeliveryRestaurants',
            'TakeAwayRestaurants',
          ].includes(flashAd.screenName)
        ) {
          navigate('/Stores/' + flashAd.serviceId);
        }
      } else {
        if (flashAd.screenName == 'SeeAndWin') {
          navigate('/Posts');
        } else if (flashAd.screenName == 'Jackpot') {
          navigate('/Jackpots');
        } else if (flashAd.screenName == 'ShopAndWin') {
          navigate('/ShopToJoin');
        } else if (flashAd.screenName == 'Deal of the Day') {
          navigate('/Deals');
        } else if (flashAd.screenName) {
          navigate('/' + flashAd.screenName);
        }
      }
      setOpen(false);
    } else {
      setOpen(false);
    }
  };

  return flashAd ? (
    <MynyfyModal
      open={open}
      position={'center'}
      close={() => setOpen(false)}
      style={{ padding: 0, background: 'none' }}>
      <div id='flashAd'>
        <img src={s3ToCloudFront(flashAd.image.url)} className='img' device={platform} />
        <HighlightOffRounded className='closeBtn cursor' onClick={() => setOpen(false)} />
        {flashAd.websiteUrl || flashAd.appUrl || flashAd.mobileNumber || flashAd.screenName ? (
          <div className='rowC btnContainer'>
            {flashAd.websiteUrl ? (
              <div
                className='iconContainer cursor'
                onClick={() => {
                  websiteClicks(flashAd._id);
                  setOpen(false);
                  window.open(flashAd.websiteUrl);
                }}>
                <LanguageRounded className='callBtn' />
              </div>
            ) : null}
            {flashAd.appUrl ? (
              <div
                className='iconContainer cursor'
                onClick={() => {
                  websiteClicks(flashAd._id);
                  setOpen(false);
                  window.open(flashAd.appUrl);
                }}>
                <ShopRounded className='callBtn' />
              </div>
            ) : null}
            {flashAd.mobileNumber ? (
              <div
                className='iconContainer cursor'
                onClick={() => window.open('tel:' + flashAd.mobileNumber)}>
                <CallRounded className='callBtn' />
              </div>
            ) : null}
            {flashAd.screenName ? (
              <div className='iconContainer cursor' onClick={() => closeFlashAd()}>
                <img
                  src={require('../../assets/imgs/LandingPage/localStoresWhite.png')}
                  width={24}
                  height={24}
                  style={{ display: 'block' }}
                />
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    </MynyfyModal>
  ) : null;
};

export default FlashAds;
