import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { fetchOfflineFoodStores } from '../../redux/actions/store.action';
import StoresList from './StoresList';
import MynyfyHeader from '../../components/MynyfyHeader';

const OfflineFoodStores = () => {
  const offlineFoodStores = useSelector((state) => state.stores?.offlineFoodStores);

  return (
    <Fragment>
      <MynyfyHeader title={'Take Away Only'} />
      <StoresList
        isFav={false}
        offline={true}
        data={offlineFoodStores}
        action={fetchOfflineFoodStores}
        isRestaurant={true}
        emptyListMsg={[
          'We are on boarding Take Away restaurants in your area',
          'Soon they will be available',
        ]}
      />
    </Fragment>
  );
};
export default OfflineFoodStores;
