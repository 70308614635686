import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import contestService from '../../services/contestService';
import { ContestType } from '../../utils/enums';
import theme from '../../utils/theme';
import './contest.scss';
import JackpotFooter from './Jackpot/JackpotFooter';
import ShopAndWinFooter from './ShopAndWin/ShopAndWinFooter';
import ScanAndWinFooter from './ScanAndWin/ScanAndWinFooter';
import SeeWinIcon from '../../assets/svg/see_and_win.svg';
import ScanAndWinIcon from '../../assets/svg/scan_and_win.svg';
import ReferComponent from '../../components/ReferComponent';
import { contestDetailsOptions } from './ContestHelper';
import MynyfyText from '../../components/MynyfyText';
import ContestTab from './ContestTab';
import ContestHalfCardList from './ContestHalfCardList';

const Contest = ({
  type,
  status,
  action,
  draws,
  isCategories,
  isScan,
  isHistory,
  from,
  emptyListTitle,
  emptyListMsg,
  brandDetails,
  sellerDetails,
  isRestaurant,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState('Active');

  const user = useSelector((state) => state.user);
  const id = user.user?.id || null;
  const isLoggedIn = user?.isLoggedIn;
  const location = useSelector((state) => state?.location?.location);

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isRefreshing, setRefresh] = useState(false);
  const [isRefer, setIsRefer] = useState(false);
  const [sortedDraws, setSortedDraws] = useState(null);

  useEffect(() => {
    if (user && type && _.isEmpty(draws)) {
      getContests();
    } else {
      setRefresh(false);
      setIsLoading(false);
    }
  }, [user, type, draws]);

  const getContests = () => {
    let params = {
      status: status,
      isCats: isCategories,
      type: type,
    };
    if (!_.isEmpty(location)) {
      if (location?.state?._id) params.state = location?.state?._id;
      if (location?.city?._id) params.city = location?.city?._id;
      if (location?.area?._id) params.area = location?.area?._id;
    }
    if (from === 'brand') params.brand = brandDetails?._id;
    if (from === 'seller') {
      params.orgId = sellerDetails?._id;
      if (!_.isEmpty(sellerDetails?.brands)) {
        params.brand = sellerDetails?.brands?.map((e) => e._id);
      }
    }
    if (isLoggedIn) params.buyerId = id;
    if (type === ContestType.SHOP_AND_WIN && !from) {
      if (isRestaurant === true) {
        params.category = [process.env.REACT_APP_FOOD_CAT_ID];
      } else if (isRestaurant === false) {
        params.notCategory = [process.env.REACT_APP_FOOD_CAT_ID];
      }
    }
    contestService
      .getContests(params)
      .then((res) => {
        if (res.success) {
          let sorted = _.orderBy(res.data, 'createdAt', 'desc');
          contestDataHandler(sorted);
        } else {
          contestDataHandler([]);
        }
      })
      .finally(() => {
        setRefresh(false);
        setIsLoading(false);
      });
  };

  const contestDataHandler = (contestData) => {
    if (action) {
      dispatch(action(contestData));
    } else {
      setSortedDraws(contestData);
    }
  };

  useEffect(() => {
    if (selectedCategory) {
      let filtered = [];
      draws.map((res) => {
        if (_.isEmpty(res.category)) {
          filtered.push(res);
        } else {
          let isCat = res.category.filter((e) => e._id === selectedCategory);
          if (!_.isEmpty(isCat)) {
            filtered.push(res);
          }
        }
      });
    }
  }, [selectedCategory, draws]);

  const onRefresh = () => {
    setRefresh(true);
    getContests();
  };

  const detailsScreenHandler = (item) => {
    let options = contestDetailsOptions(item.type, isHistory);
    if (item.type === ContestType.SEE_AND_WIN) {
      navigate('/SeeAndWinDetails', { state: { drawDetails: item } });
    } else {
      navigate('/Contest/' + item._id, {
        state: { drawDetails: item, detailOptions: options, isRestaurant, sellerDetails },
      });
    }
  };

  const customComponent = (item, isSingle) => (
    <Fragment>
      {item.type === ContestType.SEE_AND_WIN ? (
        <div className='footer'>
          <SeeWinIcon fill={theme.PRIMARY_BLUE} width={16} height={16} />
        </div>
      ) : item.type === ContestType.SHOP_AND_WIN ? (
        <ShopAndWinFooter
          item={item}
          isSingle={isSingle}
          isHistory={isHistory}
          onDetailsClick={() => detailsScreenHandler(item)}
          isRestaurant={isRestaurant}
        />
      ) : item.type === ContestType.SCAN_AND_WIN || item.type === ContestType.SHOP_AND_SCAN ? (
        <ScanAndWinFooter
          item={item}
          isSingle={isSingle}
          isHistory={isHistory}
          onDetailsClick={() => detailsScreenHandler(item)}
        />
      ) : item.type === ContestType.JACKPOT ? (
        <JackpotFooter
          item={item}
          isSingle={isSingle}
          isHistory={isHistory}
          isRefer={(e) => setIsRefer(e)}
          onDetailsClick={() => detailsScreenHandler(item)}
        />
      ) : null}
    </Fragment>
  );

  return (
    <div id='Contest'>
      {isCategories ? <MynyfyText title='Categories' /> : null}
      {isScan ? (
        <div
          style={{ alignItems: 'center', alignSelf: 'center', paddingTop: 5 }}
          onClick={() => navigate('/Enter Scan & Win Contest')}>
          <ScanAndWinIcon fill={theme.PRIMARY_BLUE} width={35} height={35} />
          <MynyfyText title={'Scan to join'} link small bold style={{ paddingBottom: 10 }} />
        </div>
      ) : null}
      {!isHistory ? (
        <ContestHalfCardList
          isLoading={isLoading}
          data={action ? draws : sortedDraws}
          emptyListTitle={emptyListTitle}
          emptyListMsg={emptyListMsg}
          onRefresh={onRefresh}
          refreshing={isRefreshing}
          onClick={detailsScreenHandler}
          customComponent={(item) => customComponent(item, false)}
        />
      ) : (
        <ContestTab
          isLoading={isLoading}
          data={action ? draws : sortedDraws}
          action={action}
          emptyListTitle={`${emptyListTitle} contest`}
          emptyListMsg={emptyListMsg}
          onRefresh={onRefresh}
          refreshing={isRefreshing}
          isHistory={isHistory}
          onClick={detailsScreenHandler}
          customComponent={(item) => customComponent(item, true)}
          customFooter={() => (isRefer ? <ReferComponent /> : null)}
          activeTab={activeTab}
        />
      )}
    </div>
  );
};

export default Contest;
