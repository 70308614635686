import React, { Fragment, useEffect, useState } from 'react';
import MynyfyText from '../../components/MynyfyText';
import { useDispatch, useSelector } from 'react-redux';
import theme from '../../utils/theme';
import { Card } from '@mui/material';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import MynyfyPre from '../../components/MynyfyPre';
import PrivacyPolicy from '../Policies/PrivacyPolicy';
import LocationModal from '../Location/LocationModal';
import MynyfyAlert from '../../components/MynyfyAlert';
import { Category, KeyboardArrowRight, SearchOutlined } from '@mui/icons-material';
import MynyfySpan from '../../components/MynyfySpan';
import DealsLayout from './DealsLayout';
import { onShare } from '../Refer/referHelper';
import VisitedStores from '../Stores/VisitedStores';
import { fetchActualBrands, fetchBrands } from '../../redux/actions/brand.action';
import brandService from '../../services/brandService';
import { snackbarInfo } from '../../redux/actions/snackbar.action';
import MynyfyInput from '../../components/MynyfyInput';
import MynyfySelect from '../../components/MynyfySelect';
import MynyfyLoader from '../../components/MynfyfLoader';
import MynyfyEmptyMsg from '../../components/MynyfyEmptyMsg';
import MynyfyDoubleCard from '../../components/MynyfyDoubleCard';

const HomeLayout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const isLoggedIn = user?.isLoggedIn;
  const profile = user.profile;
  const location = useSelector((state) => state.location?.location);
  const mynyfyStats = useSelector((state) => state.mynyfyStats);
  const brands = useSelector((state) => state.brands?.brands);
  const actualBrands = useSelector((state) => state.brands?.actualBrands);
  const categories = useSelector((state) => state.appData?.categories);
  const [filterType, setFilterType] = useState('search');
  const [search, setSearch] = useState('');
  const [category, setCategory] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [isLocationModal, setIsLocationModal] = useState(false);
  const [openLocationAlert, setOpenLocationAlert] = useState(false);

  const imgSize = 27;

  useEffect(() => {
    const urlHash = window.location.hash;
    if (urlHash.length && urlHash === '#PrivacyPolicy') {
      const element = document.getElementById(urlHash.substring(1));
      if (element) {
        element.scrollIntoView();
      }
    }

    if (_.isEmpty(brands) || brands?.length !== actualBrands?.length) {
      getBrands({ initialCall: true });
    }
  }, []);

  const getBrands = ({ searchText, selectedCat, initialCall }) => {
    setIsLoading(true);
    let params = { isActive: true };
    if (location) {
      params.state = location?.state?._id;
      params.city = location?.city?._id;
      params.area = location?.area?._id;
    }
    if (searchText) params.q = searchText;
    if (selectedCat) params.category = selectedCat.value;

    brandService
      .getBrands(params)
      .then((res) => {
        if (res.success) {
          dispatch(fetchBrands(res.data));
          if (initialCall) dispatch(fetchActualBrands(res.data));
        } else {
          dispatch(fetchBrands([]));
          dispatch(fetchActualBrands([]));
          dispatch(snackbarInfo({ type: 'error', open: true, message: res.message }));
        }
      })
      .finally(() => setIsLoading(false));
  };

  const navHandler = (item) => {
    navigate('/Brands/' + item.urlId, { state: { brandDetails: item } });
  };

  return (
    <div>
      <Fragment>
        <div className='rowSB' style={{ margin: '15px 20px 0px' }}>
          {mynyfyStats?.buyers ? (
            <div>
              <MynyfySpan title={mynyfyStats?.buyers} bold />
              &nbsp;
              <MynyfySpan title={'Mynyfians!'} />
            </div>
          ) : (
            <MynyfyText title={' '} />
          )}
          <div
            className='row cursor'
            style={{ justifyContent: 'flex-end', flexWrap: 'wrap', paddingLeft: 10 }}
            onClick={() => setIsLocationModal(true)}>
            <MynyfyText
              title={_.isEmpty(location) ? 'Set Location' : 'Location:'}
              color={_.isEmpty(location) ? theme.PRIMARY_BLUE : theme.MUTED_BLUE}
              pBig
              style={{ whiteSpace: 'nowrap' }}
            />
            <MynyfyText
              title={location?.area?.name}
              color={theme.NAVY_BLUE}
              pBig
              style={{ paddingLeft: 4 }}
            />
          </div>
        </div>
        <div className='divider' style={{ marginInline: 10 }} />
      </Fragment>

      <div style={{ marginInline: 12 }}>
        <MynyfyText title={'Explore Brands'} h3 bold />
        <div className='row' style={{ gap: 10, marginLeft: -5, paddingBlock: 10 }}>
          {filterType == 'search' ? (
            <MynyfyInput
              placeHolder='Search Brand'
              value={search}
              onChange={(e) => {
                let value = e.target.value;
                setSearch(value);
                if (value?.length > 2 || !value?.length) {
                  getBrands({ searchText: value || null, selectedCat: category || null });
                }
              }}
              style={{ margin: '5px 4px 4px' }}
              fullWidth={true}
              rest={{ sx: { '& .MuiOutlinedInput-root': { borderRadius: 20 } } }}
            />
          ) : filterType == 'cat' ? (
            <div style={{ width: '100%' }}>
              <MynyfySelect
                placeholder='Select Category'
                value={category}
                onChange={(e) => {
                  setCategory(e);
                  getBrands({ selectedCat: e || null, searchText: search || null });
                }}
                list={categories?.map((e) => ({ label: e.name, value: e._id, data: e.data }))}
                controlStyles={{ borderRadius: 50 }}
                isClear
              />
            </div>
          ) : null}

          <SearchOutlined
            color={filterType == 'search' ? 'primary' : 'disabled'}
            onClick={() => setFilterType('search')}
          />

          <Category
            color={filterType == 'cat' ? 'primary' : 'disabled'}
            onClick={() => setFilterType('cat')}
          />
        </div>
      </div>

      {!brands || isLoading ? (
        <MynyfyLoader />
      ) : _.isEmpty(brands) ? (
        <MynyfyEmptyMsg message={'No Brands are available'} />
      ) : (
        <MynyfyDoubleCard data={brands} onClick={(item) => navHandler(item)} footer={(e) => {}} />
      )}

      {/* <DealsLayout noHeader /> */}

      {isLoggedIn ? (
        <Fragment>
          <MynyfyText
            title={'Recently Visited Stores'}
            h3
            bold
            style={{ padding: '15px 20px 0px', marginBottom: -20 }}
          />
          <VisitedStores />

          <div className='rowC'>
            <Card
              style={{
                height: '180px',
                width: '90%',
                maxWidth: '450px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                borderRadius: '8px',
              }}
              onClick={() => onShare(profile?.referralCode)}>
              <img
                src={require('../../assets/imgs/refer.png')}
                style={{ height: '180px', width: '100%', objectFit: 'cover', maxWidth: '450px' }}
                alt='reference Image'
              />
            </Card>
          </div>
        </Fragment>
      ) : null}

      {/* <div>
        <Card
          style={{ padding: '20px', margin: '20px 25px', borderRadius: '20px' }}
          className='cursor'
          onClick={() => navigate('/Shopping')}>
          <MynyfyText title={'EXPLORE SELLERS'} pBig bold />
          <div className='rowSB'>
            <div>
              <MynyfyPre title={`Explore offers,\ncontests & sellers.`} pBig />
            </div>
            <div>
              <img
                src={require('../../assets/imgs/onlineshopping.png')}
                alt='Stores'
                className='storeTypeBanner'
              />
            </div>
          </div>
        </Card>
        <Card
          style={{ padding: '20px', margin: '20px 25px', borderRadius: '20px' }}
          className='cursor'
          onClick={() => navigate('/Restaurants')}>
          <MynyfyText title={'EXPLORE FOOD'} pBig bold />
          <div className='rowSB'>
            <div>
              <MynyfyPre
                title={`Explore offers,take away,\nDine In, and home\ndelivery options\nin your area.`}
                pBig
              />
            </div>
            <div>
              <img
                src={require('../../assets/imgs/pizza.jpg')}
                alt='Food'
                className='storeTypeBanner'
              />
            </div>
          </div>
        </Card>
      </div> */}

      {isLoggedIn ? (
        <div style={{ paddingBottom: 30 }} />
      ) : (
        <Fragment>
          <div className='policyContainer' id='PrivacyPolicy'>
            <Card
              className='feature rowSB'
              style={{ backgroundColor: theme.PRIMARY_BLUE }}
              onClick={() => navigate('/Policies')}>
              <MynyfyText title={'Mynyfy Policies'} color={'#fff'} semiBold />
              <KeyboardArrowRight style={{ color: '#fff' }} />
            </Card>
          </div>
          {window?.location?.hash === '#PrivacyPolicy' ? (
            <div>
              <PrivacyPolicy
                customHeader={() => (
                  <MynyfyText title={'Privacy Policy'} h4 bold style={{ marginLeft: 20 }} />
                )}
              />
            </div>
          ) : null}
        </Fragment>
      )}

      <LocationModal
        isOpen={isLocationModal}
        close={(e) => {
          if (!_.isEmpty(location) || e) setIsLocationModal(false);
        }}
        closeBtn={() => setIsLocationModal(false)}
      />

      <MynyfyAlert
        open={openLocationAlert}
        close={() => {}}
        content={'Please set your location to get personalized data'}
        action1={{
          label: 'Set Location',
          handler: () => {
            setOpenLocationAlert(false);
            setIsLocationModal(true);
          },
        }}
      />
    </div>
  );
};

export default HomeLayout;
