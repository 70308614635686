import { Card, Checkbox } from '@mui/material';
import React, { Fragment, useEffect, useState } from 'react';
import MynyfyText from '../../components/MynyfyText';
import { useNavigate, useSearchParams } from 'react-router-dom';
import contestService from '../../services/contestService';
import { snackbarInfo } from '../../redux/actions/snackbar.action';
import { useDispatch, useSelector } from 'react-redux';
import './joinByScan.scss';
import MynyfySpan from '../../components/MynyfySpan';
import { CircleRounded } from '@mui/icons-material';
import MynyfyHeader from '../../components/MynyfyHeader';
import moment from 'moment';
import MynyfyButton from '../../components/MynyfyButton';
import MynyfyInput from '../../components/MynyfyInput';
import validate from '../../utils/validate';
import { locationValidator, profileValidator, registrationValidator } from '../../utils/validators';
import profileService from '../../services/profileService';
import _ from 'lodash';
import { userLogin, userToken } from '../../redux/actions/user.action';
import { WATCH_PROFILE } from '../../redux/actionTypes';
import theme from '../../utils/theme';
import MynyfyAlert from '../../components/MynyfyAlert';
import authService from '../../services/authService';
import { SyncProfile } from '../../utils/enums';
import { MsgEnums } from '../../utils/MsgEnums';
import jwtDecode from 'jwt-decode';
import { setAuthToken } from '../../utils/apiHelper';
import MynyfyClaimed from '../../components/MynyfyClaimed';
import LocationFields from '../Location/LocationFields';
import { updateLocation } from '../../redux/actions/location.action';
import storeService from '../../services/storeService';
import { s3ToCloudFront } from '../../utils/CommonMethods';

const JoinByScan = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const user = useSelector((state) => state.user);
  const isLoggedIn = user?.isLoggedIn;
  const userId = user?.user?.id;
  const location = useSelector((state) => state.location?.location);

  const [contest, setContest] = useState(null);
  const [isContestTerms, setIsContestTerms] = useState(false);
  const [isActive, setActive] = useState(false);
  const [firstName, setFirstName] = useState(null);
  const [email, setEmail] = useState(null);
  const [mobile, setMobile] = useState('');
  const [password, setPassword] = useState('');
  const [otp, setOTP] = useState('');
  const [isOtpSent, setOtpSent] = useState(false);
  const [isTerms, setIsTerms] = useState(false);
  const [isClicked, setClicked] = useState(false);
  const [error, setError] = useState({});
  const [submitCount, setSubmitCount] = useState(0);
  const [isOpen, setOpen] = useState(false);
  const [isProfile, setIsProfile] = useState(null);
  const [step, setStep] = useState('verify');
  const [isJoined, setJoined] = useState(false);
  const [coupon, setCoupon] = useState(null);
  const [isOpenAnimation, setOpenAnimation] = useState(false);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedArea, setSelectedArea] = useState(null);

  useEffect(() => {
    let code = searchParams.get('code');
    let contestId = searchParams.get('id');
    if (code) {
      getContest(code, contestId);
    }
  }, []);

  const getContest = (code, contestId, buyerId) => {
    let params = { code: code };
    if (contestId) {
      params.contestId = contestId;
    }
    if (isLoggedIn || buyerId) {
      params.buyerId = userId || buyerId;
    }
    contestService.getContestByVoucher(params).then((res) => {
      if (res.success) {
        let isExpired = moment(res.data?.endDate).isBefore();
        if (isExpired || res.data?.isDelete || res.data?.status !== 'ACTIVE') {
          setActive(false);
          let message = isExpired
            ? 'This contest is expired'
            : res.data?.isDelete || res.data?.status
            ? 'This contest is not available at this time'
            : '';
          snackbarHandler({ type: 'error', message: message });
          navigate('/');
        } else {
          setActive(true);
          setContest(res.data);
          setJoined(res.data?.isContested || false);
          setCoupon(res.data?.coupon || null);
        }
      } else {
        // navigate('/');
        snackbarHandler({ type: 'error', message: res.message });
      }
    });
  };

  const checkProfile = async () => {
    setClicked(true);
    let body = { mobile };
    let errors = validate(body, registrationValidator);
    setError(errors);

    if (_.isEmpty(errors)) {
      await authService
        .profileExist(mobile)
        .then((res) => {
          if (res.profile === null) {
            setOTP('');
            sendOtp();
          } else if (
            res.profile.syncedWith === SyncProfile.BUYER ||
            res.profile.syncedWith === SyncProfile.BUYER_SELLER ||
            res.profile.syncedWith === SyncProfile.BUYER_PARTNER
          ) {
            setStep('login');
            setIsProfile(true);
          } else if (
            res.profile.syncedWith === SyncProfile.PARTNER ||
            res.profile.syncedWith === SyncProfile.SELLER
          ) {
            snackbarHandler({
              type: 'warning',
              message: [
                `This mobile number is registed with ${res.profile.syncedWith}`,
                'Account Sync feature will be comming soon',
                'Please SignUp with new number',
              ],
            });
          }
        })
        .finally(() => setClicked(false));
    } else {
      snackbarHandler({ type: 'warning', message: MsgEnums.FILL_DETAILS });
    }
  };

  const sendOtp = () => {
    let body = { mobile };
    let errors = validate(body, registrationValidator);
    setError(errors);

    if (_.isEmpty(errors)) {
      authService.sendOtp(mobile).then((res) => {
        if (res) {
          setOtpSent(true);
          setStep('register');
          setIsProfile(false);
          snackbarHandler({ type: 'success', message: 'OTP has been sent successfully' });
        } else {
          snackbarHandler({ type: 'error', message: 'Failed to send OTP' });
        }
      });
    } else {
      snackbarHandler({ type: 'warning', message: MsgEnums.FILL_DETAILS });
    }
  };

  const registerHandler = async () => {
    let obj = { mobile, otp, isTerms, firstName };
    // if (_.isEmpty(location?.state)) {
    //   obj.selectedState = selectedState;
    //   obj.selectedCity = selectedCity;
    //   obj.selectedArea = selectedArea;
    // }
    if (email) {
      obj.email = email;
    }
    let errors = validate(obj, {
      ...registrationValidator,
      ...profileValidator,
      // ...locationValidator,
    });
    setError(errors);
    setClicked(true);
    if (_.isEmpty(errors)) {
      setSubmitCount(submitCount + 1);
      if (submitCount > 2) {
        setClicked(false);
        setOpen(true);
      } else {
        let body = {
          profile: { mobile: mobile, password: mobile },
          otp: otp,
        };
        await authService
          .register(body)
          .then((res) => {
            if (res?.data?.message === 'Profile Created Successfully') {
              loginHandler('register');
            } else if (res?.data?.message === 'OTP Verification Failure.') {
              snackbarHandler({
                type: 'error',
                message: ['OTP Verification Failed', 'please enter correct OTP'],
              });
            } else {
              snackbarHandler({ type: 'error', message: res?.data?.message });
            }
          })
          .catch((err) => {
            snackbarHandler({
              type: 'error',
              message: [MsgEnums.SERVER_ISSUE, MsgEnums.TRY_AGAIN_LATER],
            });
          });
      }
    } else {
      snackbarHandler({ type: 'warning', message: MsgEnums.FILL_DETAILS });
    }
  };

  const loginHandler = (from) => {
    let body = { mobile, password };
    let errObj = { mobile, password };
    if (from === 'register') {
      body.password = mobile;
      delete errObj.password;
    }

    // if (_.isEmpty(location?.state)) {
    //   errObj.selectedState = selectedState;
    //   errObj.selectedCity = selectedCity;
    //   errObj.selectedArea = selectedArea;
    // }
    let errors = validate(errObj, { ...registrationValidator });
    setError(errors);
    setClicked(true);
    if (_.isEmpty(errors)) {
      authService
        .login(body)
        .then(async (res) => {
          if (res?.data?.message === 'Authenticated!') {
            if (from === 'register') {
              setAuthToken(res?.data?.token);
              updateProfile(res?.data?.token);
            } else {
              await authService._setToken(res?.data?.token);
              const user = jwtDecode(res?.data?.token);
              dispatch(userToken(res?.data?.token));
              dispatch({ type: WATCH_PROFILE });
              dispatch(userLogin(user));
              // locationApi(true, user);
              joinDraw(user.id);
            }
          } else {
            snackbarHandler({ type: 'error', message: res?.data?.message });
          }
        })
        .catch((err) => {
          snackbarHandler({
            type: 'error',
            message: ['Unable to login automatically', 'Please login on your own'],
          });
        });
    } else {
      snackbarHandler({ type: 'error', message: MsgEnums.FILL_DETAILS });
    }
  };

  const updateProfile = (token) => {
    const userDetails = jwtDecode(token);
    let data = { firstName };
    if (email) data.email = email;
    const errors = validate(data, profileValidator);
    setError(errors);
    if (_.isEmpty(errors)) {
      // if (_.isEmpty(location?.state)) {
      //   data.state = selectedState?._id;
      //   data.city = selectedCity?._id;
      //   data.area = selectedArea?._id;
      // }
      profileService.profileUpdate(data, userDetails.id, userDetails.profile).then(async (res) => {
        if (res.success) {
          userDetails.firstName = firstName;
          userDetails.email = email;
          await authService._setToken(token);
          dispatch(userToken(token));
          dispatch(userLogin(userDetails));
          dispatch({ type: WATCH_PROFILE });
          // locationApi();
          joinDraw(userDetails.id);
        } else {
          snackbarHandler({ type: 'error', message: res.message });
        }
      });
    }
  };

  const setDataHandler = (key, value) => {
    if (key === 'state') {
      setSelectedState(value);
    } else if (key === 'city') {
      setSelectedCity(value);
    } else if (key === 'area') {
      setSelectedArea(value);
    }
  };

  const locationApi = (isApi, userDetails) => {
    if (_.isEmpty(location?.state)) {
      dispatch(updateLocation({ state: selectedState, city: selectedCity, area: selectedArea }));
      if (isApi) {
        let data = { state: selectedState?._id, city: selectedCity?._id, area: selectedArea?._id };
        profileService.profileUpdate(data, userDetails.id, userDetails.profile).catch((e) => {});
      }
    }
  };

  const joinDraw = async (id) => {
    let code = searchParams.get('code');
    let contestId = searchParams.get('id');
    if (code) {
      let data = { drawCode: code, buyerId: id };
      if (contestId) {
        data.contestId = contestId;
      }
      contestService.joinScanAndWin(data).then((res) => {
        if (res.success) {
          setJoined(true);
          setOpenAnimation(true);
          setCoupon(res.data?.coupon || null);
        } else {
          if (res.message == 'Sorry! You have already joined in this contest') {
            getContest(code, contestId, id);
          } else {
            snackbarHandler({ type: 'error', message: res.message });
            // navigate('/');
          }
        }
      });
    } else {
      snackbarHandler({ type: 'error', message: 'Code is mandatory' });
    }
  };

  const snackbarHandler = ({ type, message }) => {
    dispatch(snackbarInfo({ open: true, type, message }));
    setClicked(false);
  };

  return contest ? (
    <Fragment>
      <MynyfyHeader
        id='joinByScan'
        title={
          contest.brand
            ? contest.brand.brand
            : contest.organization
            ? contest.organization.name
            : contest.name
        }
        logo={
          contest.brand?.logo?.url
            ? () => (
                <img
                  src={s3ToCloudFront(contest.brand?.logo?.url)}
                  className='brandLogo'
                  alt={contest.name}
                />
              )
            : contest.organization?.logo?.url
            ? () => (
                <img
                  src={s3ToCloudFront(contest.organization?.logo?.url)}
                  className='brandLogo'
                  alt={contest.name}
                />
              )
            : null
        }
        noBack
        titleStyles={{ padding: '9px 5px' }}
      />
      <div id='joinByScan'>
        <div className='container'>
          <Card className='contestCard'>
            <img src={s3ToCloudFront(contest.image)} className='contestImg' alt={contest.name} />
            <div className='contestDetails'>
              {contest.brand || contest.organization ? (
                <MynyfyText title={contest.name} bold pBig />
              ) : null}
              <div className='row' style={{ marginTop: 8 }}>
                <MynyfyText title={'No. of Winners:'} light bold pSmall />
                &ensp;
                <MynyfyText title={contest.winnerCount} pBig />
              </div>
              <div className='row' style={{ marginTop: 8 }}>
                <MynyfyText title={'No. of Participants:'} light bold pSmall />
                &ensp;
                <MynyfyText title={contest.totalContestants} pBig />
              </div>
              {contest.organization ? (
                <MynyfyText
                  title={'Visit Store'}
                  link
                  bold
                  pSmall
                  onClick={() => navigate('/Stores/' + contest.organization.urlId)}
                  style={{ marginTop: 8 }}
                />
              ) : null}
              {contest.url ? (
                <div className='row' style={{ marginTop: 8 }}>
                  <MynyfyText title={'Website:'} light bold pSmall />
                  &ensp;
                  <MynyfyText
                    title={'View Website'}
                    link
                    bold
                    pSmall
                    onClick={() => window.open(contest.url)}
                  />
                </div>
              ) : null}
              <div className='rowSB'>
                <MynyfyText
                  title={'Terms & Conditions'}
                  bold
                  link
                  style={{ margin: '8px 0px' }}
                  onClick={() => setIsContestTerms(!isContestTerms)}
                />
                {isJoined ? <MynyfyText title={'Joined'} bold /> : null}
              </div>
              {isContestTerms &&
                contest.terms.split('$')?.map((res, i) => (
                  <div className='row' style={{ marginBottom: 10 }} key={i}>
                    <CircleRounded className='dotIcon' />
                    <MynyfyText title={res} style={{ flexWrap: 'wrap', flexShrink: 1 }} />
                  </div>
                ))}
            </div>
          </Card>
          <div style={{ margin: '10px -2px' }}>
            {isLoggedIn ? null : (
              <Fragment>
                <MynyfyText
                  title={'Complete SignUp process to join the contest'}
                  bold
                  style={{ margin: '15px 5px 5px' }}
                />
                <MynyfyInput
                  fullWidth={true}
                  placeHolder={'Phone Number*'}
                  value={mobile}
                  onChange={(e) => setMobile(e.target.value)}
                  helperText={error?.mobile}
                  error={error?.mobile ? true : false}
                  disabled={isOtpSent}
                />

                {isOtpSent ? (
                  <MynyfyInput
                    fullWidth={true}
                    placeHolder={'OTP*'}
                    value={otp}
                    onChange={(e) => setOTP(e.target.value)}
                    helperText={error?.otp}
                    error={error?.otp ? true : false}
                  />
                ) : null}

                {isProfile ? (
                  <Fragment>
                    <MynyfyInput
                      fullWidth={true}
                      placeHolder={'Password*'}
                      type={'password'}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      helperText={error?.password}
                      error={error?.password ? true : false}
                    />
                    <MynyfyText
                      title={'Forgot Password'}
                      right
                      link
                      bold
                      style={{ margin: 5 }}
                      onClick={() => navigate('/ForgotPassword')}
                    />
                  </Fragment>
                ) : null}

                {isProfile === false ? (
                  <Fragment>
                    <MynyfyInput
                      fullWidth={true}
                      placeHolder={'First Name / Last Name*'}
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      helperText={error?.firstName}
                      error={error?.firstName ? true : false}
                    />
                    {/* <MynyfyInput
                      fullWidth={true}
                      placeHolder={'Email (optional)'}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      helperText={error?.email}
                      error={error?.email ? true : false}
                    /> */}
                  </Fragment>
                ) : null}

                {/* {isOtpSent ? (
                  <Fragment>
                    <MynyfyInput
                      fullWidth={true}
                      placeHolder={'Set Password*'}
                      type={'password'}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      helperText={error?.password}
                      error={error?.password ? true : false}
                    />
                    <MynyfyText
                      title={'Set a login password to see the contest results.'}
                      small
                      style={{ paddingLeft: 5 }}
                    />
                  </Fragment>
                ) : null} */}

                {/* {_.isEmpty(location?.state) && (isProfile || isProfile == false) ? (
                  <div style={{ marginTop: 20 }}>
                    <MynyfyText
                      title={'Set your location'}
                      bold
                      pBig
                      style={{ margin: '0px 5px' }}
                    />
                    <LocationFields error={error} sendData={setDataHandler} />
                  </div>
                ) : null} */}

                {isOtpSent ? (
                  <div className='row' style={{ padding: '10px 0px' }}>
                    <Checkbox
                      checked={isTerms}
                      style={{ color: error?.isTerms ? 'red' : theme.PRIMARY_BLUE }}
                      onClick={() => setIsTerms(!isTerms)}
                    />
                    <div>
                      <MynyfySpan title={'I agree to '} pSmall />
                      <MynyfySpan
                        title={'Terms & Conditions and Data Privacy Policies'}
                        link
                        onClick={() => {
                          navigate('/Policies/Terms&Conditions', { state: 'Terms & Conditions' });
                        }}
                        pSmall
                      />
                    </div>
                  </div>
                ) : null}
              </Fragment>
            )}
            {isJoined ? (
              coupon ? (
                <div style={{ margin: '0px 10px', textAlign: 'center' }}>
                  <MynyfyText
                    title={'You won a coupon for joining the contest! '}
                    style={{ marginTop: 30 }}
                  />
                  <MynyfyText
                    title={'View Coupon'}
                    link
                    bold
                    onClick={() => navigate('/Coupons/' + coupon, { state: { promoId: coupon } })}
                    style={{
                      border: '1px solid',
                      borderRadius: 20,
                      width: 120,
                      padding: 5,
                      margin: '20px auto 0px',
                    }}
                  />
                </div>
              ) : null
            ) : (
              <MynyfyButton
                label={isLoggedIn || isOtpSent || isProfile ? 'Join Contest' : 'Get OTP'}
                center
                disabled={!isActive || isClicked}
                onClick={() => {
                  if (isLoggedIn) {
                    setClicked(true);
                    joinDraw(userId);
                  } else if (step === 'verify') {
                    checkProfile();
                  } else if (step === 'login') {
                    loginHandler();
                  } else if (step === 'register') {
                    registerHandler();
                  }
                }}
              />
            )}
          </div>
        </div>
        <MynyfyAlert
          open={isOpen}
          close={() => setOpen(false)}
          title={'Sorry! You have entered wrong OTP many times, please try again after sometime'}
          action1={{ label: 'Close', handler: () => setOpen(false), color: 'black' }}
          action2={{
            label: 'Resend OTP',
            handler: () => {
              setOTP('');
              setSubmitCount(0);
              setPassword('');
              sendOtp();
              setOpen(false);
            },
          }}
        />
        <MynyfyClaimed
          title={`Hohoo! ${
            contest?.myntsCanEarn ? `your earned ${contest?.myntsCanEarn} mynts and` : 'You have'
          } entered into ${contest?.name} contest`}
          open={isOpenAnimation}
          callback={() => {
            setActive(false);
            setOpenAnimation(false);
            // navigate('/');
          }}
        />
      </div>
    </Fragment>
  ) : (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: theme.MIN_HEIGHT,
      }}>
      <span className='loader' />
    </div>
  );
};

export default JoinByScan;
