import React, { Fragment, useState } from 'react';
import MynyfyText from '../../components/MynyfyText';
import { useDispatch, useSelector } from 'react-redux';
// import SelectSeller from '../../components/SelectSeller';
import SelectAddress from '../../components/SelectAddress';
// import { OrderType, PaymentMode } from '../../utils/enums';
import SignInModal from '../Auth/SignInModal';
import { useNavigate } from 'react-router';
import './home.scss';
import { ChevronRight, ShoppingBagOutlined } from '@mui/icons-material';

const AddPromoBag = () => {
     const navigate = useNavigate();
     const dispatch = useDispatch();
     const bagPromo = useSelector((state) => state.bagPromo);
     const isLoggedIn = useSelector((state) => state.user?.isLoggedIn);

     const [openSellersList, setOpenSellersList] = useState(false);
     const [selectedSeller, setSelectedSeller] = useState(null);
     const [openAddressModal, setOpenAddressModal] = useState(false);
     const [selectedAddress, setSelectedAddress] = useState(null);
     const [orderType, setOrderType] = useState(null);
     const [openSignIn, setOpenSignIn] = useState(false);
 const cartHandler = () => {
    if (isLoggedIn) {
        // if (sellerDetails?.pickUp && sellerDetails?.homeDelivery) {
          setOpenAddressModal(true);
        // } else if (sellerDetails?.homeDelivery) {
        //   setOpenAddressModal(true);
        // } else if (sellerDetails?.pickUp) {
        //   addressHandler({ orderType: OrderType.TAKE_AWAY });
        // }
      }
    else {
      setOpenSignIn(true);
    }
  };

  return (
    <Fragment>
      {bagPromo?.calculation?.qty ? (
        <Fragment>
          {/* {from == 'web' ? (
            <div
              id='Products'
              className='cursor'
              style={{ position: 'relative', marginLeft: 8, padding: 0 }}
              onClick={() => cartHandler()}>
              <ShoppingBagOutlined style={{ fontSize: 30, display: 'block' }} />
              <MynyfyText title={bagPromo?.calculation?.qty || 0} className='webCartNum' />
            </div>
          ) : ( */}
            <div id='AddPromoBag' style={{ padding: 0 }}>
              <div className='cartBtnContainer cursor' onClick={() => cartHandler()}>
                <div className='cartBtn rowSB'>
                  <MynyfyText
                    title={
                      (bagPromo?.calculation?.qty || 0) +
                      (bagPromo?.calculation?.qty == 1 ? ' Item ' : ' Items ') +
                      'Added'
                    }
                    color={'#fff'}
                    className='cartNum'
                  />
                  <div className='row'>
                    <MynyfyText title={'View Bag'} color={'#fff'} />
                    <ChevronRight style={{ color: '#fff' }} />
                  </div>
                </div>
              </div>
            </div>
          {/* )} */}
          
          <SelectAddress
            isVisible={openAddressModal}
            closeModal={() => setOpenAddressModal(false)}
            selectedAddress={selectedAddress}
            // from={navFrom}
            fromId={bagPromo?.id}
            fromName={bagPromo?.about?.name}
            // isOffline={!sellerDetails?.homeDelivery}
            // sellerDetails={sellerDetails}
            // isRestaurant={
            //   sellerDetails?.ownerId?.categories?.find(
            //     (e) => e._id == process.env.REACT_APP_FOOD_CAT_ID
            //   )
            //     ? true
            //     : false
            // }
            orderType={orderType}
            // setData={(e) => addressHandler(e)}
          />
        </Fragment>
      ) : null}

      {/* <SignInModal
        open={openSignIn}
        close={() => setOpenSignIn(false)}
        isGuest={table ? true : false}
        guestLoginForDineIn={() => addressHandler({ orderType: OrderType.DINE_IN })}
        sellerDetails={{ image: sellerDetails?.orgImages[0].url, storename: sellerDetails?.name }}
      /> */}
    </Fragment>
  );
}

export default AddPromoBag
