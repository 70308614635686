import React from 'react';
import MynyfyWebModal from './MynyfyWebModal';

const MynyfyWebViewVideo = ({ openModal, closeModal, url }) => {
  return (
    <MynyfyWebModal open={openModal} onClose={() => closeModal()}>
      {url ? (
        <div className='rowC' style={{ flexDirection: 'column', padding: 2, borderRadius: 7 }}>
          {url.type == 'S3' ? (
            <video style={{ width: '100%', height: '100%', borderRadius: 7 }} controls>
              <source src={url.url} type='video/mp4' />
              Your browser does not support the video tag
            </video>
          ) : (
            <iframe
              style={{
                width: url.type == 'Youtube Short' ? '50vw' : '80vw',
                height: '80vh',
                borderRadius: 5,
              }}
              src={url.url}
              title={url.type + ' Video'}
              frameborder='0'
              allowFullScreen
              {...(url.type == 'Youtube' && {
                allow:
                  'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share',
                referrerpolicy: 'strict-origin-when-cross-origin',
              })}
            />
          )}
        </div>
      ) : null}
    </MynyfyWebModal>
  );
};

export default MynyfyWebViewVideo;
