import { fork, put, select, call, all, takeLatest } from '@redux-saga/core/effects';
import _ from 'lodash';
import promoService from '../../services/promoService';
import { RELOAD_WITH_LOC_WATCHER } from '../actionTypes';
import { fetchNonViewedCouponsCount } from '../actions/nonViewedCouponsCount.action';
import { fetchActualBrands, fetchBrands } from '../actions/brand.action';
import {
  fetchOfflineFoodStores,
  fetchOfflineStores,
  fetchOnlineFoodStores,
  fetchStores,
} from '../actions/store.action';
import contestService from '../../services/contestService';
import { ContestType, PromoType } from '../../utils/enums';
import {
  fetchCoupons,
  fetchDayDeals,
  fetchRewards,
  fetchFoodCoupons,
  fetchSortedCoupons,
  fetchSortedRewards,
  fetchSortedDayDeals,
  fetchSortedFoodCoupons,
} from '../actions/promo.action';
import {
  fetchJackpot,
  fetchSeeAndWin,
  fetchShopAndWin,
  fetchFoodShopAndWin,
} from '../actions/contest.action';
import moment from 'moment';
import opportunityService from '../../services/opportunityService';
import { fetchFlashAds } from '../actions/flashAd.action';
import storeService from '../../services/storeService';

function* reloadWithLocEffect() {
  const location = yield select((state) => state?.location?.location);
  const user = yield select((state) => state?.user);
  const isLoggedIn = user?.isLoggedIn;
  const id = user?.user?.id || null;

  const expiryHandler = (date) => {
    let presentDate = moment().format('DD/MM/YYYY');
    let endDate = moment(date).format('DD/MM/YYYY');
    let pDate = moment(presentDate, 'DD/MM/YYYY');
    let eDate = moment(endDate, 'DD/MM/YYYY');
    let result = eDate.diff(pDate, 'days', false);
    return result + 1;
  };

  const sortCoupons = (couponsData) => {
    if (couponsData?.success) {
      let redeemedCoupons = [];
      let activeCoupons = [];
      let expiredCoupons = [];
      couponsData.data.map((details) => {
        details.days = expiryHandler(details.endDate);
        details.isRedeemed = details.claimedBy.includes(id);
        if (details.isRedeemed) {
          redeemedCoupons.push(details);
        } else if (
          (!details.count && details.count !== 0) ||
          details.count - (details.claims || 0)
        ) {
          activeCoupons.push(details);
        } else {
          expiredCoupons.push(details);
        }
      });

      let sortedRedeemed = _.orderBy(redeemedCoupons, 'createdAt', 'desc');
      let sortedActive = _.orderBy(activeCoupons, 'createdAt', 'desc');
      let sortedExpired = _.orderBy(expiredCoupons, 'createdAt', 'desc');
      return [...sortedActive, ...sortedRedeemed, ...sortedExpired];
    } else {
      return [];
    }
  };

  if (location && isLoggedIn) {
    let locationParams = {
      state: location?.state?._id,
      city: location?.city?._id,
      area: location?.area?._id,
    };

    const flashAds = yield call(opportunityService.getOpportunities, {
      isActive: true,
      type: 'ANNOUNCEMENT',
      ...locationParams,
    });

    // const getNonViewedCouponsCount = yield call(promoService.getNonViewedPromoCount, {
    //   isActive: true,
    //   type: 'COUPON',
    //   buyer: id,
    //   ...locationParams,
    // });

    let couponsParams = {
      type: [PromoType.COUPON, PromoType.POINT_COUPON],
      status: 'ACTIVE',
      buyer: id,
      buyerId: id,
      state: location?.state?._id,
      city: location?.city?._id,
    };

    const getCoupons = yield call(promoService.getPromos, {
      ...couponsParams,
      notCategory: [process.env.REACT_APP_FOOD_CAT_ID],
    });
    let sortedCoupons = sortCoupons(getCoupons);

    // const getFoodCoupons = yield call(promoService.getPromos, {
    //   ...couponsParams,
    //   category: [process.env.REACT_APP_FOOD_CAT_ID],
    // });
    // let sortedFoodCoupons = sortCoupons(getFoodCoupons);

    let reqFlashAds = [];
    if (flashAds?.success) {
      reqFlashAds = _.isEmpty(flashAds.data) ? [] : flashAds.data;
    }

    // const offlineFoodStores = yield call(storeService.getStores, {
    //   pageLimit: 4,
    //   offline: true,
    //   category: process.env.REACT_APP_FOOD_CAT_ID,
    //   storeLocation: 'stores in my area',
    //   ...locationParams,
    // });
    // const onlineFoodStores = yield call(storeService.getStores, {
    //   pageLimit: 4,
    //   online: true,
    //   category: process.env.REACT_APP_FOOD_CAT_ID,
    //   storeLocation: 'stores serviceable to my area',
    //   ...locationParams,
    // });

    yield all([
      put(fetchFlashAds(reqFlashAds)),
      // put(
      //   fetchNonViewedCouponsCount(
      //     getNonViewedCouponsCount.success ? getNonViewedCouponsCount.data : 0
      //   )
      // ),
      put(fetchCoupons(_.isEmpty(sortedCoupons) ? [] : sortedCoupons)),
      put(fetchSortedCoupons(_.isEmpty(sortedCoupons) ? [] : sortedCoupons)),
      // put(fetchFoodCoupons(_.isEmpty(sortedFoodCoupons) ? [] : sortedFoodCoupons)),
      // put(fetchSortedFoodCoupons(_.isEmpty(sortedFoodCoupons) ? [] : sortedFoodCoupons)),
      // put(fetchOfflineFoodStores(offlineFoodStores.success ? offlineFoodStores.data?.orgList : [])),
      // put(fetchOnlineFoodStores(onlineFoodStores.success ? onlineFoodStores.data?.orgList : [])),
      put(fetchStores(null)),
      put(fetchOfflineStores(null)),
    ]);
  }

  let params = { status: 'active', isCats: false };

  if (_.isEmpty(location)) {
    params.isAllIndia = true;
  } else {
    params.state = location?.state?._id;
    params.city = location?.city?._id;
    params.area = location?.area?._id;
  }
  if (isLoggedIn) {
    params.buyerId = id;
  }
  let adFeedParams = {
    type: ContestType.SEE_AND_WIN,
    limit: 4,
    page: 0,
    ...params,
  };
  let jackpotParams = {
    type: ContestType.JACKPOT,
    ...params,
  };
  let shop_WinParams = {
    type: ContestType.SHOP_AND_WIN,
    notCategory: process.env.REACT_APP_FOOD_CAT_ID,
    ...params,
  };
  let food_shop_WinParams = {
    type: ContestType.SHOP_AND_WIN,
    category: process.env.REACT_APP_FOOD_CAT_ID,
    ...params,
  };
  let dayDealParams = {
    type: PromoType.DAY_DEAL,
    ...params,
  };
  let rewardParams = {
    type: PromoType.REWARD,
    ...params,
  };
  const adFeeds = yield call(contestService.getContests, adFeedParams);
  const jackpots = yield call(contestService.getContests, jackpotParams);
  const shop_Win = yield call(contestService.getContests, shop_WinParams);
  // const food_shop_Win = yield call(contestService.getContests, food_shop_WinParams);
  const dayDeals = yield call(promoService.getPromos, dayDealParams);
  const rewards = yield call(promoService.getPromos, rewardParams);

  let sortedDayDeals = [];
  let sortedRewards = [];
  if (dayDeals.success) {
    let redeemedDayDeals = [];
    let expiredDayDeals = [];
    let activeDayDeals = [];
    dayDeals.data.map((details) => {
      details.days = expiryHandler(details.endDate);
      details.isRedeemed = details.claimedBy.includes(id);
      if (details.isRedeemed) {
        redeemedDayDeals.push(details);
      } else if (details.status === 'COMPLETED') {
        expiredDayDeals.push(details);
      } else if (details.days <= 0) {
        expiredDayDeals.push(details);
      } else if (details.count - (details.claims || 0)) {
        activeDayDeals.push(details);
      } else {
        expiredDayDeals.push(details);
      }
    });

    let sortedRedeemed = _.orderBy(redeemedDayDeals, 'createdAt', 'desc');
    let sortedActive = _.orderBy(activeDayDeals, 'createdAt', 'desc');
    let sortedExpired = _.orderBy(expiredDayDeals, 'createdAt', 'desc');
    sortedDayDeals = [...sortedActive, ...sortedRedeemed, ...sortedExpired];
  }

  if (rewards.success) {
    let redeemedRewards = [];
    let activeRewards = [];
    rewards.data.map((details) => {
      details.days = expiryHandler(details.endDate);
      details.isRedeemed = details.claimedBy.includes(id);
      if (details.isRedeemed) {
        redeemedRewards.push(details);
      } else if (details.count - (details.claims || 0)) {
        activeRewards.push(details);
      }
    });

    let sortedRedeemed = _.orderBy(redeemedRewards, 'createdAt', 'desc');
    let sortedActive = _.orderBy(activeRewards, 'createdAt', 'desc');
    sortedRewards = [...sortedActive, ...sortedRedeemed];
  }

  yield all([
    put(fetchSeeAndWin(_.isEmpty(adFeeds?.data) ? [] : adFeeds?.data)),
    put(fetchJackpot(_.isEmpty(jackpots?.data) ? [] : jackpots?.data)),
    put(fetchShopAndWin(_.isEmpty(shop_Win?.data) ? [] : shop_Win?.data)),
    // put(fetchFoodShopAndWin(_.isEmpty(food_shop_Win?.data) ? [] : food_shop_Win?.data)),
    put(fetchDayDeals(_.isEmpty(sortedDayDeals) ? [] : sortedDayDeals)),
    put(fetchSortedDayDeals(_.isEmpty(sortedDayDeals) ? [] : sortedDayDeals)),
    put(fetchRewards(_.isEmpty(sortedRewards) ? [] : sortedRewards)),
    put(fetchSortedRewards(_.isEmpty(sortedRewards) ? [] : sortedRewards)),
  ]);
}

function* watchReload() {
  yield takeLatest(RELOAD_WITH_LOC_WATCHER, reloadWithLocEffect);
}

const ReloadWithLocSagas = [fork(watchReload)];
export default ReloadWithLocSagas;
