import { fork, put, select, call, all, takeLatest } from '@redux-saga/core/effects';
import _ from 'lodash';
import { RELOAD_WATCHER } from '../actionTypes';
import transactionService from '../../services/transactionService';
import commonService from '../../services/commonService';
import { fetchMynts } from '../actions/mynts.action';
import { fetchAdTimer } from '../actions/appData.action';
import notificationService from '../../services/notificationService';
import { fetchNotifications } from '../actions/notifications.action';
import storeService from '../../services/storeService';
import { fetchVisitedStores } from '../actions/visitedStores.action';

function* getReloadEffect() {
  const isLoggedIn = yield select((state) => state?.user?.isLoggedIn);
  const visitedSellers = yield select((state) => state?.user?.profile?.visitedSellers);

  if (isLoggedIn) {
    const mynts = yield call(transactionService.getPoints);
    const adTime = yield call(commonService.publicCategorySort, 'AdTimer');
    const notifications = yield call(notificationService.getNotifications, Date.now(), 20);
    const visitedStores = _.isEmpty(visitedSellers)
      ? {}
      : yield call(storeService.getVisitedSellers);

    yield all([
      put(fetchMynts(mynts?.data?.points || 0)),
      put(fetchAdTimer(_.isEmpty(adTime) ? 0 : Number(adTime[0].data))),
      put(fetchNotifications(notifications.success ? notifications.data : null)),
      put(fetchVisitedStores(visitedStores.success ? visitedStores.data : null)),
    ]);
  }
}

function* watchReload() {
  yield takeLatest(RELOAD_WATCHER, getReloadEffect);
}

const ReloadSagas = [fork(watchReload)];
export default ReloadSagas;
