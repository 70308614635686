import React, { Fragment, useEffect, useState } from 'react';
import MynyfyText from '../../components/MynyfyText';
import moment from 'moment';
import TrackingDetails from '../../components/TrackingDetails';
import { OrderDeliveryStatus, OrderStatus, OrderType, PaymentMode } from '../../utils/enums';
import MynyfySpan from '../../components/MynyfySpan';
import storeService from '../../services/storeService';
import { useDispatch, useSelector } from 'react-redux';
import { snackbarInfo } from '../../redux/actions/snackbar.action';
import MynyfyLoader from '../../components/MynfyfLoader';
import { useLocation } from 'react-router';
import './Weborders.scss';
import Convert from 'color-convert';
import theme from '../../utils/theme';
import { Card } from '@mui/material';
import { CallRounded, EmailRounded } from '@mui/icons-material';
import MynyfyPDFGenerator from '../../components/MynyfyPDFGenerator';
import OrderInvoice from '../../components/OrderInvoice';
import _ from 'lodash';
import productOrderService from '../../services/productOrderService';
import MynyfyDrawer from '../../components/MynyfyDrawer';
import IconButton from '@mui/material/IconButton';
import CloseRoundedIcon from '@mui/icons-material/Close';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import WebMynyfyText from '../../components/WebMynyfyText';

const WebOrderDetails = ({ open, orderId, onClose, orderDetail, pdfContent }) => {
  const dispatch = useDispatch();
  const route = useLocation();

  const profile = useSelector((state) => state.user?.profile?.profile);

  const [orderDetails, setOrderDetails] = useState(null);
  const [orgAddress, setOrgAddress] = useState(null);
  const [taxDetails, setTaxDetails] = useState(null);
  // const [Open, setOpen] = useState('');
  const orderData = useSelector((state) => state.orders);

  useEffect(() => {
    if (orderDetail) {
      fetchOrderDetails(orderDetail);
    } else if (orderId) {
      productOrderService.getProductOrderById(orderId).then((res) => {
        if (res.success) fetchOrderDetails(res.data);
        else dispatch(snackbarInfo({ type: 'error', open: true, message: res.message }));
      });
    }
  }, [orderDetails, orderId, orderDetail]);

  const fetchOrderDetails = (reqOrderDetails) => {
    setOrderDetails(reqOrderDetails);
    taxDetailsHandler(reqOrderDetails);
    let orgId = reqOrderDetails?.seller?._id;
    let brandId = reqOrderDetails?.brand?.[0]?._id;
    if (orgId) addressCall(orgId);
  };

  const addressHandler = (key) => {
    return key ? `${key}, ` : null;
  };

  const addressCall = (orgId) => {
    storeService.getStoreAddress(orgId).then((res) => {
      if (res.success) {
        setOrgAddress(res.data);
      } else {
        dispatch(snackbarInfo({ type: 'error', open: true, message: res.message }));
      }
    });
  };

  const addressComponent = (address) => {
    return (
      <div>
        <MynyfySpan title={addressHandler(address?.lane1)} />
        <MynyfySpan title={addressHandler(address?.lane2)} />
        <MynyfySpan title={addressHandler(address?.landMark)} />
        <MynyfySpan title={addressHandler(address?.locality)} />
        <MynyfySpan title={addressHandler(address?.city)} />
        <MynyfySpan title={addressHandler(address?.state)} />
        <MynyfySpan title={address?.pincode} />
      </div>
    );
  };

  const sendMail = () => {
    window.open(
      `mailto:${
        orderDetails.seller?.email || orderDetail?.brand?.[0]?.emailId
      }?subject=Enquiry regarding my order Id: ${orderDetails.orderId}&body=Hi, this is ${
        profile?.firstName || ''
      } ${profile?.lastName || ''} and Mobile No. ${profile?.mobile || ''}.${theme.BREAK}${
        theme.BREAK
      }I have an enquiry regarding my order Id: ${orderDetails.orderId}`
    );
  };

  const taxDetailsHandler = (data) => {
    let taxData = [];
    data?.products?.map((res) => {
      if (res.gst || res.gst == 0) {
        let taxableAmt = (res.discountedPriceBeforeGst || res.priceBeforeGst) * res.count;
        let taxAmt =
          ((res.discountedPrice || res.price) -
            (res.discountedPriceBeforeGst || res.priceBeforeGst)) *
          res.count;
        if (_.isEmpty(taxData)) {
          taxData.push({ title: res.gst, taxableAmt, taxAmt });
        } else {
          let index = taxData.findIndex((e) => e.title == res.gst);
          if (index == -1) {
            taxData.push({ title: res.gst, taxableAmt, taxAmt });
          } else {
            taxData[index].taxableAmt += taxableAmt;
            taxData[index].taxAmt += taxAmt;
          }
        }
      }
    });
    let sorted = _.orderBy(taxData, 'title', 'asc');
    setTaxDetails(sorted);
  };

  const productsComponent = (res, i) => {
    return (
      <div className='rowSB' style={{ marginTop: 7 }} key={i}>
        <MynyfyText
          title={`${res.name || res.productId.name} x ${res.count}${
            res.color || res.size ? ' -- ' : ''
          }${
            res.color
              ? res.color?.includes('#')
                ? 'Color: ' + Convert.hex.keyword(res.color)
                : 'Color: ' + res.color
              : ''
          }${res.size ? ', Size: ' + res.size?.toUpperCase() : ''}`}
          style={{ textTransform: 'capitalize', maxWidth: 275 }}
        />
        <MynyfyText
          title={
            theme.RUPEE +
            (res.discountedPriceBeforeGst || res.priceBeforeGst || res.productId.price) * res.count
          }
          p
        />
      </div>
    );
  };

  return (
    <MynyfyDrawer
      drawerMargin={'10px'}
      drawerBorderRadius={'10px'}
      anchor='right'
      width={'450px'}
      open={open}
      onClose={onClose}
      height={'97%'}>
      {orderDetails ? (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {/* <WebMynyfyHeader
            title={'Order #' + orderDetails.orderId}
            customElememt={() =>
              orderDetails.status == 'COMPLETED' ? (
                <MynyfyPDFGenerator
                  customBtn={() => <GetAppRounded className='cursor' />}
                  htmlString={OrderInvoice({ order: orderDetails, orgAddress, taxDetails })}
                  pdfName={orderDetails.orderId + ' - Invoice'}
                />
              ) : null
            }
          /> */}
          <div id='WebOrderDetails'>
            <div
              style={{
                backgroundColor: '#f9f9fa',
                padding: '0px 15px',
                borderBottom: '2px solid #f9f9fa',
              }}>
              <div className='closeContainer'>
                <IconButton
                  onClick={onClose}
                  style={{ border: '2px solid #eeeeee', borderRadius: '7px', height: '30px' }}>
                  <CloseRoundedIcon style={{ color: 'black', fontSize: '15px' }} />
                </IconButton>
              </div>
              <div style={{ marginTop: '-35px' }}>
                <MynyfyText
                  title={'Order #' + orderDetails.orderId}
                  h4
                  bold
                  style={{ paddingTop: 10 }}
                />
                <div style={{ display: 'flex' }}>
                  <MynyfyText
                    title={'Placed on:'}
                    h6
                    style={{ paddingBottom: 15, paddingTop: 5 }}
                  />
                  <MynyfyText
                    title={moment(orderDetails.createdAt).format('MMMM DD YYYY,  hh:mm A')}
                    h6
                    semiBold
                    style={{ paddingBottom: 15, paddingTop: 5 }}
                  />
                </div>
              </div>
            </div>

            {/* <MynyfyText title={orderDetails.seller.name} h6 bold style={{ paddingTop: '5px' }} />
            {orgAddress ? (
              <div style={{ marginTop: 3 }}>
                <MynyfySpan title={addressHandler(orgAddress?.lane1)} />
                <MynyfySpan title={addressHandler(orgAddress?.lane2)} />
                <MynyfySpan title={addressHandler(orgAddress?.locality)} />
                <MynyfySpan title={addressHandler(orgAddress?.city)} />
                <MynyfySpan title={addressHandler(orgAddress?.state)} />
                <MynyfySpan title={orgAddress?.pincode} />
              </div>
            ) : (
              <div>
                <MynyfySpan title={`${orderDetails.seller.area?.name},  `} />
                <MynyfySpan title={`${orderDetails.seller.city?.name},  `} />
                <MynyfySpan title={orderDetails.seller.state?.name} />
              </div>
            )}
            <div className='divider' /> */}
            <div className='orderDetailsLayout'>
              <Card className='cardStyle'>
                {orderDetails?.address || orderDetails?.fullAddress ? (
                  <Fragment>
                    <div className='section'>
                      <div className='iconHeading'>
                        <MynyfyText className='Heading' title={'Delivery Address'} pBig bold />
                      </div>
                      {addressComponent(orderDetails?.address || orderDetails?.fullAddress)}
                    </div>
                  </Fragment>
                ) : null}
              </Card>

              <Card className='cardStyle'>
                <div className='section'>
                  <div className='iconHeading'>
                    <MynyfyText className='Heading' title={'Order Details'} pBig bold />
                  </div>
                  {orderDetails.products?.map((res, i) => productsComponent(res, i))}
                  {_.isEmpty(orderDetails.newProducts) ? null : (
                    <div style={{ borderBottom: '1px dashed grey', marginTop: 7 }} />
                  )}

                  {orderDetails.newProducts?.map((newOfNew, i) => (
                    <Fragment key={i}>
                      {newOfNew?.map((res, j) => productsComponent(res, j, 'newProducts'))}
                      {orderDetails.newProducts.length == i + 1 ? null : (
                        <div style={{ borderBottom: '1px dashed grey' }} />
                      )}
                    </Fragment>
                  ))}

                  {orderDetails.deals?.map((res, i) => (
                    <div className='rowSB' style={{ marginTop: 7 }} key={i}>
                      <MynyfyText
                        title={res.name || res.dealId.name}
                        style={{ textTransform: 'capitalize' }}
                      />
                      <MynyfyText title={theme.RUPEE + res.dealId.actualPrice} p />
                    </div>
                  ))}

                  <div className='divider' />

                  <div className='rowSB' style={{ margin: '7px 0px' }}>
                    <MynyfyText title={'GST'} p />
                    <MynyfyText
                      title={
                        theme.RUPEE +
                        Number(
                          orderDetails.products.reduce(
                            (acc, curr) =>
                              acc +
                              (curr.price -
                                (curr.discountedPriceBeforeGst ||
                                  curr.priceBeforeGst ||
                                  curr.productId.price)) *
                                curr.count,
                            0
                          ) +
                            (orderDetails.newProducts
                              ?.flat()
                              .reduce(
                                (acc, curr) =>
                                  acc +
                                  (curr.price -
                                    (curr.discountedPriceBeforeGst ||
                                      curr.priceBeforeGst ||
                                      curr.productId.price)) *
                                    curr.count,
                                0
                              ) || 0)
                        ).toFixed(2)
                      }
                      p
                    />
                  </div>
                  <div className='rowSB' style={{ margin: '7px 0px' }}>
                    <MynyfyText title={'Sub Total'} p />
                    <MynyfyText title={theme.RUPEE + orderDetails.priceDetails.price} p />
                  </div>
                  <div className='rowSB' style={{ margin: '7px 0px' }}>
                    <MynyfyText title={'Shipping Charges'} p />
                    <MynyfyText title={theme.RUPEE + orderDetails.priceDetails.shippingCharges} p />
                  </div>
                  <div className='rowSB' style={{ margin: '7px 0px' }}>
                    <MynyfyText title={'Discount Applied'} p />
                    <MynyfyText
                      title={
                        theme.RUPEE +
                        ((orderDetails.priceDetails.discount || 0) +
                          (orderDetails.priceDetails.productsDiscount || 0))
                      }
                      p
                      color={'green'}
                    />
                  </div>

                  <div className='divider' />

                  <div className='rowSB' style={{ margin: '3px 0px' }}>
                    <MynyfyText
                      title={
                        orderDetails.priceDetails.totalPrice
                          ? orderDetails.paymentMode == PaymentMode.CASH
                            ? 'Cash On Delivery'
                            : orderDetails.paymentMode == PaymentMode.UPI_QR
                            ? 'Paid to Seller UPI'
                            : 'Paid'
                          : ' '
                      }
                      p
                      bold
                    />
                    <MynyfyText
                      title={`Bill Total ${theme.RUPEE}${orderDetails.priceDetails.totalPrice}`}
                      p
                      bold
                    />
                  </div>
                </div>
              </Card>

              {orderDetails.couponUsed ? (
                <Card className='cardStyle'>
                  <Fragment>
                    <div className='iconHeading'>
                      <MynyfyText className='Heading' title={'Applied Coupon/Reward'} pBig bold />
                    </div>

                    <div className='section rowSB'>
                      <MynyfyText title={orderDetails.couponUsed?.name} />
                      <MynyfyText
                        title={theme.RUPEE + orderDetails.couponUsed?.actualPrice}
                        color={'green'}
                      />
                    </div>
                  </Fragment>
                </Card>
              ) : null}

              {orderDetails.orderType !== OrderType.DINE_IN &&
              orderDetails.deliveryDetails?.deliveryStatus ? (
                <Fragment>
                  <Card className='cardStyle'>
                    <div className='iconHeading'>
                      <MynyfyText className='Heading' title={'Tracking Details'} pBig bold />
                    </div>
                    {orderDetails.status == OrderStatus.CANCELLED ? (
                      <MynyfyText title={'Order is Cancelled'} />
                    ) : orderDetails.deliveryDetails?.deliveryStatus ==
                      OrderDeliveryStatus.IN_PROGRESS ? (
                      <Fragment>
                        <MynyfyText
                          title={'We have recieved your order, shipping is in progress'}
                        />
                        <MynyfyText
                          title={'Order tracking details will be shared soon'}
                          style={{ marginTop: 5 }}
                        />
                      </Fragment>
                    ) : orderDetails.deliveryDetails?.deliveryStatus ==
                      OrderDeliveryStatus.PICK_UP_READY ? (
                      <MynyfyText
                        title={'Your order is ready, please visit the store and collect the order'}
                      />
                    ) : (
                      <TrackingDetails deatils={orderDetails.deliveryDetails} />
                    )}
                  </Card>
                </Fragment>
              ) : null}
              {orderDetails?.seller?.ownerId?.profile?.mobile ||
              orderDetails?.seller?.email ||
              orderDetail?.brand?.[0]?.emailId ? (
                <Fragment>
                  <Card className='cardStyle'>
                    <div className='iconHeading'>
                      <MynyfyText
                        className='Heading'
                        title={orderDetails?.seller?.ownerId ? 'Contact Seller' : 'Contact Brand'}
                        pBig
                        bold
                      />
                    </div>
                    {orderDetails?.seller?.ownerId?.profile?.mobile ? (
                      <div className='row' style={{ paddingBottom: 5 }}>
                        <MynyfyText title={'Mobile: '} color={theme.MUTED_BLUE} />
                        &nbsp;
                        <MynyfyText
                          title={
                            orderDetails?.seller?.ownerId?.profile?.mobile ||
                            orderDetails?.brand?.[0]?.contactCareNumber
                          }
                          onClick={() => {
                            if (orderDetails?.seller?.ownerId) {
                              window.open('tel:' + orderDetails?.seller?.ownerId?.profile?.mobile);
                            } else {
                              window.open('tel:' + orderDetails?.brand?.[0]?.contactCareNumber);
                            }
                          }}
                        />
                        <CallRounded
                          fontSize=''
                          color='primary'
                          style={{ padding: '0 10px', display: 'block', cursor: 'pointer' }}
                        />
                      </div>
                    ) : null}
                    {orderDetails?.seller?.email || orderDetails?.brand?.[0]?.emailId ? (
                      <div className='row'>
                        <MynyfyText title={'Email: '} color={theme.MUTED_BLUE} />
                        &nbsp;
                        <MynyfyText
                          title={orderDetails?.seller?.email || orderDetails?.brand?.[0]?.emailId}
                          onClick={() => sendMail()}
                        />
                        <EmailRounded
                          fontSize=''
                          color='primary'
                          style={{ padding: '0 10px', display: 'block', cursor: 'pointer' }}
                          onClick={() => sendMail()}
                        />
                      </div>
                    ) : null}
                  </Card>
                  <WebMynyfyText
                    customElememt={() =>
                      orderDetails.status == 'COMPLETED' ? (
                        <MynyfyPDFGenerator
                          customBtn={() => (
                            <div className='detailed-view-btn'>
                              <FileDownloadOutlinedIcon size={18} style={{ color: 'white' }} />
                              <MynyfyText title={'Detailed Bill'} h6 bold color={'#ffffff'} />
                            </div>
                          )}
                          htmlString={OrderInvoice({ order: orderDetails, orgAddress, taxDetails })}
                          pdfName={orderDetails.orderId + ' - Invoice'}
                        />
                      ) : null
                    }
                  />
                </Fragment>
              ) : null}
            </div>
          </div>
        </div>
      ) : (
        <MynyfyLoader />
      )}
    </MynyfyDrawer>
  );
};

export default WebOrderDetails;
