import React, { useState, Fragment } from 'react';
import MynyfyText from '../../components/MynyfyText';
import '../Contest/contest.scss';
import _ from 'lodash';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import MynyfyModal from '../../components/MynyfyModal';

import CloseIcon from '@mui/icons-material/Close';

const ContestDetailsNew = ({ state } ) => {
    const { drawDetails, detailOptions, closeModal, showDetails, isActive } = state;
    const navigate = useNavigate();

  return (
    <MynyfyModal
      open={showDetails}
      close={() => closeModal()}
      bgtransparent
      >
      <div >
        <div style={{ textAlign: 'end' }}>
          <CloseIcon style={{ color: 'grey' }} onClick={() => closeModal()} className='cursor' />
        </div>
        <MynyfyText title={drawDetails.name} bold h5 style={{ color: 'black' }} />
        <div className='rowSB' style={{ paddingTop: 5, paddingBottom: 5, color: 'black' }}>
          <MynyfyText
            title={isActive ? 'Terms & Conditions apply' : drawDetails.terms}
            pSmall
            style={{ color: 'black' }}
          />
        </div>

        {detailOptions.isDates ? (
          <Fragment>
            <div className='rowSB' style={{ marginTop: 20 }}>
              <MynyfyText title={'Start On'} style={{ color: 'black' }} />
              <MynyfyText
                title={moment(drawDetails.startDate).format('DD MMM YYYY')}
                className='content'
                style={{ color: 'black' }}
                bold
              />
            </div>
            <div className='rowSB' style={{ marginTop: 20 }}>
              <MynyfyText title={'Ends On'} style={{ color: 'black' }} />
              <MynyfyText
                title={moment(drawDetails.endDate).format('DD MMM YYYY')}
                className='content'
                style={{ color: 'black' }}
                bold
              />
            </div>
          </Fragment>
        ) : null}

        {detailOptions.isParticipants ? (
          <div className='rowSB' style={{ marginTop: 20 }}>
            <MynyfyText title={'Total Joins'} style={{ color: 'black' }} />
            <MynyfyText
              title={drawDetails.totalContestants || 0}
              className='content'
              style={{ color: 'black' }}
              bold
            />
          </div>
        ) : null}
        {detailOptions.isNoOfWinners ? (
          <div className='rowSB' style={{ marginTop: '20px' }}>
            <MynyfyText
              title={`Winners - ${drawDetails.winnerCount}`}
              bold
              style={{ color: 'black' }}
            />
          </div>
        ) : null}
        {isActive ? (
          <MynyfyText
            title={'Winners will be announced when the contest ends'}
            style={{ color: 'black' }}
          />
        ) : (
          !_.isEmpty(drawDetails.winners) &&
          drawDetails.winners.map((each) => (
            <MynyfyText title={each.profile.firstName} style={{ color: 'black' }} />
          ))
        )}
      </div>
    </MynyfyModal>
  );
};

export default ContestDetailsNew;
