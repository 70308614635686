import React from 'react';
import { ContestType } from '../utils/enums';
import _ from 'lodash';
import JackpotVideo from '../screens/Contest/Jackpot/JackpotVideo';
import { s3ToCloudFront } from '../utils/CommonMethods';

const MynyfyHalfCard = ({ data, onClick, body, footer, cardStyle, containerStyle, isHistory }) => {
  return (
    <div id='MynyfyHalfCard' style={containerStyle}>
      {data?.map((res, i) => (
        <div className='hCard cursor' key={i} style={{ cardStyle }}>
          {res.logo?.url || res.orgImages?.[0]?.url || res.image?.url || res.image ? (
            <img
              src={s3ToCloudFront(
                res.logo?.url || res.orgImages?.[0]?.url || res.image?.url || res.image
              )}
              onClick={() => onClick(res)}
              className='hImg'
            />
          ) : res.video && res.type === ContestType.JACKPOT && isHistory ? (
            <div style={{ margin: 2 }}>
              <div style={{ borderRadius: 6, overflow: 'hidden' }}>
                <JackpotVideo contestDetails={res} className='hImg' style={{ margin: 0 }} />
              </div>
            </div>
          ) : body ? (
            <div className='dImg'>{body(res)}</div>
          ) : null}
          {footer ? <div style={{ marginTop: 'auto' }}>{footer(res)}</div> : null}
        </div>
      ))}
    </div>
  );
};

export default MynyfyHalfCard;
