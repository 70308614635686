import { persistReducer, persistStore } from 'redux-persist';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import storage from 'redux-persist/lib/storage';
import rootReducer from './reducers';
import rootSaga from './sagas';
import { configureStore } from '@reduxjs/toolkit';

// Initialize saga middleware
const sagaMiddleware = createSagaMiddleware();

// Logger middleware (only in development mode)
const loggerMiddleware = createLogger({
  predicate: () => process.env.REACT_APP_ENV === 'development',
});

// Create an array of middleware including saga and logger
const middleware = [sagaMiddleware, loggerMiddleware];

// Persist configuration
const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
    'location',
    'mynyfyStats',
    'appData',
    'user',
    'mynts',
    'flashAds',
    'bag',
    'visitedStores',
  ],
};

// Persisted reducer configuration
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure store with persisted reducer and middleware
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middleware),
  devTools: process.env.REACT_APP_ENV !== 'production', // Enable devTools only in non-production environments
});

// Create persistor to persist store
const persistor = persistStore(store);

// Run the saga middleware
sagaMiddleware.run(rootSaga);

export { store, persistor };
