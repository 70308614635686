import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';
import DownloadApp from '../screens/DownloadApp';
import Policies from '../screens/Policies';
import PageNotFound from '../screens/PageNotFound';
import PrivacyPolicy from '../screens/Policies/PrivacyPolicy';
import RefundPolicy from '../screens/Policies/RefundPolicy';
import ReturnPolicy from '../screens/Policies/ReturnPolicy';
import JoinByScan from '../screens/JoinByScan';
import AboutUs from '../screens/Policies/AboutUs';
import ContactUs from '../screens/Policies/ContactUs';
import AdPolicy from '../screens/Policies/AdPolicy';
import Brands from '../screens/Brands';
import WebBrandDetails from '../screens/Brands/WebBrandDetails';
import Stores from '../screens/Stores';
import WebStoreDetails from '../screens/Stores/WebStoreDetails';
import WebContestDetails from '../screens/Contest/WebContestDetails';
import DayDealDetails from '../screens/Promos/DayDeal/DayDealDetails';
import WebDayDeal from '../screens/Promos/DayDeal/WebDayDeal';
import Jackpot from '../screens/Contest/Jackpot';
import ShopAndWin from '../screens/Contest/ShopAndWin';
import ScanAndWin from '../screens/Contest/ScanAndWin';
import UserPolicy from '../screens/Policies/UserPolicy';
import WebProductsDetails from '../screens/Products/WebProductDetails';
import OfflineStores from '../screens/Stores/OfflineStores';
import Rewards from '../screens/Promos/Rewards';
import WebPromoDetails from '../screens/Promos/WebPromoDetails';
import OfflineFoodStores from '../screens/Stores/OfflineFoodStores';
import OnlineFoodStores from '../screens/Stores/OnlineFoodStores';
import WebDealsLayout from '../screens/Home/WebDealsLayout';
import WebFoodLayout from '../screens/Home/WebFoodLayout';
import WebShopingLayout from '../screens/Home/WebShopingLayout';
import WebProductCart from '../screens/Products/WebProductCart';
import TableOrder from '../screens/Orders/TableOrder';
import WebDayDealDetails from '../screens/Promos/DayDeal/WebDayDealDetails';
import WebSignIn from '../screens/Brands/webSignIn';
import WebRegistration from '../screens/Brands/WebRegistration';
import WebProfile from '../screens/Profile/WebProfile';
import WebBrandProductDetails from '../screens/Products/WebBrandProductDetails';
import WebBrandProductCart from '../screens/Products/WebBrandProductCart';
import WebShopAndWin from '../screens/Contest/ShopAndWin/WebShopAndWin';

const WebCommonStack = () => {
  return (
    <Fragment>
      <Route path='DownloadApp' element={<DownloadApp />} />
      <Route path='Product/:id' element={<WebProductsDetails />} />
      <Route path='BrandProduct/:id' element={<WebBrandProductDetails />} />
      <Route path='Products/Bag' element={<WebProductCart />} />
      <Route path='TableOrder/:table' element={<TableOrder />} />

      {/* Brands & Stores */}
      <Route path='Brands' element={<Brands />} />
      <Route path='Brands/:brand' element={<WebBrandDetails />} />
      <Route path='BrandProducts/Bag' element={<WebBrandProductCart />} />
      <Route path='OnlineStores' element={<Stores />} />
      <Route path='OfflineStores' element={<OfflineStores />} />
      <Route path='TakeAwayRestaurants' element={<OfflineFoodStores />} />
      <Route path='HomeDeliveryRestaurants' element={<OnlineFoodStores />} />
      <Route path='Stores/:store/:table?' element={<WebStoreDetails />} />
      <Route path='Profile' element={<WebProfile />} />
      <Route path='WebSignIn' element={<WebSignIn />} />
      <Route path='Register' element={<WebRegistration />} />

      {/* Contests */}
      <Route path='Jackpots' element={<Jackpot />} />
      <Route path='ScanToJoin' element={<ScanAndWin />} />
      <Route path='ShopToJoin' element={<WebShopAndWin />} />
      <Route path='Contest/:id' element={<WebContestDetails />} />
      <Route path='JoinContest' element={<JoinByScan />} />

      {/* Promos */}
      <Route path='Deals/:id' element={<WebDayDealDetails />} />
      <Route path='Rewards' element={<Rewards />} />
      <Route path='Rewards/:id' element={<WebPromoDetails />} />

      {/* policies */}
      <Route path='Policies' element={<Policies />} />
      <Route path='Policies/Terms&Conditions' element={<PrivacyPolicy />} />
      <Route path='Policies/PrivacyPolicy' element={<PrivacyPolicy />} />
      <Route path='Policies/Cancellation&RefundPolicy' element={<RefundPolicy />} />
      <Route path='Policies/Shipping&ReturnPolicy' element={<ReturnPolicy />} />
      <Route path='Policies/PublishAdPolicy' element={<AdPolicy />} />
      <Route path='AboutUs' element={<AboutUs />} />
      <Route path='ContactUs' element={<ContactUs />} />
      <Route path='Policies/:userPolicy' element={<UserPolicy />} />

      {/* <Route path='WebDealsLayout' element={<WebDealsLayout />} /> */}
      <Route path='FoodLayout' element={<WebFoodLayout />} />
      <Route path='ShopingLayout' element={<WebShopingLayout />} />

      <Route path='*' element={<PageNotFound />} />
    </Fragment>
  );
};

export default WebCommonStack;
