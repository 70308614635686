import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';
import WebProfile from '../screens/Profile/WebProfile';
import WebChangePassword from '../screens/Auth/WebChangePassword';
import OrderDetails from '../screens/Orders/OrderDetails';
import JoinScanAndWinQR from '../screens/Contest/ScanAndWin/JoinScanAndWinByQR';
import WebCoupons from '../screens/Promos/WebCoupons';
import WebPromoDetails from '../screens/Promos/WebPromoDetails';
import Favourites from '../screens/Favourites';
import Refer from '../screens/Refer';
import FlashAdsList from '../screens/FlashAds/FlashAdsList';
import PublishAds from '../screens/PublishAd';
import PublishAdDetails from '../screens/PublishAd/PublishAdDetails';
import InterestedUsers from '../screens/PublishAd/InterestedUsers';
import EditPublishAd from '../screens/PublishAd/EditPublishAd';
import FlashAdsDetails from '../screens/FlashAds/FlashAdsDetails';
import WebOrders from '../screens/Orders/Webindex';
import WebEditProfile from '../screens/Profile/WebEditProfile';
import WebEditAddress from '../screens/Address/WebEditAddress';
import WebAddress from '../screens/Address/WebAddress';
import WebNotifications from '../screens/Notifications/WebNotifications';
import AdsList from '../screens/AdsFeed/AdsList';
import WebAdsList from '../screens/AdsFeed/WebAdsList';
import BrandsList from '../screens/Brands/BrandsList';
import WebHome from '../screens/Home/WebHome';
import WebPromos from '../screens/Promos/WebPromos';
import WebCreateFlashAds from '../screens/FlashAds/WebCreateFlashAds';
import WebCreatePublishAd from '../screens/PublishAd/WebCreatePublishAd';
import WebAdsFeed from '../screens/AdsFeed/WebAdsFeed';
import WebShoppingLayout from '../screens/Home/WebShopingLayout';
import WebFoodLayout from '../screens/Home/WebFoodLayout';
import WebDayDeal from '../screens/Promos/DayDeal/WebDayDeal';
import WebDayDealDetails from '../screens/Promos/DayDeal/WebDayDealDetails';
import WebStoresList from '../screens/Stores/WebStoresList';
import WebBrandDetails from '../screens/Brands/WebBrandDetails';
import WebBrandProductDetails from '../screens/Brands/WebBrandProductDetails';
import WebSignIn from '../screens/Brands/webSignIn';
import WebRegistration from '../screens/Brands/WebRegistration';
import WebTransactions from '../screens/Transactions/WebTransactions';
import WebRewards from '../screens/Promos/WebRewards';
import WebPaymentsHistory from '../screens/Transactions/WebPaymentsHistory';
import WebPreviewAd from '../screens/PublishAd/WebPreviewAd';
import WebJackpot from '../screens/Contest/Jackpot/webJackpot';
import WebPosts from '../screens/AdsFeed/WebPosts';
import WebStoreDetails from '../screens/Stores/WebStoreDetails';
import WebShopAndWin from '../screens/Contest/ShopAndWin/WebShopAndWin';
import WebContestDetails from '../screens/Contest/WebContestDetails';

const WebAppStack = ({ isMynyfy, storeData, brandData, noData }) => {
  return (
    <Fragment>
      <Route
        path='/'
        element={
          storeData || noData ? (
            <WebStoreDetails storeData={storeData} noData={noData} />
          ) : brandData ? (
            <WebBrandDetails brandData={brandData} />
          ) : (
            <WebHome />
          )
        }
      />
      <Route path='WebShoppingLayout' element={<WebShoppingLayout />} />
      <Route path='WebFoodLayout' element={<WebFoodLayout />} />
      <Route path='WebPromos' element={<WebPromos />} />
      <Route path='WebNotifications' element={<WebNotifications />} />
      <Route path='Jackpots' element={<WebJackpot />} />
      <Route path='Profile' element={<WebProfile />} />
      <Route path='EditProfile' element={<WebEditProfile />} />
      <Route path='ScanToJoin/QrScanner' element={<JoinScanAndWinQR />} />
      <Route path='Coupons' element={<WebCoupons />} />
      <Route path='Coupons/:id' element={<WebPromoDetails />} />
      <Route path='Orders' element={<WebOrders />} />
      <Route path='Orders/:orderId' element={<OrderDetails />} />
      <Route path='ChangePassword' element={<WebChangePassword />} />
      <Route path='Address' element={<WebAddress />} />
      <Route path='EditAddress' element={<WebEditAddress />} />
      <Route path='Favourites' element={<Favourites />} />
      <Route path='WebTransactions' element={<WebTransactions />} />
      <Route path='WebPaymentsHistory' element={<WebPaymentsHistory />} />
      <Route path='Refer' element={<Refer />} />
      <Route path='Posts' element={<WebAdsFeed />} />
      <Route path='WebPosts' element={<WebPosts />} />
      <Route path='WebAdsList' element={<WebAdsList />} />
      <Route path='BrandsList' element={<BrandsList />} />
      <Route path='Deals' element={<WebDayDeal />} />
      <Route path='Deals/:id' element={<WebDayDealDetails />} />
      <Route path='WebStoresList' element={<WebStoresList />} />{' '}
      <Route path='WebBrandProductDetails' element={<WebBrandProductDetails />} />
      <Route path='WebStoresList' element={<WebStoresList />} />
      <Route path='WebRewards' element={<WebRewards />} />
      <Route path='WebSignIn' element={<WebSignIn />} />
      <Route path='Rewards/:id' element={<WebPromoDetails />} />{' '}
      <Route path='Register' element={<WebRegistration />} />
      {/* Flash Ads */}
      <Route path='FlashAds' element={<FlashAdsList />} />
      <Route path='FlashAds/:id' element={<FlashAdsDetails />} />
      {/* <Route path='FlashAds/Create' element={<CreateFlashAds />} /> */}
      <Route path='AdsList' element={<AdsList />} />
      <Route path='FlashAds/Create' element={<WebCreateFlashAds />} />
      {/* Publish Ads */}
      <Route path='PublishAds' element={<PublishAds />} />
      <Route path='PublishAds/:id' element={<PublishAdDetails />} />
      <Route path='PublishAds/Edit/:id' element={<EditPublishAd />} />
      <Route path='PublishAds/InterestedUsers/:id' element={<InterestedUsers />} />
      <Route path='PublishAds/Create' element={<WebCreatePublishAd />} />
      <Route path='PublishAds/Create/WebPreviewAd' element={<WebPreviewAd />} />
      {/* Contest */}
      <Route path='ShopToJoin' element={<WebShopAndWin />} />
      <Route path='Contest/:id' element={<WebContestDetails />} />
    </Fragment>
  );
};

export default WebAppStack;
