import { combineReducers } from 'redux';
import { USER_LOGOUT } from '../actionTypes';
import user from './user.reducer';
import snackbar from './snackbar.reducer';
import contest from './contest.reducer';
import appData from './appData.reducer';
import location from './location.reducer';
import mynts from './mynts.reducer';
import brands from './brand.reducer';
import stores from './store.reducer';
import promos from './promo.reducer';
import isPaymentInitialized from './paymentInitialized.reducer';
import isNewOrder from './newOrder.reducer';
import nonViewedCouponsCount from './nonViewedCouponsCount.reducer';
import orders from './order.reducer';
import bottomTab from './bottomTab.reducer';
import referPoints from './referPoints.reducer';
import appState from './appState.reducer';
import bag from './bag.reducer';
import flashAds from './flashAd.reducer';
import isFlashAdSeen from './flashAdSeen.reducer';
import notifications from './notifications.reducer';
import publishAds from './publishAds.reducer';
import dealVideo from './dealVideo.reducer';
import mynyfyStats from './mynyfyStats.reducer';
import bagPromo from './bagPromo.reducer';
import visitedStores from './visitedStores.reducer';

const appReducer = combineReducers({
  user,
  contest,
  snackbar,
  appData,
  location,
  mynts,
  brands,
  stores,
  promos,
  isNewOrder,
  isPaymentInitialized,
  nonViewedCouponsCount,
  orders,
  bottomTab,
  referPoints,
  appState,
  bag,
  flashAds,
  isFlashAdSeen,
  notifications,
  publishAds,
  dealVideo,
  mynyfyStats,
  bagPromo,
  visitedStores,
});

const rootReducer = (state, action) => {
  if (action.type === USER_LOGOUT) {
    state = undefined;
  }
  return appReducer(state, action);
};
export default rootReducer;
