import React, { useEffect, useState } from 'react';
import MynyfyText from '../../../components/MynyfyText';
import MynyfyEmptyMsg from '../../../components/MynyfyEmptyMsg';
import { Card } from '@mui/material';
import JackpotVideo from './JackpotVideo';
import { s3ToCloudFront, objectReplace } from '../../../utils/CommonMethods';
import { ChevronLeftRounded } from '@mui/icons-material';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { contestDetailsOptions } from '../../Contest/ContestHelper';
// import { objectReplace } from '../../../utils/CommonMethods'
import ContestDetailsNew from '../ContestDetailsNew';
import MynyfyButton from '../../../components/MynyfyButton';
import contestService from '../../../services/contestService';
import { fetchMynts } from '../../../redux/actions/mynts.action';
import transactionService from '../../../services/transactionService';
import { snackbarInfo } from '../../../redux/actions/snackbar.action';
import { fetchJackpot } from '../../../redux/actions/contest.action';

import './jackpot.scss';
import _ from 'lodash';
import { MsgEnums } from '../../../utils/MsgEnums';
import MynyfyLoader from '../../../components/MynfyfLoader';

const JackpotNew = () => {
  const jackpots = useSelector((state) => state.contest?.jackpot);
  const user = useSelector((state) => state.user);
  const id = user.user?.id || null;
  const mynts = useSelector((state) => state.mynts);
  const isLoggedIn = user?.isLoggedIn;
  const location = useSelector((state) => state?.location?.location);
  const [drawerDetails, setDrawerDetails] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [options, setOptions] = useState(null);
  const [sortedDraws, setSortedDraws] = useState(null);
  const [isActive, setIsActive] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const closeModel = () => {
    setShowDetails(false);
  };

  const joinDraw = async (joinDrawObj) => {
    if (joinDrawObj) {
      let data = {
        _id: joinDrawObj._id,
        buyerId: id,
        image: null,
        amount: 0,
      };
      contestService.enterContest(data).then((res) => {
        if (res.success) {
          let reqObj = jackpots?.find((e) => e._id === joinDrawObj._id);
          if (reqObj) {
            reqObj.isContested = true;
            let newData = objectReplace(jackpots, reqObj, '_id');
            dispatch(fetchJackpot(newData));
          }
          getPoints();
          dispatch(
            snackbarInfo({
              open: true,
              type: 'success',
              message: `Thank you for Participating in ${joinDrawObj.name}`,
            })
          );
        } else {
          if (res?.message?.toLowerCase().includes('Insufficient MYNTS'.toLowerCase())) {
          }
          dispatch(snackbarInfo({ open: true, type: 'error', message: res.message }));
        }
      });
    } else {
      dispatch(snackbarInfo({ open: true, type: 'error', message: MsgEnums.FILL_DETAILS }));
    }
  };

  const getPoints = () => {
    transactionService.getPoints().then((res) => {
      if (res?.data?.points) {
        dispatch(fetchMynts(res?.data?.points));
      }
    });
  };

  const onJoinJackpot = (contestDetails) => {
    joinDraw(contestDetails);
  };
  useEffect(() => {
    getContests();
  }, []);
  const getContests = () => {
    setIsLoading(true);

    let params = {
      status: 'NOACTIVE',
      isCats: false,
      state: location?.state?._id,
      city: location?.city?._id,
      area: location?.area?._id,
      type: 'JACKPOT',
      buyerId: id,
    };

    contestService
      .getContests(params)
      .then((res) => {
        if (res.success) {
          let sorted = _.orderBy(res.data, 'createdAt', 'desc');
          contestDataHandler(sorted);
        } else {
          contestDataHandler([]);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const contestDataHandler = (contestData) => {
    setSortedDraws(contestData);
  };

  return (
    <div id='jackpotNew'>
      <div className='headerjackpot rowSB'>
        <ChevronLeftRounded style={{ fontSize: 35 }} onClick={() => navigate('/')} />
        <div className='row myntsContainer'>
          <img
            src={require('../../../assets/imgs/icons/coin.png')}
            style={{ width: 24, height: 24 }}
          />
          <MynyfyText title={mynts} style={{ paddingLeft: 5, paddingRight: 3 }} />
        </div>
      </div>
      <div className='titleContainer'>
        <MynyfyText title={'CURATED'} h3 center bold style={{ letterSpacing: 3 }} />
        <MynyfyText
          title={'JACKPOTS'}
          h2
          center
          bold
          style={{ paddingBottom: 5, letterSpacing: 3 }}
        />

        <MynyfyText
          title={'REWARDS FROM LEADING BRANDS, BROUGHT TO YOU BY MYNYFY.'}
          center
          xSmall
        />
      </div>
      {_.isEmpty(jackpots) ? (
        <MynyfyEmptyMsg message={'No active Jackpots at this Time'} paddingTop={10} small={true} />
      ) : (
        <div
          className='offerJackpot'
          style={{ display: 'flex', overflowX: 'auto', gap: '15px', marginBottom: '70px' }}>
          {jackpots?.map((contestDetails, i) => {
            return (
              <div key={i}>
                <Card className='cursor homeContestCardjackpot'>
                  {contestDetails.video ? (
                    <div onClick={(event) => event.stopPropagation()}>
                      <JackpotVideo contestDetails={contestDetails} />
                    </div>
                  ) : (
                    <>
                      <img
                        src={s3ToCloudFront(contestDetails.image)}
                        alt={contestDetails.name}
                        className='contestImageJackpot'
                        onClick={() => {
                          let options = contestDetailsOptions(contestDetails.type, false);
                          setShowDetails(true);
                          setOptions(options);
                          setDrawerDetails(contestDetails);
                          setIsActive(true);
                        }}
                      />
                    </>
                  )}
                  <div className='row'>
                    <MynyfyText
                      title={contestDetails.name}
                      style={{ padding: '10px', color: 'black' }}
                      className='singleLine scrollingText'
                      pBig
                      bold
                    />
                  </div>
                  <MynyfyButton
                    label={
                      contestDetails.isContested
                        ? 'Joined'
                        : `Join with ${contestDetails.myntsToJoin} mynts`
                    }
                    fullWidth
                    containerStyle={{ margin: '0px' }}
                    style={{
                      borderTopLeftRadius: '0',
                      borderTopRightRadius: '0',
                      borderBottomLeftRadius: '4px',
                      borderBottomRightRadius: '4px',
                      backgroundColor: contestDetails.isContested ? '#B0B0B0' : '#007BFF',
                    }}
                    onClick={() => onJoinJackpot(contestDetails)}
                    link
                  />
                </Card>
              </div>
            );
          })}
        </div>
      )}

      {isLoading ? (
        <MynyfyLoader paddingTop={50} />
      ) : _.isEmpty(sortedDraws) ? (
        <MynyfyEmptyMsg message={'No Jackpots in previous'} paddingTop={10} small={true} />
      ) : (
        <div className='pastContainer'>
          <MynyfyText
            title={'Past Jackpots'}
            h4
            style={{ padding: '20px 10px', color: 'black', opacity: 0.8 }}
            bold
          />
          <div className='inactiveContainer'>
            {sortedDraws?.map((contestDetails, i) => (
              <div
                key={i}
                className='cursor inactiveCard'
                onClick={() => {
                  let options = contestDetailsOptions(contestDetails.type, false);
                  setShowDetails(true);
                  setOptions(options);
                  setDrawerDetails(contestDetails);
                  setIsActive(false);
                }}>
                {contestDetails.video ? (
                  <div onClick={(event) => event.stopPropagation()} className='contestPastImg'>
                    <JackpotVideo contestDetails={contestDetails} />
                  </div>
                ) : contestDetails.image ? (
                  <img
                    src={s3ToCloudFront(contestDetails.image)}
                    alt={contestDetails.name}
                    className='contestPastImg'
                  />
                ) : (
                  <div className='noImgContainer'>No Image Available</div>
                )}
                <div className='row'>
                  <MynyfyText
                    title={contestDetails.name}
                    style={{ padding: '3px' }}
                    className='singleLine'
                    pBig
                    bold
                  />
                </div>
                <MynyfyButton
                  label={'View Winners'}
                  fullWidth
                  containerStyle={{ margin: '0px' }}
                  style={{
                    borderTopLeftRadius: '0',
                    borderTopRightRadius: '0',
                    borderBottomLeftRadius: '4px',
                    borderBottomRightRadius: '4px',
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      )}
      {showDetails && (
        <ContestDetailsNew
          state={{
            drawDetails: drawerDetails,
            detailOptions: options,
            closeModal: closeModel,
            showDetails: showDetails,
            isActive: isActive,
          }}
        />
      )}
    </div>
  );
};

export default JackpotNew;
