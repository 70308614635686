import React from 'react';
import MynyfyText from './MynyfyText';
import _ from 'lodash';

const MynyfyEmptyMsg = ({ containerStyle, paddingTop, bold, message, style, small }) => {
  return (
    <div style={{ paddingTop: paddingTop || '45vh', ...containerStyle }}>
      {_.isArray(message) ? (
        <div style={{ padding: 20, ...style }}>
          {message?.map((res, i) => (
            <MynyfyText
              title={res || ''}
              bold={bold?.includes(i) ? true : false}
              center
              pBig
              key={i}
              style={{ paddingTop: 2 }}
            />
          ))}
        </div>
      ) : (
        <MynyfyText
          title={message || ''}
          center
          pBig={!small}
          small={small}
          bold={bold}
          style={{ padding: 20, ...style }}
        />
      )}
    </div>
  );
};

export default MynyfyEmptyMsg;
