import React, { Fragment, useState } from 'react';
import MynyfyText from '../../components/MynyfyText';
import LocationFields from './LocationFields';
import './location.scss';
import validate from '../../utils/validate';
import MynyfyButton from '../../components/MynyfyButton';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { updateLocation } from '../../redux/actions/location.action';
import MynyfyModal from '../../components/MynyfyModal';
import { RELOAD_WITH_LOC_WATCHER } from '../../redux/actionTypes';
import profileService from '../../services/profileService';
import { HighlightOffRounded } from '@mui/icons-material';
import { snackbarInfo } from '../../redux/actions/snackbar.action';
import { fetchBagCalculation, fetchBagProducts } from '../../redux/actions/bag.action';

let VALIDATOR = {
  selectedState: [(value) => (value ? null : 'Select your State')],
  selectedCity: [(value) => (value ? null : 'Select your City')],
  selectedArea: [(value) => (value ? null : 'Select your Area')],
};

const LocationModal = ({ isOpen, close, closeBtn, content, modalProps }) => {
  const dispatch = useDispatch();

  const bagProducts = useSelector((state) => state.bag?.products);
  const user = useSelector((state) => state.user);
  const id = user?.user?.id || null;
  const profileId = user?.user?.profile || null;

  const [error, setError] = useState({});
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedArea, setSelectedArea] = useState(null);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [locationChanged, setLocationChanged] = useState(false);

  const locationValidation = () => {
    let obj = { selectedState, selectedCity, selectedArea };
    let isErrors = validate(obj, VALIDATOR);
    setError(isErrors);
    if (_.isEmpty(isErrors)) {
      if (!_.isEmpty(bagProducts)) setShowAlertModal(true);
      else locationApi();
    }
    return isErrors;
  };

  const locationApi = () => {
    dispatch(updateLocation({ state: selectedState, city: selectedCity, area: selectedArea }));
    if (id && profileId) {
      let data = { state: selectedState?._id, city: selectedCity?._id, area: selectedArea?._id };
      profileService.profileUpdate(data, id, profileId).catch((e) => {});
    }
    dispatch(fetchBagCalculation(null));
    dispatch(fetchBagProducts([]));
    dispatch({ type: RELOAD_WITH_LOC_WATCHER });
    close(true);
    dispatch(
      snackbarInfo({ open: true, type: 'success', message: 'Location updated successfully!' })
    );
  };

  const setDataHandler = (key, value) => {
    if (key === 'state') {
      setSelectedState(value);
    } else if (key === 'city') {
      setSelectedCity(value);
    } else if (key === 'area') {
      setSelectedArea(value);
    }
    if (key === 'state' || key === 'city' || key === 'area') {
      setLocationChanged(true);
    } else {
      setLocationChanged(false);
    }
  };

  return (
    <Fragment>
      <MynyfyModal open={isOpen} close={close} {...modalProps}>
        <div id=''>
          <div className='rowSB' style={{ marginBottom: 20 }}>
            <div />
            <MynyfyText title={'Select Location'} center pBig bold />
            <HighlightOffRounded color='error' onClick={() => closeBtn()} />
          </div>
          {!_.isEmpty(content) && <MynyfyText title={content || ''} style={{ marginBottom: 20 }} />}
          <LocationFields menuPlacement='top' error={error} sendData={setDataHandler} />
          <MynyfyButton
            label={'Set'}
            containerStyle={{ textAlign: 'center' }}
            style={{ marginTop: 10 }}
            onClick={() => {
              if (locationChanged) locationValidation();
            }}
          />
        </div>
      </MynyfyModal>
      <MynyfyModal open={showAlertModal} close={() => setShowAlertModal(false)}>
        <div id=''>
          <MynyfyText title={'Are you sure?'} h4 semiBold />
          <MynyfyText
            title={
              'All your products that you have added to bag will be removed by changing your location.'
            }
            style={{ marginTop: 10 }}
          />
          <div className='rowSB' style={{ marginTop: 20 }}>
            <MynyfyButton
              label={'Yes, Proceed'}
              onClick={() => {
                locationApi();
                setShowAlertModal(false);
              }}
            />
            <MynyfyButton
              label={'Cancel'}
              onClick={() => {
                setShowAlertModal(false);
                close();
              }}
            />
          </div>
        </div>
      </MynyfyModal>
    </Fragment>
  );
};

export default LocationModal;
