import React, { Fragment, useEffect, useRef, useState } from 'react';
import MynyfyModal from '../../components/MynyfyModal';
import MynyfyText from '../../components/MynyfyText';
import MynyfySpan from '../../components/MynyfySpan';
import MynyfyButton from '../../components/MynyfyButton';
import MynyfyRadioButton from '../../components/MynyfyRadioButton';
import { useDispatch, useSelector } from 'react-redux';
import { decrementHandler, incrementHandler, qtyHandler } from './AddProductHelper';
import { ControlPointRounded, RemoveCircleOutlineRounded } from '@mui/icons-material';
import theme from '../../utils/theme';
import ProductCartHelper from './ProductCartHelper';

const SelectVariantForOrder = ({
  isOpen,
  close,
  addToBagHandler,
  product,
  isMobile,
  brandDetails,
  sellerDetails,
  navFrom,
  table,
}) => {
  const dispatch = useDispatch();
  const inputRefs = useRef({});

  const bag = useSelector((state) => state.bag);

  const [selectedVariant, setSelectedVariant] = useState(null);

  useEffect(() => {
    if (product) setSelectedVariant(product.variants[0]);
  }, [product]);

  const closeHandler = () => {
    setSelectedVariant(null);
    close();
  };

  const qtyButtonHandler = (key, value) => {
    let obj = {
      productDetails: product,
      variant: selectedVariant,
      dispatch,
      bag,
    };
    if (value) obj.value = value;
    if (key == 'inc') incrementHandler(obj);
    else if (key == 'dec') decrementHandler(obj);
    else if (key == 'qty') qtyHandler(obj);
  };

  return (
    <MynyfyModal open={isOpen} close={closeHandler}>
      {product ? (
        <div id='Products'>
          <MynyfyText title={product.name} style={{ textTransform: 'capitalize' }} bold h6 />
          <div style={{ paddingBlock: 10 }}>
            {product?.variants?.map((item, i) => (
              <MynyfyRadioButton
                key={i}
                LableComponent={() => (
                  <div className='rowSB' style={{ width: '100%' }}>
                    <div className='row' style={{ gap: 10 }}>
                      <div>
                        {item.size ? (
                          <MynyfySpan title={item.size} style={{ textTransform: 'capitalize' }} />
                        ) : null}
                        {item.color ? (
                          <div
                            style={{
                              backgroundColor: item.color,
                              width: 10,
                              height: 10,
                              borderRadius: 3,
                            }}></div>
                        ) : null}
                      </div>

                      {bag?.products?.find((e) => e.variantId == item._id)?.count ? (
                        <Fragment>
                          <MynyfyText title={'x'} />
                          <MynyfyText
                            title={bag?.products?.find((e) => e.variantId == item._id)?.count}
                            center
                            style={{
                              border: '1px solid var(--PRIMARY_BLUE)',
                              minWidth: 10,
                              borderRadius: 4,
                              padding: '0px 4px',
                            }}
                          />
                        </Fragment>
                      ) : null}
                    </div>
                    <div>
                      <MynyfySpan
                        title={'Rs.' + item.actualPrice}
                        style={{
                          textDecoration:
                            item.price && item.actualPrice !== item.price
                              ? 'line-through'
                              : 'unset',
                        }}
                        pSmall={item.price && item.actualPrice !== item.price ? true : false}
                      />
                      {item.actualPrice == item.price || !item.price ? null : (
                        <MynyfySpan title={'Rs.' + item.price} style={{ paddingLeft: 5 }} />
                      )}
                    </div>
                  </div>
                )}
                onClick={() => setSelectedVariant(item)}
                isSelected={selectedVariant?._id == item._id ? true : false}
                style={{ marginBlock: 10 }}
              />
            ))}
          </div>

          {bag?.products?.find((e) => e.variantId == selectedVariant?._id) ? (
            <div className='row' style={{ gap: 20, marginLeft: 15 }}>
              <div className='rowC' style={{ flex: 1 }}>
                <RemoveCircleOutlineRounded
                  color='primary'
                  fontSize='medium'
                  className='cursor'
                  onClick={() => {
                    qtyButtonHandler('dec');
                    if (!isMobile) inputRefs.current.focus();
                  }}
                />
                <input
                  value={
                    bag?.products?.find((e) => e.variantId == selectedVariant?._id)?.count || 0
                  }
                  onChange={(e) => qtyButtonHandler('qty', e.target.value)}
                  autoFocus={isMobile ? false : true}
                  ref={(el) => (inputRefs.current = el)}
                  className='qtyInput detailsInput'
                />
                <ControlPointRounded
                  color='primary'
                  fontSize='medium'
                  className='cursor'
                  onClick={() => {
                    qtyButtonHandler('inc');
                    if (!isMobile) inputRefs.current.focus();
                  }}
                />
              </div>
              <div style={{ flex: 1 }}>
                <ProductCartHelper
                  brandDetails={brandDetails}
                  sellerDetails={sellerDetails}
                  navFrom={navFrom}
                  table={table}
                  from={'modal'}
                />
              </div>
            </div>
          ) : (
            <MynyfyButton
              label={
                selectedVariant
                  ? (
                      selectedVariant.count
                        ? selectedVariant.count - selectedVariant.ordersCount > 0
                          ? true
                          : false
                        : true
                    )
                    ? 'Add to Bag'
                    : 'Out of Stock'
                  : 'Select a Variant'
              }
              fullWidth
              onClick={() => {
                if (selectedVariant) {
                  if (
                    selectedVariant.count
                      ? selectedVariant.count - selectedVariant.ordersCount > 0
                        ? true
                        : false
                      : true
                  ) {
                    addToBagHandler(product, selectedVariant);
                  }
                }
              }}
            />
          )}
        </div>
      ) : null}
    </MynyfyModal>
  );
};

export default SelectVariantForOrder;
