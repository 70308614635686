import React, { Fragment } from 'react';
import MynyfyText from '../../components/MynyfyText';
import { Card } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import _ from 'lodash';
import { exchangePolicy, returnPolicy } from '../../utils/CommonPolicies';

function Policies({ details }) {
  const navigate = useNavigate();

  const [open, setOpen] = useState(true);
  return (
    <div style={{ paddingInline: '15px' }}>
      <Card className='aboutSections' style={{ marginTop: 15, padding: 10 }}>
        <MynyfyText
          title={'Policies'}
          h5
          semiBold
          className='cursor'
          onClick={() => setOpen(!open)}
        />
        {open ? (
          <Fragment>
            {_.isEmpty(details?.policies?.shipping) ? null : (
              <div className='userPolicyOptionCntainer'>
                <Card
                  className='userPolicyOption'
                  style={{ marginTop: '5px' }}
                  onClick={() => {
                    navigate(`/Policies/ShippingAndDeliveryPolicy`, {
                      state: {
                        policy: details?.policies?.shipping,
                        policyName: 'Shipping / Delivery Policy',
                      },
                    });
                  }}>
                  <MynyfyText title={'Shipping / Delivery Policy'} />
                </Card>
              </div>
            )}
            <div className='userPolicyOptionCntainer'>
              <Card
                className='userPolicyOption'
                style={{ marginTop: '5px' }}
                onClick={() => navigate('/Policies/PrivacyPolicy')}>
                <MynyfyText title={'Privacy Policy'} />
              </Card>
            </div>
            <div className='userPolicyOptionCntainer'>
              <Card
                className='userPolicyOption'
                style={{ marginTop: '5px' }}
                onClick={() => {
                  navigate(`/Policies/RefundAndExchangePolicy`, {
                    state: {
                      policy: details?.policies?.isReturn
                        ? details?.policies?.refund
                        : returnPolicy,
                      policy2: details?.policies?.isExchange
                        ? details?.policies?.exchange
                        : exchangePolicy,
                      policyName: `Return / Refund / Exchange Policy`,
                    },
                  });
                }}>
                <MynyfyText title={'Return / Refund / Exchange Policy'} />
              </Card>
            </div>
            <div className='userPolicyOptionCntainer'>
              <Card
                className='userPolicyOption'
                style={{ marginTop: '5px' }}
                onClick={() => {
                  navigate('/Policies/Terms&Conditions', { state: 'Terms & Conditions' });
                }}>
                <MynyfyText title={'Terms & Conditions'} />
              </Card>
            </div>
          </Fragment>
        ) : null}
      </Card>
      <MynyfyText title={'Powered by Mynyfy'} primary style={{ marginTop: 10, textAlign: 'end' }} />
    </div>
  );
}

export default Policies;
