import React, { useEffect, useState } from 'react';
import { ThemeProvider } from '@mui/material';
import { MuiTheme } from './utils/MuiTheme';
import { MynyfyRoutes } from './MynyfyRoutes';
import { Provider as StoreProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './redux';
import './App.scss';
import { setAuthToken } from './utils/apiHelper';
import { MynyfyEnums } from './utils/MynyfyEnums';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import authService from './services/authService';
import jwtDecode from 'jwt-decode';
import { webSiteCheck } from './utils/CommonMethods';
import Splash from './screens/Splash';

function App() {
  const [showPrompt, setShowPrompt] = useState(false);
  const [websiteData, setWebsietData] = useState(null);

  useEffect(() => {
    // const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    // const isIOS = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
    // const isInstagram = /Instagram/.test(userAgent);
    // if (isIOS && isInstagram) {
    //   setShowPrompt(true);
    // }

    const websiteHandler = async () => {
      let { isMynyfy, isMobile, storeData, brandData, noData } = await webSiteCheck();
      setWebsietData({ isMynyfy, isMobile, storeData, brandData, noData });
    };
    websiteHandler();
  }, []);

  useEffect(() => {
    let version = localStorage.getItem('version');
    if (version !== MynyfyEnums.VERSION) {
      localStorage.setItem('version', MynyfyEnums.VERSION);
      window.location.reload(true);
    }

    let token = localStorage.getItem('login_token');
    if (token) {
      let tokenData = jwtDecode(token);
      const currentTime = Math.floor(Date.now() / 1000);
      if (tokenData.exp && currentTime > tokenData.exp) {
        authService.refreshToken({ token }).then((res) => {
          if (res.success) authService._setToken(res.data);
        });
      } else setAuthToken(token);
    }
  }, []);

  return websiteData ? (
    <StoreProvider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {showPrompt && (
          <div
            style={{
              display: 'flex',
              height: '100vh',
              width: '100vw',
              justifyContent: 'center',
              alignItems: 'center',
              background: 'white',
              padding: '10px',
              textAlign: 'center',
              zIndex: 9999,
            }}>
            <div
              style={{
                border: '1px solid #e8e8e8',
                margin: 10,
                padding: 10,
                borderRadius: 10,
                boxShadow: '0 2px 5px 0 rgba(90, 90, 90, 0.2)',
              }}>
              <p style={{ marginBottom: 10 }}>
                For a better experience, open our website in browser.
              </p>
              <div
                style={{
                  width: '100%',
                  justifyContent: 'end',
                  display: 'flex',
                  alignItems: 'center',
                  borderRadius: 5,
                }}>
                <a
                  style={{
                    textDecoration: 'none',
                    padding: '5px 15px',
                    border: '1px solid #dddddd',
                  }}
                  href={window.location.href}
                  target='_blank'
                  color='#3076b5'
                  rel='noopener noreferrer'>
                  Open
                </a>
              </div>
            </div>
          </div>
        )}
        <ThemeProvider theme={MuiTheme}>
          <MynyfyRoutes websiteData={websiteData} />
        </ThemeProvider>
      </PersistGate>
    </StoreProvider>
  ) : (
    <Splash noLogo />
  );
}

export default App;
