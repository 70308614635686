import React, { Fragment, useEffect, useState } from 'react';
import AppStack from './AppStack';
import CommonStack from './CommonStack';
import AuthStack from './AuthStack';
import MynyfySnackbar from '../components/MynyfySnackbar';
import { useDispatch, useSelector } from 'react-redux';
import { snackbarInfo } from '../redux/actions/snackbar.action';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import EditProfile from '../screens/Profile/EditProfile';
import {
  INITIAL_RELOAD_WATCHER,
  RELOAD_WATCHER,
  RELOAD_WITH_LOC_WATCHER,
} from '../redux/actionTypes';
import { fetchAppState } from '../redux/actions/appState.action';
// import { messaging, requestForToken } from '../utils/firebase';
import { userDeviceId, userLogin, userToken } from '../redux/actions/user.action';
// import { onMessage } from 'firebase/messaging';
import { getOS } from '../utils/CommonMethods';
import WebAppStack from './WebAppStack';
import WebCommonStack from './WebCommonStack';
import jwtDecode from 'jwt-decode';
import Splash from '../screens/Splash';
import Layout from '../screens/Layout';
import WebAuthStack from './WebAuthStack';

let platorm = getOS();
let isMobile = platorm == 'android' || platorm == 'ios' ? true : false;

export const MynyfyRoutes = ({ websiteData }) => {
  const dispatch = useDispatch();
  const snackbarKeys = useSelector((state) => state.snackbar);
  const user = useSelector((state) => state.user);
  const isLoggedIn = user?.isLoggedIn;
  const profileDetails = user?.user;

  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    let token = localStorage.getItem('login_token');
    dispatch({ type: INITIAL_RELOAD_WATCHER });
    if (!token) dispatch({ type: RELOAD_WITH_LOC_WATCHER });
    if (isLoggedIn) {
      if (token && token !== user.token) {
        const newToken = jwtDecode(token);
        dispatch(userToken(token));
        dispatch(userLogin(newToken));
        setTimeout(() => {
          dispatch({ type: RELOAD_WITH_LOC_WATCHER });
          dispatch({ type: RELOAD_WATCHER });
          setLoading(false);
        }, 1000);
      } else {
        dispatch({ type: RELOAD_WITH_LOC_WATCHER });
        dispatch({ type: RELOAD_WATCHER });
        setLoading(false);
      }

      // if (!('Notification' in window)) {
      //   console.log('Browser does not support desktop notification');
      // } else if (Notification.permission === 'granted') {
      //   notificationHandler();
      // } else {
      //   Notification.requestPermission().then((permission) => {
      //     if (permission === 'granted') notificationHandler();
      //   });
      // }
    } else {
      setLoading(false);
    }
  }, [isLoggedIn]);

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  const handleVisibilityChange = () => {
    dispatch(fetchAppState(!document.hidden));
  };

  // const notificationHandler = async () => {
  //   await requestForToken({ dispatch, successAction: userDeviceId });

  //   onMessage(messaging, (payload) => {
  //     if (payload) {
  //       new Notification(payload?.notification?.title, { body: payload?.notification?.body });
  //     }
  //   });
  // };

  return (
    <div id={isMobile ? 'appContainer' : 'webAppContainer'}>
      <MynyfySnackbar
        open={snackbarKeys.open}
        vertical={snackbarKeys.vertical}
        horizontal={snackbarKeys.horizontal}
        direction={snackbarKeys.direction}
        type={snackbarKeys.type}
        message={snackbarKeys.message}
        transition={snackbarKeys.transition}
        handleClose={() => dispatch(snackbarInfo({ open: false }))}
      />

      <BrowserRouter>
        <Routes>
          {isLoading ? (
            <Route path='/' element={<Splash />} />
          ) : isLoggedIn ? (
            profileDetails?.firstName ? (
              isMobile ? (
                AppStack(websiteData)
              ) : (
                <Route element={<Layout isMynyfy={websiteData?.isMynyfy} />}>
                  {WebAppStack(websiteData)}
                </Route>
              )
            ) : (
              <Route path='/' element={<EditProfile />} />
            )
          ) : (
            <Fragment>{isMobile ? AuthStack(websiteData) : WebAuthStack(websiteData)}</Fragment>
          )}
          {isMobile ? CommonStack() : WebCommonStack()}
        </Routes>
      </BrowserRouter>
    </div>
  );
};
