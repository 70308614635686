import React, { useEffect, useState, Fragment } from 'react';
import MynyfyText from '../../components/MynyfyText';
import './promos.scss';
import { useDispatch, useSelector } from 'react-redux';
import { FEATURE_TYPES, PromoType, SortTypes } from '../../utils/enums';
import promoService from '../../services/promoService';
import { useNavigate } from 'react-router-dom';
import moment from 'moment/moment';
import _ from 'lodash';
import { fetchNonViewedCouponsCount } from '../../redux/actions/nonViewedCouponsCount.action';
import MynyfyLoader from '../../components/MynfyfLoader';
import MynyfyEmptyMsg from '../../components/MynyfyEmptyMsg';
import MynyfyHeader from '../../components/MynyfyHeader';
import theme from '../../utils/theme';
import MynyfySpan from '../../components/MynyfySpan';
import MynyfyMobileFilter from '../../components/MynyfyMobileFilter';
import MynyfyHalfCard from '../../components/MynyfyHalfCard';

const Promos = ({
  promos,
  sortedPromos,
  action,
  sortedAction,
  type,
  isFilter,
  from,
  screen,
  emptyListTitle,
  emptyListMsg,
  emptyListMsgBold,
  brandDetails,
  sellerDetails,
  goHome,
  isRestaurant,
  headerTitle,
  isDirectUrl,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector((state) => state.user);
  const id = user.user?.id || null;
  const isLoggedIn = user?.isLoggedIn;
  const location = useSelector((state) => state.location?.location);
  const nonViewedCouponsCount = useSelector((state) => state.nonViewedCouponsCount);

  const [isLoading, setIsLoading] = useState(true);
  const [isLocloading, setIsLocloading] = useState(false);
  const [locErrMsg, setLocErrMsg] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);

  useEffect(() => {
    if (_.isEmpty(sortedPromos) || from) {
      let state = location?.state?._id;
      let city = location?.city?._id;
      if (screen == 'Coupons') {
        if (isLoggedIn) getPromos(state, city);
        else setIsLoading(false);
      } else getPromos(state, city);
    } else {
      setIsLoading(false);
    }
  }, []);

  const getPromos = (state, city, area, isLoc) => {
    let body = {
      type: type,
      status: 'ACTIVE',
    };
    if (type.includes(PromoType.COUPON)) body.buyer = id;
    if (from === 'seller') {
      if (_.isEmpty(sellerDetails?.brands)) body.organization = sellerDetails._id;
      else body.brand = sellerDetails?.brands?.map((e) => e._id);
    } else if (from === 'brand') {
      body.brand = brandDetails?._id;
    } else {
      body.state = state;
      body.city = city;
      body.area = area;
    }
    if (isLoc) setIsLocloading(true);
    if (isLoggedIn) body.buyerId = id;
    if (!from) {
      if (isRestaurant == true) {
        body.category = [process.env.REACT_APP_FOOD_CAT_ID];
      } else if (isRestaurant == false) {
        body.notCategory = [process.env.REACT_APP_FOOD_CAT_ID];
      }
    }

    promoService.getPromos(body).then((res) => {
      if (res.success) {
        let redeemedPromos = [];
        let expiredPromos = [];
        let activePromos = [];
        res.data.map((details) => {
          details.days = expiryHandler(details.endDate);
          details.isRedeemed = details.claimedBy.includes(id);
          if (details.isRedeemed) {
            redeemedPromos.push(details);
          } else if (details.status === 'COMPLETED') {
            expiredPromos.push(details);
          } else if (details.days <= 0) {
            expiredPromos.push(details);
          } else if (
            (!details.count && details.count !== 0) ||
            details.count > (details.claims || 0)
          ) {
            activePromos.push(details);
          } else {
            expiredPromos.push(details);
          }
        });

        let sortedRedeemed = _.orderBy(redeemedPromos, 'createdAt', 'desc');
        let sortedExpired = _.orderBy(expiredPromos, 'createdAt', 'desc');
        let sortedActive = _.orderBy(activePromos, 'createdAt', 'desc');
        let sorted = [...sortedActive, ...sortedRedeemed, ...sortedExpired];
        promoDataHandler(sorted);
      } else {
        promoDataHandler([]);
      }
    });
  };

  const promoDataHandler = (promoData) => {
    dispatch(action(promoData));
    dispatch(sortedAction(promoData));
    setIsLocloading(false);
    setIsLoading(false);
  };

  const expiryHandler = (date) => {
    let presentDate = moment().format('DD/MM/YYYY');
    let endDate = moment(date).format('DD/MM/YYYY');
    let pDate = moment(presentDate, 'DD/MM/YYYY');
    let eDate = moment(endDate, 'DD/MM/YYYY');
    let result = eDate.diff(pDate, 'days', false);
    return result + 1;
  };

  const searchAndCatFilter = ({
    searchedText,
    selectedCategory,
    selectedSubCategory,
    selectedSort,
    sortedData,
  }) => {
    let reqData = sortedData ? sortedData : promos;
    if (!_.isEmpty(reqData)) {
      let newData = [];
      if (selectedCategory) {
        newData = reqData.filter((e) =>
          e.category ? e.category._id === selectedCategory?.value : e.category === undefined
        );
      } else {
        newData = reqData;
      }
      if (selectedSubCategory) {
        newData = newData.filter((e) =>
          e.subCategory
            ? e.subCategory._id === selectedSubCategory?.value
            : e.subCategory === undefined
        );
      }
      let modData = [];
      if (searchedText) {
        newData.map((res) => {
          if (res.name.toLowerCase().includes(searchedText.toLowerCase())) {
            modData.push(res);
          }
        });
      } else {
        modData = newData;
      }
      if (selectedSort) {
        sortFilter(selectedSort, modData);
      } else {
        dispatch(sortedAction(modData));
      }
    }
  };

  const locationFilter = (state, city, area, setIsLocationModal) => {
    if (state && city) {
      getPromos(state._id, city._id, area?._id, true);
      setSelectedLocation({ state, city, area });
      setIsLocationModal(false);
      setLocErrMsg(null);
    } else {
      setLocErrMsg('Please atleast state and city');
    }
  };

  const sortFilter = (e, sortedData) => {
    let reqData = sortedData ? sortedData : sortedPromos;
    if (!_.isEmpty(reqData)) {
      let sorted = null;
      if (e == SortTypes.LOW_TO_HIGH_MYNTS) {
        sorted = _.orderBy(reqData, 'points', 'asc');
      } else if (e == SortTypes.HIGH_TO_LOW_MYNTS) {
        sorted = _.orderBy(reqData, 'points', 'desc');
      } else if (e == SortTypes.LOW_TO_HIGH_PRICE) {
        sorted = _.orderBy(reqData, 'value', 'asc');
      } else if (e == SortTypes.HIGH_TO_LOW_PRICE) {
        sorted = _.orderBy(reqData, 'value', 'desc');
      }
      if (sorted) {
        dispatch(sortedAction(sorted));
      }
    }
  };

  const viewPromoCall = (item) => {
    if (!item.isViewed) {
      let data = {};
      if (location) {
        if (location?.state) data.state = location?.state?._id;
        if (location?.city) data.city = location?.city?._id;
        if (location?.area) data.area = location?.area?._id;
      }

      promoService
        .viewPromo(item._id, data)
        .then((res) => {
          if (res.success) {
            let clonedData = _.cloneDeep(sortedPromos);
            let clonedActualData = _.cloneDeep(promos);
            let promoIndex = clonedData.findIndex((e) => e._id === item._id);
            let actualIndex = clonedActualData.findIndex((e) => e._id === item._id);
            if (promoIndex > -1) {
              clonedData[promoIndex].isViewed = true;
              clonedActualData[actualIndex].isViewed = true;
              dispatch(action(clonedActualData));
              dispatch(sortedAction(clonedData));
            }
            if (res.data === 'View Added') {
              dispatch(
                fetchNonViewedCouponsCount(
                  nonViewedCouponsCount ? nonViewedCouponsCount - 1 : nonViewedCouponsCount
                )
              );
            }
          }
        })
        .catch((e) => console.log(e));
    }
  };

  const onStylesDeals = (item) => {
    return item.claimedBy.includes(id) && item.type === PromoType.DAY_DEAL
      ? 'statusGreen'
      : item.days <= 0 || item.status === 'COMPLETED'
      ? 'statusRed'
      : (!item.count && item.count !== 0) || item.count - (item.claims || 0)
      ? 'statusGreen'
      : 'statusRed';
  };

  const onDealsTitle = (item) => {
    return item.type === PromoType.DAY_DEAL
      ? item.days <= 0
        ? 'Expired'
        : item.status === 'COMPLETED'
        ? 'Completed'
        : item.count && item.count - (item.claims || 0) > 0
        ? `${item.count - (item.claims || 0)} Left`
        : !item.count && item.count !== 0
        ? 'Unlimited'
        : 'Completed'
      : null;
  };

  const customComponent = (item) => {
    return (
      <Fragment>
        {item.type === PromoType.DAY_DEAL ? (
          item.isRedeemed ||
          (item.days > 0 ? false : true) ||
          item.status === 'COMPLETED' ||
          item.status === 'REDEEMED' ? (
            <div className='expiredContainer' onClick={() => detailsNavHandler(item)} />
          ) : null
        ) : null}

        {/* {item.isRedeemed ? (
          item.type === PromoType.DAY_DEAL ? (
            <div className='expiredContainer itemCenter' onClick={() => detailsNavHandler(item)}>
              <img src={require('../../assets/imgs/icons/bought.png')} className='redeemImg' />
            </div>
          ) : null
        ) : (
          <div className='expiredContainer itemCenter' onClick={() => detailsNavHandler(item)}>
            <img src={require('../../assets/imgs/icons/redeemed.png')} className='redeemImg' />
          </div>
        )} */}

        {onDealsTitle(item) ? (
          <div className={`row listStatusContainer ${onStylesDeals(item)}`}>
            <MynyfyText
              title={onDealsTitle(item)}
              className='listStatus bold'
              style={{ color: '#fff' }}
            />
          </div>
        ) : null}
        {item.type === PromoType.DAY_DEAL ? (
          <div>
            <MynyfyText title={item.name} style={{ textTransform: 'capitalize', padding: 7 }} />
            <div className='row' style={{ padding: '5px 10px 7px', flexWrap: 'wrap' }}>
              <div className='row'>
                {item.value ? <MynyfySpan title={theme.RUPEE + item.value} pBig bold /> : null}
                {item.points && <MynyfySpan title={'+'} pBig bold style={{ margin: 3 }} />}
                <div className='row myntsContainer'>
                  <img
                    src={require('../../assets/imgs/icons/coin.png')}
                    alt='mynts'
                    style={{ width: 16, height: 16 }}
                  />
                  <MynyfyText title={item.points} style={{ marginRight: 3 }} pSmall semiBold />
                </div>
              </div>
              <div style={{ marginLeft: 10 }}>
                <MynyfySpan
                  title={theme.RUPEE + item.actualPrice}
                  pBig={item.value ? false : true}
                  pSmall={item.value ? true : false}
                  style={{ textDecoration: item.value ? 'line-through' : 'unset', color: 'red' }}
                />
              </div>
            </div>
          </div>
        ) : null}
      </Fragment>
    );
  };

  const noImageComponent = (item) => {
    return (
      <div id='Promos' style={{ height: 'inherit' }} onClick={() => detailsNavHandler(item)}>
        <div className='promoNoImgBody'>
          <div style={{ padding: 10 }}>
            <MynyfyText title={'Get'} h3 bold />
            <MynyfyText
              title={theme.RUPEE + item.actualPrice + ' off'}
              h3
              bold
              style={{ paddingTop: 2 }}
            />
            {item.minimumOrderValue ? (
              <MynyfyText
                title={'on a min order of ' + theme.RUPEE + item.minimumOrderValue}
                style={{ paddingTop: 8 }}
              />
            ) : null}
            <MynyfyText title={item.name} style={{ paddingTop: 5 }} color='grey' />
          </div>
        </div>
      </div>
    );
  };

  const detailsNavHandler = (item) => {
    if (isLoggedIn) viewPromoCall(item);
    let screenName = screen ? `/${screen}/${item._id}` : `${item._id}`;
    navigate(screenName, { state: { details: item, isRestaurant, isDirectUrl } });
  };

  return (
    <div className='mutedBackground'>
      {from ? null : (
        <MynyfyHeader
          title={headerTitle || emptyListTitle}
          goHome={goHome}
          logoRight={() =>
            type == PromoType.DAY_DEAL ? (
              <img
                src={require('../../assets/imgs/hourglass.png')}
                style={{ height: 17, weight: 20 }}
                alt='hour Glass'
              />
            ) : null
          }
        />
      )}
      {isFilter ? (
        <MynyfyMobileFilter
          searchAndCatFilter={(e) => searchAndCatFilter(e)}
          sortFilter={(e) => sortFilter(e)}
          isMynts
          isPrice
        />
      ) : null}
      {isLoading ? (
        <MynyfyLoader paddingTop={from ? '18vh' : '45vh'} />
      ) : _.isEmpty(sortedPromos) ? (
        <MynyfyEmptyMsg
          paddingTop={from ? '18vh' : '35vh'}
          message={
            _.isEmpty(promos)
              ? emptyListMsg ||
                ` ${emptyListTitle}  will be published every Friday, Keep accumulating mynts until then.`
              : `No ${emptyListTitle} available`
          }
          bold={emptyListMsgBold || null}
        />
      ) : (
        <div id='Promos'>
          <MynyfyHalfCard
            data={sortedPromos}
            onClick={(item) => detailsNavHandler(item)}
            footer={(item) => customComponent(item)}
            body={(item) => noImageComponent(item)}
            type={FEATURE_TYPES.PROMOS}
            cardStyle={{ position: 'relative' }}
          />
        </div>
      )}
    </div>
  );
};

export default Promos;
