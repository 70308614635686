import React, { Fragment, useEffect, useState } from 'react';
import { Card, IconButton, InputAdornment } from '@mui/material';
import MynyfyText from '../../../components/MynyfyText';
import MynyfyInput from '../../../components/MynyfyInput';
import MynyfyButton from '../../../components/MynyfyButton';
import MynyfyHeader from '../../../components/MynyfyHeader';
import commonService from '../../../services/commonService';
import _ from 'lodash';
import validate from '../../../utils/validate';
import MynyfySelect from '../../../components/MynyfySelect';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { fileUploadToS3WD } from '../../../utils/S3Uploader';
import { USER_LOGOUT, WATCH_PROFILE } from '../../../redux/actionTypes';
import storeService from '../../../services/storeService';
import { Tooltip } from 'react-tippy';
import {
  CheckCircleRounded,
  ErrorRounded,
  InfoRounded,
  PowerSettingsNewRounded,
} from '@mui/icons-material';
//import { orgRegistrationValidators } from '../../../utils/validators';
import { registrationValidator, orgRegistrationValidators } from '../../../utils/validators';
import theme from '../../../utils/theme';
import authService from '../../../services/authService';
import MynyfyModal from '../../../components/MynyfyModal';

// slices
import { snackbarInfo } from '../../../redux/actions/snackbar.action';
import { createSlice } from '@reduxjs/toolkit';
import { useLocation } from 'react-router';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import MynyfySpan from '../../../components/MynyfySpan';
import { userLogout } from '../../../redux/actions/user.action';

const initialState = null;

const registrationSlice = createSlice({
  name: 'registration',
  initialState: initialState,
  reducers: {
    fetchRegistration: (state, action) => {
      return action.payload;
    },
  },
});
const { fetchRegistration } = registrationSlice.actions;

const ResellerRegistration = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const branddata = location?.state?.branddata;
  const sellerProfile = location?.state?.user;
  const mobileNumber = location?.state?.user?.mobile;
  const registration = useSelector((state) => state.registration);
  const appData = useSelector((state) => state.appData);
  const fetchaStates = appData?.states;
  const token = location?.state?.token;
  const brandDetails = useSelector((state) => state?.user?.profile);

  const [fname, setFname] = useState('');
  const [email, setEmail] = useState('');
  const [shopname, setShopname] = useState('');
  const [store, setStore] = useState('');
  const [catArr, setCatArr] = useState([]);
  const [error, setError] = useState({});
  const [open, setOpen] = useState();
  const [urlId, setUrlId] = useState(null);
  const [isClicked, setClicked] = useState(false);
  const [registraionData, setRegistrationData] = useState({});
  const [partnerid, setPartnerid] = useState('');

  const BrandCat = branddata?.category;

  useEffect(() => {
    if (!sellerProfile || !token || !branddata) {
      navigate('/');
    }
  }, []);

  useEffect(() => {
    if ((!_.isEmpty(registration), branddata)) {
      setRegistrationData(registration);
      setFname(registration?.fname || '');
      setEmail(registration?.email || '');
      setShopname(registration?.shopname || '');
      setStore(registration?.store || '');
      setCatArr(registration?.catArr || []);
      setUrlId(registration?.urlId || null);
    }
  }, [branddata]);

  useEffect(() => {
    if (!_.isEmpty(registraionData)) {
      dispatch(fetchRegistration(registraionData));
    }
  }, [registraionData]);

  const saveToRegistration = (key, value) => {
    let newData = { [key]: value };
    setRegistrationData((registraionData) => ({ ...registraionData, ...newData }));
  };

  const dataApiHandler = (key, setData, regKey) => {
    commonService.categorySort(key).then((res) => {
      let data = _.orderBy(res, ['name'], ['asc']);
      setData(data);
      saveToRegistration(regKey, data);
    });
  };

  const validateData = () => {
    let validationObj = {
      mobileNumber,
      fname,
      email,
      store,
      shopname,
      BrandCat,
      urlId,
    };
    const errors = validate(validationObj, orgRegistrationValidators);
    setError(errors);
    return errors;
  };

  const submitHandler = async () => {
    setClicked(true);
    let errors = validateData();

    if (_.isEmpty(errors)) {
      let body = {
        forProfile: 'Seller',
        firstName: fname,
        email: email,
        name: store,
        registeredName: shopname,
        type: 'Main Branch',
        categories: BrandCat,
        urlId: urlId,
        brands: branddata._id,
      };

      if (partnerid) {
        body.partnerCode = partnerid;
      }

      let s3ErrorMsg = null;

      if (s3ErrorMsg) {
        dispatch(snackbarInfo({ open: true, type: 'warning', message: s3ErrorMsg }));
        setClicked(false);
      } else {
        storeService
          .createStore(sellerProfile?.id, body, token)
          .then((res) => {
            if (res.success) {
              //  dispatch({ type: WATCH_PROFILE });
              dispatch(
                snackbarInfo({
                  open: true,
                  type: 'success',
                  message: 'Organization created successfully',
                })
              );
              navigate(-2);
              sessionStorage.setItem('reseller_redirection', true);
              dispatch(fetchRegistration({}));
            } else {
              dispatch(snackbarInfo({ open: true, type: 'error', message: res.message }));
              sessionStorage.setItem('reseller_redirection', false);
            }
          })
          .finally(() => setClicked(false));
      }
    } else {
      dispatch(
        snackbarInfo({ open: true, type: 'warning', message: 'Please fill all the details' })
      );
      setClicked(false);
    }
  };

  const logoutHandler = () => {
    authService._removeToken();
    dispatch(userLogout());
    navigate('/');
  };

  return (
    <Fragment>
      <MynyfyHeader
        h2
        center
        title={'Registration Form'}
        backBtnStyle={{ display: 'none' }}
        customElememt={() => (
          <PowerSettingsNewRounded
            style={{ color: theme.PRIMARY_BLUE, fontSize: 30, cursor: 'pointer' }}
            onClick={() => logoutHandler()}
          />
        )}
      />
      <div style={{ padding: '20px' }} id='StoreRegistration'>
        <MynyfyText
          title={'Complete below sections to setup your store profile.'}
          semiBold
          style={{ paddingBottom: 10 }}
        />
        <Card className='detailsCard'>
          <div className='rowSB'>
            <MynyfyText
              h6
              link
              title={'Personal Information'}
              onClick={() => {
                setOpen((open) =>
                  open === 'Personal Information' ? null : 'Personal Information'
                );
              }}
            />
            {error?.fname || error?.email || error?.store ? (
              <ErrorRounded fontSize='small' color='error' />
            ) : !error?.fname && fname && email && store ? (
              <CheckCircleRounded fontSize='small' color='success' />
            ) : null}
          </div>
          {/* {open === 'Personal Information' ? ( */}
          <div>
            <MynyfyInput
              fullWidth={true}
              value={fname}
              onChange={(e) => {
                setFname(e.target.value);
                saveToRegistration('fname', e.target.value);
              }}
              error={error?.fname ? true : false}
              placeHolder={'Name*'}
              helperText={error?.fname}
            />
            <MynyfyInput
              fullWidth={true}
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                saveToRegistration('email', e.target.value);
              }}
              error={error?.email ? true : false}
              placeHolder={'Email*'}
              helperText={error?.email}
            />
            <MynyfyInput
              fullWidth={true}
              value={store}
              onChange={(e) => {
                setStore(e.target.value);
                saveToRegistration('store', e.target.value);
                setShopname(e.target.value);
                saveToRegistration('shopname', e.target.value);
              }}
              error={error?.store ? true : false}
              placeHolder={'Business/Store Name*'}
            />
            <MynyfyText title={'* This name will be visible on Website.'} pSmall />
          </div>
          {/* ) : null} */}
        </Card>
        <Card className='detailsCard'>
          <div className='rowSB'>
            <MynyfyText
              h6
              link
              title={'Business Information'}
              onClick={() => {
                setOpen((open) =>
                  open === 'Business Information' ? null : 'Business Information'
                );
              }}
            />
            {error?.urlId ? (
              <ErrorRounded fontSize='small' color='error' />
            ) : !error?.urlId && urlId ? (
              <CheckCircleRounded fontSize='small' color='success' />
            ) : null}
          </div>
          {/* {open === 'Business Information' ? ( */}
          <div>
            <MynyfyInput
              fullWidth={true}
              value={urlId}
              onChange={(e) => {
                setUrlId(e.target.value);
                saveToRegistration('urlId', e.target.value);
              }}
              error={error?.urlId ? true : false}
              placeHolder={'Build Your Store URL*'}
              helperText={error?.urlId}
              style={{ marginTop: '10px' }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <Tooltip
                      position='left-end'
                      arrow
                      html={
                        <div style={{ textAlign: 'left' }}>
                          <MynyfyText title={'1. Only alphabets, numbers and'} color={'#fff'} />
                          &ensp;&nbsp;
                          <MynyfySpan title={'dot(.) are allowed'} color={'#fff'} />
                          <MynyfyText title={'2. No dot(.) at stating and ending'} color={'#fff'} />
                          <MynyfyText
                            title={'3. No adjacent dots(..) are allowed'}
                            color={'#fff'}
                          />
                          <MynyfyText title={'4. No Spaces are allowed'} color={'#fff'} />
                        </div>
                      }>
                      <IconButton edge='end'>
                        <InfoRounded />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                paddingLeft: '10px',
                paddingTop: '-10px',
              }}>
              <MynyfySpan title={'Customers can access your website using below link.'} pSmall />
              <MynyfySpan
                title={`${process.env.REACT_APP_WEB_HOST}/Stores/${urlId || ''}`}
                pSmall
                link
              />
              <MynyfySpan
                title={'You can also configure your domain to this link later.'}
                pSmall
                style={{ marginTop: '30px' }}
              />
            </div>
          </div>
          {/* ) : null} */}
        </Card>
        <Card className='detailsCard'>
          <MynyfyText
            h6
            link
            title={'Enter PartnerId(optional)'}
            onClick={() => {
              setOpen((open) => (open === 'Partner Information' ? null : 'Partner Information'));
            }}
          />
          {open === 'Partner Information' ? (
            <div>
              <MynyfyInput
                fullWidth={true}
                value={partnerid}
                onChange={(e) => {
                  setPartnerid(e.target.value);
                  saveToRegistration('partnerid', e.target.value);
                }}
                error={error?.partnerid ? true : false}
                placeHolder={'Partner Id'}
              />
              <MynyfyText
                title={'Enter Mynyfy preferred Partner Id if your account is created by a Partner.'}
                pSmall
              />
            </div>
          ) : null}
        </Card>
        <MynyfyButton
          style={{ marginTop: '10px' }}
          center
          variant='contained'
          onClick={submitHandler}
          label={'Submit'}
          loading={isClicked}
          disabled={isClicked}
        />
      </div>
      {/* <MynyfyModal
        open={isOpenViewsModal}
        close={() => {
          setOpenViewsModal(false);
          navigate('/Seller/MyStore');
        }}
        position={'center'}>
        <Card style={{ paddingLeft: '10px', paddingRight: '10px' }}>
          <div style={{ marginBottom: '10px', marginTop: '10px', display: 'flex' }}>
            <MynyfyText title={'Reseller created successfully.'} h6 style={{ color: 'green' }} />
            <div style={{ paddingLeft: '15%' }}>
              <CancelOutlinedIcon
                onClick={() => {
                  setOpenViewsModal(false);
                  navigate('/Seller/MyStore');
                }}
              />
            </div>
          </div>
          <div style={{ paddingBottom: '10px' }}>
            <MynyfyText title={'Provide below login details to your reseller:'} pBig />
            <div>
              <MynyfySpan title={'Login URL: '} />{' '}
              <a href='https://seller.mynyfy.com' target='_blank' rel='noopener noreferrer'>
                https://seller.mynyfy.com
              </a>
            </div>
            <MynyfyText title={'Login ID and password are the registered mobile number.'} pBig />
          </div>
        </Card>
      </MynyfyModal> */}
    </Fragment>
  );
};

export default ResellerRegistration;
