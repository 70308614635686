import { myApi } from '../utils/apiHelper';

class NotificationService {
  async getNotifications(timeCutoff, pageLimit) {
    try {
      return await myApi
        .get(`/notifications?timeCutoff=${timeCutoff}&pageLimit=${pageLimit}`)
        .then((res) => {
          return res.data;
        });
    } catch (err) {
      return err?.response?.data;
    }
  }

  async markRead(notificationId) {
    try {
      return await myApi
        .get(`/notifications/mark-read?notificationId=${notificationId}`)
        .then((res) => {
          return res.data;
        });
    } catch (err) {
      return err?.response?.data;
    }
  }

  async markAllRead() {
    try {
      return await myApi.get(`/notifications/mark-all`).then((res) => {
        return res.data;
      });
    } catch (err) {
      return err?.response?.data;
    }
  }

  async markDelete(notificationId) {
    try {
      return await myApi
        .get(`/notifications/mark-delete?notificationId=${notificationId}`)
        .then((res) => {
          return res.data;
        });
    } catch (err) {
      return err?.response?.data;
    }
  }
}

const notificationService = new NotificationService();
export default notificationService;
export { notificationService };
