import React, { Fragment, useState } from 'react';
import './webAuth.scss';
import MynyfyText from '../../components/MynyfyText';
import MynyfyButton from '../../components/MynyfyButton';
import { useLocation, useNavigate } from 'react-router-dom';
import validate from '../../utils/validate';
import _ from 'lodash';
import authService from '../../services/authService';
import jwtDecode from 'jwt-decode';
import { useDispatch } from 'react-redux';
import { SyncProfile } from '../../utils/enums';
import { MsgEnums } from '../../utils/MsgEnums';
import EastRoundedIcon from '@mui/icons-material/EastRounded';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { snackbarInfo } from '../../redux/actions/snackbar.action';
import MynyfyInput from '../../components/MynyfyInput';
import MynyfySpan from '../../components/MynyfySpan';
import { Checkbox } from '@mui/material';
import theme from '../../utils/theme';
import commonService from '../../services/commonService';
import { MynyfyVideos } from '../../utils/MynyfyEnums';
import { registrationValidator } from '../../utils/validators';

const WebSignIn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let params = useLocation();
  const brandDetails = params.state.details;

  const [mobile, setMobile] = useState('');
  const [otp, setOTP] = useState(new Array(6).fill(''));
  const [isOtpSent, setOtpSent] = useState(false);
  const [password, setPassword] = useState('');
  const [isPassword, setIsPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isTerms, setIsTerms] = useState(false);
  const [existingProfile, setExistingProfile] = useState(null);
  const [error, setError] = useState({});
  const [otpError, setOtpError] = useState(null);
  const [isClicked, setClicked] = useState(false);

  const handleOTPChange = (element, index) => {
    const value = element.value;
    if (/[^0-9]/.test(value)) return;

    const newOtp = [...otp];
    newOtp[index] = value;
    setOTP(newOtp);
    if (value && element.nextSibling) element.nextSibling.focus();
  };

  const handleOTPKeyDown = (e, index) => {
    if (e.key === 'Backspace' && !otp[index] && e.target.previousSibling) {
      e.target.previousSibling.focus();
    }
  };

  const checkProfile = async () => {
    setClicked(true);
    let body = { mobile };
    let errors = validate(body, registrationValidator);

    setError(errors);

    if (_.isEmpty(errors)) {
      await authService
        .profileExist(mobile)
        .then((res) => {
          if (res.profile === null) {
            sendOtp();
            setExistingProfile(false);
          } else if (
            res.profile?.syncedWith === SyncProfile.SELLER ||
            res.profile?.syncedWith === SyncProfile.BUYER_SELLER ||
            res.profile.syncedWith === SyncProfile.BUYER
          ) {
            setExistingProfile(res.profile);
            setIsPassword(true);
          } else if (
            res.profile.syncedWith === SyncProfile.PARTNER ||
            res.profile.syncedWith === SyncProfile.BUYER_PARTNER
          ) {
            snackbarHandler({
              type: 'warning',
              message: [
                `This mobile number is registered with ${res.profile.syncedWith}`,
                'Account Sync feature will be comming soon',
                'Please login with new number',
              ],
            });
          }
        })
        .finally(() => setClicked(false));
    } else {
      setClicked(false);
      snackbarHandler({ type: 'warning', message: 'Please enter valid Mobile Number' });
    }
  };

  const sendOtp = () => {
    let body = { mobile };
    let errors = validate(body, registrationValidator);
    setError(errors);
    if (_.isEmpty(errors)) {
      authService
        .sendOtp(mobile)
        .then((res) => {
          if (res) {
            setOtpSent(true);
            snackbarHandler({ type: 'success', message: 'OTP has been sent successfully' });
          } else {
            snackbarHandler({ type: 'error', message: 'Failed to send OTP' });
          }
        })
        .finally(() => setClicked(false));
    } else {
      snackbarHandler({ type: 'warning', message: 'Please enter valid Mobile Number' });
      setClicked(false);
    }
  };

  const handleOTPSetup = () => {
    let finalOtp = otp.join('');
    if (finalOtp.length != 6) {
      snackbarHandler({ type: 'warning', message: 'Invalid OTP' });
      setOtpError('OTP must be of length 6');
    } else {
      setIsPassword(true);
    }
  };

  const registerHandler = async () => {
    let finalOtp = otp.join('');
    let body = { mobile, password, finalOtp, isTerms };
    let errors = validate(body, registrationValidator);
    setError(errors);

    if (_.isEmpty(errors)) {
      let body = {
        profile: { mobile: mobile, password: password },
        otp: finalOtp,
        forProfile: 'Seller',
      };
      try {
        const res = await authService.register(body);
        if (res?.data?.message === 'Profile Created Successfully') {
          await loginHandler();
          snackbarHandler({
            type: 'success',
            message: 'Your account has been created successfully',
          });
        } else if (res?.data?.message === 'OTP Verification Failure.') {
          snackbarHandler({
            type: 'error',
            message: ['OTP Verification Failed', 'please enter correct OTP'],
          });
          setIsPassword(false);
        } else {
          snackbarHandler({ type: 'error', message: res?.message });
        }
      } catch (err) {
        snackbarHandler({
          type: 'error',
          message: [MsgEnums.SOMETHING_WENT_WRONG, MsgEnums.TRY_AGAIN_LATER],
        });
      }
    } else {
      snackbarHandler({ type: 'warning', message: MsgEnums.FILL_DETAILS });
    }
  };

  const syncProfileHandler = () => {
    let registerData = { profile: { ...existingProfile, password }, forProfile: 'Seller' };
    authService.register(registerData).then((registerRes) => {
      if (registerRes?.data?.message === 'Profile Created Successfully') {
        loginHandler();
      } else {
        snackbarHandler({
          type: 'error',
          message: registerRes.message || [MsgEnums.SOMETHING_WENT_WRONG, MsgEnums.TRY_AGAIN_LATER],
        });
      }
    });
  };

  const loginHandler = async (isDirectLogin) => {
    let body = { mobile, password, forProfile: 'Seller' };
    try {
      const res = await authService.login(body);
      if (res?.data?.message === 'Authenticated!') {
        const token = res.data.token;
        const user = jwtDecode(token);
        if (isDirectLogin) {
          getSellerById({ user, token });
        } else {
          navigate('/Register', { state: { user, token, brandDetails } });
        }
      } else {
        snackbarHandler({ type: 'error', message: res?.data?.message });
      }
    } catch (err) {
      clearState();
      snackbarHandler({
        type: 'error',
        message: ['Unable to login automatically', 'Please login on your own'],
      });
    }
  };

  const getSellerById = ({ user, token }) => {
    if (user) {
      authService.getSellerByProfileId(user.profile, token).then((res) => {
        if (res.success) {
          if (res?.data?.organizationId) {
            associateBrand(res.data.organizationId, token);
          } else if (res.data.profile) {
            navigate('/Register', { state: { user, token, brandDetails } });
          }
        }
      });
    } else {
      clearState();
      snackbarHandler({
        type: 'error',
        message: [MsgEnums.SOMETHING_WENT_WRONG, MsgEnums.TRY_AGAIN_LATER],
      });
    }
  };

  const associateBrand = (orgId, token) => {
    if (orgId) {
      let body = { addBrandId: brandDetails?._id };
      commonService.updateOrgBrand(orgId, body, token).then((res) => {
        if (res.success) {
          snackbarHandler({
            type: 'success',
            message: 'Dropshipping | Influencer account added successfully',
          });
          navigate(-1);
        } else {
          snackbarHandler({ open: true, type: 'error', message: res.message });
        }
      });
    } else {
      snackbarHandler({ type: 'warning', message: 'Store Id & Brand Id are Mandatory' });
    }
  };

  const clearState = () => {
    setMobile('');
    setOTP(new Array(6).fill(''));
    setOtpSent(false);
    setPassword('');
    setIsPassword(false);
    setShowPassword(false);
    setIsTerms(false);
    setExistingProfile(null);
    setError({});
    setOtpError(null);
    setClicked(false);
  };

  const snackbarHandler = ({ type, message }) => {
    dispatch(snackbarInfo({ open: true, type, message }));
  };

  return (
    <div id='webAuth'>
      <div className='container'>
        <div
          className='banner'
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div className='rowC' style={{ flexDirection: 'column' }}>
            <div style={{ position: 'absolute', top: 0, left: 0, cursor: 'pointer' }}>
              <MynyfyText
                title={brandDetails.brand}
                h1
                color={'#ffff'}
                style={{ margin: '50px 0 0 120px' }}
              />
            </div>
            <div
              className='rowC'
              style={{ flexDirection: 'column', height: '100%', width: '100%' }}>
              <MynyfyText
                h2
                center
                bold
                color={'white'}
                title={'Dropshipping | Influencer Registration'}
                style={{ margin: '0 0 0 0px' }}
              />
            </div>
          </div>
        </div>
        <div className='signin-container'>
          <div className='signin'>
            {(isPassword && existingProfile) || (existingProfile == null && !isOtpSent) ? (
              <div className='rowSB' style={{ marginBottom: 40 }}>
                <MynyfyText h3 semiBold title={'Sign Up'} />
                <MynyfyText
                  title={'View Demo'}
                  color={theme.DARK_BLUE}
                  onClick={() => window.open(MynyfyVideos.resellerSignUpMobile)}
                  style={{ padding: 10, borderRadius: 8, border: '1px solid' }}
                  className='cursor'
                  h5
                />
              </div>
            ) : null}

            {isOtpSent && !isPassword ? (
              <div style={{ marginBottom: 40 }}>
                <MynyfyText h4 semiBold title={`Enter OTP sent to`} />
                <MynyfyText h3 semiBold link title={`+91 ${mobile}`} />
              </div>
            ) : null}

            {isPassword && existingProfile == false ? (
              <MynyfyText
                title={'Setup a strong password for your account'}
                style={{ marginBottom: 40 }}
                semiBold
                h3
              />
            ) : null}

            {(isPassword && existingProfile) || (existingProfile == null && !isOtpSent) ? (
              <MynyfyInput
                startIcon={'+91 '}
                backgroundColor='#f9f9fa'
                fullWidth
                value={mobile}
                error={error?.mobile}
                showWarning={error?.mobile ? true : false}
                placeHolder={'Enter your mobile number'}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  const numericValue = inputValue.replace(/[^0-9]/g, '');
                  setMobile(numericValue);
                }}
                style={{ marginBottom: '0px' }}
              />
            ) : null}

            {isOtpSent && !isPassword ? (
              <Fragment>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '' }}>
                  {otp.map((data, index) => (
                    <input
                      key={index}
                      type={'text'}
                      backgroundColor='#f9f9fa'
                      value={otp[index]}
                      maxLength={'1'}
                      onChange={(e) => handleOTPChange(e.target, index)}
                      onKeyDown={(e) => handleOTPKeyDown(e, index)}
                      placeholder='-'
                      style={{
                        width: '50px',
                        height: '50px',
                        backgroundColor: '#f9f9fa',
                        border: '2px solid #e8e8e8',
                        borderRadius: '10px',
                        textAlign: 'center',
                        fontSize: '18px',
                        outline: 'none',
                      }}
                      onFocus={(e) => (e.target.style.borderColor = '#3076b5')}
                      onBlur={(e) => (e.target.style.borderColor = '#e8e8e8')}
                    />
                  ))}
                </div>
                {otpError ? (
                  <MynyfyText color={'red'} style={{ margin: '10px 0 20px 0' }} title={otpError} />
                ) : null}
              </Fragment>
            ) : null}

            {isPassword ? (
              <MynyfyInput
                fullWidth
                type={showPassword ? 'text' : 'password'}
                backgroundColor='#f9f9fa'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeHolder={'Enter password'}
                style={{ marginBottom: '0px' }}
                error={error?.password}
                showWarning={error?.password ? true : false}
                endIcon={
                  <PasswordVisibilityIcon
                    showPassword={showPassword}
                    setShowPassword={setShowPassword}
                  />
                }
              />
            ) : null}

            {isOtpSent && isPassword ? (
              <div
                className='row cursor'
                style={{ padding: '10px 0', marginBottom: '-20px', marginLeft: '-5px' }}
                onClick={() => setIsTerms(!isTerms)}>
                <Checkbox
                  checked={isTerms}
                  style={{ color: error?.isTerms ? 'red' : theme.PRIMARY_BLUE }}
                />
                <div>
                  <MynyfySpan title={'I agree to '} pSmall />
                  <MynyfySpan
                    title={'Terms & Conditions and Data Privacy Policies'}
                    link
                    onClick={() => {
                      navigate('/Policies/Terms&Conditions', { state: 'Terms & Conditions' });
                    }}
                    pSmall
                  />
                </div>
              </div>
            ) : null}

            <MynyfyButton
              label={
                existingProfile == null ||
                (isOtpSent && !isPassword) ||
                existingProfile?.syncedWith == SyncProfile.BUYER
                  ? 'Continue'
                  : existingProfile == false && isOtpSent && isPassword
                  ? 'Sign Up'
                  : 'Login'
              }
              disabled={isClicked}
              loading={isClicked}
              onClick={() => {
                existingProfile == null
                  ? checkProfile()
                  : isOtpSent && !isPassword
                  ? handleOTPSetup()
                  : existingProfile == false && isOtpSent && isPassword
                  ? registerHandler()
                  : existingProfile?.syncedWith == SyncProfile.BUYER
                  ? syncProfileHandler()
                  : loginHandler(true);
              }}
              endIcon={<EastRoundedIcon style={{ fontSize: 20 }} />}
              fullWidth={true}
              style={{
                backgroundColor: 'black',
                fontFamily: 'MynyfySemiBold',
                height: '45px',
                margin: '20px 0 0 0',
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const PasswordVisibilityIcon = ({ showPassword, setShowPassword }) => {
  return showPassword ? (
    <VisibilityOutlinedIcon
      style={{ cursor: 'pointer', fontSize: '20px' }}
      onClick={() => setShowPassword(false)}
    />
  ) : (
    <VisibilityOffOutlinedIcon
      onClick={() => setShowPassword(true)}
      style={{ cursor: 'pointer', fontSize: '20px' }}
    />
  );
};

export default WebSignIn;
