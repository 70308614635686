import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import theme from '../utils/theme';
import MynyfySpan from './MynyfySpan';
import MynyfyText from './MynyfyText';
import MynyfyModal from './MynyfyModal';
import commonService from '../services/commonService';
import profileService from '../services/profileService';
import { snackbarInfo } from '../redux/actions/snackbar.action';
import { AddCircleRounded, EditRounded } from '@mui/icons-material';
import MynyfyRadioButton from './MynyfyRadioButton';
import { OrderType } from '../utils/enums';

const SelectAddress = ({
  isVisible,
  closeModal,
  selectedAddress,
  from,
  fromId,
  fromName,
  setData,
  isShippingPrice,
  isOffline,
  sellerDetails,
  orderType,
  isRestaurant,
  isDeal,
  props,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector((state) => state.user);
  const address = user?.profile?.profile?.address || [];

  const [availableAddress, setAvailableAddress] = useState([]);
  const [isHomeDeliveryChecked, setIsHomeDeliveryChecked] = useState(false);

  useEffect(() => {
    if ((isVisible && (sellerDetails?.homeDelivery || isDeal)) || (isVisible && from === 'brand')) {
      if ((sellerDetails?.homeDelivery && !sellerDetails?.pickUp) || from === 'brand') {
        setIsHomeDeliveryChecked(true);
      }
      profileService.getAddressForDelivery({ from, fromId, isOffline }).then((res) => {
        if (res.success) {
          let apiRes = res.data?.map((res) => res._id) || [];
          setAvailableAddress(apiRes);
        } else {
          dispatch(snackbarInfo({ open: true, type: 'error', message: res.message }));
        }
      });
    }
  }, [isVisible == true]);

  const addressHandler = (key) => {
    return key ? `${key}, ` : null;
  };

  const getAreaForShippingPrice = (item) => {
    commonService
      .appDataByData(item.locality)
      .then((res) => {
        if (res.success) {
          setData({ address: item, shippingPrice: res.data?.[0]?.deliveryPrice });
        } else {
          dispatch(snackbarInfo({ open: true, type: 'error', message: res.message }));
        }
      })
      .finally(() => closeModal());
  };

  return (
    <MynyfyModal open={isVisible} close={() => closeModal()} {...props}>
      <div style={{ maxHeight: '75%', overflowY: 'auto' }}>
        <div id='OrderModals'>
          <MynyfyText title={'Select delivery type'} style={{ paddingBottom: 15 }} center h6 bold />
          {sellerDetails?.pickUp ? (
            <MynyfyRadioButton
              card
              bold
              onClick={() => setData({ orderType: OrderType.TAKE_AWAY })}
              isSelected={orderType === OrderType.TAKE_AWAY ? true : false}
              title={isRestaurant ? 'Pick-Up/Take Away' : 'Pick-Up'}
            />
          ) : null}
          {/* {sellerDetails?.dineIn ? (
            <MynyfyRadioButton
              card
              bold
              onClick={() => setData({ orderType: OrderType.DINE_IN })}
              isSelected={orderType === OrderType.DINE_IN ? true : false}
              title={'Dine-In'}
            />
          ) : null} */}
          {sellerDetails?.homeDelivery ? (
            <MynyfyRadioButton
              card
              bold
              onClick={() => setIsHomeDeliveryChecked(true)}
              isSelected={isHomeDeliveryChecked}
              title={'Home Delivery'}
            />
          ) : null}
          {isHomeDeliveryChecked || isDeal ? (
            <div>
              <div className='rowSB' style={{ paddingBottom: 15 }}>
                <MynyfyText
                  title={
                    _.isEmpty(address)
                      ? 'Add Billing/Delivery Address'
                      : 'Provide Billing/Delivery Address'
                  }
                  h6
                  bold
                />
                <div
                  onClick={() => {
                    closeModal();
                    navigate('/EditAddress');
                  }}>
                  <AddCircleRounded color='primary' style={{ fontSize: 33 }} />
                </div>
              </div>
              {_.isEmpty(address) ? (
                <MynyfyText
                  title={'Please add an address to deliver your products'}
                  center
                  error
                  className='noAddressMsg'
                />
              ) : _.isEmpty(availableAddress) ? (
                <MynyfyText
                  title={`${
                    fromName ? fromName + ' is not' : 'Not'
                  } shipping to your delivery addresses`}
                  center
                  error
                  className='noAddressMsg'
                />
              ) : null}
              <div style={{ overflow: 'auto', maxHeight: '500px' }}>
                {_.isEmpty(address)
                  ? null
                  : address.map((res, i) => (
                      <div
                        className='orderModalContainer link'
                        style={{
                          backgroundColor:
                            selectedAddress?._id == res._id
                              ? theme.ICE_BLUE
                              : res.locality && availableAddress.includes(res._id)
                              ? '#fff'
                              : '#E6E6E6',
                        }}
                        key={i}
                        onClick={() => {
                          if (
                            (res.locality ? false : true) ||
                            (availableAddress.includes(res._id) ? false : true)
                          ) {
                            // it is disabled
                          } else {
                            if (isShippingPrice) {
                              getAreaForShippingPrice(res);
                            } else {
                              setData({ address: res });
                            }
                          }
                        }}>
                        <div className='rowSB'>
                          <div>
                            {res.saveAs ? (
                              <MynyfyText title={res.saveAs} bold style={{ paddingBottom: 5 }} />
                            ) : null}

                            <div style={{ flex: 1 }}>
                              <MynyfySpan title={addressHandler(res.lane1)} />
                              <MynyfySpan title={addressHandler(res.lane2)} />
                              <MynyfySpan title={addressHandler(res.landMark)} />
                              <MynyfySpan title={addressHandler(res.locality)} />
                              <MynyfySpan title={addressHandler(res.city)} />
                              <MynyfySpan title={addressHandler(res.state)} />
                              <MynyfySpan title={res.pincode} />
                              {res.locality ? null : (
                                <MynyfyText
                                  title={'Please update this address'}
                                  style={{ fontSize: 11, marginTop: 5 }}
                                  error
                                  center
                                />
                              )}
                            </div>
                          </div>
                          <div
                            className='addressEditIcon'
                            onClick={() => navigate('/EditAddress', { state: { address: res } })}>
                            <EditRounded style={{ color: '#fff', marginTop: 3, fontSize: 10 }} />
                          </div>
                        </div>
                      </div>
                    ))}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </MynyfyModal>
  );
};

export default SelectAddress;
