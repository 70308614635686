import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import './webcontest.scss';
import MynyfyText from '../../components/MynyfyText';
import { mobileNumberManipulation, objectReplace, s3ToCloudFront } from '../../utils/CommonMethods';
import { ContestType } from '../../utils/enums';
import ReferComponent from '../../components/ReferComponent';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import JoinJackpot from './Jackpot/JoinJackpot';
import { CircleRounded } from '@mui/icons-material';
import WebMynyfyHeader from '../../components/WebMynyfyHeader';
import MynyfyLoader from '../../components/MynfyfLoader';
import JoinScanAndWinByVoucher from './ScanAndWin/JoinScanAndWinByVoucher';
import contestService from '../../services/contestService';
import { snackbarInfo } from '../../redux/actions/snackbar.action';
import MynyfyClaimed from '../../components/MynyfyClaimed';
import { fetchScanAndWin } from '../../redux/actions/contest.action';
import { CircularProgress, Card } from '@mui/material';
import WebMynyfyEmptyMsg from '../../components/WebMynyfyEmptyMsg';
import { contestDetailsOptions } from './ContestHelper';
import SignInModal from '../Auth/SignInModal';
import MynyfyPre from '../../components/MynyfyPre';
import SelectStoreType from '../../components/SelectStoreType';
import JackpotVideo from './Jackpot/JackpotVideo';

const WebContestDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const urlParams = useParams();
  const route = useLocation();

  const user = useSelector((state) => state.user);
  const id = user.user?.id || null;
  const isLoggedIn = user?.isLoggedIn;

  const scanAndWin = useSelector((state) => state.contest?.scanAndWin);

  const [drawDetails, setDrawDetails] = useState(null);
  const [detailOptions, setDetailOptions] = useState({});
  const [isJoinDrawModal, setIsJoinDrawModal] = useState(false);
  const [isRefer, setIsRefer] = useState(false);
  const [isJoined, setJoined] = useState(false);
  const [isClicked, setClicked] = useState(false);
  const [errMsg, setErrMsg] = useState(null);
  const [openSignIn, setOpenSignIn] = useState(false);
  const [openStoreTypeModal, setOpenStoreTypeModal] = useState(false);
  const [isTerms, setIsTerms] = useState(false);

  useEffect(() => {
    let contest_Id = urlParams?.id;
    if (route?.state?.drawDetails) {
      setDrawDetails(route?.state?.drawDetails);
      setDetailOptions(route?.state?.detailOptions);
    } else {
      getContest(contest_Id);
    }
  }, []);

  const getContest = (contest_Id) => {
    let params = { id: contest_Id };
    contestService.getContestByVoucher(params).then((res) => {
      if (res.success) {
        let isExpired = moment(res.data?.endDate).isBefore();
        if (isExpired || res.data?.isDelete || res.data?.status !== 'ACTIVE') {
          let message = isExpired
            ? 'This contest is expired'
            : res.data?.isDelete || res.data?.status
            ? 'This contest is not available at this time'
            : '';
          setErrMsg(message);
        } else {
          let options = contestDetailsOptions(res.data?.type);
          setDetailOptions(options);
          setDrawDetails(res.data);
        }
      } else {
        setErrMsg(res.message);
        dispatch(snackbarInfo({ type: 'error', open: true, message: res.message }));
      }
    });
  };

  const joinScanAndWin = (drawCode) => {
    if (drawCode) {
      setClicked(true);
      let data = { drawCode: drawCode, buyerId: id };
      contestService
        .joinScanAndWin(data)
        .then((res) => {
          if (res.success) {
            setJoined(true);
            setDrawDetails(res.data);
            let newData = objectReplace(scanAndWin, res.data, '_id');
            dispatch(fetchScanAndWin(newData));
          } else {
            dispatch(snackbarInfo({ type: 'error', open: true, message: res.message }));
          }
        })
        .finally(() => setClicked(false));
    } else {
      dispatch(snackbarInfo({ type: 'error', open: true, message: 'Code is mandatory' }));
    }
  };

  const joinHandler = () => {
    if (isLoggedIn) {
      if (drawDetails.type === ContestType.JACKPOT) {
        setIsJoinDrawModal(ContestType.JACKPOT);
      } else if (drawDetails.type === ContestType.SHOP_AND_SCAN) {
        setIsJoinDrawModal(ContestType.SHOP_AND_SCAN);
      } else if (drawDetails.type === ContestType.SCAN_AND_WIN) {
        joinScanAndWin(drawDetails.drawCode[0]);
      } else if (drawDetails.type === ContestType.SHOP_AND_WIN) {
        if (drawDetails.organization) {
          navigate('/Stores/' + drawDetails.organization.urlId);
        } else if (drawDetails.brand) {
          navigate('/Brands/' + drawDetails.brand?.urlId);
        } else if (route?.state?.isDirectUrl) {
          navigate(-1);
        } else {
          navigate(-2);
          // setOpenStoreTypeModal(true);
        }
      }
    } else {
      setOpenSignIn(true);
    }
  };

  return errMsg ? (
    <WebMynyfyEmptyMsg message={errMsg} />
  ) : drawDetails ? (
    <div id='WebContestDetails'>
      <WebMynyfyHeader title={drawDetails.name} />
      <div
        className='containerNew'
        style={{
          padding: 10,
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <div className='rowC' style={{ width: '80%' }}>
          {drawDetails.video &&
          drawDetails.type == ContestType.JACKPOT &&
          !detailOptions.isWinners ? (
            <div style={{ padding: 5 }}>
              <div style={{ borderRadius: 10, overflow: 'hidden' }}>
                <JackpotVideo
                  contestDetails={drawDetails}
                  className='img'
                  playBtnSize={50}
                  style={{ padding: 0 }}
                />
              </div>
            </div>
          ) : (
            <div className='rowC' style={{ width: '80%' }}>
              <img src={s3ToCloudFront(drawDetails.image)} className='dimg' />
            </div>
          )}
        </div>
        <div
          style={{
            // padding: '15px 15px 5px',
            // position: 'relative',
            // top: '50%',
            // transform: 'translateY(-32%)',
            width: '50%',
          }}>
          {/* {detailOptions.isName ? (
            <div className='row'>
              <MynyfyText title={'Name :'} pSmall className='subHeading' bold />
              <MynyfyText title={drawDetails.name} className='content' />
            </div>
          ) : null} */}
          {detailOptions.isDates ? (
            <Fragment>
              <div className='rowSB'>
                <MynyfyText title={'Start from :'} primary bold />
                <MynyfyText
                  title={moment(drawDetails.startDate).format('DD MMM YYYY')}
                  className='content'
                />
              </div>
              <div className='rowSB' style={{ paddingTop: 10 }}>
                <MynyfyText title={'Ends on :'} primary bold />
                <MynyfyText
                  title={moment(drawDetails.endDate).format('DD MMM YYYY')}
                  className='content'
                />
              </div>
            </Fragment>
          ) : null}
          {detailOptions.isNoOfWinners ? (
            <div className='rowSB' style={{ paddingTop: 10 }}>
              <MynyfyText title={'Winners :'} primary bold />
              <MynyfyText title={drawDetails.winnerCount} className='content' />
            </div>
          ) : null}
          {detailOptions.isParticipants ? (
            <div className='rowSB' style={{ paddingTop: 10 }}>
              <MynyfyText title={'Total Entries :'} primary bold />
              <MynyfyText title={drawDetails.totalContestants || 0} className='content' />
            </div>
          ) : null}
          <div className='rowSB' style={{ paddingTop: 10 }}>
            <MynyfyText title={'Your Entries :'} primary bold />
            <MynyfyText title={drawDetails.isContested || 0} className='content' />
          </div>
          {/* {detailOptions.isCats ? (
            <div className='row'>
              <MynyfyText title={'Categories :'} pSmall className='subHeading' bold />
              <MynyfyText
                title={
                  _.isEmpty(drawDetails.category)
                    ? 'All'
                    : drawDetails.category.map((res) => res.name).join(', ')
                }
                className='content'
              />
            </div>
          ) : null} */}
          {/* {drawDetails.url ? (
            <div onClick={() => window.open(drawDetails.url)}>
              <div className='row'>
                <MynyfyText title={'Website :'} pSmall className='subHeading' bold />
                <MynyfyText title={'View Website'} link pSmall bold className='contentSmall' />
              </div>
            </div>
          ) : null} */}
          <div style={{ paddingTop: 10 }} onClick={() => setIsTerms(!isTerms)}>
            <MynyfyText title={'Terms & Conditions'} pSmall link bold />
          </div>

          {isTerms ? (
            <div>
              {drawDetails?.terms?.split('$').map((res, i) => (
                <div className='row' style={{ marginTop: 10 }} key={i}>
                  <CircleRounded className='dotIcon' />
                  <MynyfyText title={res} style={{ flexWrap: 'wrap', flexShrink: 1 }} />
                </div>
              ))}
            </div>
          ) : null}
          <div>
            {detailOptions.isWinners ? null : isClicked ? (
              <CircularProgress size={18} />
            ) : drawDetails.type == ContestType.SCAN_AND_WIN ? null : (
              <div
                onClick={() => joinHandler()}
                style={{
                  // opacity: drawDetails.isContested ? 0.35 : 1,
                  textAlign: 'center',
                  paddingTop: 10,
                }}
                className='rowC'>
                <MynyfyText
                  title={
                    drawDetails.isContested
                      ? 'Joined'
                      : drawDetails.type == ContestType.JACKPOT
                      ? 'Join'
                      : 'Shop to Join'
                  }
                  bold
                  className='joinContestBtn'
                  // contested={`${drawDetails.isContested}`}
                  color={'white'}
                />
              </div>
            )}
          </div>
          {/* {detailOptions.isTerms
            ? drawDetails.terms.split('$').map((res, i) => (
                <div className='row' style={{ marginTop: 10 }} key={i}>
                  <CircleRounded className='dotIcon' />
                  <MynyfyPre title={res} style={{ flexWrap: 'wrap', flexShrink: 1 }} />
                </div>
              ))
            : null} */}
        </div>
      </div>
      {isRefer ? <ReferComponent /> : null}
      {detailOptions.isWinners ? (
        <Fragment>
          <MynyfyText title={'Winners'} h5 primary bold center />
          {_.isEmpty(drawDetails.winners) ? (
            <MynyfyText
              title={
                drawDetails.totalContestants == 0
                  ? 'No Joined in this Contest'
                  : 'Winners will be declared soon'
              }
              p
              style={{ textAlign: 'center' }}
            />
          ) : (
            <div style={{ width: '50%', margin: 'auto' }}>
              {drawDetails.winners.map((res, i) => (
                <div className='card' key={i}>
                  <div className='rowSB' style={{ padding: 5 }}>
                    <MynyfyText
                      title={`${res.profile.firstName} ${res.profile.lastName || ''}`}
                      p
                      style={{ textTransform: 'capitalize', flexShrink: 1, paddingRight: 15 }}
                    />
                    <MynyfyText
                      title={
                        res.profile.mobile
                          ? mobileNumberManipulation(res.profile.mobile)
                              .map((mobileNumber) => mobileNumber)
                              .join(' ')
                          : null
                      }
                      p
                      style={{ textTransform: 'capitalize', flexShrink: 1, paddingRight: 15 }}
                    />
                  </div>
                </div>
              ))}
            </div>
          )}
        </Fragment>
      ) : null}
      <JoinJackpot
        isJoinDrawModal={isJoinDrawModal === ContestType.JACKPOT ? true : false}
        joinDrawObj={drawDetails}
        close={() => setIsJoinDrawModal(false)}
        errMsg={(e) => setIsRefer(e)}
      />
      <JoinScanAndWinByVoucher
        isJoinDrawModal={isJoinDrawModal === ContestType.SHOP_AND_SCAN ? true : false}
        close={() => setIsJoinDrawModal(false)}
      />
      <MynyfyClaimed
        title={`Hohoo! ${
          drawDetails?.myntsCanEarn
            ? `your earned ${drawDetails?.myntsCanEarn} mynts and`
            : 'You have'
        } entered into ${drawDetails?.name} contest`}
        open={isJoined}
        callback={() => setJoined(false)}
      />
      <SelectStoreType
        isVisible={openStoreTypeModal}
        closeModal={() => setOpenStoreTypeModal(false)}
        isRestaurant={route?.state?.isRestaurant}
      />
      <SignInModal open={openSignIn} close={() => setOpenSignIn(false)} />
    </div>
  ) : (
    <MynyfyLoader />
  );
};

export default WebContestDetails;
