import React, { Fragment } from 'react';
import MynyfyText from '../../components/MynyfyText';
import { CallRounded, LocationOnRounded, MailRounded, MapRounded } from '@mui/icons-material';
import theme from '../../utils/theme';
import './stores.scss';
import MynyfySpan from '../../components/MynyfySpan';
import { Card } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import _ from 'lodash';
import { exchangePolicy, returnPolicy } from '../../utils/CommonPolicies';

const StoreAddress = ({ details }) => {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  const contactNumComponent = (contactNo) => {
    return details ? (
      <div style={{ display: 'flex', paddingTop: 20 }}>
        <CallRounded color='#000' fontSize='' className='cursor' />
        <MynyfyText
          title={contactNo}
          pBig
          style={{ marginLeft: 25, letterSpacing: 1 }}
          color={'#000'}
        />
      </div>
    ) : null;
  };

  return details ? (
    <div style={{ padding: 35 }}>
      {/* {details.aboutUs ? (
        <div style={{ paddingBottom: 15 }}>
          <MynyfyText title={'About Us :'} p bold style={{ paddingBottom: 5 }} />
          <MynyfyText title={details.aboutUs} p style={{ marginLeft: 10 }} />
        </div>
      ) : null} */}
      <div className='aboutSections'>
        {details?.address ? (
          <div className='contentSec'>
            <div>
              <MynyfyText title={'Contact Us'} h4 color={'#000'} style={{ letterSpacing: 1 }} />
            </div>
            <div className='row' style={{ marginTop: 20 }}>
              <LocationOnRounded color='#000' fontSize='' className='cursor' />
              <div
                style={{
                  lineHeight: '20px',
                  marginLeft: 20,
                  display: 'flex',
                  flexDirection: 'column',
                }}>
                <div>
                  <div>
                    {details.address.lane1 ? (
                      <MynyfySpan
                        h6
                        title={`${details.address.lane1}, `}
                        color={'#000'}
                        style={{ letterSpacing: 1 }}
                      />
                    ) : null}

                    {details.address.lane2 ? (
                      <MynyfySpan
                        h6
                        title={`${details.address.lane2}  `}
                        color={'#000'}
                        style={{ letterSpacing: 1 }}
                      />
                    ) : null}

                    {details.address.locality ? (
                      <MynyfySpan
                        h6
                        title={`${details.address.locality}, `}
                        color={'#000'}
                        style={{ letterSpacing: 1 }}
                      />
                    ) : null}
                    {details.address.city ? (
                      <MynyfySpan
                        h6
                        title={`${details.address.city},  `}
                        color={'#000'}
                        style={{ letterSpacing: 1 }}
                      />
                    ) : null}

                    {details.address.state ? (
                      <MynyfySpan
                        h6
                        title={`${details.address.state}`}
                        color={'#000'}
                        style={{ letterSpacing: 1 }}
                      />
                    ) : null}

                    {details.address.pincode ? (
                      <MynyfySpan
                        h6
                        title={` - ${details.address.pincode}`}
                        color={'#000'}
                        style={{ letterSpacing: 1 }}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {contactNumComponent(
          details.contactNo ? details.contactNo : details.ownerId.profile.mobile
        )}

        {details.email ? (
          <div className='row' style={{ paddingTop: 20 }}>
            <MailRounded color='#000' fontSize='' className='cursor' />
            <MynyfyText
              title={details.email}
              pBig
              style={{ marginLeft: 20, letterSpacing: 1 }}
              color={'#000'}
            />
          </div>
        ) : null}

        {details.mapUrl ? (
          <div
            className='row'
            style={{ paddingTop: 20 }}
            onClick={() => window.open(details.mapUrl)}>
            <MapRounded color='#000' fontSize='' className='cursor' />
            <MynyfyText
              title={'Google Maps'}
              pBig
              style={{ marginLeft: 20, letterSpacing: 1 }}
              color={'#000'}
            />
          </div>
        ) : null}

        {/* {details.aboutUs ? (
          <div className='row contentSec'>
            <MynyfyText title={'About Us :'} className='subHeading' />
            <MynyfyText title={details.aboutUs} pBig style={{ marginLeft: 10 }} />
          </div>
        ) : null} */}
      </div>
      {/* <div>
        <Card className='aboutSections' style={{ marginTop: 15 }}>
          <MynyfyText title={'Policies'} className='cursor' onClick={() => setOpen(!open)} />
          {open ? (
            <Fragment>
              {_.isEmpty(details?.policies?.shipping) ? null : (
                <div className='userPolicyOptionCntainer'>
                  <Card
                    className='userPolicyOption'
                    style={{ marginTop: '5px' }}
                    onClick={() => {
                      navigate(`/Policies/ShippingAndDeliveryPolicy`, {
                        state: {
                          policy: details?.policies?.shipping,
                          policyName: 'Shipping / Delivery Policy',
                        },
                      });
                    }}>
                    <MynyfyText title={'Shipping / Delivery Policy'} />
                  </Card>
                </div>
              )}
              <div className='userPolicyOptionCntainer'>
                <Card
                  className='userPolicyOption'
                  style={{ marginTop: '5px' }}
                  onClick={() => navigate('/Policies/PrivacyPolicy')}>
                  <MynyfyText title={'Privacy Policy'} />
                </Card>
              </div>
              <div className='userPolicyOptionCntainer'>
                <Card
                  className='userPolicyOption'
                  style={{ marginTop: '5px' }}
                  onClick={() => {
                    navigate(`/Policies/RefundAndExchangePolicy`, {
                      state: {
                        policy: details?.policies?.isReturn
                          ? details?.policies?.refund
                          : returnPolicy,
                        policy2: details?.policies?.isExchange
                          ? details?.policies?.exchange
                          : exchangePolicy,
                        policyName: `Return / Refund / Exchange Policy`,
                      },
                    });
                  }}>
                  <MynyfyText title={'Return / Refund / Exchange Policy'} />
                </Card>
              </div>
              <div className='userPolicyOptionCntainer'>
                <Card
                  className='userPolicyOption'
                  style={{ marginTop: '5px' }}
                  onClick={() => {
                    navigate('/Policies/Terms&Conditions', { state: 'Terms & Conditions' });
                  }}>
                  <MynyfyText title={'Terms & Conditions'} />
                </Card>
              </div>
            </Fragment>
          ) : null}
        </Card>
        <MynyfyText
          title={'Powered by Mynyfy'}
          primary
          style={{ marginTop: 10, textAlign: 'end' }}
        />
      </div> */}
    </div>
  ) : null;
};

export default StoreAddress;
