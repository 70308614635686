import React, { Fragment } from 'react';
import MynyfyText from '../../components/MynyfyText';
import WebMynyfyHeader from '../../components/WebMynyfyHeader';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import MynyfyLoader from '../../components/MynfyfLoader';
import MynyfySpan from '../../components/MynyfySpan';
import theme from '../../utils/theme';
import _ from 'lodash';
import './webaddress.scss';
import { EditRounded } from '@mui/icons-material';

const WebAddress = () => {
  const navigate = useNavigate();
  const addresses = useSelector((state) => state.user?.profile?.profile?.address);

  const addAddressIcon = () => {
    return (
      <div
        className='link'
        onClick={() => {
          navigate('/EditAddress', { state: { title: 'Add Address', btnText: 'Add' } });
        }}>
        <AddCircleIcon style={{ color: theme.PRIMARY_BLUE, display: 'block', fontSize: 30 }} />
      </div>
    );
  };

  const addressHandler = (key) => {
    return key ? `${key}, ` : null;
  };

  return (
    <Fragment>
      <WebMynyfyHeader title={'Address'} customElememt={() => addAddressIcon()} />
      {addresses ? (
        _.isEmpty(addresses) ? (
          <div className='row' style={{ paddingTop: '38vh', justifyContent: 'center' }}>
            <MynyfyText title={'Add your delivery Address'} style={{ padding: '0px 10px' }} />
            {addAddressIcon()}
          </div>
        ) : (
          <div id='WebAddress'>
            <div className='addMainContainer'>
              <MynyfyText title={'Saved Addresses'} h3 center style={{ paddingBottom: 20 }} />
              {addresses?.map((res, i) => (
                <div className='addressContainer' key={i}>
                  <div className='rowSB'>
                    <div>
                      {res.saveAs ? (
                        <MynyfyText title={res.saveAs} bold pBig style={{ paddingBottom: 5 }} />
                      ) : null}
                      <div style={{ flex: 1 }}>
                        <MynyfySpan title={addressHandler(res.lane1)} />
                        <MynyfySpan title={addressHandler(res.lane2)} />
                        <MynyfySpan title={addressHandler(res.landMark)} />
                        <MynyfySpan title={addressHandler(res.locality)} />
                        <MynyfySpan title={addressHandler(res.city)} />
                        <MynyfySpan title={addressHandler(res.state)} />
                        <MynyfySpan title={res.pincode} />
                      </div>
                    </div>
                    <div
                      className='addressEditIcon link'
                      onClick={() => {
                        navigate('/EditAddress', {
                          state: { title: 'Edit Address', btnText: 'Update', address: res },
                        });
                      }}>
                      <EditRounded style={{ color: '#fff', marginTop: 3, fontSize: 10 }} />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )
      ) : (
        <MynyfyLoader paddingTop={'38vh'} />
      )}
    </Fragment>
  );
};

export default WebAddress;
