
import _ from 'lodash';
export const emailValidation =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const firstNameValidation = /^[^\s]+[-a-zA-Z\s]*[^\s]$/;
export const lastNameValidation = /^(?![\s-])[\w\s-]+$/;
export const pincodeValidation = /^[0-9]{6,6}$/;
export const urlIdValidation = /^(?!.*\.\.)(?!^\.)[a-zA-Z0-9.]+(?<!\.)$/;
export const urlValidation =
  /^(https?:\/\/)?([a-zA-Z0-9.-]+)\.([a-zA-Z]{2,})(:[0-9]{1,5})?(\/\S*)?$/;

export const profileValidator = {
  firstName: [
    (value) =>
      value
        ? firstNameValidation.test(value) === false
          ? 'Please enter a valid first name'
          : value?.length < 2
          ? 'First name must be atleast 3 characters'
          : null
        : 'Please enter First Name',
  ],
  lastName: [
    (value) =>
      value
        ? lastNameValidation.test(value) === false
          ? 'Please enter a valid last name'
          : value?.length < 1
          ? 'Last name cannot be empty'
          : null
        : 'Please enter Last Name',
  ],
  email: [
    (value) =>
      value
        ? emailValidation.test(value) === false
          ? 'Please enter a valid email address'
          : null
        : 'Please enter Email',
  ],
};

export const registrationValidator = {
  mobile: [(value) => (value?.length === 10 ? null : 'Please enter valid Mobile Number')],
  password: [
    (value) =>
      value?.length
        ? value?.length >= 6
          ? null
          : 'Password should be atleast 6 characters'
        : 'Please enter Password',
  ],
  otp: [(value) => (value?.length ? null : 'Please enter OTP')],
  isTerms: [(value) => (value ? null : 'Please check Terms and conditions')],
};

export const locationValidator = {
  selectedState: [(value) => (value ? null : 'Select your State')],
  selectedCity: [(value) => (value ? null : 'Select your City')],
  selectedArea: [(value) => (value ? null : 'Select your Area')],
};

export const orgRegistrationValidators = {
  fname: [
    (value) =>
      value
        ? firstNameValidation.test(value) === false
          ? 'Only alphabets, numbers & space between words are allowed'
          : null
        : 'Please enter Name',
  ],
  lastName: [
    (value) =>
      value ? null : 'Please enter valid data, there must a space between first and last name.',
  ],
  email: [
    (value) =>
      value
        ? emailValidation.test(value) === false
          ? 'Please enter valid data, email should contain @ and .com or .in etc.'
          : null
        : 'Please enter Email',
  ],
  shopname: [(value) => (value ? null : 'Please enter Reg.company/ Shop Name')],
  store: [(value) => (value ? null : 'Please enter Store Name')],
  state: [(value) => (value ? null : 'Please select State')],
  city: [(value) => (value ? null : 'Please select City/District')],
  area: [(value) => (value ? null : 'Please select Area')],
  lane1: [(value) => (value ? null : 'Please enter Lane 1')],
  lane2: [(value) => (value ? null : 'Please enter Lane 2')],
  urlId: [
    (value) =>
      value == null
        ? `Please enter Unique Name`
        : urlIdValidation.test(value) === false
        ? 'Please Check (i) icon for validation'
        : null,
  ],
  pincode: [
    (value) =>
      value
        ? pincodeValidation.test(value) === false
          ? 'Please enter valid data, Only Numbers allowed of 6 digits length.'
          : null
        : 'Please enter Pincode',
  ],
  catArr: [(value) => (!_.isEmpty(value) ? null : 'Please select Categories')],
  orgImgs: [(value) => (!_.isEmpty(value) ? null : 'Please upload Store Images')],
  
  type: [(value) => (value ? null : 'Select Business Type')],
  businessLicenseNo: [(value) => (value ? null : 'Enter Business License Number')],
  gstImg: [(value) => (value ? null : 'Upload GST Image')],
  aadharFrontImg: [(value) => (value ? null : 'Upload Aadhar Front')],
  aadharBackImg: [(value) => (value ? null : 'Upload Aadhar Back')],
  panImg: [(value) => (value ? null : 'Upload PAN Image')],
  businessLicenseImg: [(value) => (value ? null : 'Upload Business License Image')],
  paymentOption: [(value) => (value ? null : 'Please select payment option')],
  upiQRimg: [(value) => (value ? null : 'Upload Upi QR Image')],
  storeOpenTime: [(value) => (value ? null : 'Please select Store Opening Time')],
  storeCloseTime: [(value) => (value ? null : 'Please select Store Closing Time')],
};
