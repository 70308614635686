import React from 'react';
import { Modal, Box } from '@mui/material';

const MynyfyWebModal = ({
  open,
  onClose,
  children,
  blurAmount = 5,
  className,
  customStyles = {},
  disableBackdropClick = false,
  scale = 0.99,
  ...props
}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      disableEscapeKeyDown={disableBackdropClick}
      slotProps={{
        backdrop: {
          style: {
            backdropFilter: `blur(${blurAmount}px)`, // Apply blur effect
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Default opacity with black tint
          },
          onClick: disableBackdropClick ? undefined : onClose, // Handle backdrop click if not disabled
        },
      }}
      {...props}>
      <Box
        className={className}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: `translate(-50%, -50%) scale(${scale})`,
          backgroundColor: 'white',
          boxShadow: 24,
          borderRadius: 2,
          outline: 'none',
          ...customStyles,
        }}>
        {children}
      </Box>
    </Modal>
  );
};

export default MynyfyWebModal;
