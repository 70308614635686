import { myApi, myPubApi } from '../utils/apiHelper';

class StoreService {
  async createStore(id, data) {
    try {
      return await myApi.post(`/org/create/${id}`, data).then((res) => {
        return res.data;
      });
    } catch (err) {
      return err?.response?.data;
    }
  }

  async getStores(params) {
    try {
      return await myPubApi.get(`/orglist`, { params }).then((res) => {
        return res.data;
      });
    } catch (err) {
      return err?.response?.data;
    }
  }

  async getStoreAddress(id) {
    try {
      return await myPubApi.get(`/org/address/${id}`).then((res) => {
        return res.data;
      });
    } catch (err) {
      return err?.response?.data;
    }
  }

  async storeListByBrandId(params) {
    try {
      return await myApi.get(`/orgListByBrandId`, { params }).then((res) => {
        return res.data;
      });
    } catch (err) {
      return err?.response?.data;
    }
  }

  async viewStore(params) {
    try {
      let token = localStorage.getItem('login_token');
      let api = token ? myApi : myPubApi;
      return await api.get(`/viewOrganization`, { params }).then((res) => {
        return res.data;
      });
    } catch (err) {
      return err?.response?.data;
    }
  }

  async likeStore(orgId) {
    try {
      return await myApi.get(`/sellerOrg/like?id=${orgId}`).then((res) => {
        return res.data;
      });
    } catch (err) {
      return err?.response?.data;
    }
  }

  async unlikeStore(orgId) {
    try {
      return await myApi.get(`/sellerOrg/unlike?id=${orgId}`).then((res) => {
        return res.data;
      });
    } catch (err) {
      return err?.response?.data;
    }
  }

  async getLikedStores() {
    try {
      return await myApi.get(`/likedSellerOgrs`).then((res) => {
        return res.data;
      });
    } catch (err) {
      return err?.response?.data;
    }
  }

  async addVisitedSeller(orgId) {
    try {
      return await myApi.get(`/addVisitedSeller?orgId=${orgId}`).then((res) => {
        return res.data;
      });
    } catch (err) {
      return err?.response?.data;
    }
  }

  async getVisitedSellers() {
    try {
      return await myApi.get(`/getVisitedSellers`).then((res) => {
        return res.data;
      });
    } catch (err) {
      return err?.response?.data;
    }
  }
  async createStore(id, data, token) {
    try {
      return await myApi
        .post(`/org/create/${id}`, data, { headers: { Authorization: `Bearer ${token}` } })
        .then((res) => {
          return res.data;
        });
    } catch (err) {
      return err?.response?.data;
    }
  }
}

const storeService = new StoreService();
export default storeService;
export { storeService };
