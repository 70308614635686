import React from 'react';
import MyntsTxn from './MyntsTxn';
import PaymentTxn from './PaymentTxn';
import MynyfyTab from '../../components/MynyfyTab';
import { useLocation } from 'react-router-dom';
import WebPaymentTxn from './WebPaymentTxn';

const WebPaymentsHistory = () => {
  const { state } = useLocation();

  return (
    <MynyfyTab
      // tabIndex={state}
      noSwipe={true}
      tabs={['Payments']}
      tabComponent={[<WebPaymentTxn />]}
    />
  );
};

export default WebPaymentsHistory;
