import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';
import SignIn from '../screens/Auth/SignIn';
import SignUp from '../screens/Auth/SignUp';
import ForgotPassword from '../screens/Auth/ForgotPassword';
import WebStoreDetails from '../screens/Stores/WebStoreDetails';
import WebBrandDetails from '../screens/Brands/WebBrandDetails';
import AuthLanding from '../screens/Layout/AuthLanding';

const WebAuthStack = ({ isMynyfy, storeData, brandData, noData }) => {
  return (
    <Fragment>
      <Route
        path='/'
        element={
          storeData || noData ? (
            <WebStoreDetails storeData={storeData} noData={noData} />
          ) : brandData ? (
            <WebBrandDetails brandData={brandData} />
          ) : (
            <AuthLanding />
          )
        }
      />
      <Route path='SignIn' element={<SignIn from='web' />} />
      <Route path='SignUp' element={<SignUp from='web' />} />
      <Route path='ForgotPassword' element={<ForgotPassword from='web' />} />
    </Fragment>
  );
};

export default WebAuthStack;
