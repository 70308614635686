import React, { Fragment, useEffect, useState } from 'react';
import MynyfyText from '../../components/MynyfyText';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import theme from '../../utils/theme';
import MynyfyHeader from '../../components/MynyfyHeader';
import { Card } from '@mui/material';
import _ from 'lodash';
import MynyfySpan from '../../components/MynyfySpan';
import MynyfyLoader from '../../components/MynfyfLoader';
import MynyfyEmptyMsg from '../../components/MynyfyEmptyMsg';
import { contestDetailsOptions } from '../Contest/ContestHelper';
import {
  InfoOutlined,
  KeyboardDoubleArrowRight,
  PlayCircleFilled,
  AddRounded,
  ControlPointRounded,
  KeyboardArrowDownRounded,
  KeyboardArrowUpRounded,
  RemoveCircleOutlineRounded,
  RemoveRounded,
  SearchOutlined,
} from '@mui/icons-material';
import MynyfyPre from '../../components/MynyfyPre';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SelectAdType from '../../components/SelectAdType';
import './home.scss';
import SignInModal from '../Auth/SignInModal';
import JackpotVideo from '../Contest/Jackpot/JackpotVideo';
import ReactPlayer from 'react-player';
import MynyfyModal from '../../components/MynyfyModal';
import { s3ToCloudFront } from '../../utils/CommonMethods';
import MynyfyButton from '../../components/MynyfyButton';
import ContestDetailsNew from '../Contest/ContestDetailsNew';
import unlocked from '../../assets/imgs/unlocked.png';
import { PromoType, FEATURE_TYPES } from '../../utils/enums';
import MynyfyDoubleCard from '../../components/MynyfyDoubleCard';
import { addToBagPromo, incrementHandler, decrementHandler } from './AddPromoHelper';
import { snackbarInfo } from '../../redux/actions/snackbar.action';
import AddPromoBag from './AddPromoBag';

const DealsLayout = ({ noHeader }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const mynts = useSelector((state) => state.mynts);
  const user = useSelector((state) => state.user);
  const bagPromo = useSelector((state) => state.bagPromo);
  const id = user.user?.id || null;
  const profile = user.profile;
  const isLoggedIn = user?.isLoggedIn;
  const ads = useSelector((state) => state.contest?.seeAndWin);
  const jackpot = useSelector((state) => state.contest?.jackpot);
  const deals = useSelector((state) => state.promos?.dayDeals?.actual);
  const dealVideo = useSelector((state) => state.dealVideo);
  const reward = useSelector((state) => state.promos?.rewards?.sorted);

  const [openAdModal, setOpenAdModal] = useState(false);
  const [openSignIn, setOpenSignIn] = useState(false);
  const [videoPlay, setVideoPlay] = useState(false);
  const [openEarnMyntsModal, setOpenEarnMyntsModal] = useState(false);
  const [openMyntsInfo, setOpenMyntsInfo] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [options, setOptions] = useState(null);
  const [drawerDetails, setDrawerDetails] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const [latestDeals, setLatestDeals] = useState(null);
  const [openBagModel, setOpenBagModal] = useState(null);

  const sortedDeals = !_.isEmpty(deals)
    ? _.orderBy(deals, ['createdAt'], ['desc']).slice(0, 4)
    : [];

  const closeModel = () => {
    setShowDetails(false);
  };

  const customComponent = (item) => {
    return (
      <Fragment>
        {item.type === PromoType.DAY_DEAL ? (
          item.isRedeemed ||
          (item.days > 0 ? false : true) ||
          item.status === 'COMPLETED' ||
          item.status === 'REDEEMED' ? (
            <div className='expiredContainer' onClick={() => detailsNavHandler(item)} />
          ) : null
        ) : null}

        <div
          className={`row listStatusContainer ${
            item.claimedBy.includes(id) && item.type === PromoType.DAY_DEAL
              ? 'statusGreen'
              : item.days <= 0 || item.status === 'COMPLETED'
              ? 'statusRed'
              : item.count - (item.claims || 0)
              ? 'statusGreen'
              : 'statusRed'
          }`}>
          <MynyfyText
            title={
              // item.claimedBy.includes(id) && item.type === PromoType.DAY_DEAL
              //   ? 'Bought'
              //   : item.days <= 0
              //   ? ' Expired'
              //   : item.status === 'COMPLETED'
              //   ? ' You Missed it'
              //   : item.count - (item.claims || 0)
              //   ? item.count - (item.claims || 0) + ' Left'
              //   : ' You Missed it'
              item.type === PromoType.DAY_DEAL
                ? item.days <= 0
                  ? 'Expired'
                  : item.status === 'COMPLETED'
                  ? 'Completed'
                  : item.count - (item.claims || 0) > 0
                  ? `${item.count - (item.claims || 0)} Left`
                  : 'Completed'
                : null
            }
            className='listStatus bold'
            style={{ color: '#fff' }}
          />
        </div>
        {item.type === PromoType.DAY_DEAL ? (
          <div>
            <MynyfyText title={item.name} style={{ textTransform: 'capitalize', padding: 7 }} />
            <div className='row' style={{ padding: '5px 5px 5px', flexWrap: 'wrap' }}>
              <div className='row'>
                {item.value ? <MynyfySpan title={theme.RUPEE + item.value} pBig bold /> : null}
                {item.points && <MynyfySpan title={'+'} pBig bold style={{ margin: 3 }} />}
                <div className='row myntsContainer'>
                  <img
                    src={require('../../assets/imgs/icons/coin.png')}
                    alt='mynts'
                    style={{ width: 16, height: 16 }}
                  />
                  <MynyfyText title={item.points} style={{ marginRight: 3 }} pSmall semiBold />
                </div>
              </div>
              <div style={{ marginLeft: 5 }}>
                <MynyfySpan
                  title={theme.RUPEE + item.actualPrice}
                  pBig={item.value ? false : true}
                  pSmall={item.value ? true : false}
                  style={{ textDecoration: item.value ? 'line-through' : 'unset', color: 'red' }}
                />
              </div>
            </div>
            <div
              className='addToBagContainerInList'
              onClick={(event) => {
                event.stopPropagation();
              }}>
              {/* {bagPromo?.products?.find((e) => e._id == item._id) ? (
              <div className='rowC' style={{ padding: 10 }}>
                <RemoveCircleOutlineRounded
                  className='cursor'
                  htmlColor='#fff'
                  onClick={() => {
                    decrementHandler({ promoDetails: item, bagPromo, dispatch });
                  }}
                />
                <MynyfyText
                  title={bagPromo?.products?.find((e) => e._id == item._id)?.dealCount || 0}
                  className='quantityContainer'
                  h6
                  color='#fff'
                />
                <ControlPointRounded
                  className='cursor'
                  htmlColor='#fff'
                  onClick={() => {
                    incrementHandler({ promoDetails: item, bagPromo, dispatch });
                  }}
                />
              </div>
            ) : (
              <MynyfyText
                title={item.count - (item.claims || 0) === 0 ? 'Out Of Stock' : 'Add to Bag'}
                className='cursor'
                color='#fff'
                center
                style={{
                  padding: 10,
                  cursor: item.count - (item.claims || 0) !== 0 ? 'pointer' : 'not-allowed',
                }}
                onClick={() => {
                  if (item.count - (item.claims || 0) !== 0) {
                    addToBagHandler(item);
                    
                  }
                }}
              />
            )} */}
            </div>
          </div>
        ) : null}
      </Fragment>
    );
  };

  const noImageComponent = (item) => {
    return (
      <div style={{ height: 'inherit' }} onClick={() => detailsNavHandler(item)}>
        <div className='promoNoImgBody'>
          <div style={{ padding: 10 }}>
            <MynyfyText title={'Get'} h3 bold />
            <MynyfyText
              title={theme.RUPEE + item.actualPrice + ' off'}
              h3
              bold
              style={{ paddingTop: 2 }}
            />
            {item.minimumOrderValue ? (
              <MynyfyText
                title={'on a min order of ' + theme.RUPEE + item.minimumOrderValue}
                style={{ paddingTop: 8 }}
              />
            ) : null}
          </div>
        </div>
      </div>
    );
  };

  const detailsNavHandler = (item) => {
    navigate(`/Deals/${item._id}`, {
      state: { details: item },
    });
  };

  const addToBagHandler = (item) => {
    if (item.days > 0 && item.status === 'ACTIVE' && item.count - (item.claims || 0) > 0) {
      addToBagPromo({ promoDetails: item, bagPromo, dispatch });
    } else {
      dispatch(
        snackbarInfo({
          open: true,
          type: 'warning',
          message: `Currently You can't Add the Deal`,
        })
      );
    }
  };

  useEffect(() => {
    // Open WebSocket connection to backend
    const socket = new WebSocket('ws://localhost:8001');

    socket.onopen = () => {
      console.log('WebSocket Connected');
    };

    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      console.log('Received change:', data);
    };

    socket.onerror = (error) => {
      console.error('WebSocket Error:', error);
    };

    socket.onclose = () => {
      console.log('WebSocket Closed');
    };

    return () => {
      socket.close();
    };
  }, []);

  return (
    <Fragment>
      {noHeader ? null : <MynyfyHeader title={'Mynts | Deals | Jackpots | Ads'} />}
      <div id='Home'>
        {isLoggedIn && (
          <Fragment>
            <div className='rowSB' style={{ paddingInline: 20 }}>
              <div className='row cursor' onClick={() => navigate('/Transactions', { state: 1 })}>
                <img
                  src={require('../../assets/imgs/icons/coin.png')}
                  style={{ width: 40, height: 40 }}
                />
                <div style={{ marginLeft: 5 }}>
                  <MynyfyText title={mynts} className='myntVal' pBig />
                  <div className='row'>
                    <MynyfyText title={'My Mynt'} color={theme.MUTED_BLUE} pBig />
                  </div>
                </div>
              </div>
              <div>
                <InfoOutlined
                  className='cursor'
                  style={{ color: 'grey', display: 'block' }}
                  onClick={() => setOpenMyntsInfo(!openMyntsInfo)}
                />
              </div>
            </div>
            {openMyntsInfo ? (
              <div style={{ paddingTop: 10, marginLeft: 10 }}>
                <MynyfyText
                  title={
                    '* Mynts cannot be withdrawn as money. You may use them to purchase deals and join jackpots.'
                  }
                  color={theme.MUTED_BLUE}
                  pSmall
                />
              </div>
            ) : null}
            <div className='divider' style={{ margin: '15px 10px' }} />
          </Fragment>
        )}

        <div className='rowSB' style={{ padding: '0px 25px' }}>
          <MynyfyText title={'See Ads & Earn Mynts'} pBig bold />
          <MynyfyText
            title={'All Ads'}
            className='cursor'
            pBig
            bold
            onClick={() => navigate('AdsList')}
          />
        </div>

        <div style={{ margin: 20 }}>
          <div>
            {ads ? (
              _.isEmpty(ads) ? (
                <MynyfyEmptyMsg message={'No Ads at your location'} paddingTop={10} small={true} />
              ) : (
                <div className='offer'>
                  {ads?.slice(0, 4)?.map((ads, i) => (
                    <div
                      onClick={() => {
                        if (isLoggedIn) navigate('/Posts', { state: { adId: ads._id } });
                        else setOpenSignIn(true);
                      }}
                      key={i}>
                      <Card style={{ width: 150, marginRight: 10, height: 200 }} className='cursor'>
                        <img
                          src={s3ToCloudFront(ads.image)}
                          alt={ads.name}
                          style={{ objectFit: 'cover', height: 200, width: 150 }}
                        />
                      </Card>
                    </div>
                  ))}
                  <KeyboardDoubleArrowRight
                    onClick={() => {
                      if (isLoggedIn) navigate('/Posts');
                      else setOpenSignIn(true);
                    }}
                    className='all cursor'
                    style={{ marginTop: '80px' }}
                  />
                </div>
              )
            ) : null}
          </div>
        </div>

        <div style={{ margin: 20 }}>
          {reward ? (
            _.isEmpty(reward) ? null : (
              <div>
                <MynyfyText title={'Rewards'} bold h5 style={{ marginTop: 30 }} />
                <div className='offer'>
                  {reward?.slice(0, 4)?.map((rewardDetails, i) => (
                    <div
                      onClick={() => {
                        if (isLoggedIn) {
                          navigate(`/Rewards/${rewardDetails._id}`, {
                            state: { details: rewardDetails },
                          });
                        } else {
                          navigate('/SignIn');
                        }
                      }}
                      key={i}
                      style={{ height: 'auto' }}>
                      <Card style={{ width: 150, marginRight: 10 }}>
                        <img
                          src={s3ToCloudFront(rewardDetails.image.url)}
                          style={{ objectFit: 'cover', height: 150, width: 150 }}
                        />

                        <div>
                          <MynyfyText
                            title={rewardDetails.name}
                            style={{ padding: 10 }}
                            className='singleLine'
                            center
                          />
                        </div>

                        <div className='rowSB' style={{ padding: '0px 10px 10px' }}>
                          <MynyfyPre title={`Claim\nRs ${rewardDetails.actualPrice}`} small />

                          <div style={{ textAlign: 'end' }}>
                            <MynyfyPre title={`For`} small />

                            <div className='row'>
                              <img
                                src={require('../../assets/imgs/icons/coin.png')}
                                alt='mynts'
                                height={'10px'}
                                width={'10px'}
                              />
                              <MynyfyText
                                title={rewardDetails.points}
                                style={{ marginLeft: 3 }}
                                small
                              />
                            </div>
                          </div>
                        </div>
                      </Card>
                    </div>
                  ))}

                  <KeyboardDoubleArrowRight
                    onClick={() => navigate('/Rewards')}
                    className='all cursor'
                  />
                </div>
              </div>
            )
          ) : null}
        </div>

        {dealVideo ? (
          <div>
            {_.isEmpty(deals) ? null : (
              <div
                className='rowSB'
                style={{ margin: '0px 25px 10px' }}
                onClick={() => navigate('/Deals')}>
                <MynyfyText title={'Buy Deals'} pBig bold link />
                <MynyfyText title={'View All >>'} link bold />
              </div>
            )}
            <div className='dealBannerContainer'>
              {dealVideo?.video ? (
                <div style={{ position: 'relative' }}>
                  <ReactPlayer
                    url={dealVideo.video}
                    config={{
                      youtube: { playerVars: { showinfo: 0, rel: 0, controls: 0 } },
                      vimeo: { playerOptions: { title: false } },
                    }}
                    width={'100%'}
                    height={260}
                    playing={videoPlay}
                    onEnded={() => setVideoPlay(false)}
                  />
                  {dealVideo.video?.includes('vimeo') && !videoPlay ? (
                    <PlayCircleFilled
                      onClick={() => setVideoPlay(!videoPlay)}
                      className='videoPlayBtn'
                    />
                  ) : null}
                </div>
              ) : (
                <img src={s3ToCloudFront(dealVideo.image)} className='dealBannerImg' />
              )}
            </div>
          </div>
        ) : (
          <div>
            {deals ? (
              _.isEmpty(deals) ? null : (
                // <MynyfyEmptyMsg
                //   message={
                //     'Deals will be published every Friday, Keep accumulating mynts until then.'
                //   }
                //   paddingTop={10}
                //   small={true}
                // />
                <>
                  <div className='row' style={{ paddingLeft: 25, marginTop: 30 }}>
                    <MynyfyText title={'Latest Deals'} bold h5 />
                  </div>

                  <MynyfyDoubleCard
                    data={sortedDeals}
                    onClick={(item) => detailsNavHandler(item)}
                    footer={(item) => customComponent(item)}
                    body={(item) => noImageComponent(item)}
                    type={FEATURE_TYPES.PROMOS}
                    cardStyle={{ position: 'relative' }}
                  />

                  <MynyfyButton
                    label={'Unlock Deals'}
                    center
                    variant='outlined'
                    containerStyle={{ marginTop: 20 }}
                    style={{ borderRadius: 30, fontSize: 13 }}
                    onClick={() => navigate('/Deals')}
                    startIcon={
                      <span>
                        <img src={unlocked} alt='button-icon' style={{ width: 20, height: 15 }} />
                      </span>
                    }
                  />
                  {/* {bagPromo?.calculation?.qty > 0 && <AddPromoBag />} */}
                </>
              )
            ) : null}
          </div>
        )}

        <div className='featureContainerJackpot' style={{ paddingBottom: 30 }}>
          <div>
            {jackpot ? (
              _.isEmpty(jackpot) ? null : (
                <div>
                  <div className='row' style={{ margin: '30px 0px 10px' }}>
                    <MynyfyText title={'Win Jackpots'} bold h5 />
                  </div>
                  <div style={{ display: 'flex', overflowX: 'auto', gap: 15, padding: 3 }}>
                    {jackpot?.map((contestDetails, i) => {
                      return (
                        <div key={i} className='cardWrapper'>
                          <Card className='cursor homeContestCardjackpot'>
                            {contestDetails.video ? (
                              <div onClick={(event) => event.stopPropagation()}>
                                <JackpotVideo contestDetails={contestDetails} />
                              </div>
                            ) : (
                              <>
                                <img
                                  src={s3ToCloudFront(contestDetails.image)}
                                  alt={contestDetails.name}
                                  className='contestImageJackpot'
                                  onClick={() => {
                                    let options = contestDetailsOptions(contestDetails.type, false);
                                    setShowDetails(true);
                                    setOptions(options);
                                    setDrawerDetails(contestDetails);
                                    setIsActive(true);
                                  }}
                                />
                                {showDetails && (
                                  <ContestDetailsNew
                                    state={{
                                      drawDetails: drawerDetails,
                                      detailOptions: options,
                                      closeModal: closeModel,
                                      showDetails: showDetails,
                                      isActive: isActive,
                                    }}
                                  />
                                )}
                              </>
                            )}
                            <div className='row'>
                              <MynyfyText
                                title={contestDetails.name}
                                style={{ padding: '10px', color: 'black' }}
                                className='singleLine scrollingText'
                                pBig
                                bold
                              />
                            </div>
                            <MynyfyButton
                              label={
                                contestDetails.isContested
                                  ? 'Joined'
                                  : `Join with ${contestDetails.myntsToJoin} mynts`
                              }
                              fullWidth
                              containerStyle={{ margin: '0px' }}
                              style={{
                                borderTopLeftRadius: '0',
                                borderTopRightRadius: '0',
                                borderBottomLeftRadius: '8px',
                                borderBottomRightRadius: '8px',
                              }}
                              onClick={() => navigate('/Jackpots')}
                              link
                            />
                          </Card>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )
            ) : null}
          </div>
        </div>

        {jackpot && deals && reward && ads ? null : (
          <div style={{ padding: 35 }}>
            <MynyfyLoader paddingTop={1} />
          </div>
        )}

        <MynyfyButton
          label={'View all Jackpots'}
          center
          variant='outlined'
          containerStyle={{ marginTop: 20 }}
          style={{ borderRadius: 30, fontSize: 13 }}
          onClick={() => navigate('/Jackpots')}
        />
      </div>

      <MynyfyModal open={openEarnMyntsModal} close={() => setOpenEarnMyntsModal(false)}>
        <div>
          <MynyfyText title={'Earn Mynts'} bold center style={{ paddingBottom: 15 }} h6 />
          <MynyfyText title={'- For Seeing Ads'} />
          <MynyfyText title={'- For Shopping with any Seller'} style={{ padding: '7px 0px' }} />
          <MynyfyText title={'- Referring friends'} />
        </div>
      </MynyfyModal>

      <SelectAdType
        isVisible={openAdModal}
        closeModal={() => setOpenAdModal(false)}
        flashAdScreen={'/FlashAds/Create'}
        adScreen={'/PublishAds/Create'}
      />

      <SignInModal open={openSignIn} close={() => setOpenSignIn(false)} />
    </Fragment>
  );
};

export default DealsLayout;
