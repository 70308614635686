import React, { useState } from 'react';
import WebContest from '../WebContest';
import { ContestType } from '../../../utils/enums';
import { useSelector } from 'react-redux';
import { fetchShopAndWin, fetchShopAndWinHistory } from '../../../redux/actions/contest.action';
import MynyfyText from '../../../components/MynyfyText';
import theme from '../../../utils/theme';
import WebMynyfyHeader from '../../../components/WebMynyfyHeader';

const WebShopAndWin = ({ from, brandDetails, sellerDetails, isDirectUrl, noHeader }) => {
  const shopAndWin = useSelector((state) => state.contest?.shopAndWin);
  const shopAndWinHistory = useSelector((state) => state.contest?.shopAndWinHistory);

  const [isHistory, setIsHistory] = useState(false);

  return (
    <div style={{ padding: noHeader ? 0 : 10 }}>
      {noHeader ? null : <WebMynyfyHeader title={'Contest'} />}
      <div className='row' style={{ gap: 40, padding: '20px 20px 10px' }}>
        <MynyfyText
          title={'Active'}
          color={isHistory ? 'grey' : theme.PRIMARY_BLUE}
          bold={!isHistory}
          h6
          className='cursor'
          style={{ borderBottom: isHistory ? 0 : '2px solid ' + theme.PRIMARY_BLUE }}
          onClick={() => setIsHistory(false)}
        />
        <MynyfyText
          title={'Winners'}
          color={isHistory ? theme.PRIMARY_BLUE : 'grey'}
          bold={isHistory}
          h6
          className='cursor'
          style={{ borderBottom: isHistory ? '2px solid ' + theme.PRIMARY_BLUE : 0 }}
          onClick={() => setIsHistory(true)}
        />
      </div>
      <WebContest
        emptyListTitle={'Contests'}
        type={ContestType.SHOP_AND_WIN}
        sellerDetails={sellerDetails}
        brandDetails={brandDetails}
        isHistory={isHistory}
        from={from}
        isCategories={false}
        status={isHistory ? 'NOACTIVE' : 'ACTIVE'}
        draws={from ? null : isHistory ? shopAndWinHistory : shopAndWin}
        action={from ? null : isHistory ? fetchShopAndWinHistory : fetchShopAndWin}
        sidebar={!isDirectUrl}
      />
    </div>
  );
};

export default WebShopAndWin;
