import theme from './theme';

export const MynyfyEnums = {
  SUPPORT_EMAIL: 'support@mynyfy.com',
  SUPPORT_NUMBER: '9100910219',
  VERSION: '3.3.2',
  GST: 0.18,
  COMPANY: 'Mynyfy India Pvt Ltd',
  GST_NO: '36AANCM1272G1ZE',
  NUMBER: 9100910219,
  EMAIL: 'support@mynyfy.com',
  FULL_ADDRESS:
    'Flat No. 203, JP Classic Apartments, SBH Officers Colony, Saidabad, Hyderabad, Telangana - 500059',
  PART1: 'Flat No. 203, JP Classic Apartments',
  PART2: 'SBH Officers Colony, Saidabad',
  PART3: 'Hyderabad, Telangana - 500059',
};

export const MynyfyMails = {
  brandMail: `mailto:${MynyfyEnums.SUPPORT_EMAIL}?subject=Brand page submission request&body=Hi,${theme.BREAK}We would like to add our brand page on Mynyfy platform.${theme.BREAK}Please find below details of our Brand.${theme.BREAK}${theme.BREAK}Brand Name:${theme.BREAK}Brand Category:${theme.BREAK}Location: Is it PAN India or a local brand, please mention accordingly.${theme.BREAK}Customer Care Number:${theme.BREAK}Support Id:${theme.BREAK}Website:${theme.BREAK}Brand Description:${theme.BREAK}Corporate Office:${theme.BREAK}You tube Video Links:${theme.BREAK}${theme.BREAK}Thanks`,
};

export const MynyfyVideos = {
  resellerSignUpMobile: 'https://youtube.com/shorts/LMgCxt5Ilj0',
};

export const MYNYFY_DEEPLINK_ADS = (id) => `https://mynyfy.app.link/EAXSysUfiyb/?adId=${id}`;

export const SiteUrls = [
  'dev-shop-old.mynyfy.com',
  'dev-shop.mynyfy.com',
  'www.mynyfy.com',
  'mynyfy.com',
  'localhost',
];
