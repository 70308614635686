import {
  BAG_PROMO_OFFERS,
  BAG_PROMO_PRODUCTS,
  BAG_PROMO_CALCULATION,
  BAG_PROMO_SELLING_ID,
  BAG_PROMO_ABOUT,
} from '../actionTypes';

const INITIAL_STATE = {
  id: null,
  about: null,
  offers: null,
  products: null,
  calculation: null,
};

const bagPromoReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case BAG_PROMO_SELLING_ID:
      return {
        ...state,
        id: action.payload,
      };
    case BAG_PROMO_ABOUT:
      return {
        ...state,
        about: action.payload,
      };
    case BAG_PROMO_OFFERS:
      return {
        ...state,
        offers: action.payload,
      };
    case BAG_PROMO_PRODUCTS:
      return {
        ...state,
        products: action.payload,
      };
    case BAG_PROMO_CALCULATION:
      return {
        ...state,
        calculation: action.payload,
      };
    default:
      return state;
  }
};

export default bagPromoReducer;
