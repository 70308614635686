import React, { Fragment, useCallback, useEffect, useState } from 'react';
import MynyfyText from '../../components/MynyfyText';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import _ from 'lodash';
import MynyfyEmptyMsg from '../../components/MynyfyEmptyMsg';
import MynyfyLoader from '../../components/MynfyfLoader';
import WebMynyfyHeader from '../../components/WebMynyfyHeader';
import mynyfyLogo from '../../assets/imgs/LandingPage/mynyfy.png';
import './webbrands.scss';
import { Card, Drawer, IconButton } from '@mui/material';
import {
  ContactPhoneOutlined,
  StyleOutlined,
  Menu,
  ChevronLeftRounded,
  Facebook,
  Instagram,
  YouTube,
  WhatsApp,
} from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { snackbarInfo } from '../../redux/actions/snackbar.action';
import { fetchPromos, fetchSortedPromos } from '../../redux/actions/promo.action';
import Promos from '../Promos';
import { ProductType, PromoType } from '../../utils/enums';
import SignInModal from '../Auth/SignInModal';
import {
  fetchBagAbout,
  fetchBagCalculation,
  fetchBagOffers,
  fetchBagProducts,
  fetchBagSellingId,
} from '../../redux/actions/bag.action';
import promoService from '../../services/promoService';
import { Carousel } from 'react-responsive-carousel';
import moment from 'moment';
import { openUrl, s3ToCloudFront, truncateString } from '../../utils/CommonMethods';
import { ReactComponent as OrdersIcon } from '../../assets/svg/basket.svg';
import { ReactComponent as ContestsIcon } from '../../assets/svg/shop_and_win.svg';
import { ReactComponent as CouponsIcon } from '../../assets/svg/coupons.svg';
import { ReactComponent as PoliciesIcon } from '../../assets/svg/outlineRefund.svg';
import { ReactComponent as UserIcon } from '../../assets/svg/user.svg';
import { ReactComponent as SearchIcon } from '../../assets/svg/thinSearch.svg';
import theme from '../../utils/theme';
import WebOrdersList from '../Orders/WebOrdersList';
import { fetchOrders, fetchOrdersHistory } from '../../redux/actions/order.action';
import MynyfySpan from '../../components/MynyfySpan';
import productService from '../../services/productService';
import MynyfyButton from '../../components/MynyfyButton';
import brandService from '../../services/brandService';
import WebBrandProducts from '../Products/WebBrandProducts';
import BrandProductCartHelper from '../Products/ProductCartHelperForBrand';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import LocationModal from '../Location/LocationModal';
import MynyfyModal from '../../components/MynyfyModal';
import WebShopAndWin from '../Contest/ShopAndWin/WebShopAndWin';

const WebBrandDetails = ({ brandData }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const urlParams = useParams();
  const [searchParams] = useSearchParams();

  const profile = useSelector((state) => state.user?.profile);
  const brands = useSelector((state) => state.brands);
  const visitedBrands = useSelector((state) => state.visitedBrands);
  const promos = useSelector((state) => state.promos);
  const bag = useSelector((state) => state.bag);
  const location = useSelector((state) => state.location?.location);
  const isLoggedIn = useSelector((state) => state.user?.isLoggedIn);
  const orders = useSelector((state) => state.orders);

  const [brandName, setBrandName] = useState('');
  const [details, setDetails] = useState(null);
  const [isLiked, setLiked] = useState(null);
  const [openSignIn, setOpenSignIn] = useState(false);
  const [isEcommerceActive, setEcommerceActive] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [activeComponent, setActiveComponent] = useState(null);
  const [activeTab, setActiveTab] = useState('Active');
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [showRefundPolicy, setShowRefundPolicy] = useState(false);
  const [showShippingPolicy, setShowShippingPolicy] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [isDirectUrl, setDirectUrl] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [isSearchDrawerOpen, setIsSearchDrawerOpen] = useState(false);
  const [noBrand, setNoBrand] = useState(null);
  const [isProducts, setIsProducts] = useState(false);
  const [isServices, setIsServices] = useState(false);
  const [productsType, setProductsType] = useState(ProductType.PRODUCT);
  const [messageIndices, setMessageIndices] = useState(bag?.offers?.map(() => 0) || []);
  const [originalGroups, setOriginalGroups] = useState(null);
  const [originalSections, setOriginalSections] = useState(null);
  const [groups, setGroups] = useState(null);
  const [sections, setSections] = useState(null);
  const [selectedSection, setSelectedSection] = useState(null);
  const [actualProductsData, setActualProductsData] = useState(null);
  const [originalProductBrands, setOriginalProductBrands] = useState([]);
  const [selectedBrandForProducts, setSelectedBrandForProducts] = useState(null);
  const [isLocationModal, setIsLocationModal] = useState(false);
  const [showResellerLoginModal, setShowResellerLoginModal] = useState(false);

  const isFoodCategory = details?.category?.find((e) => e._id == process.env.REACT_APP_FOOD_CAT_ID);
  const successfulRedirectionAfterResellerSignup = sessionStorage.getItem('reseller_redirection');

  const caurosalFstPart = [
    details?.policies?.shippingCharges?.forRestaurant?.deliveryTime
      ? `We deliver within ${details.policies.shippingCharges.forRestaurant.deliveryTime} min`
      : null,
    details?.homeDelivery === false ? 'Offline store - Pick-up only' : null,
    !isEcommerceActive ? 'Currently not in service, please contact support.' : null,
    !false ? `Currently store is closed, Please come back at ${details?.storeOpenTime}` : null,
  ].filter(Boolean);

  const carouselData = _.isEmpty(bag?.offers) ? caurosalFstPart : bag?.offers;

  useEffect(() => {
    let urlId = urlParams?.brand;
    let table = urlParams?.table;
    setBrandName(urlId || '');
    brandDetailsHandler();
  }, []);

  useEffect(() => {
    if (!details?._id) return;
    const fetchProductProps = async () => {
      try {
        const [groupRes, sectionRes] = await Promise.all([
          productService.getProductProps({ brandId: details?._id, type: 'group' }),
          productService.getProductProps({ brandId: details?._id, type: 'section' }),
        ]);
        if (groupRes.success) setOriginalGroups(groupRes.data);
        if (sectionRes.success) setOriginalSections(sectionRes.data);
      } catch (error) {
        console.error('Error fetching product props:', error);
      }
    };

    fetchProductProps();
  }, [details?._id]);

  useEffect(() => {
    if (!details?._id) return;
    const fetchProductBrands = async () => {
      let params = {
        brandId: details?._id,
      };
      productService.getBrandProductBrandNames(params).then((res) => {
        if (res.success) {
          const data = _.sortBy(res.data, 'name');
          setOriginalProductBrands(data);
        }
      });
    };

    fetchProductBrands();
  }, [details?._id]);

  useEffect(() => {
    let successfulRedirection = sessionStorage.getItem('reseller_redirection');
    if (successfulRedirection === 'true') {
      setShowResellerLoginModal(true);
    }
  }, [successfulRedirectionAfterResellerSignup]);

  const handleGroupsAndSections = useCallback(() => {
    if (!originalGroups?.length || !originalSections?.length) return;
    const reqGroupData = [];
    const reqSectionData = [];
    originalSections?.forEach((section) => {
      if (section?.groupName?._id === originalGroups?.[0]?._id) {
        reqGroupData.push(section);
      } else {
        reqSectionData.push(section);
      }
    });
    setGroups(reqGroupData);
    setSections(reqSectionData);
  }, [originalGroups, originalSections]);

  useEffect(() => {
    handleGroupsAndSections();
  }, [handleGroupsAndSections]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  // Rotate the first statement message every time the carousel changes
  const handleSlideChange = (index) => {
    setMessageIndices((prevIndices) => {
      const newIndices = [...prevIndices];
      newIndices[index] = (newIndices[index] + 1) % caurosalFstPart.length;
      return newIndices;
    });
  };

  const brandDetailsHandler = (order) => {
    if (brandData) {
      changeTitleAndFavicon(brandData);
      setDetails(brandData);
      ecommerceStatus(brandData?.ecommerceSubscription);
      setDirectUrl(true);
      brandViewAndVisitedHandler(brandData?._id);
    } else {
      let urlId = urlParams?.brand;
      brandService.getBrandById({ urlId: urlId }).then((res) => {
        if (res?.success) {
          const data = res.data;
          if (_.isEmpty(res.data)) {
            setDetails({});
            setNoBrand({ name: urlId });
            changeTitleAndFavicon({ brand: urlId });
          } else {
            changeTitleAndFavicon(data);
            setDetails(data);
            ecommerceStatus(data?.ecommerceSubscription);
            setDirectUrl(true);
            brandViewAndVisitedHandler(data?._id);
          }
        } else {
          dispatch(snackbarInfo({ type: 'error', open: true, message: res?.data?.message }));
        }
      });
    }
  };

  const brandViewAndVisitedHandler = (id) => {
    if (isLoggedIn) {
      brandService.viewBrand({ brandId: id, buyerId: profile?._id });
      // brandService.addVisitedBrand(id).then((visitedBrandRes) => {
      //   if (visitedBrandRes.success) {
      //     if (visitedBrandRes.data == 'Added to Recently Visitied Successfully') {
      //       let clonedData = _.cloneDeep(profile);
      //       if (clonedData?.visitedSellers) clonedData?.visitedSellers?.push(id);
      //       else clonedData.visitedSellers = [id];
      //       dispatch(userProfile(clonedData));
      //       dispatch(fetchVisitedStores(null));
      //     }
      //   }
      // });
    }
  };

  const changeTitleAndFavicon = (data) => {
    document.title = data?.brand;
    const link = document.querySelector("link[rel~='icon']");
    if (!link) {
      const newLink = document.createElement('link');
      newLink.rel = 'icon';
      newLink.href = data.logo?.url;
      document.head.appendChild(newLink);
    } else {
      link.href = data.logo?.url;
    }
  };

  const handlePolicyClick = (policy) => {
    setShowRefundPolicy(false);
    setShowShippingPolicy(false);
    setShowPrivacyPolicy(false);
    setShowTerms(false);

    switch (policy) {
      case 'Refund':
        setShowRefundPolicy(true);
        break;
      case 'Shipping':
        setShowShippingPolicy(true);
        break;
      case 'Privacy':
        setShowPrivacyPolicy(true);
        break;
      case 'Terms':
        setShowTerms(true);
        break;
      default:
        break;
    }
  };

  const productNavigationHandler = (item) => {
    navigate(`/BrandProduct/${item._id}`, {
      state: {
        details: item,
        navFrom: 'brand',
        brandDetails: details,
        sellerDetails: null,
        isEcommerceActive: isEcommerceActive,
        table: null,
      },
    });
  };

  const renderGroupedSections = () => {
    const reqSections = groups?.filter(
      (section) =>
        section?.isActive &&
        actualProductsData?.some((product) =>
          product?.sections?.map(String).includes(String(section?._id))
        )
    );
    return (
      <Fragment>
        {reqSections?.length >= 6 ? (
          <div>
            <MynyfyText
              title={reqSections?.[0]?.groupName?.name}
              h2
              bold
              style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
            />
            <div className='product-section'>
              <div className='product-section-first-col'>
                <div className='ps-first-row'>
                  <img
                    className='first-row-img'
                    src={s3ToCloudFront(reqSections?.[0]?.image?.url)}
                    alt='test'
                  />
                  <div className='overlay'>
                    <MynyfyText
                      className={'filter-name'}
                      title={reqSections?.[0]?.name}
                      h3
                      bold
                      color={'white'}
                    />
                    <MynyfyButton
                      className='view-button'
                      label={'View'}
                      onClick={() => {
                        setSelectedSection(reqSections?.[0]);
                        setActiveComponent('Sections');
                      }}
                    />
                  </div>
                </div>
                <div className='ps-second-row' style={{ marginTop: '20px' }}>
                  <div className='second-row-img-container'>
                    <img
                      className='second-row-img'
                      src={s3ToCloudFront(reqSections?.[1]?.image?.url)}
                      alt='test'
                    />
                    <div className='overlay'>
                      <MynyfyText
                        className={'filter-name'}
                        title={reqSections?.[1]?.name}
                        h3
                        bold
                        color={'white'}
                      />
                      <MynyfyButton
                        className='view-button'
                        label={'View'}
                        onClick={() => {
                          setSelectedSection(reqSections?.[1]);
                          setActiveComponent('Sections');
                        }}
                      />
                    </div>
                  </div>
                  <div className='second-row-img-container'>
                    <img
                      className='second-row-img'
                      src={s3ToCloudFront(reqSections?.[2]?.image?.url)}
                      alt='test'
                    />
                    <div className='overlay'>
                      <MynyfyText
                        className={'filter-name'}
                        title={reqSections?.[2]?.name}
                        h3
                        bold
                        color={'white'}
                      />
                      <MynyfyButton
                        className='view-button'
                        label={'View'}
                        onClick={() => {
                          setSelectedSection(reqSections?.[2]);
                          setActiveComponent('Sections');
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='product-section-second-col'>
                <div className='ps-second-row' style={{ marginBottom: '20px' }}>
                  <div className='second-row-img-container'>
                    <img
                      className='second-row-img'
                      src={s3ToCloudFront(reqSections?.[3]?.image?.url)}
                      alt='test'
                    />
                    <div className='overlay'>
                      <MynyfyText
                        className={'filter-name'}
                        title={reqSections?.[3]?.name}
                        h3
                        bold
                        color={'white'}
                      />
                      <MynyfyButton
                        className='view-button'
                        label={'View'}
                        onClick={() => {
                          setSelectedSection(reqSections?.[3]);
                          setActiveComponent('Sections');
                        }}
                      />
                    </div>
                  </div>
                  <div className='second-row-img-container'>
                    <img
                      className='second-row-img'
                      src={s3ToCloudFront(reqSections?.[4]?.image?.url)}
                      alt='test'
                    />
                    <div className='overlay'>
                      <MynyfyText
                        className={'filter-name'}
                        title={reqSections?.[4]?.name}
                        h3
                        bold
                        color={'white'}
                      />
                      <MynyfyButton
                        className='view-button'
                        label={'View'}
                        onClick={() => {
                          setSelectedSection(reqSections?.[4]);
                          setActiveComponent('Sections');
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className='ps-first-row'>
                  <img
                    className='first-row-img'
                    src={s3ToCloudFront(reqSections?.[5]?.image?.url)}
                    alt='test'
                  />
                  <div className='overlay'>
                    <MynyfyText
                      className={'filter-name'}
                      title={reqSections?.[5]?.name}
                      h3
                      bold
                      color={'white'}
                    />
                    <MynyfyButton
                      className='view-button'
                      label={'View'}
                      onClick={() => {
                        setSelectedSection(reqSections?.[5]);
                        setActiveComponent('Sections');
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <MynyfyText
              title={reqSections?.[0]?.groupName?.name}
              h5
              bold
              style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
            />
            <div className='product-section'>
              <div className='grid-layout'>
                {reqSections?.map((section, index) => (
                  <div key={index} className='grid-item'>
                    <img
                      className='grid-img'
                      src={s3ToCloudFront(section?.image?.url)}
                      alt={section?.name}
                    />
                    <div className='overlay'>
                      <MynyfyText
                        className={'filter-name'}
                        title={section?.name}
                        h3
                        bold
                        color={'white'}
                      />
                      <MynyfyButton
                        className='view-button'
                        label={'View'}
                        onClick={() => {
                          setSelectedSection(section);
                          setActiveComponent('Sections');
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </Fragment>
    );
  };

  const renderSections = (data, i) => {
    return (
      <div className='non-image-product-section' key={i}>
        <MynyfyText title={data?.name} h2 bold />
        <MynyfyText
          title={'View all'}
          className='cursor'
          color={'#3076b5'}
          semiBold
          pBig
          onClick={() => {
            setSelectedSection(data);
            setActiveComponent('Sections');
          }}
          style={{ borderBottom: '2px solid #3076b5', paddingBottom: '3px', marginBottom: 20 }}
        />
        <div className='product-container' style={{ maxWidth: '1200px' }}>
          {actualProductsData
            ?.filter((product) => product?.sections?.map(String).includes(String(data?._id)))
            ?.slice(0, 5)
            ?.map((product, index) => (
              <div
                key={index}
                className='filter-product cursor'
                style={{
                  backgroundColor: 'white',
                  padding: '10px',
                  boxShadow: '0 2px 15px 0 rgba(57, 56, 56, 0.1)',
                  borderRadius: '10px',
                  border: '1px solid #e8e8e8',
                }}
                onClick={() => productNavigationHandler(product)}>
                <img
                  className='filter-product-image'
                  src={s3ToCloudFront(product?.variants?.[0]?.image?.[0]?.url)}
                  alt='product img'
                  style={{ objectFit: 'cover' }}
                />
                <div
                  style={{
                    marginTop: '10px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'start',
                  }}>
                  <div>
                    <MynyfyText title={truncateString(product?.name, 20)} h5 />
                    <MynyfyText title={theme.RUPEE + product?.variants?.[0]?.price} />
                  </div>
                  <MynyfyButton
                    label={'View'}
                    style={{
                      backgroundColor: 'white',
                      color: 'black',
                      borderRadius: '3px',
                      border: '1px solid black',
                      height: '30px',
                    }}
                  />
                </div>
              </div>
            ))}
        </div>
      </div>
    );
  };

  const renderComponent = () => {
    switch (activeComponent) {
      case 'About Us':
        return (
          <div>
            <div className='Heading'>
              <MynyfyText title={'About Us'} h2 left bold style={{ paddingleft: 30 }} />
            </div>
            <div className='aboutus-container'>
              <MynyfyText title={details?.aboutUsHeading} h3 bold style={{ marginBottom: 20 }} />
              <div className='content'>
                <MynyfyText title={details?.description} h6 style={{ lineHeight: '25px' }} />
              </div>
            </div>
          </div>
        );
      case 'BrandProducts':
        return (
          <WebBrandProducts
            from={'brand'}
            brandDetails={details}
            isEcommerceActive={true}
            type={searchParams.get('type')}
            table={urlParams?.table}
            searchValue={searchInput}
            setIsProducts={(e) => setIsProducts(e)}
            setIsServices={(e) => setIsServices(e)}
            setProductsType={(e) => setProductsType(e)}
            productsType={productsType}
            section={selectedSection}
            setActualProductsData={setActualProductsData}
            selectedBrandForProducts={selectedBrandForProducts}
            productBrandNames={originalProductBrands}
            handleActiveComponent={() => setActiveComponent(null)}
          />
        );
      case 'Sections':
        return (
          <WebBrandProducts
            from={'brand'}
            brandDetails={details}
            isEcommerceActive={true}
            type={searchParams.get('type')}
            table={urlParams?.table}
            searchValue={searchInput}
            setIsProducts={(e) => setIsProducts(e)}
            setIsServices={(e) => setIsServices(e)}
            setProductsType={(e) => setProductsType(e)}
            productsType={productsType}
            section={selectedSection}
            setActualProductsData={setActualProductsData}
            selectedBrandForProducts={selectedBrandForProducts}
            handleActiveComponent={() => setActiveComponent(null)}
          />
        );
      case 'Orders':
        return (
          <div>
            <div className='Heading'>
              <MynyfyText title={'Orders'} h2 left bold style={{ paddingleft: 30 }} />
            </div>
            <div className='order-container'>
              <div className='tab-buttons'>
                <div
                  style={{
                    padding: '10px',
                    borderBottom:
                      activeTab === 'Active'
                        ? `2px solid ${theme.PRIMARY_BLUE}`
                        : '2px solid #e8e8e8',
                  }}
                  onClick={() => handleTabClick('Active')}>
                  <MynyfyText
                    title={'Active'}
                    color={activeTab === 'Active' ? theme.PRIMARY_BLUE : 'black'}
                    semiBold
                  />
                </div>
                <div
                  style={{
                    padding: '10px',
                    borderBottom:
                      activeTab === 'Completed'
                        ? `2px solid ${theme.PRIMARY_BLUE}`
                        : '2px solid #e8e8e8',
                  }}
                  onClick={() => handleTabClick('Completed')}>
                  <MynyfyText
                    title={'Completed'}
                    color={activeTab === 'Completed' ? theme.PRIMARY_BLUE : 'black'}
                    semiBold
                  />
                </div>
              </div>
              <div className='tab-content'>
                {activeTab === 'Active' && (
                  <WebOrdersList
                    type='ACTIVE'
                    data={orders?.active}
                    action={fetchOrders}
                    brandId={details._id}
                  />
                )}
                {activeTab === 'Completed' && (
                  <WebOrdersList
                    type='NONACTIVE'
                    data={orders?.history}
                    action={fetchOrdersHistory}
                    brandId={details._id}
                  />
                )}
              </div>
            </div>
          </div>
        );

      case 'Contests':
        return (
          <div>
            <div className='Heading'>
              <MynyfyText title={'Contests'} h2 left bold style={{ paddingleft: 30 }} />
            </div>
            <div className='web-store-container'>
              <div className='content-area'>
                <WebShopAndWin
                  from={'brand'}
                  brandDetails={details}
                  isDirectUrl={isDirectUrl}
                  noHeader
                />
              </div>
            </div>
          </div>
        );
      case 'Coupons':
        return (
          <div>
            <div className='Heading'>
              <MynyfyText title={'Coupons'} h2 left bold style={{ paddingleft: 30 }} />
            </div>
            <div className='coupon-container'>
              <Promos
                type={[PromoType.COUPON, PromoType.POINT_COUPON]}
                isFilter={false}
                emptyListTitle={'Coupons'}
                emptyListMsg={
                  isLoggedIn ? ['No Active coupons or offers'] : 'Please Login for Coupons'
                }
                emptyListMsgBold={isLoggedIn ? [1, 2] : null}
                promos={promos?.promos?.actual}
                sortedPromos={promos?.promos?.sorted}
                action={fetchPromos}
                sortedAction={fetchSortedPromos}
                brandDetails={details}
                screen={'Coupons'}
                from={'brand'}
                isDirectUrl={isDirectUrl}
              />
            </div>
          </div>
        );

      case 'Contact Us':
        return (
          <div>
            <div className='Heading'>
              <MynyfyText title={'Contact Us'} h2 left bold style={{ paddingleft: 30 }} />
            </div>
            <div className='contactus-container'>{/* <WebStoreAddress details={details} /> */}</div>
          </div>
        );

      case 'Policies':
        return (
          <div>
            <div className='Heading'>
              <MynyfyText title={'Our Policies'} h2 left bold style={{ paddingleft: 30 }} />
            </div>
            <div className='policy-container'>
              <div className='sidebar-menu'>
                <button className='menu-button' onClick={() => handlePolicyClick('Refund')}>
                  <MynyfyText title={'Refund Policy'} h6 />
                </button>
                <button className='menu-button' onClick={() => handlePolicyClick('Shipping')}>
                  <MynyfyText title={'Shipping Policy'} h6 />
                </button>
                <button className='menu-button' onClick={() => handlePolicyClick('Privacy')}>
                  <MynyfyText title={'Privacy Policy'} h6 />
                </button>
                <button className='menu-button' onClick={() => handlePolicyClick('Terms')}>
                  <MynyfyText title={'Terms & Conditions'} h6 />
                </button>
              </div>
              <div className='content-area' style={{ width: '75%', marginTop: 20, marginLeft: 20 }}>
                {showPrivacyPolicy && (
                  <div>
                    <MynyfyText title={details.policies.privacy[0]?.heading} h5 />
                    <MynyfyText
                      title={details.policies.privacy[0]?.content}
                      h5
                      style={{ paddingTop: 10 }}
                    />
                  </div>
                )}
                {showRefundPolicy && (
                  <div>
                    <MynyfyText title={details.policies.refund[0]?.heading} h5 />
                    <MynyfyText
                      title={details.policies.refund[0]?.content}
                      h5
                      style={{ paddingTop: 10 }}
                    />
                  </div>
                )}
                {showShippingPolicy && (
                  <div>
                    <MynyfyText title={details.policies.shipping[0]?.heading} h5 />
                    <MynyfyText
                      title={details.policies.shipping[0]?.content}
                      h5
                      style={{ paddingTop: 10 }}
                    />
                  </div>
                )}
                {showTerms && (
                  <div>
                    <MynyfyText title={details.policies.terms[0]?.heading} h5 />
                    <MynyfyText
                      title={details.policies.terms[0]?.content}
                      h5
                      style={{ paddingTop: 10 }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  // const orderNavigation = (order, orgData) => {
  //   let table = urlParams?.table;
  //   navigate('/TableOrder/' + table, { state: { order, storeDetails: orgData } });
  // };

  const ecommerceStatus = (subscription) => {
    if (subscription) {
      let subDays = moment(subscription).diff(moment(), 'days');
      if (subDays >= 0) {
        setEcommerceActive(true);
      } else {
        setEcommerceActive(false);
      }
    } else {
      setEcommerceActive(false);
    }
  };

  useEffect(() => {
    if (!_.isEmpty(details)) {
      if (bag?.id === details._id) {
        dispatch(fetchBagAbout({ ...bag?.about, isStoreOpened: true }));
      } else {
        dispatch(fetchBagSellingId(details._id));
        dispatch(
          fetchBagAbout({
            type: 'Brand',
            name: details.brand,
            city: details.city,
            state: details.state,
            policies: details.policies,
            urlId: details.urlId,
            url: process.env.REACT_APP_WEB_HOST + '/Brands/' + details.urlId,
            orgDetails: details,
            isStoreOpened: true,
          })
        );
        if (isLoggedIn) getBuyerPromos(details?._id);
        dispatch(fetchBagCalculation(null));
        dispatch(fetchBagProducts(null));
      }
    }
  }, [details]);

  const getBuyerPromos = (id) => {
    let body = { brand: id };
    promoService.getBuyerClaimedPromos(body).then((res) => {
      if (res.success) {
        dispatch(fetchBagOffers(res.data));
      }
    });
  };

  // const likeHandler = (storeId, key) => {
  //   if (isLoggedIn) {
  //     let options = {
  //       storeId,
  //       setLiked,
  //       profile,
  //       data: state?.isFav ? stores?.favStores : state?.isVisited ? visitedStores : stores?.stores,
  //       dispatch,
  //       action: state?.isFav
  //         ? details.homeDelivery
  //           ? fetchFavStores
  //           : fetchFavOfflineStores
  //         : state?.isVisited
  //         ? fetchVisitedStores
  //         : details.homeDelivery
  //         ? fetchStores
  //         : fetchOfflineStores,
  //       userProfile,
  //       snackbarInfo,
  //     };
  //     if (key === 'like') {
  //       likeStore(options);
  //       if (_.isEmpty(options.data)) details.likesCount = details.likesCount + 1;
  //     } else {
  //       unlikeStore(options);
  //       if (_.isEmpty(options.data)) details.likesCount = details.likesCount - 1;
  //     }
  //   } else {
  //     setOpenSignIn(true);
  //   }
  // };

  const handleClick = () => {
    if (isLoggedIn) navigate('/profile', { state: { isDirectUrl } });
    else setOpenSignIn(true);
  };

  const getCompleteUrl = (url) => {
    if (url?.startsWith('http://') || url?.startsWith('https://')) {
      return url;
    } else {
      return `https://${url}`;
    }
  };

  return details ? (
    _.isEmpty(details) ? (
      <Fragment>
        <WebMynyfyHeader title={brandName || 'Brand Page'} noBack titleStyles={{ padding: 10 }} />
        <MynyfyEmptyMsg message={['We are unable to fetch the', brandName]} bold={[1]} />
      </Fragment>
    ) : (
      <div id='WebBrands'>
        {!_.isEmpty(carouselData) && (
          <div
            style={{
              position: 'fixed',
              top: '0px',
              width: '100%',
              zIndex: 10,
              backgroundColor: 'black',
              maxHeight: 50,
              minHeight: 50,
            }}>
            <Carousel
              infiniteLoop
              autoPlay
              showStatus={false}
              showArrows={false}
              showThumbs={false}
              showIndicators={false}
              interval={4000}
              transitionTime={800}
              onChange={handleSlideChange}>
              {carouselData?.map((item, index) => (
                <div
                  key={index}
                  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Card
                    style={{
                      width: '100%',
                      boxShadow: 'none',
                      backgroundColor: 'black',
                    }}
                    // onClick={() => setActiveComponent('Coupons')}
                    key={item?._id}
                    className='offersDisplayCard cursor'>
                    <div className='rowSA' style={{ paddingTop: 0 }}>
                      <div>
                        <MynyfyText
                          title={caurosalFstPart[messageIndices[index]] || ''}
                          p
                          style={{ color: 'white' }}
                        />
                      </div>
                      {_.isEmpty(bag?.offers) || item?.minimumOrderValue === undefined ? null : (
                        <div className='row'>
                          <MynyfyText
                            title={theme.RUPEE + item?.actualPrice + ' OFF'}
                            bold
                            p
                            style={{ color: 'white' }}
                          />

                          <MynyfyText
                            title={'On a min order of ' + theme.RUPEE + item.minimumOrderValue}
                            style={{ color: 'white' }}
                          />
                        </div>
                      )}
                      {details?.messageToUser && (
                        <div>
                          <MynyfyText title={details?.messageToUser} p style={{ color: 'white' }} />
                        </div>
                      )}

                      {/* <div className='row'>
                        {isLiked ? (
                          <CircularProgress size={16} />
                        ) : profile?.likedSellers?.includes(details._id) ? (
                          <div className='row' onClick={() => likeHandler(details._id, 'unlike')}>
                            <FavoriteRounded fontSize='h4' className='followBtn' />
                            <MynyfyText
                              title={details.likesCount || 0}
                              bold
                              style={{ color: 'white' }}
                              p
                            />
                            <MynyfyText
                              title={' Followers'}
                              style={{ paddingLeft: '3px', color: 'white' }}
                              p
                            />
                          </div>
                        ) : (
                          <div
                            className='row'
                            style={{ gap: 3 }}
                            onClick={() => likeHandler(details._id, 'like')}>
                            <FavoriteBorderRounded fontSize='small' className='followBtn' />
                            <MynyfyText
                              title={details.likesCount || 0}
                              bold
                              p
                              style={{ color: 'white' }}
                            />
                            <MynyfyText
                              title={' Followers'}
                              style={{ paddingLeft: '3px', color: 'white' }}
                              p
                            />
                          </div>
                        )}
                      </div> */}
                    </div>
                  </Card>
                </div>
              ))}
            </Carousel>
          </div>
        )}
        <div id='WebMynyfyHeader'>
          <Card
            className='rowSB mynyfyHeader'
            style={{
              boxShadow: 'none',
              //  top: _.isEmpty(carouselData) ? 0 : 50
            }}>
            <div
              className='row cursor'
              style={{ gap: 5, paddingLeft: 30 }}
              onClick={() => setActiveComponent(null)}>
              {state?.storeDetails ? (
                <ChevronLeftRounded
                  style={{ width: 30, height: 30, display: 'block' }}
                  onClick={() => navigate(-1)}
                />
              ) : null}
              {details?.logo?.url ? (
                <div style={{ width: 40, height: 40, borderRadius: '50%' }}>
                  <img
                    src={s3ToCloudFront(details.logo.url)}
                    width={40}
                    height={40}
                    style={{ objectFit: 'fill', borderRadius: '50%' }}
                    alt='Store logo'
                  />
                </div>
              ) : null}
              <MynyfyText className='BrandName' title={details?.brand} gayahi color={'#000'} h3 />
            </div>

            <div className='MenuItems'>
              {isProducts || isServices ? null : (
                <MynyfyText
                  title={isFoodCategory ? 'Menu' : 'Products'}
                  h6
                  color={'black'}
                  onClick={() => {
                    setSelectedSection(null);
                    setSelectedBrandForProducts(null);
                    setSelectedSection(null);
                    setActiveComponent(null);
                  }}
                  className='menu-item cursor'
                />
              )}
              {isProducts ? (
                <MynyfyText
                  title={isFoodCategory ? 'Menu' : 'Products'}
                  className='menu-item cursor'
                  color={'black'}
                  h6
                  onClick={() => {
                    setSelectedSection(null);
                    setSelectedBrandForProducts(null);
                    setActiveComponent(null);
                    setProductsType(ProductType.PRODUCT);
                  }}
                />
              ) : null}
              {isServices ? (
                <MynyfyText
                  title={'Services'}
                  className='menu-item cursor'
                  color={'black'}
                  h6
                  onClick={() => {
                    setActiveComponent(null);
                    setProductsType(ProductType.SERVICE);
                  }}
                />
              ) : null}
              <MynyfyText
                title={'Orders'}
                h6
                color={'black'}
                onClick={() => {
                  if (isLoggedIn) setActiveComponent('Orders');
                  else setOpenSignIn(true);
                }}
                className='menu-item'
                style={{ cursor: 'pointer' }}
              />
              <MynyfyText
                title={'Contests'}
                h6
                color={'black'}
                onClick={() => setActiveComponent('Contests')}
                className='menu-item'
                style={{ cursor: 'pointer' }}
              />
              <MynyfyText
                title={'Coupons'}
                h6
                color={'black'}
                onClick={() => setActiveComponent('Coupons')}
                className='menu-item'
                style={{ cursor: 'pointer' }}
              />
              <MynyfyText
                title={'Influencer Signup'}
                h6
                color={'black'}
                onClick={() => navigate('/WebSignIn', { state: { details: details } })}
                className='menu-item'
                style={{ cursor: 'pointer' }}
              />
            </div>
            <div className='right-side'>
              <div
                style={{
                  border: '1px solid #938E8B',
                  borderRadius: 40,
                  padding: '5px 10px',
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={() => setIsSearchDrawerOpen(!isSearchDrawerOpen)}>
                <SearchIcon color='gray' className='cursor' />
                <input
                  placeholder={'What are you looking for ? '}
                  value={searchInput}
                  onChange={(e) => {
                    setSearchInput(e.target.value);
                  }}
                  style={{
                    width: '100%',
                    border: 'none',
                    outline: 'none',
                    paddingLeft: '5px',
                    fontSize: '14px',
                    color: 'gray',
                  }}
                />
              </div>
              <BrandProductCartHelper
                from='web'
                navFrom='brand'
                brandDetails={details}
                table={urlParams?.table}
              />
              <UserIcon
                style={{ width: 30, height: 30, display: 'block', paddingLeft: 10 }}
                className='cursor'
                onClick={handleClick}
              />
              <LocationOnOutlinedIcon
                style={{ width: 30, height: 30, display: 'block', paddingLeft: 10 }}
                className='cursor'
                onClick={() => setIsLocationModal(true)}
              />
              <IconButton onClick={handleDrawerOpen} className='burger-menu'>
                <Menu />
              </IconButton>
            </div>
          </Card>

          <Drawer
            anchor='top'
            open={drawerOpen}
            onClose={handleDrawerClose}
            sx={{
              '& .MuiDrawer-paper': {
                width: 200,
                top: 60,
                left: 220,
                margin: '0 auto',
                height: 'auto',
              },
            }}
            id='Stores'>
            <div
              style={{ width: '100%', cursor: 'pointer' }}
              role='presentation'
              onClick={handleDrawerClose}
              onKeyDown={handleDrawerClose}>
              <div className='row drawerOption' onClick={() => setActiveComponent('About Us')}>
                <StyleOutlined width={20} height={20} />
                <MynyfyText title={isFoodCategory ? 'Menu' : 'About Us'} h5 color={'black'} />
              </div>
              <div className='row drawerOption' onClick={() => setActiveComponent(null)}>
                <StyleOutlined width={20} height={20} />
                <MynyfyText title={isFoodCategory ? 'Menu' : 'Products'} h5 color={'black'} />
              </div>
              <div
                className='row drawerOption'
                onClick={() => {
                  if (isLoggedIn) setActiveComponent('Orders');
                  else setOpenSignIn(true);
                }}>
                <OrdersIcon width={20} height={20} />
                <MynyfyText title={'Orders'} h5 color={'black'} />
              </div>

              <div className='row drawerOption' onClick={() => setActiveComponent('Contests')}>
                <ContestsIcon width={20} />
                <MynyfyText title={'Contests'} h5 color={'black'} />
              </div>

              <div className='row drawerOption' onClick={() => setActiveComponent('Coupons')}>
                <CouponsIcon width={20} height={20} />
                <MynyfyText title={'Coupons'} h5 color={'black'} />
              </div>

              <div className='row drawerOption' onClick={() => setActiveComponent('Contact Us')}>
                <ContactPhoneOutlined style={{ color: 'black', width: '20px', height: '20px' }} />
                <MynyfyText title={'Contact Us'} h5 color={'black'} />
              </div>

              <div className='row drawerOption' onClick={() => setActiveComponent('Policies')}>
                <PoliciesIcon width={20} height={20} />
                <MynyfyText title={'Policies'} h5 color={'black'} />
              </div>
            </div>
          </Drawer>
        </div>

        {activeComponent ? (
          <div className='ren-storelayout'>{renderComponent()}</div>
        ) : (
          <div style={{ backgroundColor: '#fff' }}>
            {_.isEmpty(details?.banner) ? null : (
              <Carousel
                infiniteLoop={true}
                autoPlay={true}
                showStatus={false}
                showArrows={false}
                showThumbs={false}
                showIndicators={false}
                interval={3000}>
                {details?.banner?.map((res, i) => (
                  <div key={i} style={{ paddingBottom: '20px' }}>
                    <img
                      src={s3ToCloudFront(res?.url)}
                      className={isFoodCategory ? 'storeImg' : 'normalStoreImg'}
                      alt='store img'
                    />
                  </div>
                ))}
              </Carousel>
            )}
            <div className='brandlayout'>
              <div className='rowSB' style={{ padding: 15 }}>
                {details?.logo?.url && !details?.banner ? (
                  <MynyfyText
                    title={details?.brand}
                    gayahi
                    style={{
                      fontSize: 16,
                      fontWeight: 600,
                      color: '#000',
                      letterSpacing: 1,
                    }}
                    center
                  />
                ) : null}
              </div>
              <div className='custom-product-section-container'>
                {groups?.length > 0 && renderGroupedSections()}
              </div>
              <div className='custom-product-section-container-no-image'>
                {sections?.length > 0 &&
                  sections?.map((section, index) => {
                    const isSectionPresent =
                      section?.isActive &&
                      actualProductsData?.some((product) =>
                        product?.sections?.map(String).includes(String(section?._id))
                      );
                    return isSectionPresent ? renderSections(section, index) : null;
                  })}
              </div>
              {_.isEmpty(originalProductBrands) ? null : (
                <div className='shopByBrands' style={{ margin: '2% 0' }}>
                  <MynyfyText title={'Shop by Brands'} h5 bold />
                  <div className='row brandRow' style={{ maxWidth: '900px', gap: 30 }}>
                    {originalProductBrands?.map((brand, i) => (
                      <div
                        key={i}
                        className='brand'
                        onClick={() => {
                          setSelectedBrandForProducts(brand);
                          setActiveComponent('BrandProducts');
                        }}>
                        {brand?.image?.url ? (
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                            }}>
                            <img
                              className='logo'
                              src={s3ToCloudFront(brand?.image?.url)}
                              alt='brand logo'
                              style={{
                                objectFit: 'contain',
                                width: '100px',
                                height: '80px',
                                border: '1px solid #e8e8e8',
                                borderRadius: 10,
                              }}
                            />
                            <MynyfyText title={brand?.name} semiBold />
                          </div>
                        ) : (
                          <div
                            style={{
                              objectFit: 'contain',
                              width: '100px',
                              height: '80px',
                              backgroundColor: '#e8e8e8',
                              border: '1px solid #e8e8e8',
                              borderRadius: 10,
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}>
                            <MynyfyText title={brand?.name} semiBold />
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              )}
              <WebBrandProducts
                from={'brand'}
                brandDetails={details}
                isEcommerceActive={isEcommerceActive}
                type={searchParams.get('type')}
                table={urlParams?.table}
                searchValue={searchInput}
                setIsProducts={(e) => setIsProducts(e)}
                setIsServices={(e) => setIsServices(e)}
                setProductsType={(e) => setProductsType(e)}
                productsType={productsType}
                setActualProductsData={setActualProductsData}
                handleActiveComponent={() => setActiveComponent(null)}
                productBrandNames={originalProductBrands}
              />
            </div>
          </div>
        )}
        <LocationModal
          isOpen={isLocationModal}
          close={(e) => {
            if (!_.isEmpty(location) || e) setIsLocationModal(false);
          }}
          closeBtn={() => setIsLocationModal(false)}
          content={
            'Some products may not be deliverable to every location. Set your delivery location to view serviceable products.'
          }
          modalProps={{
            position: 'center',
            style: {
              borderRadius: 10,
            },
          }}
        />
        <MynyfyModal
          open={showResellerLoginModal}
          close={() => {
            sessionStorage.removeItem('reseller_redirection');
            setShowResellerLoginModal(false);
          }}
          position={'center'}
          style={{
            borderRadius: 10,
          }}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <MynyfyText title={'Congratulations!'} h4 semiBold style={{ marginBottom: 10 }} />
            <div style={{ marginBottom: 10 }}>
              <MynyfySpan
                title={`Your Dropshipping | Influencer account with ${details?.brand} created successfully, login into `}
              />
              <MynyfySpan
                title={process.env.REACT_APP_SELLER_WEB_HOST}
                link
                onClick={() => openUrl(process.env.REACT_APP_SELLER_WEB_HOST)}
              />
              <MynyfySpan title={' to start reselling'} />
            </div>
            <a
              href={process.env.REACT_APP_SELLER_WEB_HOST + '/SignIn'}
              target='_blank'
              rel='noopener noreferrer'
              onClick={() => {
                sessionStorage.removeItem('reseller_redirection');
                setShowResellerLoginModal(false);
              }}>
              <MynyfyButton label={'Login'} />
            </a>
          </div>
        </MynyfyModal>
        <SignInModal
          open={openSignIn}
          close={() => setOpenSignIn(false)}
          sellerDetails={{
            image: details?.banner?.[0]?.url,
            storename: details?.brand,
          }}
        />
        <div className='Storefooter'>
          <div
            className='footer-content'
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'start',
            }}>
            <div className='MenuItems-footer'>
              {details?.description ? (
                <div
                  style={{ alignItems: 'start', display: 'flex', flexDirection: 'column' }}
                  onClick={() => setActiveComponent('About Us')}>
                  <MynyfyText
                    title={`About ${details?.aboutUsHeading || details?.brand}`.toUpperCase()}
                    h4
                    color={'black'}
                    semiBold
                    className='menu-item'
                    style={{ cursor: 'pointer', letterSpacing: 1 }}
                  />
                  <MynyfyText
                    title={`${details?.description?.slice(0, 150) || ''}`}
                    h6
                    color={'black'}
                    className='menu-item'
                    style={{
                      cursor: 'pointer',
                      letterSpacing: 1,
                      textAlign: 'left',
                      width: '500px',
                      marginTop: 20,
                    }}
                  />
                </div>
              ) : null}
              <div
                style={{
                  alignItems: 'start',
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: 10,
                  maxWidth: '400px',
                }}>
                <MynyfySpan
                  title={`${'Visit us at: '.toUpperCase()}`}
                  h4
                  semiBold
                  color={'black'}
                  className='menu-item'
                  style={{ cursor: 'pointer', letterSpacing: 1, textAlign: 'left' }}
                  onClick={() => details?.mapUrl && window.open(details?.mapUrl)}
                />
                {details?.address ? (
                  <MynyfyText
                    h6
                    title={details?.address + ', '}
                    style={{ textAlign: 'left', marginTop: 10 }}
                  />
                ) : null}
                <MynyfyText
                  h6
                  title={[details?.state, details?.city, details?.area, details?.pincode]
                    ?.filter((e) => !_.isEmpty(e))
                    .join(', ')}
                  style={{ textAlign: 'left', marginTop: 10 }}
                />
              </div>

              <div
                style={{
                  alignItems: 'start',
                  display: 'flex',
                  flexDirection: 'column',
                }}>
                <MynyfyText
                  title={'Contact Us'.toUpperCase()}
                  h4
                  semiBold
                  color={'black'}
                  className='menu-item'
                  style={{ cursor: 'pointer', letterSpacing: 1, paddingBottom: 15, marginTop: 10 }}
                />
                <MynyfyText title={details?.customerCareNumber || ''} />
                <MynyfyText title={details?.emailId || ''} style={{ marginTop: 5 }} />
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                justifyContent: 'start',
                height: '100%',
              }}>
              <MynyfyText
                className='cursor'
                title={'quick links'.toUpperCase()}
                h4
                semiBold
                style={{ marginBottom: '15px' }}
              />
              <MynyfyText
                className='cursor'
                title={'About Us'}
                style={{ margin: '5px 0' }}
                onClick={() => setActiveComponent('About Us')}
              />
              <MynyfyText
                className='cursor'
                title={'Policies'}
                style={{ margin: '5px 0' }}
                onClick={() => setActiveComponent('Policies')}
              />
              <MynyfyText
                className='cursor'
                title={`${isFoodCategory ? 'Menu' : 'Products'}`}
                style={{ margin: '5px 0' }}
                onClick={() => setActiveComponent(null)}
              />
            </div>
            <div className='rowC' style={{ marginTop: -13 }}>
              {details?.logo?.url ? (
                <div style={{ width: 50, height: 50, borderRadius: '50%', paddingLeft: 60 }}>
                  <img
                    src={s3ToCloudFront(details?.logo?.url)}
                    width={50}
                    height={50}
                    style={{ objectFit: 'fill', borderRadius: '50%', marginRight: 10 }}
                    alt='img'
                  />
                </div>
              ) : null}
              <MynyfyText
                className='StoreName'
                title={details?.brand}
                h2
                gayahi
                color={'#000'}
                style={{ marginLeft: 10 }}
              />
            </div>
          </div>
          <div
            // className='rowSA'
            style={{
              marginTop: 20,
              margin: '0 0%',
              // border: '2px solid red',
              backgroundColor: '#f9f9fa',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              paddingBottom: 40,
              alignItems: 'start',
              gap: '190px',
            }}>
            <div style={{ padding: 2 }}>
              <div className='rowC' style={{ paddingTop: 5 }}>
                <MynyfyText title={'Powered by'} style={{ color: 'black' }} />
                <a href={process.env.REACT_APP_WEB_HOST} target='_blank' rel='noopener noreferrer'>
                  <img
                    src={mynyfyLogo}
                    alt='Mynyfylogo'
                    style={{
                      height: 20,
                      width: 'auto',
                      marginRight: 10,
                      marginLeft: 10,
                      objectFit: 'contain',
                    }}
                  />
                </a>
                <MynyfySpan title={'Platform'} style={{ paddingTop: 3, color: 'black' }} />
              </div>
            </div>
            <div>
              © 2025 <MynyfySpan title={details?.brand} primary h5 style={{ margin: '0px 10px' }} />
              All rights reserved
            </div>
            <div className='rowC' style={{ gap: 15 }}>
              {details?.socialLinks?.facebook ||
              details?.socialLinks?.instagram ||
              details?.socialLinks?.youtube ? (
                <MynyfyText title={'Follow Us'} />
              ) : null}
              {details?.socialLinks?.facebook ? (
                <Facebook
                  style={{ color: '#1877F2', fontSize: 30 }}
                  className='cursor'
                  onClick={() => openUrl(details?.socialLinks?.facebook)}
                />
              ) : null}
              {details?.socialLinks?.instagram ? (
                <Instagram
                  style={{ color: '#d62976', fontSize: 30 }}
                  className='cursor'
                  onClick={() => openUrl(details?.socialLinks?.instagram)}
                />
              ) : null}
              {details?.socialLinks?.youtube ? (
                <YouTube
                  style={{ color: 'red', fontSize: 35 }}
                  className='cursor'
                  onClick={() => openUrl(details?.socialLinks?.youtube)}
                />
              ) : null}
              {/* <img
                src={require('../../assets/imgs/icons/logo.png')}
                style={{ width: 25, height: 25 }}
                // onClick={() => likeHandler(details?._id, 'like')}
                alt='Mynyfy Logo'
                className='cursor'
              /> */}
            </div>
          </div>
        </div>
        <div
          style={{
            position: 'fixed',
            zIndex: 9,
            display: details?.socialLinks?.whatsapp ? 'block' : 'none',
            right: '4%',
            bottom: '2%',
          }}>
          <a
            href={`https://wa.me/${details?.socialLinks?.whatsapp}`}
            target='_blank'
            rel='noopener noreferrer'
            style={{ textDecoration: 'none', color: 'inherit' }}>
            <WhatsApp style={{ color: '#25D366', fontSize: 40 }} />
          </a>
        </div>
      </div>
    )
  ) : (
    <MynyfyLoader />
  );
};

export default WebBrandDetails;
