import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import MynyfyText from '../../components/MynyfyText';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import _ from 'lodash';
import MynyfyEmptyMsg from '../../components/MynyfyEmptyMsg';
import MynyfyLoader from '../../components/MynfyfLoader';
import MynyfyHeader from '../../components/MynyfyHeader';
import { Card, CircularProgress, Drawer } from '@mui/material';
import {
  CallRounded,
  ControlPointRounded,
  Facebook,
  FavoriteBorderRounded,
  FavoriteRounded,
  Instagram,
  KeyboardDoubleArrowRight,
  MailRounded,
  RemoveCircleOutlineRounded,
  ShareRounded,
  StyleOutlined,
  WhatsApp,
  YouTube,
} from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { likeBrand, unlikeBrand } from './BrandsHelper';
import { userProfile } from '../../redux/actions/user.action';
import { snackbarInfo } from '../../redux/actions/snackbar.action';
import {
  fetchActualBrands,
  fetchActualFavBrands,
  fetchBrands,
  fetchFavBrands,
} from '../../redux/actions/brand.action';
import MynyfyTab from '../../components/MynyfyTab';
import AllContests from '../Contest/AllContests';
import brandService from '../../services/brandService';
import Promos from '../Promos';
import { PromoType } from '../../utils/enums';
import { fetchPromos, fetchSortedPromos } from '../../redux/actions/promo.action';
import SignInModal from '../Auth/SignInModal';
import promoService from '../../services/promoService';
import {
  fetchBagAbout,
  fetchBagCalculation,
  fetchBagOffers,
  fetchBagProducts,
  fetchBagSellingId,
} from '../../redux/actions/bag.action';
import ProductCartHelper from '../Products/ProductCartHelper';
import { getOS, openUrl, s3ToCloudFront } from '../../utils/CommonMethods';
import './brandsDetails.scss';
import { ReactComponent as UserIcon } from '../../assets/svg/user.svg';
import { ReactComponent as MenuIcon } from '../../assets/svg/menu.svg';
import theme from '../../utils/theme';
import { ReactComponent as OrdersIcon } from '../../assets/svg/basket.svg';
import { ReactComponent as ContestsIcon } from '../../assets/svg/shop_and_win.svg';
import { ReactComponent as CouponsIcon } from '../../assets/svg/coupons.svg';
import { ReactComponent as PoliciesIcon } from '../../assets/svg/outlineRefund.svg';
import { ContactPhoneOutlined } from '@mui/icons-material';
import { Carousel } from 'react-responsive-carousel';
import mynyfyLogo from '../../assets/imgs/LandingPage/mynyfy.png';
import MynyfySpan from '../../components/MynyfySpan';
import MynyfyMobileFilter from '../../components/MynyfyMobileFilter';
import { decrementHandler, incrementHandler, qtyHandler } from '../Products/AddProductHelper';
import MynyfyButton from '../../components/MynyfyButton';
import productService from '../../services/productService';
import BrandProducts from '../Products/BrandProducts';
import useWindowRefresh from '../../hooks/useWindowRefresh';
import BrandProductCartHelper from '../Products/ProductCartHelperForBrand';
import OrdersList from '../Orders/OrdersList';
import { fetchOrders, fetchOrdersHistory } from '../../redux/actions/order.action';
import MynyfyModal from '../../components/MynyfyModal';
import ShareRoundedIcon from '@mui/icons-material/ShareRounded';
import contestService from '../../services/contestService';
import { ContestType } from '../../utils/enums';

const BrandDetails = ({ brandData }) => {
  useWindowRefresh();

  const dispatch = useDispatch();
  const { state } = useLocation();
  const navigate = useNavigate();
  const urlParams = useParams();
  const [searchParams] = useSearchParams();

  const user = useSelector((state) => state.user);

  const profile = useSelector((state) => state.user?.profile);
  const isLoggedIn = useSelector((state) => state.user?.isLoggedIn);
  const brands = useSelector((state) => state.brands);
  const promos = useSelector((state) => state.promos);
  const bag = useSelector((state) => state.bag);
  const orders = useSelector((state) => state.orders);
  const inputRefs = useRef([]);
  const location = useSelector((state) => state?.location?.location);
  const id = user.user?.id || null;

  const [brandName, setBrandName] = useState('');
  const [details, setDetails] = useState(null);
  const [isLiked, setLiked] = useState(null);
  const [openSignIn, setOpenSignIn] = useState(false);
  const [activeComponent, setActiveComponent] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [open, setOpen] = useState(true); // for policy
  const [originalGroups, setOriginalGroups] = useState(null);
  const [originalSections, setOriginalSections] = useState(null);
  const [originalProductBrands, setOriginalProductBrands] = useState(null);
  const [groups, setGroups] = useState(null);
  const [sections, setSections] = useState(null);
  const [selectedSection, setSelectedSection] = useState(null);
  const [actualProductsData, setActualProductsData] = useState(null);
  const [selectedBrandForProducts, setSelectedBrandForProducts] = useState(null);
  const [openViewAllBrands, setOpenViewAllBrands] = useState(false);
  const [showResellerLoginModal, setShowResellerLoginModal] = useState(false);
  const [brandContests, setBrandContests] = useState([]);

  const successfulRedirectionAfterResellerSignup = sessionStorage.getItem('reseller_redirection');
  const isFoodCategory = details?.category?.find(
    (e) => e._id === process.env.REACT_APP_FOOD_CAT_ID
  );

  useEffect(() => {
    if (details) getContests();
  }, [details]);

  const getContests = () => {
    let params = {
      status: 'ACTIVE',
      type: ContestType.SHOP_AND_WIN,
    };
    if (!_.isEmpty(location)) {
      if (location?.state?._id) params.state = location?.state?._id;
      if (location?.city?._id) params.city = location?.city?._id;
      if (location?.area?._id) params.area = location?.area?._id;
    }

    params.brand = details?._id;

    if (isLoggedIn) params.buyerId = id;
    contestService.getContests(params).then((res) => {
      if (res.success) {
        let sorted = _.orderBy(res.data, 'createdAt', 'desc');
        setBrandContests(sorted);
      } else {
        setBrandContests([]);
      }
    });
  };

  useEffect(() => {
    if (!details?._id) return;
    const fetchProductProps = async () => {
      try {
        const [groupRes, sectionRes] = await Promise.all([
          productService.getProductProps({ brandId: details?._id, type: 'group' }),
          productService.getProductProps({ brandId: details?._id, type: 'section' }),
        ]);
        if (groupRes.success) setOriginalGroups(groupRes.data);
        if (sectionRes.success) setOriginalSections(sectionRes.data);
      } catch (error) {
        console.error('Error fetching product props:', error);
      }
    };

    fetchProductProps();
  }, [details?._id]);

  useEffect(() => {
    if (!details?._id) return;
    const fetchProductBrands = async () => {
      let params = {
        brandId: details?._id,
      };
      productService.getBrandProductBrandNames(params).then((res) => {
        if (res.success) {
          const data = _.sortBy(res.data, 'name');
          setOriginalProductBrands(data);
        }
      });
    };

    fetchProductBrands();
  }, [details?._id]);

  useEffect(() => {
    let successfulRedirection = sessionStorage.getItem('reseller_redirection');
    if (successfulRedirection === 'true') {
      setShowResellerLoginModal(true);
    }
  }, [successfulRedirectionAfterResellerSignup]);

  const handleGroupsAndSections = useCallback(() => {
    if (!originalGroups?.length || !originalSections?.length) return;
    const reqGroupData = [];
    const reqSectionData = [];
    originalSections?.forEach((section) => {
      if (section?.groupName?._id === originalGroups?.[0]?._id) {
        reqGroupData.push(section);
      } else {
        reqSectionData.push(section);
      }
    });
    setGroups(reqGroupData);
    setSections(reqSectionData);
  }, [originalGroups, originalSections]);

  useEffect(() => {
    handleGroupsAndSections();
  }, [handleGroupsAndSections]);

  const productNavigationHandler = (item) => {
    navigate(`/BrandProduct/${item._id}`, {
      state: {
        details: item,
        navFrom: 'brand',
        brandDetails: details,
        sellerDetails: null,
        isEcommerceActive: null,
        table: null,
      },
    });
  };

  useEffect(() => {
    if (activeComponent === null) setSelectedBrandForProducts(null);
  }, [activeComponent]);

  const changeTitleAndFavicon = (data) => {
    document.title = data?.brand;
    const link = document.querySelector("link[rel~='icon']");
    if (!link) {
      const newLink = document.createElement('link');
      newLink.rel = 'icon';
      newLink.href = data.logo?.url;
      document.head.appendChild(newLink);
    } else {
      link.href = data.logo?.url;
    }
  };

  useEffect(() => {
    if (brandData) {
      setDetails(brandData);
      changeTitleAndFavicon(brandData);
    } else {
      let urlId = urlParams?.brand;
      setBrandName(urlId || '');
      brandService.getBrandById({ urlId: urlId }).then((res) => {
        if (res.success) {
          const data = res.data;
          if (_.isEmpty(data)) {
            setDetails([]);
            changeTitleAndFavicon({ brand: urlId });
          } else {
            setDetails(data);
            changeTitleAndFavicon(data);
          }
        } else {
          setDetails([]);
          changeTitleAndFavicon({ brand: urlId });
        }
      });
    }
  }, [urlParams]);

  useEffect(() => {
    if (!_.isEmpty(details)) {
      if (bag?.id === details._id) {
        // do nothing
      } else {
        dispatch(fetchBagSellingId(details._id));
        dispatch(
          fetchBagAbout({
            type: 'Brand',
            name: details.brand,
            city: details.city,
            state: details.state,
            policies: details.policies,
            urlId: details.urlId,
            url: process.env.REACT_APP_WEB_HOST + '/Brands/' + details.urlId,
            orgDetails: details,
            isStoreOpened: true,
          })
        );
        if (isLoggedIn) getBuyerPromos(details?._id);
        dispatch(fetchBagCalculation(null));
        dispatch(fetchBagProducts(null));
      }
    }
  }, [details]);

  const getBuyerPromos = (id) => {
    let body = { brand: id };
    promoService.getBuyerClaimedPromos(body).then((res) => {
      if (res.success) {
        dispatch(fetchBagOffers(res.data));
      }
    });
  };

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };
  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleClick = () => {
    if (isLoggedIn) navigate('/profile', { state: { isDirectUrl: true } });
    else setOpenSignIn(true);
  };

  const contactNumComponent = (contactNo) => {
    return details ? (
      <div className='row contentSec' onClick={() => window.open('tel:' + contactNo)}>
        <MynyfyText title={'Contact Brand at :'} className='subHeading' />
        <CallRounded
          color='primary'
          fontSize=''
          className='cursor'
          style={{ margin: '0px 5px 0px 10px' }}
        />
        <MynyfyText title={contactNo} pBig link />
      </div>
    ) : null;
  };

  const renderGroupedSections = () => {
    const reqSections = groups?.filter(
      (section) =>
        section?.isActive &&
        actualProductsData?.some((product) =>
          product?.sections?.map(String).includes(String(section?._id))
        )
    );
    return (
      reqSections?.length > 0 && (
        <div>
          <MynyfyText
            title={reqSections?.[0]?.groupName?.name}
            h5
            bold
            style={{ width: '100%', display: 'flex', justifyContent: 'center', marginBottom: 15 }}
          />
          <div className='product-section' style={{ marginBottom: 20 }}>
            <div className='ps-first-row'>
              <img className='first-row-img' src={reqSections?.[0]?.image?.url} alt='test' />
              <div className='overlay'>
                <MynyfyText
                  className={'filter-name'}
                  title={reqSections?.[0]?.name}
                  h3
                  bold
                  color={'white'}
                />
                <MynyfyButton
                  className='view-button'
                  label={'View'}
                  onClick={() => {
                    setSelectedSection(reqSections?.[0]);
                    setActiveComponent('Sections');
                  }}
                />
              </div>
            </div>
          </div>
          <div className='product-section'>
            <div className='grid-layout'>
              {reqSections?.slice(1, 6).map((section, index) => (
                <div key={index} className='grid-item'>
                  <img className='grid-img' src={section?.image?.url} alt={section?.name} />
                  <div className='overlay'>
                    <MynyfyText
                      className={'filter-name'}
                      title={section?.name}
                      h6
                      bold
                      color={'white'}
                    />
                    <MynyfyButton
                      className='view-button'
                      label={'View'}
                      style={{ marginBottom: '10px' }}
                      onClick={() => {
                        setSelectedSection(section);
                        setActiveComponent('Sections');
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )
    );
  };

  const renderSections = (data, index) => {
    return (
      <div className='non-image-product-section' key={index}>
        <MynyfyText title={data?.name} h5 bold />
        <MynyfyText
          title={'View all'}
          color={'#3076b5'}
          semiBold
          pBig
          onClick={() => {
            setSelectedSection(data);
            setActiveComponent('Sections');
          }}
          style={{ borderBottom: '2px solid #3076b5', paddingBottom: '3px' }}
        />
        <div className='product-container'>
          {actualProductsData
            ?.filter((product) => product?.sections?.map(String).includes(String(data?._id)))
            ?.slice(0, 5)
            .map((product, index) => (
              <div
                key={index}
                className='filter-product'
                onClick={() => productNavigationHandler(product)}>
                <div className='filter-product-image'>
                  <img
                    className='filter-product-image'
                    src={product?.variants?.[0]?.image?.[0]?.url}
                    alt='product img'
                    style={{ objectFit: 'cover', border: '1px solid #e8e8e8', borderRadius: '5px' }}
                  />
                </div>
                <div style={{ marginTop: '10px' }}>
                  <MynyfyText title={product?.name} h5 />
                  <MynyfyText title={theme.RUPEE + product?.variants?.[0]?.price} />
                </div>
              </div>
            ))}
        </div>
      </div>
    );
  };

  const shareContestUrl = async (item) => {
    try {
      if (navigator.share) {
        const imageUrl = item?.image;

        await navigator.share({
          title: item?.name,
          files: [
            new File([await fetch(imageUrl).then((res) => res.blob())], 'image.jpg', {
              type: 'image/jpeg',
            }),
          ],
        });
      }
    } catch (error) {
      dispatch(snackbarInfo({ open: true, type: 'error', message: error.message }));
    }
  };

  const renderComponent = () => {
    switch (activeComponent) {
      case 'About Us':
        return (
          <div style={{ paddingInline: '15px' }}>
            <div className='aboutus-container'>
              <div className='content'>
                <Card className='aboutSections'>
                  {details.description ? (
                    <div style={{ paddingBottom: 15 }}>
                      <MynyfyText
                        title={'About Us'}
                        h4
                        semiBold
                        style={{ marginLeft: 10, marginBottom: 10, marginTop: 10 }}
                      />
                      <MynyfyText title={details?.description} p style={{ marginLeft: 10 }} />
                    </div>
                  ) : null}
                </Card>
              </div>
            </div>
          </div>
        );
      case 'BrandProducts':
        return (
          <BrandProducts
            from={'brand'}
            brandDetails={details}
            isEcommerceActive={true}
            type={searchParams.get('type')}
            table={urlParams?.table}
            section={selectedSection}
            setActualProductsData={setActualProductsData}
            productBrandNames={originalProductBrands}
            selectedBrandForProducts={selectedBrandForProducts}
          />
        );
      case 'Sections':
        return (
          <BrandProducts
            from={'brand'}
            brandDetails={details}
            isEcommerceActive={true}
            type={searchParams.get('type')}
            table={urlParams?.table}
            section={selectedSection}
            setActualProductsData={setActualProductsData}
            productBrandNames={originalProductBrands}
          />
        );

      case 'Products':
        return (
          <BrandProducts
            from={'brand'}
            brandDetails={details}
            isEcommerceActive={true}
            type={searchParams.get('type')}
            table={urlParams?.table}
            setActualProductsData={setActualProductsData}
            productBrandNames={originalProductBrands}
          />
        );

      case 'Orders':
        return (
          <div>
            <MynyfyTab
              tabs={['Active', 'Closed']}
              tabComponent={[
                <OrdersList
                  type='ACTIVE'
                  data={orders?.active}
                  action={fetchOrders}
                  brandId={details?._id}
                />,
                <OrdersList
                  type='NONACTIVE'
                  data={orders?.history}
                  action={fetchOrdersHistory}
                  brandId={details?._id}
                />,
              ]}
            />
          </div>
        );

      case 'Contests':
        return (
          <MynyfyTab
            noBackBtn={true}
            tabs={['Active', 'Winners']}
            tabComponent={[
              <AllContests brandDetails={details} status='active' from={'brand'} />,
              <AllContests
                brandDetails={details}
                status='NOACTIVE'
                from={'brand'}
                isHistory={true}
              />,
            ]}
          />
        );
      case 'Coupons':
        return (
          <Promos
            type={[PromoType.COUPON, PromoType.POINT_COUPON]}
            isFilter={false}
            emptyListTitle={'Coupons'}
            emptyListMsg={isLoggedIn ? ['No Active coupons or offers'] : 'Please Login for Coupons'}
            emptyListMsgBold={isLoggedIn ? [1, 2] : null}
            promos={promos?.promos?.actual}
            sortedPromos={promos?.promos?.sorted}
            action={fetchPromos}
            sortedAction={fetchSortedPromos}
            brandDetails={details}
            screen={'Coupons'}
            from={'brand'}
          />
        );
      case 'Contact Us':
        return (
          <div style={{ paddingInline: '15px' }}>
            <Card className='aboutSections'>
              {details?.address ? (
                <div className='row contentSec'>
                  <MynyfyText title={'Address :'} className='subHeading' />
                  <div style={{ marginLeft: 10 }}>
                    {details.address ? <MynyfySpan title={`${details.address},  `} /> : null}
                  </div>
                </div>
              ) : null}
              {contactNumComponent(details?.customerCareNumber)}
              {details?.emailId ? (
                <div
                  className='row contentSec'
                  onClick={() => window.open(`mailto:${details.emailId}?subject=Help!&body=Hi,`)}>
                  <MynyfyText title={'Support Id :'} className='subHeading' />
                  <MailRounded
                    color='primary'
                    fontSize=''
                    className='cursor'
                    style={{ margin: '0px 5px 0px 10px' }}
                  />
                  <MynyfyText title={details.emailId} pBig link />
                </div>
              ) : null}
              <div></div>
            </Card>
          </div>
        );
      case 'Policies':
        return (
          <div style={{ paddingInline: '15px' }}>
            <Card className='aboutSections' style={{ marginTop: 15 }}>
              <MynyfyText title={'Policies'} className='cursor' onClick={() => setOpen(!open)} />
              {open ? (
                <Fragment>
                  <div className='userPolicyOptionCntainer'>
                    <Card
                      className='userPolicyOption'
                      style={{ marginTop: '5px' }}
                      onClick={() =>
                        navigate('/BrandShippingPolicy', { state: { details: details } })
                      }>
                      <MynyfyText title={'Shipping Policy'} />
                    </Card>
                  </div>
                  <div className='userPolicyOptionCntainer'>
                    <Card
                      className='userPolicyOption'
                      style={{ marginTop: '5px' }}
                      onClick={() =>
                        navigate('/BrandPrivicyPolicy', { state: { details: details } })
                      }>
                      <MynyfyText title={'Privacy Policy'} />
                    </Card>
                  </div>
                  <div className='userPolicyOptionCntainer'>
                    <Card
                      className='userPolicyOption'
                      style={{ marginTop: '5px' }}
                      onClick={() =>
                        navigate('/BrandRefundPolicy', { state: { details: details } })
                      }>
                      <MynyfyText title={'Return / Refund / Exchange Policy'} />
                    </Card>
                  </div>

                  <div className='userPolicyOptionCntainer'>
                    <Card
                      className='userPolicyOption'
                      style={{ marginTop: '5px' }}
                      onClick={() =>
                        navigate('/BrandTermsPolicy', { state: { details: details } })
                      }>
                      <MynyfyText title={'Terms & Conditions'} />
                    </Card>
                  </div>
                </Fragment>
              ) : null}
            </Card>
            <MynyfyText
              title={'Powered by Mynyfy'}
              primary
              style={{ marginTop: 10, textAlign: 'end' }}
            />
          </div>
        );
      default:
        return null;
    }
  };

  const likeHandler = (brandId, key) => {
    if (isLoggedIn) {
      let options = {
        brandId,
        setLiked,
        profile,
        data: state?.isFav ? brands?.favBrands : brands?.brands,
        actalData: state?.isFav ? brands?.actualFavBrands : brands?.actualBrands,
        dispatch,
        action: state?.isFav ? fetchFavBrands : fetchBrands,
        actualAction: state?.isFav ? fetchActualFavBrands : fetchActualBrands,
        userProfile,
        snackbarInfo,
      };
      if (key === 'like') {
        likeBrand(options);
        if (_.isEmpty(options.data)) details.likesCount = details.likesCount + 1;
      } else {
        unlikeBrand(options);
        if (_.isEmpty(options.data)) details.likesCount = details.likesCount - 1;
      }
    } else {
      setOpenSignIn(true);
    }
  };

  const getCompleteUrl = (url) => {
    if (url?.startsWith('http://') || url?.startsWith('https://')) {
      return url;
    } else {
      return `https://${url}`;
    }
  };

  return details ? (
    _.isEmpty(details) ? (
      <Fragment>
        <MynyfyHeader title={brandName || 'Brand Page'} noBack titleStyles={{ padding: 10 }} />
        <MynyfyEmptyMsg message={['We are unable to fetch the', brandName]} bold={[1]} />
      </Fragment>
    ) : (
      <div id='BrandsDetails'>
        <BrandProductCartHelper navFrom='brand' brandDetails={details} />
        <div id='MynyfyHeader'>
          <Card
            className='rowSB mynyfyHeader'
            style={{
              padding: 10,
              boxShadow: 'none',
              display: 'flex',
              alignItems: 'center',
            }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <MenuIcon
                style={{ width: 24, height: 24, color: 'grey' }}
                onClick={handleDrawerOpen}
                className='cursor'
              />
            </div>
            <div
              className='row cursor'
              style={{ gap: 5 }}
              onClick={() => {
                setSelectedSection(null);
                setSelectedBrandForProducts(null);
                setActiveComponent(null);
              }}>
              {details?.logo?.url ? (
                <div className='rowC' style={{ width: 180, height: 40 }}>
                  <img
                    src={s3ToCloudFront(details.logo.url)}
                    width={'50px'}
                    height={'50px'}
                    style={{
                      objectFit: 'cover',
                      border: '0px solid red',
                      marginTop: 1,
                      borderRadius: 5,
                    }}
                    alt='logo'
                  />
                </div>
              ) : (
                <MynyfyText
                  title={details?.brand}
                  gayahi
                  style={{ width: 180, fontSize: 12 }}
                  center
                />
              )}
            </div>

            <div style={{ display: 'flex', alignItems: 'center' }}>
              <UserIcon
                style={{
                  width: 24,
                  height: 24,
                  color: 'grey',
                }}
                className='cursor'
                onClick={handleClick}
                alt='profile'
              />
            </div>
          </Card>

          <Drawer
            anchor='top'
            open={drawerOpen}
            onClose={handleDrawerClose}
            sx={{
              '& .MuiDrawer-paper': { width: 170, top: 60, height: 'auto', position: 'absolute' },
            }}
            style={{ width: theme.WIDTH, margin: 'auto' }}
            id='Stores'>
            <div
              style={{ width: '100%', cursor: 'pointer' }}
              role='presentation'
              onClick={handleDrawerClose}
              onKeyDown={handleDrawerClose}>
              <div className='row drawerOption' onClick={() => setActiveComponent('Products')}>
                <StyleOutlined width={20} height={20} style={{ color: 'grey' }} />
                <MynyfyText title={'Products'} h5 color={'grey'} />
              </div>
              <div
                className='row drawerOption'
                onClick={() => {
                  if (isLoggedIn) setActiveComponent('Orders');
                  else setOpenSignIn(true);
                }}>
                <OrdersIcon width={20} height={20} style={{ color: 'grey' }} />
                <MynyfyText title={'Orders'} h5 color={'grey'} />
              </div>

              <div className='row drawerOption' onClick={() => setActiveComponent('Contests')}>
                <ContestsIcon width={20} style={{ color: 'grey' }} />
                <MynyfyText title={'Contests'} h5 color={'grey'} />
              </div>

              <div className='row drawerOption' onClick={() => setActiveComponent('Coupons')}>
                <CouponsIcon width={20} height={20} style={{ color: 'grey' }} />
                <MynyfyText title={'Coupons'} h5 color={'grey'} />
              </div>

              <div className='row drawerOption' onClick={() => setActiveComponent('Contact Us')}>
                <ContactPhoneOutlined style={{ color: 'grey', width: '20px', height: '20px' }} />
                <MynyfyText title={'Contact Us'} h5 color={'grey'} />
              </div>

              <div className='row drawerOption' onClick={() => setActiveComponent('Policies')}>
                <PoliciesIcon width={20} height={20} style={{ fill: 'grey' }} />
                <MynyfyText title={'Policies'} h5 color={'grey'} />
              </div>
              {/* <div className='row drawerOption' onClick={() => navigate(-1)}>
                <UserIcon width={20} height={20} style={{ fill: 'grey' }} />
                <a href='https://dev-seller.mynyfy.com/' target='_self'>
                  <MynyfyText title={'Influencer Signup'} h5 color={'grey'} /></a>
              </div> */}
              <div
                className='row drawerOption'
                onClick={() => navigate('/Reseller/SignUp', { state: { data: details } })}>
                <UserIcon width={20} height={20} style={{ fill: 'grey' }} />
                <MynyfyText title={'Influencer Signup'} h5 color={'grey'} style={{ flex: 1 }} />
              </div>
            </div>
          </Drawer>
        </div>
        {activeComponent ? (
          <div style={{ minHeight: 'calc(var(--MIN_HEIGHT) - 201px)' }}>{renderComponent()}</div>
        ) : (
          <div style={{ backgroundColor: '#fff', minHeight: 'calc(var(--MIN_HEIGHT) - 201px)' }}>
            {_.isEmpty(details.banner?.[0]?.url) ? null : (
              <Carousel
                infiniteLoop={true}
                autoPlay={true}
                showStatus={false}
                showArrows={false}
                showThumbs={false}
                showIndicators={false}
                interval={3000}>
                {details.banner?.[0]?.url ? (
                  <img
                    src={s3ToCloudFront(details.banner?.[0].url)}
                    className='banner'
                    alt='brand banner'
                  />
                ) : (
                  <div className='banner noBanner'>
                    <MynyfyText title={details.brand} italic h2 className='noBannerText' />
                  </div>
                )}
              </Carousel>
            )}
            <div>
              <div className='rowSB' style={{ padding: '15px' }}>
                <div className='InnerlogoName'>
                  <MynyfyText
                    title={details?.brand}
                    gayahi
                    style={{
                      fontSize: 12,
                      fontWeight: 600,
                      color: 'rgb(0 3 6)',
                    }}
                    center
                  />
                </div>
                {/* <div className='row'>
                  {isLiked ? (
                    <CircularProgress size={16} />
                  ) : profile?.likedBrands?.includes(details._id) ? (
                    <div className='row' onClick={() => likeHandler(details._id, 'unlike')}>
                      <FavoriteRounded fontSize='small' className='followBtn' />
                      <MynyfyText title={details.likesCount === 0 ? 0 : details.likesCount} bold />
                      <MynyfyText title={' Followers'} style={{ paddingLeft: '3px' }} />
                    </div>
                  ) : (
                    <div className='row' onClick={() => likeHandler(details._id, 'like')}>
                      <FavoriteBorderRounded fontSize='small' className='followBtn' />
                      <MynyfyText title={details.likesCount === 0 ? 0 : details.likesCount} bold />
                      <MynyfyText title={' Followers'} style={{ paddingLeft: '3px' }} />
                    </div>
                  )}
                </div> */}
              </div>

              {_.isEmpty(bag?.offers) ? null : (
                <Carousel
                  infiniteLoop={true}
                  autoPlay={true}
                  showStatus={false}
                  showArrows={false}
                  showThumbs={false}
                  showIndicators={false}
                  interval={5000}>
                  {bag?.offers?.map((item, index, array) => (
                    <Card
                      onClick={() => setActiveComponent('Coupons')}
                      key={item?._id}
                      className='offersDisplayCard cursor'>
                      <div className='rowSB'>
                        <div className='row' style={{ gap: 10, textAlign: 'left' }}>
                          <div style={{ width: 60, height: 60 }}>
                            <img
                              src={require('../../assets/imgs/offersImage.png')}
                              alt='offerImg'
                            />
                          </div>
                          <div>
                            <MynyfyText title={theme.RUPEE + item?.actualPrice + ' OFF'} bold h4 />
                            {item.minimumOrderValue ? (
                              <MynyfyText
                                title={'On a min order of ' + theme.RUPEE + item.minimumOrderValue}
                                style={{ paddingTop: 4 }}
                              />
                            ) : null}
                          </div>
                        </div>
                        <MynyfyText title={`${index + 1}/${array.length}`} h6 />
                      </div>
                    </Card>
                  ))}
                </Carousel>
              )}
            </div>
            <div className='custom-product-section-container'>
              {groups?.length > 0 && renderGroupedSections()}
            </div>
            <div className='custom-product-section-container-no-image'>
              {sections?.length > 0 &&
                sections?.map((section, index) => {
                  const isSectionPresent =
                    section?.isActive &&
                    actualProductsData?.some((product) =>
                      product?.sections?.map(String).includes(String(section?._id))
                    );
                  return isSectionPresent ? renderSections(section, index) : null;
                })}
            </div>
            {_.isEmpty(originalProductBrands) ? null : (
              <div className='shopByBrands' style={{ marginTop: '3%', marginBottom: '9%' }}>
                <MynyfyText title={'Shop by Brands'} h5 bold style={{ marginBottom: 20 }} />
                <div className='row brandRow'>
                  {originalProductBrands?.slice(0, 4).map((brand, i) => (
                    <div
                      key={i}
                      className='brand'
                      onClick={() => {
                        setSelectedBrandForProducts(brand);
                        setActiveComponent('BrandProducts');
                      }}>
                      {brand?.image?.url ? (
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                          }}>
                          <img
                            className='logo'
                            src={s3ToCloudFront(brand?.image?.url)}
                            alt='brand logo'
                            style={{
                              objectFit: 'contain',
                              border: '1px solid #e8e8e8',
                              width: '60px',
                              height: '60px',
                              padding: 5,
                            }}
                          />
                          <MynyfyText title={brand?.name} semiBold />
                        </div>
                      ) : (
                        <div
                          style={{
                            width: '60px',
                            height: '60px',
                            padding: 5,
                            backgroundColor: '#e8e8e8',
                            border: '1px solid #e8e8e8',
                            borderRadius: 5,
                            marginBottom: 5,
                          }}
                          className='rowC'>
                          <MynyfyText title={brand?.name} semiBold />
                        </div>
                      )}
                    </div>
                  ))}
                  {originalProductBrands?.length >= 5 && (
                    <div>
                      <KeyboardDoubleArrowRight onClick={() => setOpenViewAllBrands(true)} />
                    </div>
                    // <div className='brand' onClick={() => setOpenViewAllBrands(true)}>
                    //   <div
                    //     className='logo'
                    //     alt='brand logo'
                    //     width={40}
                    //     height={40}
                    //     style={{
                    //       backgroundColor: '#e8e8e8',
                    //       display: 'flex',
                    //       justifyContent: 'center',
                    //       alignItems: 'center',
                    //     }}>
                    //     <MynyfyText className={'name'} title={'View All'} pSmall />
                    //   </div>
                    //   <MynyfyText
                    //     className={'name'}
                    //     title={'View All'}
                    //     color={'transparent'}
                    //     pSmall
                    //   />
                    // </div>
                  )}
                </div>
              </div>
            )}

            {_.isEmpty(brandContests) ? null : (
              <Carousel
                infiniteLoop={true}
                autoPlay={true}
                showStatus={false}
                showArrows={false}
                showThumbs={false}
                showIndicators={false}
                interval={5000}>
                <Card className='offersDisplayCard cursor'>
                  <div className='rowSB' style={{ paddingTop: '15px' }}>
                    <div style={{ paddingBottom: 10 }}>
                      <MynyfySpan title={'Contests '} pBig bold />
                    </div>
                    <div style={{ paddingBottom: 10 }}>
                      <MynyfySpan
                        title={'View All '}
                        onClick={() => setActiveComponent('Contests')}
                        pBig
                        link
                      />
                    </div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: '300px',
                        height: '200px',
                        marginTop: '10px',
                      }}>
                      {brandContests?.length > 0 ? (
                        <Carousel
                          infiniteLoop={true}
                          autoPlay={true}
                          showStatus={false}
                          showArrows={false}
                          showThumbs={false}
                          showIndicators={false}
                          interval={5000}>
                          {brandContests.map((item, i) => (
                            <div
                              key={i}
                              onClick={() => setActiveComponent('Contests')}
                              style={{ backgroundColor: 'white' }}>
                              <img
                                src={s3ToCloudFront(item.image)}
                                alt='Banner Image'
                                style={{
                                  width: '300px',
                                  height: '150px',
                                  //objectFit: 'cover',
                                  borderRadius: '16px',
                                }}
                              />
                              <div className='rowSB' style={{ paddingTop: '10px' }}>
                                <div />
                                <div style={{ justifyContent: 'left' }}>
                                  <MynyfyText title={item?.name} />
                                  {item?.createdKey === 'AdminProfile' ? (
                                    <MynyfyText title={'Contest by MYNYFY Flatform'} />
                                  ) : (
                                    <MynyfyText title={`Contest by ${item?.brand?.brand} `} />
                                  )}
                                </div>
                                <div style={{ paddingRight: '6px' }}>
                                  <ShareRounded
                                    onClick={(e) => {
                                      shareContestUrl(item);
                                      e.stopPropagation();
                                    }}
                                    className='cursor'
                                  />
                                </div>
                              </div>
                            </div>
                          ))}
                        </Carousel>
                      ) : null}
                    </div>
                  </div>
                </Card>
              </Carousel>
            )}

            <BrandProducts
              from={'brand'}
              brandDetails={details}
              isEcommerceActive={true}
              type={searchParams.get('type')}
              table={urlParams?.table}
              setActualProductsData={setActualProductsData}
              productBrandNames={originalProductBrands}
              selectedBrandForProducts={selectedBrandForProducts}
            />
          </div>
        )}

        <MynyfyModal open={openViewAllBrands} close={() => setOpenViewAllBrands(false)}>
          <MynyfyText title={'Shop by Brands'} h5 bold style={{ marginBottom: 10 }} />
          <div style={{ maxHeight: '75vh', overflowY: 'auto' }}>
            {originalProductBrands?.map((brand) => (
              <div
                className='row'
                style={{ padding: '10px 0' }}
                onClick={() => {
                  setSelectedBrandForProducts(brand);
                  setActiveComponent('BrandProducts');
                  setOpenViewAllBrands(false);
                }}>
                {brand?.image?.url ? (
                  <img
                    src={s3ToCloudFront(brand?.image?.url)}
                    width={50}
                    height={50}
                    alt='brand logo'
                    style={{ borderRadius: 5, objectFit: 'contain' }}
                  />
                ) : (
                  <div style={{ width: 50, height: 50 }} className='rowC'>
                    <MynyfyText title={brand?.name} bold gayahi center />
                  </div>
                )}
                <MynyfyText title={brand?.name} style={{ marginLeft: 10 }} bold />
              </div>
            ))}
          </div>
        </MynyfyModal>

        <MynyfyModal
          open={showResellerLoginModal}
          close={() => {
            sessionStorage.removeItem('reseller_redirection');
            setShowResellerLoginModal(false);
          }}
          position={'center'}
          style={{
            borderRadius: 10,
          }}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <MynyfyText title={'Congratulations!'} h4 semiBold style={{ marginBottom: 10 }} />
            <div style={{ marginBottom: 10 }}>
              <MynyfySpan
                title={`Your dropshipping | influencer account with ${details?.brand} created successfully, login into `}
              />
              <MynyfySpan
                title={process.env.REACT_APP_SELLER_WEB_HOST}
                link
                onClick={() => openUrl(process.env.REACT_APP_SELLER_WEB_HOST)}
              />
              <MynyfySpan title={' to start reselling'} />
            </div>
            <a
              href={process.env.REACT_APP_SELLER_WEB_HOST + '/SignIn'}
              target='_blank'
              rel='noopener noreferrer'
              onClick={() => {
                sessionStorage.removeItem('reseller_redirection');
                setShowResellerLoginModal(false);
              }}>
              <MynyfyButton label={'Login'} />
            </a>
          </div>
        </MynyfyModal>

        <SignInModal open={openSignIn} close={() => setOpenSignIn(false)} />

        <div className='Brandfooter'>
          <div className='footer-content'>
            <div className='MenuItems-footer'>
              <MynyfyText
                title={'About Us'}
                p
                color={'black'}
                onClick={() => setActiveComponent('About Us')}
                className='menu-item cursor'
              />
              <MynyfyText
                title={'Products'}
                p
                color={'black'}
                onClick={() => setActiveComponent('Products')}
                className='menu-item cursor'
              />
              <MynyfyText
                title={'Orders'}
                p
                color={'black'}
                onClick={() => {
                  if (isLoggedIn) setActiveComponent('Orders');
                  else setOpenSignIn(true);
                }}
                className='menu-item cursor'
              />
              <MynyfyText
                title={'Contests'}
                p
                color={'black'}
                onClick={() => setActiveComponent('Contests')}
                className='menu-item cursor'
              />
              <MynyfyText
                title={'Coupons'}
                p
                color={'black'}
                onClick={() => setActiveComponent('Coupons')}
                className='menu-item cursor'
              />
              <MynyfyText
                title={'Contact Us'}
                p
                color={'black'}
                onClick={() => setActiveComponent('Contact Us')}
                className='menu-item cursor'
              />
              <MynyfyText
                title={'Policies'}
                p
                color={'black'}
                onClick={() => setActiveComponent('Policies')}
                className='menu-item cursor'
              />
              <MynyfyText
                title={'Influencer Signup'}
                p
                color={'black'}
                className='menu-item cursor'
                onClick={() => navigate('/Reseller/SignUp', { state: { data: details } })}
              />
            </div>
          </div>

          <div style={{ padding: '30px 5px' }}>
            <MynyfyText
              title={`© 2024 ${details?.brand} All rights reserved`}
              style={{ color: 'black' }}
            />
            <div className='rowC' style={{ paddingTop: 5 }}>
              <MynyfyText title={'Powered by'} style={{ color: 'black' }} />
              <img
                src={mynyfyLogo}
                alt='Mynyfylogo'
                style={{
                  height: 15,
                  width: 'auto',
                  marginRight: 2,
                  marginLeft: 5,
                  objectFit: 'contain',
                }}
              />
              <MynyfySpan title={'Platform'} style={{ paddingTop: 3, color: 'black' }} />
            </div>
          </div>

          <MynyfyText title={'Follow Us'} center style={{ paddingBottom: 10 }} />
          <div className='rowC' style={{ gap: '10px' }}>
            {details?.socialLinks?.facebook ? (
              <a
                href={getCompleteUrl(details?.socialLinks?.facebook)}
                target='_blank'
                rel='noopener noreferrer'>
                <Facebook fontSize='small' style={{ color: '#0765ff', fontSize: 30 }} />
              </a>
            ) : null}
            {details?.socialLinks?.instagram ? (
              <a
                href={getCompleteUrl(details?.socialLinks?.instagram)}
                target='_blank'
                rel='noopener noreferrer'>
                <Instagram fontSize='small' style={{ color: '#d62976', fontSize: 30 }} />
              </a>
            ) : null}
            {details?.socialLinks?.youtube ? (
              <a
                href={getCompleteUrl(details?.socialLinks?.youtube)}
                target='_blank'
                rel='noopener noreferrer'>
                <YouTube fontSize='small' style={{ color: '#FF0032', fontSize: 39 }} />
              </a>
            ) : null}
            <img
              src={require('../../assets/imgs/icons/logo.png')}
              style={{ width: 25, height: 25, marginTop: -2 }}
              onClick={() => likeHandler(details?._id, 'like')}
              alt='Mynyfy Logo'
            />
          </div>
        </div>
        <div
          className='whatsAppIcon'
          style={{ display: details?.socialLinks?.whatsapp ? 'block' : 'none' }}>
          <a
            href={`https://wa.me/${details?.socialLinks?.whatsapp}`}
            target='_blank'
            rel='noopener noreferrer'
            style={{ textDecoration: 'none', color: 'inherit' }}>
            <WhatsApp style={{ color: '#25D366', fontSize: 40 }} />
          </a>
        </div>
      </div>
    )
  ) : (
    <MynyfyLoader />
  );
};

export default BrandDetails;
