import React, { Fragment, useState } from 'react';
import MynyfyText from '../../components/MynyfyText';
import theme from '../../utils/theme';
import { websiteClick } from './AdsHelper';
import { useDispatch } from 'react-redux';
import { snackbarInfo } from '../../redux/actions/snackbar.action';
import {
  LanguageRounded,
  ShareRounded,
  StarOutlineRounded,
  StarRounded,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import MynyfyAlert from '../../components/MynyfyAlert';
import { s3ToCloudFront, getOS } from '../../utils/CommonMethods';
import MynyfyButton from '../../components/MynyfyButton';
import VisibilityTwoToneIcon from '@mui/icons-material/VisibilityTwoTone';

const AdItemFooter = ({ item, onShare, handleInterest }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const platform = getOS();

  const [isExpanded, setIsExpanded] = useState(false);

  const [isOpen, setOpen] = useState(false);
  const shareInterest = () => {
    if (item.isUserInterested) {
      dispatch(
        snackbarInfo({
          type: 'warning',
          open: true,
          message: ['Cheers!', 'Your interest already has been shared with the Ad publisher'],
        })
      );
    } else {
      setOpen(true);
    }
  };

  return (
    <Fragment>
      <div className='footerContainer'>
        <div className='discriptionContainer'>
          <MynyfyText title={item.name} h5 style={{ color: 'white', marginBottom: 2 }} />
          <div
            className={`descriptionHolder ${isExpanded ? 'expanded' : 'truncated'}`}
            os={platform == 'android' || platform == 'ios' ? 'mobile' : 'pc'}>
            <MynyfyText
              title={item.terms}
              style={{ flexWrap: 'wrap', flexShrink: 1, color: '#ececec' }}
              pSmall
              onClick={() => setIsExpanded(!isExpanded)}
            />
          </div>
          {item?.url ? (
            <MynyfyButton
              label={'Visit Us'}
              onClick={() => websiteClick(item._id, item.url)}
              containerStyle={{ width: '50%', margin: '5px 0px' }}
              fullWidth
              style={{ height: 30, borderRadius: 5, backgroundColor: '#1a567b' }}
            />
          ) : (
            <div className='btnHolder' />
          )}
          <div className='divider' style={{ margin: '5px 0' }} />
          <div className='rowSB' style={{paddingTop:5,paddingBottom:5}}>
            {item.isInterested ? (
              <div className='rowC' onClick={shareInterest} style={{ padding: 5 }}>
                {item.isUserInterested ? (
                  <StarRounded
                    className='feedFooterIcon cursor feedFooterStarIcon'
                    style={{ color: theme.WARNING }}
                  />
                ) : (
                  <StarOutlineRounded className='feedFooterIcon cursor feedFooterStarIcon' />
                )}
                <MynyfyText
                  title={item.isUserInterested ? 'Interest Shared' : 'Share Interest'}
                  className='cursor'
                  PSmall
                  center
                  style={{ color: '#ececec' }}
                />
              </div>
            ) : (
              <div className='btnHolder' />
            )}
            {item?.brand ? (
              <div className='btnHolder' onClick={() => navigate('/Brands/' + item.brand?.urlId)}>
                <img
                  src={s3ToCloudFront(item.brand.logo ? item.brand.logo.url : item.image)}
                  className='brandLogo cursor'
                />
                <MynyfyText
                  title={'Go to Page'}
                  className='cursor'
                  small
                  style={{ color: 'white' }}
                />
              </div>
            ) : (
              <div className='btnHolder' />
            )}
          </div>
        </div>
        <div className='iconsContainer'>
          <VisibilityTwoToneIcon style={{ color: 'white' }} />
          <MynyfyText
            title={item.totalViews || 0}
            bold
            style={{ color: 'white', paddingBottom: 10 }}
          />
          <ShareRounded className='feedFooterShareIcon cursor' onClick={onShare} />
        </div>
      </div>
      <MynyfyAlert
        open={isOpen}
        close={() => setOpen(false)}
        title={'Are you sure?'}
        content={
          'Your details including mobile number, email id & location will be shared with the Ad publisher.'
        }
        action1={{
          label: 'No',
          handler: () => setOpen(false),
          color: 'black',
        }}
        action2={{
          label: 'Yes',
          handler: () => {
            handleInterest(item._id);
            setOpen(false);
          },
        }}
      />
    </Fragment>
  );
};

export default AdItemFooter;
