import React, { Fragment, useEffect, useState, useRef } from 'react';
import MynyfyHeader from '../../components/MynyfyHeader';
import { useLocation, useNavigate, useParams } from 'react-router';
import MynyfyText from '../../components/MynyfyText';
import MynyfyButton from '../../components/MynyfyButton';
import { Carousel } from 'react-responsive-carousel';
import { Button, CardContent } from '@mui/material';
import IosShareOutlinedIcon from '@mui/icons-material/IosShareOutlined';
import MynyfyLoader from '../../components/MynfyfLoader';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { snackbarInfo } from '../../redux/actions/snackbar.action';
import ProductCartHelper from './ProductCartHelper';
import MynyfyPre from '../../components/MynyfyPre';
import {
  CloseRounded,
  ControlPointRounded,
  DiscountRounded,
  RemoveCircleOutlineRounded,
} from '@mui/icons-material';
import { ReactComponent as VegIcon } from '../../assets/svg/veg.svg';
import { ReactComponent as NonVegIcon } from '../../assets/svg/nonVeg.svg';
import { ProductMaterialType, PromoType } from '../../utils/enums';
import { addToBag, decrementHandler, incrementHandler, qtyHandler } from './AddProductHelper';
import {
  formatToMoney,
  getOS,
  openUrl,
  s3ToCloudFront,
  storeTimingHandler,
  videoUrl,
} from '../../utils/CommonMethods';
import productService from '../../services/productService';
import { productsMergeHandler } from './ProductHelper';
import {
  fetchBagAbout,
  fetchBagCalculation,
  fetchBagOffers,
  fetchBagProducts,
  fetchBagSellingId,
} from '../../redux/actions/bag.action';
import storeService from '../../services/storeService';
import promoService from '../../services/promoService';
import moment from 'moment';
import ShowImages from '../../components/ShowImages';
import LocationModal from '../Location/LocationModal';
import MynyfyModal from '../../components/MynyfyModal';
import MynyfyViewVideo from '../../components/MynyfyViewVideo';
import MynyfyWebViewVideo from '../../components/MynyfyWebViewVideo';

const NO_OF_OFFERS = 3;

let platorm = getOS();
let isMobile = platorm == 'android' || platorm == 'ios' ? true : false;

const ProductsDetailsModal = ({
  id,
  details,
  navFrom,
  brandDetails,
  sellerDetails,
  isEcommerceActive,
  table,
  openModal,
  closeModal,
  platform,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //   const route = useLocation();
  //   const { id } = useParams();

  const inputRef = useRef(null);
  const containerRef = useRef(null);

  const bag = useSelector((state) => state.bag);
  const location = useSelector((state) => state.location?.location);
  const isLoggedIn = useSelector((state) => state.user?.isLoggedIn);
  const profile = useSelector((state) => state.user?.profile);

  const [productDetails, setProductDetails] = useState(null);
  const [isMoreOffers, setMoreOffers] = useState(false);
  const [offers, setOffers] = useState(false);
  const [isAddBtnClicked, setAddBtnClicked] = useState(false);
  const [routeDetails, setRouteDetails] = useState(null);
  const [policies, setPolicies] = useState(null);
  const [openImageList, setOpenImageList] = useState(false);
  const [selectedSize, setSelectedSize] = useState(null);
  const [selectedColor, setSelectedColor] = useState(null);
  const [isSizes, setSizes] = useState(false);
  const [isColors, setColors] = useState(false);
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [isLocationModal, setIsLocationModal] = useState(false);
  const [isViewVideo, setIsViewVideo] = useState({ open: false, url: null });

  useEffect(() => {
    if (details) {
      setProductDetails(details);
      setRouteDetails({ details, navFrom, brandDetails, sellerDetails, isEcommerceActive, table });
      setPolicies(sellerDetails?.policies);
      afterResponse(details);
    } else if (id) getProductDetails();
  }, [id, details, openModal]);

  useEffect(() => {
    let reqProduct = details || productDetails;
    const selectedVariant = reqProduct?.variants.find(
      (f) => f.size == selectedSize && f.color == selectedColor
    );
    setSelectedVariant(selectedVariant);
  }, [selectedSize, selectedColor]);

  useEffect(() => {
    if (bag?.calculation?.qty && isAddBtnClicked) {
      containerRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
      setAddBtnClicked(false);
    }
  }, [bag?.calculation?.qty]);

  const getProductDetails = () => {
    productService.getProductById(id).then(async (res) => {
      if (res.success) {
        let reqData = productsMergeHandler({ products: [res.data], needData: true });
        let reqRouteDetails = {};
        if (res.data?.seller?._id) {
          if (bag?.id !== res.data?.seller?._id) {
            await storeService.getStores({ orgId: res.data?.seller?._id }).then((orgRes) => {
              if (orgRes.success) {
                let orgDetails = orgRes.data.orgList[0];
                let storeStatus = storeTimingHandler(orgDetails);
                dispatch(fetchBagSellingId(orgDetails._id));
                dispatch(
                  fetchBagAbout({
                    type: 'Store',
                    name: orgDetails.name,
                    city: orgDetails.city,
                    state: orgDetails.state,
                    policies: orgDetails.policies,
                    urlId: orgDetails.urlId,
                    url: process.env.REACT_APP_WEB_HOST + '/Stores/' + orgDetails.urlId,
                    orgDetails,
                    isStoreOpened: storeStatus,
                  })
                );
                let isActive = ecommerceStatus(orgDetails?.ecommerceSubscription);
                reqRouteDetails = {
                  navFrom: 'seller',
                  sellerDetails: orgDetails,
                  isEcommerceActive: isActive,
                  storeStatus,
                };
                setRouteDetails(reqRouteDetails);
                if (isLoggedIn) getBuyerPromos(orgDetails);
                dispatch(fetchBagCalculation(null));
                dispatch(fetchBagProducts(null));
              } else {
                dispatch(
                  snackbarInfo({ type: 'error', open: true, message: orgRes?.data?.message })
                );
              }
            });
          } else {
            let isActive = ecommerceStatus(bag?.about?.orgDetails?.ecommerceSubscription);
            let storeStatus = storeTimingHandler(bag?.about?.orgDetails);
            if (bag?.about !== storeStatus) {
              dispatch(fetchBagAbout({ ...bag?.about, isStoreOpened: storeStatus }));
            }
            reqRouteDetails = {
              navFrom: 'seller',
              sellerDetails: bag?.about?.orgDetails,
              isEcommerceActive: isActive,
              storeStatus,
            };
            setRouteDetails(reqRouteDetails);
          }
        }
        if (res.data?.isDelete) {
          backNavigationHandler(reqRouteDetails);
          dispatch(
            snackbarInfo({
              open: true,
              type: 'error',
              message: ['Opened product is not active', 'Checkout our other products'],
            })
          );
        } else {
          setProductDetails(reqData[0]);
          afterResponse(reqData[0]);
        }
      }
    });
  };

  const ecommerceStatus = (subscription) => {
    if (subscription) {
      let subDays = moment(subscription).diff(moment(), 'days');
      if (subDays >= 0) return true;
      else return false;
    } else return false;
  };

  const getBuyerPromos = (org) => {
    if (bag?.id !== org._id && _.isEmpty(bag.offers)) {
      let body = {
        type: [PromoType.COUPON, PromoType.REWARD],
        noOrg: true,
      };
      if (isLoggedIn) body.buyer = profile._id;
      if (_.isEmpty(org.brands)) body.organization = org._id;
      else body.brand = org.brands;
      promoService.getPromosForOrder(body).then((res) => {
        if (res.success) dispatch(fetchBagOffers(res.data));
      });
    }
  };

  const afterResponse = (details) => {
    if (details) {
      setSelectedColor(details.variants?.[0]?.color);
      setSelectedSize(details.variants?.[0]?.size);

      setColors(_.isEmpty(details.variants?.map((e) => e.color)?.filter((e) => e)) ? false : true);
      setSizes(_.isEmpty(details.variants?.map((e) => e.size)?.filter((e) => e)) ? false : true);

      if (details?.category) {
        if (!_.isEmpty(bag?.offers)) {
          let reqOffers = bag?.offers?.filter(
            (e) => e?.category?._id == details?.category?._id || !e?.category
          );
          setOffers(reqOffers);
        }
      } else setOffers(bag?.offers);
    }
  };

  const shareUrl = async () => {
    if (bag?.about && productDetails) {
      try {
        if (navigator.share) {
          await navigator.share({
            title: productDetails?.name,
            text:
              `Check out this amazing product: ${productDetails?.name}! Click here to view ${process.env.REACT_APP_WEB_HOST}/Product/${productDetails?._id}` +
              '\n' +
              '\n' +
              `Check out the other products in the ${bag?.about?.name} ${bag?.about?.type}` +
              '\n' +
              '\n' +
              `${bag?.about?.type} link: ${bag?.about?.url}`,
          });
        }
      } catch (error) {
        alert(error.message);
      }
    }
  };

  const getUniqueAttributes = ({ key }) => {
    return [...new Set(productDetails.variants.map((item) => item[key]))].filter((e) => e);
  };

  const getRelatedValues = ({ key, selectedKey, selectedValue }) => {
    return productDetails.variants
      .filter((item) => item[selectedKey] === selectedValue)
      .map((item) => item[key]);
  };

  const isDisabled = (key, value) => {
    let obj = {};
    if (key === 'size') {
      obj = { key: 'size', selectedKey: 'color', selectedValue: selectedColor };
    } else if (key === 'color') {
      obj = { key: 'color', selectedKey: 'size', selectedValue: selectedSize };
    }
    return getRelatedValues(obj)?.includes(value) ? 'true' : 'false';
  };

  const backNavigationHandler = (data) => {
    let reqData = data?.sellerDetails || routeDetails?.sellerDetails;
    if (details) {
      navigate(-1);
    } else {
      let visitStoreQuery = { orgId: reqData?._id };
      if (profile?._id) visitStoreQuery.buyerId = profile._id;
      storeService.viewStore(visitStoreQuery);

      navigate('/Stores/' + reqData?.urlId, {
        state: { isFav: false, storeDetails: reqData },
        replace: true,
      });
    }
  };

  return (
    <MynyfyModal open={openModal} close={() => closeModal()}>
      <div style={{ maxHeight: '80vh', overflowY: 'auto' }}>
        <Fragment>
          {productDetails && selectedVariant ? (
            <Fragment>
              <div
                id='Products'
                style={{ paddingBottom: bag?.calculation?.qty ? 75 : 0 }}
                ref={containerRef}>
                <div
                  style={{
                    position: 'sticky',
                    top: 0,
                    right: 0,
                    zIndex: 100,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'end',
                  }}>
                  <CloseRounded onClick={() => closeModal()} className='cursor' />
                </div>
                <div style={{ marginTop: 10 }}>
                  <div style={{ display: 'flex' }}>
                    <div sx={{ maxWidth: 200 }}>
                      <div style={{ width: '100%', margin: 'auto' }}>
                        <Carousel
                          infiniteLoop={true}
                          autoPlay={true}
                          showStatus={false}
                          showArrows={false}
                          showThumbs={false}
                          interval={5000}>
                          {selectedVariant?.image?.map((res, i) => (
                            <div key={i} onClick={() => setOpenImageList(true)} className='cursor'>
                              <img
                                src={s3ToCloudFront(res.url)}
                                alt={productDetails.name}
                                style={{ objectFit: 'contain', height: 370 }}
                              />
                            </div>
                          ))}
                        </Carousel>
                      </div>
                      <CardContent>
                        <div className='rowSB'>
                          <MynyfyText
                            title={productDetails.name}
                            h4
                            color={'#000000'}
                            bold
                            style={{ marginBottom: 5, flex: 1 }}
                          />
                          {selectedVariant?.videoUrl ? (
                            <div
                              onClick={() => {
                                let reqUrl = videoUrl(selectedVariant?.videoUrl);
                                setIsViewVideo({ open: true, url: reqUrl });
                              }}>
                              <MynyfyText title={'View Video'} bold link />
                            </div>
                          ) : null}
                        </div>
                        <MynyfyText
                          title={productDetails?.description}
                          style={{ marginTop: 5 }}
                          color={'#5f5f5f'}
                          h6
                        />
                        <div className='rowSB'>
                          <div className='row' style={{ marginTop: 10 }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <MynyfyText title='Rs. ' bold={true} />
                              <MynyfyText
                                title={formatToMoney(selectedVariant?.actualPrice)}
                                style={{
                                  textDecoration:
                                    selectedVariant.price &&
                                    selectedVariant.actualPrice !== selectedVariant.price
                                      ? 'line-through'
                                      : 'unset',
                                }}
                                bold={
                                  !(
                                    selectedVariant.price &&
                                    selectedVariant.actualPrice !== selectedVariant.price
                                  )
                                }
                                color={
                                  selectedVariant.actualPrice !== selectedVariant.price
                                    ? 'grey'
                                    : null
                                }
                              />
                            </div>
                            {selectedVariant.actualPrice === selectedVariant.price ||
                            !selectedVariant.price ? null : (
                              <div style={{ marginLeft: 5 }}>
                                <MynyfyText
                                  title={'Rs. ' + formatToMoney(selectedVariant?.price)}
                                  bold
                                />
                              </div>
                            )}
                          </div>
                          {productDetails.materialType ? (
                            productDetails.materialType == ProductMaterialType.NON_VEGETARIAN ? (
                              <NonVegIcon width={20} height={20} />
                            ) : (
                              <VegIcon width={20} height={20} />
                            )
                          ) : null}
                        </div>
                      </CardContent>
                    </div>
                  </div>
                </div>
                {productDetails.variants?.length > 1 ? (
                  <div>
                    <div style={{ background: '#F1F1F1', height: '5px' }}></div>
                    <div style={{ paddingBlock: '15px' }}>
                      <div style={{ margin: '0px 16px', marginTop: '5px' }}>
                        <MynyfyText title='Variants' h4 link />
                        {productDetails?.variants.length > 1 ? (
                          <div>
                            {isSizes ? (
                              <Fragment>
                                <MynyfyText
                                  pSmall
                                  color={'#5f5f5f'}
                                  title={'Sizes / Weights'}
                                  style={{ margin: '10px 0px -10px' }}
                                />
                                <div
                                  className='row'
                                  style={{ padding: '10px 5px', overflowX: 'auto', gap: 10 }}>
                                  {getUniqueAttributes({ key: 'size' }).map((size) => (
                                    <MynyfyText
                                      key={size}
                                      title={size}
                                      className='chipBtn'
                                      isselected={selectedSize == size ? 'true' : 'false'}
                                      isactive={isDisabled('size', size)}
                                      onClick={() => {
                                        setSelectedSize(size);
                                        let obj = {
                                          key: 'color',
                                          selectedKey: 'size',
                                          selectedValue: size,
                                        };
                                        const reqColors = getRelatedValues(obj);
                                        if (!reqColors.includes(selectedColor)) {
                                          setSelectedColor(reqColors?.[0] || null);
                                        }
                                      }}
                                    />
                                  ))}
                                </div>
                              </Fragment>
                            ) : null}

                            {isColors ? (
                              <Fragment>
                                <MynyfyText
                                  pSmall
                                  color={'#5f5f5f'}
                                  title={'Colors'}
                                  style={{ margin: '10px 0px -10px' }}
                                />
                                <div
                                  className='row'
                                  style={{ padding: '10px 5px', overflowX: 'auto', gap: 10 }}>
                                  {getUniqueAttributes({ key: 'color' }).map((color) => (
                                    <div
                                      key={color}
                                      className='chipBtn colorChip'
                                      style={{ backgroundColor: color }}
                                      isselected={selectedColor == color ? 'true' : 'false'}
                                      isactive={isDisabled('color', color)}
                                      onClick={() => {
                                        setSelectedColor(color);
                                        let obj = {
                                          key: 'size',
                                          selectedKey: 'color',
                                          selectedValue: color,
                                        };
                                        const reqSizes = getRelatedValues(obj);
                                        if (!reqSizes.includes(selectedSize))
                                          setSelectedSize(reqSizes?.[0] || null);
                                      }}></div>
                                  ))}
                                </div>
                              </Fragment>
                            ) : null}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                ) : null}
                <div>
                  {policies?.shipping ? (
                    <div>
                      <div style={{ background: '#F1F1F1', height: '5px' }}></div>
                      <div style={{ paddingTop: '15px' }}>
                        <div className='rowSB' style={{ margin: '0px 16px', marginTop: '5px' }}>
                          <MynyfyText title='Delivery Details' pBig bold />
                          <MynyfyText
                            title='Check Details'
                            link
                            onClick={() => {
                              navigate(`/Policies/Delivery_Policy`, {
                                state: {
                                  policy: [
                                    {
                                      heading: null,
                                      content: ['Delivery timeline will be shown in order screen'],
                                    },
                                  ],
                                  policy2: policies?.shipping,
                                  policyName: `Delivery Policy`,
                                },
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <br />
                  {/* {_.isEmpty(offers) ? null : (
                    <div>
                      <div style={{ background: '#F1F1F1', height: '5px' }}></div>
                      <div style={{ paddingTop: '15px' }}>
                        <div style={{ margin: '0px 16px', marginTop: '5px' }}>
                          <div className='rowSB'>
                            <MynyfyText title=' Offers' pBig bold />
                            {isMoreOffers ? (
                              <MynyfyText
                                title={`less offers`}
                                link
                                onClick={() => setMoreOffers(!isMoreOffers)}
                              />
                            ) : offers?.length - NO_OF_OFFERS > 0 ? (
                              <MynyfyText
                                title={`+${offers?.length - NO_OF_OFFERS} More offers`}
                                link
                                onClick={() => setMoreOffers(!isMoreOffers)}
                              />
                            ) : null}
                          </div>
                          {offers
                            ?.slice(0, isMoreOffers ? offers?.length : NO_OF_OFFERS)
                            ?.map((res, i) => (
                              <div style={{ display: 'flex', marginTop: 5 }} key={i}>
                                <DiscountRounded style={{ padding: 5 }} />
                                <div style={{ padding: '5px 8px 8px' }}>
                                  <MynyfyText
                                    style={{ paddingTop: 0 }}
                                    title={
                                      res.name + (res.description ? ' - ' + res.description : '')
                                    }
                                    className='poductOffersDescription'
                                  />
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                      <br />
                    </div>
                  )} */}
                  {selectedVariant?.specification ? (
                    <div>
                      <div style={{ background: '#F1F1F1', height: '5px' }}></div>
                      <div style={{ paddingTop: '15px' }}>
                        <div style={{ margin: '0px 16px', marginTop: '5px' }}>
                          <div className='rowSB'>
                            <MynyfyText title='Product Details' pBig bold />
                            {/* <MynyfyText title='+ More ' link /> */}
                          </div>
                          <div style={{ paddingTop: 10 }}>
                            <MynyfyPre title={selectedVariant?.specification} color={'#000'} />
                          </div>
                        </div>
                      </div>
                      <br />
                    </div>
                  ) : null}
                  {policies?.isReturn || policies?.isExchange ? (
                    <div>
                      <div style={{ background: '#F1F1F1', height: '5px' }}></div>
                      <div style={{ paddingTop: '15px', paddingBottom: '15px' }}>
                        <div className='rowSB' style={{ margin: '0px 16px', marginTop: '5px' }}>
                          <MynyfyText
                            title='Return/Refund/Exchange Details'
                            style={{ marginRight: 10 }}
                            pBig
                            bold
                          />
                          <MynyfyText
                            title='Check Details'
                            link
                            style={{ whiteSpace: 'nowrap' }}
                            onClick={() => {
                              navigate(`/Policies/Return_Refund_Exchange_Policy`, {
                                state: {
                                  policy: policies?.refund,
                                  policy2: policies?.exchange,
                                  policyName: `Return / Refund / Exchange Policy`,
                                },
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <div style={{ background: '#F1F1F1', height: '5px' }}></div>
                </div>
                <div className='row' style={{ padding: '10px 20px 15px' }}>
                  <Button
                    style={{ background: '#C5C5C5', padding: '15px', borderRadius: '10px' }}
                    onClick={() => shareUrl()}>
                    <IosShareOutlinedIcon style={{ color: 'black', fontSize: '20px' }} />
                  </Button>
                  {/* <div style={{ margin: '0px 10px 0px 15px' }}>
                    <Button
                      style={{ background: '#C5C5C5', padding: '15px', borderRadius: '10px' }}>
                      <FavoriteBorderOutlinedIcon style={{ color: 'black', fontSize: '20px' }} />
                    </Button>
                  </div> */}
                  {bag?.products?.find(
                    (e) => e.productId == productDetails._id && e.variantId == selectedVariant._id
                  ) ? (
                    <div className='rowC' style={{ margin: '0px 10px', flex: 1 }}>
                      <RemoveCircleOutlineRounded
                        color='primary'
                        fontSize='medium'
                        className='cursor'
                        onClick={() => {
                          decrementHandler({
                            productDetails,
                            variant: selectedVariant,
                            bag,
                            dispatch,
                          });
                          if (!isMobile) inputRef.current.focus();
                        }}
                      />
                      <input
                        value={
                          bag?.products?.find(
                            (e) =>
                              e.productId == productDetails._id &&
                              e.variantId == selectedVariant._id
                          )?.count || 0
                        }
                        onChange={(e) => {
                          qtyHandler({
                            productDetails,
                            variant: selectedVariant,
                            value: e.target.value,
                            bag,
                            dispatch,
                          });
                        }}
                        autoFocus={isMobile ? false : true}
                        ref={(el) => (inputRef.current = el)}
                        className='qtyInput detailsInput'
                      />
                      <ControlPointRounded
                        color='primary'
                        fontSize='medium'
                        className='cursor'
                        onClick={() => {
                          incrementHandler({
                            productDetails,
                            variant: selectedVariant,
                            bag,
                            dispatch,
                          });
                          if (!isMobile) inputRef.current.focus();
                        }}
                      />
                    </div>
                  ) : (
                    <div style={{ flex: 1, marginLeft: 10 }}>
                      <MynyfyButton
                        label={
                          (selectedVariant.count
                            ? selectedVariant.count - selectedVariant.ordersCount
                            : selectedVariant.count == 0
                            ? 0
                            : 'unlimited') == 0
                            ? 'Out Of Stock'
                            : 'Add to Bag'
                        }
                        fullWidth
                        className='addBagBtn'
                        onClick={() => {
                          if (routeDetails?.isEcommerceActive) {
                            if (details ? true : routeDetails?.storeStatus) {
                              if (
                                location?.area?._id &&
                                location?.city?._id &&
                                location?.state?._id
                              ) {
                                addToBag({
                                  productDetails,
                                  variant: selectedVariant,
                                  bag,
                                  dispatch,
                                });
                                setAddBtnClicked(true);
                              } else {
                                setIsLocationModal(true);
                              }
                            } else {
                              dispatch(
                                snackbarInfo({
                                  open: true,
                                  type: 'warning',
                                  message: `Currently store is closed, Please come back at ${routeDetails?.sellerDetails?.storeOpenTime}`,
                                })
                              );
                            }
                          } else {
                            dispatch(
                              snackbarInfo({
                                open: true,
                                type: 'error',
                                message: 'Currently not in service please contact store',
                              })
                            );
                          }
                        }}
                        disabled={
                          (selectedVariant.count
                            ? selectedVariant.count - selectedVariant.ordersCount
                            : selectedVariant.count == 0
                            ? 0
                            : 'unlimited') == 0
                            ? true
                            : false
                        }
                      />
                    </div>
                  )}
                </div>
                <ProductCartHelper
                  sellerDetails={routeDetails?.sellerDetails}
                  brandDetails={routeDetails?.brandDetails}
                  navFrom={routeDetails?.navFrom}
                  table={routeDetails?.table}
                  from={'modal'}
                  modalProps={{
                    height: '40px',
                  }}
                />
                <MynyfyButton
                  label={'Close'}
                  onClick={() => closeModal()}
                  fullWidth
                  style={{ border: '2px solid #e8e8e8', backgroundColor: 'white', color: 'black' }}
                />
              </div>
              <LocationModal
                isOpen={isLocationModal}
                close={(e) => {
                  if (!_.isEmpty(location) || e) setIsLocationModal(false);
                }}
                closeBtn={() => setIsLocationModal(false)}
                content={
                  'Some products may not be deliverable to every location. Set your delivery location to view serviceable products.'
                }
              />
              {selectedVariant?.image ? (
                <ShowImages
                  isVisible={openImageList}
                  closeModal={() => setOpenImageList(false)}
                  images={selectedVariant?.image}
                />
              ) : null}
            </Fragment>
          ) : (
            <MynyfyLoader />
          )}
        </Fragment>
      </div>

      {platform == 'web' ? (
        <MynyfyWebViewVideo
          openModal={isViewVideo?.open}
          closeModal={() => setIsViewVideo({ open: false, url: null })}
          url={isViewVideo?.url}
        />
      ) : (
        <MynyfyViewVideo
          openModal={isViewVideo?.open}
          closeModal={() => setIsViewVideo({ open: false, url: null })}
          url={isViewVideo?.url}
        />
      )}
    </MynyfyModal>
  );
};

export default ProductsDetailsModal;
