import React, { useEffect, useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MynyfyText from '../../components/MynyfyText';
import { CircularProgress } from '@mui/material';
import { userProfile } from '../../redux/actions/user.action';
import { snackbarInfo } from '../../redux/actions/snackbar.action';
import { fetchVisitedStores } from '../../redux/actions/visitedStores.action';
import { FavoriteBorderRounded, FavoriteRounded } from '@mui/icons-material';
import { s3ToCloudFront } from '../../utils/CommonMethods';
import { likeStore, unlikeStore } from './StoresHelper';
import storeService from '../../services/storeService';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import './stores.scss';

const VisitedStores = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const visitedStores = useSelector((state) => state?.visitedStores);
  const profile = useSelector((state) => state?.user?.profile);
  const isLoggedIn = useSelector((state) => state.user?.isLoggedIn);

  const [isLiked, setLiked] = useState(false);

  useEffect(() => {
    let isApiCall = profile?.visitedSellers?.length !== visitedStores?.length ? true : false;
    if (_.isEmpty(visitedStores) || isApiCall) {
      if (isLoggedIn) getVisitedStores();
    }
  }, []);

  const getVisitedStores = () => {
    storeService.getVisitedSellers().then((res) => {
      if (res.success) {
        dispatch(fetchVisitedStores(res.data));
      } else {
        dispatch(fetchVisitedStores([]));
        dispatch(snackbarInfo({ type: 'error', open: true, message: res.message }));
      }
    });
  };

  const likeHandler = (storeId, key) => {
    if (isLoggedIn) {
      let options = {
        storeId,
        setLiked,
        profile,
        data: visitedStores,
        dispatch,
        action: fetchVisitedStores,
        userProfile,
        snackbarInfo,
      };
      if (key === 'like') likeStore(options);
      else unlikeStore(options);
    }
  };

  const footer = (item) => {
    return (
      <Fragment>
        <MynyfyText title={item.name} style={{ padding: '7px 10px' }} />
        <div className='rowSB footer'>
          <MynyfyText title={item?.area?.name} link onClick={() => navHandler(item)} />
          <div className='row'>
            {isLiked ? (
              <CircularProgress size={16} />
            ) : profile?.likedSellers?.includes(item._id) ? (
              <FavoriteRounded
                color='error'
                fontSize=''
                style={{ cursor: 'pointer' }}
                onClick={() => likeHandler(item._id, 'unlike')}
              />
            ) : (
              <FavoriteBorderRounded
                color='error'
                fontSize=''
                style={{ cursor: 'pointer' }}
                onClick={() => likeHandler(item._id, 'like')}
              />
            )}
            <MynyfyText title={item.likesCount} style={{ marginLeft: 2 }} />
          </div>
        </div>
      </Fragment>
    );
  };

  const navHandler = (item) => {
    navigate('/Stores/' + item.urlId, { state: { isVisited: true, storeDetails: item } });
  };

  return !_.isEmpty(visitedStores) ? (
    <div id='visitedStores'>
      <div className='visitedStoreSection row'>
        {visitedStores.map((res, i) => (
          <div key={i} className='visitedStoreContainer'>
            <img
              src={s3ToCloudFront(res.logo?.url || res.orgImages?.[0]?.url)}
              onClick={() => navHandler(res)}
              className='visitedStoreImg'
            />
            {footer(res)}
          </div>
        ))}
      </div>
    </div>
  ) : null;
};

export default VisitedStores;
