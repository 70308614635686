import React from 'react';
import MynyfyTab from '../../../components/MynyfyTab';
import Contest from '..';
import { ContestType } from '../../../utils/enums';
import { useSelector } from 'react-redux';
import { fetchJackpot, fetchJackpotHistory } from '../../../redux/actions/contest.action';
import WebContest from '../WebContest';

const WebJackpot = ({ goHome }) => {
  const jackpot = useSelector((state) => state.contest?.jackpot);
  const jackpotHistory = useSelector((state) => state.contest?.jackpotHistory);

  return (
    <MynyfyTab
      goHome={goHome}
      tabs={['Active', 'Winners']}
      tabComponent={[
        <WebContest
          type={ContestType.JACKPOT}
          status={'active'}
          isCategories={false}
          emptyListTitle={'Jackpot'}
          draws={jackpot}
          action={fetchJackpot}
        />,
        <WebContest
          type={ContestType.JACKPOT}
          status={'NOACTIVE'}
          isCategories={false}
          emptyListTitle={'Jackpot'}
          draws={jackpotHistory}
          action={fetchJackpotHistory}
          isHistory={true}
        />,
      ]}
    />
  );
};

export default WebJackpot;
