import React from 'react';
import MynyfyTab from '../../../components/MynyfyTab';
import Contest from '..';
import { ContestType } from '../../../utils/enums';
import { useSelector } from 'react-redux';
import {
  fetchFoodShopAndWin,
  fetchFoodShopAndWinHistory,
  fetchShopAndWin,
  fetchShopAndWinHistory,
} from '../../../redux/actions/contest.action';
import { useLocation } from 'react-router';

const ShopAndWin = ({ goHome, noBackBtn }) => {
  const route = useLocation();

  const shopAndWin = useSelector((state) => state.contest?.shopAndWin);
  const shopAndWinHistory = useSelector((state) => state.contest?.shopAndWinHistory);
  const foodShopAndWin = useSelector((state) => state.contest?.foodShopAndWin);
  const foodShopAndWinHistory = useSelector((state) => state.contest?.foodShopAndWinHistory);

  const isRestaurant = route?.state?.isRestaurant || false;

  return (
    <MynyfyTab
      noBackBtn={noBackBtn}
      goHome={goHome}
      tabs={['Active', 'Winners']}
      tabComponent={[
        <Contest
          type={ContestType.SHOP_AND_WIN}
          status={'active'}
          isCategories={false}
          emptyListTitle={'Shop to Join'}
          draws={isRestaurant ? foodShopAndWin : shopAndWin}
          action={isRestaurant ? fetchFoodShopAndWin : fetchShopAndWin}
          isRestaurant={isRestaurant}
        />,
        <Contest
          type={ContestType.SHOP_AND_WIN}
          status={'NOACTIVE'}
          isCategories={false}
          emptyListTitle={'Shop to Join'}
          draws={isRestaurant ? foodShopAndWinHistory : shopAndWinHistory}
          action={isRestaurant ? fetchFoodShopAndWinHistory : fetchShopAndWinHistory}
          isHistory={true}
          isRestaurant={isRestaurant}
        />,
      ]}
    />
  );
};

export default ShopAndWin;
