import { FETCH_VISITED_STORES } from '../actionTypes';

const INITIAL_STATE = null;

const visitedStoresReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_VISITED_STORES:
      return action.payload;
    default:
      return state;
  }
};

export default visitedStoresReducer;
