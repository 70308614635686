import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';
import SignIn from '../screens/Auth/SignIn';
import SignUp from '../screens/Auth/SignUp';
import ForgotPassword from '../screens/Auth/ForgotPassword';
import Home from '../screens/Home';
import StoreDetails from '../screens/Stores/StoreDetails';
import BrandDetails from '../screens/Brands/BrandDetails';

const AuthStack = ({ isMynyfy, storeData, brandData, noData }) => {
  return (
    <Fragment>
      <Route
        path='/'
        element={
          storeData || noData ? (
            <StoreDetails storeData={storeData} noData={noData} />
          ) : brandData ? (
            <BrandDetails brandData={brandData} />
          ) : (
            <Home />
          )
        }
      />
      <Route path='SignIn' element={<SignIn />} />
      <Route path='SignUp' element={<SignUp />} />
      <Route path='ForgotPassword' element={<ForgotPassword />} />
    </Fragment>
  );
};

export default AuthStack;
