import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import _ from 'lodash';
import MynyfyLoader from '../../components/MynfyfLoader';
import MynyfyEmptyMsg from '../../components/MynyfyEmptyMsg';
import SignInModal from '../Auth/SignInModal';
import MynyfyText from '../../components/MynyfyText';
import MynyfyDoubleCard from '../../components/MynyfyDoubleCard';
import MynyfyHeader from '../../components/MynyfyHeader';
import WebMynyfyHeader from '../../components/WebMynyfyHeader';
import theme from '../../utils/theme';

const WebAdsList = () => {
  const adlist = useSelector((state) => state.contest?.seeAndWin);
  const user = useSelector((state) => state.user);
  const isLoggedIn = user?.isLoggedIn;

  const navigate = useNavigate();

  const [openSignIn, setOpenSignIn] = useState(false);

  const onClickingAd = (ads) => {
    if (isLoggedIn) navigate('/WebPosts', { state: { adId: ads._id } });
    else setOpenSignIn(true);
  };

  const renderFooter = (ad) => {
    return (
      <div>
        <div>
          <MynyfyText title={ad.name || 'No Name Available'} center />
        </div>
      </div>
    );
  };

  return (
    <div>
      <WebMynyfyHeader title={'All Ads'} />
      <div>
        {adlist ? (
          _.isEmpty(adlist) ? (
            <MynyfyEmptyMsg message={'No Ads at your location'} paddingTop={10} small={true} />
          ) : (
            <div className='row'>
              <MynyfyDoubleCard
                data={adlist}
                onClick={(ads) => onClickingAd(ads)}
                footer={(ad) => renderFooter(ad)}
              />
            </div>
          )
        ) : (
          <MynyfyLoader paddingTop={10} />
        )}
      </div>
      <SignInModal open={openSignIn} close={() => setOpenSignIn(false)} />
    </div>
  );
};

export default WebAdsList;
