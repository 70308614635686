import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';
import DownloadApp from '../screens/DownloadApp';
import Policies from '../screens/Policies';
import PageNotFound from '../screens/PageNotFound';
import PrivacyPolicy from '../screens/Policies/PrivacyPolicy';
import RefundPolicy from '../screens/Policies/RefundPolicy';
import ReturnPolicy from '../screens/Policies/ReturnPolicy';
import JoinByScan from '../screens/JoinByScan';
import AboutUs from '../screens/Policies/AboutUs';
import ContactUs from '../screens/Policies/ContactUs';
import AdPolicy from '../screens/Policies/AdPolicy';
import Brands from '../screens/Brands';
import BrandDetails from '../screens/Brands/BrandDetails';
import Stores from '../screens/Stores';
import StoreDetails from '../screens/Stores/StoreDetails';
import ContestDetails from '../screens/Contest/ContestDetails';
import DayDealDetails from '../screens/Promos/DayDeal/DayDealDetails';
import DayDeal from '../screens/Promos/DayDeal';
import Jackpot from '../screens/Contest/Jackpot';
import ShopAndWin from '../screens/Contest/ShopAndWin';
import ScanAndWin from '../screens/Contest/ScanAndWin';
import UserPolicy from '../screens/Policies/UserPolicy';
import ProductsDetails from '../screens/Products/ProductDetails';
import OfflineStores from '../screens/Stores/OfflineStores';
import Rewards from '../screens/Promos/Rewards';
import PromoDetails from '../screens/Promos/PromoDetails';
import OfflineFoodStores from '../screens/Stores/OfflineFoodStores';
import OnlineFoodStores from '../screens/Stores/OnlineFoodStores';
import DealsLayout from '../screens/Home/DealsLayout';
import FoodLayout from '../screens/Home/FoodLayout';
import ShopingLayout from '../screens/Home/ShopingLayout';
import ProductCart from '../screens/Products/ProductCart';
import TableOrder from '../screens/Orders/TableOrder';
import WebBrandDetails from '../screens/Brands/WebBrandDetails';
import ContestDetailsNew from '../screens/Contest/ContestDetailsNew';
import JackpotNew from '../screens/Contest/Jackpot/JackpotNew';
import BrandShippingPolicy from '../screens/Brands/BrandShippingPolicy';
import BrandRefundPolicy from '../screens/Brands/BrandRefundPolicy';
import BrandTermsPolicy from '../screens/Brands/BrandTermsPolicy';
import BrandPrivicyPolicy from '../screens/Brands/BrandPrivicyPolicy';
import SignUp from '../screens/Brands/Reseller/SignUp';
import ResellerRegistration from '../screens/Brands/Reseller/ResellerRegistration';
import Profile from '../screens/Profile';
import BrandProductCart from '../screens/Products/BrandProductCart';
import Products from '../screens/Products';
import BrandProductsDetails from '../screens/Products/BrandProductDetails';

const CommonStack = () => {
  return (
    <Fragment>
      <Route path='DownloadApp' element={<DownloadApp />} />
      <Route path='Product/:id' element={<ProductsDetails />} />
      <Route path='Products/Bag' element={<ProductCart />} />
      <Route path='ShopByBrandProducts' element={<Products />} />
      <Route path='BrandProducts/Bag' element={<BrandProductCart />} />
      <Route path='TableOrder/:table' element={<TableOrder />} />

      {/* Brands & Stores */}
      <Route path='Brands' element={<Brands />} />
      <Route path='Brands/:brand' element={<BrandDetails />} />
      <Route path='BrandProduct/:id' element={<BrandProductsDetails />} />
      <Route path='BrandShippingPolicy' element={<BrandShippingPolicy />} />
      <Route path='BrandRefundPolicy' element={<BrandRefundPolicy />} />
      <Route path='BrandTermsPolicy' element={<BrandTermsPolicy />} />
      <Route path='BrandPrivicyPolicy' element={<BrandPrivicyPolicy />} />
      <Route path='BrandShippingPolicy' element={<BrandShippingPolicy />} />
      <Route path='Reseller/SignUp' element={<SignUp />} />
      <Route path='Reseller/ResellerRegistration' element={<ResellerRegistration />} />
      <Route path='Profile' element={<Profile />} />

      <Route path='OnlineStores' element={<Stores />} />
      <Route path='OfflineStores' element={<OfflineStores />} />
      <Route path='TakeAwayRestaurants' element={<OfflineFoodStores />} />
      <Route path='HomeDeliveryRestaurants' element={<OnlineFoodStores />} />
      <Route path='Stores/:store/:table?' element={<StoreDetails />} />

      {/* Contests */}
      {/* <Route path='Jackpots' element={<Jackpot />} /> */}
      <Route path='Jackpots' element={<JackpotNew />} />

      <Route path='ShopToJoin' element={<ShopAndWin />} />
      <Route path='ScanToJoin' element={<ScanAndWin />} />
      <Route path='Contest/:id' element={<ContestDetails />} />
      {/* <Route path='Contest/:id' element={<ContestDetailsNew />} /> */}
      <Route path='JoinContest' element={<JoinByScan />} />

      {/* Promos */}
      <Route path='Deals' element={<DayDeal />} />
      <Route path='Deals/:id' element={<DayDealDetails />} />
      <Route path='Rewards' element={<Rewards />} />
      <Route path='Rewards/:id' element={<PromoDetails />} />

      {/* policies */}
      <Route path='Policies' element={<Policies />} />
      <Route path='Policies/Terms&Conditions' element={<PrivacyPolicy />} />
      <Route path='Policies/PrivacyPolicy' element={<PrivacyPolicy />} />
      <Route path='Policies/Cancellation&RefundPolicy' element={<RefundPolicy />} />
      <Route path='Policies/Shipping&ReturnPolicy' element={<ReturnPolicy />} />
      <Route path='Policies/PublishAdPolicy' element={<AdPolicy />} />
      <Route path='AboutUs' element={<AboutUs />} />
      <Route path='ContactUs' element={<ContactUs />} />
      <Route path='Policies/:userPolicy' element={<UserPolicy />} />

      <Route path='DealsLayout' element={<DealsLayout />} />
      <Route path='Restaurants' element={<FoodLayout />} />
      <Route path='Shopping' element={<ShopingLayout />} />

      <Route path='*' element={<PageNotFound />} />
    </Fragment>
  );
};

export default CommonStack;
