import React from 'react';
import MynyfyModal from './MynyfyModal';

const MynyfyViewVideo = ({ openModal, closeModal, url }) => {
  return (
    <MynyfyModal
      open={openModal}
      close={() => closeModal()}
      position='center'
      style={{ padding: 2, borderRadius: 7, margin: 5 }}>
      {url ? (
        <div className='rowC' style={{ flexDirection: 'column' }}>
          {url.type == 'S3' ? (
            <video style={{ width: '100%', height: '100%', borderRadius: 7 }} controls>
              <source src={url.url} type='video/mp4' />
              Your browser does not support the video tag
            </video>
          ) : (
            <iframe
              style={{
                width: '100%',
                height: url.type == 'Youtube Short' ? '80vh' : '300px',
                borderRadius: 5,
              }}
              src={url.url}
              title={url.type + ' Video'}
              frameborder='0'
              allowFullScreen
              {...(url.type == 'Youtube' && {
                allow:
                  'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share',
                referrerpolicy: 'strict-origin-when-cross-origin',
              })}
            />
          )}
        </div>
      ) : null}
    </MynyfyModal>
  );
};

export default MynyfyViewVideo;
