import React, { Fragment, useEffect, useState } from 'react';
import MynyfyText from '../../components/MynyfyText';
import { useSelector, useDispatch } from 'react-redux';
import ProgressBar from '../../components/ProgressBar';
import { s3ToCloudFront } from '../../utils/CommonMethods';
import { AddCircleRounded } from '@mui/icons-material';
import theme from '../../utils/theme';
import { useNavigate } from 'react-router-dom';
import { ChevronLeftRounded } from '@mui/icons-material';
import { fetchBottomTab } from '../../redux/actions/bottomTab.action';

const AdItemHeader = ({ currPosition, itemIndex, item }) => {
  const mynts = useSelector((state) => state.mynts);
  const user = useSelector((state) => state.user);
  const isLoggedIn = user?.isLoggedIn;
  const dispatch = useDispatch();

  const [isAnimation, setIsAnimation] = useState('false');

  const navigate = useNavigate();

  useEffect(() => {
    if (item?.isContested && currPosition === itemIndex) {
      setIsAnimation('true');
      setTimeout(() => setIsAnimation('false'), 2500);
    }
  }, [item?.isContested]);

  return (
    <Fragment>
      <div className='rowSB headerContainer' style={{ backgroundColor: 'rgb(46 49 48)' }}>
        <div className='row'>
          <div
            style={{ padding: 5, cursor: 'pointer' }}
            onClick={() => {
                navigate('/');
            }}>
            <ChevronLeftRounded style={{ width: 30, height: 30, display: 'block', color:'white' }} />
          </div>
          {item.brand ? (
            <div className='brand'>
              <img
                src={s3ToCloudFront(item.brand.logo ? item.brand.logo.url : item.image)}
                className='headerBrandLogo'
              />
              <div>
                <MynyfyText
                  title={item?.brand?.brand || ''}
                  small
                  h1
                  style={{ color: 'white', fontSize: 16 }}
                />
              </div>
            </div>
          ) : (
            <div className='brand' />
          )}
        </div>
        <div className='row'>
          <div iscontested={isAnimation} className='row myntsHolder'>
            <img src={require('../../assets/imgs/icons/coin.png')} className='myntsIcon' />
            <MynyfyText title={mynts} small bold className='myntsTxt' />
          </div>
          <AddCircleRounded
            className='cursor'
            style={{ color: theme.PRIMARY_BLUE }}
            onClick={() => {
              if (isLoggedIn) navigate('/PublishAds/Create');
              else navigate('/SignIn');
            }}
          />
        </div>
      </div>
      {!item.isContested && <ProgressBar currPosition={currPosition} itemIndex={itemIndex} />}
    </Fragment>
  );
};

export default AdItemHeader;
