import React, { useEffect, useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MynyfyText from '../../components/MynyfyText';
import { CircularProgress } from '@mui/material';
import { userProfile } from '../../redux/actions/user.action';
import { snackbarInfo } from '../../redux/actions/snackbar.action';
import { fetchVisitedStores } from '../../redux/actions/visitedStores.action';
import {
  ChevronRightRounded,
  Facebook,
  FavoriteBorderRounded,
  FavoriteRounded,
  Instagram,
  WhatsApp,
  YouTube,
} from '@mui/icons-material';
import { s3ToCloudFront } from '../../utils/CommonMethods';
import { likeStore, unlikeStore } from './StoresHelper';
import storeService from '../../services/storeService';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import './WebVisitedStores.scss';
import WebMynyfyText from '../../components/WebMynyfyText';
import mynyfyLogo from '../../assets/imgs/LandingPage/mynyfy.png';
import MynyfySpan from '../../components/MynyfySpan';

const WebVisitedStores = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const visitedStores = useSelector((state) => state?.visitedStores);
  const profile = useSelector((state) => state?.user?.profile);
  const isLoggedIn = useSelector((state) => state.user?.isLoggedIn);

  const [isLiked, setLiked] = useState(false);

  useEffect(() => {
    let isApiCall = profile?.visitedSellers?.length !== visitedStores?.length ? true : false;
    if (_.isEmpty(visitedStores) || isApiCall) {
      if (isLoggedIn) getVisitedStores();
    }
  }, []);

  const getVisitedStores = () => {
    storeService.getVisitedSellers().then((res) => {
      if (res.success) {
        dispatch(fetchVisitedStores(res.data));
      } else {
        dispatch(fetchVisitedStores([]));
        dispatch(snackbarInfo({ type: 'error', open: true, message: res.message }));
      }
    });
  };

  const likeHandler = (storeId, key) => {
    if (isLoggedIn) {
      let options = {
        storeId,
        setLiked,
        profile,
        data: visitedStores,
        dispatch,
        action: fetchVisitedStores,
        userProfile,
        snackbarInfo,
      };
      if (key === 'like') likeStore(options);
      else unlikeStore(options);
    }
  };

  const footer = (item) => {
    return (
      <Fragment>
        <div className='rowSB footer'>
          <MynyfyText title={item.name} style={{ padding: '7px 10px' }} />
          <div className='row' onClick={(e) => e.stopPropagation()}>
            {isLiked ? (
              <CircularProgress size={16} />
            ) : profile?.likedSellers?.includes(item._id) ? (
              <FavoriteRounded
                color='error'
                fontSize=''
                style={{ cursor: 'pointer' }}
                onClick={() => likeHandler(item._id, 'unlike')}
              />
            ) : (
              <FavoriteBorderRounded
                color='error'
                fontSize=''
                style={{ cursor: 'pointer' }}
                onClick={() => likeHandler(item._id, 'like')}
              />
            )}
            <MynyfyText title={item.likesCount} style={{ marginLeft: 2 }} />
          </div>
        </div>
      </Fragment>
    );
  };

  const navHandler = (item) => {
    navigate('/Stores/' + item.urlId, { state: { isVisited: true, storeDetails: item } });
  };

  return !_.isEmpty(visitedStores) ? (
    <Fragment>
      <div id='WebVisitedStores' style={{ padingTop: 30 }}>
        <div className='visitedList'>
          <div style={{ paddingBottom: 25 }}>
            <WebMynyfyText title={'Recently Viewed'} h6 bold />
          </div>
          <div className='visitedStoreSection'>
            {visitedStores.map((res, i) => (
              <div
                key={i}
                className='visitedStoreContainer cursor rowSB'
                onClick={() => navHandler(res)}>
                <img
                  src={s3ToCloudFront(res.logo?.url || res.orgImages?.[0]?.url)}
                  className='visitedStoreImg'
                />
                {footer(res)}
              </div>
            ))}
          </div>
        </div>
      </div>
    </Fragment>
  ) : null;
};

export default WebVisitedStores;
