import _ from 'lodash';
import validate from '../../utils/validate';
import { fetchBagCalculation, fetchBagProducts } from '../../redux/actions/bag.action';
import { objectReplace, priceClaculation } from '../../utils/CommonMethods';
import { snackbarInfo } from '../../redux/actions/snackbar.action';

let VALIDATOR = {
  selectedColor: [(value) => (value ? null : 'Select product color')],
  selectedSize: [(value) => (value ? null : 'Select product size')],
};

export const addToBag = ({ productDetails, variant, bag, dispatch, from }) => {
  let qty = variant.count ? variant.count - variant.ordersCount : 'unlimited';
  if (qty == 'unlimited' || qty > 0) {
    let obj = {};
    if (!_.isEmpty(variant.size)) obj.selectedSize = variant.size;
    if (!_.isEmpty(variant.color)) obj.selectedColor = variant.color;
    let errors = validate(obj, VALIDATOR);
    if (_.isEmpty(errors)) {
      let productObj = {
        // productId: productDetails._id,
        // variantId: variant._id,
        productCode: variant.productCode,
        count: 1,
        orderCount: variant.ordersCount,
        leftOverQuantity: qty,
        price: variant.price,
        gst: productDetails.gst?.data || 0,
        priceBeforeGst: priceClaculation({ price: variant.price, gst: productDetails.gst }),
        costPrice: variant.costPrice || 0,
        name: productDetails.name,
        brandId: productDetails.brand?._id,
        categoryId: productDetails.category?._id,
        subCategoryId: productDetails.subCategory?._id,
        state: productDetails.state?.map((res) => res._id),
        city: productDetails.city?.map((res) => res._id),
        area: productDetails.area?.map((res) => res._id),
        weightRange: variant?.weightRange,
        img: variant.image?.[0]?.url,
      };
      if (from === 'brand') {
        productObj.brandProductId = productDetails?._id;
        productObj.brandVariantId = variant?._id;
      } else {
        productObj.productId = productDetails?._id;
        productObj.variantId = variant?._id;
      }
      if (!_.isEmpty(variant.size)) productObj.size = variant.size;
      if (!_.isEmpty(variant.color)) productObj.color = variant.color;
      if (!_.isEmpty(productDetails.microcCategory)) {
        productObj.microCategoryId = productDetails.microcCategory?._id;
      }
      if (productDetails?.brandProductId) {
        productObj.brandProductId = productDetails?.brandProductId;
        productObj.brandVariantId = variant?.brandVariantId;
      }
      if (productDetails.brandProductId) {
        productObj.maxDiscountRupees = variant.price - variant.costPrice;
        productObj.maxDiscountPercent = Number(
          ((productObj.maxDiscountRupees * 100) / variant.price)?.toFixed(2)
        );
      } else {
        productObj.maxDiscountRupees = variant.price;
        productObj.maxDiscountPercent = 100;
      }

      let clonedProducts = _.cloneDeep(bag?.products);
      if (_.isEmpty(clonedProducts)) {
        dispatch(fetchBagProducts([productObj]));
        totalCalculation({ dispatch, reqObj: [productObj] });
      } else {
        let reqProduct = clonedProducts.find(
          (e) => e.productId == productDetails._id && e.variantId == variant._id
        );
        if (reqProduct) {
          incrementHandler({ productDetails, variant, bag, dispatch });
        } else {
          clonedProducts.push(productObj);
          dispatch(fetchBagProducts(clonedProducts));
          totalCalculation({ dispatch, reqObj: clonedProducts });
        }
      }
      dispatch(
        snackbarInfo({
          open: true,
          type: 'success',
          message: 'Product Added to Cart successfully!',
        })
      );
    } else {
      let message = Object.values(errors);
      if (message.length > 1) message.unshift('Below fields are mandatory');
      dispatch(snackbarInfo({ type: 'warning', open: true, message }));
    }
  } else {
    dispatch(
      snackbarInfo({
        type: 'warning',
        open: true,
        message: qty <= 0 ? 'Currently this product is out of stock' : `Only ${qty} are available`,
      })
    );
  }
};

export const incrementHandler = ({ productDetails, variant, bag, dispatch, from }) => {
  let clonedProducts = _.cloneDeep(bag?.products);

  let productKey = from === 'brand' ? 'brandProductId' : 'productId';
  let variantKey = from === 'brand' ? 'brandVariantId' : 'variantId';

  let reqProduct = clonedProducts.find(
    (e) => e[productKey] == productDetails._id && e[variantKey] == variant._id
  );

  if (reqProduct) {
    let qty = reqProduct.leftOverQuantity;
    if (qty == 'unlimited' || qty > reqProduct.count) {
      reqProduct.count = reqProduct.count + 1;
      let modifiedData = objectReplace(clonedProducts, reqProduct, variantKey);
      dispatch(fetchBagProducts(modifiedData));
      totalCalculation({ dispatch, reqObj: modifiedData });
    } else {
      dispatch(snackbarInfo({ type: 'warning', open: true, message: `Only ${qty} are available` }));
    }
  }
};

export const decrementHandler = ({ productDetails, variant, bag, dispatch, from }) => {
  let clonedProducts = _.cloneDeep(bag?.products);

  let productKey = from === 'brand' ? 'brandProductId' : 'productId';
  let variantKey = from === 'brand' ? 'brandVariantId' : 'variantId';

  let reqProduct = clonedProducts.find(
    (e) => e[productKey] == productDetails._id && e[variantKey] == variant._id
  );

  if (reqProduct) {
    if (reqProduct.count > 0) {
      reqProduct.count = reqProduct.count - 1;
      if (reqProduct.count > 0) {
        let modifiedData = objectReplace(clonedProducts, reqProduct, variantKey);
        dispatch(fetchBagProducts(modifiedData));
        totalCalculation({ dispatch, reqObj: modifiedData });
      } else {
        let modifiedData = clonedProducts.filter(
          (e) => !(e[productKey] == productDetails._id && e[variantKey] == variant._id)
        );
        dispatch(fetchBagProducts(modifiedData));
        totalCalculation({ dispatch, reqObj: modifiedData });
      }
    }
  }
};

export const qtyHandler = ({ productDetails, variant, value, bag, dispatch, from }) => {
  let reqQty = Number(value);
  if (!isNaN(value)) {
    let clonedProducts = _.cloneDeep(bag?.products);

    let productKey = from === 'brand' ? 'brandProductId' : 'productId';
    let variantKey = from === 'brand' ? 'brandVariantId' : 'variantId';

    let reqProduct = clonedProducts.find(
      (e) => e[productKey] == productDetails?._id && e[variantKey] == variant._id
    );

    if (reqProduct) {
      let qty = reqProduct.leftOverQuantity;
      if (qty == 'unlimited' || qty >= reqQty) {
        reqProduct.count = reqQty;
        let modifiedData = objectReplace(clonedProducts, reqProduct, variantKey);
        dispatch(fetchBagProducts(modifiedData));
        totalCalculation({ dispatch, reqObj: modifiedData });
      } else {
        dispatch(
          snackbarInfo({ type: 'warning', open: true, message: `Only ${qty} are available` })
        );
      }
    }
  }
};

export const totalCalculation = ({ dispatch, reqObj }) => {
  if (!_.isEmpty(reqObj)) {
    let qty = 0;
    let amount = 0;
    reqObj.map((res) => {
      qty = qty + res.count;
      amount = amount + res.count * res.price;
    });
    if (qty > 0) {
      dispatch(fetchBagCalculation({ qty, amount }));
    } else {
      dispatch(fetchBagCalculation(null));
    }
  } else {
    dispatch(fetchBagCalculation(null));
  }
};

export const productFinalPriceHandler = ({ price, discount, discountType, gst }) => {
  let discountValue = discountType == 'percentage' ? (price * Number(discount)) / 100 : discount;
  let reqDiscountValue = Number(discountValue.toFixed(2));

  let priceWithGst = Number((price - reqDiscountValue).toFixed(2));
  let priceWithoutGst = Number((priceWithGst / (1 + gst)).toFixed(2));
  return { priceWithGst, priceWithoutGst };
};

export const maxDiscountHandler = ({ discount, discountType, product }) => {
  let isPercentage = (discountType?.value || discountType) == 'percentage' ? true : false;
  let maxDiscount = isPercentage ? product?.maxDiscountPercent : product?.maxDiscountRupees;
  if (maxDiscount || maxDiscount === 0) {
    return discount > maxDiscount ? maxDiscount : discount;
  } else return discount;
};

export const getShippingCharges = ({ products, address, org, brands, isRestaurant }) => {
  let shippingCharges = 0;
  let brandIds = [];
  products?.forEach((e) => {
    if (!brandIds.includes(e.brandId) && e.brandId) brandIds.push(e.brandId);
  });

  if (_.isEmpty(brandIds)) {
    let orderAmount = 0;
    let totalWeight = 0;
    let charges = getReqCharges(org, address, isRestaurant);
    if (isRestaurant) {
      shippingCharges += charges;
    } else {
      products?.map((res) => {
        orderAmount += (res.discountedPrice || res.price) * res.count;
        if (res?.weightRange) {
          totalWeight +=
            (isNaN(Number(res?.weightRange)) ? 0 : Number(res?.weightRange)) * res.count;
        } else totalWeight += 0;
      });

      if (totalWeight) {
        shippingCharges += Number(
          charges?.flatShipping
            ? charges?.flatShipping
            : charges?.movForShipping
            ? orderAmount >= charges?.movForShipping
              ? 0
              : getChargesOnWeight(totalWeight, charges)
            : getChargesOnWeight(totalWeight, charges)
        );
      } else shippingCharges += 0;
    }
  } else {
    brandIds.forEach((brandId) => {
      let reqBrand = brands?.find((e) => e._id == brandId);
      let charges = getReqCharges(reqBrand, address, isRestaurant);
      let reqProducts = products?.filter((e) => e.brandId == brandId);
      let orderAmount = 0;
      let totalWeight = 0;

      reqProducts?.map((res) => {
        orderAmount += (res.discountedPrice || res.price) * res.count;
        if (res?.weightRange) {
          totalWeight +=
            (isNaN(Number(res?.weightRange)) ? 0 : Number(res?.weightRange)) * res.count;
        } else totalWeight += 0;
      });

      if (totalWeight) {
        shippingCharges += Number(
          charges?.flatShipping
            ? charges?.flatShipping
            : charges?.movForShipping
            ? orderAmount >= charges?.movForShipping
              ? 0
              : getChargesOnWeight(totalWeight, charges)
            : getChargesOnWeight(totalWeight, charges)
        );
      } else shippingCharges += 0;
    });
  }

  return shippingCharges;
};

const getChargesOnWeight = (weight, charges) => {
  let isAbove = weight > 500 ? true : false;
  if (isAbove) {
    let for500 = Number(charges?.upto500 || 0);
    let forAbove500 =
      (charges?.above500 || 0) * Math.ceil(Number(((weight - 500) / 500)?.toFixed(2)));
    return for500 + forAbove500;
  } else {
    return charges?.upto500 || 0;
  }
};

const getReqCharges = (reqObj, address, isRestaurant) => {
  let charges = {};
  if (isRestaurant) {
    charges = reqObj?.policies?.shippingCharges?.forRestaurant?.deliveryCharge || 0;
  } else {
    let shippingType;
    if (reqObj && address) {
      if ((reqObj?.state?._id || reqObj?.state) == address?.state) {
        if ((reqObj?.city?._id || reqObj?.city) == address?.city) shippingType = 'insideCity';
        else shippingType = 'insideState';
      } else shippingType = 'outsideState';
    } else charges = 0;

    charges = reqObj?.policies?.shippingCharges?.[shippingType || 'insideCity'];
  }
  return charges;
};
