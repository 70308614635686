import React, { useState } from 'react';
import MynyfyText from '../../components/MynyfyText';
import theme from '../../utils/theme';
import AdItemHeader from './AdItemHeader';
import ShowImages from '../../components/ShowImages';
import { useDispatch } from 'react-redux';
import { snackbarInfo } from '../../redux/actions/snackbar.action';
import { CircleRounded } from '@mui/icons-material';
import { getOS, s3ToCloudFront } from '../../utils/CommonMethods';
import WebAdItemHeader from './WebAdItemHeader';
import WebAdItemFooter from './WebAdItemFooter';

const WebAdItem = ({ item, currPosition, itemIndex, handleIntrest }) => {
  const dispatch = useDispatch();
  const platform = getOS();
  const [isImageModalVisible, setIsImageModalVisible] = useState(false);

  const onShare = async () => {
    try {
      let body = {
        title: item.name,
        text:
          item.terms
            ?.split('$')
            .map((res) => '- ' + res)
            .join('\n') || '',
      };
      if (navigator.share) {
        await navigator.share(body);
      }
    } catch (error) {
      dispatch(
        snackbarInfo({ open: true, type: 'error', message: 'Share option is not available' })
      );
    }
  };

  return (
    <div className='pagerView'>
      <WebAdItemHeader item={item} currPosition={currPosition} itemIndex={itemIndex} />
      <div className='post'>
        <div
          onClick={() => {
            if (platform == 'windows') {
              setIsImageModalVisible(true);
            }
          }}>
          <img src={s3ToCloudFront(item.image)} className='drawImg' />
        </div>
        <WebAdItemFooter item={item} onShare={onShare} handleInterest={handleIntrest} />
      </div>
    </div>
  );
};

export default WebAdItem;
