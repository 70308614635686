import React from 'react';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import CloseRoundedIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';

const MynyfyDrawer = ({
  anchor = 'right',
  open,
  onClose,
  children,
  width,
  height,
  style,
  drawerMargin,
  drawerBorderRadius,
}) => {
  return (
    <Drawer
      sx={{
        '& .MuiDrawer-paper': {
          margin: drawerMargin,
          borderRadius: drawerBorderRadius,
          width: width ?? 400,
          height: height ?? '100%',
          overflowY: 'auto',
          maxHeight: '100vh',
        },
      }}
      anchor={anchor}
      open={open}
      onClose={onClose}>
      <Box
        sx={{
          scrollbarWidth: 'none',
          backgroundColor: '#ffffff',
          width: '100%',
          height: '100%',
          ...style,
        }}>
        {/* <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
            marginRight: '5px',
            padding: '10px 5px 0 5px',
          }}>
          <IconButton
            onClick={onClose}
            style={{ border: '2px solid #eeeeee', borderRadius: '7px', height: '30px' }}>
            <CloseRoundedIcon style={{ color: 'black', fontSize: '15px' }} />
          </IconButton>
        </div> */}
        {children}
      </Box>
    </Drawer>
  );
};

export default MynyfyDrawer;
