import React, { Fragment, useState } from 'react';
import MynyfyText from '../../../components/MynyfyText';
import '../contest.scss';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { GroupsRounded } from '@mui/icons-material';
import SelectStoreType from '../../../components/SelectStoreType';
import { s3ToCloudFront } from '../../../utils/CommonMethods';

const ShopAndWinDetails = ({ item, isSingle, isHistory, onDetailsClick, isRestaurant }) => {
  const navigate = useNavigate();

  const [openStoreTypeModal, setOpenStoreTypeModal] = useState(false);

  return (
    <div>
      {isSingle ? (
        <Fragment>
          <MynyfyText title={item.name} pBig bold style={{ padding: '5px 10px 10px' }} />
          {item?.brand ? (
            <div className='row brandInfo' onClick={() => navigate('/Brands/' + item.brand?.urlId)}>
              {item?.brand?.logo ? (
                <img src={s3ToCloudFront(item.brand?.logo?.url)} className='logo' />
              ) : null}
              <MynyfyText title={item?.brand?.brand} style={{ margin: '0px 10px' }} />
              <MynyfyText title={'View Page'} primary small className='brandBtn cursor' />
            </div>
          ) : null}
        </Fragment>
      ) : null}
      <div className='rowSB footer'>
        {isHistory ? (
          <Fragment>
            <MynyfyText title={'View Winners'} bold link onClick={() => onDetailsClick(item)} />
            <div className='row'>
              <GroupsRounded className='groupIcon' />
              <MynyfyText title={item.totalContestants || 0} bold primary />
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <div
              onClick={() => {
                if (item.organization) {
                  navigate('/Stores/' + item.organization.urlId);
                } else if (item.brand) {
                  navigate('/Brands/' + item.brand?.urlId);
                } else {
                  setOpenStoreTypeModal(true);
                }
              }}
              style={{ opacity: item.isContested ? 0.35 : 1 }}>
              {isSingle ? (
                <MynyfyText
                  title={item.isContested ? 'Joined' : 'Shop to Join'}
                  bold
                  small
                  className='joinContestBtn'
                  contested={`${item.isContested}`}
                />
              ) : null}
            </div>
            <MynyfyText title={'Details'} bold link onClick={() => onDetailsClick(item)} />
          </Fragment>
        )}
      </div>
      <SelectStoreType
        isVisible={openStoreTypeModal}
        closeModal={() => setOpenStoreTypeModal(false)}
        isRestaurant={isRestaurant}
      />
    </div>
  );
};

export default ShopAndWinDetails;
