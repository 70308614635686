import React, { Fragment, useEffect, useRef, useState } from 'react';
import MynyfyText from '../../components/MynyfyText';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import productService from '../../services/productService';
import { snackbarInfo } from '../../redux/actions/snackbar.action';
import _ from 'lodash';
import MynyfyEmptyMsg from '../../components/MynyfyEmptyMsg';
import MynyfyLoader from '../../components/MynfyfLoader';
import MynyfySpan from '../../components/MynyfySpan';
import './WebBrandProducts.scss';
import { ProductMaterialType, ProductType, SortTypes } from '../../utils/enums';
import { s3ToCloudFront, storeTimingHandler } from '../../utils/CommonMethods';
import { ReactComponent as VegIcon } from '../../assets/svg/veg.svg';
import { ReactComponent as NonVegIcon } from '../../assets/svg/nonVeg.svg';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {
  AddRounded,
  ControlPointRounded,
  RemoveCircleOutlineRounded,
  RemoveRounded,
} from '@mui/icons-material';
import { addToBag, decrementHandler, incrementHandler, qtyHandler } from './AddProductHelper';
import theme from '../../utils/theme';
import ShowImages from '../../components/ShowImages';
import ShowDescription from '../../components/ShowDescription';
import { productsMergeHandler } from './ProductHelper';
import WebMynyfyVerFilter from '../../components/WebMynyfyVerFilter';
import MynyfyButton from '../../components/MynyfyButton';
import { Tooltip } from 'react-tippy';
import SelectVariantForOrder from './SelectVariantForOrder';
import LocationModal from '../Location/LocationModal';
import BrandProductsDetailsModal from './BrandProductDetailsModal';
import WebBrandProductDetailsModal from './WebBrandProductDetailsModal';

const WebBrandProducts = ({
  from,
  brandDetails,
  sellerDetails,
  isEcommerceActive,
  table,
  searchValue,
  setIsProducts,
  setIsServices,
  setProductsType,
  productsType,
  section,
  setActualProductsData,
  selectedBrandForProducts,
  productBrandNames,
  handleActiveComponent,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const route = useLocation();
  const navFrom = from;
  const inputRefs = useRef([]);

  const location = useSelector((state) => state.location?.location);
  const bag = useSelector((state) => state.bag);

  const isRestaurant = brandDetails?.category?.find(
    (e) => e._id == process.env.REACT_APP_FOOD_CAT_ID
  );

  const [products, setProducts] = useState(null);
  const [services, setServices] = useState(null);
  const [actualProducts, setActualProducts] = useState(null);
  const [searchInput, setSearchInput] = useState(searchValue | null);
  const [sectionProducts, setSectionProducts] = useState([]);
  const [selectedSubCat, setSelectedSubCat] = useState('AllProducts');
  const [subCatList, setSubCatList] = useState([]);
  const [actualSectionProducts, setActualSectionProducts] = useState([]);
  const [openImageList, setOpenImageList] = useState(false);
  const [imageList, setImageList] = useState(null);
  const [openDescriptionModal, setOpenDescriptionModal] = useState(false);
  const [description, setDescription] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [subCats, setSubCats] = useState();
  const [microCats, setMicroCats] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentMicroCats, setCurrentMicroCats] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [openSelectVariantModal, setOpenSelectVariantModal] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [isLocationModal, setIsLocationModal] = useState(false);
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [productItem, setProductItem] = useState(null);

  const productBrand = selectedBrandForProducts;

  useEffect(() => {
    if (!_.isEmpty(brandDetails) || route?.state?.from == 'shop') {
      let params = productsParamsHandler({});
      if (!_.isEmpty(params)) getProducts(params);
    } else {
      setProducts([]);
      setServices([]);
      setActualProducts([]);
    }
  }, [refresh, section, productBrand, location]);

  useEffect(() => {
    if (productsType) {
      let reqData = productsType == ProductType.SERVICE ? services : products;
      if (reqData) productSectionHandler(reqData);
    }
  }, [productsType]);

  useEffect(() => {
    if (searchValue && actualProducts.length > 0) {
      const newSearchData = actualProducts.filter((e) =>
        e.name.toLowerCase().includes(searchValue.toLowerCase())
      );
      productSectionHandler(newSearchData);
    } else if (actualProducts?.length > 0 && !searchValue) {
      productSectionHandler(actualProducts);
    }
  }, [searchValue]);

  useEffect(() => {
    if (!_.isEmpty(productBrand?._id)) {
      searchAndCatFilter({
        selectedBrand: { value: productBrand?._id },
        sortedData: actualProducts,
      });
    }
  }, [productBrand, actualProducts]);

  const productsParamsHandler = ({ catId }) => {
    let brandId = brandDetails?._id;
    if (!_.isEmpty(brandId) || route?.state?.from == 'shop') {
      let data = {
        status: 'ACTIVE',
        brands: brandId || [],
      };
      if (catId) data.category = catId;
      if (searchInput) data.search = searchInput;
      if (brandDetails) {
        data.brands = brandDetails._id;
        if (brandDetails.state) data.state = brandDetails.state?._id;
        if (brandDetails.city) data.city = brandDetails.city?._id;
        if (brandDetails.area) data.area = brandDetails.area?._id;
        if (_.isEmpty(catId)) {
          let brandCats = brandDetails.category.filter((e) => e.code === 'Category');
          data.category = brandCats.map((res) => res._id);
        }
        if (!_.isEmpty(brandDetails.productInActiveSubCategories)) {
          data.notRequiredSubCats = brandDetails.productInActiveSubCategories;
        }
      }
      if (route?.state?.from == 'shop') {
        if (location) {
          if (location?.state) data.state = location?.state?._id;
          if (location?.city) data.city = location?.city?._id;
          if (location?.area) data.area = location?.area?._id;
        }
      }
      return data;
    } else {
      setProducts([]);
      setServices([]);
      setActualProducts([]);
    }
  };

  const getProducts = (params) => {
    productService.getBrandProducts(params).then((res) => {
      if (res.success) {
        let reqData = res.data?.map((e) => {
          e.variants = e.variants.filter((f) => f.isActive);
          return e;
        });
        reqData = reqData.filter((e) => !_.isEmpty(e.variants));
        let sorted = _.orderBy(reqData, 'createdAt', 'desc');
        let sortedProducts = sorted.filter((e) => e.type == ProductType.PRODUCT);
        let sortedServices = sorted.filter((e) => e.type == ProductType.SERVICE);
        productSectionHandler(_.isEmpty(sortedProducts) ? sortedServices : sortedProducts);
        setProducts(sortedProducts);
        setServices(sortedServices);
        setIsProducts(_.isEmpty(sortedProducts) ? false : true);
        setIsServices(_.isEmpty(sortedServices) ? false : true);
        setActualProducts(sorted);
        setActualProductsData(sorted);
        if (_.isEmpty(sortedProducts)) setProductsType(ProductType.SERVICE);
        if (_.isEmpty(sortedProducts) && _.isEmpty(sortedServices)) {
          setProductsType(ProductType.PRODUCT);
        }
      } else {
        dispatch(snackbarInfo({ type: 'error', open: true, message: res.message }));
      }
    });
  };

  const searchAndCatFilter = ({
    selectedInStock,
    selectedOutStock,
    searchedText,
    selectedCategory,
    selectedSubCategory,
    selectedMicroCategory,
    selectedSort,
    selectedFoodType,
    sortedData,
    selectedBrand,
  }) => {
    let reqData = sortedData
      ? sortedData
      : actualProducts?.filter((e) => e.type == (productsType || ProductType.PRODUCT));
    if (!_.isEmpty(reqData)) {
      let newData = [];
      if (selectedFoodType == SortTypes.ONLY_VEG) {
        newData = reqData.filter((e) => e.materialType == ProductMaterialType.VEGETARIAN);
      } else if (selectedFoodType == SortTypes.ONLY_NON_VEG) {
        newData = reqData.filter((e) => e.materialType == ProductMaterialType.NON_VEGETARIAN);
      } else if (selectedFoodType == SortTypes.ALL) {
        newData = reqData;
      } else newData = reqData;
      if (selectedCategory) {
        newData = newData.filter((e) =>
          e.category ? e.category._id === selectedCategory?.value : e.category === undefined
        );
      }
      if (selectedSubCategory) {
        newData = newData.filter((e) =>
          e.subCategory
            ? e.subCategory._id === selectedSubCategory?.value
            : e.subCategory === undefined
        );
      }
      if (selectedMicroCategory) {
        newData = newData.filter((e) => e.microCategory?._id === selectedMicroCategory?.value);
      }
      if (selectedInStock && selectedOutStock) {
        //
      } else {
        if (selectedInStock) {
          newData = newData.filter((e) =>
            e?.variants?.some((variant) => !variant?.count || variant?.count > variant?.ordersCount)
          );
        }
        if (selectedOutStock) {
          newData = newData.filter((e) =>
            e?.variants?.some((variant) => variant?.count <= variant?.ordersCount)
          );
        }
      }

      if (selectedBrand) newData = newData.filter((e) => e.brandName === selectedBrand?.value);

      let modData = [];
      modData = searchedText
        ? newData.filter((res) => res.name.toLowerCase().includes(searchedText.toLowerCase()))
        : newData;

      if (selectedSort) sortFilter(selectedSort, modData);
      else {
        productSectionHandler(modData, 'search');
        if (productsType == ProductType.PRODUCT) setProducts(modData);
        else setServices(modData);
      }
    }
  };

  const sortFilter = (e, sortedData) => {
    let reqData = sortedData
      ? sortedData
      : productsType == ProductType.PRODUCT
      ? products
      : services;
    if (!_.isEmpty(reqData)) {
      let sorted = null;
      if (e == SortTypes.LOW_TO_HIGH_PRICE) {
        sorted = _.orderBy(reqData, 'variants[0].price', 'asc');
      } else if (e == SortTypes.HIGH_TO_LOW_PRICE) {
        sorted = _.orderBy(reqData, 'variants[0].price', 'desc');
      }
      setSectionProducts(sorted);
    } else {
      productSectionHandler([]);
      if (productsType == ProductType.PRODUCT) setProducts([]);
      else setServices([]);
    }
  };

  const productNavigationHandler = (item) => {
    // navigate(`/BrandProduct/${item._id}`, {
    //   state: { details: item, navFrom, brandDetails, sellerDetails, isEcommerceActive, table },
    // });
    setProductItem(item);
    setOpenDetailsModal(true);
  };

  const productSectionHandler = (productsData) => {
    const initialSelections = {};
    let sectionData = [];
    let cats = [];
    let subCats = [];
    let microCats = [];
    productsData.map((e) => {
      if (e.category) {
        if (!cats.find((f) => f._id == e.category?._id)) cats.push(e.category);
      }
      if (e.subCategory) {
        if (!subCats.find((f) => f?._id == e?.subCategory?._id)) subCats.push(e.subCategory);
      }
      if (e.microCategory) {
        if (!microCats.find((f) => f?._id == e?.microCategory?._id))
          microCats.push(e.microCategory);
      }
      if (e.variants?.length > 1) {
        let reqVariant = {};
        if (e.variants[0]?.size) reqVariant.size = e.variants[0]?.size;
        if (e.variants[0]?.color) reqVariant.color = e.variants[0]?.color;
        if (!_.isEmpty(reqVariant)) initialSelections[e._id] = reqVariant;
      }
      sectionData = Object.values(
        productsData.reduce((acc, item) => {
          if (!acc[item.subCategory?.name || 'Others']) {
            acc[item.subCategory?.name || 'Others'] = {
              title: item.subCategory?.name || 'Others',
              id: item.subCategory?._id || null,
              data: [],
            };
          }
          item.canOrder = item.variants.some(
            (e) => (!e.count || e.count - e.ordersCount > 0) && e.count !== 0
          );
          acc[item.subCategory?.name || 'Others'].data.push(item);
          return acc;
        }, {})
      );
    });
    setSubCats(subCats);
    setMicroCats(microCats);
    let sorted = _.orderBy(sectionData, 'title', 'asc');
    const othersIndex = sorted.findIndex((e) => e.title === 'Others');
    if (othersIndex !== -1) {
      const othersItem = sorted.splice(othersIndex, 1)[0];
      sorted.push(othersItem);
    }

    if (_.isEmpty(brandDetails?.productSubCatSequence)) {
      let reqProducts = sorted.map((e) => e.data)?.flat();
      let reqCats = sorted.map((e) => ({ title: e.title, id: e.id }));
      if (!_.isEmpty(section)) {
        reqProducts = reqProducts.filter((product) => product?.sections?.includes(section?._id));
      }
      setSectionProducts(_.orderBy(reqProducts, 'createdAt', 'desc'));
      setActualSectionProducts(_.orderBy(reqProducts, 'createdAt', 'desc'));
      setSubCatList(reqCats);
    } else {
      let arrangedData = brandDetails?.productSubCatSequence
        .map((e) => sorted.find((item) => item.id === e))
        .filter(Boolean);

      let remainingData = sorted.filter(
        (item) => !brandDetails?.productSubCatSequence.includes(item.id)
      );
      let result = arrangedData.concat(remainingData);
      let reqProducts = result.map((e) => e.data)?.flat();
      let reqCats = result.map((e) => ({ title: e.title, id: e.id }));
      if (!_.isEmpty(section)) {
        reqProducts = reqProducts.filter((product) => product?.sections?.includes(section?._id));
      }
      setSectionProducts(reqProducts);
      setActualSectionProducts(reqProducts);
      setSubCatList(reqCats);
    }
    setSelectedOptions(initialSelections);
  };

  const addToBagHandler = (item, variant) => {
    if (!_.isEmpty(brandDetails?.razorpayAccountId)) {
      if (location?.state?._id && location?.city?._id && location?.area?._id) {
        addToBag({ productDetails: item, variant, bag, dispatch, from: 'brand' });
      } else {
        setIsLocationModal(true);
      }
    } else {
      dispatch(
        snackbarInfo({
          open: true,
          type: 'error',
          message: 'Currently not in service please contact brand',
        })
      );
    }
  };

  const subCatOpenHandler = (key) => {
    setSelectedSubCat(key);
    if (key === 'AllProducts') {
      setSectionProducts(actualSectionProducts);
    } else {
      let sorted = actualSectionProducts.filter((e) => (e.subCategory?.name || 'Others') === key);
      setSectionProducts(sorted);
    }
  };

  const handleMouseEnter = (event, categoryData) => {
    const microCatsData = microCats.filter((micro) => micro.code == categoryData); // Filter microcategories
    if (microCats.length > 0) {
      setAnchorEl(event.currentTarget);
      setCurrentMicroCats(microCatsData);
    }
  };

  const descriptionHandler = (item, variant) => {
    setDescription({ title: item.name, description: item.description, variant });
    setOpenDescriptionModal(true);
  };

  const selectVariant = (productId, optionType, value) => {
    setSelectedOptions((prev) => ({
      ...prev,
      [productId]: { ...prev[productId], [optionType]: value },
    }));
  };

  const getUniqueAttributes = ({ variants, key, selectedKey, selectedValue }) => {
    return [
      ...new Set(
        variants.map((item) =>
          selectedValue ? item[selectedKey] == selectedValue && item[key] : item[key]
        )
      ),
    ]?.filter((e) => e);
  };

  return actualProducts ? (
    <Fragment>
      <div id='WebBrandProducts' style={{ paddingBottom: bag?.calculation?.qty ? 75 : 10 }}>
        {!_.isEmpty(section) && (
          <div
            style={{
              marginLeft: 15,
              marginBottom: 14,
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: 20,
            }}>
            <div style={{ position: 'relative', width: '65%', height: '500px' }}>
              <img
                src={s3ToCloudFront(section?.image?.url)}
                alt=''
                width='100%'
                height='100%'
                style={{
                  borderRadius: 20,
                  objectFit: 'cover',
                }}
              />
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  borderRadius: 20,
                }}></div>
              <MynyfyText
                title={section?.name}
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  color: 'white',
                  textAlign: 'center',
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  padding: '10px 20px',
                  borderRadius: '10px',
                }}
                bold
                h3
              />
            </div>
          </div>
        )}

        {!_.isEmpty(selectedBrandForProducts) && (
          <Fragment>
            <div
              className='shopByBrand'
              style={{
                marginTop: 50,
                paddingTop: 50,
                padding: '15px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'start',
              }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}>
                {selectedBrandForProducts?.image?.url ? (
                  <img
                    src={s3ToCloudFront(selectedBrandForProducts?.image?.url)}
                    alt='brand logo'
                    style={{
                      borderRadius: 10,
                      marginBottom: 10,
                      border: '1px solid #e8e8e8',
                      width: '100%',
                      height: '150px',
                      backgroundColor: '#e8e8e8',
                    }}
                  />
                ) : (
                  <div
                    style={{
                      borderRadius: 10,
                      marginBottom: 10,
                      border: '1px solid #e8e8e8',
                      width: '100%',
                      padding: 10,
                      height: '150px',
                      backgroundColor: '#e8e8e8',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}>
                    <MynyfyText title={selectedBrandForProducts?.name} h3 bold />
                  </div>
                )}
                <MynyfyText title={selectedBrandForProducts?.name} h3 bold />
                <div>
                  <MynyfyText
                    className='cursor'
                    title={'View All Brands'}
                    style={{ marginTop: 20 }}
                    link
                    onClick={() => handleActiveComponent()}
                  />
                </div>
              </div>
            </div>
          </Fragment>
        )}

        {!_.isEmpty(sectionProducts) ? (
          <div
            className='row'
            style={{ marginTop: 50, width: '100%', overflowX: 'auto', paddingBottom: -20 }}>
            <div className={`row catagorylist`} style={{ marginBottom: 5 }}>
              <div
                className={`catSectionIn ${selectedSubCat === 'AllProducts' ? 'active' : ''}`}
                onClick={() => {
                  subCatOpenHandler('AllProducts');
                  setRefresh(true);
                }}>
                <MynyfySpan
                  title={isRestaurant ? 'All Items' : 'Shop All'}
                  color={selectedSubCat === 'AllProducts' && '#3076b5'}
                  style={{ fontSize: 17 }}
                />
              </div>
              {subCatList?.map((res, j) => {
                const hasMicroCats = !_.isEmpty(currentMicroCats);
                return (
                  <div
                    key={res.title + j}
                    onClick={() => subCatOpenHandler(res.title)}
                    className={`catSectionIn ${selectedSubCat === res.title ? 'active' : ''}`}
                    onMouseEnter={(e) => handleMouseEnter(e, res.title)}>
                    {hasMicroCats ? (
                      <Tooltip
                        position='bottom'
                        interactive
                        trigger='mouseenter'
                        html={
                          _.isEmpty(currentMicroCats) ? null : (
                            <div
                              style={{
                                marginTop: 13,
                                backgroundColor: '#fff',
                                padding: '10px 20px 10px',
                              }}>
                              {currentMicroCats?.map((micro) => (
                                <div
                                  key={micro._id}
                                  onClick={() =>
                                    searchAndCatFilter({
                                      selectedMicroCategory: { value: micro._id },
                                    })
                                  }
                                  style={{ paddingTop: 10 }}>
                                  {micro.name}
                                </div>
                              ))}
                            </div>
                          )
                        }>
                        {' '}
                        <MynyfySpan
                          className='category-name'
                          title={res.title}
                          color={selectedSubCat === res.title && '#3076b5'}
                          style={{ fontSize: 17 }}
                        />
                      </Tooltip>
                    ) : (
                      <MynyfySpan
                        className='category-name'
                        title={res.title}
                        color={selectedSubCat === res.title && '#3076b5'}
                        style={{ fontSize: 17 }}
                      />
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        ) : null}

        <div style={{ paddingTop: 10, display: 'flex', height: '100vh', overflow: 'hidden' }}>
          <WebMynyfyVerFilter
            searchAndCatFilter={(e) => searchAndCatFilter(e)}
            sortFilter={(e) => sortFilter(e, actualProducts)}
            isPrice
            isFood={isRestaurant ? true : false}
            userCategories={brandDetails?.category}
            brands={productBrandNames}
          />
          {_.isEmpty(sectionProducts) ? (
            <div
              style={{
                alignItems: 'center',
                flex: 7,
              }}>
              <MynyfyEmptyMsg
                message={`No ${
                  isRestaurant ? 'dishes' : `${(productsType || 'Product') + 's'}`
                } are available ${
                  !_.isEmpty(section || productBrand)
                    ? ' in ' + (section?.name || productBrand?.name)
                    : ''
                }`}
                paddingTop={'25vh'}
                bold='i'
              />
            </div>
          ) : isRestaurant ? (
            <div
              style={{
                flex: 8,
                display: 'flex',
                flexWrap: 'wrap',
                gap: '15px',
                overflowY: 'auto',
              }}>
              {sectionProducts.map((item, i) => {
                const selectedSize = selectedOptions[item._id]?.size;
                const selectedColor = selectedOptions[item._id]?.color;
                const selectedVariant =
                  item.variants?.length > 1
                    ? item.variants.find(
                        (f) => f.size === selectedSize && f.color === selectedColor
                      )
                    : item.variants[0];
                return (
                  <div
                    className='foodProductContainer'
                    key={i}
                    menu-img={table && !brandDetails?.menuImages ? 'no' : 'yes'}>
                    <div
                      className='foodProductSubContainer cursor'
                      menu-img={table && !brandDetails?.menuImages ? 'no' : 'yes'}
                      onClick={() => productNavigationHandler(item)}>
                      <div className='foodProductLeftContainer'>
                        <div>
                          <div className='row' style={{ alignItems: 'flex-start' }}>
                            {table && !brandDetails?.menuImages && item.materialType ? (
                              <div style={{ margin: '5px 8px -4px 0px' }}>
                                {item.materialType == ProductMaterialType.NON_VEGETARIAN ? (
                                  <NonVegIcon width={15} height={15} />
                                ) : (
                                  <VegIcon width={15} height={15} />
                                )}
                              </div>
                            ) : null}
                            <MynyfyText
                              title={item.name}
                              bold
                              h4
                              color={'#000'}
                              style={{ textTransform: 'capitalize' }}
                            />
                          </div>
                          {item.variants?.length > 1 ? (
                            <div>
                              <MynyfyText
                                title='Variants'
                                color={'rgba(2, 6, 12, 0.6)'}
                                style={{ paddingTop: 15, letterSpacing: 1 }}
                                p
                              />
                              <div className='row' style={{ gap: 10, flexWrap: 'wrap' }}>
                                {getUniqueAttributes({
                                  variants: item.variants,
                                  key: 'size',
                                  selectedKey: 'color',
                                  selectedValue: selectedColor,
                                }).map((size) => (
                                  <MynyfyText
                                    key={size}
                                    title={size}
                                    className='chipBtn'
                                    isselected={selectedSize == size ? 'true' : 'false'}
                                    onClick={() => selectVariant(item._id, 'size', size)}
                                    style={{ userSelect: 'none', cursor: 'pointer' }}
                                  />
                                ))}
                              </div>
                              <div className='row' style={{ gap: 10, flexWrap: 'wrap' }}>
                                {getUniqueAttributes({
                                  variants: item.variants,
                                  key: 'color',
                                  selectedKey: 'size',
                                  selectedValue: selectedSize,
                                }).map((color) => (
                                  <div
                                    key={color}
                                    className='chipBtn colorChip'
                                    style={{ backgroundColor: color }}
                                    isselected={selectedColor == color ? 'true' : 'false'}
                                    onClick={() => selectVariant(item._id, 'color', color)}></div>
                                ))}
                              </div>
                            </div>
                          ) : null}
                          {table && !brandDetails?.menuImages ? null : item.description ? (
                            <Fragment>
                              <MynyfyText
                                id={item._id}
                                title={item.description}
                                className='foodPoductDescription'
                                onClick={() => descriptionHandler(item, selectedVariant)}
                                color={'#000'}
                              />
                              <MynyfyText
                                id={item._id}
                                title={'More details'}
                                link
                                small
                                onClick={() => descriptionHandler(item, selectedVariant)}
                              />
                            </Fragment>
                          ) : null}
                          {selectedVariant ? (
                            <div
                              className='row'
                              style={{
                                margin:
                                  table && !brandDetails?.menuImages ? '10px 0px 0px' : '5px 0px',
                              }}>
                              <MynyfyText
                                title={theme.RUPEE + selectedVariant.actualPrice}
                                style={{
                                  textDecorationLine:
                                    selectedVariant.price &&
                                    selectedVariant.actualPrice !== selectedVariant.price
                                      ? 'line-through'
                                      : 'unset',
                                }}
                                p={
                                  selectedVariant.price &&
                                  selectedVariant.actualPrice !== selectedVariant.price
                                    ? true
                                    : false
                                }
                                bold={
                                  selectedVariant.price &&
                                  selectedVariant.actualPrice !== selectedVariant.price
                                    ? false
                                    : true
                                }
                                color={'#000'}
                              />
                              {selectedVariant.actualPrice == selectedVariant.price ||
                              !selectedVariant.price ? null : (
                                <MynyfyText
                                  title={theme.RUPEE + selectedVariant.price}
                                  style={{ margin: '0px 5px' }}
                                  bold
                                  p
                                  color={'#000'}
                                />
                              )}
                            </div>
                          ) : null}
                        </div>

                        {table && !brandDetails?.menuImages ? null : item.materialType ? (
                          <div style={{ marginBottom: -20, marginTop: 5 }}>
                            {item.materialType == ProductMaterialType.NON_VEGETARIAN ? (
                              <NonVegIcon width={20} height={20} />
                            ) : (
                              <VegIcon width={20} height={20} />
                            )}
                          </div>
                        ) : null}
                      </div>
                      {selectedVariant ? (
                        <div style={{ flex: 1, textAlign: 'end' }}>
                          <div style={{ position: 'relative' }}>
                            {table && !brandDetails?.menuImages ? null : (
                              <div
                                onClick={() => {
                                  setImageList(selectedVariant.image);
                                  setOpenImageList(true);
                                }}>
                                <img
                                  src={s3ToCloudFront(selectedVariant.image[0].url)}
                                  className='foodProductImg'
                                  alt='selected product'
                                />
                              </div>
                            )}
                            {bag?.products?.find((e) => e.brandVariantId == selectedVariant._id) ? (
                              <div
                                className='rowSB addProductConatiner'
                                style={{ padding: '8.5px 10px' }}>
                                <div
                                  onClick={() => {
                                    decrementHandler({
                                      productDetails: item,
                                      variant: selectedVariant,
                                      bag,
                                      dispatch,
                                      from: 'brand',
                                    });
                                    inputRefs.current[i].focus();
                                  }}>
                                  <RemoveRounded fontSize='small' className='productIcon link' />
                                </div>
                                <input
                                  value={
                                    bag?.products?.find(
                                      (e) => e.brandVariantId == selectedVariant._id
                                    )?.count || 0
                                  }
                                  onChange={(e) => {
                                    qtyHandler({
                                      productDetails: item,
                                      variant: selectedVariant,
                                      value: e.target.value,
                                      bag,
                                      dispatch,
                                      from: 'brand',
                                    });
                                  }}
                                  autoFocus
                                  ref={(el) => (inputRefs.current[i] = el)}
                                  className='qtyInput'
                                  style={{ textAlign: 'center' }}
                                />
                                <div
                                  onClick={() => {
                                    incrementHandler({
                                      productDetails: item,
                                      variant: selectedVariant,
                                      bag,
                                      dispatch,
                                      from: 'brand',
                                    });
                                    inputRefs.current[i].focus();
                                  }}>
                                  <AddRounded fontSize='small' className='productIcon link' />
                                </div>
                              </div>
                            ) : (
                              <div
                                className='addProductConatiner link'
                                style={{
                                  backgroundColor: !item.canOrder
                                    ? selectedVariant.count
                                      ? selectedVariant.count - selectedVariant.ordersCount > 0
                                        ? theme.LIGHT_COLOR
                                        : theme.MUTED_COLOR
                                      : selectedVariant.count == 0
                                      ? theme.MUTED_COLOR
                                      : theme.LIGHT_COLOR
                                    : theme.LIGHT_COLOR,
                                }}
                                onClick={() => {
                                  if (
                                    !item.canOrder
                                      ? selectedVariant.count
                                        ? selectedVariant.count - selectedVariant.ordersCount > 0
                                        : !(selectedVariant.count == 0)
                                      : true
                                  ) {
                                    if (item?.variants?.length == 1) {
                                      addToBagHandler(item, selectedVariant);
                                    } else {
                                      setSelectedProduct(item);
                                      setOpenSelectVariantModal(true);
                                    }
                                  }
                                }}>
                                {(
                                  selectedVariant.count
                                    ? !(selectedVariant.count - selectedVariant.ordersCount > 0)
                                    : selectedVariant.count == 0
                                ) ? (
                                  <MynyfyText
                                    title={'Out Of Stock'}
                                    small
                                    bold
                                    center
                                    color={theme.ERROR}
                                  />
                                ) : (
                                  <div>
                                    <MynyfyText
                                      title={route?.state?.from == 'shop' ? 'Shop' : 'Add'}
                                      color={'rgb(27, 166, 114)'}
                                      bold
                                      center
                                      style={{ textTransform: 'uppercase' }}
                                    />
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div
              style={{
                flex: 8,
                display: 'flex',
                flexWrap: 'wrap',
                gap: '15px',
                overflowY: 'auto',
              }}>
              {sectionProducts.map((item, i) => {
                const selectedSize = selectedOptions[item._id]?.size;
                const selectedColor = selectedOptions[item._id]?.color;
                const selectedVariant =
                  item.variants?.length > 1
                    ? item.variants.find(
                        (f) => f.size === selectedSize && f.color === selectedColor
                      )
                    : item.variants[0];

                const isCount =
                  selectedVariant && !item.canOrder
                    ? selectedVariant.count
                      ? selectedVariant.count - selectedVariant.ordersCount > 0
                      : !(selectedVariant.count == 0)
                    : true;

                return (
                  <div key={i} className='productContainer' style={{ marginTop: 30 }}>
                    {selectedVariant ? (
                      <div className='productImgContainer cursor' style={{ position: 'relative' }}>
                        <img
                          src={s3ToCloudFront(selectedVariant.image[0].url)}
                          alt={item.name}
                          className='productImg'
                          onClick={() => productNavigationHandler(item)}
                        />
                        <div
                          className='addToBagContainerInList'
                          onClick={(event) => event.stopPropagation()}>
                          {bag?.products?.find((e) => e.brandVariantId == selectedVariant._id) ? (
                            <div className='rowC' style={{ padding: '5px 10px' }}>
                              <RemoveCircleOutlineRounded
                                className='cursor'
                                htmlColor='#fff'
                                onClick={() => {
                                  decrementHandler({
                                    productDetails: item,
                                    variant: selectedVariant,
                                    bag,
                                    dispatch,
                                    from: 'brand',
                                  });
                                  inputRefs.current[i].focus();
                                }}
                              />
                              <input
                                value={
                                  bag?.products?.find(
                                    (e) => e.brandVariantId == selectedVariant._id
                                  )?.count || 0
                                }
                                onChange={(e) => {
                                  qtyHandler({
                                    productDetails: item,
                                    variant: selectedVariant,
                                    value: e.target.value,
                                    bag,
                                    dispatch,
                                    from: 'brand',
                                  });
                                }}
                                autoFocus
                                ref={(el) => (inputRefs.current[i] = el)}
                                className='qtyInput nonRestaurantInput'
                                style={{ textAlign: 'center' }}
                              />
                              <ControlPointRounded
                                className='cursor'
                                htmlColor='#fff'
                                onClick={() => {
                                  incrementHandler({
                                    productDetails: item,
                                    variant: selectedVariant,
                                    bag,
                                    dispatch,
                                    from: 'brand',
                                  });
                                  inputRefs.current[i].focus();
                                }}
                              />
                            </div>
                          ) : (
                            <MynyfyText
                              title={
                                isCount
                                  ? bag?.products?.find((e) => e.brandProductId == item._id)
                                    ? 'Added'
                                    : 'Add to Bag'
                                  : 'Out Of Stock'
                              }
                              className='cursor'
                              color='#fff'
                              center
                              style={{
                                padding: 10,
                                cursor: isCount ? 'pointer' : 'not-allowed',
                              }}
                              onClick={() => {
                                if (isCount) {
                                  if (item.variants?.length == 1) {
                                    addToBagHandler(item, selectedVariant);
                                  } else productNavigationHandler(item);
                                }
                              }}
                            />
                          )}
                        </div>
                      </div>
                    ) : null}
                    <div style={{ marginTop: 5 }}>
                      <MynyfyText title={item.name} p color={'#000000'} center />
                      <div className='rowC' style={{ paddingTop: 5 }}>
                        {selectedVariant ? (
                          <div>
                            <MynyfySpan
                              title={'Rs.' + selectedVariant.actualPrice}
                              style={{
                                textDecoration:
                                  selectedVariant.price &&
                                  selectedVariant.actualPrice !== selectedVariant.price
                                    ? 'line-through'
                                    : 'unset',
                              }}
                              p
                            />
                            {selectedVariant.actualPrice == selectedVariant.price ||
                            !selectedVariant.price ? null : (
                              <MynyfySpan
                                title={'Rs.' + selectedVariant.price}
                                style={{ paddingLeft: 5 }}
                                p
                              />
                            )}
                          </div>
                        ) : null}

                        {item.materialType ? (
                          item.materialType == ProductMaterialType.NON_VEGETARIAN ? (
                            <NonVegIcon width={20} height={20} />
                          ) : (
                            <VegIcon width={20} height={20} />
                          )
                        ) : null}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
      {openDetailsModal ? (
        <WebBrandProductDetailsModal
          id={productItem?._id}
          details={productItem}
          brandDetails={brandDetails}
          isEcommerceActive={isEcommerceActive}
          table={table}
          openModal={openDetailsModal}
          closeModal={() => setOpenDetailsModal(false)}
          modalProps={{
            style: {
              borderRadius: 10,
            },
          }}
        />
      ) : null}
      <LocationModal
        isOpen={isLocationModal}
        close={(e) => {
          if (!_.isEmpty(location) || e) setIsLocationModal(false);
        }}
        closeBtn={() => setIsLocationModal(false)}
        content={
          'Some products may not be deliverable to every location. Set your delivery location to view serviceable products.'
        }
        modalProps={{
          position: 'center',
          style: {
            borderRadius: 10,
          },
        }}
      />
      <ShowImages
        isVisible={openImageList}
        closeModal={() => {
          setOpenImageList(false);
          setImageList(null);
        }}
        images={imageList}
      />
      <ShowDescription
        isVisible={openDescriptionModal}
        closeModal={() => {
          setOpenDescriptionModal(false);
          setDescription(null);
        }}
        data={description}
      />
      <SelectVariantForOrder
        isOpen={openSelectVariantModal}
        close={() => {
          setOpenSelectVariantModal(false);
          setSelectedProduct(null);
        }}
        product={selectedProduct}
        addToBagHandler={addToBagHandler}
        isMobile
        navFrom={navFrom}
        brandDetails={brandDetails}
        sellerDetails={sellerDetails}
        table={table}
      />
    </Fragment>
  ) : (
    <MynyfyLoader />
  );
};

export default WebBrandProducts;
