import React, { Fragment, useEffect, useRef, useState } from 'react';
import MynyfyText from '../../components/MynyfyText';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import productService from '../../services/productService';
import { snackbarInfo } from '../../redux/actions/snackbar.action';
import _ from 'lodash';
import MynyfyEmptyMsg from '../../components/MynyfyEmptyMsg';
import MynyfyLoader from '../../components/MynfyfLoader';
import MynyfySpan from '../../components/MynyfySpan';
import './products.scss';
import MynyfyMobileFilter from '../../components/MynyfyMobileFilter';
import { ProductMaterialType, ProductType, SortTypes } from '../../utils/enums';
import { getOS, s3ToCloudFront, storeTimingHandler } from '../../utils/CommonMethods';
import { ReactComponent as VegIcon } from '../../assets/svg/veg.svg';
import { ReactComponent as NonVegIcon } from '../../assets/svg/nonVeg.svg';
import {
  AddRounded,
  ControlPointRounded,
  RemoveCircleOutlineRounded,
  RemoveRounded,
} from '@mui/icons-material';
import { addToBag, decrementHandler, incrementHandler, qtyHandler } from './AddProductHelper';
import theme from '../../utils/theme';
import ShowImages from '../../components/ShowImages';
import ShowDescription from '../../components/ShowDescription';
import { productsMergeHandler } from './ProductHelper';
import SelectVariantForOrder from './SelectVariantForOrder';
import LocationModal from '../Location/LocationModal';
import BrandProductsDetailsModal from './BrandProductDetailsModal';

let platorm = getOS();
let isMobile = platorm == 'android' || platorm == 'ios' ? true : false;

const BrandProducts = ({
  from,
  brandDetails,
  sellerDetails,
  isEcommerceActive,
  table,
  section,
  setActualProductsData,
  productBrandNames,
  selectedBrandForProducts,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const route = useLocation();
  const navFrom = from;
  const inputRefs = useRef([]);

  const productBrand = selectedBrandForProducts;
  const location = useSelector((state) => state.location?.location);
  const bag = useSelector((state) => state.bag);

  const isRestaurant = brandDetails?.category?.find(
    (e) => e._id == process.env.REACT_APP_FOOD_CAT_ID
  );

  const [products, setProducts] = useState(null);
  const [services, setServices] = useState(null);
  const [isProducts, setIsProducts] = useState(null);
  const [isServices, setIsServices] = useState(null);
  const [type, setType] = useState(ProductType.PRODUCT);
  const [actualProducts, setActualProducts] = useState(null);
  const [sectionProducts, setSectionProducts] = useState([]);
  const [openImageList, setOpenImageList] = useState(false);
  const [imageList, setImageList] = useState(null);
  const [openDescriptionModal, setOpenDescriptionModal] = useState(false);
  const [description, setDescription] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [category, setCategory] = useState('AllProducts');
  const [categoryList, setCategoryList] = useState([]);
  const [actualSectionProducts, setActualSectionProducts] = useState([]);
  const [openSelectVariantModal, setOpenSelectVariantModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isLocationModal, setIsLocationModal] = useState(false);
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [productItem, setProductItem] = useState(null);

  useEffect(() => {
    if (!_.isEmpty(brandDetails) || route?.state?.from == 'shop') {
      let params = productsParamsHandler({});
      if (!_.isEmpty(params)) getProducts(params);
    } else {
      setProducts([]);
      setServices([]);
      setActualProducts([]);
    }
  }, [section, productBrand]);

  const productsParamsHandler = ({ catId }) => {
    let brandId = brandDetails?._id;
    if (!_.isEmpty(brandId) || route?.state?.from == 'shop') {
      let data = {
        status: 'ACTIVE',
        brands: brandId || [],
      };
      if (catId) data.category = catId;
      if (brandDetails) {
        data.brands = brandDetails._id;
        if (brandDetails.state) data.state = brandDetails.state?._id;
        if (brandDetails.city) data.city = brandDetails.city?._id;
        if (brandDetails.area) data.area = brandDetails.area?._id;
        if (_.isEmpty(catId)) {
          let brandCats = brandDetails.category.filter((e) => e.code === 'Category');
          data.category = brandCats.map((res) => res._id);
        }
        if (!_.isEmpty(brandDetails.productInActiveSubCategories)) {
          data.notRequiredSubCats = brandDetails.productInActiveSubCategories;
        }
      }
      if (route?.state?.from == 'shop') {
        if (location) {
          if (location?.state) data.state = location?.state?._id;
          if (location?.city) data.city = location?.city?._id;
          if (location?.area) data.area = location?.area?._id;
        }
      }
      return data;
    } else {
      setProducts([]);
      setServices([]);
      setActualProducts([]);
    }
  };

  const getProducts = (params) => {
    productService.getBrandProducts(params).then((res) => {
      if (res.success) {
        let reqData = res.data?.map((e) => {
          e.variants = e.variants.filter((f) => f.isActive);
          return e;
        });
        reqData = reqData.filter((e) => !_.isEmpty(e.variants));
        let sorted = _.orderBy(reqData, 'createdAt', 'desc');
        let sortedProducts = sorted.filter((e) => e.type == ProductType.PRODUCT);
        let sortedServices = sorted.filter((e) => e.type == ProductType.SERVICE);
        productSectionHandler(_.isEmpty(sortedProducts) ? sortedServices : sortedProducts);
        setProducts(sortedProducts);
        setServices(sortedServices);
        setIsProducts(_.isEmpty(sortedProducts) ? false : true);
        setIsServices(_.isEmpty(sortedServices) ? false : true);
        setActualProducts(sorted);
        setActualProductsData(sorted);
        if (_.isEmpty(sortedProducts)) setType(ProductType.SERVICE);
        if (_.isEmpty(sortedProducts) && _.isEmpty(sortedServices)) setType(ProductType.PRODUCT);
      } else {
        dispatch(snackbarInfo({ type: 'error', open: true, message: res.message }));
      }
    });
  };

  useEffect(() => {
    if (!_.isEmpty(productBrand?._id)) {
      searchAndCatFilter({
        selectedBrand: { value: productBrand?._id },
        sortedData: actualProducts,
      });
    }
  }, [productBrand, actualProducts]);

  const searchAndCatFilter = ({
    searchedText,
    selectedCategory,
    selectedSubCategory,
    selectedMicroCategory,
    selectedSort,
    selectedFoodType,
    selectedBrand,
    sortedData,
  }) => {
    let reqData = sortedData
      ? sortedData
      : actualProducts?.filter((e) => e.type == (type || ProductType.PRODUCT));
    if (!_.isEmpty(reqData)) {
      let newData = [];
      if (selectedFoodType == SortTypes.ONLY_VEG) {
        newData = reqData.filter((e) => e.materialType == ProductMaterialType.VEGETARIAN);
      } else if (selectedFoodType == SortTypes.ONLY_NON_VEG) {
        newData = reqData.filter((e) => e.materialType == ProductMaterialType.NON_VEGETARIAN);
      } else if (selectedFoodType == SortTypes.ALL) {
        newData = reqData;
      } else newData = reqData;

      if (selectedCategory) {
        newData = newData.filter((e) => e.category?._id === selectedCategory?.value);
      }
      if (selectedSubCategory) {
        newData = newData.filter((e) => e.subCategory?._id === selectedSubCategory?.value);
      }
      if (selectedMicroCategory) {
        newData = newData.filter((e) => e.microCategory?._id === selectedMicroCategory?.value);
      }
      if (selectedBrand) newData = newData.filter((e) => e.brandName === selectedBrand?.value);
      let modData = [];
      if (searchedText) {
        newData.map((res) => {
          if (res.name.toLowerCase().includes(searchedText.toLowerCase())) modData.push(res);
        });
      } else modData = newData;

      if (selectedSort) sortFilter(selectedSort, modData);
      else {
        productSectionHandler(modData, !_.isEmpty(selectedBrand || searchedText) ? 'search' : null);
        if (type == ProductType.PRODUCT) setProducts(modData);
        else setServices(modData);
      }
    }
  };

  const sortFilter = (e, sortedData) => {
    let reqData = sortedData ? sortedData : type == ProductType.PRODUCT ? products : services;
    if (!_.isEmpty(reqData)) {
      let sorted = null;
      if (e == SortTypes.LOW_TO_HIGH_PRICE) {
        sorted = _.orderBy(reqData, 'variants[0].price', 'asc');
      } else if (e == SortTypes.HIGH_TO_LOW_PRICE) {
        sorted = _.orderBy(reqData, 'variants[0].price', 'desc');
      }
      setSectionProducts(sorted);
      if (sorted) {
        productSectionHandler(sorted);
        if (type == ProductType.PRODUCT) setProducts(sorted);
        else setServices(sorted);
      }
    } else {
      productSectionHandler([]);
      if (type == ProductType.PRODUCT) setProducts([]);
      else setServices([]);
    }
  };

  const productNavigationHandler = (item) => {
    // navigate(`/BrandProduct/${item._id}`, {
    //   state: { details: item, navFrom, brandDetails, isEcommerceActive, table },
    // });
    setProductItem(item);
    setOpenDetailsModal(true);
  };

  const productSectionHandler = (productsData, type) => {
    const initialSelections = {};
    let sectionData = [];
    productsData.map((e) => {
      if (e.variants?.length > 1) {
        let reqVariant = {};
        if (e.variants[0]?.size) reqVariant.size = e.variants[0]?.size;
        if (e.variants[0]?.color) reqVariant.color = e.variants[0]?.color;
        if (!_.isEmpty(reqVariant)) initialSelections[e._id] = reqVariant;
      }
      sectionData = Object.values(
        productsData.reduce((acc, item) => {
          if (!acc[item.subCategory?.name || 'Others']) {
            acc[item.subCategory?.name || 'Others'] = {
              title: item.subCategory?.name || 'Others',
              id: item.subCategory?._id || null,
              data: [],
            };
          }

          item.canOrder = item.variants.some(
            (e) => (!e.count || e.count - e.ordersCount > 0) && e.count !== 0
          );
          acc[item.subCategory?.name || 'Others'].data.push(item);
          return acc;
        }, {})
      );
    });
    let sorted = _.orderBy(sectionData, 'title', 'asc');
    const othersIndex = sorted.findIndex((e) => e.title === 'Others');
    if (othersIndex !== -1) {
      const othersItem = sorted.splice(othersIndex, 1)[0];
      sorted.push(othersItem);
    }

    if (_.isEmpty(brandDetails?.productSubCatSequence)) {
      let reqProducts = sorted.map((e) => e.data)?.flat();
      // setActualProductsData(reqProducts);
      let reqCats = sorted.map((e) => ({ title: e.title, id: e.id }));
      if (!_.isEmpty(section)) {
        reqProducts = reqProducts.filter((product) => product?.sections?.includes(section?._id));
      }
      if (type === 'search') {
        setSectionProducts(_.orderBy(reqProducts, 'createdAt', 'desc'));
      } else {
        setSectionProducts((prevProducts) => {
          const newProducts = [...prevProducts];
          reqProducts.forEach((product) => {
            if (!newProducts.find((p) => p._id === product._id)) {
              newProducts.push(product);
            }
          });
          let finalProducts = _.orderBy(newProducts, 'createdAt', 'desc');
          return finalProducts;
        });
      }
      setActualSectionProducts(reqProducts);
      setCategoryList(reqCats);
    } else {
      let arrangedData = brandDetails?.productSubCatSequence
        .map((e) => sorted.find((item) => item.id === e))
        .filter(Boolean);

      let remainingData = sorted.filter(
        (item) => !brandDetails?.productSubCatSequence.includes(item.id)
      );
      let result = arrangedData.concat(remainingData);
      let reqProducts = result.map((e) => e.data)?.flat();
      // setActualProductsData(reqProducts);
      let reqCats = result.map((e) => ({ title: e.title, id: e.id }));
      if (!_.isEmpty(section)) {
        reqProducts = reqProducts.filter((product) => product?.sections?.includes(section?._id));
      }
      if (type === 'search') {
        setSectionProducts(reqProducts);
      } else {
        setSectionProducts((prevProducts) => {
          const newProducts = [...prevProducts];
          reqProducts.forEach((product) => {
            if (!newProducts.find((p) => p._id === product._id)) {
              newProducts.push(product);
            }
          });
          let finalProducts = _.orderBy(newProducts, 'createdAt', 'desc');
          return finalProducts;
        });
      }
      setActualSectionProducts(reqProducts);
      setCategoryList(reqCats);
    }
    setSelectedOptions(initialSelections);
  };

  const addToBagHandler = (item, variant) => {
    if (!_.isEmpty(brandDetails?.razorpayAccountId)) {
      if (location?.state?._id && location?.city?._id && location?.area?._id) {
        addToBag({ productDetails: item, variant, bag, dispatch, from: 'brand' });
      } else {
        setIsLocationModal(true);
      }
    } else {
      dispatch(
        snackbarInfo({
          open: true,
          type: 'error',
          message: 'Currently not in service please contact brand',
        })
      );
    }
  };

  const categoryData = (key) => {
    setCategory(key);
    if (key === 'AllProducts') {
      setSectionProducts(actualSectionProducts);
    } else {
      let sorted = actualSectionProducts.filter((e) => (e.subCategory?.name || 'Others') === key);
      setSectionProducts(sorted);
    }
  };

  const descriptionHandler = (item, variant) => {
    setDescription({ title: item.name, description: item.description, variant });
    setOpenDescriptionModal(true);
  };

  return actualProducts ? (
    <Fragment>
      {!_.isEmpty(selectedBrandForProducts) && (
        <div
          className='shopByBrand'
          style={{
            padding: '15px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'start',
          }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
            }}>
            {selectedBrandForProducts?.image?.url ? (
              <img
                src={s3ToCloudFront(selectedBrandForProducts?.image?.url)}
                alt='brand logo'
                style={{
                  borderRadius: 10,
                  marginBottom: 10,
                  border: '1px solid #e8e8e8',
                  width: '100%',
                  height: '100px',
                }}
              />
            ) : (
              <div
                style={{
                  borderRadius: 10,
                  marginBottom: 10,
                  border: '1px solid #e8e8e8',
                  width: '100%',
                  height: '100px',
                  padding: 10,
                  backgroundColor: '#e8e8e8',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                <MynyfyText title={selectedBrandForProducts?.name} bold />
              </div>
            )}
            <MynyfyText title={selectedBrandForProducts?.name} h3 bold />
          </div>
        </div>
      )}
      <div id='Products' className='rowSB' style={{ padding: '0px 15px 10px' }}>
        <div className='rowSB' style={{ paddingTop: 10, width: '100%' }}>
          <div>
            {isProducts || isServices ? null : (
              <MynyfyText title={isRestaurant ? 'Menu' : 'Products'} h6 />
            )}
            {isProducts ? (
              <MynyfyText
                title={isRestaurant ? 'Menu' : 'Products'}
                className='itemTypeBtn cursor'
                active={type == ProductType.PRODUCT ? 'true' : 'false'}
                color={type == ProductType.PRODUCT ? '#4F3550' : 'grey'}
                onClick={() => {
                  setType(ProductType.PRODUCT);
                  productSectionHandler(products);
                }}
              />
            ) : null}
            {isServices ? (
              <MynyfyText
                title={'Services'}
                className='itemTypeBtn cursor'
                active={type == ProductType.SERVICE ? 'true' : 'false'}
                color={type == ProductType.PRODUCT ? '#4F3550' : 'grey'}
                onClick={() => {
                  setType(ProductType.SERVICE);
                  productSectionHandler(services);
                }}
              />
            ) : null}
          </div>
          <div
            className='row cursor'
            style={{
              justifyContent: 'flex-end',
              flexWrap: 'wrap',
              paddingLeft: 10,
            }}
            onClick={() => setIsLocationModal(true)}>
            <MynyfyText
              title={_.isEmpty(location) ? 'Set Location' : 'Location:'}
              color={_.isEmpty(location) ? theme.PRIMARY_BLUE : theme.MUTED_BLUE}
              pBig
              style={{ whiteSpace: 'nowrap' }}
            />
            <MynyfyText
              title={location?.area?.name}
              color={theme.NAVY_BLUE}
              pBig
              style={{ paddingLeft: 4 }}
            />
          </div>
        </div>
      </div>

      <MynyfyMobileFilter
        searchAndCatFilter={(e) => searchAndCatFilter(e)}
        sortFilter={(e) => sortFilter(e)}
        isPrice
        isFood={isRestaurant ? true : false}
        userCategories={brandDetails?.category}
        brands={productBrandNames}
      />

      {!_.isEmpty(section) && (
        <div style={{ marginLeft: 15, marginBottom: 14 }}>
          <MynyfyText title={section?.name} h4 semiBold />
        </div>
      )}

      {_.isEmpty(sectionProducts) ? (
        <MynyfyEmptyMsg
          paddingTop={'18vh'}
          message={`No ${(type || 'Product') + 's'} are available ${
            !_.isEmpty(section || productBrand)
              ? ' in ' + (section?.name || productBrand?.name)
              : null
          }`}
        />
      ) : (
        <Fragment>
          <div id='Products' style={{ paddingBottom: bag?.calculation?.qty ? 75 : 10 }}>
            <div className='row catSectionNew' style={{ display: 'flex', gap: 5 }}>
              <div
                className={`catSectionIn ${category === 'AllProducts' ? 'active' : ''}`}
                style={{ border: category === 'AllProducts' && '2px solid #3076b5' }}
                onClick={() => categoryData('AllProducts')}>
                <MynyfySpan
                  title={isRestaurant ? 'All Items' : 'Shop All'}
                  cursor
                  color={category === 'AllProducts' && '#3076b5'}
                />
              </div>
              {categoryList?.map((res, j) => (
                <div
                  className={`catSectionIn ${category === res.title ? 'active' : ''}`}
                  style={{ border: category === res?.title && '2px solid #3076b5' }}
                  onClick={() => categoryData(res.title)}
                  key={res.title + j}>
                  <MynyfySpan
                    title={res.title}
                    cursor
                    color={category === res?.title && '#3076b5'}
                  />
                </div>
              ))}
            </div>
            {isRestaurant ? (
              sectionProducts.map((item, i) => {
                const selectedSize = selectedOptions[item._id]?.size;
                const selectedColor = selectedOptions[item._id]?.color;
                const selectedVariant =
                  item.variants?.length > 1
                    ? item.variants.find(
                        (f) => f.size === selectedSize && f.color === selectedColor
                      )
                    : item.variants[0];
                return (
                  <div
                    className='foodProductContainer'
                    key={i}
                    menu-img={table && !brandDetails?.menuImages ? 'no' : 'yes'}>
                    <div
                      className='foodProductSubContainer'
                      menu-img={table && !brandDetails?.menuImages ? 'no' : 'yes'}>
                      <div className='foodProductLeftContainer'>
                        <div>
                          <div className='row' style={{ alignItems: 'flex-start' }}>
                            {table && !brandDetails?.menuImages && item.materialType ? (
                              <div style={{ margin: '5px 8px -4px 0px' }}>
                                {item.materialType == ProductMaterialType.NON_VEGETARIAN ? (
                                  <NonVegIcon width={15} height={15} />
                                ) : (
                                  <VegIcon width={15} height={15} />
                                )}
                              </div>
                            ) : null}
                            <MynyfyText title={item.name} h5 />
                          </div>
                          {/* {item.variants?.length > 1 ? (
                              <div>
                                <MynyfyText
                                  title='Variants'
                                  color={'grey'}
                                  style={{ paddingTop: 15 }}
                                />
                                <div className='row' style={{ gap: 10, flexWrap: 'wrap' }}>
                                  {getUniqueAttributes({
                                    key: 'size',
                                    variants: item.variants,
                                  }).map((size) => (
                                    <MynyfyText
                                      key={size}
                                      title={size}
                                      className='chipBtn'
                                      isselected={selectedSize == size ? 'true' : 'false'}
                                      isactive={isDisabled({
                                        key: 'size',
                                        value: size,
                                        selectedKey: 'color',
                                        selectedValue: selectedColor,
                                        variants: item.variants,
                                      })}
                                      onClick={() => {
                                        selectVariant(item._id, 'size', size);
                                        const reqColors = getRelatedValues({
                                          key: 'color',
                                          selectedKey: 'size',
                                          selectedValue: size,
                                          variants: item.variants,
                                        });
                                        if (!reqColors.includes(selectedColor)) {
                                          selectVariant(
                                            item._id,
                                            'color',
                                            reqColors?.[0] || undefined
                                          );
                                        }
                                      }}
                                    />
                                  ))}
                                </div>
                                <div className='row' style={{ gap: 10, flexWrap: 'wrap' }}>
                                  {getUniqueAttributes({
                                    key: 'color',
                                    variants: item.variants,
                                  }).map((color) => (
                                    <div
                                      key={color}
                                      className='chipBtn colorChip'
                                      style={{ backgroundColor: color }}
                                      isselected={selectedColor == color ? 'true' : 'false'}
                                      isactive={isDisabled({
                                        key: 'color',
                                        value: color,
                                        selectedKey: 'size',
                                        selectedValue: selectedSize,
                                        variants: item.variants,
                                      })}
                                      onClick={() => {
                                        selectVariant(item._id, 'color', color);
                                        const reqSizes = getRelatedValues({
                                          key: 'size',
                                          selectedKey: 'color',
                                          selectedValue: color,
                                          variants: item.variants,
                                        });
                                        if (!reqSizes.includes(selectedSize)) {
                                          selectVariant(
                                            item._id,
                                            'size',
                                            reqSizes?.[0] || undefined
                                          );
                                        }
                                      }}></div>
                                  ))}
                                </div>
                              </div>
                            ) : null} */}
                          {table && !brandDetails?.menuImages ? null : item.description ? (
                            <Fragment>
                              <MynyfyText
                                id={item._id}
                                title={item.description}
                                className='foodPoductDescription'
                                onClick={() => descriptionHandler(item, selectedVariant)}
                              />
                              <MynyfyText
                                id={item._id}
                                title={'More details'}
                                link
                                small
                                onClick={() => descriptionHandler(item, selectedVariant)}
                              />
                            </Fragment>
                          ) : null}
                          {selectedVariant ? (
                            <div
                              className='row'
                              style={{
                                margin:
                                  table && !brandDetails?.menuImages ? '10px 0px 0px' : '5px 0px',
                              }}>
                              <MynyfyText
                                title={theme.RUPEE + selectedVariant.actualPrice}
                                style={{
                                  textDecorationLine:
                                    selectedVariant.price &&
                                    selectedVariant.actualPrice !== selectedVariant.price
                                      ? 'line-through'
                                      : 'unset',
                                }}
                                small={
                                  selectedVariant.price &&
                                  selectedVariant.actualPrice !== selectedVariant.price
                                    ? true
                                    : false
                                }
                                bold={
                                  selectedVariant.price &&
                                  selectedVariant.actualPrice !== selectedVariant.price
                                    ? false
                                    : true
                                }
                              />
                              {selectedVariant.actualPrice == selectedVariant.price ||
                              !selectedVariant.price ? null : (
                                <MynyfyText
                                  title={theme.RUPEE + selectedVariant.price}
                                  style={{ margin: '0px 5px' }}
                                  bold
                                />
                              )}
                            </div>
                          ) : null}
                        </div>

                        {table && !brandDetails?.menuImages ? null : item.materialType ? (
                          <div style={{ marginBottom: -20, marginTop: 5 }}>
                            {item.materialType == ProductMaterialType.NON_VEGETARIAN ? (
                              <NonVegIcon width={20} height={20} />
                            ) : (
                              <VegIcon width={20} height={20} />
                            )}
                          </div>
                        ) : null}
                      </div>
                      {selectedVariant ? (
                        <div style={{ flex: 1, textAlign: 'end' }}>
                          <div style={{ position: 'relative' }}>
                            {table && !brandDetails?.menuImages ? null : (
                              <div
                                onClick={() => {
                                  setImageList(selectedVariant.image);
                                  setOpenImageList(true);
                                }}>
                                <img
                                  src={s3ToCloudFront(selectedVariant.image[0].url)}
                                  className='foodProductImg'
                                  onClick={() => productNavigationHandler(item)}
                                  alt=''
                                />
                              </div>
                            )}
                            {item.variants?.length == 1 &&
                            bag?.products?.find((e) => e.brandVariantId == selectedVariant._id) ? (
                              <div
                                className='rowSB addProductConatiner'
                                style={{ padding: '8.5px 10px' }}>
                                <div
                                  onClick={() => {
                                    decrementHandler({
                                      productDetails: item,
                                      variant: selectedVariant,
                                      bag,
                                      dispatch,
                                      from: 'brand',
                                    });
                                    if (!isMobile) inputRefs.current[i].focus();
                                  }}>
                                  <RemoveRounded fontSize='small' className='productIcon link' />
                                </div>
                                <input
                                  value={
                                    bag?.products?.find(
                                      (e) => e.brandVariantId == selectedVariant._id
                                    )?.count || 0
                                  }
                                  onChange={(e) => {
                                    qtyHandler({
                                      productDetails: item,
                                      variant: selectedVariant,
                                      value: e.target.value,
                                      bag,
                                      dispatch,
                                      from: 'brand',
                                    });
                                  }}
                                  autoFocus={isMobile ? false : true}
                                  ref={(el) => (inputRefs.current[i] = el)}
                                  className='qtyInput'
                                />
                                <div
                                  onClick={() => {
                                    incrementHandler({
                                      productDetails: item,
                                      variant: selectedVariant,
                                      bag,
                                      dispatch,
                                      from: 'brand',
                                    });
                                    if (!isMobile) inputRefs.current[i].focus();
                                  }}>
                                  <AddRounded fontSize='small' className='productIcon link' />
                                </div>
                              </div>
                            ) : (
                              <div
                                className='addProductConatiner link'
                                style={{
                                  backgroundColor: !item.canOrder
                                    ? selectedVariant.count
                                      ? selectedVariant.count - selectedVariant.ordersCount > 0
                                        ? theme.LIGHT_COLOR
                                        : theme.MUTED_COLOR
                                      : selectedVariant.count == 0
                                      ? theme.MUTED_COLOR
                                      : theme.LIGHT_COLOR
                                    : theme.LIGHT_COLOR,
                                }}
                                onClick={() => {
                                  if (
                                    !item.canOrder
                                      ? selectedVariant.count
                                        ? selectedVariant.count - selectedVariant.ordersCount > 0
                                        : !(selectedVariant.count == 0)
                                      : true
                                  ) {
                                    if (item?.variants?.length == 1) {
                                      addToBagHandler(item, selectedVariant);
                                    } else if (!_.isEmpty(brandDetails?.razorpayAccountId)) {
                                      setSelectedProduct(item);
                                      setOpenSelectVariantModal(true);
                                    } else {
                                      dispatch(
                                        snackbarInfo({
                                          open: true,
                                          type: 'error',
                                          message: 'Currently not in service please contact brand',
                                        })
                                      );
                                    }
                                  }
                                }}>
                                {(
                                  selectedVariant.count
                                    ? !(selectedVariant.count - selectedVariant.ordersCount > 0)
                                    : selectedVariant.count == 0
                                ) ? (
                                  <MynyfyText
                                    title={'Out Of Stock'}
                                    small
                                    bold
                                    center
                                    color={theme.ERROR}
                                  />
                                ) : (
                                  <MynyfyText
                                    title={
                                      route?.state?.from == 'shop'
                                        ? 'Shop'
                                        : bag?.products?.find((e) => e.brandProductId == item._id)
                                        ? 'Added'
                                        : 'Add'
                                    }
                                    primary
                                    bold
                                    center
                                    style={{ textTransform: 'uppercase' }}
                                  />
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                );
              })
            ) : (
              <div className='productsMainContainer' style={{ paddingTop: 10 }}>
                {sectionProducts.map((item, i) => {
                  // const selectedSize = selectedOptions[item._id]?.size;
                  // const selectedColor = selectedOptions[item._id]?.color;
                  const selectedVariant = item.variants[0];
                  // item.variants?.length > 1
                  //   ? item.variants.find(
                  //       (f) => f.size === selectedSize && f.color === selectedColor
                  //     )
                  //   : item.variants[0];

                  const isCount =
                    selectedVariant && !item.canOrder
                      ? selectedVariant.count
                        ? selectedVariant.count - selectedVariant.ordersCount > 0
                        : !(selectedVariant.count == 0)
                      : true;

                  return (
                    <div key={i} className='productContainer' style={{ marginTop: i > 1 ? 30 : 5 }}>
                      {selectedVariant ? (
                        <div
                          className='productImgContainer cursor'
                          style={{ position: 'relative' }}>
                          <img
                            src={s3ToCloudFront(selectedVariant.image[0].url)}
                            alt={item.name}
                            className='productImg'
                            onClick={() => productNavigationHandler(item)}
                          />
                          <div
                            className='addToBagContainerInList'
                            onClick={(event) => event.stopPropagation()}>
                            {item.variants?.length == 1 &&
                            bag?.products?.find((e) => e.brandVariantId == selectedVariant._id) ? (
                              <div className='rowC' style={{ padding: '5px 10px' }}>
                                <RemoveCircleOutlineRounded
                                  className='cursor'
                                  htmlColor='#fff'
                                  onClick={() => {
                                    decrementHandler({
                                      productDetails: item,
                                      variant: selectedVariant,
                                      bag,
                                      dispatch,
                                      from: 'brand',
                                    });
                                    if (!isMobile) inputRefs.current[i].focus();
                                  }}
                                />
                                <input
                                  value={
                                    bag?.products?.find(
                                      (e) => e.brandVariantId == selectedVariant._id
                                    )?.count || 0
                                  }
                                  onChange={(e) => {
                                    qtyHandler({
                                      productDetails: item,
                                      variant: selectedVariant,
                                      value: e.target.value,
                                      bag,
                                      dispatch,
                                      from: 'brand',
                                    });
                                  }}
                                  autoFocus={isMobile ? false : true}
                                  ref={(el) => (inputRefs.current[i] = el)}
                                  className='qtyInput nonRestaurantInput'
                                />
                                <ControlPointRounded
                                  className='cursor'
                                  htmlColor='#fff'
                                  onClick={() => {
                                    incrementHandler({
                                      productDetails: item,
                                      variant: selectedVariant,
                                      bag,
                                      dispatch,
                                      from: 'brand',
                                    });
                                    if (!isMobile) inputRefs.current[i].focus();
                                  }}
                                />
                              </div>
                            ) : (
                              <MynyfyText
                                title={
                                  isCount
                                    ? bag?.products?.find((e) => e.brandProductId == item._id)
                                      ? 'Added'
                                      : 'Add to Bag'
                                    : 'Out Of Stock'
                                }
                                className='cursor'
                                color='#fff'
                                center
                                style={{
                                  padding: 10,
                                  cursor: isCount ? 'pointer' : 'not-allowed',
                                }}
                                onClick={() => {
                                  if (isCount) {
                                    if (item.variants?.length == 1) {
                                      addToBagHandler(item, selectedVariant);
                                    } else productNavigationHandler(item);
                                  }
                                }}
                              />
                            )}
                          </div>
                        </div>
                      ) : null}
                      <div style={{ marginLeft: 10 }}>
                        <MynyfyText title={item.name} p color={'#000000'} />
                        {/* <MynyfyText title={item.description} className='poductDescription' /> */}
                        {/* {item.variants?.length > 1 ? (
                            <div>
                              <MynyfyText
                                title='Variants'
                                color={'grey'}
                                style={{ paddingTop: 15 }}
                              />
                              <div className='row' style={{ gap: 10, flexWrap: 'wrap' }}>
                                {getUniqueAttributes({
                                  key: 'size',
                                  variants: item.variants,
                                }).map((size) => (
                                  <MynyfyText
                                    key={size}
                                    title={size}
                                    className='chipBtn'
                                    isselected={selectedSize == size ? 'true' : 'false'}
                                    isactive={isDisabled({
                                      key: 'size',
                                      value: size,
                                      selectedKey: 'color',
                                      selectedValue: selectedColor,
                                      variants: item.variants,
                                    })}
                                    onClick={() => {
                                      selectVariant(item._id, 'size', size);
                                      const reqColors = getRelatedValues({
                                        key: 'color',
                                        selectedKey: 'size',
                                        selectedValue: size,
                                        variants: item.variants,
                                      });
                                      if (!reqColors.includes(selectedColor)) {
                                        selectVariant(
                                          item._id,
                                          'color',
                                          reqColors?.[0] || undefined
                                        );
                                      }
                                    }}
                                  />
                                ))}
                              </div>
                              <div className='row' style={{ gap: 10, flexWrap: 'wrap' }}>
                                {getUniqueAttributes({
                                  key: 'color',
                                  variants: item.variants,
                                }).map((color) => (
                                  <div
                                    key={color}
                                    className='chipBtn colorChip'
                                    style={{ backgroundColor: color }}
                                    isselected={selectedColor == color ? 'true' : 'false'}
                                    isactive={isDisabled({
                                      key: 'color',
                                      value: color,
                                      selectedKey: 'size',
                                      selectedValue: selectedSize,
                                      variants: item.variants,
                                    })}
                                    onClick={() => {
                                      selectVariant(item._id, 'color', color);
                                      const reqSizes = getRelatedValues({
                                        key: 'size',
                                        selectedKey: 'color',
                                        selectedValue: color,
                                        variants: item.variants,
                                      });
                                      if (!reqSizes.includes(selectedSize)) {
                                        selectVariant(item._id, 'size', reqSizes?.[0] || undefined);
                                      }
                                    }}></div>
                                ))}
                              </div>
                            </div>
                          ) : null} */}
                        <div className='rowSB' style={{ paddingTop: 5 }}>
                          {selectedVariant ? (
                            <div>
                              <MynyfySpan
                                title={'Rs.' + selectedVariant.actualPrice}
                                style={{
                                  textDecoration:
                                    selectedVariant.price &&
                                    selectedVariant.actualPrice !== selectedVariant.price
                                      ? 'line-through'
                                      : 'unset',
                                }}
                                bold={
                                  selectedVariant.price &&
                                  selectedVariant.actualPrice !== selectedVariant.price
                                    ? false
                                    : true
                                }
                              />
                              {selectedVariant.actualPrice == selectedVariant.price ||
                              !selectedVariant.price ? null : (
                                <MynyfySpan
                                  title={'Rs.' + selectedVariant.price}
                                  bold
                                  style={{ paddingLeft: 5 }}
                                />
                              )}
                            </div>
                          ) : null}

                          {item.materialType ? (
                            item.materialType == ProductMaterialType.NON_VEGETARIAN ? (
                              <NonVegIcon width={20} height={20} />
                            ) : (
                              <VegIcon width={20} height={20} />
                            )
                          ) : null}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          {openDetailsModal ? (
            <BrandProductsDetailsModal
              id={productItem?._id}
              details={productItem}
              brandDetails={brandDetails}
              isEcommerceActive={isEcommerceActive}
              table={table}
              openModal={openDetailsModal}
              closeModal={() => setOpenDetailsModal(false)}
            />
          ) : null}
          <LocationModal
            isOpen={isLocationModal}
            close={(e) => {
              if (!_.isEmpty(location) || e) setIsLocationModal(false);
            }}
            closeBtn={() => setIsLocationModal(false)}
            content={
              'Some products may not be deliverable to every location. Set your delivery location to view serviceable products.'
            }
          />
          <ShowImages
            isVisible={openImageList}
            closeModal={() => {
              setOpenImageList(false);
              setImageList(null);
            }}
            images={imageList}
          />
          <ShowDescription
            isVisible={openDescriptionModal}
            closeModal={() => {
              setOpenDescriptionModal(false);
              setDescription(null);
            }}
            data={description}
          />
          <SelectVariantForOrder
            isOpen={openSelectVariantModal}
            close={() => {
              setOpenSelectVariantModal(false);
              setSelectedProduct(null);
            }}
            product={selectedProduct}
            addToBagHandler={addToBagHandler}
            isMobile
            navFrom={navFrom}
            brandDetails={brandDetails}
            sellerDetails={sellerDetails}
            table={table}
          />
        </Fragment>
      )}
    </Fragment>
  ) : (
    <MynyfyLoader />
  );
};

export default BrandProducts;
