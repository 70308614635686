import React, { Fragment, useState } from 'react';
import MynyfyText from '../../components/MynyfyText';
import './auth.scss';
import MynyfyInput from '../../components/MynyfyInput';
import { Card, Checkbox } from '@mui/material';
import MynyfyButton from '../../components/MynyfyButton';
import { useNavigate } from 'react-router-dom';
import MynyfySpan from '../../components/MynyfySpan';
import { useDispatch } from 'react-redux';
import validate from '../../utils/validate';
import _ from 'lodash';
import { snackbarInfo } from '../../redux/actions/snackbar.action';
import { MsgEnums } from '../../utils/MsgEnums';
import { KeyboardBackspaceRounded } from '@mui/icons-material';
import authService from '../../services/authService';
import { SyncProfile } from '../../utils/enums';
import MynyfyAlert from '../../components/MynyfyAlert';
import jwtDecode from 'jwt-decode';
import { userLogin, userToken } from '../../redux/actions/user.action';
import { RELOAD_WITH_LOC_WATCHER, WATCH_PROFILE } from '../../redux/actionTypes';
import theme from '../../utils/theme';

const VALIDATOR = {
  mobile: [(value) => (value?.length === 10 ? null : 'Please enter valid Mobile Number')],
  password: [
    (value) =>
      value?.length
        ? value?.length >= 6
          ? null
          : 'Password should be atleast 6 characters'
        : 'Please enter Password',
  ],
  otp: [(value) => (value?.length ? null : 'Please enter OTP')],
  isTerms: [(value) => (value ? null : 'Please check Terms and conditions')],
};

const SignUp = ({ from }) => {
  const [mobile, setMobile] = useState('');
  const [password, setPassword] = useState('');
  const [otp, setOTP] = useState('');
  const [isOtpSent, setOtpSent] = useState(false);
  const [isTerms, setIsTerms] = useState(false);
  const [error, setError] = useState({});
  const [isClicked, setClicked] = useState(false);
  const [submitCount, setSubmitCount] = useState(0);
  const [isOpen, setOpen] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const checkProfile = async () => {
    setClicked(true);
    let body = { mobile };
    let errors = validate(body, VALIDATOR);
    setError(errors);

    if (_.isEmpty(errors)) {
      await authService.profileExist(mobile).then((res) => {
        if (res.profile === null) {
          setOTP('');
          sendOtp();
        } else if (
          res.profile.syncedWith === SyncProfile.BUYER ||
          res.profile.syncedWith === SyncProfile.BUYER_SELLER ||
          res.profile.syncedWith === SyncProfile.BUYER_PARTNER
        ) {
          snackbarHandler({
            type: 'warning',
            message: ['This mobile number is already registed', 'Please login'],
          });
          navigate('/SignIn', { state: mobile });
        } else if (
          res.profile.syncedWith === SyncProfile.PARTNER ||
          res.profile.syncedWith === SyncProfile.SELLER
        ) {
          setClicked(false);
          snackbarHandler({
            type: 'warning',
            message: [
              `This mobile number is registed with ${res.profile.syncedWith}`,
              'Account Sync feature will be comming soon',
              'Please login with new number',
            ],
          });
        }
      });
    } else {
      setClicked(false);
      snackbarHandler({ type: 'warning', message: MsgEnums.FILL_DETAILS });
    }
  };

  const sendOtp = () => {
    let body = { mobile };
    let errors = validate(body, VALIDATOR);
    setError(errors);

    if (_.isEmpty(errors)) {
      authService.sendOtp(mobile).then((res) => {
        setClicked(false);
        if (res) {
          setOtpSent(true);
          snackbarHandler({ type: 'success', message: 'OTP has been sent successfully' });
        } else {
          snackbarHandler({ type: 'error', message: 'Failed to send OTP' });
        }
      });
    } else {
      snackbarHandler({ type: 'warning', message: MsgEnums.FILL_DETAILS });
      setClicked(false);
    }
  };

  const registerHandler = async () => {
    let body = { mobile, password, otp, isTerms };
    let errors = validate(body, VALIDATOR);
    setError(errors);

    if (_.isEmpty(errors)) {
      setSubmitCount(submitCount + 1);
      if (submitCount > 2) {
        setOpen(true);
      } else {
        let body = {
          profile: { mobile: mobile, password: password },
          otp: otp,
        };
        await authService
          .register(body)
          .then((res) => {
            if (res.data.message === 'Profile Created Successfully') {
              loginHandler();
              snackbarHandler({
                type: 'success',
                message: 'Your account has been created successfully',
              });
            } else if (res.data.message === 'OTP Verification Failure.') {
              snackbarHandler({
                type: 'error',
                message: ['OTP Verification Failed', 'please enter correct OTP'],
              });
            } else {
              snackbarHandler({
                type: 'error',
                message: [MsgEnums.SERVER_ISSUE, MsgEnums.TRY_AGAIN_LATER],
              });
            }
          })
          .catch((err) => {
            snackbarHandler({
              type: 'error',
              message: [MsgEnums.SERVER_ISSUE, MsgEnums.TRY_AGAIN_LATER],
            });
          });
      }
    } else {
      snackbarHandler({ type: 'warning', message: MsgEnums.FILL_DETAILS });
    }
  };

  const loginHandler = () => {
    let body = { mobile, password };
    let errors = validate(body, VALIDATOR);

    if (_.isEmpty(errors)) {
      authService
        .login(body)
        .then(async (res) => {
          if (res.data.message === 'Authenticated!') {
            await authService._setToken(res.data.token);
            const user = jwtDecode(res.data.token);
            dispatch(userToken(res.data.token));
            // TODO location api call
            // if (location?.state?._id && location?.city?._id && location?.area?._id) {
            //   let data = {
            //     state: location?.state?._id,
            //     city: location?.city?._id,
            //     area: location?.area?._id,
            //   };
            //   await profileService.profileUpdate(data, user.id, user.profile);
            // }
            // dispatch({ type: RELOAD_WATCHER });
            dispatch({ type: RELOAD_WITH_LOC_WATCHER });
            dispatch({ type: WATCH_PROFILE });
            snackbarHandler({ type: 'success', message: 'Logged In Successful!' });
            dispatch(userLogin(user));
            navigate('/');
            window.location.reload();
          } else {
            navigate('/SignIn');
          }
        })
        .catch((err) => {
          snackbarHandler({
            type: 'error',
            message: ['Unable to login automatically', 'Please login on your own'],
          });
        });
    } else {
      snackbarHandler({
        type: 'error',
        message: ['Unable to login automatically', 'Please login on your own'],
      });
    }
  };

  const snackbarHandler = ({ type, message }) => {
    dispatch(snackbarInfo({ open: true, type, message }));
  };

  return (
    <div id='auth'>
      <KeyboardBackspaceRounded className='backBtn' onClick={() => navigate(-1)} />
      <img
        src={require('../../assets/imgs/LandingPage/mynyfy_White.png')}
        alt='Mynyfy'
        className='logo'
      />
      <MynyfyText title={'Sign Up'} color={'#fff'} h6 />
      <Card className='container' style={{ width: from == 'web' ? '350px' : '' }}>
        <MynyfyInput
          fullWidth={true}
          placeHolder={'Phone Number'}
          value={mobile}
          onChange={(e) => setMobile(e.target.value)}
          helperText={error?.mobile}
          error={error?.mobile ? true : false}
        />
        {isOtpSent ? (
          <Fragment>
            <MynyfyInput
              fullWidth={true}
              placeHolder={'OTP'}
              value={otp}
              onChange={(e) => setOTP(e.target.value)}
              helperText={error?.otp}
              error={error?.otp ? true : false}
            />
            <MynyfyInput
              fullWidth={true}
              placeHolder={'Set Password'}
              type={'password'}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              helperText={error?.password}
              error={error?.password ? true : false}
            />
            <div className='row' style={{ padding: '10px 0px' }}>
              <Checkbox
                checked={isTerms}
                style={{ color: error?.isTerms ? 'red' : theme.PRIMARY_BLUE }}
                onClick={() => setIsTerms(!isTerms)}
              />
              <div>
                <MynyfySpan title={'I agree to '} pSmall />
                <MynyfySpan
                  title={'Terms & Conditions and Data Privacy Policies'}
                  link
                  onClick={() => {
                    navigate('/Policies/Terms&Conditions', { state: 'Terms & Conditions' });
                  }}
                  pSmall
                />
              </div>
            </div>
          </Fragment>
        ) : null}
        <MynyfyButton
          label={isOtpSent ? 'Sign Up' : 'Verify'}
          fullWidth={true}
          disabled={isClicked}
          onClick={() => (isOtpSent ? registerHandler() : checkProfile())}
        />
      </Card>
      <MynyfyAlert
        open={isOpen}
        close={() => setOpen(false)}
        title={'Sorry! You have entered wrong OTP many times, please try again after sometime'}
        action1={{ label: 'Close', handler: () => setOpen(false), color: 'black' }}
        action2={{
          label: 'Resend OTP',
          handler: () => {
            setOTP('');
            setSubmitCount(0);
            setPassword('');
            sendOtp();
          },
        }}
      />
    </div>
  );
};

export default SignUp;
