import React from 'react';
import MyntsTxn from './MyntsTxn';
import PaymentTxn from './PaymentTxn';
import MynyfyTab from '../../components/MynyfyTab';
import { useLocation } from 'react-router-dom';
import WebMyntsTxn from './WebMyntsTxn';

const WebTransactions = () => {
  const { state } = useLocation();

  return (
    <MynyfyTab
      // tabIndex={state}
      noSwipe={true}
      tabs={['Mynts']}
      tabComponent={[<WebMyntsTxn />]}
    />
  );
};

export default WebTransactions;
