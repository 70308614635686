import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './transactions.scss';
import _ from 'lodash';
import moment from 'moment';
import MynyfyText from '../../components/MynyfyText';
import MynyfySpan from '../../components/MynyfySpan';
import transactionService from '../../services/transactionService';
import { snackbarInfo } from '../../redux/actions/snackbar.action';
import { ContestType, OpportunityType, TransactionStatus } from '../../utils/enums';
import MynyfyLoader from '../../components/MynfyfLoader';
import MynyfyEmptyMsg from '../../components/MynyfyEmptyMsg';
import theme from '../../utils/theme';

const PAGE_LIMIT = 30;

const PaymentTxn = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const id = user.user?.id || null;

  const [txns, setTxns] = useState(null);
  const [canInfinity, setCanInfinity] = useState(true);
  const [isInfinityApiCall, setIsInfinityApiCal] = useState(false);

  // Add event listener for scrolling
  useEffect(() => {
    // Function to check if the user has reached the bottom of the page (80%)
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const scrollHeight = document.documentElement.scrollHeight;
      const clientHeight = document.documentElement.clientHeight;
      if (scrollY + clientHeight >= scrollHeight * 0.8 && isInfinityApiCall) {
        infinityScrolling();
      }
    };

    // Adjust the debounce time as needed
    const debounceScroll = debounce(handleScroll, 500);

    window.addEventListener('scroll', debounceScroll);
    return () => {
      window.removeEventListener('scroll', debounceScroll);
    };
  }, [isInfinityApiCall]);

  // Debounce function to delay the infinity API call
  const debounce = (func, delay) => {
    let timer;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(timer);
      timer = setTimeout(() => func.apply(context, args), delay);
    };
  };

  useEffect(() => {
    let params = { pageLimit: PAGE_LIMIT };
    getTxns(params);
  }, []);

  const getTxns = (params) => {
    transactionService.getBuyerPaymentTxns(id, params).then((res) => {
      if (res.success) {
        let sorted = _.orderBy(res.data, 'createdAt', 'desc');
        setTxns(sorted);
        setIsInfinityApiCal(true);
        if (sorted.length < PAGE_LIMIT) setCanInfinity(false);
      } else {
        setTxns([]);
        setCanInfinity(false);
        setIsInfinityApiCal(true);
      }
    });
  };

  const txnDetails = (service) => {
    let type, name, description, orderIds;
    if (Array.isArray(service)) {
      type = service[0]?.type;
      name = service[0]?.name;
      description = service[0]?.description;
      orderIds = service?.map((e) => e?.orderId).filter((e) => e);
    } else {
      type = service.type;
      name = service.name;
      description = service.description;
      orderIds = [service.orderId];
    }

    if (type == ContestType.SEE_AND_WIN) {
      return 'For Publishing ' + name + ' Ad';
    } else if (type == OpportunityType.ANNOUNCEMENT) {
      return 'For Publishing ' + description + ' Flash Ad';
    } else if (!_.isEmpty(orderIds)) {
      return 'For Placing Order #' + orderIds?.join(', #');
    } else {
      return null;
    }
  };

  const txnStatus = (status) => {
    if (status == TransactionStatus.SUCCESS) {
      return { status: 'Success', color: 'red' };
    } else if (status == TransactionStatus.FAILED) {
      return { status: 'Failed', color: 'grey' };
    } else if (status == TransactionStatus.PENDING) {
      return { status: 'In Progress', color: theme.WARNING };
    } else if (status == TransactionStatus.REFUNDED) {
      return { status: 'Completed', color: 'green' };
    } else if (status == TransactionStatus.REFUND_FAILED) {
      return { status: 'In Progress', color: theme.WARNING };
    } else {
      return { status: '', color: 'grey' };
    }
  };

  const txnType = (status) => {
    if (
      status == TransactionStatus.SUCCESS ||
      status == TransactionStatus.FAILED ||
      status == TransactionStatus.PENDING
    ) {
      return 'Debit';
    } else if (status == TransactionStatus.REFUNDED || status == TransactionStatus.REFUND_FAILED) {
      return 'Refund';
    } else {
      return '';
    }
  };

  const infinityScrolling = () => {
    let reqTxns = txns;
    if (canInfinity && isInfinityApiCall && reqTxns) {
      setIsInfinityApiCal(false);
      let params = { pageLimit: PAGE_LIMIT, timeCutoff: reqTxns?.[reqTxns?.length - 1].createdAt };
      transactionService
        .getBuyerPaymentTxns(id, params)
        .then((res) => {
          if (res.success) {
            if (_.isEmpty(res.data)) {
              setCanInfinity(false);
            } else {
              let sorted = _.orderBy(res.data, 'createdAt', 'desc');
              setTxns([...reqTxns, ...sorted]);
              setCanInfinity(true);
            }
          } else {
            setTxns([]);
            dispatch(snackbarInfo({ type: 'error', open: true, message: res.message }));
          }
        })
        .finally(() => setIsInfinityApiCal(true));
    }
  };

  return txns ? (
    _.isEmpty(txns) ? (
      <MynyfyEmptyMsg message={'No Transactions found'} />
    ) : (
      <div id='Transactions'>
        <div className='txnContainer'>
          {txns?.map((item, i) => (
            <div
              key={i}
              className='txnCard'
              style={{ borderLeftColor: txnStatus(item.status).color }}>
              {item.serviceId || item.serviceIds ? (
                <MynyfyText title={txnDetails(item.serviceId || item.serviceIds)} />
              ) : null}
              <div className='rowSB' style={{ paddingTop: 7 }}>
                <div>
                  <MynyfySpan title={'Amount:'} className='subHeader' small />
                  &ensp;
                  <MynyfySpan title={item.amount} className='value' />
                </div>
                <MynyfyText title={txnType(item.status)} className='value' small />
              </div>
              <div className='rowSB' style={{ paddingTop: 7 }}>
                <div>
                  <MynyfySpan title={'Date:'} className='subHeader' small />
                  &ensp;
                  <MynyfySpan
                    title={moment(item.txnDate).format('DD MMM YYYY')}
                    className='value'
                    small
                  />
                </div>
                <MynyfyText title={txnStatus(item.status).status} className='value' small />
              </div>
            </div>
          ))}
          {canInfinity ? (
            <div style={{ padding: '20px' }}>
              <div className='loader' />
            </div>
          ) : null}
        </div>
      </div>
    )
  ) : (
    <MynyfyLoader />
  );
};

export default PaymentTxn;
