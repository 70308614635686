import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './WebTransactions.scss';
import _ from 'lodash';
import moment from 'moment';
import transactionService from '../../services/transactionService';
import MynyfyText from '../../components/MynyfyText';
import MynyfySpan from '../../components/MynyfySpan';
import { snackbarInfo } from '../../redux/actions/snackbar.action';
import MynyfyLoader from '../../components/MynfyfLoader';
import MynyfyEmptyMsg from '../../components/MynyfyEmptyMsg';
import { useNavigate } from 'react-router';
import WebMynyfyHeader from '../../components/WebMynyfyHeader';
import theme from '../../utils/theme';
import { onShare } from '../Refer/referHelper';

const PAGE_LIMIT = 30;

const WebMyntsTxn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector((state) => state.user);
  const id = user.user?.id || null;
  const mynts = useSelector((state) => state.mynts);
  const profile = user.profile;

  const [txns, setTxns] = useState(null);
  const [canInfinity, setCanInfinity] = useState(true);
  const [isInfinityApiCall, setIsInfinityApiCal] = useState(false);

  // Add event listener for scrolling
  useEffect(() => {
    // Function to check if the user has reached the bottom of the page (80%)
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const scrollHeight = document.documentElement.scrollHeight;
      const clientHeight = document.documentElement.clientHeight;
      if (scrollY + clientHeight >= scrollHeight * 0.8 && isInfinityApiCall) {
        infinityScrolling();
      }
    };

    // Adjust the debounce time as needed
    const debounceScroll = debounce(handleScroll, 500);

    window.addEventListener('scroll', debounceScroll);
    return () => {
      window.removeEventListener('scroll', debounceScroll);
    };
  }, [isInfinityApiCall]);

  // Debounce function to delay the infinity API call
  const debounce = (func, delay) => {
    let timer;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(timer);
      timer = setTimeout(() => func.apply(context, args), delay);
    };
  };

  useEffect(() => {
    let params = { pageLimit: PAGE_LIMIT };
    getTxns(params);
  }, []);

  const getTxns = (params) => {
    transactionService.getTransactions(id, params).then((res) => {
      if (res.success) {
        let filteredData = res.data.filter(
          (e) => !(e?.mode == 'Referral' && e?.txnFrom?._id == id)
        );
        let sorted = _.orderBy(filteredData, 'createdAt', 'desc');
        setTxns(sorted);
        setIsInfinityApiCal(true);
        if (sorted.length < PAGE_LIMIT) setCanInfinity(false);
      } else {
        setTxns([]);
        setCanInfinity(false);
        setIsInfinityApiCal(true);
      }
    });
  };

  const txnColor = (txn) => {
    if ((txn.txnTo && txn.txnTo._id == id) || txn.status == 'Refunded') return 'green';
    else return 'red';
  };

  const txnType = (txn) => {
    if (txn.txnTo && txn.txnTo._id == id) return 'Mynts Earned:';
    else if (txn.status == 'Refunded') return 'Mynts Refunded';
    else return 'Mynts Spent:';
  };

  const infinityScrolling = () => {
    let reqTxns = txns;
    if (canInfinity && isInfinityApiCall && reqTxns) {
      setIsInfinityApiCal(false);
      let params = { pageLimit: PAGE_LIMIT, timeCutoff: reqTxns?.[reqTxns?.length - 1].createdAt };
      transactionService
        .getTransactions(id, params)
        .then((res) => {
          if (res.success) {
            if (_.isEmpty(res.data)) {
              setCanInfinity(false);
            } else {
              let filteredData = res.data.filter(
                (e) => !(e?.mode == 'Referral' && e?.txnFrom?._id == id)
              );
              let sorted = _.orderBy(filteredData, 'createdAt', 'desc');
              setTxns([...reqTxns, ...sorted]);
              setCanInfinity(true);
            }
          } else {
            setTxns([]);
            dispatch(snackbarInfo({ type: 'error', open: true, message: res.message }));
          }
        })
        .finally(() => setIsInfinityApiCal(true));
    }
  };

  return txns ? (
    <div id='WebTransactions'>
      <div className='txnContainer'>
        <div>
          <MynyfyText title={'How to Earn Mynts'} bold style={{ fontSize: '1.2rem' }} />
          <ul className='steps'>
            <li className='step' onClick={() => navigate('/WebPosts/')}>
              <div className='step-icon' style={{ background: theme.PRIMARY_BLUE }}>
                1
              </div>
              <div className='step-content'>
                For{' '}
                <MynyfySpan
                  style={{ color: theme.PRIMARY_BLUE, fontSize: '1.2rem' }}
                  title={'Seeing Ads'}
                />
              </div>
            </li>
            <li className='step' onClick={() => navigate('/WebShoppingLayout')}>
              <div className='step-icon' style={{ background: theme.PRIMARY_BLUE }}>
                2
              </div>
              <div className='step-content'>
                For{' '}
                <MynyfySpan
                  style={{ color: theme.PRIMARY_BLUE, fontSize: '1.2rem' }}
                  title={'Shopping with any Seller'}
                />
              </div>
            </li>
            <li className='step' onClick={() => onShare(profile?.referralCode)}>
              <div className='step-icon' style={{ background: theme.PRIMARY_BLUE }}>
                3
              </div>
              <div className='step-content'>
                For{' '}
                <MynyfySpan
                  style={{ color: theme.PRIMARY_BLUE, fontSize: '1.2rem' }}
                  title={'Referring Friends'}
                />
              </div>
            </li>
          </ul>
          <div className='row' style={{ margin: '25px 20px 20px' }}>
            <MynyfyText title={'Note :'} bold pBig />
            <MynyfyText
              title={
                '* Mynts cannot be withdrawn as money. You may use them to purchase deals and join jackpots.'
              }
              color={theme.MUTED_BLUE}
              pSmall
            />
          </div>
        </div>

        {!_.isEmpty(txns) && (
          <div className='txnContainer'>
            <MynyfyText title={'History'} bold style={{ fontSize: '1.2rem' }} />
          </div>
        )}

        {_.isEmpty(txns) ? (
          <MynyfyEmptyMsg message={'No Transactions found'} style={{ marginTop: '-220px' }} />
        ) : (
          txns.map((item, i) => (
            <div
              className={
                item.mode == 'Product Order' && item?.txnFrom?.orderId
                  ? 'txnCard cursor'
                  : 'txnCard'
              }
              style={{ borderLeftColor: txnColor(item) }}
              key={i}
              onClick={() => {
                if (item.mode == 'Product Order' && item?.txnFrom?.orderId) {
                  navigate('/Orders/' + item.txnFrom?.orderId, {
                    state: { orderId: item.txnFrom?.orderId },
                  });
                }
              }}>
              <MynyfyText title={item.details} p />
              <div className='rowSB' style={{ paddingTop: 15 }}>
                <div>
                  <MynyfySpan title={'Date:'} className='subHeader' small />
                  &ensp;
                  <MynyfySpan
                    title={moment(item.txnDate).format('DD MMM YYYY')}
                    className='value'
                    small
                  />
                </div>
                <div>
                  <MynyfySpan title={txnType(item)} className='subHeader' small />
                  &ensp;
                  <MynyfySpan title={item.points} className='value' small />
                </div>
              </div>
            </div>
          ))
        )}

        {canInfinity && (
          <div style={{ padding: '20px' }}>
            <div className='loader' />
          </div>
        )}
      </div>
    </div>
  ) : (
    <MynyfyLoader />
  );
};

export default WebMyntsTxn;
