import _ from 'lodash';
import { PaymentGateways } from './enums';
import moment from 'moment';
import { SiteUrls } from './MynyfyEnums';
import storeService from '../services/storeService';
import brandService from '../services/brandService';

export const objectReplace = (objects, newObj, key) => {
  return objects.map((obj) => (obj[key] === newObj[key] ? newObj : obj));
};

export const removeObject = (objects, newObj, key) => {
  return objects.filter((obj) => obj[key] !== newObj[key]);
};

export const assignObject = (src, tar) => {
  return _.assign(src, tar);
};

export const setObject = (target, path, value) => {
  _.set(target, path, value);
  return target;
};

export const mobileNumberManipulation = (mobile) => {
  mobile = mobile.toString();
  let mobileArr = mobile.split('');
  let result = mobileArr.map((res, i) => {
    return i % 2 === 0 ? res : '*';
  });
  return result;
};

export const arrayContainsElement = (superset, subset) => {
  if (0 === subset.length) {
    return false;
  }
  return subset.some((e) => superset.includes(e));
};

export const arrayContainsArray = (superset, subset) => {
  if (0 === subset.length) {
    return false;
  }
  return subset.every((e) => superset.includes(e));
};

export const allEqual = (arr) => arr.every((val) => val === arr[0]);

export const getGreeting = () => {
  const d = new Date();
  const time = d.getHours();

  if (time < 12) {
    return 'Good Morning!';
  } else if (time > 12 && time < 17) {
    return 'Good Afternoon!';
  } else {
    return 'Good Evening!';
  }
};

export const getOS = () => {
  const platform = navigator.platform;
  const userAgent = navigator.userAgent;
  const macPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
  const iosPlatforms = ['iPhone', 'iPad', 'iPod'];

  if (macPlatforms.indexOf(platform) !== -1) {
    return 'mac';
  } else if (iosPlatforms.indexOf(platform) !== -1 || userAgent.match(/iPad|iPhone|iPod/)) {
    return 'ios';
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    return 'windows';
  } else if (/Android/.test(userAgent)) {
    return 'android';
  } else if (/Linux/.test(platform)) {
    return 'linux';
  }
  return 'unknown';
};

export const merchantTxnId = ({ userId, mobile, gateway }) => {
  let userIdPart = userId?.slice(-5);
  let mobilePart = mobile?.toString()?.slice(-5);
  return gateway === PaymentGateways.SAB_PAISA
    ? `MYMT${userIdPart}${mobilePart}${Date.now()}`
    : `MYMT-${userIdPart}-${mobilePart}-${Date.now()}`;
};

export const sabpaisaPaymentGateway = ({ encData, loader }) => {
  if (encData) {
    window.open(
      `${process.env.REACT_APP_PG_API_HOST}/sabpaisaPayInPaymentRequest?encStr=${encData}`
    );
  } else {
    alert('Please check the payment details');
    loader(false);
  }
};

export const storeTimingHandler = (data) => {
  if (data?.storeOpenTime && data?.storeCloseTime) {
    let openTime = moment(data?.storeOpenTime, 'h A');
    let closeTime = moment(data?.storeCloseTime, 'h A');
    let isOpened = moment().isBetween(openTime, closeTime);
    return isOpened;
  } else {
    return true;
  }
};

export function formatToMoney(value = 0) {
  // Converting the value to a string if given as number
  let [integerPart, decimalPart] = value.toString().split('.');
  // Formating the integer part (before decimal)
  let lastThreeDigits = integerPart.slice(-3);
  let otherDigits = integerPart.slice(0, -3);
  if (otherDigits !== '') {
    lastThreeDigits = ',' + lastThreeDigits;
  }
  // We are adding commas after every two digits in the remaining part
  let formattedInteger = otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ',') + lastThreeDigits;
  // If there is a decimal part in given value, we will append it, otherwise, we will return the formatted integer part
  return decimalPart ? `${formattedInteger}.${decimalPart}` : formattedInteger;
}

export const youtubeVideoId = (url) => {
  let domine = url?.includes('youtu.be');
  let a = url?.split('?');
  let b = domine ? a?.[0].split('/') : a?.[1].split('&');
  let videoId = domine ? b?.[b.length - 1] : b?.[0].split('v=')?.[1];
  return videoId;
};

export const priceClaculation = ({ price, gst }) => {
  if (gst) {
    let reqPrice = (price / (1 + Number(gst?.data || gst) / 100)).toFixed(2);
    return reqPrice;
  } else {
    return price;
  }
};

export const s3ToCloudFront = (url) => {
  if (typeof url == 'string' && url) {
    let s3Url = url;
    let s3Domain = process.env.REACT_APP_S3_BUCKET_DOMIN;
    let cloudFrontUrl = process.env.REACT_APP_S3_CLOUD_FRONT;
    if (s3Url.includes(s3Domain)) s3Url = s3Url.replace(s3Domain, cloudFrontUrl);
    return s3Url;
  } else return '';
};

export function truncateString(str, cutOffAt = 25) {
  if (str == undefined || str == null) {
    return '';
  }
  if (str.length > cutOffAt) {
    return str.substring(0, cutOffAt) + '...';
  }
  return str;
}

export const webSiteCheck = async () => {
  const origin = window.location.origin;
  const isMynyfy = SiteUrls.some((url) => origin.includes(url));

  let platorm = getOS();
  let isMobile = ['android', 'ios'].includes(platorm) ? true : false;

  const parsedUrl = new URL(origin);

  let siteKey;
  let siteUrl;
  let noData = false;
  let storeData = null;
  let brandData = null;

  if (!isMynyfy) {
    if (!parsedUrl.origin.includes('.mynyfy.com')) {
      siteUrl = parsedUrl.host;
      if (siteUrl.startsWith('www.')) siteUrl = siteUrl.slice(4);
    } else {
      let hostname = parsedUrl.hostname;
      if (hostname.startsWith('www.')) hostname = hostname.substring(4);
      siteKey = hostname.split('.')[0];
    }

    let body = {};
    if (siteKey) body.urlId = siteKey;
    if (siteUrl) body.domain = siteUrl;

    let storeResponse = await storeService.getStores(body);
    if (storeResponse.success) {
      if (!_.isEmpty(storeResponse?.data?.orgList)) storeData = storeResponse.data.orgList?.[0];
    }

    if (!storeData) {
      let brandResponse = await brandService.getBrandById(body);
      if (brandResponse.success) {
        if (_.isEmpty(brandResponse.data)) noData = true;
        else brandData = brandResponse.data;
      } else noData = true;
    }
  }

  return { isMobile, isMynyfy, storeData, brandData, noData };
};

export const openUrl = (url) => {
  if (url) {
    const absoluteUrl =
      url.startsWith('http://') || url.startsWith('https://') ? url : `https://${url}`;
    window.open(absoluteUrl, '_blank', 'noopener,noreferrer');
  }
};

export const videoUrl = (url) => {
  if (url) {
    const isYouTube = url.includes('youtube.com') || url.includes('youtu.be');
    const isGoogleDrive = url.includes('drive.google.com');
    const isS3 = url.includes('s3.amazonaws.com') || url.includes('cloudfront.net');

    if (isYouTube || isGoogleDrive || isS3) {
      if (isYouTube) {
        let newUrl = getYouTubeEmbedUrl(url);

        return newUrl
          ? { url: newUrl, type: url.includes('/shorts/') ? 'Youtube Short' : 'Youtube' }
          : openUrl(url);
      } else if (isGoogleDrive) {
        let newUrl = getGoogleDriveEmbedUrl(url);
        return newUrl ? { url: newUrl, type: 'Google Drive' } : openUrl(url);
      } else if (isS3) {
        return { url, type: 'S3' };
      }
    } else {
      openUrl(url);
    }
  }
};

const getYouTubeEmbedUrl = (url) => {
  const match = url.match(
    /(?:youtube\.com\/(?:shorts\/|(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=))|youtu\.be\/)([^"&?\/\s]{11})/
  );
  return match ? `https://www.youtube.com/embed/${match[1]}?autoplay=1` : null;
};

const getGoogleDriveEmbedUrl = (url) => {
  const match = url.match(/(?:drive\.google\.com\/file\/d\/|uc\?id=)([a-zA-Z0-9_-]+)/);
  return match ? `https://drive.google.com/file/d/${match[1]}/preview` : null;
};
