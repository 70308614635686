import React from 'react';
import MynyfyText from '../../components/MynyfyText';
import { Card } from '@mui/material';
import './AuthLanding.scss';
import WebHomeLayout from '../Home/WebHomeLayout';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

const AuthLanding = () => {
  const navigate = useNavigate();

  return (
    <div>
      <div id='AuthLanding'>
        <div style={{ position: 'relative', height: 61, zIndex: 1 }}>
          <Card className='rowSB topBar'>
            <img
              src={require('../../assets/imgs/LandingPage/mynyfy.png')}
              className='logo'
              alt='Mynyfy'
            />

            <MynyfyText
              title={'Login / SignUp'}
              pBig
              link
              style={{ padding: 8.5 }}
              onClick={() => navigate('SignIn')}
            />
          </Card>
        </div>
        <div className='brandsSection' id='webHome'>
          <WebHomeLayout />
        </div>
      </div>
    </div>
  );
};

export default AuthLanding;
