import React from 'react';
import './promos.scss';
import Promos from '.';
import { PromoType } from '../../utils/enums';
import { useSelector } from 'react-redux';
import {
  fetchCoupons,
  fetchFoodCoupons,
  fetchSortedCoupons,
  fetchSortedFoodCoupons,
} from '../../redux/actions/promo.action';
import { useLocation } from 'react-router';

const Coupons = ({ goHome, screen }) => {
  const route = useLocation();

  const promos = useSelector((state) => state.promos?.coupons?.actual);
  const sortedPromos = useSelector((state) => state.promos?.coupons?.sorted);
  const foodPromos = useSelector((state) => state.promos?.foodCoupons?.actual);
  const sortedFoodPromos = useSelector((state) => state.promos?.foodCoupons?.sorted);

  const isRestaurant = route?.state?.isRestaurant || false;

  return (
    <Promos
      type={[PromoType.COUPON, PromoType.POINT_COUPON]}
      isFilter={false}
      emptyListTitle={'Coupons'}
      emptyListMsg={[
        "You don't have any Coupons",
        'Get Coupons by participating',
        'in Contests & Jackpots',
      ]}
      emptyListMsgBold={[1, 2]}
      promos={isRestaurant ? foodPromos : promos}
      sortedPromos={isRestaurant ? sortedFoodPromos : sortedPromos}
      action={isRestaurant ? fetchFoodCoupons : fetchCoupons}
      sortedAction={isRestaurant ? fetchSortedFoodCoupons : fetchSortedCoupons}
      isRestaurant={isRestaurant}
      goHome={goHome || null}
      screen={screen || null}
    />
  );
};

export default Coupons;
