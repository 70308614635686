import React, { Fragment, useRef, useState } from 'react';
import MynyfyText from '../../components/MynyfyText';
import MynyfyInput from '../../components/MynyfyInput';
import MynyfyHeader from '../../components/MynyfyHeader';
import MynyfyButton from '../../components/MynyfyButton';
import MynyfySelect from '../../components/MynyfySelect';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import MynyfyUploadImg from '../../components/MynyfyUploadImg';
import { snackbarInfo } from '../../redux/actions/snackbar.action';
import commonService from '../../services/commonService';
import _ from 'lodash';
import validate from '../../utils/validate';
import { OpportunityType, PaymentGateways, TransactionType } from '../../utils/enums';
import { fileUploadToS3WD } from '../../utils/S3Uploader';
import opportunityService from '../../services/opportunityService';
import { MsgEnums } from '../../utils/MsgEnums';
import { useEffect } from 'react';
import theme from '../../utils/theme';
import { MynyfyEnums } from '../../utils/MynyfyEnums';
import { merchantTxnId } from '../../utils/CommonMethods';
import { AES } from 'crypto-js';
import paymentService from '../../services/paymentService';
import { RazorPayOptions } from '../../payments/RazorPay';
import MynyfySpan from '../../components/MynyfySpan';
import MynyfyPre from '../../components/MynyfyPre';
import WebMynyfyHeader from '../../components/WebMynyfyHeader';

let VALIDATOR = {
  image: [(value) => (_.isEmpty(value) ? `Please upload Ad Image` : null)],
  description: [(value) => (_.isEmpty(value) ? `Please enter Description` : null)],
  selectedCategory: [(value) => (_.isEmpty(value) ? `Please select Category` : null)],
  selectedPricePlan: [(value) => (_.isEmpty(value) ? `Please select Ad Plan` : null)],
};

const WebCreateFlashAds = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const paymentDetailsRef = useRef();

  const user = useSelector((state) => state.user?.user);
  const categories = useSelector((state) => state.appData?.categories);
  const states = useSelector((state) => state.appData?.states);

  const [description, setDescription] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedArea, setSelectedArea] = useState(null);
  const [cities, setCities] = useState([]);
  const [areas, setAreas] = useState([]);
  const [pricePlans, setPricePlans] = useState([]);
  const [selectedPricePlan, setSelectedPricePlan] = useState(null);
  const [websiteUrl, setWebsiteUrl] = useState(null);
  const [appUrl, setAppUrl] = useState(null);
  const [mobileNumber, setMobileNumber] = useState(null);
  const [image, setImage] = useState(null);
  const [isClicked, setClicked] = useState(false);
  const [error, setError] = useState({});

  useEffect(() => {
    let params = { isActive: true, type: 'Subscription', code: 'FlashAd', all: true };
    commonService.appDataByAny(params).then((res) => {
      if (res.success) {
        let moded = res.data?.map((e) => ({
          label: theme.RUPEE + '' + e.quotePoints + ' / ' + e.name,
          value: e._id,
          data: { ...e, data: Number(e.data) },
        }));
        let sorted = _.orderBy(moded, 'data.data', 'asc');
        setPricePlans(sorted);
      } else {
        dispatch(snackbarInfo({ open: true, type: 'error', message: res.message }));
      }
    });
  }, []);

  // Sabpaisa callback
  // useEffect(() => {
  //   const messageHandler = (event) => {
  //     if (paymentDetailsRef.current?.isInitiated && paymentDetailsRef.current?.txnId) {
  //       if (
  //         event.data &&
  //         typeof event.data === 'string' &&
  //         !event.data?.includes('webpackHotUpdate')
  //       ) {
  //         let response = JSON.parse(event.data);
  //         if (
  //           response &&
  //           response?.clientTxnId === paymentDetailsRef.current?.txnId &&
  //           response.userId === user.id
  //         ) {
  //           if (response.status === 'SUCCESS' || response.statusCode === '0000') {
  //             console.log(paymentDetailsRef.current?.body);
  //             submitHandler({ txnId: response.txnId, flashAdBody: paymentDetailsRef.current?.body });
  //           } else {
  //             dispatch(
  //               snackbarInfo({
  //                 open: true,
  //                 type: 'error',
  //                 message: [
  //                   'Sorry!, your transaction is ' + response.status.toLowerCase(),
  //                   'If any amount is debited please contact support',
  //                 ],
  //               })
  //             );
  //           }
  //         } else if (response?.clientTxnId && paymentDetailsRef.current?.txnId) {
  //           paymentDetailsRef.current = null;
  //           dispatch(
  //             snackbarInfo({
  //               open: true,
  //               type: 'error',
  //               message: [
  //                 'Sorry!, something went wrong',
  //                 'If any amount is debited please contact support',
  //               ],
  //             })
  //           );
  //         }
  //       } else {
  //         paymentDetailsRef.current = null;
  //       }
  //     } else {
  //       paymentDetailsRef.current = null;
  //     }
  //   };
  //   window.addEventListener('message', messageHandler);
  //   return () => {
  //     window.removeEventListener('message', messageHandler);
  //   };
  // }, []);

  const uploadHandler = (event) => {
    if (event.imgFile.size <= 524288) {
      event.purposeType = 'Opportunities';
      setImage(event);
    } else {
      dispatch(
        snackbarInfo({ open: true, type: 'warning', message: 'File size must not exceed 5MB' })
      );
    }
  };

  const handelSelectorChange = (key, event, saveData, nextSetData) => {
    saveData(event);

    if (key === 'state') {
      setSelectedCity(null);
      setCities([]);
    }
    if (['state', 'city'].includes(key)) {
      setSelectedArea(null);
      setAreas([]);
    }

    if (Array.isArray(event)) {
      if (event.length > 1) {
        // Do Nothing
      } else if (!_.isEmpty(event)) {
        appDataApi(event[0].data, nextSetData);
      }
    } else {
      if (!_.isEmpty(event)) {
        appDataApi(event.data, nextSetData);
      }
    }
  };

  const appDataApi = (key, setData) => {
    commonService.publicCategorySort(key).then((res) => {
      let sortedData = _.orderBy(res, ['name'], ['asc']);
      setData(sortedData);
    });
  };

  const validationHandler = () => {
    let obj = { description, image, selectedPricePlan };
    const errors = validate(obj, VALIDATOR);
    setError(errors);
    if (_.isEmpty(errors)) {
      let amount = selectedPricePlan.data?.quotePoints;
      if (amount) {
        setClicked(true);
        let finalAmount = amount + amount * (MynyfyEnums.GST || 0);
        initiateRazorPayPayment({ amount: finalAmount });
      } else {
        dispatch(
          snackbarInfo({ opne: true, type: 'error', message: 'Please select a flash Ad plan' })
        );
      }
    } else {
      dispatch(snackbarInfo({ open: true, type: 'warning', message: MsgEnums.FILL_DETAILS }));
    }
  };

  const initiateSabpaisaPayment = ({ amount }) => {
    let myTxnId = merchantTxnId({
      userId: user.id,
      mobile: user.mobile,
      gateway: PaymentGateways.SAB_PAISA,
    });
    let body = {
      payerName: user.firstName + ' ' + (user.lastName || ''),
      payerEmail: user.email,
      payerMobile: user.mobile,
      clientTxnId: myTxnId,
      amount,
      udf1: 'Flash Ad Purchase',
      udf2: 'Purchasing ' + selectedPricePlan?.name + ' Flash Ad',
      udf3: 'browser',
      udf4: 'Opportunity',
      udf6: user.id,
      udf7: 'SellerProfile',
    };
    let encData = AES.encrypt(JSON.stringify(body), process.env.REACT_APP_PAYMENT_PAYLOAD_KEY);
    let flashAdBody = bodyHandler();
    paymentDetailsRef.current = { isInitiated: true, txnId: myTxnId, body: flashAdBody };
    window.open(
      `${process.env.REACT_APP_PG_API_HOST}/sabpaisaPayInPaymentRequest?encStr=${encData}`
    );
  };

  const initiateRazorPayPayment = ({ amount }) => {
    paymentService
      .initiateRazorPayPayment({ amount: Number(amount), type: TransactionType.FLASH_AD })
      .then((res) => {
        if (res.success) {
          razorPayPaymentHandler({ amount: res.data.amount, orderId: res.data.orderId });
        } else {
          setClicked(false);
          dispatch(snackbarInfo({ type: 'error', open: true, message: res.message }));
        }
      })
      .catch((e) => setClicked(false));
  };

  const razorPayPaymentHandler = ({ amount, orderId }) => {
    let options = RazorPayOptions({
      user,
      amount,
      orderId,
      description: 'Flash Ad Charges (includes GST)',
    });
    options.handler = (res) => {
      res.type = PaymentGateways.RAZOR_PAY;
      if (res.razorpay_order_id && res.razorpay_payment_id && res.razorpay_signature) {
        let flashAdBody = bodyHandler();
        submitHandler({ paymentDetails: res, flashAdBody });
      } else {
        dispatch(snackbarInfo({ open: true, type: 'error', message: 'Payment Failed' }));
      }
    };
    options.modal = { ondismiss: () => setClicked(false) };

    const rzpay = new window.Razorpay(options);
    rzpay.open();
  };

  const bodyHandler = () => {
    let body = {
      type: OpportunityType.ANNOUNCEMENT,
      image,
      description,
      category: selectedCategory?.value,
      viewsLimit: selectedPricePlan?.data?.data,
    };
    if (websiteUrl) body.websiteUrl = websiteUrl;
    if (appUrl) body.appUrl = appUrl;
    if (mobileNumber) body.mobileNumber = mobileNumber;
    if (!_.isEmpty(selectedState)) body.state = selectedState.map((e) => e.value);
    if (!_.isEmpty(selectedCity)) body.city = selectedCity.map((e) => e.value);
    if (!_.isEmpty(selectedArea)) body.area = selectedArea.map((e) => e.value);
    return body;
  };

  const submitHandler = async ({ flashAdBody, paymentDetails, txnId }) => {
    if (flashAdBody) {
      let body = flashAdBody;
      if (txnId) body.txnId = txnId;
      if (paymentDetails) body.paymentDetails = paymentDetails;

      paymentDetailsRef.current = null;
      let s3ErrorMsg = null;

      if (body.image) {
        await fileUploadToS3WD(body.image, 'Announcements').then((res) => {
          if (res.status !== 403 && res.url) {
            body.image = { name: res.name, url: res.url, purposeType: res.purposeType };
            s3ErrorMsg = null;
          } else {
            delete body.image;
            s3ErrorMsg = [
              'We have recieved your payment',
              'Unable to upload image, please upload the image again to newly created Ad below',
            ];
          }
        });
      }

      opportunityService
        .createOpportunity(body)
        .then((res) => {
          if (res.success) {
            dispatch(
              snackbarInfo({
                open: true,
                type: s3ErrorMsg ? 'warning' : 'success',
                message: s3ErrorMsg || 'Flash Ad created successfully',
              })
            );
            setClicked(false);
            navigate(-2);
          } else {
            dispatch(snackbarInfo({ open: true, type: 'error', message: res.message }));
            setClicked(false);
          }
        })
        .catch((err) => setClicked(false));
    } else {
      dispatch(
        snackbarInfo({
          opne: true,
          type: 'error',
          message: ['Something went wrong', 'Please contact Support for refund, if debited any'],
        })
      );
      setClicked(false);
    }
  };

  return (
    <Fragment>
      <WebMynyfyHeader title={'Create Flash Ad'} />
      <div style={{ padding: '15px 20px' }}>
        <MynyfyPre
          title={
            'Flash your Ad on home screen when logged-in Flash Ad gives guaranteed views as per selected plan'
          }
          pSmall
          color={theme.MUTED_BLUE}
          style={{ marginInline: 5 }}
        />
        <div style={{ marginBlock: 15 }}>
          <MynyfyUploadImg
            accept={true}
            type='button'
            label={image ? 'Image uploaded' : 'Upload Ad image'}
            name={'adImage'}
            containerMargin='0px 5px 5px'
            onChange={(e) => uploadHandler(e)}
          />
          {error?.image ? (
            <MynyfyText
              title={error?.image}
              pSmall
              style={{ margin: '0px 19px 7px', color: '#D32F2F' }}
            />
          ) : null}
        </div>
        <MynyfyInput
          placeHolder='Title*'
          fullWidth={true}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          error={error?.description ? true : false}
          helperText={error?.description}
        />
        <MynyfyInput
          placeHolder='Mobile Number'
          fullWidth={true}
          value={mobileNumber}
          onChange={(e) => setMobileNumber(e.target.value)}
        />
        <MynyfyInput
          placeHolder='Target Url'
          fullWidth={true}
          value={websiteUrl}
          onChange={(e) => setWebsiteUrl(e.target.value)}
        />
        <MynyfyText
          title={'Target url can be website url, youtube url or social media page url'}
          style={{ margin: '0px 5px 5px' }}
          color={theme.MUTED_BLUE}
          pSmall
        />
        <MynyfyInput
          placeHolder='App Url'
          fullWidth={true}
          value={appUrl}
          onChange={(e) => setAppUrl(e.target.value)}
        />
        <MynyfyText bold title={'Select Ad Category'} style={{ padding: '7px 5px 0px' }} />
        <MynyfySelect
          placeholder={'Category'}
          value={selectedCategory}
          onChange={(e) => setSelectedCategory(e)}
          list={categories.map((res) => ({ label: res.name, value: res._id, data: res.data }))}
          error={error?.selectedCategory ? true : false}
          helperText={error?.selectedCategory}
        />
        <MynyfyText bold title={'Set Ad Location'} style={{ padding: '10px 5px 0px' }} />
        <MynyfySelect
          placeholder={'State'}
          value={selectedState}
          onChange={(e) => handelSelectorChange('state', e, setSelectedState, setCities)}
          list={states.map((res) => ({ label: res.name, value: res._id, data: res.data }))}
          type='multi'
        />
        <MynyfySelect
          placeholder={'City'}
          value={selectedCity}
          onChange={(e) => handelSelectorChange('city', e, setSelectedCity, setAreas)}
          list={cities.map((res) => ({ label: res.name, value: res._id, data: res.data }))}
          type='multi'
        />
        <MynyfySelect
          placeholder={'Area'}
          value={selectedArea}
          onChange={(e) => setSelectedArea(e)}
          list={areas.map((res) => ({ label: res.name, value: res._id, data: res.data }))}
          type='multi'
        />
        <MynyfyText bold title={'Select Plan'} style={{ padding: '10px 5px 0px' }} />
        <MynyfySelect
          placeholder={'Flash Ad Plan*'}
          value={selectedPricePlan}
          onChange={(e) => setSelectedPricePlan(e)}
          list={pricePlans}
          error={error?.selectedPricePlan ? true : false}
          helperText={error?.selectedPricePlan}
        />
        <div style={{ margin: '10px 5px' }}>
          <MynyfyText title='Important' pBig bold primary />
          <MynyfyText title={'1.Maintain 1:2 ratio or A4'} pSmall />
          <MynyfyText title={'2.Maximum size allowed: 1MB'} pSmall />
          <MynyfyText title={'3.Supported Formats:png, jpg, jpeg'} pSmall />
        </div>
        <div style={{ padding: '0px 5px' }}>
          <MynyfySpan title={'By clicking on '} p />
          <MynyfySpan title={'Create'} bold p />
          <MynyfySpan title={', you will be accepted '} p />
          <MynyfySpan
            title={'Mynyfy Guidelines For Advertisements'}
            link
            onClick={() => navigate('/Policies/PublishAdPolicy')}
          />
        </div>
        <MynyfyButton
          label={'Create'}
          center
          style={{ marginTop: 15 }}
          loading={isClicked}
          disabled={isClicked}
          onClick={() => validationHandler()}
        />
      </div>
    </Fragment>
  );
};

export default WebCreateFlashAds;
