// import React, { useState } from 'react';
// import MynyfyModal from './MynyfyModal';
// import SwipeableViews from 'react-swipeable-views';
// import { HighlightOffRounded } from '@mui/icons-material';
// import { s3ToCloudFront } from '../utils/CommonMethods';

// const ShowImages = ({ isVisible, closeModal, images }) => {
//   const [page, setPage] = useState(0);

//   const closeHandler = () => {
//     closeModal();
//     setPage(0);
//   };

//   return (
//     <MynyfyModal
//       open={isVisible}
//       close={() => closeHandler()}
//       style={{ backgroundColor: 'transparent' }}>
//       <div id='ShowImages'>
//         <HighlightOffRounded
//           style={{ color: '#fff' }}
//           onClick={() => closeHandler()}
//           className='closeBtnContainer'
//         />

//         {images ? (
//           <SwipeableViews
//             axis={'x'}
//             index={page}
//             onChangeIndex={(index) => setPage(index)}
//             enableMouseEvents
//             className='tab'
//             containerStyle={{ height: '100%' }}
//             slideStyle={{ height: '100%' }}>
//             {images?.map((res, i) => (
//               <img key={i} src={s3ToCloudFront(res.url)} className='carouselImg' />
//             ))}
//           </SwipeableViews>
//         ) : null}

//         <div className='imgIndicationContainer row'>
//           {images?.map((res, i) => (
//             <div key={i} onClick={() => setPage(i)}>
//               <div className={`indicator ${i == page && 'activeIndicator'}`} />
//             </div>
//           ))}
//         </div>
//       </div>
//     </MynyfyModal>
//   );
// };

// export default ShowImages;

import React, { useState, useRef } from 'react';
import MynyfyModal from './MynyfyModal';
import SwipeableViews from 'react-swipeable-views';
import { HighlightOffRounded } from '@mui/icons-material';
import { s3ToCloudFront } from '../utils/CommonMethods';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

const ShowImages = ({ isVisible, closeModal, images }) => {
  const [page, setPage] = useState(0);

  const closeHandler = () => {
    closeModal();
    setPage(0);
  };

  return (
    <MynyfyModal
      open={isVisible}
      close={() => closeHandler()}
      style={{ backgroundColor: 'transparent' }}>
      <div id='ShowImages'>
        <HighlightOffRounded
          style={{ color: '#fff' }}
          onClick={() => closeHandler()}
          className='closeBtnContainer'
        />

        {images ? (
          <SwipeableViews
            axis={'x'}
            index={page}
            onChangeIndex={(index) => setPage(index)}
            enableMouseEvents
            className='tab'
            containerStyle={{ height: '100%' }}
            slideStyle={{ height: '100%' }}>
            {images?.map((res, i) => (
              <div key={i} className='image-container'>
                <TransformWrapper
                  initialScale={1}
                  initialPositionX={0}
                  initialPositionY={0}
                  minScale={1}
                  maxScale={3}
                  pinch={{ disabled: true }}
                  doubleClick={{ disabled: true }}>
                  {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                    <div>
                      <TransformComponent>
                        <img
                          src={s3ToCloudFront(res.url)}
                          className='carouselImg'
                          alt={`image-${i}`}
                        />
                      </TransformComponent>
                    </div>
                  )}
                </TransformWrapper>
              </div>
            ))}
          </SwipeableViews>
        ) : null}

        <div className='imgIndicationContainer row'>
          {images?.map((res, i) => (
            <div key={i} onClick={() => setPage(i)}>
              <div className={`indicator ${i === page && 'activeIndicator'}`} />
            </div>
          ))}
        </div>
      </div>
    </MynyfyModal>
  );
};

export default ShowImages;
