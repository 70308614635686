import React, { Fragment, useEffect, useState, useRef } from 'react';
import WebMynyfyHeader from '../../components/WebMynyfyHeader';
import { useLocation, useNavigate } from 'react-router';
import MynyfyText from '../../components/MynyfyText';
import MynyfyButton from '../../components/MynyfyButton';
import { Carousel } from 'react-responsive-carousel';
import { Button, CardContent } from '@mui/material';
import IosShareOutlinedIcon from '@mui/icons-material/IosShareOutlined';
import MynyfyLoader from '../../components/MynfyfLoader';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { snackbarInfo } from '../../redux/actions/snackbar.action';
import ProductCartHelper from './ProductCartHelper';
import MynyfyPre from '../../components/MynyfyPre';
import {
  ControlPointRounded,
  DiscountRounded,
  RemoveCircleOutlineRounded,
} from '@mui/icons-material';
import { ReactComponent as VegIcon } from '../../assets/svg/veg.svg';
import { ReactComponent as NonVegIcon } from '../../assets/svg/nonVeg.svg';
import { ProductMaterialType } from '../../utils/enums';
import { addToBag, decrementHandler, incrementHandler, qtyHandler } from './AddProductHelper';
import { formatToMoney, s3ToCloudFront } from '../../utils/CommonMethods';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import BrandProductCartHelper from './ProductCartHelperForBrand';
import LocationModal from '../Location/LocationModal';

const NO_OF_OFFERS = 3;

const WebBrandProductDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const route = useLocation();
  let productDetails = route.state?.details;
  let policies = route?.state?.sellerDetails?.policies || route?.state?.sellerDetails?.policies;

  const inputRef = useRef(null);
  const containerRef = useRef(null);

  const bag = useSelector((state) => state.bag);
  const location = useSelector((state) => state.location?.location);

  const [isMoreOffers, setMoreOffers] = useState(false);
  const [offers, setOffers] = useState(false);
  const [isAddBtnClicked, setAddBtnClicked] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [currentImage, setCurrentImage] = useState(null);
  const [isLocationModal, setIsLocationModal] = useState(false);

  useEffect(() => {
    if (productDetails.variants?.length > 1) {
      let reqVariant = {};
      if (productDetails.variants[0]?.size) reqVariant.size = productDetails.variants[0]?.size;
      if (productDetails.variants[0]?.color) reqVariant.color = productDetails.variants[0]?.color;
      if (!_.isEmpty(reqVariant)) setSelectedOptions(reqVariant);
    }

    if (productDetails?.category) {
      if (!_.isEmpty(bag?.offers)) {
        let reqOffers = bag?.offers?.filter(
          (e) => e?.category?._id == productDetails?.category?._id || !e?.category
        );
        setOffers(reqOffers);
      }
    } else setOffers(bag?.offers);
  }, []);

  const shareUrl = async () => {
    if (bag?.about) {
      try {
        if (navigator.share) {
          await navigator.share({
            title: bag?.about?.name || bag?.about?.brand,
            text:
              `Now you can find all the products, offer, contest of ${
                bag?.about?.name || bag?.about?.brand
              } ${bag?.about?.type}` +
              '\n' +
              '\n' +
              `${bag?.about?.type} link : ${bag?.about?.url}`,
          });
        }
      } catch (error) {
        alert(error.message);
      }
    }
  };

  useEffect(() => {
    if (bag?.calculation?.qty && isAddBtnClicked) {
      containerRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
      setAddBtnClicked(false);
    }
  }, [bag?.calculation?.qty]);

  const selectVariant = (optionType, value) => {
    setSelectedOptions((prev) => ({ ...prev, [optionType]: value }));
  };

  const getUniqueAttributes = ({ variants, key, selectedKey, selectedValue }) => {
    return [
      ...new Set(
        variants.map((item) =>
          selectedValue ? item[selectedKey] == selectedValue && item[key] : item[key]
        )
      ),
    ]?.filter((e) => e);
  };

  const selectedVariant =
    productDetails.variants?.length > 1
      ? _.isEmpty(selectedOptions)
        ? productDetails.variants[0]
        : productDetails.variants.find(
            (f) => f.size === selectedOptions?.size && f.color === selectedOptions?.color
          )
      : productDetails.variants[0];

  useEffect(() => {
    setCurrentImage(selectedVariant?.image?.[0]);
  }, [selectedVariant]);

  const calculateDiscount = (originalPrice, discountedPrice) => {
    if (originalPrice <= 0 || discountedPrice < 0 || discountedPrice > originalPrice) {
      return 'Invalid prices';
    }
    const discountPercentage = ((originalPrice - discountedPrice) / originalPrice) * 100;
    return discountPercentage.toFixed(2) + '%';
  };

  return productDetails ? (
    <Fragment>
      <WebMynyfyHeader title={'Product Details'} />
      <div
        id='Products'
        style={{ paddingBottom: bag?.calculation?.qty ? 75 : 0 }}
        ref={containerRef}>
        <div style={{ marginTop: 30, marginLeft: 50, marginRight: 50 }}>
          <div style={{ display: 'flex' }}>
            <div sx={{ maxWidth: 200 }}>
              <div
                style={{
                  margin: 'auto',
                  display: 'flex',
                  maxHeight: '500px',
                }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: '10px 20px',
                    overflowY: 'auto',
                  }}>
                  {selectedVariant?.image?.map((image, i) => (
                    <img
                      key={i}
                      src={s3ToCloudFront(image?.url)}
                      alt={productDetails?.name}
                      className='cursor'
                      style={{
                        objectFit: 'contain',
                        width: '100px',
                        height: '100px',
                        border: `2px solid ${
                          currentImage?._id === image?._id ? 'black' : '#e8e8e8'
                        }`,
                        padding: '5px 0',
                        borderRadius: 10,
                        margin: '10px 10px',
                      }}
                      onClick={() => setCurrentImage(image)}
                    />
                  ))}
                </div>
                <img
                  src={s3ToCloudFront(currentImage?.url)}
                  alt={productDetails?.name}
                  style={{
                    width: '550px',
                    height: '500px',
                    objectFit: 'contain',
                    marginRight: '20px',
                  }}
                />
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
              <div>
                <CardContent>
                  <div className='row' style={{ marginBottom: 10 }}>
                    <MynyfyText className='cursor' title={'Home'} onClick={() => navigate(-1)} />
                    <MynyfyText title={'/'} style={{ margin: '0 5px' }} />
                    <MynyfyText title={productDetails?.name} />
                  </div>
                  <div className='rowSB'>
                    <MynyfyText title={productDetails?.name} h2 bold style={{ marginBottom: 10 }} />
                  </div>
                  <MynyfyText
                    title={productDetails?.description}
                    style={{ marginTop: 1 }}
                    pBig
                    color={'#5f5f5f'}
                  />
                  <div className='rowSB'>
                    <div className='row' style={{ marginTop: 20 }}>
                      {selectedVariant?.actualPrice !== undefined && (
                        <MynyfyText
                          title={'Rs. ' + formatToMoney(selectedVariant?.actualPrice)}
                          style={{
                            textDecoration:
                              selectedVariant?.price &&
                              selectedVariant.actualPrice !== selectedVariant.price
                                ? 'line-through'
                                : 'unset',
                          }}
                          h4
                          bold={
                            selectedVariant?.price &&
                            selectedVariant.actualPrice !== selectedVariant.price
                              ? false
                              : true
                          }
                        />
                      )}
                      {selectedVariant?.actualPrice === selectedVariant?.price ||
                      !selectedVariant?.price ? null : (
                        <MynyfyText
                          title={'Rs. ' + formatToMoney(selectedVariant?.price)}
                          bold
                          h4
                          color={'green'}
                          style={{ marginLeft: 10 }}
                        />
                      )}
                    </div>
                    {productDetails.materialType ? (
                      productDetails.materialType == ProductMaterialType.NON_VEGETARIAN ? (
                        <NonVegIcon width={20} height={20} />
                      ) : (
                        <VegIcon width={20} height={20} />
                      )
                    ) : null}
                  </div>
                </CardContent>
              </div>

              <div style={{ width: '100%' }}>
                {productDetails?.variants?.length > 1 ? (
                  <div>
                    <div style={{ background: '#F1F1F1', height: '2px' }}></div>
                    <div style={{ paddingBlock: '15px' }}>
                      <div style={{ margin: '0px 16px', marginTop: '5px' }}>
                        <MynyfyText title='Variants' color={'grey'} style={{ marginBottom: 10 }} />
                        <div className='row' style={{ gap: 10 }}>
                          {getUniqueAttributes({
                            variants: productDetails.variants,
                            key: 'size',
                            selectedKey: 'color',
                            selectedValue: selectedOptions?.color,
                          }).map((size) => (
                            <MynyfyText
                              key={size}
                              title={size}
                              className='cursor'
                              style={{
                                padding: '10px 20px',
                                borderRadius: 5,
                                backgroundColor: selectedOptions?.size === size && '#f0f8fd',
                                border:
                                  selectedOptions?.size === size
                                    ? '2px solid #3076b5'
                                    : '2px solid #e8e8e8',
                              }}
                              color={selectedOptions?.size === size ? ' #3076b5' : 'black'}
                              isselected={selectedOptions?.size == size ? 'true' : 'false'}
                              onClick={() => selectVariant('size', size)}
                            />
                          ))}
                        </div>
                        <MynyfyText title='Colors' color={'grey'} style={{ marginTop: 20 }} />
                        <div className='row' style={{ gap: 10 }}>
                          {getUniqueAttributes({
                            variants: productDetails.variants,
                            key: 'color',
                            selectedKey: 'size',
                            selectedValue: selectedOptions?.size,
                          }).map((color) => (
                            <div
                              key={color}
                              className='chipBtn colorChip'
                              style={{ backgroundColor: color }}
                              isselected={selectedOptions?.color == color ? 'true' : 'false'}
                              onClick={() => selectVariant('color', color)}></div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>

              <div className='row' style={{ padding: '10px 20px 15px' }}>
                {bag?.products?.find(
                  (e) =>
                    e.brandProductId == productDetails._id &&
                    e.brandVariantId == selectedVariant._id
                ) ? (
                  <div
                    className='rowC'
                    style={{
                      margin: '0px 10px',
                      height: 50,
                      backgroundColor: 'black',
                      width: '300px',
                      marginLeft: -10,
                    }}>
                    <RemoveRoundedIcon
                      fontSize='medium'
                      className='cursor'
                      style={{ color: 'white' }}
                      onClick={() => {
                        decrementHandler({
                          productDetails,
                          variant: selectedVariant,
                          bag,
                          dispatch,
                          from: 'brand',
                        });
                        inputRef.current.focus();
                      }}
                    />
                    <input
                      value={
                        bag?.products?.find(
                          (e) =>
                            e.brandProductId == productDetails._id &&
                            e.brandVariantId == selectedVariant._id
                        )?.count || 0
                      }
                      onChange={(e) => {
                        qtyHandler({
                          productDetails,
                          variant: selectedVariant,
                          value: e.target.value,
                          bag,
                          dispatch,
                          from: 'brand',
                        });
                      }}
                      autoFocus
                      ref={(el) => (inputRef.current = el)}
                      className='qtyInput detailsInput'
                      style={{
                        width: '200px',
                        backgroundColor: 'transparent',
                        color: 'white',
                        border: 'none',
                        fontSize: '20px',
                      }}
                    />
                    <AddRoundedIcon
                      fontSize='medium'
                      className='cursor'
                      style={{ color: 'white' }}
                      onClick={() => {
                        incrementHandler({
                          productDetails,
                          variant: selectedVariant,
                          bag,
                          dispatch,
                          from: 'brand',
                        });
                        inputRef.current.focus();
                      }}
                    />
                  </div>
                ) : (
                  <div style={{ flex: 1, display: 'flex', alignItems: 'center', marginLeft: 10 }}>
                    <MynyfyButton
                      label={
                        (selectedVariant.count
                          ? selectedVariant.count - selectedVariant.ordersCount
                          : selectedVariant.count == 0
                          ? 0
                          : 'unlimited') == 0
                          ? 'OUT OF STOCK'
                          : 'ADD TO CART'
                      }
                      className='addBagBtn'
                      onClick={() => {
                        if (location?.state?._id && location?.city?._id && location?.area?._id) {
                          addToBag({
                            productDetails,
                            variant: selectedVariant,
                            bag,
                            dispatch,
                            from: 'brand',
                          });
                          setAddBtnClicked(true);
                        } else {
                          setIsLocationModal(true);
                        }
                      }}
                      disabled={
                        (selectedVariant.count
                          ? selectedVariant.count - selectedVariant.ordersCount
                          : selectedVariant.count == 0
                          ? 0
                          : 'unlimited') == 0
                          ? true
                          : false
                      }
                      style={{
                        backgroundColor:
                          (selectedVariant.count
                            ? selectedVariant.count - selectedVariant.ordersCount
                            : selectedVariant.count == 0
                            ? 0
                            : 'unlimited') == 0
                            ? 'gray'
                            : 'black',
                        borderRadius: 3,
                        width: '350px',
                        height: 50,
                        marginLeft: -20,
                        color: 'white',
                        fontFamily: 'MynyfyRegular',
                      }}
                    />
                    <Button
                      style={{
                        background: 'white',
                        borderRadius: 3,
                        border: '1px solid black',
                        height: 50,
                      }}
                      onClick={() => shareUrl()}>
                      <IosShareOutlinedIcon style={{ color: 'black', fontSize: '20px' }} />
                    </Button>
                  </div>
                )}
              </div>
              <BrandProductCartHelper
                brandDetails={route.state?.brandDetails}
                navFrom={route.state?.navFrom}
                table={route?.state?.table}
                from={'modal'}
                modalProps={{
                  style: {
                    height: '50px',
                    borderRadius: '5px',
                    width: '300px',
                    marginLeft: 5,
                    border: '1px solid black',
                    backgroundColor: 'white',
                    color: 'black',
                  },
                }}
              />
              <div>
                {/* {policies?.shipping ? (
                  <div>
                    <div style={{ background: '#F1F1F1', height: '5px' }}></div>
                    <div style={{ paddingTop: '15px' }}>
                      <div className='rowSB' style={{ margin: '0px 16px', marginTop: '5px' }}>
                        <MynyfyText title='Delivery Details' pBig bold />
                        <MynyfyText
                          title='Check Details'
                          link
                          onClick={() => {
                            navigate(`/Policies/Delivery_Policy`, {
                              state: {
                                policy: [
                                  {
                                    heading: null,
                                    content: ['Delivery timeline will be shown in order screen'],
                                  },
                                ],
                                policy2: policies?.shipping,
                                policyName: `Delivery Policy`,
                              },
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                ) : null} */}
                <br />
                {/* {_.isEmpty(offers) ? null : (
                  <div>
                    <div style={{ background: '#F1F1F1', height: '2px' }}></div>
                    <div style={{ paddingTop: '15px' }}>
                      <div style={{ margin: '0px 16px', marginTop: '5px' }}>
                        <div className='rowSB'>
                          <MynyfyText title=' Offers' pBig bold />
                          {isMoreOffers ? (
                            <MynyfyText
                              title={`less offers`}
                              link
                              onClick={() => setMoreOffers(!isMoreOffers)}
                            />
                          ) : offers?.length - NO_OF_OFFERS > 0 ? (
                            <MynyfyText
                              title={`+${offers?.length - NO_OF_OFFERS} More offers`}
                              link
                              onClick={() => setMoreOffers(!isMoreOffers)}
                            />
                          ) : null}
                        </div>
                        {offers
                          ?.slice(0, isMoreOffers ? offers?.length : NO_OF_OFFERS)
                          ?.map((res, i) => (
                            <div style={{ display: 'flex', marginTop: 5 }} key={i}>
                              <DiscountRounded style={{ padding: 5, height: 20, width: 20 }} />
                              <div style={{ padding: '5px 8px 8px' }}>
                                <MynyfyText
                                  style={{ paddingTop: 0 }}
                                  title={
                                    res.name + (res.description ? ' - ' + res.description : '')
                                  }
                                  className='poductOffersDescription'
                                />
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                    <br />
                  </div>
                )} */}
                {selectedVariant?.specification ? (
                  <div>
                    <div style={{ background: '#F1F1F1', height: '2px' }}></div>
                    <div style={{ paddingTop: '15px' }}>
                      <div style={{ margin: '0px 16px', marginTop: '5px' }}>
                        <div className='rowSB'>
                          <MynyfyText title='Product Details / Specifications' pBig bold />
                          <MynyfyText title='+ More ' link />
                        </div>
                        <div style={{ paddingTop: 10 }}>
                          <MynyfyPre title={selectedVariant?.specification} color={'#000'} />
                        </div>
                      </div>
                    </div>
                    <br />
                  </div>
                ) : null}
                {/* {policies?.isReturn || policies?.isExchange ? (
                  <div>
                    <div style={{ background: '#F1F1F1', height: '5px' }}></div>
                    <div style={{ paddingTop: '15px', paddingBottom: '15px' }}>
                      <div className='rowSB' style={{ margin: '0px 16px', marginTop: '5px' }}>
                        <MynyfyText
                          title='Return/Refund/Exchange Details'
                          style={{ marginRight: 10 }}
                          pBig
                          bold
                        />
                        <MynyfyText
                          title='Check Details'
                          link
                          style={{ whiteSpace: 'nowrap' }}
                          onClick={() => {
                            navigate(`/Policies/Return_Refund_Exchange_Policy`, {
                              state: {
                                policy: policies?.refund,
                                policy2: policies?.exchange,
                                policyName: `Return / Refund / Exchange Policy`,
                              },
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                ) : null} */}
              </div>
              <div>{/* <div style={{ background: '#F1F1F1', height: '5px' }}></div> */}</div>
            </div>
          </div>
          <div></div>
        </div>
      </div>
      <LocationModal
        isOpen={isLocationModal}
        close={(e) => {
          if (!_.isEmpty(location) || e) setIsLocationModal(false);
        }}
        closeBtn={() => setIsLocationModal(false)}
        content={
          'Some products may not be deliverable to every location. Set your delivery location to view serviceable products.'
        }
        modalProps={{
          position: 'center',
          style: {
            borderRadius: 10,
          },
        }}
      />
    </Fragment>
  ) : (
    <MynyfyLoader />
  );
};

export default WebBrandProductDetails;
