import React from 'react';
import '../promos.scss';
import { PromoType } from '../../../utils/enums';
import Promos from '..';
import { useSelector } from 'react-redux';
import { fetchDayDeals, fetchSortedDayDeals } from '../../../redux/actions/promo.action';

const DayDeal = ({ goHome }) => {
  const promos = useSelector((state) => state.promos?.dayDeals?.actual);
  const sortedPromos = useSelector((state) => state.promos?.dayDeals?.sorted);

  return (
    <Promos
      type={PromoType.DAY_DEAL}
      isFilter={true}
      screen={'Deals'}
      emptyListTitle={'Deals'}
      headerTitle='Limited Time Deals'
      promos={promos}
      sortedPromos={sortedPromos}
      action={fetchDayDeals}
      sortedAction={fetchSortedDayDeals}
      goHome={goHome}
    />
  );
};

export default DayDeal;
