import React from 'react';
import './webpromos.scss';
import { PromoType } from '../../utils/enums';
import Promos from '.';
import { useSelector } from 'react-redux';
import { fetchRewards, fetchSortedRewards } from '../../redux/actions/promo.action';
import WebPromos from './WebPromos';

const WebRewards = ({ goHome }) => {
  const promos = useSelector((state) => state.promos?.rewards?.actual);
  const sortedPromos = useSelector((state) => state.promos?.rewards?.sorted);

  return (
    <WebPromos
      type={PromoType.REWARD}
      isFilter={false}
      screen={'Rewards'}
      emptyListTitle={'Vouchers'}
      promos={promos}
      sortedPromos={sortedPromos}
      action={fetchRewards}
      sortedAction={fetchSortedRewards}
      goHome={goHome}
    />
  );
};

export default WebRewards;
