import React, { Fragment } from 'react';
import MynyfyText from '../../components/MynyfyText';
import MynyfyHeader from '../../components/MynyfyHeader';
import '../Policies/policy.scss';
import { useLocation } from 'react-router';

const BrandTermsPolicy = () => {
  const route = useLocation();
  const { details } = route.state || {};

  return (
    <Fragment>
      <MynyfyHeader title={"Terms & Conditions"} />
      <div style={{ padding: 20 }} id='policy'>

        {details.policies && details.policies.terms && details.policies.terms.length > 0 ? (
          <div>
            {details.policies.terms.map((policy, index) => (
              <div>
                <MynyfyText
                  key={index}
                  title={policy.heading}
                  pBig bold style={{ padding: '20px 0px 0px', marginBottom: '20px' }} />
                <MynyfyText
                  key={index}
                  title={policy.content}
                  style={{ paddingBottom: 20, marginBottom: '10px' }}
                />
              </div>
            ))}
          </div>
        ) : (
          <MynyfyText
            title="No Terms"
            pBig bold style={{ padding: '20px 0px 0px', marginBottom: '10px' }}
          />
        )}

      </div>
    </Fragment>
  );
};

export default BrandTermsPolicy;
