import React, { Fragment, useEffect, useState } from 'react';
import MynyfyInput from '../../components/MynyfyInput';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import validate from '../../utils/validate';
import MynyfyButton from '../../components/MynyfyButton';
import MynyfyText from '../../components/MynyfyText';
import profileService from '../../services/profileService';
import { snackbarInfo } from '../../redux/actions/snackbar.action';
import { userLogin } from '../../redux/actions/user.action';
import { WATCH_PROFILE } from '../../redux/actionTypes';
import authService from '../../services/authService';
import { profileValidator } from '../../utils/validators';
import _ from 'lodash';
import MynyfyHeader from '../../components/MynyfyHeader';
import LocationFields from '../Location/LocationFields';
import { updateLocation } from '../../redux/actions/location.action';
import { MsgEnums } from '../../utils/MsgEnums';
import WebMynyfyHeader from '../../components/WebMynyfyHeader';
import './webprofile.scss';

let VALIDATOR = {
  ...profileValidator,
  selectedState: [(value) => (value ? null : 'Select your State')],
  selectedCity: [(value) => (value ? null : 'Select your City')],
  selectedArea: [(value) => (value ? null : 'Select your Area')],
};

const WebEditProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let params = useLocation();

  const location = useSelector((state) => state.location?.location);
  const user = useSelector((state) => state.user);
  const id = user.user?.id || null;
  const profileId = user.user?.profile || null;
  const profileDetails = user?.profile?.profile || {};

  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [email, setEmail] = useState(null);
  const [referralCode, setReferralCode] = useState(null);
  const [isClicked, setIsClicked] = useState(false);
  const [error, setError] = useState({});
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedArea, setSelectedArea] = useState(null);

  useEffect(() => {
    if (profileDetails?.firstName) {
      let reqName = profileDetails?.firstName;
      if (profileDetails?.lastName) reqName = reqName + ' ' + profileDetails?.lastName;
      setFirstName(reqName);
    }
    if (profileDetails?.email) setEmail(profileDetails?.email);

    if (location) {
      setSelectedState(location.state);
      setSelectedCity(location.city);
      setSelectedArea(location.area);
    }
  }, []);

  useEffect(() => {
    if (!_.isEmpty(profileDetails)) {
      if (!firstName) {
        if (profileDetails?.firstName) {
          let reqName = profileDetails?.firstName;
          if (profileDetails?.lastName) reqName = reqName + ' ' + profileDetails?.lastName;
          setFirstName(reqName);
        }
      }
      if (email) setEmail(profileDetails?.email);
    }
  }, [profileDetails]);

  const setDataHandler = (key, value) => {
    if (key === 'state') {
      setSelectedState(value);
    } else if (key === 'city') {
      setSelectedCity(value);
    } else if (key === 'area') {
      setSelectedArea(value);
    }
  };

  const updateProfile = () => {
    setIsClicked(true);
    let obj = { firstName };
    if (email) obj.email = email;
    if (!params?.state?.noReferral) {
      obj.selectedState = selectedState;
      obj.selectedCity = selectedCity;
      obj.selectedArea = selectedArea;
    }
    let data = { firstName, lastName: null };
    if (email) data.email = email;
    const errors = validate(obj, VALIDATOR);
    setError(errors);
    if (_.isEmpty(errors)) {
      if (!params?.state?.noReferral) {
        dispatch(updateLocation({ state: selectedState, city: selectedCity, area: selectedArea }));
        data.state = selectedState?._id;
        data.city = selectedCity?._id;
        data.area = selectedArea?._id;
      }
      profileService.profileUpdate(data, id, profileId).then((res) => {
        if (res.success) {
          if (referralCode) referralUpdate();
          else setIsClicked(false);

          dispatch(
            snackbarInfo({ open: true, type: 'success', message: 'Profile details updated' })
          );

          let userDetails = _.clone(user?.user);
          userDetails.firstName = firstName;
          userDetails.lastName = null;
          if (email) userDetails.email = email;
          dispatch(userLogin(userDetails));
          dispatch({ type: WATCH_PROFILE });
          navigate(-1);
        } else {
          setIsClicked(false);
          dispatch(snackbarInfo({ open: true, type: 'error', message: res.message }));
        }
      });
    } else {
      dispatch(snackbarInfo({ type: 'warning', open: true, message: MsgEnums.FILL_DETAILS }));
      setIsClicked(false);
    }
  };

  const referralUpdate = () => {
    let data = { referralCode: referralCode };
    authService.referralCode(id, data);
  };

  const referralCheck = () => {
    if (referralCode) {
      setIsClicked(true);
      authService
        .referralCheck(referralCode)
        .then((res) => {
          if (res.data !== 'Good to Go') {
            dispatch(snackbarInfo({ open: true, type: 'error', message: res.data }));
          } else {
            dispatch(
              snackbarInfo({
                open: true,
                type: 'success',
                message: "It's a valid code, you are Good to Go",
              })
            );
          }
        })
        .finally(() => setIsClicked(false));
    }
  };

  return (
    <Fragment>
      <WebMynyfyHeader title={'Update Profile'} />
      <div id='Editprofile' style={{ padding: 30 }}>
        <MynyfyInput
          fullWidth={true}
          placeHolder={'First Name*'}
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          helperText={error?.firstName}
          error={error?.firstName ? true : false}
        />
        <MynyfyInput
          fullWidth={true}
          placeHolder={'Email (Optional)'}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          helperText={error?.email}
          error={error?.email ? true : false}
        />
        {/* {params?.state?.noReferral ? null : (
          <Fragment>
            <div className='rowSB'>
              <MynyfyInput
                placeHolder={'Referral Code (optional)'}
                value={referralCode}
                onChange={(e) => setReferralCode(e.target.value)}
                style={{ width: '30%' }}
              />
              <MynyfyButton
                label={'Verify'}
                onClick={() => referralCheck()}
                disabled={isClicked}
                loading={isClicked}
                style={{ marginTop: -2 }}
              />
            </div>
            <MynyfyText
              title={'Referral Code is case sensitive, please verify before submit'}
              pSmall
              style={{ margin: '-3px 5px 0px' }}
            />

            {location ? null : (
              <div style={{ marginTop: 20 }}>
                <MynyfyText title={'Set your location'} bold pBig style={{ margin: '0px 5px' }} />
                <LocationFields error={error} sendData={setDataHandler} />
              </div>
            )}
          </Fragment>
        )} */}

        <MynyfyButton
          label={params?.state?.noReferral ? 'Next' : 'Update'}
          onClick={() => updateProfile()}
          disabled={isClicked}
          loading={isClicked}
          containerStyle={{ textAlign: 'center', marginTop: 25 }}
          style={{ width: '25%' }}
        />
      </div>
    </Fragment>
  );
};

export default WebEditProfile;
