import React from 'react';
import './splash.scss';
import { LinearProgress } from '@mui/material';

const Splash = ({ noLogo }) => {
  return (
    <div id='Splash'>
      {noLogo ? null : (
        <img
          src={require('../../assets/imgs/LandingPage/mynyfy_White.png')}
          className='logo'
          alt='Mynyfy'
        />
      )}
      <LinearProgress style={{ width: 200 }} />
    </div>
  );
};

export default Splash;
