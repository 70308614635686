import React, { Fragment, useEffect, useState } from 'react';
import MynyfyText from '../../components/MynyfyText';
import moment from 'moment';
import TrackingDetails from '../../components/TrackingDetails';
import { OrderDeliveryStatus, OrderStatus, OrderType, PaymentMode } from '../../utils/enums';
import MynyfySpan from '../../components/MynyfySpan';
import storeService from '../../services/storeService';
import { useDispatch, useSelector } from 'react-redux';
import { snackbarInfo } from '../../redux/actions/snackbar.action';
import MynyfyLoader from '../../components/MynfyfLoader';
import { useLocation } from 'react-router';
import './orders.scss';
import MynyfyHeader from '../../components/MynyfyHeader';
import Convert from 'color-convert';
import theme from '../../utils/theme';
import { CallRounded, EmailRounded, GetAppRounded } from '@mui/icons-material';
import MynyfyPDFGenerator from '../../components/MynyfyPDFGenerator';
import OrderInvoice from '../../components/OrderInvoice';
import _ from 'lodash';
import productOrderService from '../../services/productOrderService';

const OrderDetails = () => {
  const dispatch = useDispatch();
  const route = useLocation();

  const profile = useSelector((state) => state.user?.profile?.profile);

  const [orderDetails, setOrderDetails] = useState(null);
  const [orgAddress, setOrgAddress] = useState(null);
  const [taxDetails, setTaxDetails] = useState(null);

  useEffect(() => {
    if (route?.state?.orderDetails) {
      fetchOrderDetails(route?.state?.orderDetails);
    } else if (route?.state?.orderId) {
      productOrderService.getProductOrderById(route?.state?.orderId).then((res) => {
        if (res.success) fetchOrderDetails(res.data);
        else dispatch(snackbarInfo({ type: 'error', open: true, message: res.message }));
      });
    }
  }, []);

  const fetchOrderDetails = async (reqOrderDetails) => {
    setOrderDetails(reqOrderDetails);
    taxDetailsHandler(reqOrderDetails);
    let orgId = reqOrderDetails?.seller?._id;
    let brandId = reqOrderDetails?.brand?.[0]?._id;
    if (orgId) addressCall(orgId);
  };

  const addressHandler = (key) => {
    return key ? `${key}, ` : null;
  };

  const addressCall = (orgId) => {
    storeService.getStoreAddress(orgId).then((res) => {
      if (res.success) {
        setOrgAddress(res.data);
      } else {
        dispatch(snackbarInfo({ type: 'error', open: true, message: res.message }));
      }
    });
  };

  const addressComponent = (address) => {
    return (
      <div>
        <MynyfySpan title={addressHandler(address?.lane1)} />
        <MynyfySpan title={addressHandler(address?.lane2)} />
        <MynyfySpan title={addressHandler(address?.landMark)} />
        <MynyfySpan title={addressHandler(address?.locality)} />
        <MynyfySpan title={addressHandler(address?.city)} />
        <MynyfySpan title={addressHandler(address?.state)} />
        <MynyfySpan title={address?.pincode} />
      </div>
    );
  };

  const sendMail = (email) => {
    window.open(
      `mailto:${email}?subject=Enquiry regarding my order Id: ${
        orderDetails.orderId
      }&body=Hi, this is ${profile?.firstName || ''} ${profile?.lastName || ''} and Mobile No. ${
        profile?.mobile || ''
      }.${theme.BREAK}${theme.BREAK}I have an enquiry regarding my order Id: ${
        orderDetails.orderId
      }`
    );
  };

  const taxDetailsHandler = (data) => {
    let taxData = [];
    const processData = (arr) => {
      arr.forEach((res) => {
        if (res.gst || res.gst == 0) {
          let taxableAmt =
            (res.discountedPriceBeforeGst || (res.discount && res.discountedPriceBeforeGst == 0)
              ? res.discountedPriceBeforeGst
              : res.priceBeforeGst) * res.count;
          let taxAmt =
            ((res.discountedPrice || (res.discount && res.discountedPrice == 0)
              ? res.discountedPrice
              : res.price) -
              (res.discountedPriceBeforeGst || (res.discount && res.discountedPriceBeforeGst == 0)
                ? res.discountedPriceBeforeGst
                : res.priceBeforeGst)) *
            res.count;

          let index = taxData.findIndex((e) => e.title == res.gst);
          if (index == -1) {
            taxData.push({ title: res.gst, taxableAmt, taxAmt });
          } else {
            taxData[index].taxableAmt += taxableAmt;
            taxData[index].taxAmt += taxAmt;
          }
        }
      });
    };

    if (data?.products) processData(data?.products);
    if (!_.isEmpty(data?.newProducts)) {
      data?.newProducts?.map((newProductsArray) => processData(newProductsArray));
    }
    let sorted = _.orderBy(taxData, 'title', 'asc');
    setTaxDetails(sorted);
  };

  const productsComponent = (res, i) => {
    return (
      <div className='rowSB' style={{ marginTop: 7 }} key={i}>
        <MynyfyText
          title={`${res.name || res.productId.name} x ${res.count}${
            res.color || res.size ? ' -- ' : ''
          }${
            res.color
              ? res.color?.includes('#')
                ? 'Color: ' + Convert.hex.keyword(res.color) + ', '
                : 'Color: ' + res.color + ', '
              : ''
          }${res.size ? 'Size: ' + res.size?.toUpperCase() : ''}`}
          style={{ textTransform: 'capitalize', maxWidth: 275 }}
        />
        <MynyfyText
          title={
            theme.RUPEE +
            (
              (res.discountedPriceBeforeGst || res.priceBeforeGst || res.productId.price) *
              res.count
            ).toFixed(2)
          }
          p
        />
      </div>
    );
  };

  const contactComponent = ({ mobile, email }) => {
    if (mobile || email) {
      return (
        <Fragment>
          <MynyfyText title={'CONTACT SUPPORT'} p style={{ margin: 15 }} />
          <div className='section'>
            {mobile ? (
              <div className='row' style={{ paddingBottom: 5 }}>
                <MynyfyText title={'Mobile: '} color={theme.MUTED_BLUE} />
                &nbsp;
                <MynyfyText title={mobile} onClick={() => window.open('tel:' + mobile)} />
                <CallRounded
                  fontSize=''
                  color='primary'
                  style={{ padding: '0 10px', display: 'block', cursor: 'pointer' }}
                />
              </div>
            ) : null}
            {email ? (
              <div className='row'>
                <MynyfyText title={'Email: '} color={theme.MUTED_BLUE} />
                &nbsp;
                <MynyfyText title={email} onClick={() => sendMail(email)} />
                <EmailRounded
                  fontSize=''
                  color='primary'
                  style={{ padding: '0 10px', display: 'block', cursor: 'pointer' }}
                  onClick={() => sendMail(email)}
                />
              </div>
            ) : null}
          </div>
        </Fragment>
      );
    } else return null;
  };

  return orderDetails ? (
    <div id='Orders' style={{ backgroundColor: '#f0f0f0' }}>
      <MynyfyHeader
        title={'Order #' + orderDetails.orderId}
        customElememt={() =>
          orderDetails.status == 'COMPLETED' ? (
            <MynyfyPDFGenerator
              customBtn={() => <GetAppRounded className='cursor' />}
              htmlString={OrderInvoice({ order: orderDetails, orgAddress, taxDetails })}
              pdfName={orderDetails.orderId + ' - Invoice'}
            />
          ) : null
        }
      />
      <div className='section'>
        <MynyfyText title={orderDetails?.seller?.name || orderDetails?.brand?.[0]?.brand} h6 bold />
        {orgAddress ? (
          <div style={{ marginTop: 3 }}>
            <MynyfySpan title={addressHandler(orgAddress?.lane1)} />
            <MynyfySpan title={addressHandler(orgAddress?.lane2)} />
            <MynyfySpan title={addressHandler(orgAddress?.locality)} />
            <MynyfySpan title={addressHandler(orgAddress?.city)} />
            <MynyfySpan title={addressHandler(orgAddress?.state)} />
            <MynyfySpan title={orgAddress?.pincode} />
          </div>
        ) : (
          <div>
            <MynyfySpan
              title={`${orderDetails?.seller?.area?.name || orderDetails?.brand?.[0]?.area?.name},  `}
            />
            <MynyfySpan
              title={`${orderDetails?.seller?.city?.name || orderDetails?.brand?.[0]?.city?.name},  `}
            />
            <MynyfySpan
              title={orderDetails?.seller?.state?.name || orderDetails?.brand?.[0]?.state?.name}
            />
          </div>
        )}
        <div className='divider' />
        <MynyfyText
          title={`Order Placed on ${moment(orderDetails?.createdAt).format(
            'MMMM DD YYYY,  hh:mm A'
          )}`}
          small
        />
      </div>

      {orderDetails?.address || orderDetails?.fullAddress ? (
        <Fragment>
          <MynyfyText title={'DELIVERY ADDRESS'} p style={{ margin: 15 }} />
          <div className='section'>
            {addressComponent(orderDetails?.address || orderDetails?.fullAddress)}
          </div>
        </Fragment>
      ) : null}

      <MynyfyText title={'BILL DETAILS'} p style={{ margin: 15 }} />
      <div className='section'>
        {orderDetails.products?.map((res, i) => productsComponent(res, i))}

        {_.isEmpty(orderDetails.newProducts) ? null : (
          <div style={{ borderBottom: '1px dashed grey', marginTop: 7 }} />
        )}

        {orderDetails.newProducts?.map((newOfNew, i) => (
          <Fragment key={i}>
            {newOfNew?.map((res, j) => productsComponent(res, j, 'newProducts'))}
            {orderDetails.newProducts.length == i + 1 ? null : (
              <div style={{ borderBottom: '1px dashed grey' }} />
            )}
          </Fragment>
        ))}

        {orderDetails.deals?.map((res, i) => (
          <div className='rowSB' style={{ marginTop: 7 }} key={i}>
            <MynyfyText
              title={res.name || res.dealId.name}
              style={{ textTransform: 'capitalize' }}
            />
            <MynyfyText title={theme.RUPEE + res.dealId.actualPrice} p />
          </div>
        ))}

        <div className='divider' />

        <div className='rowSB' style={{ margin: '7px 0px' }}>
          <MynyfyText title={'GST'} p />
          <MynyfyText
            title={
              theme.RUPEE +
              Number(
                orderDetails.products.reduce(
                  (acc, curr) =>
                    acc +
                    (curr.price -
                      (curr.discountedPriceBeforeGst ||
                        curr.priceBeforeGst ||
                        curr.productId.price)) *
                      curr.count,
                  0
                ) +
                  (orderDetails.newProducts
                    ?.flat()
                    .reduce(
                      (acc, curr) =>
                        acc +
                        (curr.price -
                          (curr.discountedPriceBeforeGst ||
                            curr.priceBeforeGst ||
                            curr.productId.price)) *
                          curr.count,
                      0
                    ) || 0)
              ).toFixed(2)
            }
            p
          />
        </div>
        <div className='rowSB' style={{ margin: '7px 0px' }}>
          <MynyfyText title={'Sub Total'} p />
          <MynyfyText title={theme.RUPEE + orderDetails.priceDetails.price} p />
        </div>
        <div className='rowSB' style={{ margin: '7px 0px' }}>
          <MynyfyText title={'Shipping Charges'} p />
          <MynyfyText title={theme.RUPEE + orderDetails.priceDetails.shippingCharges} p />
        </div>
        <div className='rowSB' style={{ margin: '7px 0px' }}>
          <MynyfyText title={'Discount Applied'} p />
          <MynyfyText
            title={
              theme.RUPEE +
              ((orderDetails.priceDetails.discount || 0) +
                (orderDetails.priceDetails.productsDiscount || 0))
            }
            p
            color={'green'}
          />
        </div>

        <div className='divider' />

        <div className='rowSB' style={{ margin: '3px 0px' }}>
          <MynyfyText
            title={
              orderDetails.priceDetails.totalPrice
                ? orderDetails.paymentMode == PaymentMode.CASH
                  ? 'Cash On Delivery'
                  : orderDetails.paymentMode == PaymentMode.UPI_QR
                  ? 'Paid to Seller UPI'
                  : 'Paid'
                : ' '
            }
            p
            bold
          />
          <MynyfyText
            title={`Bill Total ${theme.RUPEE}${orderDetails.priceDetails.totalPrice}`}
            p
            bold
          />
        </div>
      </div>

      {orderDetails.couponUsed ? (
        <Fragment>
          <MynyfyText title={'Applied Coupon/Reward'} p style={{ margin: 15 }} />
          <div className='section rowSB'>
            <MynyfyText title={orderDetails.couponUsed?.name} />
            <MynyfyText
              title={theme.RUPEE + orderDetails.couponUsed?.actualPrice}
              color={'green'}
            />
          </div>
        </Fragment>
      ) : null}

      {orderDetails.orderType !== OrderType.DINE_IN &&
      orderDetails.deliveryDetails?.deliveryStatus ? (
        <Fragment>
          <MynyfyText title={'TRACKING DETAILS'} p style={{ margin: 15 }} />
          <div className='section'>
            {orderDetails.status == OrderStatus.CANCELLED ? (
              <Fragment>
                <MynyfyText title={'Order is Cancelled by Store'} />
                {orderDetails.reason ? (
                  <div style={{ marginTop: 7 }}>
                    <MynyfySpan title={'Order Cancelled Reason: '} semiBold />
                    <MynyfySpan title={orderDetails.reason} />
                  </div>
                ) : null}
              </Fragment>
            ) : orderDetails.deliveryDetails?.deliveryStatus == OrderDeliveryStatus.IN_PROGRESS ? (
              <Fragment>
                <MynyfyText title={'We have recieved your order, shipping is in progress'} />
                <MynyfyText
                  title={'Order tracking details will be shared soon'}
                  style={{ marginTop: 5 }}
                />
              </Fragment>
            ) : orderDetails.deliveryDetails?.deliveryStatus ==
              OrderDeliveryStatus.PICK_UP_READY ? (
              <MynyfyText
                title={'Your order is ready, please visit the store and collect the order'}
              />
            ) : (
              <TrackingDetails deatils={orderDetails.deliveryDetails} />
            )}
          </div>
        </Fragment>
      ) : null}
      {contactComponent({
        mobile: _.isEmpty(orderDetails?.brand)
          ? orderDetails?.seller?.ownerId?.profile?.mobile
          : orderDetails?.brand[0]?.customerCareNumber,
        email: _.isEmpty(orderDetails.brand)
          ? orderDetails?.seller?.email
          : orderDetails?.brand[0]?.emailId,
      })}
    </div>
  ) : (
    <MynyfyLoader />
  );
};

export default OrderDetails;
