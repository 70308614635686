import React, { Fragment, useEffect, useState } from 'react';
import MynyfyText from '../../components/MynyfyText';
import { useDispatch, useSelector } from 'react-redux';
import SelectSeller from '../../components/SelectSeller';
import SelectAddress from '../../components/SelectAddress';
import { OrderType, PaymentMode } from '../../utils/enums';
import SignInModal from '../Auth/SignInModal';
import { useNavigate } from 'react-router';
import './products.scss';
import { ChevronRight, ShoppingBagOutlined } from '@mui/icons-material';
import { fetchBagProducts } from '../../redux/actions/bag.action';
import _ from 'lodash';
import { snackbarInfo } from '../../redux/actions/snackbar.action';
import { motion } from 'framer-motion';
import MynyfyButton from '../../components/MynyfyButton';
import { Tooltip } from '@mui/material';

const BrandProductCartHelper = ({ navFrom, brandDetails, from, modalProps }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const bag = useSelector((state) => state.bag);
  const isLoggedIn = useSelector((state) => state.user?.isLoggedIn);

  const [openAddressModal, setOpenAddressModal] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [orderType, setOrderType] = useState(OrderType.HOME_DELIVERY);
  const [openSignIn, setOpenSignIn] = useState(false);
  const [animate, setAnimate] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  useEffect(() => {
    if (bag) {
      setAnimate(true);
      setTooltipOpen(true);
      const bounceTimeout = setTimeout(() => setAnimate(false), 4000);
      const tooltipTimeout = setTimeout(() => setTooltipOpen(false), 5000);
      return () => {
        clearTimeout(bounceTimeout);
        clearTimeout(tooltipTimeout);
      };
    }
  }, [bag]);

  const handelViewCart = ({ brand, address, orderType }) => {
    let sorted = _.orderBy(bag?.products, 'name', 'asc');
    dispatch(fetchBagProducts(sorted));
    let reqAddress = address || selectedAddress;
    let shippingType;
    if (orderType === OrderType.HOME_DELIVERY) {
      shippingType =
        brand?.city?.data == reqAddress.city
          ? 'insideCity'
          : brand?.state?.data == reqAddress.state
          ? 'insideState'
          : 'outsideState';
    }
    navigate('/BrandProducts/Bag', {
      state: {
        name: brand.brand,
        brandId: brand._id,
        orgPayModes: paymentModeHandler(brand),
        deleveryAddress: reqAddress,
        orderType,
        shippingType,
        policies: brand?.policies,
        // upiId: brand?.upiId,
        // upiQr: brand?.bankDetails?.upiQr?.url,
        // isOffline: [OrderType.DINE_IN, OrderType.TAKE_AWAY].includes(orderType),
        // dineTablesList: seller.dineTablesList,
        // table,
        isRestaurant: brandDetails?.category?.find(
          (e) => e._id == process.env.REACT_APP_FOOD_CAT_ID
        )
          ? true
          : false,
        isBrand: _.isEmpty(brandDetails?._id) ? false : true,
        brand: brandDetails,
        canAccess: true,
      },
    });
  };

  const paymentModeHandler = (brand) => {
    let modes = [];
    // if (brand.upi && brand.upiId) modes.push(PaymentMode.UPI_QR);
    // if (brand.cash) modes.push(PaymentMode.CASH);
    if (brand.razorpayAccountId) modes.push(PaymentMode.ONLINE);
    // if (table && !seller.receiveDineInPayment) {
    //   modes.push(PaymentMode.CASH);
    // } else {
    //   if (_.isEmpty(sellerDetails?.brands)) {
    //     if (seller.upi && seller.upiId) modes.push(PaymentMode.UPI_QR);
    //     if (seller.cash) modes.push(PaymentMode.CASH);
    //     if (seller.online && seller.razorpayAccountId) modes.push(PaymentMode.ONLINE);
    //   } else modes.push(PaymentMode.ONLINE);
    // }
    return modes;
  };

  const addressHandler = (e) => {
    if (e.orderType) setOrderType(e.orderType);
    else setSelectedAddress(e.address);

    if (navFrom == 'brand') {
      handelViewCart({
        brand: brandDetails,
        address: e.address,
        orderType: OrderType.HOME_DELIVERY,
      });
    } else {
      setOpenAddressModal(false);
    }
  };

  const cartHandler = () => {
    if (isLoggedIn) {
      setOpenAddressModal(true);
      // addressHandler({ orderType: OrderType.TAKE_AWAY });
      // setOpenAddressModal(true);
      // if (brandDetails?.pickUp && brandDetails?.homeDelivery) {
      //   setOpenAddressModal(true);
      // } else if (!brandDetails?.homeDelivery) {
      //   setOpenAddressModal(true);
      // } else if (brandDetails?.pickUp) {
      //   addressHandler({ orderType: OrderType.TAKE_AWAY });
      // }
    } else {
      setOpenSignIn(true);
    }
  };

  return (
    <Fragment>
      {bag?.calculation?.qty ? (
        <Fragment>
          {from == 'web' ? (
            <div
              id='Products'
              className='cursor'
              style={{ position: 'relative', marginLeft: 8, padding: 0 }}
              onClick={() => cartHandler()}>
              <Tooltip
                title='✅ Product added to bag!'
                open={tooltipOpen}
                placement='bottom'
                arrow
                componentsProps={{
                  tooltip: {
                    sx: {
                      backgroundColor: 'white',
                      borderRadius: '5px',
                      boxShadow: '0px 4px 15px 0px rgba(0, 0, 0, 0.1)',
                      border: '1px solid #e8e8e8',
                      color: '#5f5f5f',
                      padding: '15px',
                      fontSize: '20px',
                      fontFamily: 'MynyfyMedium',
                      marginRight: 2,
                    },
                  },
                }}>
                <motion.div
                  animate={animate ? { y: [0, -10, 0] } : {}}
                  transition={{
                    duration: 0.4,
                    repeat: animate ? 3 : 0,
                    ease: 'easeInOut',
                  }}>
                  <ShoppingBagOutlined style={{ fontSize: 30, display: 'block' }} />
                  <MynyfyText title={bag?.calculation?.qty || 0} className='webCartNum' />
                </motion.div>
              </Tooltip>
            </div>
          ) : from == 'modal' ? (
            <MynyfyButton
              label={'View bag'}
              startIcon={<ShoppingBagOutlined />}
              fullWidth
              onClick={() => cartHandler()}
              {...modalProps}
            />
          ) : (
            <div id='Products' style={{ padding: 0 }}>
              <div className='cartBtnContainer cursor' onClick={() => cartHandler()}>
                <div className='cartBtn rowSB'>
                  <MynyfyText
                    title={
                      (bag?.calculation?.qty || 0) +
                      (bag?.calculation?.qty == 1 ? ' Item ' : ' Items ') +
                      'Added'
                    }
                    color={'#fff'}
                    className='cartNum'
                  />
                  <div className='row'>
                    <MynyfyText title={'View Bag'} color={'#fff'} />
                    <ChevronRight style={{ color: '#fff' }} />
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* <SelectSeller
            isVisible={openSellersList}
            closeModal={() => setOpenSellersList(false)}
            brandId={bag?.id}
            selectedAddress={selectedAddress}
            selectedSeller={selectedSeller}
            setData={(e) => {
              setSelectedSeller(e);
              handelViewCart({ seller: e });
            }}
          /> */}
          <SelectAddress
            isVisible={openAddressModal}
            closeModal={() => setOpenAddressModal(false)}
            selectedAddress={selectedAddress}
            from={'brand'}
            fromId={_.isEmpty(brandDetails?.brands) ? bag?.id : brandDetails?._id}
            fromName={bag?.about?.name}
            isOffline={false}
            brandDetails={brandDetails}
            isRestaurant={
              brandDetails?.category?.find((e) => e._id == process.env.REACT_APP_FOOD_CAT_ID)
                ? true
                : false
            }
            orderType={orderType}
            setData={(e) => addressHandler(e)}
            props={{
              position: 'center',
              style: {
                borderRadius: 10,
              },
            }}
          />
        </Fragment>
      ) : null}

      <SignInModal
        open={openSignIn}
        close={() => setOpenSignIn(false)}
        isGuest={false}
        guestLoginForDineIn={() => addressHandler({ orderType: OrderType.DINE_IN })}
        brandDetails={{
          image: brandDetails?.banner?.[0]?.url,
          brandname: brandDetails?.brand,
        }}
      />
    </Fragment>
  );
};

export default BrandProductCartHelper;
