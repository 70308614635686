import React, { Fragment, useEffect, useState, useRef } from 'react';
import WebMynyfyHeader from '../../components/WebMynyfyHeader';
import { useLocation, useNavigate } from 'react-router';
import MynyfyText from '../../components/MynyfyText';
import MynyfyButton from '../../components/MynyfyButton';
import { Carousel } from 'react-responsive-carousel';
import { Button, CardContent } from '@mui/material';
import IosShareOutlinedIcon from '@mui/icons-material/IosShareOutlined';
import MynyfyLoader from '../../components/MynfyfLoader';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { snackbarInfo } from '../../redux/actions/snackbar.action';
import ProductCartHelper from '../Products/ProductCartHelper';
import MynyfyPre from '../../components/MynyfyPre';
import {
  ControlPointRounded,
  DiscountRounded,
  RemoveCircleOutlineRounded,
  FavoriteRounded,
} from '@mui/icons-material';
import { ReactComponent as VegIcon } from '../../assets/svg/veg.svg';
import { ReactComponent as NonVegIcon } from '../../assets/svg/nonVeg.svg';
import { ProductMaterialType } from '../../utils/enums';
import {
  addToBag,
  decrementHandler,
  incrementHandler,
  qtyHandler,
} from '../Products/AddProductHelper';
import { s3ToCloudFront } from '../../utils/CommonMethods';
const NO_OF_OFFERS = 3;

const WebBrandProductDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const route = useLocation();
  let productDetails = route.state?.details;
  let policies = route?.state?.sellerDetails?.policies || route?.state?.sellerDetails?.policies;

  const inputRef = useRef(null);
  const containerRef = useRef(null);

  const bag = useSelector((state) => state.bag);

  const [isMoreOffers, setMoreOffers] = useState(false);
  const [offers, setOffers] = useState(false);
  const [isAddBtnClicked, setAddBtnClicked] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState({});

  useEffect(() => {
    if (productDetails.variants?.length > 1) {
      let reqVariant = {};
      if (productDetails.variants[0]?.size) reqVariant.size = productDetails.variants[0]?.size;
      if (productDetails.variants[0]?.color) reqVariant.color = productDetails.variants[0]?.color;
      if (!_.isEmpty(reqVariant)) setSelectedOptions(reqVariant);
    }

    if (productDetails?.category) {
      if (!_.isEmpty(bag?.offers)) {
        let reqOffers = bag?.offers?.filter(
          (e) => e?.category?._id == productDetails?.category?._id || !e?.category
        );
        setOffers(reqOffers);
      }
    } else setOffers(bag?.offers);
  }, []);

  const shareUrl = async () => {
    if (bag?.about) {
      try {
        if (navigator.share) {
          await navigator.share({
            title: bag?.about?.name,
            text:
              `Now you can find all the products, offer, contest of ${bag?.about?.name} ${bag?.about?.type}` +
              '\n' +
              '\n' +
              `${bag?.about?.type} link : ${bag?.about?.url}`,
          });
        }
      } catch (error) {
        alert(error.message);
      }
    }
  };

  useEffect(() => {
    if (bag?.calculation?.qty && isAddBtnClicked) {
      containerRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
      setAddBtnClicked(false);
    }
  }, [bag?.calculation?.qty]);

  const selectVariant = (optionType, value) => {
    setSelectedOptions((prev) => ({ ...prev, [optionType]: value }));
  };

  const getUniqueAttributes = ({ variants, key, selectedKey, selectedValue }) => {
    return [
      ...new Set(
        variants.map((item) =>
          selectedValue ? item[selectedKey] == selectedValue && item[key] : item[key]
        )
      ),
    ]?.filter((e) => e);
  };

  const selectedVariant =
    productDetails.variants?.length > 1
      ? _.isEmpty(selectedOptions)
        ? productDetails.variants[0]
        : productDetails.variants.find(
            (f) => f.size === selectedOptions?.size && f.color === selectedOptions?.color
          )
      : productDetails.variants[0];

  return productDetails ? (
    <Fragment>
      <WebMynyfyHeader title={'Product Details'} />
      <ProductCartHelper
        sellerDetails={route.state?.sellerDetails}
        brandDetails={route.state?.brandDetails}
        navFrom={route.state?.navFrom}
        table={route?.state?.table}
      />
      <div
        id='Products'
        style={{ paddingBottom: bag?.calculation?.qty ? 75 : 0, gap: 0 }}
        ref={containerRef}>
        <div style={{ marginTop: 30, marginLeft: 50, marginRight: 50 }}>
          <div
            style={{
              display: 'flex',
              gap: 0,
              alignItems: 'flex-start',
              margin: 0,
              padding: 0,
            }}>
            {/* <div sx={{ maxWidth: 200 }}> */}
            {/* <div style={{ flexShrink: 0 }}> */}
            <Carousel
              infiniteLoop={true}
              autoPlay={true}
              showStatus={false}
              showArrows={false}
              showThumbs={false}
              interval={5000}>
              {selectedVariant?.image?.map((res, i) => (
                <div key={i} style={{ margin: 0, padding: 0 }}>
                  <img
                    src={s3ToCloudFront(res.url)}
                    alt={productDetails.name}
                    style={{ height: 350, width: 250 }}
                  />
                </div>
              ))}
            </Carousel>
            {/* </div> */}
            {/* </div> */}
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                // width: 'calc(50% - 20px)',
                flex: 1,

                margin: 0, // Remove any additional margin
                padding: 0, // Remove any padding
              }}>
              <div>
                <CardContent>
                  <div className='rowSB'>
                    <MynyfyText title={productDetails.name} h6 color={'#000000'} bold />
                    {selectedVariant?.videoUrl ? (
                      <div
                        onClick={() => {
                          let url = selectedVariant?.videoUrl;
                          const absoluteUrl =
                            url.startsWith('http://') || url.startsWith('https://')
                              ? url
                              : `https://${url}`;
                          window.open(absoluteUrl, '_blank', 'noopener,noreferrer');
                        }}>
                        <MynyfyText title={'View Video'} bold link />
                      </div>
                    ) : null}
                  </div>
                  {/* <MynyfyText title={productDetails.description} style={{ marginTop: 1 }} h6 /> */}
                  {/* <div className='rowSB'>
                    <div>
                      {selectedVariant?.actualPrice !== undefined && (
                        <MynyfyText
                          title={'Price Rs.' + selectedVariant.actualPrice}
                          style={{
                            marginTop: 10,
                            textDecoration:
                              selectedVariant?.price &&
                              selectedVariant.actualPrice !== selectedVariant.price
                                ? 'line-through'
                                : 'unset',
                          }}
                          bold={
                            selectedVariant?.price &&
                            selectedVariant.actualPrice !== selectedVariant.price
                              ? false
                              : true
                          }
                        />
                      )}

                      {selectedVariant?.actualPrice === selectedVariant?.price ||
                      !selectedVariant?.price ? null : (
                        <MynyfyText title={'Offer Price Rs.' + selectedVariant.price} bold />
                      )}

                      {selectedVariant?.price !== undefined &&
                        selectedVariant?.actualPrice !== selectedVariant?.price && (
                          <MynyfyText title={'Offer Price Rs.' + selectedVariant.price} bold />
                        )}
                    </div>
                  </div> */}
                  <div
                    className='product-details-container'
                    style={{ fontFamily: 'Arial, sans-serif', marginRight: '20px' }}>
                    {/* Product Title */}
                    {/* <h1 style={{ fontSize: '28px', fontWeight: 'bold', marginBottom: '10px' }}>
                      {productDetails.description}
                    </h1> */}
                    <MynyfyText
                      title={productDetails.description}
                      style={{
                        fontSize: '28px',
                        fontWeight: 'bold',
                        marginBottom: '10px',
                        color: 'black',
                      }}
                      h6
                    />
                    {/* Pricing Section */}
                    {/* <div style={{ marginBottom: '15px' }}> */}
                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                      <span style={{ fontSize: '24px', fontWeight: 'bold', color: '#7A1C2C' }}>
                        Rs. {selectedVariant?.price}
                      </span>
                      <span
                        style={{
                          fontSize: '18px',
                          textDecoration:
                            selectedVariant?.price &&
                            selectedVariant?.actualPrice !== selectedVariant?.price
                              ? 'line-through'
                              : 'unset',
                          color: '#888',
                        }}>
                        Rs. {selectedVariant?.actualPrice}
                      </span>
                      {selectedVariant?.actualPrice !== selectedVariant?.price && (
                        <span
                          style={{
                            fontSize: '14px',
                            fontWeight: 'bold',
                            color: '#fff',
                            backgroundColor: '#7A1C2C',
                            padding: '2px 6px',
                            borderRadius: '4px',
                          }}>
                          SAVE{' '}
                          {(
                            (1 - selectedVariant?.price / selectedVariant?.actualPrice) *
                            100
                          ).toFixed(0)}
                          %
                        </span>
                      )}
                    </div>
                    <div style={{ marginTop: '5px' }}>
                      <p>Tax included. Shipping calculated at checkout</p>
                    </div>
                    {/* </div> */}

                    {/* Add to Wishlist Button */}
                    <button
                      style={{
                        background: 'none',
                        border: 'none',
                        color: 'black',
                        font: '20',
                        // fontWeight: 'bold',
                        marginTop: '20px',
                        marginBottom: '10px',
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '5px',
                      }}>
                      <FavoriteRounded fontSize='h4' className='followBtn' /> Add to Wishlist
                    </button>
                    <div style={{ border: '1px black', marginTop: '5px' }}></div>
                    <div>
                      {productDetails.variants?.length > 1 ? (
                        <div>
                          <div style={{ background: '#F1F1F1', height: '5px' }}></div>
                          <div style={{ paddingBlock: '15px' }}>
                            <div style={{ margin: '0px 16px', marginTop: '5px' }}>
                              <MynyfyText title='Variants' color={'black'} />
                              <div className='row' style={{ gap: 10 }}>
                                {getUniqueAttributes({
                                  variants: productDetails.variants,
                                  key: 'size',
                                  selectedKey: 'color',
                                  selectedValue: selectedOptions?.color,
                                }).map((size) => (
                                  <MynyfyText
                                    key={size}
                                    title={size}
                                    className='chipBtn'
                                    isselected={selectedOptions?.size == size ? 'true' : 'false'}
                                    onClick={() => selectVariant('size', size)}
                                  />
                                ))}
                              </div>
                              <div className='row' style={{ gap: 10 }}>
                                {getUniqueAttributes({
                                  variants: productDetails.variants,
                                  key: 'color',
                                  selectedKey: 'size',
                                  selectedValue: selectedOptions?.size,
                                }).map((color) => (
                                  <div
                                    key={color}
                                    className='chipBtn colorChip'
                                    style={{ backgroundColor: color }}
                                    isselected={selectedOptions?.color == color ? 'true' : 'false'}
                                    onClick={() => selectVariant('color', color)}></div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>

                    <div
                      style={{
                        marginBottom: '15px',
                        marginTop: '10px',
                        color: 'black',
                        // fontWeight: 'bold',
                      }}>
                      In stock
                    </div>

                    {/* Delivery Check */}
                    <div style={{ marginBottom: '20px' }}>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          // gap: '10px',
                          marginBottom: '10px',
                        }}>
                        <span style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                          🚚 <span>Check Delivery</span>
                        </span>
                      </div>
                      <div style={{ display: 'flex', gap: '10px', marginBottom: '20px' }}>
                        <input
                          type='text'
                          placeholder='Enter Pincode'
                          style={{
                            border: '1px solid black',
                            borderRadius: '4px',
                            padding: '8px',
                            flexGrow: 1,
                            marginBottom: '20px',
                          }}
                        />
                        <button
                          style={{
                            backgroundColor: '#7A1C2C',
                            color: '#fff',
                            border: 'none',
                            borderRadius: '4px',
                            padding: '10px 20px',
                            fontWeight: 'bold',
                            cursor: 'pointer',
                            marginBottom: '20px',
                          }}>
                          Check Now
                        </button>
                      </div>
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginBottom: '8px',
                        marginTop: '2px',
                      }}>
                      <Button
                        style={{
                          background: ' #DABE9D',
                          padding: '15px',
                          borderRadius: '4px',
                          height: '40px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          marginBottom: '10px',
                        }}
                        onClick={() => shareUrl()}>
                        <IosShareOutlinedIcon style={{ color: 'black', fontSize: '20px' }} />
                      </Button>
                      <div style={{ flex: 1, height: '50px', marginRight: 0, width: '400px' }}>
                        <button
                          label={
                            (selectedVariant.count
                              ? selectedVariant.count - selectedVariant.ordersCount
                              : selectedVariant.count == 0
                              ? 0
                              : 'unlimited') == 0
                              ? 'Out Of Stock'
                              : 'ADD TO CART'
                          }
                          fullWidth
                          style={{
                            backgroundColor: ' #DABE9D',
                            color: 'black',
                            border: 'none',
                            borderRadius: '4px',
                            padding: '15px 20px',
                            fontWeight: 'bold',
                            cursor: 'pointer',
                            width: '528px', // Set a fixed width
                            height: '40px', // Keep height consistent
                            marginRight: '22px',
                            marginLeft: '5px',
                          }}
                          // className='addBagBtn'
                          onClick={() => {
                            if (route.state?.isEcommerceActive) {
                              addToBag({ productDetails, variant: selectedVariant, bag, dispatch });
                              setAddBtnClicked(true);
                            } else {
                              dispatch(
                                snackbarInfo({
                                  open: true,
                                  type: 'error',
                                  message: 'Currently not in service, please contact store',
                                })
                              );
                            }
                          }}
                          disabled={
                            (selectedVariant.count
                              ? selectedVariant.count - selectedVariant.ordersCount
                              : selectedVariant.count == 0
                              ? 0
                              : 'unlimited') == 0
                              ? true
                              : false
                          }>
                          Add to Bag
                        </button>
                      </div>
                    </div>
                  </div>
                  <div>
                    <button
                      style={{
                        backgroundColor: '#7A1C2C',
                        color: '#fff',
                        border: 'none',
                        borderRadius: '4px',
                        padding: '15px 20px',
                        fontWeight: 'bold',
                        cursor: 'pointer',
                        width: '600px', // Set a fixed width
                        height: '40px', // Keep height consistent
                        marginRight: '22px',
                      }}>
                      BUY IT NOW
                    </button>
                  </div>
                </CardContent>
              </div>

              <div>
                {policies?.shipping ? (
                  <div>
                    <div style={{ background: '#F1F1F1', height: '5px' }}></div>
                    <div style={{ paddingTop: '15px' }}>
                      <div className='rowSB' style={{ margin: '0px 16px', marginTop: '5px' }}>
                        <MynyfyText title='Delivery Details' pBig bold />
                        <MynyfyText
                          title='Check Details'
                          link
                          onClick={() => {
                            navigate(`/Policies/Delivery_Policy`, {
                              state: {
                                policy: [
                                  {
                                    heading: null,
                                    content: ['Delivery timeline will be shown in order screen'],
                                  },
                                ],
                                policy2: policies?.shipping,
                                policyName: `Delivery Policy`,
                              },
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                ) : null}
                <br />
                {_.isEmpty(offers) ? null : (
                  <div>
                    <div style={{ background: '#F1F1F1', height: '5px' }}></div>
                    <div style={{ paddingTop: '15px' }}>
                      <div style={{ margin: '0px 16px', marginTop: '5px' }}>
                        <div className='rowSB'>
                          <MynyfyText title=' Offers' pBig bold />
                          {isMoreOffers ? (
                            <MynyfyText
                              title={`less offers`}
                              link
                              onClick={() => setMoreOffers(!isMoreOffers)}
                            />
                          ) : offers?.length - NO_OF_OFFERS > 0 ? (
                            <MynyfyText
                              title={`+${offers?.length - NO_OF_OFFERS} More offers`}
                              link
                              onClick={() => setMoreOffers(!isMoreOffers)}
                            />
                          ) : null}
                        </div>
                        {offers
                          ?.slice(0, isMoreOffers ? offers?.length : NO_OF_OFFERS)
                          ?.map((res, i) => (
                            <div style={{ display: 'flex', marginTop: 5 }} key={i}>
                              <DiscountRounded style={{ padding: 5 }} />
                              <div style={{ padding: '5px 8px 8px' }}>
                                <MynyfyText
                                  style={{ paddingTop: 0 }}
                                  title={
                                    res.name + (res.description ? ' - ' + res.description : '')
                                  }
                                  className='poductOffersDescription'
                                />
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                    <br />
                  </div>
                )}
                {selectedVariant?.specification ? (
                  <div>
                    <div style={{ background: '#F1F1F1', height: '5px' }}></div>
                    <div style={{ paddingTop: '15px' }}>
                      <div style={{ margin: '0px 16px', marginTop: '5px' }}>
                        <div className='rowSB'>
                          <MynyfyText title='Product Details' pBig bold />
                          <MynyfyText title='+ More ' link />
                        </div>
                        <div style={{ paddingTop: 10 }}>
                          <MynyfyPre title={selectedVariant?.specification} color={'#000'} />
                        </div>
                      </div>
                    </div>
                    <br />
                  </div>
                ) : null}
                {policies?.isReturn || policies?.isExchange ? (
                  <div>
                    <div style={{ background: '#F1F1F1', height: '5px' }}></div>
                    <div style={{ paddingTop: '15px', paddingBottom: '15px' }}>
                      <div className='rowSB' style={{ margin: '0px 16px', marginTop: '5px' }}>
                        <MynyfyText
                          title='Return/Refund/Exchange Details'
                          style={{ marginRight: 10 }}
                          pBig
                          bold
                        />
                        <MynyfyText
                          title='Check Details'
                          link
                          style={{ whiteSpace: 'nowrap' }}
                          onClick={() => {
                            navigate(`/Policies/Return_Refund_Exchange_Policy`, {
                              state: {
                                policy: policies?.refund,
                                policy2: policies?.exchange,
                                policyName: `Return / Refund / Exchange Policy`,
                              },
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
              <div>
                <div style={{ background: '#F1F1F1', height: '5px' }}></div>
              </div>
              <div className='row' style={{ padding: '10px 20px 15px' }}>
                {/* <Button
                  style={{ background: '#C5C5C5', padding: '15px', borderRadius: '10px' }}
                  onClick={() => shareUrl()}>
                  <IosShareOutlinedIcon style={{ color: 'black', fontSize: '20px' }} />
                </Button> */}
                {/* <div style={{ margin: '0px 10px 0px 15px' }}>
            <Button style={{ background: '#C5C5C5', padding: '15px', borderRadius: '10px' }}>
              <FavoriteBorderOutlinedIcon style={{ color: 'black', fontSize: '20px' }} />
            </Button>
          </div> */}
                {bag?.products?.find(
                  (e) => e.productId == productDetails._id && e.variantId == selectedVariant._id
                ) ? (
                  <div className='rowC' style={{ margin: '0px 10px', flex: 1 }}>
                    <RemoveCircleOutlineRounded
                      color='primary'
                      fontSize='medium'
                      className='cursor'
                      onClick={() => {
                        decrementHandler({
                          productDetails,
                          variant: selectedVariant,
                          bag,
                          dispatch,
                        });
                        inputRef.current.focus();
                      }}
                    />
                    <input
                      value={
                        bag?.products?.find(
                          (e) =>
                            e.productId == productDetails._id && e.variantId == selectedVariant._id
                        )?.count || 0
                      }
                      onChange={(e) => {
                        qtyHandler({
                          productDetails,
                          variant: selectedVariant,
                          value: e.target.value,
                          bag,
                          dispatch,
                        });
                      }}
                      autoFocus
                      ref={(el) => (inputRef.current = el)}
                      className='qtyInput detailsInput'
                    />
                    <ControlPointRounded
                      color='primary'
                      fontSize='medium'
                      className='cursor'
                      onClick={() => {
                        incrementHandler({
                          productDetails,
                          variant: selectedVariant,
                          bag,
                          dispatch,
                        });
                        inputRef.current.focus();
                      }}
                    />
                  </div>
                ) : (
                  <div style={{ flex: 1, marginLeft: 10 }}>
                    {/* <MynyfyButton
                      label={
                        (selectedVariant.count
                          ? selectedVariant.count - selectedVariant.ordersCount
                          : selectedVariant.count == 0
                          ? 0
                          : 'unlimited') == 0
                          ? 'Out Of Stock'
                          : 'Add to Bag'
                      }
                      fullWidth
                      className='addBagBtn'
                      onClick={() => {
                        if (route.state?.isEcommerceActive) {
                          addToBag({ productDetails, variant: selectedVariant, bag, dispatch });
                          setAddBtnClicked(true);
                        } else {
                          dispatch(
                            snackbarInfo({
                              open: true,
                              type: 'error',
                              message: 'Currently not in service please contact store',
                            })
                          );
                        }
                      }}
                      disabled={
                        (selectedVariant.count
                          ? selectedVariant.count - selectedVariant.ordersCount
                          : selectedVariant.count == 0
                          ? 0
                          : 'unlimited') == 0
                          ? true
                          : false
                      }
                    /> */}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div></div>
        </div>
      </div>

      <div></div>
    </Fragment>
  ) : (
    <MynyfyLoader />
  );
};

export default WebBrandProductDetails;
