import React, { Fragment, useState } from 'react';
import FilterListIcon from '@mui/icons-material/FilterList';
import MynyfyInput from './MynyfyInput';
import MynyfySelect from './MynyfySelect';
import MynyfyModal from './MynyfyModal';
import MynyfyRadioButton from './MynyfyRadioButton';
import commonService from '../services/commonService';
import MynyfyButton from './MynyfyButton';
import { SortTypes } from '../utils/enums';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import MynyfyText from './MynyfyText';
import { ReactComponent as VegIcon } from '../assets/svg/veg.svg';
import { ReactComponent as NonVegIcon } from '../assets/svg/nonVeg.svg';
import './common.scss';

const MynyfyMobileFilter = ({
  searchAndCatFilter,
  sortFilter,
  isMynts,
  isPrice,
  isFood,
  brands,
  style,
  userCategories,
}) => {
  const allCategories = useSelector((state) => state.appData?.categories);
  let categories = _.isEmpty(userCategories)
    ? allCategories
    : userCategories?.filter((e) => e.code === 'Category');

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [subcategories, setSubCategories] = useState([]);
  const [microCategories, setMicroCategories] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [selectedMicroCategory, setSelectedMicroCategory] = useState(null);
  const [selectedFoodType, setSelectedFoodType] = useState(null);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [searchInput, setSearchInput] = useState(null);
  const [selectedSort, setSelectedSort] = useState(false);
  const [openSortModal, setOpenSortModal] = useState(false);
  const [selectedTab, setSelectedTab] = useState(
    isMynts ? 'searchByMynyts' : isPrice ? 'searchByPrice' : 'searchByCategory'
  );

  const appDataApi = (key, value) => {
    commonService.publicCategorySort(value).then((res) => {
      let sortedData = _.orderBy(res, ['name'], ['asc']);
      if (key == 'subCat') setSubCategories(sortedData);
      else if (key == 'microCat') setMicroCategories(sortedData);
    });
  };

  const onCategorySelected = (cat) => {
    setSelectedCategory(cat);
    setSelectedSubCategory(null);
    setSelectedMicroCategory(null);
    setSubCategories([]);
    setMicroCategories([]);
    if (cat) {
      appDataApi('subCat', cat.data);
    }
  };

  const onSubCategorySelected = (cat) => {
    setSelectedSubCategory(cat);
    setSelectedMicroCategory(null);
    setMicroCategories([]);
    if (cat) appDataApi('microCat', cat.data);
  };

  const onSortTypeSelected = (e) => {
    setSelectedSort(e);
    sortFilter(e);
    setOpenSortModal(false);
  };

  const foodFilterHandler = (e) => {
    setSelectedFoodType(e);
    setOpenSortModal(false);
    searchAndCatFilterHandler(searchInput, e);
  };

  const onApply = () => {
    if (selectedTab === 'searchByCategory') {
      searchAndCatFilterHandler(searchInput);
    } else if (selectedTab === 'searchByBrand') {
      searchAndCatFilterHandler(searchInput, selectedFoodType);
    }
    setOpenSortModal(false);
  };

  const searchAndCatFilterHandler = (searchedText, foodType) => {
    searchAndCatFilter({
      searchedText,
      selectedCategory,
      selectedSubCategory,
      selectedMicroCategory,
      selectedFoodType: foodType || selectedFoodType,
      selectedSort,
      selectedBrand,
    });
  };

  return (
    <div id='MynyfyMobileFilter'>
      <div className='rowSB filterMobileContainer' style={{ ...style }}>
        <MynyfyInput
          fullWidth={true}
          placeHolder={'Search'}
          value={searchInput}
          onChange={(e) => {
            setSearchInput(e.target.value);
            searchAndCatFilterHandler(e.target.value);
          }}
          style={{ padding: 0 }}
        />

        <div>
          <FilterListIcon
            color='primary'
            className='cursor'
            onClick={() => setOpenSortModal(!openSortModal)}
          />
        </div>
      </div>

      <MynyfyModal open={openSortModal} close={() => setOpenSortModal(false)}>
        <div id='MynyfyMobileFilter'>
          <MynyfyText title={'Filters'} bold style={{ marginBottom: 10 }} h5 center />
          <div
            className='row'
            style={{ borderTop: '1px solid gray', borderBottom: '1px solid gray' }}>
            <div
              style={{
                borderRight: '1px solid gray',
                paddingTop: '20px',
                paddingBottom: '20px',
                flex: 0.4,
              }}>
              {isMynts ? (
                <div
                  className={`searchOption ${selectedTab === 'searchByMynyts' ? 'active' : ''}`}
                  onClick={() => setSelectedTab('searchByMynyts')}>
                  <MynyfyText title={'Sort by Mynts'} h6 cursor style={{ paddingLeft: 10 }} />
                </div>
              ) : null}
              {isPrice ? (
                <div
                  className={`searchOption ${selectedTab === 'searchByPrice' ? 'active' : ''}`}
                  onClick={() => setSelectedTab('searchByPrice')}>
                  <MynyfyText title={'Sort by Price'} h6 style={{ paddingLeft: 5 }} />
                </div>
              ) : null}
              <div
                className={`searchOption ${selectedTab === 'searchByCategory' ? 'active' : ''}`}
                onClick={() => setSelectedTab('searchByCategory')}>
                <MynyfyText title={'Category'} h6 cursor style={{ paddingLeft: 5 }} />
              </div>
              {!_.isEmpty(brands) && isFood === false ? (
                <div
                  className={`searchOption ${selectedTab === 'searchByBrand' ? 'active' : ''}`}
                  onClick={() => setSelectedTab('searchByBrand')}>
                  <MynyfyText title={'Brands'} h6 cursor style={{ paddingLeft: 5 }} />
                </div>
              ) : null}
              <div
                className={`searchOption ${selectedTab === 'searchByFood' ? 'active' : ''}`}
                onClick={() => setSelectedTab('searchByFood')}>
                {isFood && <MynyfyText title={'Sort by Food'} h6 cursor />}
              </div>
            </div>

            <div style={{ paddingLeft: 20, flex: 0.6, paddingTop: '20px', paddingBottom: '20px' }}>
              {isMynts && selectedTab === 'searchByMynyts' ? (
                <Fragment>
                  <MynyfyRadioButton
                    title={SortTypes.LOW_TO_HIGH_MYNTS}
                    onClick={() => onSortTypeSelected(SortTypes.LOW_TO_HIGH_MYNTS)}
                    isSelected={selectedSort === SortTypes.LOW_TO_HIGH_MYNTS ? true : false}
                    style={{ paddingBottom: 1 }}
                  />
                  <MynyfyRadioButton
                    title={SortTypes.HIGH_TO_LOW_MYNTS}
                    onClick={() => onSortTypeSelected(SortTypes.HIGH_TO_LOW_MYNTS)}
                    isSelected={selectedSort === SortTypes.HIGH_TO_LOW_MYNTS ? true : false}
                    style={{ paddingBottom: 1 }}
                  />
                </Fragment>
              ) : null}

              {isPrice && selectedTab === 'searchByPrice' ? (
                <Fragment>
                  <MynyfyRadioButton
                    title={SortTypes.LOW_TO_HIGH_PRICE}
                    onClick={() => onSortTypeSelected(SortTypes.LOW_TO_HIGH_PRICE)}
                    isSelected={selectedSort === SortTypes.LOW_TO_HIGH_PRICE ? true : false}
                    style={{ paddingBottom: 1 }}
                  />
                  <MynyfyRadioButton
                    title={SortTypes.HIGH_TO_LOW_PRICE}
                    onClick={() => onSortTypeSelected(SortTypes.HIGH_TO_LOW_PRICE)}
                    isSelected={selectedSort === SortTypes.HIGH_TO_LOW_PRICE ? true : false}
                    style={{ paddingBottom: 1 }}
                  />
                </Fragment>
              ) : null}
              {selectedTab === 'searchByCategory' ? (
                <div style={{ margin: '10px 0px' }}>
                  <MynyfySelect
                    placeholder={'Main Category'}
                    value={selectedCategory}
                    onChange={(e) => onCategorySelected(e)}
                    list={
                      categories?.map((res) => ({
                        label: res.name,
                        value: res._id,
                        data: res.data,
                      })) || []
                    }
                    isClear
                  />
                  <MynyfySelect
                    placeholder={'Category'}
                    value={selectedSubCategory}
                    onChange={(e) => onSubCategorySelected(e)}
                    list={subcategories.map((res) => ({
                      label: res.name,
                      value: res._id,
                      data: res.data,
                    }))}
                    isClear
                  />
                  <MynyfySelect
                    placeholder={'Sub Category'}
                    value={selectedMicroCategory}
                    onChange={(e) => setSelectedMicroCategory(e)}
                    list={microCategories.map((res) => ({
                      label: res.name,
                      value: res._id,
                      data: res.data,
                    }))}
                    isClear
                  />
                </div>
              ) : null}
              {selectedTab === 'searchByBrand' ? (
                <div style={{ margin: '10px 0px' }}>
                  <MynyfySelect
                    placeholder={'Brands'}
                    value={selectedBrand}
                    onChange={(e) => setSelectedBrand(e)}
                    list={brands?.map((res) => ({ label: res?.name, value: res?._id }))}
                    isClear
                  />
                </div>
              ) : null}
              {isFood && selectedTab === 'searchByFood' ? (
                <Fragment>
                  <div className='row' style={{ paddingBottom: 5, marginTop: -5 }}>
                    <MynyfyRadioButton
                      title={SortTypes.ONLY_VEG}
                      onClick={() => foodFilterHandler(SortTypes.ONLY_VEG)}
                      isSelected={selectedFoodType === SortTypes.ONLY_VEG ? true : false}
                    />
                    <VegIcon width={20} height={20} style={{ marginLeft: 5 }} />
                  </div>
                  <div className='row' style={{ paddingBottom: 5 }}>
                    <MynyfyRadioButton
                      title={SortTypes.ONLY_NON_VEG}
                      onClick={() => foodFilterHandler(SortTypes.ONLY_NON_VEG)}
                      isSelected={selectedFoodType === SortTypes.ONLY_NON_VEG ? true : false}
                    />
                    <NonVegIcon width={20} height={20} style={{ marginLeft: 5 }} />
                  </div>
                  <MynyfyRadioButton
                    title={SortTypes.ALL}
                    onClick={() => foodFilterHandler(SortTypes.ALL)}
                    isSelected={selectedFoodType === SortTypes.ALL ? true : false}
                    style={{ paddingBottom: 1 }}
                  />
                </Fragment>
              ) : null}
            </div>
          </div>
          <MynyfyButton
            label={'Apply'}
            fullWidth
            style={{
              borderRadius: 15,
              height: 35,
              backgroundColor: 'rgba(0, 84, 139, 1)',
              marginTop: 7,
            }}
            onClick={() => onApply()}
          />
          <MynyfyButton
            label={'clear'}
            style={{
              backgroundColor: 'transparent',
              color: 'grey',
              textDecoration: 'underline',
              height: 30,
            }}
            center
            onClick={() => setOpenSortModal(false)}
          />
        </div>
      </MynyfyModal>
    </div>
  );
};

export default MynyfyMobileFilter;
