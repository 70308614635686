import React, { useState, useEffect, Fragment } from 'react';
import MynyfyText from './MynyfyText';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import MynyfySelect from './MynyfySelect';
import commonService from '../services/commonService';
import { SortTypes } from '../utils/enums';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { ReactComponent as VegIcon } from '../assets/svg/veg.svg';
import { ReactComponent as NonVegIcon } from '../assets/svg/nonVeg.svg';
import { Close } from '@mui/icons-material';
import MynyfyRadioButton from './MynyfyRadioButton';

const WebMynyfyVerFilter = ({
  searchAndCatFilter,
  sortFilter,
  brands,
  userCategories,
  isFood,
  handleClearAllFilters,
}) => {
  const allCategories = useSelector((state) => state.appData?.categories);
  let categories = _.isEmpty(userCategories)
    ? allCategories
    : userCategories?.filter((e) => e.code === 'Category');
  const [available, setAvalable] = useState(false);
  const [priceExpanded, setPriceExpanded] = useState(false);
  const [brandExpanded, setBrandExpanded] = useState(false);
  const [categoryExpanded, setCategoryExpanded] = useState(false);
  const [foodSortExpanded, setFoodSortExpanded] = useState(false);

  const [selectedMainCategory, setSelectedMainCategory] = useState(null);
  const [subcategories, setSubCategories] = useState([]);
  const [microCategories, setMicroCategories] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [selectedMicroCategory, setSelectedMicroCategory] = useState(null);
  const [selectedFoodType, setSelectedSortedFood] = useState(null);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [searchInput, setSearchInput] = useState(null);
  const [selectedSort, setSelectedSort] = useState(false);
  const [allFilterValues, setAllFilterValues] = useState([]);
  const [selectedInStock, setSelectedInStock] = useState(null);
  const [selectedOutStock, setSelectedOutStock] = useState(null);

  const appDataApi = (key, value) => {
    commonService.publicCategorySort(value).then((res) => {
      let sortedData = _.orderBy(res, ['name'], ['asc']);
      if (key == 'subCat') setSubCategories(sortedData);
      else if (key == 'microCat') setMicroCategories(sortedData);
    });
  };
  const onCategorySelected = (cat) => {
    setSelectedMainCategory(cat);
    setSelectedSubCategory(null);
    setSelectedMicroCategory(null);
    setSubCategories([]);
    setMicroCategories([]);
    if (cat) {
      appDataApi('subCat', cat.data);
    }
  };

  useEffect(() => {
    searchAndCatFilter({
      selectedInStock,
      selectedOutStock,
      selectedCategory: selectedMainCategory,
      selectedSubCategory,
      selectedMicroCategory,
      selectedFoodType: selectedFoodType,
      selectedSort,
      selectedBrand,
    });
  }, [
    selectedInStock,
    selectedOutStock,
    selectedMainCategory,
    selectedSubCategory,
    selectedMicroCategory,
    selectedSort,
    selectedBrand,
    selectedFoodType,
  ]);

  const onSubCategorySelected = (cat) => {
    setSelectedSubCategory(cat);
    setSelectedMicroCategory(null);
    setMicroCategories([]);
    if (cat) appDataApi('microCat', cat.data);
  };
  const onSortTypeSelected = (e) => {
    setSelectedSort(e);
    sortFilter(e);
  };

  // Handle checkbox toggle
  const handleCheckboxChange = (value) => {
    setAllFilterValues((prev) => {
      if (value === SortTypes.IN_STOCK) {
        if (selectedInStock) {
          return prev.filter((filter) => !filter.InStock);
        } else {
          return [...prev, { InStock: true }];
        }
      } else if (value === SortTypes.OUT_OF_STOCK) {
        if (selectedOutStock) {
          return prev.filter((filter) => !filter.OutOfStock);
        } else {
          return [...prev, { OutOfStock: true }];
        }
      }
      return prev;
    });
    if (value === SortTypes.IN_STOCK) {
      setSelectedInStock((prev) => !prev);
    } else if (value === SortTypes.OUT_OF_STOCK) {
      setSelectedOutStock((prev) => !prev);
    }
  };

  // Handle radio button change
  const handleRadioChange = (value) => {
    setAllFilterValues((prev) => {
      const updatedFilters = prev.filter((item) => !item.Price);
      return [...updatedFilters, { Price: value }];
    });
  };

  // Handle brand selection
  const handleBrandChange = (selectedBrand) => {
    setAllFilterValues((prev) => {
      const updatedFilters = prev.filter((item) => !item.brand);
      return selectedBrand ? [...updatedFilters, { Brand: selectedBrand }] : updatedFilters;
    });
  };

  //Handling Main Category Selection/Removal
  const handleMainCategoryChange = (mainCategory) => {
    setAllFilterValues((prev) => {
      if (!mainCategory) {
        return prev.filter(
          (item) => !item.MainCategory && !item.SubCategory && !item.MicroCategory
        );
      }
      const isSelected = prev.some((item) => item.MainCategory === mainCategory);
      if (isSelected) {
        return prev.filter(
          (item) =>
            item.MainCategory !== mainCategory &&
            item.SubCategory !== mainCategory &&
            item.MicroCategory !== mainCategory
        );
      } else {
        // Add main category
        return [...prev, { MainCategory: mainCategory }];
      }
    });
  };

  const handleSubCategoryChange = (subcategory) => {
    setAllFilterValues((prev) => {
      if (!subcategory) {
        return prev.filter((item) => !item.SubCategory && !item.MicroCategory);
      }
      const isSelected = prev.some((item) => item.SubCategory === subcategory);
      if (isSelected) {
        return prev.filter(
          (item) => item.SubCategory !== subcategory && item.MicroCategory !== subcategory
        );
      } else {
        return [...prev, { SubCategory: subcategory }];
      }
    });
  };

  const handleMicroCategoryChange = (microCategory) => {
    setAllFilterValues((prev) => {
      if (!microCategory) {
        return prev.filter((item) => !item.MicroCategory);
      }
      const isSelected = prev.some((item) => item.MicroCategory === microCategory);
      if (isSelected) {
        return prev.filter((item) => item.MicroCategory !== microCategory);
      } else {
        return [...prev, { MicroCategory: microCategory }];
      }
    });
  };

  // const foodFilterHandler = (e) => {
  //   setSelectedSortedFood(e);
  //   searchAndCatFilterHandler(e);
  // };

  const handleFoodRadioChange = (value) => {
    setAllFilterValues((prev) => {
      const updatedFilters = prev.filter((item) => !item.SortByFood);
      return [...updatedFilters, { SortByFood: value }];
    });
  };

  // Handle individual tag removal
  const handleRemoveTag = (filter) => {
    if (!filter || typeof filter !== 'object' || Object.entries(filter).length === 0) {
      console.error('Invalid filter passed to handleRemoveTag:', filter);
      return;
    }

    // Extract key-value pair from the filter
    const [key, value] = Object.entries(filter)[0];

    switch (key) {
      case 'InStock':
        setSelectedInStock(false);
        break;

      case 'OutOfStock':
        setSelectedOutStock(false);
        break;

      case 'Price':
        setSelectedSort(null);
        break;

      case 'Brand':
        setSelectedBrand(null);
        break;

      case 'MainCategory':
        setSelectedMainCategory(null);
        setSelectedSubCategory(null);
        setSelectedMicroCategory(null);
        setAllFilterValues((prev) =>
          prev.filter(
            (item) =>
              item.MainCategory !== value &&
              item.SubCategory !== value &&
              item.MicroCategory !== value
          )
        );
        break;

      case 'SubCategory':
        setSelectedSubCategory(null);
        setSelectedMicroCategory(null);
        setAllFilterValues((prev) =>
          prev.filter((item) => item.SubCategory !== value && item.MicroCategory !== value)
        );
        break;

      case 'MicroCategory':
        setSelectedMicroCategory(null);
        setAllFilterValues((prev) => prev.filter((item) => item.MicroCategory !== value));
        break;
      case 'SortByFood':
        setSelectedSortedFood(null);
        setAllFilterValues((prev) => prev.filter((item) => item.SortedFood !== value));
        break;

      default:
        console.warn('Unknown filter key:', key);
        break;
    }

    // Remove the filter from allFilterValues if it matches exactly
    setAllFilterValues((prev) => prev.filter((item) => item !== filter));
  };

  // Clear all selected filters
  const handleClearAll = () => {
    setAllFilterValues([]);
    setSelectedInStock(false);
    setSelectedOutStock(false);
    setSelectedSort(null);
    setSelectedBrand(null);
    setSelectedMainCategory(null);
    setSelectedSubCategory(null);
    setSelectedMicroCategory(null);
    setSelectedSortedFood(null);
    handleClearAllFilters();
  };

  return (
    <div
      id='WebMynyfyVerFilter'
      className='verticalFilters'
      style={{
        flex: 2,
        maxWidth: '20%',
        paddingRight: '10px',
        overflowY: 'auto',
        position: 'sticky',
        height: '100vh',
        marginRight: 40,
        marginLeft: 40,
        paddingBottom: 40,
      }}>
      <MynyfyText title={'Filters'} h3 style={{ color: 'black' }} />
      <div className='divider' style={{ margin: '15px 0px' }}></div>
      {/* Selected Filters Display */}
      <div className='row' style={{ flexWrap: 'wrap' }}>
        {allFilterValues.map((filter, index) => (
          <div
            key={index}
            style={{
              display: 'flex',
              alignItems: 'center',
              backgroundColor: '#e9eaec',
              borderRadius: '4px',
              padding: '5px 10px',
              marginRight: '10px',
            }}>
            {Object.entries(filter).map(([key, value]) =>
              key === 'OutOfStock' || key === 'InStock' ? (
                <MynyfyText
                  key={key}
                  title={`${key}`} // E.g., "InStock"
                  style={{ color: 'black' }}
                />
              ) : (
                <MynyfyText key={key} title={`${value}`} style={{ color: 'black' }} />
              )
            )}
            <Close
              style={{
                cursor: 'pointer',
                marginLeft: '8px',
                color: 'black',
              }}
              onClick={() => handleRemoveTag(filter)}
              fontSize='15'
            />
          </div>
        ))}
        {/* Clear All Button */}
        {allFilterValues.length > 1 && (
          <button
            style={{
              marginTop: '10px',
              color: 'black',
              border: 'none',
              cursor: 'pointer',
              textDecoration: 'underline',
            }}
            onClick={handleClearAll}>
            Clear All
          </button>
        )}
      </div>

      <div>
        <div className='rowSB cursor' onClick={() => setAvalable(!available)}>
          <MynyfyText title={'Availability'} h6 style={{ color: 'black' }} />
          {available ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </div>
        {available && (
          <div style={{ marginTop: '15px' }}>
            <div
              className='row'
              style={{ marginBottom: '10px' }}
              onClick={() => handleCheckboxChange(SortTypes.IN_STOCK)}>
              <input
                type='checkbox'
                id='InStock'
                // checked={allFilterValues.includes('InStock')}
                checked={selectedInStock}
                style={{ height: 15, width: 15, marginRight: '5px' }}
              />
              <label htmlFor='InStock'>In Stock</label>
            </div>
            <div
              className='row'
              style={{ marginBottom: '10px' }}
              onClick={() => handleCheckboxChange(SortTypes.OUT_OF_STOCK)}>
              <input
                type='checkbox'
                id='OutOfStock'
                // checked={allFilterValues.includes('OutOfStock')}
                checked={selectedOutStock}
                style={{ height: 15, width: 15, marginRight: '5px' }}
              />
              <label htmlFor='OutOfStock'>Out Of Stock</label>
            </div>
          </div>
        )}
      </div>
      <div className='divider' style={{ margin: '15px 0px' }}></div>
      {/* Price Filter */}
      <div>
        <div
          className='rowSB'
          onClick={() => setPriceExpanded(!priceExpanded)}
          style={{ cursor: 'pointer' }}>
          <MynyfyText title='Price' h6 style={{ color: 'black' }} />
          {priceExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </div>
        {priceExpanded && (
          <div style={{ marginTop: '15px' }}>
            <MynyfyRadioButton
              title={SortTypes.LOW_TO_HIGH_PRICE}
              onClick={() => {
                onSortTypeSelected(SortTypes.LOW_TO_HIGH_PRICE);
                handleRadioChange(SortTypes.LOW_TO_HIGH_PRICE);
              }}
              isSelected={selectedSort === SortTypes.LOW_TO_HIGH_PRICE ? true : false}
              style={{ paddingBottom: 1, fontSize: 14 }}
            />
            <MynyfyRadioButton
              title={SortTypes.HIGH_TO_LOW_PRICE}
              onClick={() => {
                onSortTypeSelected(SortTypes.HIGH_TO_LOW_PRICE);
                handleRadioChange(SortTypes.HIGH_TO_LOW_PRICE);
              }}
              isSelected={selectedSort === SortTypes.HIGH_TO_LOW_PRICE ? true : false}
              style={{ paddingBottom: 1 }}
            />
          </div>
        )}
      </div>
      <div className='divider' style={{ margin: '15px 0px' }}></div>
      {/* Brand Filter */}
      {isFood || _.isEmpty(brands) ? null : (
        <Fragment>
          <div>
            <div
              className='rowSB'
              onClick={() => setBrandExpanded(!brandExpanded)}
              style={{ cursor: 'pointer' }}>
              <MynyfyText title='Brand' h6 style={{ color: 'black' }} />
              {brandExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </div>
            {brandExpanded && (
              <div style={{ marginTop: '15px' }}>
                <div style={{ marginBottom: '10px', width: '100%' }}>
                  <MynyfySelect
                    placeholder={'Brands'}
                    value={selectedBrand}
                    // value={selectedBrand ? { label: selectedBrand, value: selectedBrand } : null}
                    onChange={(e) => {
                      setSelectedBrand(e);
                      handleBrandChange(e?.label);
                    }}
                    // onChange={(e) => handleBrandChange(e?.label)}
                    list={brands?.map((res) => ({ label: res?.name, value: res?._id }))}
                    isClear
                  />
                </div>
              </div>
            )}
          </div>
          <div className='divider' style={{ margin: '15px 0px' }}></div>
        </Fragment>
      )}
      {/* Category Filter */}
      <div>
        <div
          className='rowSB'
          onClick={() => setCategoryExpanded(!categoryExpanded)}
          style={{ cursor: 'pointer' }}>
          <MynyfyText title='Category' h6 style={{ color: 'black' }} />
          {categoryExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </div>
        {categoryExpanded && (
          <div style={{ marginTop: '15px' }}>
            <div style={{ marginBottom: '10px', width: '100%' }}>
              <MynyfySelect
                placeholder={'Main Category'}
                value={selectedMainCategory}
                onChange={(e) => {
                  console.log('Selected Cat: ', e);
                  onCategorySelected(e);
                  handleMainCategoryChange(e?.label);
                }}
                list={
                  categories?.map((res) => ({ label: res.name, value: res._id, data: res.data })) ||
                  []
                }
                isClear
              />
              {!_.isEmpty(subcategories) && (
                <MynyfySelect
                  placeholder={'Category'}
                  value={selectedSubCategory}
                  onChange={(e) => {
                    onSubCategorySelected(e);
                    handleSubCategoryChange(e?.label);
                  }}
                  list={subcategories.map((res) => ({
                    label: res.name,
                    value: res._id,
                    data: res.data,
                  }))}
                  isClear
                />
              )}
              {!_.isEmpty(microCategories) && (
                <MynyfySelect
                  placeholder={'Sub Category'}
                  value={selectedMicroCategory}
                  onChange={(e) => {
                    setSelectedMicroCategory(e);
                    handleMicroCategoryChange(e.label);
                  }}
                  list={microCategories.map((res) => ({
                    label: res.name,
                    value: res._id,
                    data: res.data,
                  }))}
                  isClear
                />
              )}
            </div>
          </div>
        )}
        <div className='divider' style={{ margin: '15px 0px' }}></div>

        {/* sort by food */}
        {isFood ? (
          <div>
            <div
              className='rowSB'
              onClick={() => setFoodSortExpanded(!foodSortExpanded)}
              style={{ cursor: 'pointer' }}>
              <MynyfyText title='Sort by Food' h6 style={{ color: 'black' }} />
              {foodSortExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </div>
            {foodSortExpanded && (
              <div style={{ marginTop: '15px' }}>
                <MynyfyRadioButton
                  title={SortTypes.ONLY_VEG}
                  isSelected={selectedFoodType === SortTypes.ONLY_VEG ? true : false}
                  onClick={() => {
                    // foodFilterHandler(SortTypes.ONLY_VEG);
                    setSelectedSortedFood(SortTypes.ONLY_VEG);
                    handleFoodRadioChange(SortTypes.ONLY_VEG);
                  }}
                  style={{ paddingBottom: 1, fontSize: 14 }}
                />
                <MynyfyRadioButton
                  title={SortTypes.ONLY_NON_VEG}
                  onClick={() => {
                    setSelectedSortedFood(SortTypes.ONLY_NON_VEG);
                    handleFoodRadioChange(SortTypes.ONLY_NON_VEG);
                  }}
                  isSelected={selectedFoodType === SortTypes.ONLY_NON_VEG ? true : false}
                  style={{ paddingBottom: 1 }}
                />
                <MynyfyRadioButton
                  title={SortTypes.ALL}
                  onClick={() => {
                    setSelectedSortedFood(SortTypes.ALL);
                    handleFoodRadioChange(SortTypes.ALL);
                  }}
                  isSelected={selectedFoodType === SortTypes.ALL ? true : false}
                  style={{ paddingBottom: 1 }}
                />
              </div>
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default WebMynyfyVerFilter;
