import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import MynyfyText from '../../components/MynyfyText';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import _ from 'lodash';
import MynyfyEmptyMsg from '../../components/MynyfyEmptyMsg';
import MynyfyLoader from '../../components/MynfyfLoader';
import MynyfyHeader from '../../components/MynyfyHeader';
import './stores.scss';
import { Card, CircularProgress, Drawer } from '@mui/material';
import {
  FavoriteBorderRounded,
  FavoriteRounded,
  ContactPhoneOutlined,
  StyleOutlined,
  ChevronLeftRounded,
  Facebook,
  Instagram,
  YouTube,
  WhatsApp,
  ChevronRightRounded,
  KeyboardDoubleArrowRight,
} from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { userProfile } from '../../redux/actions/user.action';
import { snackbarInfo } from '../../redux/actions/snackbar.action';
import MynyfyTab from '../../components/MynyfyTab';
import {
  fetchFavOfflineStores,
  fetchFavStores,
  fetchOfflineStores,
  fetchStores,
} from '../../redux/actions/store.action';
import { likeStore, unlikeStore } from './StoresHelper';
import StoreAddress from './StoreAddress';
import storeService from '../../services/storeService';
import { fetchPromos, fetchSortedPromos } from '../../redux/actions/promo.action';
import Promos from '../Promos';
import { OrderStatus, PromoType } from '../../utils/enums';
import SignInModal from '../Auth/SignInModal';
import Products from '../Products';
import {
  fetchBagAbout,
  fetchBagCalculation,
  fetchBagOffers,
  fetchBagProducts,
  fetchBagSellingId,
} from '../../redux/actions/bag.action';
import promoService from '../../services/promoService';
import ProductCartHelper from '../Products/ProductCartHelper';
import { Carousel } from 'react-responsive-carousel';
import AllContests from '../Contest/AllContests';
import moment from 'moment';
import { openUrl, s3ToCloudFront, storeTimingHandler } from '../../utils/CommonMethods';
import productOrderService from '../../services/productOrderService';
import Policies from './Policies';
import { ReactComponent as OrdersIcon } from '../../assets/svg/basket.svg';
import { ReactComponent as ContestsIcon } from '../../assets/svg/shop_and_win.svg';
import { ReactComponent as CouponsIcon } from '../../assets/svg/coupons.svg';
import { ReactComponent as PoliciesIcon } from '../../assets/svg/outlineRefund.svg';
import { ReactComponent as UserIcon } from '../../assets/svg/user.svg';
import { ReactComponent as MenuIcon } from '../../assets/svg/menu.svg';
import theme from '../../utils/theme';
import OrdersList from '../Orders/OrdersList';
import { fetchOrders, fetchOrdersHistory } from '../../redux/actions/order.action';
import mynyfyLogo from '../../assets/imgs/LandingPage/mynyfy.png';
import MynyfySpan from '../../components/MynyfySpan';
import { fetchVisitedStores } from '../../redux/actions/visitedStores.action';
import MynyfyButton from '../../components/MynyfyButton';
import productService from '../../services/productService';
import MynyfyModal from '../../components/MynyfyModal';
import ShareRoundedIcon from '@mui/icons-material/ShareRounded';
import contestService from '../../services/contestService';
import { ContestType } from '../../utils/enums';

const StoreDetails = ({ storeData, noData }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const urlParams = useParams();
  const [searchParams] = useSearchParams();
  const location2 = useLocation();

  const shopWinNavigate = location2?.state?.shopWinNavigate;
  const user = useSelector((state) => state.user);
  const id = user.user?.id || null;
  const profile = useSelector((state) => state.user?.profile);
  const stores = useSelector((state) => state.stores);
  const visitedStores = useSelector((state) => state.visitedStores);
  const promos = useSelector((state) => state.promos);
  const bag = useSelector((state) => state.bag);
  const isLoggedIn = useSelector((state) => state.user?.isLoggedIn);
  const orders = useSelector((state) => state.orders);
  const location = useSelector((state) => state?.location?.location);

  const [storeName, setStoreName] = useState('');
  const [details, setDetails] = useState(null);
  const [isLiked, setLiked] = useState(null);
  const [openSignIn, setOpenSignIn] = useState(false);
  const [isEcommerceActive, setEcommerceActive] = useState(false);
  const [isStoreOpened, setStoreOpened] = useState(true);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [activeComponent, setActiveComponent] = useState(null);
  const [isDirectUrl, setDirectUrl] = useState(false);
  const [noStore, setNoStore] = useState(false);
  const [messageIndices, setMessageIndices] = useState(bag?.offers?.map(() => 0) || []);
  const [originalGroups, setOriginalGroups] = useState(null);
  const [originalSections, setOriginalSections] = useState(null);
  const [groups, setGroups] = useState(null);
  const [sections, setSections] = useState(null);
  const [selectedSection, setSelectedSection] = useState(null);
  const [actualProductsData, setActualProductsData] = useState(null);
  const [selectedBrandForProducts, setSelectedBrandForProducts] = useState(null);
  const [openViewAllBrands, setOpenViewAllBrands] = useState(false);
  const [originalProductBrands, setOriginalProductBrands] = useState([]);
  const [multipleProductBrands, setMultipleProductBrands] = useState([]);
  const [storeContests, setStoreContests] = useState([]);

  const isFoodCategory = details?.ownerId?.categories?.find(
    (e) => e._id == process.env.REACT_APP_FOOD_CAT_ID
  );

  const caurosalFstPart = [details?.messageToUser ? details?.messageToUser : null].filter(Boolean);
  const carouselData = _.isEmpty(bag?.offers) ? caurosalFstPart : null;

  useEffect(() => {
    let urlId = urlParams?.store;
    let table = urlParams?.table;
    if (urlId) setStoreName(urlId || '');
    if (table) {
      productOrderService
        .getProductOrderIdByTable({ table, status: OrderStatus.ACTIVE })
        .then((res) => {
          if (res.success) {
            storeDetailsHandler(res.data);
          } else storeDetailsHandler();
        });
    } else storeDetailsHandler();
  }, []);

  useEffect(() => {
    if (shopWinNavigate === 'Products') {
      setActiveComponent('Products');
    }
  }, []);

  useEffect(() => {
    if (details) getContests();
  }, [details]);

  const getContests = () => {
    let params = {
      status: 'ACTIVE',
      type: ContestType.SHOP_AND_WIN,
    };
    if (!_.isEmpty(location)) {
      if (location?.state?._id) params.state = location?.state?._id;
      if (location?.city?._id) params.city = location?.city?._id;
      if (location?.area?._id) params.area = location?.area?._id;
    }

    params.orgId = details?._id;
    if (!_.isEmpty(details?.brands)) {
      params.brand = details?.brands?.map((e) => e._id);
    }

    if (isLoggedIn) params.buyerId = id;
    contestService.getContests(params).then((res) => {
      if (res.success) {
        let sorted = _.orderBy(res.data, 'createdAt', 'desc');
        setStoreContests(sorted);
      } else {
        setStoreContests([]);
      }
    });
  };

  useEffect(() => {
    if (!details?._id) return;
    const fetchProductProps = async () => {
      try {
        const [groupRes, sectionRes] = await Promise.all([
          productService.getProductProps({ orgId: details?._id, type: 'group' }),
          productService.getProductProps({ orgId: details?._id, type: 'section' }),
        ]);
        if (groupRes.success) setOriginalGroups(groupRes.data);
        if (sectionRes.success) setOriginalSections(sectionRes.data);
      } catch (error) {
        console.error('Error fetching product props:', error);
      }
    };

    // fetchBrandProductProps();
    fetchProductProps();
  }, [details?._id]);

  useEffect(() => {
    if (!details?._id) return;
    const fetchProductBrands = async () => {
      let params = {
        orgId: details?._id,
        type: 'product',
      };
      productService.getProductBrandNames(params).then((res) => {
        if (res.success) {
          const data = res.data;
          if (_.isEmpty(details?.brands)) {
            setOriginalProductBrands(data);
          } else {
            let finalProductBrands = mergeProductBrands(data);
            finalProductBrands = _.sortBy(finalProductBrands, 'name');
            setMultipleProductBrands(finalProductBrands);
          }
        }
      });
    };

    fetchProductBrands();
  }, [details?._id]);

  const handleGroupsAndSections = useCallback(() => {
    if (!originalGroups?.length || !originalSections?.length) return;
    const reqGroupData = [];
    const reqSectionData = [];
    originalSections?.forEach((section) => {
      if (section?.groupName?._id === originalGroups?.[0]?._id) {
        reqGroupData.push(section);
      } else {
        reqSectionData.push(section);
      }
    });
    setGroups(reqGroupData);
    setSections(reqSectionData);
  }, [originalGroups, originalSections]);

  const mergeProductBrands = (data) => {
    const grouped = {};
    data.forEach((item) => {
      const key = item.name.toLowerCase();
      if (!grouped[key]) {
        grouped[key] = [];
      }
      grouped[key].push(item);
    });
    return Object.values(grouped).map((group) =>
      group.sort((a, b) => ('image' in b) - ('image' in a))
    );
  };

  useEffect(() => {
    handleGroupsAndSections();
  }, [handleGroupsAndSections]);

  useEffect(() => {
    if (activeComponent === null) setSelectedBrandForProducts(null);
  }, [activeComponent]);

  const storeDetailsHandler = (order) => {
    let urlId = urlParams?.store;
    let reqStoreData = storeData || state?.storeDetails;
    if (reqStoreData) {
      if (storeData) {
        changeTitleAndFavicon(storeData);
        setStoreName(storeData?.urlId || '');
        sessionStorage.setItem('storeDetails', JSON.stringify(storeData));
        setDirectUrl(true);
      } else setDirectUrl(false);
      setDetails(reqStoreData);
      ecommerceStatus(reqStoreData?.ecommerceSubscription);
      let storeStatus = storeTimingHandler(reqStoreData);
      setStoreOpened(storeStatus);
      storeViewAndVisitedHandler(reqStoreData?._id);
      if (!_.isEmpty(order)) orderNavigation(order, reqStoreData);
    } else if (urlId) {
      let body = { urlId };
      storeService.getStores(body).then((res) => {
        if (res.success) {
          if (_.isEmpty(res.data.orgList)) {
            setNoStore(true);
          } else {
            changeTitleAndFavicon(res.data.orgList[0]);
            setDetails(res.data.orgList[0]);
            setStoreName(res.data.orgList[0]?.urlId || '');
            sessionStorage.setItem('storeDetails', JSON.stringify(res.data?.orgList[0]));
            ecommerceStatus(res.data.orgList[0]?.ecommerceSubscription);
            let storeStatus = storeTimingHandler(res.data.orgList[0]);
            setStoreOpened(storeStatus);
            setDirectUrl(true);
            storeViewAndVisitedHandler(res.data.orgList[0]?._id);
            if (!_.isEmpty(order)) orderNavigation(order, res.data.orgList[0]);
          }
        } else {
          dispatch(snackbarInfo({ type: 'error', open: true, message: res.data.message }));
        }
      });
    } else if (noData) setNoStore(true);
  };

  const storeViewAndVisitedHandler = (id) => {
    let visitStoreQuery = { orgId: id };
    if (profile?._id) visitStoreQuery.buyerId = profile._id;
    storeService.viewStore(visitStoreQuery);

    if (isLoggedIn) {
      if (!profile?.visitedSellers?.includes(id)) {
        storeService.addVisitedSeller(id).then((visitedSellerRes) => {
          if (visitedSellerRes.success) {
            if (visitedSellerRes.data == 'Added to Recently Visitied Successfully') {
              let clonedData = _.cloneDeep(profile);
              if (clonedData?.visitedSellers) clonedData?.visitedSellers?.push(id);
              else clonedData.visitedSellers = [id];
              dispatch(userProfile(clonedData));
              dispatch(fetchVisitedStores(null));
            }
          }
        });
      }
    }
  };

  const changeTitleAndFavicon = (data) => {
    document.title = data?.name;
    const link = document.querySelector("link[rel~='icon']");
    if (!link) {
      const newLink = document.createElement('link');
      newLink.rel = 'icon';
      newLink.href = data.logo?.url;
      document.head.appendChild(newLink);
    } else {
      link.href = data.logo?.url;
    }
  };

  const productNavigationHandler = (item) => {
    let storeStatus = details ? storeTimingHandler(details) : true;
    if (storeStatus) {
      navigate(`/Product/${item?._id}`, {
        state: {
          details: item,
          navFrom: 'seller',
          brandDetails: null,
          sellerDetails: details,
          isEcommerceActive: isEcommerceActive,
          tabe: urlParams?.table,
        },
      });
    } else {
      dispatch(
        snackbarInfo({
          open: true,
          type: 'warning',
          message: `Currently store is closed, Please come back at ${details.storeOpenTime}`,
        })
      );
    }
  };

  const renderGroupedSections = () => {
    const reqSections = groups?.filter((section) => section?.isActive);
    return (
      reqSections?.length > 0 && (
        <div>
          <MynyfyText
            title={reqSections?.[0]?.groupName?.name}
            h5
            bold
            style={{ width: '100%', display: 'flex', justifyContent: 'center', marginBottom: 15 }}
          />
          <div className='product-section' style={{ marginBottom: 20 }}>
            <div className='ps-first-row'>
              <img
                className='first-row-img'
                src={s3ToCloudFront(reqSections?.[0]?.image?.url)}
                alt='section image'
              />
              <div className='overlay'>
                <MynyfyText
                  className={'filter-name'}
                  title={reqSections?.[0]?.name}
                  h3
                  bold
                  color={'white'}
                />
                <MynyfyButton
                  className='view-button'
                  label={'View'}
                  onClick={() => {
                    setSelectedSection(reqSections?.[0]);
                    setActiveComponent('Sections');
                  }}
                />
              </div>
            </div>
          </div>
          <div className='product-section'>
            <div className='grid-layout'>
              {reqSections?.slice(1, 6).map((section, index) => (
                <div key={index} className='grid-item'>
                  <img
                    className='grid-img'
                    src={s3ToCloudFront(section?.image?.url)}
                    alt={section?.name}
                  />
                  <div className='overlay'>
                    <MynyfyText
                      className={'filter-name'}
                      title={section?.name}
                      h6
                      bold
                      color={'white'}
                    />
                    <MynyfyButton
                      className='view-button'
                      label={'View'}
                      style={{ marginBottom: '10px' }}
                      onClick={() => {
                        setSelectedSection(section);
                        setActiveComponent('Sections');
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )
    );
  };

  const renderSections = (data, index) => {
    return (
      <div className='non-image-product-section' key={index}>
        <MynyfyText title={data?.name} h5 bold />
        <MynyfyText
          title={'View all'}
          color={'#3076b5'}
          semiBold
          pBig
          onClick={() => {
            setSelectedSection(data);
            setActiveComponent('Sections');
          }}
          style={{ borderBottom: '2px solid #3076b5', paddingBottom: '3px' }}
        />
        <div className='product-container'>
          {actualProductsData
            ?.filter((product) => product?.sections?.map(String).includes(String(data?._id)))
            ?.slice(0, 5)
            .map((product, index) => (
              <div
                key={index}
                className='filter-product'
                onClick={() => productNavigationHandler(product)}>
                <div className='filter-product-image'>
                  <img
                    className='filter-product-image'
                    src={s3ToCloudFront(product?.variants?.[0]?.image?.[0]?.url)}
                    alt='product img'
                    style={{ objectFit: 'cover', border: '1px solid #e8e8e8', borderRadius: '5px' }}
                  />
                </div>
                <div style={{ marginTop: '10px' }}>
                  <MynyfyText title={product?.name} h5 />
                  <MynyfyText title={theme.RUPEE + product?.variants?.[0]?.price} />
                </div>
              </div>
            ))}
        </div>
      </div>
    );
  };

  const renderComponent = () => {
    switch (activeComponent) {
      case 'Sections':
        return (
          <Products
            from={'seller'}
            sellerDetails={details}
            isEcommerceActive={isEcommerceActive}
            type={searchParams.get('type')}
            table={urlParams?.table}
            section={selectedSection}
            setActualProductsData={setActualProductsData}
          />
        );
      case 'BrandProducts':
        return (
          <Products
            from={'seller'}
            sellerDetails={details}
            isEcommerceActive={isEcommerceActive}
            type={searchParams.get('type')}
            table={urlParams?.table}
            section={selectedSection}
            setActualProductsData={setActualProductsData}
            selectedBrandForProducts={selectedBrandForProducts}
          />
        );
      case 'Products':
        return (
          <Products
            from={'seller'}
            sellerDetails={details}
            isEcommerceActive={isEcommerceActive}
            type={searchParams.get('type')}
            table={urlParams?.table}
            setActualProductsData={setActualProductsData}
          />
        );
      case 'Orders':
        return (
          <div>
            <MynyfyTab
              tabs={['Active', 'Closed']}
              tabComponent={[
                <OrdersList
                  type='ACTIVE'
                  data={orders?.active}
                  action={fetchOrders}
                  sellerId={details._id}
                />,
                <OrdersList
                  type='NONACTIVE'
                  data={orders?.history}
                  action={fetchOrdersHistory}
                  sellerId={details._id}
                />,
              ]}
            />
          </div>
        );

      case 'Contests':
        return (
          <MynyfyTab
            noBackBtn={true}
            tabs={['Active', 'Winners']}
            tabComponent={[
              <AllContests sellerDetails={details} status='active' from={'seller'} />,
              <AllContests
                sellerDetails={details}
                status='NOACTIVE'
                from={'seller'}
                isHistory={true}
              />,
            ]}
          />
        );
      case 'Coupons':
        return (
          <Promos
            type={[PromoType.COUPON, PromoType.POINT_COUPON]}
            isFilter={false}
            emptyListTitle={'Coupons'}
            emptyListMsg={isLoggedIn ? ['No Active coupons or offers'] : 'Please Login for Coupons'}
            emptyListMsgBold={isLoggedIn ? [1, 2] : null}
            promos={promos?.promos?.actual}
            sortedPromos={promos?.promos?.sorted}
            action={fetchPromos}
            sortedAction={fetchSortedPromos}
            sellerDetails={details}
            screen={'Coupons'}
            from={'seller'}
          />
        );
      case 'About Us':
        return (
          <div style={{ paddingInline: '15px' }}>
            <div className='aboutus-container'>
              <div className='content'>
                <Card className='aboutSections'>
                  {details?.aboutUs ? (
                    <div style={{ paddingBottom: 15 }}>
                      <MynyfyText
                        title={'About Us'}
                        h4
                        semiBold
                        style={{ marginLeft: 10, marginBottom: 10, marginTop: 10 }}
                      />
                      <MynyfyText title={details?.aboutUs} p style={{ marginLeft: 10 }} />
                    </div>
                  ) : null}
                </Card>
              </div>
            </div>
          </div>
        );
      case 'Contact Us':
        return <StoreAddress details={details} />;
      case 'Policies':
        return <Policies details={details} />;
      default:
        return null;
    }
  };

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };
  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const orderNavigation = (order, orgData) => {
    let table = urlParams?.table;
    navigate('/TableOrder/' + table, { state: { order, storeDetails: orgData } });
  };

  const ecommerceStatus = (subscription) => {
    if (subscription) {
      let subDays = moment(subscription).diff(moment(), 'days');
      if (subDays >= 0) {
        setEcommerceActive(true);
      } else {
        setEcommerceActive(false);
      }
    } else {
      setEcommerceActive(false);
    }
  };

  useEffect(() => {
    if (!_.isEmpty(details)) {
      if (bag?.id === details._id) {
        if (bag?.about?.isStoreOpened !== isStoreOpened) {
          dispatch(fetchBagAbout({ ...bag?.about, isStoreOpened }));
        }
      } else {
        dispatch(fetchBagSellingId(details._id));
        dispatch(
          fetchBagAbout({
            type: 'Store',
            name: details.name,
            city: details.city,
            state: details.state,
            policies: details.policies,
            urlId: details.urlId,
            url: process.env.REACT_APP_WEB_HOST + '/Stores/' + details.urlId,
            orgDetails: details,
            isStoreOpened,
          })
        );
        if (isLoggedIn) getBuyerPromos(details);
        dispatch(fetchBagCalculation(null));
        dispatch(fetchBagProducts(null));
      }
    }
  }, [details]);

  const getBuyerPromos = (org) => {
    let body = {
      type: [PromoType.COUPON, PromoType.REWARD],
      noOrg: true,
    };
    if (isLoggedIn) body.buyer = profile._id;
    if (_.isEmpty(org.brands)) body.organization = org._id;
    else body.brand = org.brands;
    promoService.getPromosForOrder(body).then((res) => {
      if (res.success) {
        dispatch(fetchBagOffers(res.data));
      }
    });
  };

  const likeHandler = (storeId, key) => {
    if (isLoggedIn) {
      let options = {
        storeId,
        setLiked,
        profile,
        data: state?.isFav ? stores?.favStores : state?.isVisited ? visitedStores : stores?.stores,
        dispatch,
        action: state?.isFav
          ? details.homeDelivery
            ? fetchFavStores
            : fetchFavOfflineStores
          : state?.isVisited
          ? fetchVisitedStores
          : details.homeDelivery
          ? fetchStores
          : fetchOfflineStores,
        userProfile,
        snackbarInfo,
      };
      if (key === 'like') {
        likeStore(options);
        if (_.isEmpty(options.data)) details.likesCount = details.likesCount + 1;
      } else {
        unlikeStore(options);
        if (_.isEmpty(options.data)) details.likesCount = details.likesCount - 1;
      }
    } else {
      setOpenSignIn(true);
    }
  };

  const handleClick = () => {
    if (isLoggedIn) navigate('/profile', { state: { isDirectUrl: true } });
    else setOpenSignIn(true);
  };

  const handleSlideChange = (index) => {
    setMessageIndices((prevIndices) => {
      const newIndices = [...prevIndices];
      newIndices[index] = (newIndices[index] + 1) % caurosalFstPart.length;
      return newIndices;
    });
  };

  const getCompleteUrl = (url) => {
    if (url?.startsWith('http://') || url?.startsWith('https://')) {
      return url;
    } else {
      return `https://${url}`;
    }
  };

  const shareContestUrl = async (item) => {
    try {
      if (navigator.share) {
        const imageUrl = item?.image;

        await navigator.share({
          title: item?.name,
          files: [
            new File([await fetch(imageUrl).then((res) => res.blob())], 'image.jpg', {
              type: 'image/jpeg',
            }),
          ],
        });
      }
    } catch (error) {
      dispatch(snackbarInfo({ open: true, type: 'error', message: error.message }));
    }
  };

  return details ? (
    _.isEmpty(details) ? (
      <Fragment>
        <MynyfyHeader title={storeName || 'Store Page'} />
        <MynyfyEmptyMsg
          message={['We are unable to fetch the', storeName || 'Store Page']}
          bold={[1]}
        />
      </Fragment>
    ) : (
      <div id='Stores'>
        {!_.isEmpty(carouselData) && (
          <div
            style={{
              position: 'fixed',
              top: '0px',
              width: '100%',
              zIndex: 10,
              backgroundColor: 'black',
              maxHeight: 50,
              minHeight: 50,
            }}>
            <Carousel
              infiniteLoop
              autoPlay
              showStatus={false}
              showArrows={false}
              showThumbs={false}
              showIndicators={false}
              interval={4000}
              transitionTime={800}
              onChange={handleSlideChange}>
              {carouselData?.map((item, index) => (
                <div
                  key={index}
                  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Card
                    style={{
                      width: '100%',
                      boxShadow: 'none',
                      backgroundColor: 'black',
                    }}
                    // onClick={() => setActiveComponent('Coupons')}
                    key={item?._id}
                    className='offersDisplayCard cursor'>
                    <div className='rowSA' style={{ paddingTop: 0 }}>
                      <div>
                        <MynyfyText
                          title={caurosalFstPart[messageIndices[index]] || ''}
                          p
                          style={{ color: 'white' }}
                        />
                      </div>
                    </div>
                  </Card>
                </div>
              ))}
            </Carousel>
          </div>
        )}
        <ProductCartHelper navFrom='seller' sellerDetails={details} table={urlParams?.table} />
        <div id='MynyfyHeader'>
          <Card
            className='rowSB mynyfyHeader'
            style={{ padding: 10, boxShadow: 'none', top: _.isEmpty(carouselData) ? 0 : 50 }}>
            <div className='row' style={{ gap: 5 }}>
              {state?.storeDetails ? (
                <ChevronLeftRounded
                  className='cursor'
                  style={{ width: 30, height: 30 }}
                  onClick={() => navigate(-1)}
                />
              ) : null}
              <MenuIcon
                style={{ width: 24, height: 24, color: 'grey' }}
                onClick={handleDrawerOpen}
                className='cursor'
              />
            </div>

            <div className='cursor' onClick={() => setActiveComponent(null)}>
              {details?.logo?.url ? (
                <div className='rowC' style={{ width: 180, height: 40 }}>
                  <img
                    src={s3ToCloudFront(details.logo.url)}
                    width={180}
                    height={40}
                    style={{ objectFit: 'contain' }}
                    alt='logo'
                  />
                </div>
              ) : (
                <MynyfyText
                  title={details.name}
                  gayahi
                  style={{ width: 180, fontSize: 12 }}
                  center
                />
              )}
            </div>

            <div className='row' style={{ gap: 5 }}>
              {state?.storeDetails ? <div style={{ width: 30, height: 30 }} /> : null}
              <UserIcon
                style={{ width: 24, height: 24, color: 'grey' }}
                className='cursor'
                onClick={handleClick}
              />
            </div>
          </Card>

          <Drawer
            anchor='top'
            open={drawerOpen}
            onClose={handleDrawerClose}
            sx={{
              '& .MuiDrawer-paper': { width: 170, top: 60, height: 'auto', position: 'absolute' },
            }}
            style={{ width: theme.WIDTH, margin: 'auto' }}
            id='Stores'>
            <div
              style={{ width: '100%', cursor: 'pointer' }}
              role='presentation'
              onClick={handleDrawerClose}
              onKeyDown={handleDrawerClose}>
              <div className='row drawerOption' onClick={() => setActiveComponent('Products')}>
                <StyleOutlined width={20} height={20} style={{ color: 'grey' }} />
                <MynyfyText title={isFoodCategory ? 'Menu' : 'Products'} h5 color={'grey'} />
              </div>
              <div
                className='row drawerOption'
                onClick={() => {
                  if (isLoggedIn) setActiveComponent('Orders');
                  else setOpenSignIn(true);
                }}>
                <OrdersIcon width={20} height={20} style={{ color: 'grey' }} />
                <MynyfyText title={'Orders'} h5 color={'grey'} />
              </div>

              <div className='row drawerOption' onClick={() => setActiveComponent('Contests')}>
                <ContestsIcon width={20} style={{ color: 'grey' }} />
                <MynyfyText title={'Contests'} h5 color={'grey'} />
              </div>

              <div className='row drawerOption' onClick={() => setActiveComponent('Coupons')}>
                <CouponsIcon width={20} height={20} style={{ color: 'grey' }} />
                <MynyfyText title={'Coupons'} h5 color={'grey'} />
              </div>

              <div className='row drawerOption' onClick={() => setActiveComponent('Contact Us')}>
                <ContactPhoneOutlined style={{ color: 'grey', width: '20px', height: '20px' }} />
                <MynyfyText title={'Contact Us'} h5 color={'grey'} />
              </div>

              <div className='row drawerOption' onClick={() => setActiveComponent('Policies')}>
                <PoliciesIcon width={20} height={20} style={{ fill: 'grey' }} />
                <MynyfyText title={'Policies'} h5 color={'grey'} />
              </div>
            </div>
          </Drawer>
        </div>

        {activeComponent ? (
          <div style={{ minHeight: 'calc(var(--MIN_HEIGHT) - 201px)' }}>{renderComponent()}</div>
        ) : (
          <div style={{ backgroundColor: '#fff', minHeight: 'calc(var(--MIN_HEIGHT) - 201px)' }}>
            {_.isEmpty(details?.mobileOrgImages || details.orgImages) ? null : (
              <Carousel
                infiniteLoop={true}
                autoPlay={true}
                showStatus={false}
                showArrows={false}
                showThumbs={false}
                showIndicators={false}
                interval={3000}>
                {(details?.mobileOrgImages || details?.orgImages)?.map((res, i) => (
                  <div key={i}>
                    <img
                      src={s3ToCloudFront(res.url)}
                      className={isFoodCategory ? 'storeImg' : 'normalStoreImg'}
                      alt='org'
                    />
                  </div>
                ))}
              </Carousel>
            )}

            <div>
              <div className='rowSB' style={{ padding: '15px' }}>
                <div className='InnerlogoName'>
                  {details?.logo?.url && !details?.orgImages ? (
                    <MynyfyText
                      title={details.name}
                      gayahi
                      style={{
                        fontSize: 12,
                        fontWeight: 600,
                        color: 'rgb(0 3 6)',
                      }}
                      center
                    />
                  ) : null}
                  {isFoodCategory ? (
                    details?.policies?.shippingCharges?.forRestaurant?.deliveryTime ? (
                      <MynyfyText
                        title={
                          'We deliver in ' +
                          details?.policies?.shippingCharges?.forRestaurant?.deliveryTime +
                          ' min'
                        }
                      />
                    ) : details?.policies?.shippingCharges?.forRestaurant?.deliveryMessage ? (
                      <MynyfyText
                        title={details?.policies?.shippingCharges?.forRestaurant?.deliveryMessage}
                      />
                    ) : null
                  ) : details?.policies?.shippingCharges?.forRestaurant?.deliveryDays ? (
                    <MynyfyText
                      title={
                        'We deliver in ' +
                        details?.policies?.shippingCharges?.forRestaurant?.deliveryDays +
                        ' days'
                      }
                    />
                  ) : null}
                </div>

                {/* <div className='row'>
                  {isLiked ? (
                    <CircularProgress size={16} />
                  ) : profile?.likedSellers?.includes(details._id) ? (
                    <div className='row' onClick={() => likeHandler(details._id, 'unlike')}>
                      <FavoriteRounded fontSize='small' className='followBtn' />
                      <MynyfyText title={details.likesCount || 0} bold />
                      <MynyfyText title={' Followers'} style={{ paddingLeft: '3px' }} />
                    </div>
                  ) : (
                    <div className='row' onClick={() => likeHandler(details._id, 'like')}>
                      <FavoriteBorderRounded fontSize='small' className='followBtn' />
                      <MynyfyText title={details.likesCount || 0} bold />
                      <MynyfyText title={' Followers'} style={{ paddingLeft: '3px' }} />
                    </div>
                  )}
                </div> */}
              </div>

              {_.isEmpty(bag?.offers) ? null : (
                <Carousel
                  infiniteLoop={true}
                  autoPlay={true}
                  showStatus={false}
                  showArrows={false}
                  showThumbs={false}
                  showIndicators={false}
                  interval={5000}>
                  {bag?.offers?.map((item, index, array) => (
                    <Card
                      onClick={() => setActiveComponent('Coupons')}
                      key={item?._id}
                      className='offersDisplayCard cursor'>
                      <div className='rowSB'>
                        <div className='row' style={{ gap: 10, textAlign: 'left' }}>
                          <div style={{ width: 60, height: 60 }}>
                            <img
                              src={require('../../assets/imgs/offersImage.png')}
                              alt='offerImg'
                            />
                          </div>

                          <div>
                            <MynyfyText title={theme.RUPEE + item?.actualPrice + ' OFF'} bold h4 />
                            {item.minimumOrderValue ? (
                              <MynyfyText
                                title={'On a min order of ' + theme.RUPEE + item.minimumOrderValue}
                                style={{ paddingTop: 4 }}
                              />
                            ) : null}
                          </div>
                        </div>

                        <MynyfyText title={`${index + 1}/${array.length}`} h6 />
                      </div>
                    </Card>
                  ))}
                </Carousel>
              )}
              {_.isEmpty(storeContests) ? null : (
                <Carousel
                  infiniteLoop={true}
                  autoPlay={true}
                  showStatus={false}
                  showArrows={false}
                  showThumbs={false}
                  showIndicators={false}
                  interval={5000}>
                  <Card className='offersDisplayCard cursor'>
                    <div className='rowSB' style={{ paddingTop: '15px' }}>
                      <div style={{ paddingBottom: 10 }}>
                        <MynyfySpan title={'Contests '} pBig bold />
                      </div>
                      <div style={{ paddingBottom: 10 }}>
                        <MynyfySpan
                          title={'View All '}
                          onClick={() => setActiveComponent('Contests')}
                          pBig
                          link
                        />
                      </div>
                    </div>
                    <div>
                      <div
                        style={{
                          width: '300px',
                          height: '200px',
                          marginTop: '10px',
                        }}>
                        {storeContests.length > 0 ? (
                          <Carousel
                            infiniteLoop={true}
                            autoPlay={true}
                            showStatus={false}
                            showArrows={false}
                            showThumbs={false}
                            showIndicators={false}
                            interval={5000}>
                            {storeContests.slice(0, 5).map((item, i) => (
                              <div
                                key={i}
                                onClick={() => setActiveComponent('Contests')}
                                style={{ backgroundColor: 'white' }}>
                                <img
                                  src={s3ToCloudFront(item.image)}
                                  alt='Banner Image'
                                  style={{
                                    width: '300px',
                                    height: '150px',
                                    borderRadius: '16px',
                                  }}
                                />
                                <div className='rowSB' style={{ paddingTop: '10px' }}>
                                  <div />
                                  <div style={{ justifyContent: 'left' }}>
                                    <MynyfyText title={item?.name} />
                                    {item?.createdKey === 'AdminProfile' ? (
                                      <MynyfyText title={'Contest by MYNYFY Flatform'} />
                                    ) : (
                                      <MynyfyText title={`Contest by ${item?.brand?.brand} `} />
                                    )}
                                  </div>
                                  <div style={{ paddingRight: '6px' }}>
                                    <ShareRoundedIcon
                                      onClick={(e) => {
                                        shareContestUrl(item);
                                        e.stopPropagation();
                                      }}
                                      className='cursor'
                                    />
                                  </div>
                                </div>
                              </div>
                            ))}
                          </Carousel>
                        ) : null}
                      </div>
                    </div>
                  </Card>
                </Carousel>
              )}
            </div>

            {/* <div className='rowSB' style={{ padding: '20px 10px 10px' }}>
            <div className='row'>
              <MynyfyText title={details.name} bold h6 className='storeName' />
              <CallRounded color='primary' fontSize='small' />
            </div>
            <div className='row'>
              {isLiked ? (
                <CircularProgress size={16} />
              ) : profile?.likedSellers?.includes(details._id) ? (
                <div className='row' onClick={() => likeHandler(details._id, 'unlike')}>
                  <FavoriteRounded fontSize='small' className='followBtn' />
                  <MynyfyText title={details.likesCount || 0} />
                </div>
              ) : (
                <div className='row' onClick={() => likeHandler(details._id, 'like')}>
                  <FavoriteBorderRounded fontSize='small' className='followBtn' />
                  <MynyfyText title={details.likesCount || 0} />
                </div>
              )}
            </div>
          </div> */}

            {isFoodCategory ? (
              details.homeDelivery ? null : (
                <div style={{ padding: '0px 20px 5px' }}>
                  <MynyfyText title={'Offline store - Pick-up only'} pSmall />
                </div>
              )
            ) : null}

            {isEcommerceActive ? null : (
              <div style={{ padding: '0px 20px 5px' }}>
                <MynyfyText
                  title={'Currently not in service, please contact support.'}
                  error
                  pSmall
                />
              </div>
            )}

            {isStoreOpened ? null : (
              <div style={{ padding: '0px 20px 5px' }}>
                <MynyfyText
                  title={'Currently store is closed, Please come back at ' + details.storeOpenTime}
                  error
                  pSmall
                />
              </div>
            )}

            <div className='custom-product-section-container'>
              {groups?.length > 0 && renderGroupedSections()}
            </div>
            <div className='custom-product-section-container-no-image'>
              {sections?.length > 0 &&
                sections?.map((section, index) => {
                  const isSectionPresent =
                    section?.isActive &&
                    actualProductsData?.some((product) =>
                      product?.sections?.map(String).includes(String(section?._id))
                    );
                  return isSectionPresent ? renderSections(section, index) : null;
                })}
            </div>

            {_.isEmpty(
              details?.brands?.[0]?._id ? multipleProductBrands : originalProductBrands
            ) ? null : (
              <div className='shopByBrands' style={{ marginTop: '3%', marginBottom: '9%' }}>
                <MynyfyText title={'Shop by Brands'} h5 bold />
                <div className='row brandRow'>
                  {(details?.brands?.[0]?._id ? multipleProductBrands : originalProductBrands)
                    ?.slice(0, 4)
                    .map((brand, i) => (
                      <div
                        key={i}
                        className='brand'
                        onClick={() => {
                          setSelectedBrandForProducts(
                            details?.brands?.[0]?._id ? brand?.[0] : brand
                          );
                          setActiveComponent('BrandProducts');
                        }}>
                        {brand?.image?.url || brand?.[0]?.image?.url ? (
                          <img
                            className='logo'
                            src={s3ToCloudFront(brand?.image?.url || brand?.[0]?.image?.url)}
                            alt='brand logo'
                            style={{
                              objectFit: 'contain',
                              border: '1px solid #e8e8e8',
                              width: '60px',
                              height: '60px',
                              padding: 5,
                            }}
                          />
                        ) : (
                          <div
                            style={{
                              width: '60px',
                              height: '60px',
                              padding: 5,
                              marginBottom: 5,
                              backgroundColor: '#e8e8e8',
                              border: '1px solid #e8e8e8',
                              borderRadius: 10,
                            }}
                            className='rowC'>
                            <MynyfyText title={brand?.name || brand?.[0]?.name} bold />
                          </div>
                        )}
                        <MynyfyText className={'name'} title={brand?.name || brand?.[0]?.name} />
                      </div>
                    ))}
                  {(details?.brands?.[0]?._id ? multipleProductBrands : originalProductBrands)
                    ?.length >= 5 && (
                    <div>
                      <KeyboardDoubleArrowRight onClick={() => setOpenViewAllBrands(true)} />
                    </div>
                    // <div
                    //   className='brand'
                    //   onClick={() => {
                    //     setOpenViewAllBrands(true);
                    //   }}>
                    //   <div
                    //     className='logo'
                    //     alt='brand logo'
                    //     width={40}
                    //     height={40}
                    //     style={{
                    //       backgroundColor: '#e8e8e8',
                    //       display: 'flex',
                    //       justifyContent: 'center',
                    //       alignItems: 'center',
                    //     }}>
                    //     <MynyfyText className={'name'} title={'View All'} pSmall />
                    //   </div>
                    //   <MynyfyText
                    //     className={'name'}
                    //     title={'View All'}
                    //     color={'transparent'}
                    //     pSmall
                    //   />
                    // </div>
                  )}
                </div>
              </div>
            )}
            <Products
              from={'seller'}
              sellerDetails={details}
              isEcommerceActive={isEcommerceActive}
              type={searchParams.get('type')}
              table={urlParams?.table}
              setActualProductsData={setActualProductsData}
              selectedBrandForProducts={selectedBrandForProducts}
            />

            {/* <MynyfyTab
            noBackBtn
            tabs={[
              details?.ownerId?.categories?.find(
                (e) => e.code == 'Category' && e.data?.toLowerCase().includes('food')
              )
                ? 'Menu'
                : 'Products',
              'About',
              'Contests',
              'Coupons',
            ]}
            tabComponent={[
              <Products
                from={'seller'}
                sellerDetails={details}
                isEcommerceActive={isEcommerceActive}
                type={searchParams.get('type')}
                table={urlParams?.table}
              />,
              <StoreAddress details={details} />,
              <MynyfyTab
                noBackBtn={true}
                tabs={['Active', 'Winners']}
                tabComponent={[
                  <AllContests sellerDetails={details} status='active' from={'seller'} />,
                  <AllContests
                    sellerDetails={details}
                    status='NOACTIVE'
                    from={'seller'}
                    isHistory={true}
                  />,
                ]}
              />,
              <Promos
                type={[PromoType.COUPON, PromoType.POINT_COUPON]}
                isFilter={false}
                emptyListTitle={'Coupons'}
                emptyListMsg={
                  isLoggedIn
                    ? [
                        "You don't have any Coupons",
                        'Get Coupons by participating',
                        'in Contests & Jackpots',
                      ]
                    : 'Please Login for Coupons'
                }
                emptyListMsgBold={isLoggedIn ? [1, 2] : null}
                promos={promos?.promos?.actual}
                sortedPromos={promos?.promos?.sorted}
                action={fetchPromos}
                sortedAction={fetchSortedPromos}
                sellerDetails={details}
                screen={'Coupons'}
                from={'seller'}
              />,
            ]}
          /> */}
          </div>
        )}

        <MynyfyModal open={openViewAllBrands} close={() => setOpenViewAllBrands(false)}>
          <MynyfyText title={'Shop by Brands'} h5 bold style={{ marginBottom: 10 }} />
          <div style={{ maxHeight: '75vh', overflowY: 'auto' }}>
            {(details?.brands?.[0]?._id ? multipleProductBrands : originalProductBrands)?.map(
              (brand, i) => (
                <div
                  key={i}
                  className='row'
                  style={{ padding: '10px 0' }}
                  onClick={() => {
                    setSelectedBrandForProducts(details?.brands?.[0]?._id ? brand?.[0] : brand);
                    setActiveComponent('BrandProducts');
                    setOpenViewAllBrands(false);
                  }}>
                  {brand?.image?.url || brand?.[0]?.image?.url ? (
                    <img
                      src={s3ToCloudFront(brand?.image?.url || brand?.[0]?.image?.url)}
                      width={50}
                      height={50}
                      alt='brand logo'
                      style={{ borderRadius: 5, objectFit: 'contain' }}
                    />
                  ) : (
                    <div style={{ width: 50, height: 50 }} className='rowC'>
                      <MynyfyText title={brand?.name || brand?.[0]?.name} bold gayahi center />
                    </div>
                  )}
                  <MynyfyText
                    title={brand?.name || brand?.[0]?.name}
                    style={{ marginLeft: 10 }}
                    bold
                  />
                </div>
              )
            )}
          </div>
        </MynyfyModal>

        <SignInModal
          open={openSignIn}
          close={() => setOpenSignIn(false)}
          sellerDetails={{
            image: details?.orgImages?.[0]?.url,
            storename: details?.name,
          }}
        />

        <div className='Storefooter'>
          <div className='footer-content'>
            <div className='MenuItems-footer'>
              <MynyfyText
                title={'About Us'}
                p
                color={'black'}
                className='menu-item cursor'
                onClick={() => setActiveComponent('About Us')}
              />

              <MynyfyText
                title={isFoodCategory ? 'Menu' : 'Products'}
                p
                color={'black'}
                className='menu-item cursor'
                onClick={() => setActiveComponent(null)}
              />

              <MynyfyText
                title={'Policies'}
                p
                color={'black'}
                className='menu-item cursor'
                onClick={() => setActiveComponent('Policies')}
              />

              {/* <MynyfyText
                title={'Orders'}
                p
                color={'black'}
                onClick={() => {
                  if (isLoggedIn) setActiveComponent('Orders');
                  else setOpenSignIn(true);
                }}
                className='menu-item'
                style={{ cursor: 'pointer' }}
              />
              <MynyfyText
                title={'Contests'}
                p
                color={'black'}
                onClick={() => setActiveComponent('Contests')}
                className='menu-item'
                style={{ cursor: 'pointer' }}
              />
              <MynyfyText
                title={'Coupons'}
                p
                color={'black'}
                onClick={() => setActiveComponent('Coupons')}
                className='menu-item'
                style={{ cursor: 'pointer' }}
              /> */}
              {/* <div className='row' onClick={() => setActiveComponent('Contact Us')} cursor>
                <ChevronRightRounded
                  style={{
                    width: 15,
                    height: 15,
                    display: 'block',
                    backgroundColor: 'rgb(27, 166, 114)',
                    color: '#fff',
                    borderRadius: '50%',
                  }}
                />
                <MynyfyText
                  title={'Contact Us'}
                  h5
                  color={'black'}
                  onClick={() => setActiveComponent('Contact Us')}
                  className='menu-item'
                  style={{ cursor: 'pointer' }}
                />
              </div> */}

              {/* {!_.isEmpty(details?.brands) && (
                <div
                  className='row'
                  onClick={() => setActiveComponent('Contact Us')}
                  cursor>
                  <ChevronRightRounded
                    style={{
                      width: 15,
                      height: 15,
                      display: 'block',
                      backgroundColor: 'rgb(27, 166, 114)',
                      color: '#fff',
                      borderRadius: '50%',
                    }}
                  />
                  <MynyfyText
                    title={'Authorized Reseller'}
                    h5
                    color={'black'}
                    className='menu-item'
                    style={{ cursor: 'pointer' }}
                  />
                </div>
              )} */}
            </div>
          </div>

          <div style={{ padding: '30px 5px' }}>
            <MynyfyText
              title={`© 2025 ${details?.name} All rights reserved`}
              style={{ color: 'black' }}
              center
            />
            <div className='rowC' style={{ paddingTop: 5 }}>
              <MynyfyText title={'Powered by'} style={{ color: 'black' }} />
              <img
                src={mynyfyLogo}
                alt='Mynyfylogo'
                style={{
                  height: 20,
                  width: 'auto',
                  marginRight: 10,
                  marginLeft: 10,
                  objectFit: 'contain',
                }}
              />
              <MynyfySpan title={'Platform'} style={{ paddingTop: 3, color: 'black' }} />
            </div>
          </div>

          {details?.socialLinks?.facebook ||
          details?.socialLinks?.instagram ||
          details?.socialLinks?.youtube ? (
            <MynyfyText title={'Follow Us'} center style={{ paddingBottom: 10 }} />
          ) : null}
          <div className='rowC' style={{ gap: '10px', paddingBottom: 20 }}>
            {details?.socialLinks?.facebook ? (
              <Facebook
                style={{ color: '#0765ff', fontSize: 30 }}
                className='cursor'
                onClick={() => openUrl(details?.socialLinks?.facebook)}
              />
            ) : null}
            {details?.socialLinks?.instagram ? (
              <Instagram
                style={{ color: '#d62976', fontSize: 30 }}
                className='cursor'
                onClick={() => openUrl(details?.socialLinks?.instagram)}
              />
            ) : null}
            {details?.socialLinks?.youtube ? (
              <YouTube
                style={{ color: '#FF0032', fontSize: 39 }}
                className='cursor'
                onClick={() => openUrl(details?.socialLinks?.youtube)}
              />
            ) : null}
            {/* <img
              src={require('../../assets/imgs/icons/logo.png')}
              style={{ width: 25, height: 25, marginTop: -2 }}
              onClick={() => likeHandler(details?._id, 'like')}
              alt='Mynyfy Logo'
            /> */}
          </div>
        </div>

        <div className='whatsAppIcon'>
          {details?.socialLinks?.whatsapp ? (
            <a
              href={`https://wa.me/+91${details?.socialLinks?.whatsapp}`}
              target='_blank'
              rel='noopener noreferrer'
              style={{ textDecoration: 'none', color: 'inherit' }}>
              <WhatsApp style={{ color: '#25D366', fontSize: 40 }} />
            </a>
          ) : null}
        </div>
      </div>
    )
  ) : noStore ? (
    <div id='Stores'>
      <div className='noStore'>
        <MynyfyText title={'No ' + (noStore?.name || '') + ' Store is found'} center h6 />
      </div>
    </div>
  ) : (
    <MynyfyLoader />
  );
};

export default StoreDetails;
