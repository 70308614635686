import { useEffect, useState } from 'react';

const useWindowRefresh = () => {
  const [isBelow500, setIsBelow500] = useState(window.innerWidth < 500);

  useEffect(() => {
    const handleResize = () => {
      const newIsBelow500 = window.innerWidth < 500;

      if (newIsBelow500 !== isBelow500) {
        setIsBelow500(newIsBelow500);
        window.location.reload();
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isBelow500]);
};

export default useWindowRefresh;
