import { io } from 'socket.io-client';
import { SocketEvent } from '../utils/enums';

class SocketService {
  constructor() {
    this.socket = null;
    this.socketUrl = process.env.REACT_APP_API_HOST;
  }

  getSocketUrl(path = '') {
    return path ? `${this.socketUrl}${path}` : this.socketUrl;
  }

  init(profileId, path) {
    if (!profileId) {
      console.warn('Profile ID is required to initialize the socket.');
      return null;
    }

    if (this.socket) {
      console.warn('Socket is already initialized.');
      return this.socket;
    }

    try {
      this.socket = io(this.getSocketUrl(path), {
        query: { userId: profileId },
        reconnection: true,
        reconnectionAttempts: 20,
        reconnectionDelay: 5000,
      });

      this.setupSocketEvents();
      return this.socket;
    } catch (err) {
      console.error('Error initializing socket:', err);
      return null;
    }
  }

  setupSocketEvents() {
    if (!this.socket) return;

    this.socket.on(SocketEvent.CONNECT, () => {
      console.log('Socket connected');
    });

    this.socket.on(SocketEvent.DISCONNECT, () => {
      console.log('Socket disconnected');
    });

    this.socket.on(SocketEvent.RECONNECT, (attempt) => {
      console.log(`Socket reconnected successfully on attempt ${attempt}`);
    });

    this.socket.on(SocketEvent.RECONNECT_ATTEMPT, (attempt) => {
      console.log(`Attempting to reconnect... Attempt number: ${attempt}`);
    });

    this.socket.on(SocketEvent.RECONNECT_ERROR, (error) => {
      console.error('Socket reconnection error:', error);
    });

    this.socket.on(SocketEvent.RECONNECT_FAILED, () => {
      console.error('Socket reconnection failed after maximum attempts');
    });

    this.socket.on(SocketEvent.ERROR, (error) => {
      console.error('Socket encountered an error:', error);
    });
  }

  onNewNotification(callback) {
    if (!this.socket) {
      console.warn('Socket is not initialized to fetch new notifications');
      return;
    }

    try {
      this.socket.off(SocketEvent.NEW_NOTIFICATION);
      this.socket.on(SocketEvent.NEW_NOTIFICATION, (notificationData) => {
        callback && callback(notificationData);
      });
    } catch (err) {
      console.error('Notification Socket Error: ', err);
    }
  }

  onNewOrder(callback) {
    if (!this.socket) {
      console.warn('Socket is not initialized to fetch new orders');
      return;
    }

    try {
      this.socket.off(SocketEvent.NEW_ORDER);
      this.socket.on(SocketEvent.NEW_ORDER, (orderData) => {
        callback && callback(orderData);
      });
    } catch (err) {
      console.error('Order Socket Error: ', err);
    }
  }

  onOrderUpdate(callback) {
    if (!this.socket) {
      console.warn('Socket is not initialized to fetch new orders');
      return;
    }

    try {
      this.socket.off(SocketEvent.ORDER_UPDATED);
      this.socket.on(SocketEvent.ORDER_UPDATED, (orderData) => {
        callback && callback(orderData);
      });
    } catch (err) {
      console.error('Order Socket Error: ', err);
    }
  }

  disconnect() {
    if (this.socket) {
      this.socket.disconnect();
      this.socket = null;
      console.log('Socket disconnected and cleaned up.');
    } else {
      console.warn('No active socket to disconnect.');
    }
  }
}

const socketService = new SocketService();
export default socketService;
export { socketService };
