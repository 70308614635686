import React from 'react';
import theme from '../utils/theme';

const MynyfyText = ({
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  pSmall,
  pBig,
  small,
  xSmall,
  bold,
  semiBold,
  italic,
  boldItalic,
  semiBoldItalic,
  light,
  gayahi,
  primary,
  error,
  title,
  style,
  center,
  right,
  left,
  color,
  link,
  onClick,
  className,
  noSelect,
  ...rest
}) => (
  <p
    style={{
      ...{ fontFamily: theme.DEFAULT_FONT, color: theme.DARK_COLOR, fontSize: 14 },
      ...(h1 && { fontSize: 50 }),
      ...(h2 && { fontSize: 34 }),
      ...(h3 && { fontSize: 25 }),
      ...(h4 && { fontSize: 20 }),
      ...(h5 && { fontSize: 18 }),
      ...(h6 && { fontSize: 16 }),
      ...(pBig && { fontSize: 15 }),
      ...(p && { fontSize: 14 }),
      ...(pSmall && { fontSize: 13 }),
      ...(small && { fontSize: 11.5 }),
      ...(xSmall && { fontSize: 10 }),
      ...style,
      ...(link && { color: theme.PRIMARY_BLUE, cursor: 'pointer', userSelect: 'none' }),
      ...(center && { textAlign: 'center' }),
      ...(left && { textAlign: 'left' }),
      ...(right && { textAlign: 'right' }),
      ...(bold && { fontFamily: theme.BOLD_FONT }),
      ...(semiBold && { fontFamily: theme.SEMI_BOLD_FONT }),
      ...(italic && { fontFamily: theme.ITALIC_FONT }),
      ...(boldItalic && { fontFamily: theme.BOLD_ITALIC_FONT }),
      ...(semiBoldItalic && { fontFamily: theme.SEMI_BOLD_ITALIC_FONT }),
      ...(gayahi && { fontFamily: theme.ANTA_REGULAR }),
      ...(light && { color: theme.MUTED_BLUE }),
      ...(primary && { color: theme.PRIMARY_BLUE }),
      ...(error && { color: theme.ERROR }),
      ...(color && { color: color }),
      ...(noSelect && { userSelect: 'none' }),
    }}
    onClick={onClick}
    className={className}
    {...rest}>
    {title}
  </p>
);

export default MynyfyText;
