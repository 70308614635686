import React, { useState, useEffect } from 'react';
import MynyfyText from '../../components/MynyfyText';
import { Card, IconButton, InputAdornment } from '@mui/material';
import MynyfyInput from '../../components/MynyfyInput';
import MynyfyButton from '../../components/MynyfyButton';
import MynyfySpan from '../../components/MynyfySpan';
import { Tooltip } from 'react-tippy';
import { NavigateBeforeRounded, NavigateNextRounded, InfoRounded } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import _ from 'lodash';
import validate from '../../utils/validate';
import { orgRegistrationValidators } from '../../utils/validators';
import storeService from '../../services/storeService';
import { snackbarInfo } from '../../redux/actions/snackbar.action';
import MynyfyEmptyMsg from '../../components/MynyfyEmptyMsg';

const WebRegistration = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const sellerProfile = location.state?.user;
  const token = location.state?.token;
  const brandDetails = location.state?.brandDetails;

  const [currentCard, setCurrentCard] = useState(1);
  const [fname, setFname] = useState('');
  const [email, setEmail] = useState('');
  const [shopname, setShopname] = useState('');
  const [partnerId, setpartnerId] = useState('');
  const [store, setStore] = useState('');
  const [urlId, setUrlId] = useState('');
  const [error, setError] = useState({});
  const [isClicked, setClicked] = useState(false);

  useEffect(() => {
    if (!sellerProfile || !token || !brandDetails) {
      navigate('/');
    }
  }, []);

  const validateData = (obj) => {
    let validationObj = obj;
    if (!obj) validationObj = { fname, email, store, shopname, urlId };
    const errors = validate(validationObj, orgRegistrationValidators);
    setError(errors);
    return errors;
  };

  const submitHandler = async () => {
    setClicked(true);
    let errors = validateData();
    if (_.isEmpty(errors)) {
      let body = {
        forProfile: 'Seller',
        firstName: fname,
        email: email,
        name: store,
        registeredName: shopname,
        type: 'Main Branch',
        urlId: urlId,
        categories: brandDetails?.category?.map((cat) => cat._id),
        brands: brandDetails?._id,
      };
      if (partnerId) body.partnerCode = partnerId;
      let s3ErrorMsg = null;
      if (s3ErrorMsg) {
        dispatch(snackbarInfo({ open: true, type: 'warning', message: s3ErrorMsg }));
        setClicked(false);
      } else {
        storeService
          .createStore(sellerProfile?.id, body, token)
          .then((res) => {
            if (res.success) {
              dispatch(
                snackbarInfo({
                  open: true,
                  type: 'success',
                  message: 'Organization created successfully',
                })
              );
              navigate(-2);
              sessionStorage.setItem('reseller_redirection', true);
            } else {
              dispatch(snackbarInfo({ open: true, type: 'error', message: res.message }));
              sessionStorage.setItem('reseller_redirection', false);
            }
          })
          .finally(() => setClicked(false));
      }
    } else {
      dispatch(
        snackbarInfo({ open: true, type: 'warning', message: 'Please fill all the details' })
      );
      setClicked(false);
    }
  };

  const handleNext = (obj) => {
    let errors = validateData(obj);
    if (_.isEmpty(errors)) setCurrentCard((prev) => prev + 1);
  };

  const handleBack = () => {
    setCurrentCard((prev) => prev - 1);
  };

  return brandDetails ? (
    <div
      id='registration'
      style={{
        backgroundColor: '#1a476e',
        height: '100vh',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
      }}>
      <div>
        <div style={{ textAlign: 'center' }}>
          <MynyfyText title={brandDetails.brand} h3 color={'#ffff'} />

          <MynyfyText
            title={'Complete below information to setup your store profile.'}
            pBig
            color={'#fff'}
            style={{ paddingTop: '10px' }}
          />
        </div>
        {currentCard === 1 && (
          <Card
            style={{
              marginTop: '20px',
              padding: '20px',
              width: '400px',
              borderRadius: '10px',
            }}>
            <MynyfyText title={'Provide your Information'} h4 center />
            <div style={{ paddingInline: '15px' }}>
              <div style={{ paddingTop: '15px' }}>
                <MynyfyInput
                  fullWidth
                  inputTitle={'Enter your Name'}
                  placeHolder={'Enter your Name'}
                  inputTitleSize='13px'
                  value={fname}
                  onChange={(e) => setFname(e.target.value)}
                  error={error?.fname ? true : false}
                  helperText={error?.fname}
                />
              </div>
              <div style={{ paddingTop: '15px' }}>
                <MynyfyInput
                  fullWidth
                  inputTitle={'Enter your Email '}
                  placeHolder={'Enter your Email'}
                  inputTitleSize='13px'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  error={error?.email ? true : false}
                  helperText={error?.email}
                />
              </div>
              <div style={{ paddingTop: '10px' }}>
                <MynyfyInput
                  fullWidth
                  inputTitle={'Enter Business name'}
                  placeHolder={'Enter Business Name'}
                  inputTitleSize='13px'
                  value={store}
                  onChange={(e) => {
                    setStore(e.target.value);
                    setShopname(e.target.value);
                  }}
                  error={error?.store ? true : false}
                  helperText={error?.store}
                />
              </div>
            </div>

            <div style={{ display: 'flex', justifyContent: 'right' }}>
              <MynyfyButton
                label={'Next'}
                backgroundColor={'#ffff'}
                textColor={'black'}
                endIcon={<NavigateNextRounded />}
                onClick={() => handleNext({ fname, email, store })}
                className='custom-button'
              />
            </div>
          </Card>
        )}
        {currentCard === 2 && (
          <Card
            style={{ marginTop: '20px', padding: '20px', width: '400px', borderRadius: '10px' }}>
            <MynyfyText
              title={'Provide your Business Details'}
              h4
              center
              style={{ paddingBottom: '10px' }}
            />
            <div style={{ paddingInline: '15px' }}>
              <MynyfyText title={'Buid your URL Id'} h4 center />
              <div style={{ paddingTop: '10px' }}>
                <MynyfyInput
                  fullWidth
                  placeHolder={'URL Id'}
                  inputTitleSize='13px'
                  value={urlId}
                  onChange={(e) => setUrlId(e.target.value)}
                  error={error?.urlId ? true : false}
                  helperText={error?.urlId}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <Tooltip
                          position='left-end'
                          arrow
                          html={
                            <div
                              style={{ background: 'grey', padding: '10px 20px', borderRadius: 8 }}>
                              <MynyfyText title={'1. Only alphabets, numbers and'} color={'#fff'} />
                              &ensp;&nbsp;
                              <MynyfySpan title={'dot(.) are allowed'} color={'#fff'} />
                              <MynyfyText
                                title={'2. No dot(.) at stating and ending'}
                                color={'#fff'}
                              />
                              <MynyfyText
                                title={'3. No adjacent dots(..) are allowed'}
                                color={'#fff'}
                              />
                              <MynyfyText title={'4. No Spaces are allowed'} color={'#fff'} />
                            </div>
                          }>
                          <IconButton edge='end'>
                            <InfoRounded />
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
                <MynyfySpan title={'Customeres can access your website '} pSmall />
                <MynyfySpan
                  title={`url: ${process.env.REACT_APP_WEB_HOST}/Stores/${urlId || ''}`}
                  primary
                  semiBold
                />
                <MynyfyText
                  title={'You can also configure your domain to this link later'}
                  pSmall
                />
              </div>
            </div>

            <div className='rowSB'>
              <MynyfyButton
                label={'Back'}
                backgroundColor={'#ffff'}
                textColor={'black'}
                startIcon={<NavigateBeforeRounded />}
                onClick={handleBack}
                className='custom-button'
              />
              <MynyfyButton
                label={'Next'}
                backgroundColor={'#ffff'}
                textColor={'black'}
                endIcon={<NavigateNextRounded />}
                onClick={() => handleNext({ urlId })}
                className='custom-button'
              />
            </div>
          </Card>
        )}

        {currentCard === 3 && (
          <Card style={{ marginTop: '20px', padding: '20px', borderRadius: '10px' }}>
            <div style={{ paddingInline: '15px' }}>
              <MynyfyText title={'Partner Details'} h4 center />
              <div style={{ paddingInline: '15px' }}>
                <div style={{ paddingTop: '15px' }}>
                  <MynyfyInput
                    inputTitle={'Enter Partner Id (Optional)'}
                    placeHolder={'Enter Partner Id'}
                    inputTitleSize='13px'
                    value={partnerId}
                    onChange={(e) => {
                      setpartnerId(e.target.value);
                    }}
                    error={error?.partnerId ? true : false}
                    helperText={
                      'Enter Mynyfy preferred partner id if your account is created by a partner.'
                    }
                  />
                </div>
              </div>
            </div>

            <div className='rowSB'>
              <MynyfyButton
                label={'Back'}
                backgroundColor={'#ffff'}
                textColor={'black'}
                startIcon={<NavigateBeforeRounded />}
                onClick={handleBack}
                className='custom-button'
              />
              <MynyfyButton
                label={'Submit'}
                backgroundColor={'#ffff'}
                textColor={'black'}
                className='custom-button'
                onClick={submitHandler}
              />
            </div>
          </Card>
        )}
      </div>
    </div>
  ) : (
    <MynyfyEmptyMsg message={'No details'} />
  );
};
export default WebRegistration;
