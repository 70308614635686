import React from "react";
import './WebSuggestedSellers.scss';
import MynyfyText from "../../components/MynyfyText";
import WebMynyfyText from "../../components/WebMynyfyText";

const suggestions = [
  {
    username: "Pranavi Retail",
    followers: "Followed by HR Retail",
    profilePic: require('../../assets/imgs/pizza.jpg'), 
  },
  {
    username: "HR Retail",
    followers: "Followed by HMT Foods + 14 others",
    profilePic: require('../../assets/imgs/DealsHomepageBanner.png'),
  },
  {
    username: "HMT Foods",
    followers: "Followed by HR Retail",
    profilePic: require('../../assets/imgs/offersImage.png'),
  },
  {
    username: "Sasi Bakery",
    followers: "Followed by varunreddy_27",
    profilePic: require('../../assets/imgs/weekenddeals.png'),
  },
  {
    username: "Rekha Garments",
    followers: "Followed by Sasi Bakery + 22 others",
    profilePic: require('../../assets/imgs/playstore.png'),
  },
];
const WebSuggestedSellers = () => {
  return (
    <div className="suggested-list">
      <div className="header">
        <WebMynyfyText title={'Recently Viewed'} bold/>
        <a href="#" className="see-all">
          See All
        </a>
      </div>
      {suggestions.map((suggestion, index) => (
        <div className="suggestion-item" key={index}>
          <img
        //   src={require('../../assets/imgs/pizza.jpg')}
            src={suggestion.profilePic}
            // alt={suggestion.username}
            className="profile-pic"
          />
          <div className="user-info">
            <h5 className="username">{suggestion.username}</h5>
            <p className="followers">{suggestion.followers}</p>
          </div>
          <button className="follow-button">Follow</button>
        </div>
      ))}
    </div>
  );
};

export default WebSuggestedSellers;
