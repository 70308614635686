import React, { Fragment } from 'react';
import _ from 'lodash';
import { PaymentMode } from '../utils/enums';
import MynyfyRadioButton from './MynyfyRadioButton';
import MynyfyModal from './MynyfyModal';
import MynyfyText from './MynyfyText';

const SelectPaymentMode = ({ isVisible, closeModal, selectedPaymentMode, setData, payModes }) => {
  const selectHandler = (mode) => {
    setData(mode);
    closeModal();
  };

  return (
    <MynyfyModal open={isVisible} close={() => closeModal()}>
      <div style={{ maxHeight: '75%', backgroundColor: '#fff' }}>
        <div id='OrderModals'>
          <MynyfyText title={'Select Payment Mode'} bold h5 />
          <div style={{ paddingVertical: 20 }}>
            {payModes?.includes(PaymentMode.CASH) || _.isEmpty(payModes) ? (
              <MynyfyRadioButton
                card
                onClick={() => selectHandler(PaymentMode.CASH)}
                isSelected={selectedPaymentMode === PaymentMode.CASH ? true : false}
                LableComponent={() => (
                  <div className='rowSB' style={{ flex: 1, paddingRight: 15 }}>
                    <MynyfyText title={'Pay by Cash'} p bold />
                    <img
                      src={require('../assets/imgs/icons/cash.png')}
                      style={{ width: 25, height: 25 }}
                    />
                  </div>
                )}
              />
            ) : null}
            {payModes?.includes(PaymentMode.UPI_QR) ? (
              <MynyfyRadioButton
                card
                onClick={() => selectHandler(PaymentMode.UPI_QR)}
                isSelected={selectedPaymentMode === PaymentMode.UPI_QR ? true : false}
                LableComponent={() => (
                  <div className='rowSB' style={{ flex: 1, paddingRight: 15 }}>
                    <MynyfyText title={'Pay to UPI Id'} p bold />
                    <img
                      src={require('../assets/imgs/icons/upi.webp')}
                      className='paymodeImg'
                      style={{ width: 50, height: 20, margin: '2.5px 0px' }}
                    />
                  </div>
                )}
              />
            ) : null}
            {payModes?.includes(PaymentMode.ONLINE) ? (
              <Fragment>
                <MynyfyRadioButton
                  card
                  onClick={() => selectHandler(PaymentMode.RAZOR_PAY)}
                  isSelected={selectedPaymentMode === PaymentMode.RAZOR_PAY ? true : false}
                  LableComponent={() => (
                    <div className='rowSB' style={{ flex: 1, paddingRight: 15 }}>
                      <MynyfyText title={'Pay Now'} p bold />
                      <img
                        src={require('../assets/imgs/icons/razorpay.png')}
                        className='paymodeImg'
                      />
                    </div>
                  )}
                />
                {/* <MynyfyRadioButton
                  card
                  onClick={() => selectHandler(PaymentMode.UPI)}
                  isSelected={selectedPaymentMode === PaymentMode.UPI ? true : false}
                  LableComponent={() => (
                    <div className='rowSB' style={{ flex: 1, paddingRight: 15 }}>
                      <MynyfyText title={'Pay with UPI'} p bold />
                      <img
                        src={require('../assets/imgs/icons/upi.webp')}
                        className='paymodeImg'
                        style={{ width: 50, height: 20, margin: '2.5px 0px' }}
                      />
                    </div>
                  )}
                /> */}
                {/* <MynyfyRadioButton
                  card
                  onClick={() => selectHandler(PaymentMode.SAB_PAISA)}
                  isSelected={selectedPaymentMode === PaymentMode.SAB_PAISA ? true : false}
                  LableComponent={() => (
                    <div className='rowSB' style={{ flex: 1, paddingRight: 15 }}>
                      <MynyfyText title={'Pay Now'} p bold />
                      <img
                        src={require('../assets/imgs/icons/sabpaisa.png')}
                        className='paymodeImg'
                      />
                    </div>
                  )}
                /> */}
              </Fragment>
            ) : null}
          </div>
        </div>
      </div>
    </MynyfyModal>
  );
};

export default SelectPaymentMode;
