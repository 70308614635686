import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import MynyfyText from '../../components/MynyfyText';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import _ from 'lodash';
import MynyfyEmptyMsg from '../../components/MynyfyEmptyMsg';
import MynyfyLoader from '../../components/MynfyfLoader';
import WebMynyfyHeader from '../../components/WebMynyfyHeader';
import mynyfyLogo from '../../assets/imgs/LandingPage/mynyfy.png';
import './WebStores.scss';
import { Card, Drawer, IconButton } from '@mui/material';
import {
  ContactPhoneOutlined,
  StyleOutlined,
  Menu,
  ChevronLeftRounded,
  Facebook,
  Instagram,
  YouTube,
  WhatsApp,
} from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { userProfile } from '../../redux/actions/user.action';
import { snackbarInfo } from '../../redux/actions/snackbar.action';
import {
  fetchFavOfflineStores,
  fetchFavStores,
  fetchOfflineStores,
  fetchStores,
} from '../../redux/actions/store.action';
import { likeStore, unlikeStore } from './StoresHelper';
import storeService from '../../services/storeService';
import { fetchPromos, fetchSortedPromos } from '../../redux/actions/promo.action';
import Promos from '../Promos';
import { OrderStatus, ProductType, PromoType } from '../../utils/enums';
import SignInModal from '../Auth/SignInModal';
import WebProducts from '../Products/Webindex';
import {
  fetchBagAbout,
  fetchBagCalculation,
  fetchBagOffers,
  fetchBagProducts,
  fetchBagSellingId,
} from '../../redux/actions/bag.action';
import promoService from '../../services/promoService';
import ProductCartHelper from '../Products/ProductCartHelper';
import { Carousel } from 'react-responsive-carousel';
import moment from 'moment';
import { openUrl, s3ToCloudFront, storeTimingHandler } from '../../utils/CommonMethods';
import productOrderService from '../../services/productOrderService';
import { ReactComponent as OrdersIcon } from '../../assets/svg/basket.svg';
import { ReactComponent as ContestsIcon } from '../../assets/svg/shop_and_win.svg';
import { ReactComponent as CouponsIcon } from '../../assets/svg/coupons.svg';
import { ReactComponent as PoliciesIcon } from '../../assets/svg/outlineRefund.svg';
import { ReactComponent as UserIcon } from '../../assets/svg/user.svg';
import { ReactComponent as SearchIcon } from '../../assets/svg/thinSearch.svg';
import theme from '../../utils/theme';
import WebOrdersList from '../Orders/WebOrdersList';
import { fetchOrders, fetchOrdersHistory } from '../../redux/actions/order.action';
import WebStoreAddress from './WebStoreAddress';
import MynyfySpan from '../../components/MynyfySpan';
import { fetchVisitedStores } from '../../redux/actions/visitedStores.action';
import productService from '../../services/productService';
import MynyfyButton from '../../components/MynyfyButton';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import LocationModal from '../Location/LocationModal';
import WebShopAndWin from '../Contest/ShopAndWin/WebShopAndWin';

const WebStoreDetails = ({ storeData, noData }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const urlParams = useParams();
  const [searchParams] = useSearchParams();

  const storeRef = useRef(null);

  const profile = useSelector((state) => state.user?.profile);
  const stores = useSelector((state) => state.stores);
  const visitedStores = useSelector((state) => state.visitedStores);
  const promos = useSelector((state) => state.promos);
  const location = useSelector((state) => state.location?.location);
  const bag = useSelector((state) => state.bag);
  const isLoggedIn = useSelector((state) => state.user?.isLoggedIn);
  const orders = useSelector((state) => state.orders);

  const [storeName, setStoreName] = useState('');
  const [details, setDetails] = useState(null);
  const [isLiked, setLiked] = useState(null);
  const [openSignIn, setOpenSignIn] = useState(false);
  const [isEcommerceActive, setEcommerceActive] = useState(false);
  const [isStoreOpened, setStoreOpened] = useState(true);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [activeComponent, setActiveComponent] = useState(null);
  const [activeTab, setActiveTab] = useState('Active');
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [showRefundPolicy, setShowRefundPolicy] = useState(false);
  const [showShippingPolicy, setShowShippingPolicy] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [isDirectUrl, setDirectUrl] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [isSearchDrawerOpen, setIsSearchDrawerOpen] = useState(false);
  const [noStore, setNoStore] = useState(false);
  const [isProducts, setIsProducts] = useState(false);
  const [isServices, setIsServices] = useState(false);
  const [productsType, setProductsType] = useState(ProductType.PRODUCT);
  const [messageIndices, setMessageIndices] = useState(bag?.offers?.map(() => 0) || []);
  const [originalGroups, setOriginalGroups] = useState(null);
  const [originalSections, setOriginalSections] = useState(null);
  const [groups, setGroups] = useState(null);
  const [sections, setSections] = useState(null);
  const [selectedSection, setSelectedSection] = useState(null);
  const [actualProductsData, setActualProductsData] = useState(null);
  const [selectedBrandForProducts, setSelectedBrandForProducts] = useState(null);
  const [originalProductBrands, setOriginalProductBrands] = useState([]);
  const [isLocationModal, setIsLocationModal] = useState(false);
  const [multipleProductBrands, setMultipleProductBrands] = useState([]);

  const isFoodCategory = details?.ownerId?.categories?.find(
    (e) => e._id == process.env.REACT_APP_FOOD_CAT_ID
  );

  const caurosalFstPart = [
    details?.policies?.shippingCharges?.forRestaurant?.deliveryTime
      ? `We deliver within ${details.policies.shippingCharges.forRestaurant.deliveryTime} min`
      : null,
    details?.homeDelivery === false ? 'Offline store - Pick-up only' : null,
    !isEcommerceActive ? 'Currently not in service, please contact support.' : null,
    !isStoreOpened
      ? `Currently store is closed, Please come back at ${details.storeOpenTime}`
      : null,
  ].filter(Boolean);

  const carouselData = _.isEmpty(bag?.offers) ? caurosalFstPart : bag?.offers;

  useEffect(() => {
    let urlId = urlParams?.store;
    let table = urlParams?.table;
    if (urlId) setStoreName(urlId || '');
    if (table) {
      productOrderService
        .getProductOrderIdByTable({ table, status: OrderStatus.ACTIVE })
        .then((res) => {
          if (res.success) {
            storeDetailsHandler(res.data);
          } else storeDetailsHandler();
        });
    } else storeDetailsHandler();
  }, []);

  useEffect(() => {
    if (!details?._id) return;
    const fetchProductProps = async () => {
      try {
        const [groupRes, sectionRes] = await Promise.all([
          productService.getProductProps({ orgId: details?._id, type: 'group' }),
          productService.getProductProps({ orgId: details?._id, type: 'section' }),
        ]);
        if (groupRes.success) setOriginalGroups(groupRes.data);
        if (sectionRes.success) setOriginalSections(sectionRes.data);
      } catch (error) {
        console.error('Error fetching product props:', error);
      }
    };

    fetchProductProps();
  }, [details?._id]);

  useEffect(() => {
    if (!details?._id) return;
    const fetchProductBrands = async () => {
      let params = {
        orgId: details?._id,
        type: 'product',
      };
      productService.getProductBrandNames(params).then((res) => {
        if (res.success) {
          const data = res.data;
          if (_.isEmpty(details?.brands)) {
            setOriginalProductBrands(data);
          } else {
            let finalProductBrands = mergeProductBrands(data);
            finalProductBrands = _.sortBy(finalProductBrands, 'name');
            setMultipleProductBrands(finalProductBrands);
          }
        }
      });
    };

    fetchProductBrands();
  }, [details?._id]);

  useEffect(() => {
    if (activeComponent === 'BrandProducts') {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [activeComponent]);

  const handleGroupsAndSections = useCallback(() => {
    if (!originalGroups?.length || !originalSections?.length) return;
    const reqGroupData = [];
    const reqSectionData = [];
    originalSections?.forEach((section) => {
      if (section?.groupName?._id === originalGroups?.[0]?._id) {
        reqGroupData.push(section);
      } else {
        reqSectionData.push(section);
      }
    });
    setGroups(reqGroupData);
    setSections(reqSectionData);
  }, [originalGroups, originalSections]);

  useEffect(() => {
    handleGroupsAndSections();
  }, [handleGroupsAndSections]);

  const mergeProductBrands = (data) => {
    const grouped = {};
    data.forEach((item) => {
      const key = item.name.toLowerCase();
      if (!grouped[key]) {
        grouped[key] = [];
      }
      grouped[key].push(item);
    });
    return Object.values(grouped).map((group) =>
      group.sort((a, b) => ('image' in b) - ('image' in a))
    );
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  // Rotate the first statement message every time the carousel changes
  const handleSlideChange = (index) => {
    setMessageIndices((prevIndices) => {
      const newIndices = [...prevIndices];
      newIndices[index] = (newIndices[index] + 1) % caurosalFstPart.length;
      return newIndices;
    });
  };

  const storeDetailsHandler = (order) => {
    let urlId = urlParams?.store;
    let reqStoreData = storeData || state?.storeDetails;
    if (reqStoreData) {
      if (storeData) {
        changeTitleAndFavicon(storeData);
        setStoreName(storeData?.urlId || '');
        setDirectUrl(true);
      } else setDirectUrl(false);
      setDetails(reqStoreData);
      ecommerceStatus(reqStoreData?.ecommerceSubscription);
      let storeStatus = storeTimingHandler(reqStoreData);
      setStoreOpened(storeStatus);
      storeViewAndVisitedHandler(reqStoreData?._id);
      if (!_.isEmpty(order)) orderNavigation(order, reqStoreData);
    } else if (urlId) {
      let body = { urlId };
      storeService.getStores(body).then((res) => {
        if (res?.success) {
          if (_.isEmpty(res.data.orgList)) {
            setNoStore(true);
          } else {
            changeTitleAndFavicon(res.data.orgList[0]);
            setDetails(res.data.orgList[0]);
            setStoreName(res.data.orgList[0]?.urlId || '');
            ecommerceStatus(res.data.orgList[0]?.ecommerceSubscription);
            let storeStatus = storeTimingHandler(res.data.orgList[0]);
            setStoreOpened(storeStatus);
            setDirectUrl(true);
            storeViewAndVisitedHandler(res.data.orgList[0]?._id);
            if (!_.isEmpty(order)) orderNavigation(order, res.data.orgList[0]);
          }
        } else {
          dispatch(snackbarInfo({ type: 'error', open: true, message: res?.data?.message }));
        }
      });
    } else if (noData) setNoStore(true);
  };

  const storeViewAndVisitedHandler = (id) => {
    let visitStoreQuery = { orgId: id };
    if (profile?._id) visitStoreQuery.buyerId = profile._id;
    storeService.viewStore(visitStoreQuery);

    if (isLoggedIn) {
      if (!profile?.visitedSellers?.includes(id)) {
        storeService.addVisitedSeller(id).then((visitedSellerRes) => {
          if (visitedSellerRes.success) {
            if (visitedSellerRes.data == 'Added to Recently Visitied Successfully') {
              let clonedData = _.cloneDeep(profile);
              if (clonedData?.visitedSellers) clonedData?.visitedSellers?.push(id);
              else clonedData.visitedSellers = [id];
              dispatch(userProfile(clonedData));
              dispatch(fetchVisitedStores(null));
            }
          }
        });
      }
    }
  };

  const changeTitleAndFavicon = (data) => {
    document.title = data?.name;
    const link = document.querySelector("link[rel~='icon']");
    if (!link) {
      const newLink = document.createElement('link');
      newLink.rel = 'icon';
      newLink.href = data.logo?.url;
      document.head.appendChild(newLink);
    } else {
      link.href = data.logo?.url;
    }
  };

  const handlePolicyClick = (policy) => {
    setShowRefundPolicy(false);
    setShowShippingPolicy(false);
    setShowPrivacyPolicy(false);
    setShowTerms(false);

    switch (policy) {
      case 'Refund':
        setShowRefundPolicy(true);
        break;
      case 'Shipping':
        setShowShippingPolicy(true);
        break;
      case 'Privacy':
        setShowPrivacyPolicy(true);
        break;
      case 'Terms':
        setShowTerms(true);
        break;
      default:
        break;
    }
  };

  const productNavigationHandler = (item) => {
    let storeStatus = details ? storeTimingHandler(details) : true;
    if (storeStatus) {
      navigate(`/Product/${item._id}`, {
        state: {
          details: item,
          navFrom: 'seller',
          brandDetails: null,
          sellerDetails: details,
          isEcommerceActive: isEcommerceActive,
          table: urlParams?.table,
        },
      });
    } else {
      dispatch(
        snackbarInfo({
          open: true,
          type: 'warning',
          message: `Currently store is closed, Please come back at ${details.storeOpenTime}`,
        })
      );
    }
  };

  const renderGroupedSections = () => {
    const reqSections = groups?.filter((section) => section?.isActive);
    return (
      <Fragment>
        {reqSections?.length >= 6 ? (
          <div>
            <MynyfyText
              title={reqSections?.[0]?.groupName?.name}
              h2
              bold
              style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
            />
            <div className='product-section'>
              <div className='product-section-first-col'>
                <div className='ps-first-row'>
                  <img
                    className='first-row-img'
                    src={s3ToCloudFront(reqSections?.[0]?.image?.url)}
                    alt='test'
                  />
                  <div className='overlay'>
                    <MynyfyText
                      className={'filter-name'}
                      title={reqSections?.[0]?.name}
                      h3
                      bold
                      color={'white'}
                    />
                    <MynyfyButton
                      className='view-button'
                      label={'View'}
                      onClick={() => {
                        setSelectedSection(reqSections?.[0]);
                        setActiveComponent('Sections');
                      }}
                    />
                  </div>
                </div>
                <div className='ps-second-row' style={{ marginTop: '10px' }}>
                  <div className='second-row-img-container'>
                    <img
                      className='second-row-img'
                      src={s3ToCloudFront(reqSections?.[1]?.image?.url)}
                      alt='test'
                    />
                    <div className='overlay'>
                      <MynyfyText
                        className={'filter-name'}
                        title={reqSections?.[1]?.name}
                        h3
                        bold
                        color={'white'}
                      />
                      <MynyfyButton
                        className='view-button'
                        label={'View'}
                        onClick={() => {
                          setSelectedSection(reqSections?.[1]);
                          setActiveComponent('Sections');
                        }}
                      />
                    </div>
                  </div>
                  <div className='second-row-img-container'>
                    <img
                      className='second-row-img'
                      src={s3ToCloudFront(reqSections?.[2]?.image?.url)}
                      alt='test'
                    />
                    <div className='overlay'>
                      <MynyfyText
                        className={'filter-name'}
                        title={reqSections?.[2]?.name}
                        h3
                        bold
                        color={'white'}
                      />
                      <MynyfyButton
                        className='view-button'
                        label={'View'}
                        onClick={() => {
                          setSelectedSection(reqSections?.[2]);
                          setActiveComponent('Sections');
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='product-section-second-col'>
                <div className='ps-second-row' style={{ marginBottom: '10px' }}>
                  <div className='second-row-img-container'>
                    <img
                      className='second-row-img'
                      src={s3ToCloudFront(reqSections?.[3]?.image?.url)}
                      alt='test'
                    />
                    <div className='overlay'>
                      <MynyfyText
                        className={'filter-name'}
                        title={reqSections?.[3]?.name}
                        h3
                        bold
                        color={'white'}
                      />
                      <MynyfyButton
                        className='view-button'
                        label={'View'}
                        onClick={() => {
                          setSelectedSection(reqSections?.[3]);
                          setActiveComponent('Sections');
                        }}
                      />
                    </div>
                  </div>
                  <div className='second-row-img-container'>
                    <img
                      className='second-row-img'
                      src={s3ToCloudFront(reqSections?.[4]?.image?.url)}
                      alt='test'
                    />
                    <div className='overlay'>
                      <MynyfyText
                        className={'filter-name'}
                        title={reqSections?.[4]?.name}
                        h3
                        bold
                        color={'white'}
                      />
                      <MynyfyButton
                        className='view-button'
                        label={'View'}
                        onClick={() => {
                          setSelectedSection(reqSections?.[4]);
                          setActiveComponent('Sections');
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className='ps-first-row'>
                  <img
                    className='first-row-img'
                    src={s3ToCloudFront(reqSections?.[5]?.image?.url)}
                    alt='test'
                  />
                  <div className='overlay'>
                    <MynyfyText
                      className={'filter-name'}
                      title={reqSections?.[5]?.name}
                      h3
                      bold
                      color={'white'}
                    />
                    <MynyfyButton
                      className='view-button'
                      label={'View'}
                      onClick={() => {
                        setSelectedSection(reqSections?.[5]);
                        setActiveComponent('Sections');
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <MynyfyText
              title={reqSections?.[0]?.groupName?.name}
              h5
              bold
              style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
            />
            <div className='product-section'>
              <div className='grid-layout'>
                {reqSections?.map((section, index) => (
                  <div key={index} className='grid-item'>
                    <img
                      className='grid-img'
                      src={s3ToCloudFront(section?.image?.url)}
                      alt={section?.name}
                    />
                    <div className='overlay'>
                      <MynyfyText
                        className={'filter-name'}
                        title={section?.name}
                        h3
                        bold
                        color={'white'}
                      />
                      <MynyfyButton
                        className='view-button'
                        label={'View'}
                        onClick={() => {
                          setSelectedSection(section);
                          setActiveComponent('Sections');
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </Fragment>
    );
  };

  const renderSections = (data) => {
    return (
      <div className='non-image-product-section'>
        <MynyfyText title={data?.name} h3 bold />
        <MynyfyText
          title={'View all'}
          className='cursor'
          color={'#3076b5'}
          semiBold
          pBig
          onClick={() => {
            setSelectedSection(data);
            setActiveComponent('Sections');
          }}
          style={{ borderBottom: '2px solid #3076b5', paddingBottom: '3px', marginBottom: 20 }}
        />
        <div className='product-container' style={{ maxWidth: '1200px' }}>
          {actualProductsData
            ?.filter((product) => product?.sections?.map(String).includes(String(data?._id)))
            ?.slice(0, 5)
            ?.map((product, index) => (
              <div
                key={index}
                className='filter-product cursor'
                style={{
                  backgroundColor: 'white',
                  padding: '10px',
                  boxShadow: '0 2px 15px 0 rgba(57, 56, 56, 0.1)',
                  borderRadius: '10px',
                  border: '1px solid #e8e8e8',
                }}
                onClick={() => productNavigationHandler(product)}>
                <img
                  className='filter-product-image'
                  src={s3ToCloudFront(product?.variants?.[0]?.image?.[0]?.url)}
                  alt='product img'
                  style={{ objectFit: 'cover' }}
                />
                <div
                  style={{
                    marginTop: '10px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'start',
                  }}>
                  <div>
                    <MynyfyText title={product?.name} h5 />
                    <MynyfyText title={theme.RUPEE + product?.variants?.[0]?.price} />
                  </div>
                  <MynyfyButton
                    label={'View'}
                    style={{
                      backgroundColor: 'white',
                      color: 'black',
                      borderRadius: '3px',
                      border: '1px solid black',
                      height: '30px',
                    }}
                  />
                </div>
              </div>
            ))}
        </div>
      </div>
    );
  };

  const renderComponent = () => {
    switch (activeComponent) {
      case 'About Us':
        return (
          <div>
            <div className='Heading'>
              <MynyfyText title={'About Us'} h2 left bold style={{ paddingleft: 30 }} />
            </div>
            <div className='aboutus-container'>
              <div className='content'>
                <MynyfyText title={details?.aboutUs} h6 style={{ lineHeight: '25px' }} />
              </div>
            </div>
          </div>
        );
      case 'Sections':
        return (
          <WebProducts
            from={'seller'}
            sellerDetails={details}
            isEcommerceActive={isEcommerceActive}
            type={searchParams.get('type')}
            table={urlParams?.table}
            searchValue={searchInput}
            setIsProducts={(e) => setIsProducts(e)}
            setIsServices={(e) => setIsServices(e)}
            setProductsType={(e) => setProductsType(e)}
            productsType={productsType}
            section={selectedSection}
            handleActiveComponent={() => handleActiveComponent()}
            setActualProductsData={setActualProductsData}
          />
        );
      case 'BrandProducts':
        return (
          <WebProducts
            from={'seller'}
            sellerDetails={details}
            isEcommerceActive={isEcommerceActive}
            type={searchParams.get('type')}
            table={urlParams?.table}
            searchValue={searchInput}
            setIsProducts={(e) => setIsProducts(e)}
            setIsServices={(e) => setIsServices(e)}
            setProductsType={(e) => setProductsType(e)}
            productsType={productsType}
            section={selectedSection}
            handleActiveComponent={() => handleActiveComponent()}
            setActualProductsData={setActualProductsData}
            selectedBrandForProducts={selectedBrandForProducts}
          />
        );
      case 'Orders':
        return (
          <div>
            <div className='Heading'>
              <MynyfyText title={'Orders'} h2 left bold style={{ paddingleft: 30 }} />
            </div>
            <div className='order-container'>
              <div className='tab-buttons'>
                <div
                  style={{
                    padding: '10px',
                    borderBottom:
                      activeTab === 'Active'
                        ? `2px solid ${theme.PRIMARY_BLUE}`
                        : '2px solid #e8e8e8',
                  }}
                  onClick={() => handleTabClick('Active')}>
                  <MynyfyText
                    title={'Active'}
                    color={activeTab === 'Active' ? theme.PRIMARY_BLUE : 'black'}
                    semiBold
                  />
                </div>
                <div
                  style={{
                    padding: '10px',
                    borderBottom:
                      activeTab === 'Completed'
                        ? `2px solid ${theme.PRIMARY_BLUE}`
                        : '2px solid #e8e8e8',
                  }}
                  onClick={() => handleTabClick('Completed')}>
                  <MynyfyText
                    title={'Completed'}
                    color={activeTab === 'Completed' ? theme.PRIMARY_BLUE : 'black'}
                    semiBold
                  />
                </div>
              </div>
              <div className='tab-content'>
                {activeTab === 'Active' && (
                  <WebOrdersList
                    type='ACTIVE'
                    data={orders?.active}
                    action={fetchOrders}
                    sellerId={details._id}
                  />
                )}
                {activeTab === 'Completed' && (
                  <WebOrdersList
                    type='NONACTIVE'
                    data={orders?.history}
                    action={fetchOrdersHistory}
                    sellerId={details._id}
                  />
                )}
              </div>
            </div>
          </div>
        );

      case 'Contests':
        return (
          <div>
            <div className='Heading'>
              <MynyfyText title={'Contests'} h2 left bold style={{ paddingleft: 30 }} />
            </div>
            <div className='web-store-container'>
              <div className='content-area'>
                <WebShopAndWin
                  from={'seller'}
                  sellerDetails={details}
                  isDirectUrl={isDirectUrl}
                  noHeader
                />
              </div>
            </div>
          </div>
        );
      case 'Coupons':
        return (
          <div>
            <div className='Heading'>
              <MynyfyText title={'Coupons'} h2 left bold style={{ paddingleft: 30 }} />
            </div>
            <div className='coupon-container'>
              <Promos
                type={[PromoType.COUPON, PromoType.POINT_COUPON]}
                isFilter={false}
                emptyListTitle={'Coupons'}
                emptyListMsg={
                  isLoggedIn ? ['No Active coupons or offers'] : 'Please Login for Coupons'
                }
                emptyListMsgBold={isLoggedIn ? [1, 2] : null}
                promos={promos?.promos?.actual}
                sortedPromos={promos?.promos?.sorted}
                action={fetchPromos}
                sortedAction={fetchSortedPromos}
                sellerDetails={details}
                screen={'Coupons'}
                from={'seller'}
                isDirectUrl={isDirectUrl}
              />
            </div>
          </div>
        );

      case 'Contact Us':
        return (
          <div>
            <div className='Heading'>
              <MynyfyText title={'Contact Us'} h2 left bold style={{ paddingleft: 30 }} />
            </div>
            <div className='contactus-container'>
              <WebStoreAddress details={details} />
            </div>
          </div>
        );

      case 'Policies':
        return (
          <div>
            <div className='Heading'>
              <MynyfyText title={'Our Policies'} h2 left bold style={{ paddingleft: 30 }} />
            </div>
            <div className='policy-container'>
              <div className='sidebar-menu'>
                <button className='menu-button' onClick={() => handlePolicyClick('Refund')}>
                  <MynyfyText title={'Refund Policy'} h6 />
                </button>
                <button className='menu-button' onClick={() => handlePolicyClick('Shipping')}>
                  <MynyfyText title={'Shipping Policy'} h6 />
                </button>
                <button className='menu-button' onClick={() => handlePolicyClick('Privacy')}>
                  <MynyfyText title={'Privacy Policy'} h6 />
                </button>
                <button className='menu-button' onClick={() => handlePolicyClick('Terms')}>
                  <MynyfyText title={'Terms & Conditions'} h6 />
                </button>
              </div>
              <div className='content-area' style={{ width: '75%', marginTop: 20, marginLeft: 20 }}>
                {showPrivacyPolicy && (
                  <div>
                    <MynyfyText title={details.policies.privacy[0]?.heading} h5 />
                    <MynyfyText
                      title={details.policies.privacy[0]?.content}
                      h5
                      style={{ paddingTop: 10 }}
                    />
                  </div>
                )}
                {showRefundPolicy && (
                  <div>
                    <MynyfyText title={details.policies.refund[0]?.heading} h5 />
                    <MynyfyText
                      title={details.policies.refund[0]?.content}
                      h5
                      style={{ paddingTop: 10 }}
                    />
                  </div>
                )}
                {showShippingPolicy && (
                  <div>
                    <MynyfyText title={details.policies.shipping[0]?.heading} h5 />
                    <MynyfyText
                      title={details.policies.shipping[0]?.content}
                      h5
                      style={{ paddingTop: 10 }}
                    />
                  </div>
                )}
                {showTerms && (
                  <div>
                    <MynyfyText title={details.policies.terms[0]?.heading} h5 />
                    <MynyfyText
                      title={details.policies.terms[0]?.content}
                      h5
                      style={{ paddingTop: 10 }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const orderNavigation = (order, orgData) => {
    let table = urlParams?.table;
    navigate('/TableOrder/' + table, { state: { order, storeDetails: orgData } });
  };

  const ecommerceStatus = (subscription) => {
    if (subscription) {
      let subDays = moment(subscription).diff(moment(), 'days');
      if (subDays >= 0) {
        setEcommerceActive(true);
      } else {
        setEcommerceActive(false);
      }
    } else {
      setEcommerceActive(false);
    }
  };

  useEffect(() => {
    if (!_.isEmpty(details)) {
      if (bag?.id === details._id) {
        if (bag?.about?.isStoreOpened !== isStoreOpened) {
          dispatch(fetchBagAbout({ ...bag?.about, isStoreOpened }));
        }
      } else {
        dispatch(fetchBagSellingId(details._id));
        dispatch(
          fetchBagAbout({
            type: 'Store',
            name: details.name,
            city: details.city,
            state: details.state,
            policies: details.policies,
            urlId: details.urlId,
            url: process.env.REACT_APP_WEB_HOST + '/Stores/' + details.urlId,
            orgDetails: details,
            isStoreOpened,
          })
        );
        if (isLoggedIn) getBuyerPromos(details);
        dispatch(fetchBagCalculation(null));
        dispatch(fetchBagProducts(null));
      }
    }
  }, [details]);

  const getBuyerPromos = (org) => {
    let body = {
      type: [PromoType.COUPON, PromoType.REWARD],
      noOrg: true,
    };
    if (isLoggedIn) body.buyer = profile._id;
    if (_.isEmpty(org.brands)) body.organization = org._id;
    else body.brand = org.brands;
    promoService.getPromosForOrder(body).then((res) => {
      if (res.success) {
        dispatch(fetchBagOffers(res.data));
      }
    });
  };

  const likeHandler = (storeId, key) => {
    if (isLoggedIn) {
      let options = {
        storeId,
        setLiked,
        profile,
        data: state?.isFav ? stores?.favStores : state?.isVisited ? visitedStores : stores?.stores,
        dispatch,
        action: state?.isFav
          ? details.homeDelivery
            ? fetchFavStores
            : fetchFavOfflineStores
          : state?.isVisited
          ? fetchVisitedStores
          : details.homeDelivery
          ? fetchStores
          : fetchOfflineStores,
        userProfile,
        snackbarInfo,
      };
      if (key === 'like') {
        likeStore(options);
        if (_.isEmpty(options.data)) details.likesCount = details.likesCount + 1;
      } else {
        unlikeStore(options);
        if (_.isEmpty(options.data)) details.likesCount = details.likesCount - 1;
      }
    } else {
      setOpenSignIn(true);
    }
  };

  const handleClick = () => {
    if (isLoggedIn) navigate('/profile', { state: { isDirectUrl } });
    else setOpenSignIn(true);
  };

  const handleActiveComponent = (e) => {
    setActiveComponent(null);
  };

  const getCompleteUrl = (url) => {
    if (url?.startsWith('http://') || url?.startsWith('https://')) {
      return url;
    } else {
      return `https://${url}`;
    }
  };

  return details ? (
    _.isEmpty(details) ? (
      <Fragment>
        <WebMynyfyHeader title={storeName || 'Store Page'} />
        <MynyfyEmptyMsg
          message={['We are unable to fetch the', storeName || 'Store Page']}
          bold={[1]}
        />
      </Fragment>
    ) : (
      <div
        id='WebStores'
        ref={storeRef}
        style={{ height: '100vh', overflow: 'auto', scrollBehavior: 'smooth' }}>
        {!_.isEmpty(carouselData) && (
          <div
            style={{
              position: 'fixed',
              top: '0px',
              width: '100%',
              zIndex: 10,
              backgroundColor: 'black',
              height: 50,
            }}>
            <Carousel
              infiniteLoop
              autoPlay
              showStatus={false}
              showArrows={false}
              showThumbs={false}
              showIndicators={false}
              interval={4000}
              transitionTime={800}
              onChange={handleSlideChange}>
              {carouselData?.map((item, index) => (
                <div
                  key={index}
                  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Card
                    style={{
                      width: '100%',
                      boxShadow: 'none',
                      backgroundColor: 'black',
                    }}
                    // onClick={() => setActiveComponent('Coupons')}
                    key={item?._id}
                    className='offersDisplayCard cursor'>
                    <div className='rowC' style={{ paddingTop: 0 }}>
                      <div>
                        <MynyfyText
                          title={caurosalFstPart[messageIndices[index]] || ''}
                          p
                          style={{ color: 'white' }}
                        />
                      </div>
                      {_.isEmpty(bag?.offers) || item?.minimumOrderValue === undefined ? null : (
                        <div className='row'>
                          <MynyfyText
                            title={theme.RUPEE + item?.actualPrice + ' OFF'}
                            bold
                            p
                            style={{ color: 'white' }}
                          />

                          <MynyfyText
                            title={'On a min order of ' + theme.RUPEE + item.minimumOrderValue}
                            style={{ color: 'white' }}
                          />
                        </div>
                      )}
                      {details?.messageToUser && (
                        <div>
                          <MynyfyText title={details?.messageToUser} p style={{ color: 'white' }} />
                        </div>
                      )}
                    </div>
                  </Card>
                </div>
              ))}
            </Carousel>
          </div>
        )}
        <div id='WebMynyfyHeader' style={{ marginTop: !_.isEmpty(carouselData) ? 50 : 0 }}>
          <Card
            className='rowSB mynyfyHeader'
            style={{ boxShadow: 'none', top: _.isEmpty(carouselData) ? 0 : 50 }}>
            <div
              className='row cursor'
              style={{ gap: 5, paddingLeft: 30 }}
              onClick={() => {
                setSelectedSection(null);
                setActiveComponent(null);
              }}>
              {state?.storeDetails ? (
                <ChevronLeftRounded
                  style={{ width: 30, height: 30, display: 'block' }}
                  onClick={() => navigate(-1)}
                />
              ) : null}
              {details?.logo?.url ? (
                <div style={{ width: 40, height: 40, borderRadius: '50%' }}>
                  <img
                    src={s3ToCloudFront(details.logo.url)}
                    width={40}
                    height={40}
                    style={{ objectFit: 'fill', borderRadius: '50%' }}
                    alt='Store logo'
                  />
                </div>
              ) : null}
              <MynyfyText className='StoreName' title={details.name} gayahi color={'#000'} h3 />
            </div>

            <div className='MenuItems'>
              {isProducts || isServices ? null : (
                <MynyfyText
                  title={isFoodCategory ? 'Menu' : 'Products'}
                  h6
                  color={'black'}
                  onClick={() => {
                    setSelectedSection(null);
                    setActiveComponent(null);
                  }}
                  className='menu-item cursor'
                />
              )}
              {isProducts ? (
                <MynyfyText
                  title={isFoodCategory ? 'Menu' : 'Products'}
                  className='menu-item cursor'
                  color={'black'}
                  h6
                  onClick={() => {
                    setSelectedSection(null);
                    setActiveComponent(null);
                    setProductsType(ProductType.PRODUCT);
                  }}
                />
              ) : null}
              {isServices ? (
                <MynyfyText
                  title={'Services'}
                  className='menu-item cursor'
                  color={'black'}
                  h6
                  onClick={() => {
                    setActiveComponent(null);
                    setProductsType(ProductType.SERVICE);
                  }}
                />
              ) : null}
              <MynyfyText
                title={'Orders'}
                h6
                color={'black'}
                onClick={() => {
                  if (isLoggedIn) setActiveComponent('Orders');
                  else setOpenSignIn(true);
                }}
                className='menu-item'
                style={{ cursor: 'pointer' }}
              />
              <MynyfyText
                title={'Contests'}
                h6
                color={'black'}
                onClick={() => setActiveComponent('Contests')}
                className='menu-item'
                style={{ cursor: 'pointer' }}
              />
              <MynyfyText
                title={'Coupons'}
                h6
                color={'black'}
                onClick={() => setActiveComponent('Coupons')}
                className='menu-item'
                style={{ cursor: 'pointer' }}
              />
            </div>
            <div className='right-side'>
              <div
                style={{
                  border: '1px solid #dddddd',
                  borderRadius: 10,
                  padding: '10px 10px',
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                  backgroundColor: '#f9f9fa',
                }}
                onClick={() => setIsSearchDrawerOpen(!isSearchDrawerOpen)}>
                <SearchIcon color='gray' className='cursor' />
                <input
                  placeholder={'What are you looking for? '}
                  value={searchInput}
                  onChange={(e) => {
                    setSearchInput(e.target.value);
                  }}
                  style={{
                    width: '170px',
                    border: 'none',
                    outline: 'none',
                    backgroundColor: '#f9f9fa',
                    paddingLeft: '5px',
                    fontSize: '14px',
                    color: 'gray',
                    marginLeft: 5,
                    borderRadius: 3,
                  }}
                />
              </div>
              <ProductCartHelper
                from='web'
                navFrom='seller'
                sellerDetails={details}
                table={urlParams?.table}
              />
              <UserIcon
                style={{ width: 30, height: 30, display: 'block', paddingLeft: 10 }}
                className='cursor'
                onClick={handleClick}
              />
              <LocationOnOutlinedIcon
                style={{ width: 30, height: 30, display: 'block', paddingLeft: 10 }}
                className='cursor'
                onClick={() => setIsLocationModal(true)}
              />
              <IconButton onClick={handleDrawerOpen} className='burger-menu'>
                <Menu />
              </IconButton>
            </div>
          </Card>

          <Drawer
            anchor='top'
            open={drawerOpen}
            onClose={handleDrawerClose}
            sx={{
              '& .MuiDrawer-paper': {
                width: 200,
                top: 60,
                left: 220,
                margin: '0 auto',
                height: 'auto',
              },
            }}
            id='Stores'>
            <div
              style={{ width: '100%', cursor: 'pointer' }}
              role='presentation'
              onClick={handleDrawerClose}
              onKeyDown={handleDrawerClose}>
              <div className='row drawerOption' onClick={() => setActiveComponent('About Us')}>
                <StyleOutlined width={20} height={20} />
                <MynyfyText title={isFoodCategory ? 'Menu' : 'About Us'} h5 color={'black'} />
              </div>
              <div className='row drawerOption' onClick={() => setActiveComponent(null)}>
                <StyleOutlined width={20} height={20} />
                <MynyfyText title={isFoodCategory ? 'Menu' : 'Products'} h5 color={'black'} />
              </div>
              <div
                className='row drawerOption'
                onClick={() => {
                  if (isLoggedIn) setActiveComponent('Orders');
                  else setOpenSignIn(true);
                }}>
                <OrdersIcon width={20} height={20} />
                <MynyfyText title={'Orders'} h5 color={'black'} />
              </div>

              <div className='row drawerOption' onClick={() => setActiveComponent('Contests')}>
                <ContestsIcon width={20} />
                <MynyfyText title={'Contests'} h5 color={'black'} />
              </div>

              <div className='row drawerOption' onClick={() => setActiveComponent('Coupons')}>
                <CouponsIcon width={20} height={20} />
                <MynyfyText title={'Coupons'} h5 color={'black'} />
              </div>

              <div className='row drawerOption' onClick={() => setActiveComponent('Contact Us')}>
                <ContactPhoneOutlined style={{ color: 'black', width: '20px', height: '20px' }} />
                <MynyfyText title={'Contact Us'} h5 color={'black'} />
              </div>

              <div className='row drawerOption' onClick={() => setActiveComponent('Policies')}>
                <PoliciesIcon width={20} height={20} />
                <MynyfyText title={'Policies'} h5 color={'black'} />
              </div>
            </div>
          </Drawer>
        </div>

        {activeComponent ? (
          <div className='ren-storelayout'>{renderComponent()}</div>
        ) : (
          <div style={{ backgroundColor: '#fff' }}>
            {_.isEmpty(details.orgImages) ? null : (
              <Carousel
                infiniteLoop={true}
                autoPlay={true}
                showStatus={false}
                showArrows={false}
                showThumbs={false}
                showIndicators={false}
                interval={3000}>
                {details.orgImages?.map((res, i) => (
                  <div key={i} style={{ paddingBottom: '20px', marginTop: 20 }}>
                    <img
                      src={s3ToCloudFront(res.url)}
                      className={isFoodCategory ? 'storeImg' : 'normalStoreImg'}
                      alt='store img'
                      style={{ height: '500px' }}
                    />
                  </div>
                ))}
              </Carousel>
            )}
            <div className='storelayout'>
              <div className='rowSB' style={{ padding: 15 }}>
                {details?.logo?.url && !details?.orgImages ? (
                  <MynyfyText
                    title={details.name}
                    gayahi
                    style={{
                      fontSize: 16,
                      fontWeight: 600,
                      color: '#000',
                      letterSpacing: 1,
                    }}
                    center
                  />
                ) : null}
                <div>
                  {details.homeDelivery ? null : (
                    <div style={{ marginBottom: 5 }}>
                      <MynyfyText title={'Offline store - Pick-up only'} h5 />
                    </div>
                  )}
                  {isEcommerceActive ? null : (
                    <div>
                      <MynyfyText
                        title={'Currently not in service, please contact support.'}
                        error
                        h5
                      />
                    </div>
                  )}
                </div>
                {isStoreOpened ? null : (
                  <div>
                    <MynyfyText
                      title={
                        'Currently store is closed, Please come back at ' + details.storeOpenTime
                      }
                      error
                      h5
                    />
                  </div>
                )}
              </div>
              <div className='custom-product-section-container'>
                {groups?.length > 0 && renderGroupedSections()}
              </div>
              <div className='custom-product-section-container-no-image'>
                {sections?.length > 0 &&
                  sections?.map((section, index) => {
                    const isSectionPresent =
                      section?.isActive &&
                      actualProductsData?.some((product) =>
                        product?.sections?.map(String).includes(String(section?._id))
                      );
                    return isSectionPresent ? renderSections(section, index) : null;
                  })}
              </div>
              {_.isEmpty(
                details?.brands?.[0]?._id ? multipleProductBrands : originalProductBrands
              ) ? null : (
                <div className='shopByBrands' style={{ margin: '3% 0' }}>
                  <MynyfyText title={'Shop by Brands'} h5 bold />
                  <div className='row brandRow' style={{ maxWidth: '900px', gap: 30 }}>
                    {(details?.brands?.[0]?._id
                      ? multipleProductBrands
                      : originalProductBrands
                    )?.map((brand, i) => (
                      <div
                        key={i}
                        className='brand cursor'
                        onClick={() => {
                          setSelectedBrandForProducts(
                            details?.brands?.[0]?._id ? brand?.[0] : brand
                          );
                          setActiveComponent('BrandProducts');
                        }}>
                        {brand?.image?.url || brand?.[0]?.image?.url ? (
                          <img
                            className='logo'
                            src={s3ToCloudFront(brand?.image?.url || brand?.[0]?.image?.url)}
                            alt='brand logo'
                            style={{
                              objectFit: 'contain',
                              width: '100px',
                              height: '80px',
                              border: '1px solid #e8e8e8',
                              borderRadius: 10,
                            }}
                          />
                        ) : (
                          <div
                            style={{
                              width: '100px',
                              height: '80px',
                              backgroundColor: '#e8e8e8',
                              border: '1px solid #e8e8e8',
                              borderRadius: 10,
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}>
                            <MynyfyText title={brand?.name || brand?.[0]?.name} bold />
                          </div>
                        )}
                        <MynyfyText
                          className={'name'}
                          title={brand?.name || brand?.[0]?.name}
                          style={{ marginTop: 5 }}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              )}
              <WebProducts
                from={'seller'}
                sellerDetails={details}
                isEcommerceActive={isEcommerceActive}
                type={searchParams.get('type')}
                table={urlParams?.table}
                searchValue={searchInput}
                setIsProducts={(e) => setIsProducts(e)}
                setIsServices={(e) => setIsServices(e)}
                setProductsType={(e) => setProductsType(e)}
                productsType={productsType}
                section={selectedSection}
                handleActiveComponent={() => handleActiveComponent()}
                setActualProductsData={setActualProductsData}
                handleScroll={() => {
                  if (storeRef.current) {
                    const targetElement = storeRef.current.querySelector('.catsListScroll');
                    if (targetElement) {
                      targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
                    }
                  }
                }}
              />

              {/* <MynyfyTab
                noBackBtn
                tabs={[
                  details?.ownerId?.categories?.find(
                    (e) => e.code == 'Category' && e.data?.toLowerCase().includes('food')
                  )
                    ? 'Menu'
                    : 'Products',
                  'About',
                  'Contests',
                  'Coupons',
                ]}
                tabComponent={[
                  <Products
                    from={'seller'}
                    sellerDetails={details}
                    isEcommerceActive={isEcommerceActive}
                    type={searchParams.get('type')}
                    table={urlParams?.table}
                  />,
                  <StoreAddress details={details} />,
                  <MynyfyTab
                    noBackBtn={true}
                    tabs={['Active', 'Winners']}
                    tabComponent={[
                      <AllContests sellerDetails={details} status='active' from={'seller'} />,
                      <AllContests
                        sellerDetails={details}
                        status='NOACTIVE'
                        from={'seller'}
                        isHistory={true}
                      />,
                    ]}
                  />,
                  <Promos
                    type={[PromoType.COUPON, PromoType.POINT_COUPON]}
                    isFilter={false}
                    emptyListTitle={'Coupons'}
                    emptyListMsg={
                      isLoggedIn
                        ? [
                            "You don't have any Coupons",
                            'Get Coupons by participating',
                            'in Contests & Jackpots',
                          ]
                        : 'Please Login for Coupons'
                    }
                    emptyListMsgBold={isLoggedIn ? [1, 2] : null}
                    promos={promos?.promos?.actual}
                    sortedPromos={promos?.promos?.sorted}
                    action={fetchPromos}
                    sortedAction={fetchSortedPromos}
                    sellerDetails={details}
                    screen={'Coupons'}
                    from={'seller'}
                  />,
                ]}
              /> */}
            </div>
          </div>
        )}
        <LocationModal
          isOpen={isLocationModal}
          close={(e) => {
            if (!_.isEmpty(location) || e) setIsLocationModal(false);
          }}
          closeBtn={() => setIsLocationModal(false)}
          content={
            'Some products may not be deliverable to every location. Set your delivery location to view serviceable products.'
          }
          modalProps={{
            position: 'center',
            style: {
              borderRadius: 10,
            },
          }}
        />
        <SignInModal
          open={openSignIn}
          close={() => setOpenSignIn(false)}
          sellerDetails={{
            image: details?.orgImages?.[0]?.url,
            storename: details?.name,
          }}
        />
        <div className='Storefooter'>
          <div
            className='footer-content'
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'start',
            }}>
            <div className='MenuItems-footer'>
              {details?.aboutUs ? (
                <div
                  style={{ alignItems: 'start', display: 'flex', flexDirection: 'column' }}
                  onClick={() => setActiveComponent('About Us')}
                  cursor>
                  <MynyfyText
                    title={`About ${details?.name}`.toUpperCase()}
                    h4
                    color={'black'}
                    semiBold
                    className='menu-item'
                    style={{ cursor: 'pointer', letterSpacing: 1 }}
                  />
                  <MynyfyText
                    title={`${details?.aboutUs?.slice(0, 150)}`}
                    h6
                    color={'black'}
                    className='menu-item'
                    style={{
                      cursor: 'pointer',
                      letterSpacing: 1,
                      textAlign: 'left',
                      width: '500px',
                      marginTop: 20,
                    }}
                  />
                </div>
              ) : null}
              {details?.address ? (
                <div
                  style={{
                    alignItems: 'start',
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: 10,
                  }}>
                  <MynyfySpan
                    title={`${'Visit us at: '.toUpperCase()}`}
                    h4
                    semiBold
                    color={'black'}
                    className='menu-item'
                    style={{ cursor: 'pointer', letterSpacing: 1, textAlign: 'left' }}
                    onClick={() => details?.mapUrl && window.open(details?.mapUrl)}
                  />

                  <MynyfyText
                    h6
                    title={[details?.address?.lane1 || '', details?.address?.lane2 || ''].join(
                      ', '
                    )}
                    style={{ textAlign: 'left', marginTop: 10 }}
                  />
                  <MynyfyText
                    h6
                    title={[
                      details?.address?.locality || '',
                      details?.address?.city || '',
                      details?.address?.state || '',
                      details?.address?.pincode || '',
                    ].join(', ')}
                    style={{ textAlign: 'left', marginTop: 10 }}
                  />
                </div>
              ) : null}
              <div style={{ alignItems: 'start', display: 'flex', flexDirection: 'column' }}>
                <MynyfyText
                  title={'Contact Us'.toUpperCase()}
                  h4
                  semiBold
                  color={'black'}
                  className='menu-item'
                  style={{ cursor: 'pointer', letterSpacing: 1, paddingBottom: 15, marginTop: 10 }}
                />
                <MynyfyText
                  title={
                    details?.contactNo ? details?.contactNo : details?.ownerId?.profile?.mobile
                  }
                />
                <MynyfyText title={details?.email} style={{ marginTop: 5 }} />
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                height: '100%',
              }}>
              <MynyfyText
                className='cursor'
                title={'quick links'.toUpperCase()}
                h4
                semiBold
                style={{ marginBottom: '15px' }}
              />
              <MynyfyText
                className='cursor'
                title={'About Us'}
                style={{ margin: '5px 0' }}
                onClick={() => setActiveComponent('About Us')}
              />
              <MynyfyText
                className='cursor'
                title={'Policies'}
                style={{ margin: '5px 0' }}
                onClick={() => setActiveComponent('Policies')}
              />
              <MynyfyText
                className='cursor'
                title={`${isFoodCategory ? 'Menu' : 'Products'}`}
                style={{ margin: '5px 0' }}
                onClick={() => setActiveComponent(null)}
              />
              {/* {!_.isEmpty(details?.brands) && (
                <MynyfyText
                  className='cursor'
                  title={`Authorized Reseller`}
                  style={{ margin: '5px 0' }}
                  // onClick={() => setActiveComponent(null)}
                />
              )} */}
            </div>
            <div className='rowC' style={{ marginTop: -13 }}>
              {details?.logo?.url ? (
                <div style={{ width: 50, height: 50, borderRadius: '50%', paddingLeft: 60 }}>
                  <img
                    src={s3ToCloudFront(details?.logo?.url)}
                    width={50}
                    height={50}
                    style={{ objectFit: 'fill', borderRadius: '50%' }}
                    alt='img'
                  />
                </div>
              ) : null}
              <MynyfyText className='StoreName' title={details?.name} h2 gayahi color={'#000'} />
            </div>
          </div>
          {/* <div className='divider'></div> */}
          <div className='rowSA' style={{ marginTop: 40, margin: '0 15%' }}>
            <div style={{ padding: 2 }}>
              <div className='rowC' style={{ paddingTop: 5 }}>
                <MynyfyText title={'Powered by'} style={{ color: 'black' }} />
                <img
                  src={mynyfyLogo}
                  alt='Mynyfylogo'
                  style={{
                    height: 20,
                    width: 'auto',
                    marginRight: 10,
                    marginLeft: 10,
                    objectFit: 'contain',
                  }}
                />
                <MynyfySpan title={'Platform'} style={{ paddingTop: 3, color: 'black' }} />
              </div>
            </div>
            <div>
              © 2025 <MynyfySpan title={details?.name} primary h5 style={{ margin: '0px 10px' }} />
              All rights reserved
            </div>
            <div className='rowC' style={{ gap: 15 }}>
              {details?.socialLinks?.facebook ||
              details?.socialLinks?.instagram ||
              details?.socialLinks?.youtube ? (
                <MynyfyText title={'Follow Us'} style={{ marginLeft: -15 }} />
              ) : null}
              {details?.socialLinks?.facebook ? (
                <Facebook
                  style={{ color: '#1877F2', fontSize: 30 }}
                  className='cursor'
                  onClick={() => openUrl(details?.socialLinks?.facebook)}
                />
              ) : null}
              {details?.socialLinks?.instagram ? (
                <Instagram
                  style={{ color: '#d62976', fontSize: 28 }}
                  className='cursor'
                  onClick={() => openUrl(details?.socialLinks?.instagram)}
                />
              ) : null}
              {details?.socialLinks?.youtube ? (
                <YouTube
                  style={{ color: 'red', fontSize: 35 }}
                  className='cursor'
                  onClick={() => openUrl(details?.socialLinks?.youtube)}
                />
              ) : null}
            </div>
          </div>
        </div>
        <div
          style={{
            position: 'fixed',
            zIndex: 9,
            display: _.isEmpty(details?.socialLinks?.whatsapp) ? 'none' : 'block',
            right: '4%',
            bottom: '2%',
          }}>
          <a
            href={`https://wa.me/${details?.socialLinks?.whatsapp}`}
            target='_blank'
            rel='noopener noreferrer'
            style={{ textDecoration: 'none', color: 'inherit' }}>
            <WhatsApp style={{ color: '#25D366', fontSize: 40 }} />
          </a>
        </div>
      </div>
    )
  ) : noStore ? (
    <div id='Stores'>
      <div className='noStore'>
        <MynyfyText title={'No store found'} center h6 />
      </div>
    </div>
  ) : (
    <MynyfyLoader />
  );
};

export default WebStoreDetails;
