import React from 'react';
import MynyfyTab from '../../components/MynyfyTab';
import { useSelector } from 'react-redux';
import { fetchFavOfflineStores, fetchOfflineStores } from '../../redux/actions/store.action';
import StoresList from './StoresList';

const OfflineStores = () => {
  const offlineStores = useSelector((state) => state.stores?.offlineStores);
  const favOfflineStores = useSelector((state) => state.stores?.favOfflineStores);
  const isLoggedIn = useSelector((state) => state.user?.isLoggedIn);

  return (
    <MynyfyTab
      noSwipe
      tabs={['Stores', 'Favourite Stores']}
      tabComponent={[
        <StoresList
          isFav={false}
          offline={true}
          data={offlineStores}
          action={fetchOfflineStores}
          emptyListMsg={[
            'We are on boarding Pick-Up Stores in your area',
            'Soon they will be available',
          ]}
        />,
        <StoresList
          isFav={true}
          offline={true}
          data={favOfflineStores}
          action={fetchFavOfflineStores}
          emptyListMsg={
            isLoggedIn
              ? "You haven't added any Stores to your favourites"
              : 'Please Login for your favourite Stores'
          }
        />,
      ]}
    />
  );
};
export default OfflineStores;
