import React, { Fragment, useState } from 'react';
import MynyfyText from '../../components/MynyfyText';
import './auth.scss';
import MynyfyInput from '../../components/MynyfyInput';
import { Card } from '@mui/material';
import MynyfyButton from '../../components/MynyfyButton';
import { useNavigate } from 'react-router-dom';
import { KeyboardBackspaceRounded } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { snackbarInfo } from '../../redux/actions/snackbar.action';
import _ from 'lodash';
import authService from '../../services/authService';
import { SyncProfile } from '../../utils/enums';
import MynyfyAlert from '../../components/MynyfyAlert';

const VALIDATOR = {
  mobile: [(value) => (value?.length === 10 ? null : 'Please enter valid Mobile Number')],
  password: [(value) => (value?.length ? null : 'Please enter Password')],
};

const ForgotPassword = ({ from }) => {
  const [mobile, setMobile] = useState('');
  const [password, setPassword] = useState('');
  const [otp, setOTP] = useState('');
  const [isOtpSent, setOtpSent] = useState('');
  const [error, setError] = useState({});
  const [isOpen, setOpen] = useState(false);
  const [isWrongOtpModal, setWrongOtpModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [count, setCount] = useState(0);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const sendOtp = () => {
    if (mobile) {
      authService.forgotPasswordSendOTP({ mobile }).then((res) => {
        if (res.success) {
          dispatch(snackbarInfo({ open: true, type: 'success', message: 'Otp sent successfully' }));
          setOtpSent(true);
        } else {
          dispatch(snackbarInfo({ open: true, type: 'error', message: res.message }));
        }
      });
    } else {
      dispatch(
        snackbarInfo({
          open: true,
          type: 'warning',
          message: ['Mobile number cannot be empty', 'Enter your mobile number'],
        })
      );
    }
  };

  const profile_Exists = () => {
    if (!mobile) {
      dispatch(
        snackbarInfo({
          open: true,
          type: 'warning',
          message: ['Mobile number cannot be empty', 'Enter your mobile number'],
        })
      );
    } else if (!mobile.match(/^\d{10}$/)) {
      dispatch(
        snackbarInfo({ open: true, type: 'warning', message: 'Enter proper mobile number' })
      );
    } else {
      authService.profileExist(mobile).then((res) => {
        if (
          res.profile &&
          (res.profile.syncedWith === SyncProfile.BUYER ||
            res.profile.syncedWith === SyncProfile.BUYER_SELLER ||
            res.profile.syncedWith === SyncProfile.BUYER_PARTNER)
        ) {
          sendOtp();
        } else {
          setOpen(true);
        }
      });
    }
  };

  const handleSubmit = () => {
    if (mobile && otp && password) {
      if (password.length >= 6) {
        setCount(count + 1);
        if (count > 2) {
          setIsSubmitting(false);
          setWrongOtpModal(true);
        } else {
          setIsSubmitting(true);
          let body = {
            number: mobile,
            code: otp,
            newPassword: password,
          };
          authService.forgotPassword(body).then((res) => {
            if (res.success) {
              dispatch(
                snackbarInfo({
                  open: true,
                  type: 'success',
                  message: ['Password changed successfully', 'Please login'],
                })
              );

              setIsSubmitting(false);
              navigate('/SignIn');
            } else {
              setIsSubmitting(false);
              dispatch(
                snackbarInfo({ open: true, type: 'error', message: 'Please enter correct otp' })
              );
            }
          });
        }
      } else {
        dispatch(
          snackbarInfo({
            open: true,
            type: 'warning',
            message: 'Password should have minimum 6 characters',
          })
        );
      }
    } else {
      if (!mobile) {
        dispatch(
          snackbarInfo({ open: true, type: 'warning', message: 'Please enter Mobile Number' })
        );
      } else if (!otp) {
        dispatch(snackbarInfo({ open: true, type: 'warning', message: 'Please enter OTP' }));
      } else if (!password) {
        dispatch(snackbarInfo({ open: true, type: 'warning', message: 'Please enter Password' }));
      }
    }
  };

  return (
    <div id='auth'>
      <KeyboardBackspaceRounded className='backBtn' onClick={() => navigate(-1)} />
      <img
        src={require('../../assets/imgs/LandingPage/mynyfy_White.png')}
        alt='Mynyfy'
        className='logo'
      />
      <MynyfyText title={'Forgot Password'} color={'#fff'} h6 />
      <Card className='container' style={{ width: from == 'web' ? '350px' : '' }}>
        <MynyfyInput
          fullWidth={true}
          placeHolder={'Phone Number'}
          value={mobile}
          onChange={(e) => setMobile(e.target.value)}
          helperText={error?.mobile}
          error={error?.mobile}
        />
        {isOtpSent ? (
          <Fragment>
            <MynyfyInput
              fullWidth={true}
              placeHolder={'OTP'}
              value={otp}
              onChange={(e) => setOTP(e.target.value)}
              helperText={error?.otp}
              error={error?.otp}
            />
            <MynyfyInput
              fullWidth={true}
              placeHolder={'Password'}
              type={'password'}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              helperText={error?.password}
              error={error?.password}
            />
          </Fragment>
        ) : null}
        <MynyfyButton
          label={isOtpSent ? 'Change' : 'Verify'}
          fullWidth={true}
          onClick={() => (isOtpSent ? handleSubmit() : profile_Exists())}
          loading={isSubmitting}
          disabled={isSubmitting}
        />
      </Card>

      <MynyfyAlert
        open={isOpen}
        close={() => setOpen(false)}
        title={'Your profile does not exists with us.'}
        action1={{ label: 'Cancel', handler: () => setOpen(false), color: 'black' }}
        action2={{
          label: 'Sign Up Now',
          handler: () => {
            navigate('/SignUp');
            setOpen(false);
          },
        }}
      />

      <MynyfyAlert
        open={isWrongOtpModal}
        close={() => setWrongOtpModal(false)}
        content={'Sorry! You have entered wrong OTP many times, please try again after sometime'}
        action1={{ label: 'Close', handler: () => setWrongOtpModal(false), color: 'black' }}
        action2={{
          label: 'Resend OTP',
          handler: () => {
            setOTP('');
            setCount(0);
            setPassword('');
            sendOtp();
            setWrongOtpModal(false);
          },
        }}
      />
    </div>
  );
};

export default ForgotPassword;
