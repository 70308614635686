// Layout.js
import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Sidebar from './Sidebar';

const exludePaths = [
  'profile',
  'Orders',
  'WebSignIn',
  'EditAddress',
  'Coupons',
  'ShopToJoin',
  'Contest',
  'Register',
];

const Layout = ({ isMynyfy }) => {
  const location = useLocation();

  const [showSideBar, setShowSideBar] = useState(true);

  useEffect(() => {
    let key = location.pathname?.split('/')?.[1];
    let isSideBar = location.state?.sidebar;
    if (!isSideBar) {
      if (exludePaths.includes(key) || !isMynyfy) setShowSideBar(false);
    }
  }, [location]);

  return (
    <div>
      {showSideBar && <Sidebar />}
      <div style={{ marginLeft: showSideBar ? '247px' : 0, padding: '0px 0px 0px 0px' }}>
        <Outlet />
      </div>
    </div>
  );
};

export default Layout;
