import React, { Fragment, useEffect, useState } from 'react';
import MynyfyText from '../../components/MynyfyText';
import { useDispatch, useSelector } from 'react-redux';
import theme from '../../utils/theme';
import { Card } from '@mui/material';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import PrivacyPolicy from '../Policies/PrivacyPolicy';
import LocationModal from '../Location/LocationModal';
import MynyfyAlert from '../../components/MynyfyAlert';
import { Category, KeyboardArrowRight, SearchOutlined } from '@mui/icons-material';
import { ReactComponent as FavoritesIcon } from '../../assets/svg/favorites.svg';
import MynyfySpan from '../../components/MynyfySpan';
import WebDealsLayout from './WebDealsLayout';
import { fetchActualBrands, fetchBrands } from '../../redux/actions/brand.action';
import brandService from '../../services/brandService';
import MynyfyDoubleCard from '../../components/MynyfyDoubleCard';
import { snackbarInfo } from '../../redux/actions/snackbar.action';
import WebMynyfyText from '../../components/WebMynyfyText';
import MynyfyInput from '../../components/MynyfyInput';
import MynyfySelect from '../../components/MynyfySelect';
import MynyfyLoader from '../../components/MynfyfLoader';
import MynyfyEmptyMsg from '../../components/MynyfyEmptyMsg';

const WebHomeLayout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const isLoggedIn = user?.isLoggedIn;
  const location = useSelector((state) => state.location?.location);
  const mynyfyStats = useSelector((state) => state.mynyfyStats);
  const brands = useSelector((state) => state.brands?.brands);
  const actualBrands = useSelector((state) => state.brands?.actualBrands);
  const categories = useSelector((state) => state.appData?.categories);

  const [isLocationModal, setIsLocationModal] = useState(false);
  const [openLocationAlert, setOpenLocationAlert] = useState(false);
  const [filterType, setFilterType] = useState('search');
  const [search, setSearch] = useState('');
  const [category, setCategory] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const imgSize = 27;

  useEffect(() => {
    const urlHash = window.location.hash;
    if (urlHash.length && urlHash === '#PrivacyPolicy') {
      const element = document.getElementById(urlHash.substring(1));
      if (element) {
        element.scrollIntoView();
      }
    }

    if (_.isEmpty(brands) || brands?.length !== actualBrands?.length) {
      getBrands({ initialCall: true });
    }
  }, []);

  const getBrands = ({ searchText, selectedCat, initialCall }) => {
    setIsLoading(true);
    let params = { isActive: true };
    if (location) {
      params.state = location?.state?._id;
      params.city = location?.city?._id;
      params.area = location?.area?._id;
    }
    if (searchText) params.q = searchText;
    if (selectedCat) params.category = selectedCat.value;

    brandService
      .getBrands(params)
      .then((res) => {
        if (res.success) {
          dispatch(fetchBrands(res.data));
          if (initialCall) dispatch(fetchActualBrands(res.data));
        } else {
          dispatch(fetchBrands([]));
          dispatch(fetchActualBrands([]));
          dispatch(snackbarInfo({ type: 'error', open: true, message: res.message }));
        }
      })
      .finally(() => setIsLoading(false));
  };

  const navHandler = (item) => {
    navigate('/Brands/' + item.urlId, { state: { brandDetails: item } });
  };

  return (
    <div style={{ width: '60vw', maxWidth: '60vw' }}>
      {/* <Fragment>
        <div className='rowSB' style={{ paddingInline: 20 }}>
          {mynyfyStats?.buyers ? (
            <div style={{ paddingInline: 35 }}>
              <MynyfySpan title={mynyfyStats?.buyers} bold />
              &nbsp;
              <MynyfySpan title={'Mynyfians!'} />
            </div>
          ) : (
            <MynyfyText title={' '} />
          )}
          <div
            className='row cursor'
            style={{ paddingInline: 35,justifyContent: 'flex-end', flexWrap: 'wrap', paddingLeft: 10 }}
            onClick={() => setIsLocationModal(true)}
            >
            <MynyfyText
              title={_.isEmpty(location) ? 'Set Location' : 'Location:'}
              color={_.isEmpty(location) ? theme.PRIMARY_BLUE : theme.MUTED_BLUE}
              pBig
              style={{ whiteSpace: 'nowrap' }}
            />
            <MynyfyText
              title={location?.area?.name}
              color={theme.NAVY_BLUE}
              pBig
              style={{ paddingLeft: 4 }}
            />
          </div>
       </div>
        <div className='divider' style={{ margin: '15px 40px' }}/>
      </Fragment> */}

      <div className='rowSB' style={{ marginInline: 12, height: 60 }}>
        <WebMynyfyText title={'Explore Brands'} h3 bold />
        <div className='row' style={{ gap: 10 }}>
          {filterType == 'search' ? (
            <MynyfyInput
              placeHolder='Search Brand'
              value={search}
              onChange={(e) => {
                let value = e.target.value;
                setSearch(value);
                if (value?.length > 2 || !value?.length) {
                  getBrands({ searchText: value || null, selectedCat: category || null });
                }
              }}
              style={{ width: 250 }}
              rest={{ sx: { '& .MuiOutlinedInput-root': { borderRadius: 20 } } }}
            />
          ) : filterType == 'cat' ? (
            <MynyfySelect
              placeholder='Select Category'
              width={250}
              value={category}
              onChange={(e) => {
                setCategory(e);
                getBrands({ selectedCat: e || null, searchText: search || null });
              }}
              list={categories?.map((e) => ({ label: e.name, value: e._id, data: e.data }))}
              controlStyles={{ borderRadius: 50 }}
              isClear
            />
          ) : null}
          <div className='row' style={{ gap: 10 }}>
            <SearchOutlined
              color={filterType == 'search' ? 'primary' : 'disabled'}
              onClick={() => setFilterType('search')}
            />
            <Category
              color={filterType == 'cat' ? 'primary' : 'disabled'}
              onClick={() => setFilterType('cat')}
            />
          </div>
          <div className='row' onClick={() => setIsLocationModal(true)}>
            <MynyfyText
              title={_.isEmpty(location) ? 'Set Location' : 'Location:'}
              color={_.isEmpty(location) ? theme.PRIMARY_BLUE : theme.MUTED_BLUE}
              pBig
              style={{ whiteSpace: 'nowrap' }}
            />
            {location ? (
              <MynyfyText
                title={location?.area?.name}
                color={theme.NAVY_BLUE}
                pBig
                style={{ paddingLeft: 4 }}
              />
            ) : null}
          </div>
        </div>
      </div>

      {!brands || isLoading ? (
        <MynyfyLoader />
      ) : _.isEmpty(brands) ? (
        <MynyfyEmptyMsg
          message={'No Brands are available'}
          containerStyle={{ paddingTop: 0, paddingBlock: '30vh' }}
        />
      ) : (
        <MynyfyDoubleCard
          data={brands}
          cardStyle={{ margin: 10 }}
          containerStyle={{ justifyContent: 'center' }}
          onClick={(item) => navHandler(item)}
          footer={(e) => {}}
        />
      )}

      {/* <WebDealsLayout noHeader /> */}

      {isLoggedIn ? (
        <div style={{ paddingBottom: 30 }} />
      ) : (
        <Fragment>
          <div className='policyContainer' id='PrivacyPolicy'>
            <Card
              className='feature rowSB'
              style={{ backgroundColor: theme.PRIMARY_BLUE }}
              onClick={() => navigate('/Policies')}>
              <MynyfyText title={'Mynyfy Policies'} color={'#fff'} semiBold />
              <KeyboardArrowRight style={{ color: '#fff' }} />
            </Card>
          </div>
          {window?.location?.hash === '#PrivacyPolicy' ? (
            <div>
              <PrivacyPolicy
                customHeader={() => (
                  <MynyfyText title={'Privacy Policy'} h4 bold style={{ marginLeft: 20 }} />
                )}
              />
            </div>
          ) : null}
        </Fragment>
      )}

      <LocationModal
        isOpen={isLocationModal}
        close={(e) => {
          if (!_.isEmpty(location) || e) setIsLocationModal(false);
        }}
        closeBtn={() => setIsLocationModal(false)}
      />

      <MynyfyAlert
        open={openLocationAlert}
        close={() => {}}
        content={'Please set your location to get personalized data'}
        action1={{
          label: 'Set Location',
          handler: () => {
            setOpenLocationAlert(false);
            setIsLocationModal(true);
          },
        }}
      />
    </div>
  );
};

export default WebHomeLayout;
