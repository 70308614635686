import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import contestService from '../../services/contestService';
import { objectReplace } from '../../utils/CommonMethods';
import { ContestType } from '../../utils/enums';
import _ from 'lodash';
import MynyfyLoader from '../../components/MynfyfLoader';
import MynyfyEmptyMsg from '../../components/MynyfyEmptyMsg';
import MynyfyHalfCard from '../../components/MynyfyHalfCard';

const ContestHalfCardList = ({
  data,
  action,
  onClick,
  isLoading,
  refreshing,
  onRefresh,
  isHistory,
  customComponent,
  emptyListMsg,
  emptyListTitle,
}) => {
  const dispatch = useDispatch();
  const location = useSelector((state) => state.location?.location);

  const viewContestHandler = (id) => {
    let body = {
      state: location?.state?._id,
      city: location?.city?._id,
      area: location?.area?._id,
    };

    contestService
      .viewContest(id, body)
      .then((res) => {
        if (res.success) {
          let reqObj = data.find((e) => e._id == id);
          if (reqObj) {
            reqObj.totalViews = reqObj.totalViews ? reqObj.totalViews + 1 : 1;
            let newData = objectReplace(data, reqObj, '_id');
            dispatch(action(newData));
          }
        }
      })
      .catch((e) => console.log(e));
  };

  const detailsNavHandler = (item) => {
    onClick(item);
    if (item.type === ContestType.SEE_AND_WIN) {
      viewContestHandler(item._id);
    }
  };

  return isLoading ? (
    <MynyfyLoader paddingTop={'18vh'} />
  ) : _.isEmpty(data) ? (
    <MynyfyEmptyMsg
      paddingTop={'18vh'}
      message={emptyListMsg || `No ${emptyListTitle} currently running in your area`}
    />
  ) : (
    <div style={{ paddingTop: 10 }}>
      <MynyfyHalfCard
        data={data}
        onClick={(item) => detailsNavHandler(item)}
        footer={(item) => (isHistory ? customComponent(item) : null)}
        cardStyle={{ position: 'relative' }}
        isHistory={isHistory}
      />
    </div>
  );
};

export default ContestHalfCardList;
