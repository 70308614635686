// Profile
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_LOGIN = 'USER_LOGIN';
export const USER_REGISTER = 'USER_REGISTER';
export const USER_DEVICE_ID = 'USER_DEVICE_ID';
export const USER_TOKEN = 'USER_TOKEN';
export const USER_PROFILE = 'USER_PROFILE';
export const WATCH_PROFILE = 'WATCH_PROFILE';
export const RELOAD_WATCHER = 'RELOAD_WATCHER';
export const INITIAL_RELOAD_WATCHER = 'INITIAL_RELOAD_WATCHER';
export const RELOAD_WITH_LOC_WATCHER = 'RELOAD_WITH_LOC_WATCHER';

// Contests
export const JACKPOT = 'JACKPOT';
export const SEE_AND_WIN = 'SEE_AND_WIN';
export const SHOP_AND_WIN = 'SHOP_AND_WIN';
export const FOOD_SHOP_AND_WIN = 'FOOD_SHOP_AND_WIN';
export const SCAN_AND_WIN = 'SCAN_AND_WIN';
export const JACKPOT_HISTORY = 'JACKPOT_HISTORY';
export const SEE_AND_WIN_HISTORY = 'SEE_AND_WIN_HISTORY';
export const SHOP_AND_WIN_HISTORY = 'SHOP_AND_WIN_HISTORY';
export const FOOD_SHOP_AND_WIN_HISTORY = 'FOOD_SHOP_AND_WIN_HISTORY';
export const SCAN_AND_WIN_HISTORY = 'SCAN_AND_WIN_HISTORY';

// Promos
export const DAY_DEALS = 'DAY_DEALS';
export const SORTED_DAY_DEALS = 'SORTED_DAY_DEALS';
export const COUPONS = 'COUPONS';
export const SORTED_COUPONS = 'SORTED_COUPONS';
export const FOOD_COUPONS = 'FOOD_COUPONS';
export const SORTED_FOOD_COUPONS = 'SORTED_FOOD_COUPONS';
export const REWARDS = 'REWARDS';
export const SORTED_REWARDS = 'SORTED_REWARDS';
export const PROMOS = 'PROMOS';
export const SORTED_PROMOS = 'SORTED_PROMOS';

// App Data
export const STATES = 'STATES';
export const CATEGORIES = 'CATEGORIES';
export const REFER_POINTS = 'REFER_POINTS';
export const AD_TIMER = 'AD_TIMER';
export const ORDER_MYNTS = 'ORDER_MYNTS';

// Brands
export const FETCH_ACTUAL_BRANDS = 'FETCH_ACTUAL_BRANDS';
export const FETCH_BRANDS = 'FETCH_BRANDS';
export const FETCH_FAV_BRANDS = 'FETCH_FAV_BRANDS';
export const FETCH_ACTUAL_FAV_BRANDS = 'FETCH_ACTUAL_FAV_BRANDS';
export const FETCH_BRANDS_DETAILS = 'FETCH_BRANDS_DETAILS';

// Orders
export const ORDERS = 'ORDERS';
export const ORDERS_HISTORY = 'ORDERS_HISTORY';

// Stores
export const FETCH_STORES = 'FETCH_STORES';
export const FETCH_FAV_STORES = 'FETCH_FAV_STORES';
export const FETCH_OFFLINE_STORES = 'FETCH_OFFLINE_STORES';
export const FETCH_FAV_OFFLINE_STORES = 'FETCH_FAV_OFFLINE_STORES';
export const FETCH_STORES_DETAILS = 'FETCH_STORES_DETAILS';
export const FETCH_ONLINE_FOOD_STORES = 'FETCH_ONLINE_FOOD_STORES';
export const FETCH_OFFLINE_FOOD_STORES = 'FETCH_OFFLINE_FOOD_STORES';
export const FETCH_VISITED_STORES = 'FETCH_VISITED_STORES';

// Bag
export const BAG_PRODUCTS = 'BAG_PRODUCTS';
export const BAG_OFFERS = 'BAG_OFFERS';
export const BAG_CALCULATION = 'BAG_CALCULATION';
export const BAG_SELLING_ID = 'BAG_SELLING_ID';
export const BAG_ABOUT = 'BAG_ABOUT';

// Flash Ads
export const FLASH_ADS = 'FLASH_ADS';
export const SEEN_FLASH_ADS = 'SEEN_FLASH_ADS';
export const IS_FLASH_AD_SEEN = 'IS_FLASH_AD_SEEN';

export const NOTIFICATIONS = 'NOTIFICATIONS';
export const SNACKBAR = 'SNACKBAR';
export const QR_INFO = 'QR_INFO';
export const NON_VIEWED_COUPONS_COUNT = 'NON_VIEWED_COUPONS_COUNT';
export const MYNTS = 'MYNTS';
export const UPDATE_LOCATION = 'UPDATE_LOCATION';
export const PAYMENT_INITIALIZED = 'PAYMENT_INITIALIZED';
export const NEW_ORDER = 'NEW_ORDER';
export const BOTTOM_TAB = 'BOTTOM_TAB';
export const APP_STATE = 'APP_STATE';
export const PUBLISH_AD = 'PUBLISH_AD';
export const DEAL_VIDEO = 'DEAL_VIDEO';
export const MYNYFY_STATS = 'MYNYFY_STATS';

//bagProm
export const BAG_PROMO_PRODUCTS = 'BAG_PRODUCTS';
export const BAG_PROMO_OFFERS = 'BAG_OFFERS';
export const BAG_PROMO_CALCULATION = 'BAG_CALCULATION';
export const BAG_PROMO_SELLING_ID = 'BAG_SELLING_ID';
export const BAG_PROMO_ABOUT = 'BAG_ABOUT';
