import React, { Fragment, useEffect, useState } from 'react';
import MynyfyText from '../../components/MynyfyText';
import { useDispatch, useSelector } from 'react-redux';
import productOrderService from '../../services/productOrderService';
import { fetchIsNewOrder } from '../../redux/actions/newOrder.action';
import { snackbarInfo } from '../../redux/actions/snackbar.action';
import WebMynyfyEmptyMsg from '../../components/WebMynyfyEmptyMsg';
import MynyfyLoader from '../../components/MynfyfLoader';
import { useNavigate } from 'react-router-dom';
import { Card } from '@mui/material';
import _ from 'lodash';
import {
  AccessTimeFilledRounded,
  CancelRounded,
  CheckCircleRounded,
  ChevronRightRounded,
} from '@mui/icons-material';
import { OrderDeliveryStatus, OrderStatus, PaymentMode } from '../../utils/enums';
import theme from '../../utils/theme';
import moment from 'moment';
import { orderDeliveryStatusSwitchCase, orderStatusSwitchCase } from '../../utils/SwitchCases';
import './Weborders.scss';
import OrderPaymentUpdate from './OrderPaymentUpdate';
import { objectReplace } from '../../utils/CommonMethods';
import ShowImages from '../../components/ShowImages';
import storeService from '../../services/storeService';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import WebOrderDetails from './WebOrderDetails';

const WebOrdersList = ({ type, action, data, sellerId, brandId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isNewOrder = useSelector((state) => state.isNewOrder);
  const buyerId = useSelector((state) => state.user?.user?.id);

  const [openPaymentUpdateModal, setOpenPaymentUpdateModal] = useState(false);
  const [orderId, setOrderId] = useState(null);
  const [openImgModal, setOpenImgModal] = useState(false);
  const [paymentImg, setPaymentImg] = useState(null);
  const [openOrderDrawer, setopenOrderDrawer] = useState(false);
  const [selectedOrder, setselectedOrder] = useState(null);
  const [openSignIn, setOpenSignIn] = useState(false);

  useEffect(() => {
    if (isNewOrder !== false || _.isEmpty(data)) {
      getOrders();
    }
  }, [isNewOrder]);

  const getOrders = () => {
    let body = { status: type };
    if (sellerId) body.seller = sellerId;
    if (brandId) body.brand = brandId;

    productOrderService
      .getProductOrders(body)
      .then((res) => {
        if (res.success) {
          let sorted = _.orderBy(res.data, 'createdAt', 'desc');
          dispatch(action(sorted));
        } else {
          dispatch(snackbarInfo({ open: true, type: 'error', message: res.message }));
        }
      })
      .finally(() => {
        if (isNewOrder) dispatch(fetchIsNewOrder(false));
      });
  };

  // const navHandler = (item) => {
  //   navigate('/Orders/' + item.orderId, {
  //     state: { orderId: item.orderId, orderDetails: item },
  //   });
  // };

  const navHandler = (item) => {
    setselectedOrder(item);
    setopenOrderDrawer(true);
  };

  return data ? (
    _.isEmpty(data) ? (
      <WebMynyfyEmptyMsg message={"You haven't placed any orders"} />
    ) : (
      <Fragment>
        <div id='WebOrders'>
          <div className='Webcontainer'>
            <Card style={{ borderRadius: 5, margin: 10 }}>
              <table className='table-full-width' aria-label='a dense table'>
                <thead style={{ backgroundColor: '#f9f9fa' }}>
                  <TableRow style={{ height: '35px' }}>
                    <TableCell>
                      <MynyfyText title='S.No' bold />
                    </TableCell>
                    <TableCell>
                      <MynyfyText title='Order Id' bold />
                    </TableCell>
                    <TableCell>
                      <MynyfyText title='Payment Type' bold />
                    </TableCell>
                    <TableCell>
                      <MynyfyText title='Order Type' bold />
                    </TableCell>
                    <TableCell>
                      <MynyfyText title='Amount' bold />
                    </TableCell>
                  </TableRow>
                </thead>
                <tbody>
                  {data?.map((item, i) => (
                    <tr
                      style={{ borderBottom: '1px solid #e0e0e0' }}
                      key={i}
                      className='cursor'
                      onClick={() => navHandler(item)}>
                      <td>{i + 1}</td>
                      <td>
                        <MynyfyText title={item.orderId} />
                      </td>
                      <td>
                        <div>
                          {item.paymentMode == PaymentMode.UPI_QR ? (
                            item.paymentStatus !== 'RECEIVED' ? (
                              <div>
                                {item.paymentStatus == 'PAID' ? (
                                  <MynyfyText
                                    title={'View Payment'}
                                    onClick={() => {
                                      setOpenImgModal(true);
                                      setPaymentImg(item.paymentImg);
                                    }}
                                    link
                                  />
                                ) : item.paymentStatus == 'NOT RECEIVED' ? (
                                  <Fragment>
                                    <MynyfyText
                                      title={'Update Payment'}
                                      onClick={() => {
                                        setOpenPaymentUpdateModal(true);
                                        setOrderId(item._id);
                                      }}
                                      link
                                    />
                                    <MynyfyText title={item.paymentStatus} error />
                                  </Fragment>
                                ) : item.paymentStatus == 'REFUNDED' ? (
                                  <MynyfyText title={'Refunded'} color={'green'} />
                                ) : null}
                              </div>
                            ) : item.paymentStatus == 'RECEIVED' ? (
                              <MynyfyText title={'Payment Completed'} color={'green'} />
                            ) : null
                          ) : item.paymentMode == PaymentMode.CASH ? (
                            <MynyfyText title={'Cash Payment'} color={'green'} />
                          ) : item.paymentMode == PaymentMode.ONLINE ? (
                            <MynyfyText
                              title={
                                item.paymentStatus == 'REFUNDED' ? 'Refunded' : 'Payment Completed'
                              }
                              color={'green'}
                            />
                          ) : null}
                        </div>
                      </td>
                      <td>
                        <div className='rowOT'>
                          <MynyfyText
                            title={
                              [
                                OrderStatus.ACTIVE,
                                OrderStatus.COMPLETED,
                                OrderStatus.CANCELLED,
                              ].includes(item.status)
                                ? orderDeliveryStatusSwitchCase(
                                    item.deliveryDetails?.deliveryStatus
                                  )
                                : orderStatusSwitchCase(item.status)
                            }
                            style={{ margin: '0px 5px' }}
                          />
                          {item.status == OrderStatus.PENDING ? (
                            <AccessTimeFilledRounded fontSize='' color='warning' />
                          ) : item.status == OrderStatus.CANCELLED ? (
                            <CancelRounded fontSize='' color='error' />
                          ) : item.deliveryDetails?.deliveryStatus ==
                              OrderDeliveryStatus.IN_PROGRESS ||
                            item.deliveryDetails?.deliveryStatus == OrderDeliveryStatus.ACCEPTED ||
                            item.deliveryDetails?.deliveryStatus ==
                              OrderDeliveryStatus.PICK_UP_READY ||
                            item.deliveryDetails?.deliveryStatus == OrderDeliveryStatus.SHIPPED ? (
                            <AccessTimeFilledRounded fontSize='' color='primary' />
                          ) : item.deliveryDetails?.deliveryStatus ==
                              OrderDeliveryStatus.CANCELLED ||
                            item.deliveryDetails?.deliveryStatus == OrderDeliveryStatus.RETURNED ? (
                            <CheckCircleRounded fontSize='' color='error' />
                          ) : item.deliveryDetails?.deliveryStatus ==
                              OrderDeliveryStatus.DELIVERED ||
                            item.status == OrderStatus.COMPLETED ? (
                            <CheckCircleRounded fontSize='' color='success' />
                          ) : null}
                        </div>
                      </td>
                      <td>
                        <div className='row'>
                          <MynyfyText title={`Rs. ${item.priceDetails.totalPrice}`} bold />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Card>
            <WebOrderDetails
              open={openOrderDrawer}
              onClose={() => setopenOrderDrawer(false)}
              orderId={selectedOrder?.orderId}
              orderDetail={selectedOrder}
            />
          </div>
        </div>
        <OrderPaymentUpdate
          isVisible={openPaymentUpdateModal}
          closeModal={(isUpdated) => {
            setOpenPaymentUpdateModal(false);
            if (isUpdated) {
              let reqOrder = data.find((e) => e._id == orderId);
              if (reqOrder) {
                reqOrder.paymentStatus = 'PAID';
                let modData = objectReplace(data, reqOrder, '_id');
                dispatch(action(modData));
              }
              setOrderId(null);
            } else {
              setOrderId(null);
            }
          }}
          id={orderId}
        />
        <ShowImages
          isVisible={openImgModal}
          closeModal={() => setOpenImgModal(false)}
          images={paymentImg}
        />
      </Fragment>
    )
  ) : (
    <MynyfyLoader />
  );
};

export default WebOrdersList;
