import theme from '../../utils/theme';

export const ProfileList = [
  {
    name: 'My Ads',
    helperText: 'Publish & Monitor your Ads',
    icon: 'ads',
    iconColor: theme.PRIMARY_BLUE,
    screenName: 'FlashAds',
  },
  {
    name: 'Favourites',
    helperText: 'Save all you liked Brands & Stores',
    icon: 'favourites',
    iconColor: 'red',
    screenName: 'Favourites',
  },
  {
    name: 'Notifications',
    helperText: 'Notification Center',
    icon: 'notification',
    iconColor: '#fb8c00',
    screenName: 'Notifications',
  },
  {
    name: 'Edit',
    helperText: 'Edit Profile Details',
    icon: 'favourites',
    iconColor: 'red',
    screenName: 'EditProfile',
  },
  {
    name: 'Saved Addresses',
    helperText: 'Your Saved Addresses',
    icon: 'favourites',
    iconColor: 'red',
    screenName: 'Address',
  },
  {
    name: 'Transactions',
    helperText: 'All Mynyfy Mynts transactions',
    icon: 'transactions',
    iconColor: theme.PRIMARY_BLUE,
    screenName: 'Transactions',
  },
  {
    name: 'Refer',
    helperText: 'Help your friends get the app',
    icon: 'share',
    iconColor: '#388e3c',
    screenName: 'Refer',
  },
  {
    name: 'Mynyfy Policies',
    helperText: 'View All Mynyfy Policies',
    icon: 'policy',
    iconColor: '#5e35b1',
    screenName: 'Policies',
  },
  {
    name: 'About Us',
    helperText: 'View About Mynyfy',
    icon: 'about',
    iconColor: '#ffa500',
    screenName: 'AboutUs',
  },
  {
    name: 'Contact Us',
    helperText: 'Contact Us for any queries',
    icon: 'contact',
    iconColor: theme.PRIMARY_BLUE,
    screenName: 'ContactUs',
  },
  {
    name: 'Change Password',
    helperText: 'Manage your password',
    icon: 'changePassword',
    iconColor: '#843284',
    screenName: 'ChangePassword',
  },
  {
    name: 'Logout',
    helperText: 'Switch Account / Logout from app',
    icon: 'logout',
    iconColor: '#FF4C4C',
    screenName: 'logout',
  },
];
