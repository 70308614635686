import React from 'react';
import WebTopBar from './WebTopBar';
import './WebHome.scss';
import WebHomeLayout from './WebHomeLayout';
import FlashAds from '../FlashAds';
import { useSelector } from 'react-redux';
import WebVisitedStores from '../Stores/WebVisitedStores';

const WebHome = () => {
  const user = useSelector((state) => state.user);
  const isLoggedIn = user?.isLoggedIn;

  return (
    <div id='webHome'>
      <div style={{ display: 'flex' }}>
        <div style={{ flex: 6, padding: '0 20px' }}>
          <WebHomeLayout />
        </div>
        {isLoggedIn ? (
          <div style={{ flex: 4, padding: '0 20px' }}>
            <WebVisitedStores />
          </div>
        ) : null}
      </div>
      <FlashAds />
    </div>
  );
};

export default WebHome;
